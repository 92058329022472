import React, { PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { OnLoading } from '../../../types/types';

interface UploadRecoveryButtonProps {
  onClick?: () => void;
  onLoading: OnLoading;
}

export default class UploadRecoveryButton extends PureComponent<UploadRecoveryButtonProps> {
  recoveryInput: React.RefObject<unknown>;

  constructor(props: UploadRecoveryButtonProps) {
    super(props);

    this.recoveryInput = React.createRef();
  }

  render() {
    return (
      <Button onClick={this.props.onClick} variant="outlined" component="label">
        Load
      </Button>
    );
  }
}
