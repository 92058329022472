import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, Button } from '@material-ui/core';
import { FaArrowRight } from 'react-icons/fa';

import shippingLabel from '../../assets/img/shippingLabel.png';
import shippingLabelVoid from '../../assets/img/shippingLabelVoid.png';

interface VoidLabelDialogProps {
  voidLabelPopupVisible: boolean;
  setVoidLabelPopupVisible: (visible: boolean) => void;
}

const VoidLabelDialog = ({ voidLabelPopupVisible, setVoidLabelPopupVisible }: VoidLabelDialogProps) => {
  return (
    <Dialog open={voidLabelPopupVisible} maxWidth={'md'} title="Please void shipping label">
      <DialogTitle>Please void this boxes shipping label</DialogTitle>
      <DialogContent>
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <img
              style={{
                height: '100px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              src={shippingLabel}
              alt="Shipping Label"
            />
          </Grid>

          <Grid item>
            <FaArrowRight fontSize={32} />
          </Grid>

          <Grid item>
            <img
              style={{
                height: '100px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              src={shippingLabelVoid}
              alt="Shipping Label Void"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="default" onClick={() => setVoidLabelPopupVisible(false)}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VoidLabelDialog;
