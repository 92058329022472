import { Grid, Modal, Paper } from '@material-ui/core';
import { BarcodeScanner } from '../utils';
import { BarcodeFormat } from '@zxing/library';

interface Props {
  activeScan: boolean;
  targetBoxIDForUPSLabel: string;
  loadingScanner: boolean;
  updateDetectedCode: (text: string, rawBytes: ArrayBuffer | File | string) => Promise<void>;
  closeScanner: () => void;
  onCanPlay: () => void;
  handleLoadFailure: (err?: unknown) => void;
  progressBar: JSX.Element[];
}

export function ShippingBarocdeScanner({
  activeScan,
  targetBoxIDForUPSLabel,
  loadingScanner,
  updateDetectedCode,
  closeScanner,
  onCanPlay,
  handleLoadFailure,
  progressBar,
}: Props) {
  if (!activeScan && !targetBoxIDForUPSLabel) {
    return null;
  }

  return (
    <Modal
      key={'modal 1'}
      style={{ height: '100%', width: '100%' }}
      open={true}
      className={loadingScanner ? 'hide' : ''}
    >
      <Paper square={true}>
        <Grid container justifyContent="center">
          <BarcodeScanner
            updateDetectedCode={updateDetectedCode}
            type={[BarcodeFormat.CODE_128, BarcodeFormat.QR_CODE]}
            fullScreen={true}
            facingMode={'environment'}
            // () => this.setState({ activeScan: false, targetBoxIDForUPSLabel: '', loadingScanner: false })
            closeScanner={closeScanner}
            progressBar={progressBar}
            // () => this.setState({ loadingScanner: false })
            onCanPlay={onCanPlay}
            // this.handleLoadFailure.bind(this)
            handleLoadFailure={handleLoadFailure}
            sampleScanner={false}
          />
        </Grid>
      </Paper>
    </Modal>
  );
}
