import { alertFullScreen, alertWarn } from './alertDispatcher';
import { findNextSample } from './barcodes';
import {
  BD_CORE_AUTO,
  BD_CORE_MANUAL,
  COLOR,
  FEET_PER_MILE,
  FERTILITY_CORE_AUTO,
  FERTILITY_CORE_MANUAL,
  METERS_PER_FOOT,
} from './constants';
import { getCurrentMission } from './dataModelHelpers';
import { Sample } from './db';
import {
  SimulatorFPS,
  SimulatorAddJitter,
  SimulatorAccelerationScaler,
  SimulatorMaxMilesPerHour,
  SimulatorAccelerationFactor,
  SimulatorDecelerationFactor,
  SimulatorKeyRepeatMode,
} from './db/local_storage';
import EventBus from './EventBus';
import { dispatchMissionUpdated } from './missionEvents';
import { PUB_HEADER } from './RobotConnection';
import {
  // MissionEventStore,
  setSampleSelected,
} from './sampleSelectionHelpers';
import { CoreMsg, RosPosition } from './types/rosmsg';
import {
  CoringAllowedState,
  CoringAllowedStates,
  RobotNavControl,
  RosMsgPubData,
  WebsocketPacket,
} from './types/types';
import { clamp, convertProjection3857, LocalStorageGenerator, wait } from './utils';
import { calculateArmPosition } from './services/RobotArmService';

function publishHMIButtonColor(button: string, color: COLOR) {
  console.log('publishHMIButtonColor', button, color);
  document.dispatchEvent(
    new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
      detail: {
        type: 'data',
        topic: `hmi/${button}/color`,
        compressed: false,
        data: {
          data: color.valueOf(),
        },
      },
    }),
  );
}

function publishHMIButtonLabel(button: string, text: string) {
  console.log('publishHMIButtonLabel', button, text);
  document.dispatchEvent(
    new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
      detail: {
        type: 'data',
        topic: `hmi/${button}/label`,
        compressed: false,
        data: {
          data: text,
        },
      },
    }),
  );
}

const autoManualState = () => SimulatorLastAutoManualState.get();
const inAuto = () => autoManualState() === 'Auto';

type MissionType = 'Fertility' | 'BD';

const CoreTypeLookup: Record<RobotNavControl, Record<MissionType, number>> = {
  Manual: {
    Fertility: FERTILITY_CORE_MANUAL,
    BD: BD_CORE_MANUAL,
  },
  Auto: {
    Fertility: FERTILITY_CORE_AUTO,
    BD: BD_CORE_AUTO,
  },
};

const RandomBarcodeGenerator = {
  rogo: () => `RG${Math.floor(Math.random() * 1000000000000)}`,
  // Generate 8 digit number
  agtegra: () => `8${Math.floor(Math.random() * 10000000)}`,
}

const keyListener = async (keyboardEvent: KeyboardEvent, position: RosPosition, currentSample?: Sample) => {
  if (!keyboardEvent.shiftKey) {
    return;
  }

  const mission = getCurrentMission();
  if (!mission) {
    alertWarn(`SIMULATOR: No current mission found.`);

    return;
  }

  switch (keyboardEvent.key) {
    case 'A':
      // toggle auto/manual
      SimulatorLastAutoManualState.set(inAuto() ? 'Manual' : 'Auto');
      publishHMIButtonColor('butt_4', inAuto() ? COLOR.GREEN : COLOR.BLUE);
      publishHMIButtonLabel('butt_4', autoManualState());
      break;

    case 'C':
      if (CoringAllowed.get() !== 'Good') {
        alertWarn(`SIMULATOR: Coring is not allowed at this time due to ${CoringAllowed.get()}`);
        return;
      }
      //await target.dumpComplete(Date.now());
      // 'ROBOT_MOCK', async (event: CustomEvent<WebsocketPacket>)
      const time = Date.now();
      // TODO check if allowed to send

      const armPosition = calculateArmPosition([position.y, position.x], -position.z, '3857');
      const robotMessageArmPosition = { x: armPosition[0], y: armPosition[1], z: position.z };
      // console.log(`Robot Message Arm Position`, robotMessageArmPosition);
      const isBDMission = mission?.isBDMission();

      // console.log(`simulatorTools - autoMan state ${autoManualState()}`);
      const initiator = CoreTypeLookup[autoManualState()][isBDMission ? 'BD' : 'Fertility'];

      document.dispatchEvent(
        new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
          detail: {
            type: 'data',
            topic: 'arm/core_complete',
            compressed: false,
            data: {
              header: {
                stamp: {
                  secs: Math.floor(time / 1000),
                  nsecs: (time % 1000) * 1000000,
                },
              },
              offset: {
                x: 0,
                y: 0,
              },
              arm_centered: false,
              core_offset: 0,
              big_motor_used: 1,
              duration: 5 + (Math.random() - 0.5),
              initiator,
              job_id: mission?.job_id,
              location_error: {
                x: 0,
                y: 0,
                z: 0,
              },
              postlaunch_depth_error: 1,
              result: 0,
              robot_id: 'K-99',
              sample_id: currentSample?.sample_id,
              sample_sn: 0,
              status_code: 0,
              target_depth: 249, // TODO use mission spec?
              location: robotMessageArmPosition,
              core_diameter: '1.75in',
            } as CoreMsg,
          },
        }),
      );
      break;

    case 'D':
      document.dispatchEvent(
        new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
          detail: {
            type: 'data',
            topic: 'arm/dump_complete',
            compressed: false,
            data: {
              header: {
                stamp: {
                  secs: 0,
                  nsecs: 0,
                },
              },
              status: 0,
            },
          },
        }),
      );
      // publishHMIButtonColor('butt_2', COLOR["WHITE"]);
      break;

    case 'E':
      mission
        .getSamples()
        .map((sample) => sample.getSoilCores())
        .flat()
        .forEach((core) => {
          if (core.source === 'Rogo Field Ops') {
            const waypoint = core.getCorePoint()?.getWaypoint();
            waypoint?.dispose();
            core.dispose();
          } else {
            core.pulled_at = 0;
            core.pulled_lat = 0;
            core.pulled_lon = 0;
            core.pulled_heading = 0;
            core.initiator = undefined;
            const waypoint = core.getCorePoint()?.getWaypoint();
            if (waypoint) {
              waypoint.lat = core.lat;
              waypoint.lon = core.lon;
            }
          }
        });

      mission.getSoilDumpEvents().forEach((dump) => dump.dispose());

      // MissionEventStore.reset();
      await setSampleSelected();
      dispatchMissionUpdated();
      break;

    case 'H':
      position.x = mission.pullin_lat;
      position.y = mission.pullin_lon;
      position.z = 0;
      break;

    // Go to current sample
    case 'I':
      currentSample = findNextSample(currentSample);
      console.log(`simulatorTools - going to current sample`, currentSample);

      if (!currentSample) {
        alertWarn(`SIMULATOR: No current sample found.`);
        break;
      }
      const centroid = currentSample.getSampleSite()?.getSampleCentroid();
      if (!centroid) {
        break;
      }
      position.x = centroid.lat;
      position.y = centroid.lon;
      position.z = 0;
      break;

    case 'M':
      // Send sync message
      console.log('Sending mission/sync event');

      document.dispatchEvent(
        new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
          detail: {
            type: 'data',
            topic: 'mission/sync',
            compressed: false,
            data: mission.to_ros_msg('map'),
          },
        }),
      );
      break;

    case 'N':
      document.dispatchEvent(
        new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
          detail: {
            type: 'data',
            topic: 'navigation/nav_leg_complete',
            compressed: false,
            data: {
              header: {
                stamp: {
                  secs: 0,
                  nsecs: 0,
                },
              },
              status: 0,
            },
          },
        }),
      );
      break;

    case 'Q':
      await alertFullScreen(`Please remove bag RG618136033608`, `Box Order Packing Position: 7`, ['Ok']);
      break;

    case 'S':
      // set current sample
      currentSample = findNextSample(currentSample);
      console.log('simulatorTools - setting current sample', currentSample);

      document.dispatchEvent(
        new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
          detail: {
            type: 'data',
            topic: 'mission/waypoint',
            compressed: false,
            data: {
              data: currentSample?.sample_id,
            },
          },
        }),
      );
      break;

    case 'U':
      // * Example msg: {"hostname":"local.rogoag.com","msg":{"data":"RG621390598042"}}
      document.dispatchEvent(
        new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
          detail: {
            type: 'data',
            topic: 'app/barcode',
            compressed: false,
            data: {
              data: 'RG' + Math.floor(Math.random() * 1000000000000),
            },
          },
        }),
      );
      break;

    case 'V':
      publishHMIButtonColor('butt_2', COLOR['ORBRWN']);
      break;
  }
};

const SimulatorID = LocalStorageGenerator<number>('simulatorId', 0);
const SimulatorLastPosition = LocalStorageGenerator<RosPosition>('simulatorLastPosition', {
  x: 39.531842072681485,
  y: -85.56451666422635,
  z: 0,
});
const SimulatorDirection = LocalStorageGenerator<'Forward' | 'Backward' | 'Stopped'>('simulatorDirection', 'Stopped');
const SimulatorRotation = LocalStorageGenerator<number>('simulatorRotation', 0);
const SimulatorLastAutoManualState = LocalStorageGenerator<RobotNavControl>('simulatorLastAutoManualState', 'Auto');
const CtrlKeyPressed = LocalStorageGenerator<boolean>('ctrlKeyPressed', false);
const CoringAllowed = LocalStorageGenerator<CoringAllowedState>('coringAllowed', 'Good');

export const inProcessSimulator = async (target: any) => {
  if (!target) return;
  const mySimulatorID = Math.round(Math.random() * 10000);
  SimulatorID.set(mySimulatorID);
  const position = SimulatorLastPosition.get();
  let exit = false;
  let currentSample: Sample | undefined = undefined;
  let rotationDelta = 0;
  const fps = SimulatorFPS.get();
  const keyListenerWithPosition = (keyboardEvent: KeyboardEvent) => {
    // console.log('Key Listener Down', keyboardEvent.key, keyboardEvent.code, keyboardEvent.repeat);
    if (mySimulatorID !== SimulatorID.get()) {
      document.removeEventListener('keydown', keyListenerWithPosition);
      document.removeEventListener('keyup', keyUpListener);
      exit = true;
      return;
    }

    CtrlKeyPressed.set(keyboardEvent.ctrlKey);

    if (SimulatorKeyRepeatMode.get()) {
      if (keyboardEvent.key === 'ArrowLeft') {
        SimulatorRotation.set(Math.PI / 32);
      } else if (keyboardEvent.key === 'ArrowRight') {
        SimulatorRotation.set(-Math.PI / 32);
      } else if (keyboardEvent.key === 'ArrowUp') {
        SimulatorDirection.set('Forward');
      } else if (keyboardEvent.key === 'ArrowDown') {
        SimulatorDirection.set('Backward');
      }
    } else {
      if (keyboardEvent.key === 'ArrowLeft') {
        if (SimulatorRotation.get() < 0) {
          SimulatorRotation.set(0);
        } else {
          SimulatorRotation.set(Math.PI / 32);
        }
      } else if (keyboardEvent.key === 'ArrowRight') {
        if (SimulatorRotation.get() > 0) {
          SimulatorRotation.set(0);
        } else {
          SimulatorRotation.set(-Math.PI / 32);
        }
      } else if (keyboardEvent.key === 'ArrowUp') {
        if (SimulatorDirection.get() === 'Backward') {
          SimulatorDirection.set('Stopped');
        } else {
          SimulatorDirection.set('Forward');
        }
      } else if (keyboardEvent.key === 'ArrowDown') {
        if (SimulatorDirection.get() === 'Forward') {
          SimulatorDirection.set('Stopped');
        } else {
          SimulatorDirection.set('Backward');
        }
      }
    }

    keyListener(keyboardEvent, position, currentSample);
  };

  const keyUpListener = (keyboardEvent: KeyboardEvent) => {
    if (mySimulatorID !== SimulatorID.get()) {
      document.removeEventListener('keydown', keyListenerWithPosition);
      document.removeEventListener('keyup', keyUpListener);
      exit = true;
      return;
    }
    if (SimulatorKeyRepeatMode.get()) {
      CtrlKeyPressed.set(keyboardEvent.ctrlKey);
      if (keyboardEvent.key === 'ArrowRight' || keyboardEvent.key === 'ArrowLeft') {
        SimulatorRotation.set(0);
      }
      if (keyboardEvent.key === 'ArrowUp' || keyboardEvent.key === 'ArrowDown') {
        SimulatorDirection.set('Stopped');
      }
    } else {
      if (keyboardEvent.key === 'ArrowLeft' && rotationDelta < 0) {
        SimulatorRotation.set(0);
      }
      if (keyboardEvent.key === 'ArrowRight' && rotationDelta > 0) {
        SimulatorRotation.set(0);
      }
      if (keyboardEvent.key === 'ArrowUp' && velocity < 0) {
        SimulatorDirection.set('Stopped');
      }
      if (keyboardEvent.key === 'ArrowDown' && velocity > 0) {
        SimulatorDirection.set('Stopped');
      }
    }

    // console.log(myDebuggerID, 'Debugger Up:   Direction', direction.padEnd(10), 'Rotation', rotationDelta, 'Key', keyboardEvent.key);
  };

  // TODO I don't think this will work since the new functions on reload will have a different reference

  // document.removeEventListener('keyup', keyUpListener);

  // listen for keypresses to move the robot around
  document.addEventListener('keydown', keyListenerWithPosition);

  document.addEventListener('keyup', keyUpListener);

  // async _publish({ topic, msg, compress, expectationParams }: RosMsgPubData) {
  const addTopicHandlers = ({ topic, msg, compress, expectationParams }: RosMsgPubData) => {
    if (mySimulatorID !== SimulatorID.get()) {
      EventBus.remove(PUB_HEADER, addTopicHandlers);
      exit = true;

      return;
    }

    switch (topic) {
      case 'app/core_allow':
        // @ts-ignore
        CoringAllowed.set(CoringAllowedStates[parseInt(msg.data)]);
        break;
      case 'mission/set_waypoint':
        document.dispatchEvent(
          new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
            detail: {
              type: 'data',
              topic: 'mission/set_waypoint',
              compressed: false,
              data: {},
            },
          }),
        );

        expectationParams?.doneCallback && expectationParams.doneCallback(true, true);
        break;

      default:
        break;
    }
  };
  EventBus.on(PUB_HEADER, addTopicHandlers);

  const maxMilesPerHour = SimulatorMaxMilesPerHour.get();
  const feetPerHour = maxMilesPerHour * FEET_PER_MILE;
  const feetPerSecond = feetPerHour / 3600;
  const maxMetersPerSecond = feetPerSecond * METERS_PER_FOOT;
  const LOOP_MS = 1000 / fps;
  const loopsPerSecond = 1000 / LOOP_MS;
  const maxMetersPerLoop = maxMetersPerSecond / loopsPerSecond;

  const [convertedMaxMetersPerLoop] = convertProjection3857([maxMetersPerLoop, maxMetersPerLoop]);

  // This is a bit arbitrary, could probably be tuned or at least be a parameter
  // really just forces a constant acceleration
  const secondsToFullSpeed = maxMilesPerHour / SimulatorAccelerationFactor.get();
  const loopsToFullSpeed = secondsToFullSpeed * loopsPerSecond;
  let acceleration = convertedMaxMetersPerLoop / loopsToFullSpeed;

  // TODO make a local storage setting
  const coordinateJitter = () => {
    const jitter = SimulatorAddJitter.get();
    return Math.random() * jitter - jitter / 2;
  };

  let velocity = 0;
  // 10 52800 14.666666666666666 4.470399856947204 0.8940799713894408

  while (!exit && SimulatorID.get() === mySimulatorID) {
    const startTime = Date.now();
    const direction = SimulatorDirection.get();
    const rotation = SimulatorRotation.get();
    const accelerationScalar = SimulatorAccelerationScaler.get();

    // calculate loop speeds, increased by ctrl key being set
    const immediateAcceleration = acceleration * (CtrlKeyPressed.get() ? accelerationScalar : 1);
    const immediateFullSpeed = convertedMaxMetersPerLoop * (CtrlKeyPressed.get() ? accelerationScalar : 1);

    // calculate a percentage of full acceleration to scale steering. Probably a better way to do this
    const percentageOfFullAcceleration = CtrlKeyPressed.get() ? 1 : Math.abs(velocity) / immediateFullSpeed;

    // do movement adjustment
    position.x += velocity * Math.sin(position.z);
    position.y += velocity * Math.cos(position.z);
    position.z += (percentageOfFullAcceleration * (Math.sign(velocity) * rotation)) % (2 * Math.PI);

    // do acceleration or deceleration
    if (direction === 'Backward' && velocity <= 0) {
      velocity = Math.max(-immediateFullSpeed, velocity - immediateAcceleration);
    } else if (direction === 'Forward' && velocity >= 0) {
      velocity = Math.min(immediateFullSpeed, velocity + immediateAcceleration);
    } else {
      // slow down

      const newSpeed = velocity - Math.sign(velocity) * SimulatorDecelerationFactor.get() * immediateAcceleration;

      // TODO This is just a magic number I picked that is "if it's a very small velocity, set iot to 0"
      if (Math.abs(newSpeed) < 6 * immediateAcceleration) {
        velocity = 0;
      } else {
        velocity = newSpeed;
      }
    }

    // add randomness to simulate real world updates
    if (SimulatorAddJitter.get()) {
      // jitter by different amounts to represent real world GPS jitter
      const jitterX = coordinateJitter();
      const jitterY = coordinateJitter();
      position.x += jitterX;
      position.y += jitterY;
      position.z = 2 * Math.PI + (position.z % (2 * Math.PI));
    }

    document.dispatchEvent(
      new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
        detail: {
          type: 'data',
          topic: 'position',
          compressed: false,
          data: position,
        },
      }),
    );

    document.dispatchEvent(
      new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
        detail: {
          type: 'data',
          topic: 'config/offset_x',
          compressed: false,
          data: {
            data: 230,
          },
        },
      }),
    );

    document.dispatchEvent(
      new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
        detail: {
          type: 'data',
          topic: 'config/offset_y',
          compressed: false,
          data: {
            data: 965,
          },
        },
      }),
    );

    SimulatorLastPosition.set(position);

    // sleep every LOOP_MS
    // TODO should actually do a timer calculation to figure out elapsed
    // loop time and then retrigger sooner
    const loopDuration = Date.now() - startTime;
    await wait(clamp(0, LOOP_MS - loopDuration, LOOP_MS));
  }
};
