import React from 'react';

import Button from '@material-ui/core/Button';
import Alert, { Color } from '@material-ui/lab/Alert';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { BiCheckCircle } from 'react-icons/bi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AlertActions } from '../../alertDispatcher';

const CATEGORY_COLOR_MAP = {
  success: '#43a047',
  error: '#cc0000',
  warning: '#ff8c00',
  info: '#4f86f7',
};

interface AlertMessageProps {
  actions?: AlertActions;
  category: Color;
  confirmMessage?: string;
  confirmNum?: number;
  denyMessage?: string;
  disposeAlert?: (message: string) => void;
  id: number;
  message: string;
  needsConfirm: boolean;
  onConfirm: (message: string, confirm: boolean, confirmNum: number) => void;
  showAll: boolean;
}

export default function AlertMessage(props: AlertMessageProps) {
  return (
    <Alert
      elevation={6}
      variant="filled"
      severity={props.category}
      style={{
        fontSize: 'medium',
        width: 'max-content',
        color: 'white',
        backgroundColor: CATEGORY_COLOR_MAP[props.category],
      }}
      iconMapping={{
        success: <BiCheckCircle />,
        info: <AiOutlineInfoCircle />,
      }}
      action={
        !props.needsConfirm ? (
          <React.Fragment>
            {props.actions &&
              Object.keys(props.actions).map((action) => (
                <Button
                  onClick={async () => {
                    props.disposeAlert && props.disposeAlert(props.message);
                    await props.actions?.[action]();
                  }}
                  style={{ color: 'white', textTransform: 'none' }}
                >
                  {action}
                </Button>
              ))}
            <IconButton
              size={'small'}
              onClick={() => props.disposeAlert && props.disposeAlert(props.message)}
              disabled={props.showAll}
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              onClick={() => props.onConfirm(props.message, true, props.confirmNum ?? 0)}
              data-testid={`message-${props.id}-confirm`}
              style={{ textAlign: 'center', color: 'white' }}
              disabled={props.showAll}
            >
              {props.confirmMessage ? props.confirmMessage : 'yes'}
            </Button>
            <Button
              onClick={() => props.onConfirm(props.message, false, props.confirmNum ?? 0)}
              data-testid={`message-${props.id}-deny`}
              style={{ textAlign: 'center', color: 'white' }}
              disabled={props.showAll}
            >
              {props.denyMessage ? props.denyMessage : 'no'}
            </Button>
          </React.Fragment>
        )
      }
    >
      {props.message}
    </Alert>
  );
}
