import { Coordinate } from 'ol/coordinate';
import { fromLatLon, toLatLon } from 'utm';

// square distance between 2 points
function getSqDist(p1: number[], p2: number[]) {
  let dx = p1[0] - p2[0];
  let dy = p1[1] - p2[1];
  return dx * dx + dy * dy;
}

// square distance from a point to a segment
function getSqSegDist(p: number[], p1: number[], p2: number[]) {
  let x = p1[0];
  let y = p1[1];
  let dx = p2[0] - x;
  let dy = p2[1] - y;

  if (dx !== 0 || dy !== 0) {
    const t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy);
    if (t > 1) {
      x = p2[0];
      y = p2[1];
    } else if (t > 0) {
      x += dx * t;
      y += dy * t;
    }
  }
  dx = p[0] - x;
  dy = p[1] - y;
  return dx * dx + dy * dy;
}

function simplifyRadialDist(points: Coordinate[], sqTolerance: number) {
  let prevPoint = points[0];
  let newPoints = [prevPoint];
  let point: Coordinate = [0, 0];
  for (let i = 1, len = points.length; i < len; i++) {
    point = points[i];
    if (getSqDist(point, prevPoint) > sqTolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }

  if (prevPoint !== point) newPoints.push(point);

  return newPoints;
}

function simplifyDPStep(
  points: Coordinate[],
  first: number,
  last: number,
  sqTolerance: number,
  simplified: Coordinate[],
) {
  let maxSqDist = sqTolerance;
  let index: number = 0;

  for (let i = first + 1; i < last; i++) {
    const sqDist = getSqSegDist(points[i], points[first], points[last]);
    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }

  if (maxSqDist > sqTolerance) {
    if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(points[index]);
    if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points: Coordinate[], sqTolerance: number) {
  let last = points.length - 1;
  let simplified = [points[0]];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(points[last]);
  return simplified;
}

export function simplify(points: Coordinate[], tolerance: number, highestQuality?: boolean) {
  if (points.length <= 2) return points;
  const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;
  points = highestQuality ? points : simplifyRadialDist(points, sqTolerance);
  points = simplifyDouglasPeucker(points, sqTolerance);
  return points;
}

export function simplifyPolygon(points: Coordinate[], tolerance: number, highestQuality: boolean) {
  const polys: { [key: string]: Coordinate[] } = {};
  let finalPoly: Coordinate[] = [];
  for (let point of points) {
    const _point = fromLatLon(point[0], point[1]);
    if (!polys[`${_point.zoneNum}:${_point.zoneLetter}`]) {
      polys[`${_point.zoneNum}:${_point.zoneLetter}`] = [];
    }
    polys[`${_point.zoneNum}:${_point.zoneLetter}`].push([_point.easting, _point.northing]);
  }
  for (let zoneKey of Object.keys(polys)) {
    const simplified = simplify(polys[zoneKey], tolerance, highestQuality);
    const [zoneNum, zoneLetter] = zoneKey.split(':');
    const converted = simplified.map((coord) => {
      const latlon = toLatLon(coord[0], coord[1], parseInt(zoneNum), zoneLetter);
      return [latlon.latitude, latlon.longitude];
    });
    finalPoly = [...finalPoly, ...converted];
  }
  return finalPoly;
}
