import { ALERT_DISPATCH_EVENTS } from '../../alertEvents';

export const ALERT_TYPES = {
  [ALERT_DISPATCH_EVENTS.SUCCESS]: 'success',
  [ALERT_DISPATCH_EVENTS.ERROR]: 'error',
  [ALERT_DISPATCH_EVENTS.CONFIRM_ERROR]: 'warning',
  [ALERT_DISPATCH_EVENTS.CONFIRM]: 'info',
  [ALERT_DISPATCH_EVENTS.CONFIRM_WARN]: 'warning',
  [ALERT_DISPATCH_EVENTS.WARNING]: 'warning',
  [ALERT_DISPATCH_EVENTS.INFO]: 'info',
};
