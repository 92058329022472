import EventBus from './EventBus';
import { ConfirmDialogResult } from './components/utils/ConfirmDialog';
import { SampleChangeType, ChangeReason } from './types/types';

export interface ShowChangeTypeSelectorPopup {
  changeTypeOptions: SampleChangeType[];
  defaultChangeType?: SampleChangeType;
  defaultChangeReason?: ChangeReason | string;
  sampleCoreOrZoneId: string;
}

export interface ChangeReasonPopupResult {
  result: ConfirmDialogResult;
  reason?: string;
  selectedChangeType?: SampleChangeType;
  error?: string;
}

export async function promptForChangeReason(options: ShowChangeTypeSelectorPopup) {
  return new Promise<ChangeReasonPopupResult>(async (resolve) => {
    const handlePopupResult = (resultPayloadString) => {
      const {
        result,
        reason,
        selectedChangeType,
      }: { result: ConfirmDialogResult; reason: string; selectedChangeType: SampleChangeType } =
        JSON.parse(resultPayloadString);
      EventBus.remove('SAMPLING_TASK:CHANGE_TYPE_REASON_SELECTOR_CLOSED', handlePopupResult);
      resolve({ result, reason, selectedChangeType });
    };
    EventBus.on('SAMPLING_TASK:CHANGE_TYPE_REASON_SELECTOR_CLOSED', handlePopupResult);
    const dispatchOptions = { ...options, sampleId: options.sampleCoreOrZoneId };
    EventBus.dispatch('SAMPLING_TASK:SHOW_CHANGE_REASON_SELECTOR', dispatchOptions);
  });
}
