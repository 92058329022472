import React, { PureComponent } from 'react';

import MapButton from './MapButton';

import { getPointResolution, toLonLat } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { geometryToShapefile, calculateAcres } from '../../../utils';
import { ft_to_m } from '../../../constants';

import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import ContrastPaper from '../../utils/ContrastPaper';

import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ReplayIcon from '@material-ui/icons/Replay';
import {
  MdNorthWest,
  MdNorthEast,
  MdSouthEast,
  MdSouthWest,
  MdNorth,
  MdSouth,
  MdEast,
  MdWest,
  MdSwapHoriz,
  MdSwapVert,
  MdViewColumn,
  MdFileDownload,
  MdExpandLess,
  MdExpandMore,
} from 'react-icons/md';

import { HiOutlineViewBoards } from 'react-icons/hi';

import { inBounds } from '../helpers/gridHelper';

import { ZoneType } from '../../../db';
import Feature from 'ol/Feature';
import { LineString, Polygon } from 'ol/geom';

import { MISSION_EVENTS, dispatchMissionUpdated } from '../../../missionEvents';
import EventBus from '../../../EventBus';

import './styles/gridbutton.css';
import { getCurrentMission, getCurrentUser } from '../../../dataModelHelpers';
import { alertWarnConfirm } from '../../../alertDispatcher';
import { Switch, Tooltip } from '@material-ui/core';
import { Coordinate } from 'ol/coordinate';
import { MapType } from '../helpers/mapTypes';
import { GridLayerFeature } from '../layers/GridLayer';
import { SettingsAccessLevel } from '../../../types/types';
import download from 'downloadjs';
import ValueChanger from './ValueChanger';
import ValueChangerPair from './ValueChangerPair';

const StartCornerOptions = ['NW', 'NE', 'SE', 'SW'] as const;
type StartCornerOption = (typeof StartCornerOptions)[number];
const PathDirectionOptions = ['N/S', 'E/W'];
type PathDirectionOption = (typeof PathDirectionOptions)[number];

interface PlotGenerationButtonProps {
  addCheckpoint: (label: string) => void;
  updateGridLayerFeatures: (features: GridLayerFeature[]) => void;
  gridLayerFeatures: GridLayerFeature[];
  mapType: MapType;
  accessLevel: SettingsAccessLevel;
}

type ErrorInfo = {
  error: boolean;
  text: string;
};

type ValidationMapResult = {
  density: ErrorInfo;
  angle: ErrorInfo;
  xCount: ErrorInfo;
  yCount: ErrorInfo;
};

const FAB_SIZE_MAPS = 'small';
const FAB_SIZE_SAMPLING = 'medium';

interface PlotGenerationButtonState {
  plotContainerOpen: boolean;
  minimized: boolean;
  acres: number;
  actual: number;
  angle: number; // degrees
  angleRadians: number; // radians
  startCorner: StartCornerOption; // NW, NE, SE, SW could be enum or new type
  pathDirection: PathDirectionOption; // N/S, E/W could be enum or new type
  shiftNorth: number; // feet
  shiftEast: number; // feet
  angleIncrement: number; // degrees
  boundaryIncrement: number; // feet
  shiftIncrement: number; // feet
  upToDate: boolean;
  fields: Feature<Polygon>[];
  xStart: number;
  xCount: number;
  xIncrement: number;
  yStart: number;
  yCount: number;
  yIncrement: number;
  plotWidth: number;
  plotHeight: number;
  alleyWidthX: number;
  alleyWidthY: number;
  plotWidthIncrement: number;
  plotLengthIncrement: number;
  fieldAngle: number;
  numberOfSamples: number;
  plots: Polygon[];
}

export default class PlotGenerationButton extends PureComponent<PlotGenerationButtonProps, PlotGenerationButtonState> {
  SERIALIZABLE_STATE_VARIABLES = ['plotContainerOpen'];
  shiftKeyDown: boolean;

  constructor(props: PlotGenerationButtonProps) {
    super(props);

    this.shiftKeyDown = false;

    this.state = {
      plotContainerOpen: false,
      minimized: false,
      acres: 0,
      actual: 0,
      angle: 0, // degrees
      angleRadians: 0, // radians
      startCorner: 'SW',
      pathDirection: 'N/S',
      shiftNorth: 0, // feet
      shiftEast: 0, // feet
      angleIncrement: 5, // degrees
      boundaryIncrement: 5, // feet
      shiftIncrement: 5, // feet
      upToDate: false,
      fields: [],
      xStart: 0,
      xCount: 4,
      xIncrement: 1,
      yStart: 0,
      yCount: 4,
      yIncrement: 1,
      plotWidth: 10,
      plotHeight: 40,
      alleyWidthX: 0,
      alleyWidthY: 0,
      plotWidthIncrement: 5,
      plotLengthIncrement: 5,
      fieldAngle: 0,
      numberOfSamples: 1,
      plots: [],
    };

    this.toggleContainer = this.toggleContainer.bind(this);
    this.initFields = this.initFields.bind(this);
  }

  async componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.initFields);
    await this.initFields();

    this.deserializeState();

    document.addEventListener('keydown', this.keyListener);
    document.addEventListener('keyup', this.keyListener);
  }

  keyListener = (keyboardEvent: KeyboardEvent) => {
    if (keyboardEvent.key === 'Escape') {
      this.close();
    }

    this.shiftKeyDown = keyboardEvent.shiftKey;
  };

  async componentDidUpdate(prevProps: PlotGenerationButtonProps) {
    let acres = calculateAcres(this.state.fields);
    if (acres !== this.state.acres) {
      this.setState({ acres });
    }

    if (!this.state.upToDate && this.state.plotContainerOpen && this.state.fields.length > 0) {
      this.generatePlot();
    }
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.UPDATED, this.initFields);
    document.removeEventListener('keydown', this.keyListener);
    document.removeEventListener('keyup', this.keyListener);

    this.serializeState();
  }

  /**
   * serialize the state into session
   */
  serializeState() {
    const { fields, upToDate, plots, ...serializedState } = this.state;
    const job = getCurrentMission()?.getJob();
    if (!!job) {
      job.plot_settings = JSON.stringify(serializedState);
    }
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const plot_settings_string = getCurrentMission()?.getJob()?.plot_settings;
    if (plot_settings_string) {
      const plot_settings = JSON.parse(plot_settings_string);
      this.setState({ ...plot_settings });
    } else {
      console.log('No plot settings found, import from submission notes?');
      // These are the submission notes. Parse the values for the plot tool from this

      // The submission notes below look like this
      // -Large Plot
      // Number of Strips Wide: 20
      // Number of Strips Long: 1
      // Strip Width: 30 feet/26 rows (two passes)
      // Strip Length: 1235 feet
      // Alley Length: 0

      try {
        const mapMakingNotes = getCurrentMission()?.getJob()?.map_making_rules;
        if (mapMakingNotes) {
          const lines = mapMakingNotes.split('\n');
          const largePlotLine = lines.find((line) => line.includes('-Large Plot'));
          const xCountLine = lines.find((line) => line.includes('Number of Strips Wide:'));
          const yCountLine = lines.find((line) => line.includes('Number of Strips Long:'));
          const xWidthLine = lines.find((line) => line.includes('Strip Width:'));
          const yWidthLine = lines.find((line) => line.includes('Strip Length:'));
          const alleyWidthLine = lines.find((line) => line.includes('Alley Length:'));

          if (!largePlotLine || !xCountLine || !yCountLine || !xWidthLine || !yWidthLine || !alleyWidthLine) {
            console.error('Could not find all necessary lines in map making notes');
            return;
          }
          const xCount = parseInt(xCountLine.split(':')[1].trim());
          const yCount = parseInt(yCountLine.split(':')[1].trim());
          const xWidth = parseInt(xWidthLine.split(':')[1].split(' ')[1].trim());
          const yWidth = parseInt(yWidthLine.split(':')[1].split(' ')[1].trim());
          const alleyWidth = parseInt(alleyWidthLine.split(':')[1].trim());
          const numberOfSamples = largePlotLine ? 4 : 1;

          // TODO for calculating angle based on field geometry
          // let field = new MultiPolygon(this.state.fields.map(f => f.getGeometry().clone()));
          // const angleRadians = calculateFieldAngle(field);

          this.setState({
            xCount,
            yCount,
            plotWidth: xWidth,
            plotHeight: yWidth,
            alleyWidthX: alleyWidth,
            // alleyWidthY: alleyWidth,
            numberOfSamples,
            // angle: roundToIncrement(angleRadians * 180 / Math.PI, 0.01),
            // angleRadians
          });
        }
      } catch (err) {
        // This is a normally expected error state, so we are using the local try/catch
        // as execution control
        // console.error('Could not parse map making notes', err);
      }
    }
  }

  async initFields() {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }
    const collectedField = mission.getZones().find((sel) => sel.zone_type === ZoneType.COLLECTED_FIELD);
    const originalField = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);
    const fieldZone = collectedField || originalField;
    if (!fieldZone) {
      console.error('No field zone found');
      return;
    }
    const features = await fieldZone.to_feature();
    const fields = features.map((feature) => {
      const coords = feature.geometry.coordinates.map((coords) =>
        coords.map((ring) => {
          return ring;
        }),
      );
      const geom = new Polygon(coords);
      return new Feature(geom);
    });

    this.setState({
      fields,
    });
  }

  async toggleContainer() {
    if (!this.state.plotContainerOpen) {
      const mission = getCurrentMission();
      const job = mission?.getJob();
      if (mission && job && (job.points_submitted_from_customer || !job.allowed_to_create)) {
        const missionHasPoints = mission.getSampleSites().length > 0;
        if (missionHasPoints) {
          this.props.addCheckpoint('Generate Plots');
          mission.delete_all_samples_and_cores();
        }
      }
      this.setState({ plotContainerOpen: true, upToDate: false });
    } else {
      this.close();
    }
  }

  validate() {
    const validationMap: ValidationMapResult = {
      density: { error: false, text: '' },
      angle: { error: false, text: '' },
      xCount: { error: false, text: '' },
      yCount: { error: false, text: '' },
    };
    if (undefined === this.state.angle || isNaN(this.state.angle) || this.state.angle < 0 || this.state.angle >= 90) {
      validationMap.angle = { error: true, text: 'Must be between 0 and 90' };
    }
    return validationMap;
  }

  reset() {
    this.setState({
      angle: 0,
      startCorner: 'NW',
      pathDirection: 'N/S',
      shiftNorth: 0,
      shiftEast: 0,
      upToDate: false,
    });
  }

  generatePlot() {
    //Parse inputs
    let { angle, fieldAngle, xCount, yCount, startCorner, pathDirection, shiftNorth, shiftEast, numberOfSamples } =
      this.state;

    const alleyWidthX = ft_to_m(this.state.alleyWidthX);
    const alleyWidthY = ft_to_m(this.state.alleyWidthY);
    let field = new MultiPolygon(this.state.fields.map((f) => f.getGeometry()!.clone()));

    // Create grid of points
    let plots: Polygon[] = [];
    let points: Point[][] = [];

    for (let x = 0; x < xCount; x++) {
      points.push([]);
    }

    // Input Checking
    if (xCount < 1 || isNaN(angle)) {
      this.props.updateGridLayerFeatures([]);
      console.log('Invalid inputs to generation');
      this.setState({ upToDate: true });
      return;
    }

    // ensure angle is in the range [0,90)
    const WRAP_ANGLE = 360;
    if (angle >= WRAP_ANGLE || angle < 0) {
      angle = (angle + WRAP_ANGLE) % WRAP_ANGLE;
    }

    //angle = ((angle * Math.PI / 180) + (Math.PI / 2) % (Math.PI / 2))  // degrees to radians in range [0, PI/2)
    angle = (angle * Math.PI) / 180; // degrees to radians

    fieldAngle = (fieldAngle * Math.PI) / 180; // degrees to radians

    field.rotate(fieldAngle, getCenter(field.getExtent()));

    // plots.push(field.getPolygon(0));

    // Find boundary coordinates
    let extent = field.getExtent();
    let minx = extent[0];
    let miny = extent[1];
    // let maxx = extent[2];
    // let maxy = extent[3];

    const resCenter = getPointResolution('EPSG:3857', 1, getCenter(extent));

    // TODO fix and make a utility function again
    const plotWidthMeters = ft_to_m(this.state.plotWidth);
    const plotHeightMeters = ft_to_m(this.state.plotHeight);
    let plotWidth = plotWidthMeters / resCenter; // (maxx - minx) / xCount;
    let plotHeight = plotHeightMeters / resCenter; // (maxy - miny) / yCount;

    minx = minx - shiftEast;
    miny = miny - shiftNorth;
    // maxx = maxx - shiftEast;
    // maxy = maxy - shiftNorth;

    let pointCount = 0;

    // get all array points except the last one so no duplicates

    // measure field angle difference from normal
    // get extent
    const fieldExtent = field.getExtent();
    const fieldCenter = getCenter(fieldExtent);

    // create polygon from extent
    // TODO For debugging to display the extent
    // const fieldExtentPolygon = new Polygon([[
    //   [fieldExtent[0], fieldExtent[1]],
    //   [fieldExtent[2], fieldExtent[1]],
    //   [fieldExtent[2], fieldExtent[3]],
    //   [fieldExtent[0], fieldExtent[3]],
    //   [fieldExtent[0], fieldExtent[1]]
    // ]]);

    for (let x = 0; x < xCount; x++) {
      for (let y = 0; y < yCount; y++) {
        const _x = pathDirection === 'N/S' ? x : y;
        const _y = pathDirection === 'N/S' ? y : x;
        const point0 = [minx + _x * (plotWidth + alleyWidthX), miny + _y * (plotHeight + alleyWidthY)];
        const point1 = [
          minx + (_x + 1) * (plotWidth + alleyWidthX) - alleyWidthX,
          miny + _y * (plotHeight + alleyWidthY),
        ];
        const point2 = [
          minx + (_x + 1) * (plotWidth + alleyWidthX) - alleyWidthX,
          miny + (_y + 1) * (plotHeight + alleyWidthY) - alleyWidthY,
        ];
        const point3 = [
          minx + _x * (plotWidth + alleyWidthX),
          miny + (_y + 1) * (plotHeight + alleyWidthY) - alleyWidthY,
        ];
        const plotCoordinates: Coordinate[] = [point0, point1, point2, point3, point0];

        let plot = new Polygon([plotCoordinates]);

        // TODO use coordinates instead of extents to improve angle rotation anchor

        plot.rotate(angle, [minx, miny]);

        // check if plot polygon and field polygon intersect
        const plotValid =
          field.intersectsCoordinate(plot.getCoordinates()[0][0]) && //plotCoordinates[0]) &&
          field.intersectsCoordinate(plot.getCoordinates()[0][1]) && //plotCoordinates[1]) &&
          field.intersectsCoordinate(plot.getCoordinates()[0][2]) && //plotCoordinates[2]) &&
          field.intersectsCoordinate(plot.getCoordinates()[0][3]); //plotCoordinates[3]);

        plot.setProperties({ x: x, y: y, valid: plotValid });
        plots.push(plot);

        for (let pointIndex = 0; pointIndex < numberOfSamples; pointIndex++) {
          // Depending on the number of points, distribute them across the length of the plot
          // evently
          // 1 Point
          // +++++o+++++
          // 2 Points
          // +++o+++o+++
          // 3 points
          // ++o++o++o++
          // 4 points
          // o++o++o++o+
          // 5 points
          // +o+o+o+o+o+

          //const pointDistance = (plotExtent[2] - plotExtent[0]) / (numberOfPoints + 1);
          const pointDistance = (point2[1] - point1[1]) / numberOfSamples;
          const pointX = point0[0] + (point1[0] - point0[0]) / 2;
          const pointY = point0[1] + pointDistance / 2 + pointDistance * pointIndex;

          const newPoint = new Point([pointX, pointY]);
          newPoint.rotate(angle, [minx, miny]);

          const pointInBounds = inBounds(newPoint, field);
          newPoint.setProperties({ x, y, id: pointInBounds ? ++pointCount : '', valid: pointInBounds, plotValid });
          points[x].push(newPoint);
        }
      }
      // get rid of empty rows
      if (points[x].length === 0) {
        points.splice(x, 1);
        x--;
        xCount--;
      }
    }

    const validPoints = points.map((pointRow) =>
      pointRow.map((point) => (point.getProperties().valid ? point : null)).filter((point) => point !== null),
    );

    switch (startCorner) {
      case 'SW':
        // do nothing because we always start in the SW
        break;
      case 'NW':
        if (pathDirection === 'N/S') {
          validPoints.forEach((row) => row.reverse());
        } else {
          validPoints.reverse();
        }
        break;
      case 'SE':
        if (pathDirection === 'N/S') {
          validPoints.reverse();
        } else {
          validPoints.forEach((row) => row.reverse());
        }
        break;
      case 'NE':
        validPoints.reverse();
        validPoints.forEach((row) => row.reverse());
        break;
    }

    // reverse every other row to end up with alternating paths
    validPoints.forEach((row, idx) => {
      if (idx % 2 === 1) {
        row.reverse();
      }
    });

    const renumberedPoints = validPoints.flat();
    renumberedPoints.forEach((point, idx) => {
      point.setProperties({ id: idx + 1 });
    });

    // const OFFSET = 3;
    // make the line length match the max plot x/y
    // const xDrawingLine = new LineString([[minx-OFFSET, miny-OFFSET], [maxx, miny-OFFSET]]);
    const plotOriginX1 = [minx, miny];
    const plotOriginX2 = [minx + xCount * (plotWidth + alleyWidthX) + alleyWidthX, miny];
    const plotOriginY1 = [minx, miny];
    const plotOriginY2 = [minx, miny + yCount * (plotHeight + alleyWidthY) + alleyWidthY];
    const xDrawingLine = new LineString([plotOriginX1, plotOriginX2]);
    const yDrawingLine = new LineString([plotOriginY1, plotOriginY2]);

    xDrawingLine.rotate(angle, [minx, miny]);
    xDrawingLine.setProperties({ id: 'Width', rotation: -angle, offsetX: 0, offsetY: 25 });

    // add a line that stretches from the minx,miny to the minx,maxy axis
    // const yDrawingLine = new LineString([[minx-OFFSET, miny-OFFSET], [minx-OFFSET, maxy]]);
    yDrawingLine.rotate(angle, [minx, miny]);
    yDrawingLine.setProperties({ id: 'Length', rotation: -Math.PI / 2 - angle, offsetX: 0, offsetY: -25 });
    const centerPoint = new Point(fieldCenter);
    centerPoint.setProperties({ id: 'Center' });

    // Display results
    this.props.updateGridLayerFeatures([
      ...validPoints.flat(),
      // centerPoint,
      ...plots,
      xDrawingLine,
      yDrawingLine,
      // fieldExtentPolygon,
      // ...pointsChange.map((point, idx) => {
      //   const newPoint = new Point(point);
      //   newPoint.setProperties({ x: 0, y: 0, 'id': idx, 'valid': inBounds(newPoint, field, boundaryDist) });
      //   return newPoint;
      // })
    ]);
    this.setState({ actual: plots.length, plots, upToDate: true });
  }

  async applyGrid() {
    // send coordinates to server
    const points = this.props.gridLayerFeatures
      .map((f, idx) => {
        if (f instanceof Polygon || f instanceof LineString) {
          return null;
        }
        const properties = f.getProperties();
        console.log(properties);
        const id = 'id' in properties ? properties.id : idx + 1;
        const lonlat = toLonLat(f.getCoordinates());
        return { id: id.toString(), coords: { lat: lonlat[1], lon: lonlat[0] } };
      })
      .filter((point) => !!point);
    const mission = getCurrentMission();
    if (!mission) {
      console.error('No mission found');
      return;
    }
    this.props.addCheckpoint('Add Points');
    mission.delete_all_samples_and_cores();
    await mission.update_points(points, false);
    const spec = mission?.getPrimarySpec();
    await spec?.update_parameters({
      angle: (this.state.pathDirection === 'N/S' ? 90 : 0) + this.state.angle,
    });
    const cores = mission
      .getWaypoints()
      .map((waypoint) => waypoint.getCorePoint()?.getSoilCore())
      .filter((core) => !!core);
    // get the corner closest to points[0]
    // calculate the lat and lon distance from points[1] and points[0] and create a point that is "opposite" of points[0]

    const latDiff = points[1].coords.lat - points[0].coords.lat;
    const lonDiff = points[1].coords.lon - points[0].coords.lon;

    await mission.calculate_path(cores, [points[0].coords.lat - latDiff, points[0].coords.lon - lonDiff]);

    if (getCurrentUser()?.customer_boundary_recording) {
      await mission.skipUnsampled('Skip', 'Generated by Plot Generation Tool');
    }

    dispatchMissionUpdated();
    this.close();
  }

  close() {
    // this.reset();
    this.setState({ plotContainerOpen: false });
    this.props.updateGridLayerFeatures([]);
  }

  minimize() {
    const minimized = !this.state.minimized;
    this.setState({ minimized: minimized });
    const minimizableDiv = document.getElementById('minimizable');
    if (minimizableDiv) {
      minimizableDiv.classList.toggle('minimized', minimized);
    }
  }

  render() {
    const showDangerous =
      this.props.mapType === 'maps' ||
      this.props.accessLevel !== 'Locked' ||
      getCurrentUser()?.customer_boundary_recording;

    return (
      <React.Fragment>
        <MapButton
          tooltip="Generate Plot Points"
          onClick={this.toggleContainer}
          toggled={this.state.plotContainerOpen}
          id={'toggleGridContainer'}
        >
          <HiOutlineViewBoards size={20} />
        </MapButton>
        {this.state.plotContainerOpen && (
          <ContrastPaper style={{ position: 'absolute', zIndex: 150 }} className="plotGenerationContainer">
            <FormGroup row>
              <Box pt={1} pl={1}>
                <Typography variant="h5">Generate Plots</Typography>
              </Box>
              <div id="top-buttons">
                <Box pt={1} pl={1} pb={1} id="minimize-button">
                  <Fab
                    size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                    onClick={this.minimize.bind(this)}
                    title="Minimize"
                  >
                    {this.state.minimized ? <MdExpandMore size={26} /> : <MdExpandLess size={26} />}
                  </Fab>
                </Box>
                <Box pt={1} pl={1} pb={1}>
                  {showDangerous && (
                    <Fab
                      size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                      onClick={this.reset.bind(this)}
                      title="Reset"
                    >
                      <ReplayIcon />
                    </Fab>
                  )}
                </Box>
                <Box pt={1} pl={1} pb={1} pr={1}>
                  <Fab
                    size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                    title="Close"
                    onClick={this.close.bind(this)}
                  >
                    <CloseIcon />
                  </Fab>
                </Box>
              </div>
            </FormGroup>
            <div id="minimizable">
              <FormGroup row id="plotGenerationSettings">
                {/* Radio buttons to select coarse or fine adjustments. Changes the various state increments */}
                <Box pt={1} pl={1} className="small-change">
                  <Typography>Small Changes</Typography>
                  <Switch
                    checked={this.state.angleIncrement === 0.1}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      const coarse = !e.target.checked;
                      if (coarse) {
                        this.setState({
                          angleIncrement: 5,
                          shiftIncrement: 5,
                          plotWidthIncrement: 5,
                          plotLengthIncrement: 5,
                        });
                      } else {
                        this.setState({
                          angleIncrement: 0.1,
                          shiftIncrement: 1,
                          plotWidthIncrement: 1,
                          plotLengthIncrement: 1,
                        });
                      }
                    }}
                  />
                </Box>
                <Box pt={1} pl={1} className="corner-and-direction">
                  <Box pt={1} pl={1} className="corner">
                    <FormControl fullWidth>
                      <InputLabel style={{ fontSize: 20 }}>start corner</InputLabel>
                      <Select
                        value={this.state.startCorner}
                        onChange={(e) => {
                          this.setState({ startCorner: e.target.value as StartCornerOption, upToDate: false });
                        }}
                      >
                        <MenuItem value={'NW'}>
                          <Tooltip title={'NW'}>
                            <MdNorthWest style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />
                          </Tooltip>
                        </MenuItem>
                        <MenuItem value={'NE'}>
                          <Tooltip title={'NE'}>
                            <MdNorthEast style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />
                          </Tooltip>
                        </MenuItem>
                        <MenuItem value={'SE'}>
                          <Tooltip title={'SE'}>
                            <MdSouthEast style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />
                          </Tooltip>
                        </MenuItem>
                        <MenuItem value={'SW'}>
                          <Tooltip title={'SW'}>
                            <MdSouthWest style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />
                          </Tooltip>
                        </MenuItem>
                        {/* {StartCornerOptions.map((corner) => <MenuItem value={corner}>{corner}</MenuItem>)} */}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box pt={1} pl={1} pr={1} className="direction">
                    <FormControl fullWidth>
                      <InputLabel style={{ fontSize: 20 }}>path direction</InputLabel>
                      <Select
                        value={this.state.pathDirection}
                        onChange={(e) => {
                          this.setState({
                            pathDirection: e.target.value as PathDirectionOption,
                            xCount: this.state.yCount,
                            yCount: this.state.xCount,
                            upToDate: false,
                          });
                        }}
                      >
                        {/* {PathDirectionOptions.map((dir) => <MenuItem value={dir}>{dir}</MenuItem>)} */}
                        <MenuItem value={'N/S'}>
                          <MdSwapVert style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />
                        </MenuItem>
                        <MenuItem value={'E/W'}>
                          <MdSwapHoriz style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </FormGroup>
              {showDangerous && (
                <>
                  <ValueChangerPair
                    label={'Plots'}
                    value1={this.state.pathDirection === 'N/S' ? this.state.yCount : this.state.xCount}
                    value2={this.state.pathDirection === 'N/S' ? this.state.xCount : this.state.yCount}
                    increment1={this.state.pathDirection === 'N/S' ? this.state.yIncrement : this.state.xIncrement}
                    increment2={this.state.pathDirection === 'N/S' ? this.state.xIncrement : this.state.yIncrement}
                    units={'#'}
                    direction1="L"
                    direction2="W"
                    icon1={<MdViewColumn style={{ transform: `rotate(${90 - this.state.angle}deg)` }} size={32} />}
                    icon2={<MdViewColumn style={{ transform: `rotate(${-this.state.angle}deg)` }} size={32} />}
                    update1={(newValue) => {
                      if (this.state.pathDirection === 'N/S') {
                        this.setState({ yCount: newValue, upToDate: false });
                      } else {
                        this.setState({ xCount: newValue, upToDate: false });
                      }
                    }}
                    update2={(newValue) => {
                      if (this.state.pathDirection === 'N/S') {
                        this.setState({ xCount: newValue, upToDate: false });
                      } else {
                        this.setState({ yCount: newValue, upToDate: false });
                      }
                    }}
                    validate={this.validate}
                    mapType={this.props.mapType}
                  />
                  <ValueChangerPair
                    label={'Plot Size'}
                    value1={this.state.plotHeight}
                    value2={this.state.plotWidth}
                    increment1={this.state.plotLengthIncrement}
                    increment2={this.state.plotWidthIncrement}
                    units={'ft'}
                    direction1="L"
                    direction2="W"
                    // @ts-ignore
                    update1={(newValue) => this.setState({ plotHeight: newValue, upToDate: false })}
                    // @ts-ignore
                    update2={(newValue) => this.setState({ plotWidth: newValue, upToDate: false })}
                    validate={this.validate}
                    mapType={this.props.mapType}
                  />
                </>
              )}
              <ValueChangerPair
                label={'Alley Size'}
                value1={this.state.alleyWidthX}
                value2={this.state.alleyWidthY}
                increment1={this.state.xIncrement}
                increment2={this.state.xIncrement}
                units={'ft'}
                direction1="L"
                direction2="W"
                // @ts-ignore
                update1={(newValue) => this.setState({ alleyWidthX: newValue, upToDate: false })}
                // @ts-ignore
                update2={(newValue) => this.setState({ alleyWidthY: newValue, upToDate: false })}
                validate={this.validate}
                mapType={this.props.mapType}
              />
            </div>
            <div className="dual-unpaired-value-changers">
              <div id="sample-value-changer">
                <ValueChanger
                  label={'Samples'}
                  smallScreenLabelAddition="& Angle"
                  value={this.state.numberOfSamples}
                  increment={1}
                  units={``}
                  update={(newValue) =>
                    this.setState({
                      numberOfSamples: newValue,
                      upToDate: false,
                    })
                  }
                  validate={this.validate}
                  mapType={this.props.mapType}
                />
              </div>
              <div id="angle-value-changer">
                <ValueChanger
                  label={'Angle'}
                  smallScreenLabelClass="smallscreen-angle-label"
                  value={this.state.angle}
                  increment={-this.state.angleIncrement * (this.shiftKeyDown ? 5 : 1)}
                  units={`°`}
                  update={(newValue) =>
                    this.setState({
                      angle: newValue,
                      angleRadians: (newValue * Math.PI) / 180,
                      upToDate: false,
                    })
                  }
                  validate={this.validate}
                  decrementFragment={<RotateLeftIcon />}
                  incrementFragment={<RotateRightIcon />}
                  mapType={this.props.mapType}
                />
              </div>
            </div>
            <FormGroup row className="plotGenerationControls">
              <Box pt={1} pl={2} pb={1} pr={1} className="buttons">
                <Button variant="contained" color="secondary" size="large" onClick={this.applyGrid.bind(this)}>
                  Apply
                </Button>
                <Button
                  variant="contained"
                  id="download-button"
                  // color="primary"
                  // size="large"
                  style={{ width: 5 }}
                  onClick={async () => {
                    // TODO make a separate button?
                    const shapefileBlob = await geometryToShapefile([], this.state.plots);
                    if (!shapefileBlob) {
                      return;
                    }
                    download(shapefileBlob, 'plots.zip', 'application/zip');
                  }}
                >
                  <MdFileDownload size={26} />
                </Button>
              </Box>
              <div className="directionalControls">
                <Tooltip title={this.state.shiftEast}>
                  <Box pt={1} pl={1} pb={1}>
                    <Fab
                      size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                      title="Shift west"
                      onClick={(e) => {
                        this.setState({
                          shiftNorth:
                            this.state.shiftNorth -
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.sin(-this.state.angleRadians),
                          shiftEast:
                            this.state.shiftEast +
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.cos(-this.state.angleRadians),
                          upToDate: false,
                        });
                      }}
                    >
                      <MdWest style={{ transform: `rotate(${-this.state.angle}deg)` }} />
                    </Fab>
                  </Box>
                </Tooltip>
                <Tooltip title={this.state.shiftEast}>
                  <Box pt={1} pl={1} pb={1}>
                    <Fab
                      size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                      title="Shift east"
                      onClick={(e) => {
                        this.setState({
                          shiftNorth:
                            this.state.shiftNorth +
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.sin(-this.state.angleRadians),
                          shiftEast:
                            this.state.shiftEast -
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.cos(-this.state.angleRadians),
                          upToDate: false,
                        });
                      }}
                    >
                      <MdEast style={{ transform: `rotate(${-this.state.angle}deg)` }} />
                    </Fab>
                  </Box>
                </Tooltip>
                <Tooltip title={this.state.shiftNorth}>
                  <Box pt={1} pl={1} pb={1}>
                    <Fab
                      size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                      title="Shift north"
                      onClick={(e) => {
                        this.setState({
                          shiftNorth:
                            this.state.shiftNorth -
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.cos(-this.state.angleRadians),
                          shiftEast:
                            this.state.shiftEast -
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.sin(-this.state.angleRadians),
                          upToDate: false,
                        });
                      }}
                    >
                      <MdNorth style={{ transform: `rotate(${-this.state.angle}deg)` }} />
                    </Fab>
                  </Box>
                </Tooltip>
                <Tooltip title={this.state.shiftNorth}>
                  <Box pt={1} pl={1} pb={1}>
                    <Fab
                      size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                      title="Shift south"
                      onClick={(e) => {
                        this.setState({
                          shiftNorth:
                            this.state.shiftNorth +
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.cos(-this.state.angleRadians),
                          shiftEast:
                            this.state.shiftEast +
                            (this.shiftKeyDown ? 0.1 : 1) *
                              this.state.shiftIncrement *
                              Math.sin(-this.state.angleRadians),
                          upToDate: false,
                        });
                      }}
                    >
                      <MdSouth style={{ transform: `rotate(${-this.state.angle}deg)` }} />
                    </Fab>
                  </Box>
                </Tooltip>
              </div>
            </FormGroup>
          </ContrastPaper>
        )}
      </React.Fragment>
    );
  }
}
