import React, { PureComponent, ReactElement } from 'react';
import {
  Slider,
  Grid,
  Typography,
  Button,
  withStyles,
  DialogContent,
  Dialog,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  TableCell,
} from '@material-ui/core';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';
import { LoadingButton } from '../utils';
import { SetStateAsync, numberToFraction, roundToIncrement, setStateAsync } from '../../utils';

function CustomThumbComponent(props: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

function ValueLabelComponent(props: { children: ReactElement<any, any>; open: boolean; value: number }) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const CustomSlider = withStyles({
  root: {
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    // old value:
    // '&:focus, &:hover, &$active': {
    // TODO the &$active doesn't make sense... so I changed it
    // to &:active
    '&:focus, &:hover, &:active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  track: {
    height: 0,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

export interface SliderButtonCellProps<T extends number | NullableRange | NonNullableRange> {
  min: number;
  max: number;
  step: number;
  labelStep: number;
  value: T | null;
  label: string;
  measurementLabel: string;
  type: string;
  startLabel: string;
  endLabel: string;
  onSave: (newValue: T) => void;
  showQuotient?: boolean;
  extraLabel?: string;
  additionalValue?: any;
  additionalValueLabel?: string;
  onToggleLock?: () => Promise<void>;
  locked?: boolean;
  helperValue?: any;
  helperValueLabel?: string;
  displayHelperValue?: boolean;
  textfieldHelperText?: any;
  pending?: boolean;
  isDepthSetting?: boolean;
  preSave?: (newValue: any, props: SliderButtonCellProps<T>) => void;
}

export type NonNullableRange = [number, number];
export type NullableRange = [number | null, number | null];
export type SliderRange = NonNullableRange | NullableRange;
export type SliderValueOptions = number | SliderRange;
type SliderMark = { value: number; label: string };
interface SliderButtonCellState<T extends SliderValueOptions> {
  marks: SliderMark[];
  // TODO This seems wildly broken, being used as a number and an array and a string maybe?
  // currentValue: number | [number, number] | string;
  currentValue: T | null;
  dialogOpen: boolean;
}

function isRange(value: SliderValueOptions): value is SliderRange {
  return Array.isArray(value);
}

type UpdateValue = null | SliderValueOptions | string | [string, number] | [number, string] | [string, string];

export default class SliderButtonCell<T extends SliderValueOptions> extends PureComponent<
  SliderButtonCellProps<T>,
  SliderButtonCellState<T>
> {
  setStateAsync: SetStateAsync;
  maxSaveTimeout: any;
  parseValue: (value: string) => number;
  updateValue: (value: UpdateValue) => void;

  constructor(props: SliderButtonCellProps<T>) {
    super(props);

    this.state = {
      marks: [],
      currentValue: this.props.value,
      dialogOpen: false,
    };

    //this.updateValue = this.updateValue.bind(this);
    this.saveValue = this.saveValue.bind(this);
    this.resetValue = this.resetValue.bind(this);
    this.isRange = this.isRange.bind(this);
    this.setStateAsync = setStateAsync.bind(this);

    // just set parse value functions once so we don't have to do a lookup
    // every time
    if (this.props.type === 'float') {
      this.parseValue = parseFloat;
    } else {
      this.parseValue = parseInt;
    }

    // If a range type, then set the appropriate state setter function
    if (this.isRange()) {
      this.updateValue = (value: UpdateValue) =>
        this.setState({ currentValue: value ? (this.parseRange(value[0], value[1]) as T) : null });
    } else {
      this.updateValue = (value: UpdateValue) =>
        this.setState({ currentValue: value ? (this.parseValue(value.toString()) as T) : null });
    }

    this.parseRange = this.parseRange.bind(this);

    this.maxSaveTimeout = undefined;
  }

  parseRange(lower: string | number, upper: string | number): [number, number] {
    return [this.parseValue(lower.toString()), this.parseValue(upper.toString())];
  }

  componentDidMount() {
    this.generateMarks();
  }

  componentDidUpdate(prevProps: SliderButtonCellProps<T>) {
    if (!this.isRange() && prevProps.value !== this.props.value) {
      this.setState({ currentValue: this.props.value });
    } else if (this.isRange() && JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({ currentValue: this.props.value });
    }
  }

  async saveValue() {
    let newValue: SliderValueOptions | undefined = undefined;
    if (this.isRange() && this.state.currentValue) {
      const lower = roundToIncrement(this.state.currentValue[0], this.props.step);
      const upper = roundToIncrement(this.state.currentValue[1], this.props.step);
      newValue = [lower, upper];
    } else if (typeof this.state.currentValue === 'number') {
      newValue = roundToIncrement(this.state.currentValue, this.props.step);
    }
    await this.setStateAsync({ currentValue: newValue });
    // TODO OP ACCOUNTABILITY log setting changes
    if (this.props.preSave) {
      this.props.preSave(newValue, this.props);
    }
    // TODO wish I could do this differently...
    this.props.onSave(newValue as T);
  }

  resetValue() {
    this.setState({ currentValue: this.props.value || null, dialogOpen: false });
  }

  generateMarks() {
    const marks: SliderMark[] = [];
    const min = this.props.min;
    const max = this.props.max;
    const step = this.props.labelStep;
    for (let i = min; i <= max; i += step) {
      if (this.props.showQuotient) {
        const quotient = numberToFraction(i);
        marks.push({ value: i, label: quotient });
      } else {
        marks.push({ value: i, label: i.toString() });
      }
    }
    this.setState({ marks });
  }

  isRange() {
    return this.props.value ? isRange(this.props.value) : false;
  }

  render() {
    const rangeEqual =
      this.isRange() &&
      roundToIncrement(this.state.currentValue?.[0], this.props.step) === this.props.value?.[0] &&
      roundToIncrement(this.state.currentValue?.[1], this.props.step) === this.props.value[1];
    const numberEqual =
      typeof this.state.currentValue === 'number' &&
      roundToIncrement(this.state.currentValue, this.props.step) === this.props.value;
    const equal = rangeEqual || numberEqual;
    return (
      <React.Fragment>
        <TableCell
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            textTransform: 'capitalize',
            width: '33.33333333%',
            display: 'inline-block',
            height: '100px',
            verticalAlign: 'text-top',
            color:
              this.props.additionalValue !== undefined && this.props.additionalValue !== this.props.value ? 'red' : '',
          }}
          size={'small'}
          onClick={
            !this.props.locked || this.props.isDepthSetting ? () => this.setState({ dialogOpen: true }) : undefined
          }
        >
          <b>{this.props.label}</b>
          <br />
          <br />
          {this.isRange() ? (
            `${this.props.value?.[0] !== null ? this.props.value?.[0] : '?'} - ${this.props.value?.[1] !== null ? this.props.value?.[1] : '?'} ${this.props.measurementLabel}`
          ) : (
            <React.Fragment>
              {this.props.extraLabel && <b> {this.props.extraLabel}: </b>}
              {`${this.props.value !== null ? this.props.value : '?'} ${this.props.measurementLabel}`}
            </React.Fragment>
          )}
          {this.props.additionalValue !== undefined && (
            <React.Fragment>
              <br />
              {
                <React.Fragment>
                  <Typography style={{ fontSize: 12 }}>
                    <b>{this.props.additionalValueLabel}: </b>
                    {this.props.additionalValue !== null ? this.props.additionalValue : '?'}{' '}
                    {this.props.measurementLabel}
                  </Typography>
                </React.Fragment>
              }
            </React.Fragment>
          )}
          <br />
        </TableCell>
        {this.state.dialogOpen && (
          <Dialog open={true} fullWidth maxWidth={'lg'}>
            <DialogContent>
              <Grid container>
                <Grid item xs={4} style={{ textAlign: 'left', alignSelf: 'center' }}>
                  <Typography>
                    {this.props.label}
                    {this.props.measurementLabel && ` (${this.props.measurementLabel})`}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', alignItems: 'center' }}>
                  {!this.isRange() && (
                    <React.Fragment>
                      <IconButton
                        onClick={() => {
                          // TODO there's probably way to do this, just can't crack it right now...
                          // we can do this because we know this is a number and not a range
                          // @ts-ignore
                          this.setState({ currentValue: this.state.currentValue - this.props.step });
                        }}
                        disabled={this.props.locked}
                      >
                        <RiArrowLeftSLine size={40} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          // TODO there's probably way to do this, just can't crack it right now...
                          // we can do this because we know this is a number and not a range
                          // @ts-ignore
                          this.setState({ currentValue: this.state.currentValue + this.props.step });
                        }}
                        disabled={this.props.locked}
                      >
                        <RiArrowRightSLine size={40} />
                      </IconButton>
                    </React.Fragment>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {this.isRange() ? (
                    <React.Fragment>
                      <TextField
                        value={this.state.currentValue?.[0] === null ? '?' : this.state.currentValue?.[0]}
                        variant={'outlined'}
                        size={'small'}
                        label={this.props.textfieldHelperText ? this.props.textfieldHelperText[0] : ''}
                        type={'number'}
                        style={{ marginTop: 10, alignItems: 'center' }}
                        onChange={(event) => {
                          this.updateValue([event.target.value, this.state.currentValue?.[1]]);
                        }}
                        disabled={this.props.locked}
                      />
                      <TextField
                        value={this.state.currentValue?.[1] === null ? '?' : this.state.currentValue?.[1]}
                        variant={'outlined'}
                        size={'small'}
                        type={'number'}
                        label={this.props.textfieldHelperText ? this.props.textfieldHelperText[1] : ''}
                        style={{ marginTop: 10, alignItems: 'center' }}
                        onChange={(event) => {
                          this.updateValue([this.state.currentValue?.[0], event.target.value]);
                        }}
                        disabled={this.props.locked}
                      />
                    </React.Fragment>
                  ) : (
                    <TextField
                      value={this.state.currentValue === null ? '?' : this.state.currentValue}
                      variant={'outlined'}
                      size={'small'}
                      type={'number'}
                      style={{ marginTop: 10, alignItems: 'center' }}
                      onChange={(event) => {
                        this.updateValue(event.target.value || '');
                      }}
                      disabled={this.props.locked}
                    />
                  )}
                </Grid>

                {this.props.displayHelperValue && (
                  <TextField
                    value={this.props.helperValue}
                    label={this.props.helperValueLabel}
                    variant={'outlined'}
                    size={'small'}
                    type={'number'}
                    disabled={true}
                  />
                )}
              </Grid>
              <Grid container style={{ width: '100%', padding: 10 }} spacing={2}>
                <Grid container direction={'row'}>
                  <Grid item xs={4}>
                    <Typography variant="caption" style={{ marginBottom: 10 }}>
                      {this.props.startLabel}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4} style={{ textAlign: 'right' }}>
                    <Typography variant="caption" style={{ marginBottom: 10 }}>
                      {this.props.endLabel}
                    </Typography>
                  </Grid>
                </Grid>
                <CustomSlider
                  color={'secondary'}
                  marks={this.state.marks}
                  max={this.props.max}
                  min={this.props.min}
                  step={this.props.step}
                  onChange={(_event, value) => this.updateValue(value as SliderValueOptions)}
                  // This component claims it doesn't support nullable numbers
                  // is it possible that the nullable slider range isn't really a possible runtime value?
                  // @ts-ignore
                  value={this.state.currentValue ?? undefined}
                  ThumbComponent={CustomThumbComponent}
                  ValueLabelComponent={ValueLabelComponent}
                  valueLabelDisplay={'off'}
                  disabled={this.props.locked}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                style={{ textAlign: 'right', alignItems: 'center', alignSelf: 'center' }}
                justifyContent={'flex-end'}
              >
                {this.props.isDepthSetting && (
                  <LoadingButton
                    size={'large'}
                    onClick={async () => {
                      if (this.props.onToggleLock) await this.props.onToggleLock();
                      if (this.props.value) this.updateValue(this.props.value);
                    }}
                    style={{ fontSize: 15, textTransform: 'capitalize' }}
                    loading={this.props.pending}
                  >
                    {this.props.locked ? 'Override Mission Depth' : 'Use Mission Depth'}
                  </LoadingButton>
                )}
                <Button
                  style={{
                    color:
                      roundToIncrement(
                        this.state.currentValue && !isRange(this.state.currentValue) ? this.state.currentValue : 0,
                        this.props.step,
                      ) !== this.props.value || this.props.pending
                        ? '#cc0000'
                        : '',
                    fontSize: 15,
                    textTransform: 'capitalize',
                  }}
                  size={'large'}
                  onClick={this.resetValue}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    color: !equal && !this.props.locked ? 'rgb(29, 120, 29)' : '',
                    fontSize: 15,
                    textTransform: 'capitalize',
                  }}
                  size={'large'}
                  onClick={async () => {
                    await this.saveValue();
                    this.setState({ dialogOpen: false });
                  }}
                  disabled={equal || this.props.locked}
                >
                  Apply
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
