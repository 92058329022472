import React, { PropsWithChildren, PureComponent } from 'react';

import { Grid, Paper } from '@material-ui/core';

interface PaperAreaProps {
  fullwidth?: boolean;
}

export default class PaperArea extends PureComponent<PropsWithChildren<PaperAreaProps>> {
  render() {
    return (
      <Grid item xs={this.props.fullwidth ? 12 : 6} sm={this.props.fullwidth ? 12 : 6}>
        <Paper
          style={{
            flex: 1,
            justifyContent: 'center',
            display: 'flex',
            height: 100,
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {this.props.children}
        </Paper>
      </Grid>
    );
  }
}
