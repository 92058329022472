import { PureComponent } from 'react';

import { Button, Grid, GridSize, Typography } from '@material-ui/core';

interface ShowHMIButtonProps {
  toggleHMIVisible: () => void;
  hmiVisible: boolean;
  height?: number;
  width?: string | number;
  minWidth?: string | number;
  fontSize?: number;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
  style?: React.CSSProperties;
}

export default class ShowHMIButton extends PureComponent<ShowHMIButtonProps> {
  render() {
    return (
      <Grid
        item
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        xl={this.props.xl}
        style={this.props.style}
      >
        <Button
          onClick={this.props.toggleHMIVisible}
          style={{ minWidth: this.props.minWidth }}
          size="large"
          variant="outlined"
        >
          <Typography style={{ fontSize: 14 }}>{this.props.hmiVisible ? 'Hide HMI' : 'Show HMI'}</Typography>
        </Button>
      </Grid>
    );
  }
}
