import React, { PureComponent } from 'react';

interface MapProgressBarProps {
  total?: number;
  filled?: number;
  marginTop: number;
  preText?: string;
  marginRight?: number;
}

interface MapProgressBarState {
  barSize: number;
  blocks: JSX.Element[];
}

const DEFAULT_MARGIN_RIGHT = 10;

export default class MapProgressBar extends PureComponent<MapProgressBarProps, MapProgressBarState> {
  DEFAULT_BAR_SIZE = 200;

  constructor(props: MapProgressBarProps) {
    super(props);

    this.state = {
      blocks: [],
      barSize: this.DEFAULT_BAR_SIZE,
    };

    this.generateBar = this.generateBar.bind(this);
  }

  componentDidMount() {
    this.generateBar();
    window.addEventListener('resize', this.generateBar);
  }

  componentDidUpdate(prevProps: MapProgressBarProps) {
    if (prevProps.total !== this.props.total || prevProps.filled !== this.props.filled) {
      this.generateBar();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.generateBar);
  }

  generateBar() {
    const innerWidth = window.innerWidth;
    let barSize = this.DEFAULT_BAR_SIZE;
    if (this.DEFAULT_BAR_SIZE / innerWidth > 1 / 3) {
      barSize = innerWidth / 3;
      this.setState({ barSize });
    } else if (barSize !== this.DEFAULT_BAR_SIZE) {
      this.setState({ barSize: this.DEFAULT_BAR_SIZE });
    }
    if (!this.props.total || !this.props.filled) {
      return;
    }
    const blocks: JSX.Element[] = [];
    for (let i = 0; i < this.props.total; i++) {
      const blockSize = barSize / this.props.total;
      let endBorder = {};
      if (i === this.props.filled - 1 && i !== this.props.total - 1) {
        endBorder = {
          borderRightColor: '#EEEEEE',
          borderRightWidth: 0.5,
          borderRightStyle: 'solid',
        };
      }
      const block = (
        <div
          key={i}
          style={{
            margin: 0,
            height: 16,
            width: blockSize,
            backgroundColor: this.props.filled <= i ? 'rgba(255,255,255,.3)' : 'rgb(39, 64, 82, .5)',
            display: 'inline-block',
            padding: 0,
            ...endBorder,
          }}
        />
      );
      blocks.push(block);
    }

    this.setState({ blocks });
  }

  render() {
    return (
      <div
        style={{
          margin: 0,
          position: 'absolute',
          minWidth: 100,
          right: this.props.marginRight || DEFAULT_MARGIN_RIGHT,
          maxWidth: this.state.barSize,
          marginTop: this.props.marginTop,
          zIndex: 1,
          borderColor: '#EEEEEE',
          borderWidth: 1,
          borderStyle: 'solid',
          height: 20,
          padding: 0,
          display: 'flex',
        }}
      >
        {this.state.blocks}
        {typeof this.props.total !== 'undefined' && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              fontSize: '20px',
              lineHeight: 0.9,
              padding: 0,
              color: '#FFFFFF',
              textShadow: '#000 2px 2px 7px',
            }}
          >
            {`${this.props.preText || ''} ${this.props.filled}/${this.props.total} `}
          </div>
        )}
      </div>
    );
  }
}
