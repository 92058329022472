import { PropsWithChildren, PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from '@material-ui/core';

interface MapLoadingButtonProps {
  onClick: () => Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  size?: 'small' | 'large';
  tooltip?: string;
}

interface MapLoadingButtonState {
  loading: boolean;
}

export default class MapLoadingButton extends PureComponent<
  PropsWithChildren<MapLoadingButtonProps>,
  MapLoadingButtonState
> {
  mounted: boolean;

  constructor(props: MapLoadingButtonProps) {
    super(props);
    this.state = {
      loading: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onClick() {
    this.setState({ loading: true }, async () => {
      try {
        await this.props.onClick();
      } catch (e) {
        // https://stackoverflow.com/questions/42754270/re-throwing-exception-in-nodejs-and-not-losing-stack-trace
        const stack = new Error('Errors should be caught in the click handler, not propagated to the button.').stack;
        if (e && typeof e === 'object' && 'stack' in e) {
          e.stack = e.stack + '\nFrom previous: ' + stack?.split('\n').slice(0, 2).join('\n') + '\n';
        }
        throw e;
      } finally {
        if (this.mounted) {
          this.setState({ loading: false });
        }
      }
    });
  }

  render() {
    const { onClick, loading, ...props } = this.props;
    return (
      <Tooltip title={this.props.tooltip || ''}>
        <Button
          {...props}
          onClick={this.onClick}
          className={'mapbutton'}
          disabled={loading || this.state.loading || props.disabled}
        >
          {loading || this.state.loading ? (
            <CircularProgress size={this.props.size === 'small' ? '18px' : '20px'} />
          ) : (
            this.props.children
          )}
        </Button>
      </Tooltip>
    );
  }
}
