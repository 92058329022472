import React, { PureComponent } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import { Grid } from '@material-ui/core';

import { LoadingButton } from '../../utils';
import { SampleBox } from '../../../db';

interface CloseBoxButtonProps {
  openBoxExitInterview: (box?: SampleBox) => Promise<void>;
  disabled?: boolean;
}

export default class CloseBoxButton extends PureComponent<CloseBoxButtonProps> {
  render() {
    return (
      <Grid item>
        <LoadingButton
          variant="outlined"
          disabled={this.props.disabled}
          style={{
            color: this.props.disabled ? 'grey' : '#cc0000',
            borderColor: this.props.disabled ? 'grey' : '#cc0000',
          }}
          onClick={this.props.openBoxExitInterview}
        >
          <CloseIcon style={{ paddingRight: 7.5 }} /> Close Box
        </LoadingButton>
      </Grid>
    );
  }
}
