import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
//import DirectionsIcon from '@material-ui/icons/Directions';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
//import IconButton from '@material-ui/core/IconButton';

import { distanceLatLon, formatDirectionsUrl, round } from '../../../utils';

import logger from '../../../logger';

import { LatLonSource } from '../../../types/types';
import AirtableRecord from '../../../db/AirtableRecordClass';
import { Mission } from '../../../db';
import { alertFullScreen, alertWarn, alertWarnConfirm } from '../../../alertDispatcher';
import MissionDirections from '../../mission/common/MissionDirections';
import { IconButton, Tooltip } from '@material-ui/core';
import { FaReceipt } from 'react-icons/fa';

const styles = (theme) => ({
  doneRoot: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  doneButton: {
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  partialRoot: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  partialButton: {
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  },
});

interface SampleJobProps {
  id: string;
  job: AirtableRecord;
  currentLat: number;
  currentLon: number;
  missionActive: string;
  order: number;
  loadMissionSchedule: (id: string) => Promise<void>;
  showNavMessage: (directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) => void;
  name: string;
  classes: any;
  showSampled: boolean;
}

// TODO not sure if EVERYTHING needs to be undefined... could probably come up with more reasonable default values
interface SampleJobState {
  ready?: boolean;
  pullLat?: number;
  pullLon?: number;
  numSamples?: number;
  boundAcres?: number;
  done?: boolean;
  partial?: boolean;
  distance?: number;
  skipped?: boolean;
  jobId?: string;
  latLonSource?: LatLonSource;
  directionsLink?: string;
  offlineSync: boolean;
}

class SampleJob extends PureComponent<SampleJobProps, SampleJobState> {
  constructor(props: SampleJobProps) {
    super(props);
    this.state = {
      ready: undefined,
      pullLat: undefined,
      pullLon: undefined,
      numSamples: undefined,
      boundAcres: undefined,
      done: undefined,
      partial: undefined,
      distance: undefined,
      skipped: undefined,
      jobId: undefined,
      latLonSource: undefined,
      directionsLink: undefined,
      offlineSync: false,
    };
  }

  async componentDidMount() {
    await this.initJobInfo();
  }

  async componentDidUpdate(prevProps: SampleJobProps) {
    const lat = this.props.job.get('Lat') as number;
    const lon = this.props.job.get('Lon') as number;
    this.setState({
      distance: round(distanceLatLon(lat, lon, this.props.currentLat, this.props.currentLon)),
      directionsLink: formatDirectionsUrl(lat, lon),
      latLonSource: this.props.job.get('Lat Lon Source') as LatLonSource,
      pullLat: lat,
      pullLon: lon,
    });

    if (prevProps.id !== this.props.id) {
      await this.initJobInfo();
    }
  }

  async initJobInfo() {
    if (!this.state.ready) {
      await this.checkAttachmentsReady();
    }

    const jobId = this.props.job.get('Rogo Job ID');
    const missions = Mission.query((mission: Mission) => mission.job_id === jobId);
    if (missions.length > 1) {
      logger.log('INIT_JOB_INFO', `More than one mission found for job ${jobId} (${missions.length})`);
    }
    const mission = missions[0];
    if (mission) {
      console.log('Got a mission!');
    }
    this.setState({
      jobId,
      numSamples: this.props.job.get('# Samples') as number,
      boundAcres: round((this.props.job.get('Boundary Acres') as number) || 0, 1),
      done: Boolean(this.props.job.get('Sample Date') || this.props.job.get('Offline Synced')),
      partial: this.props.job.fields['Partial Mission'],
      skipped: this.props.job.get('Skipped by Operator Time') as boolean,
      offlineSync: (this.props.job.get('Offline Synced') as boolean) || false,
    });
  }

  async checkAttachmentsReady() {
    if (this.props.job.attachments_up_to_date()) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
      await this.props.job.refresh_attachments();
      this.setState({ ready: true });
    }
  }

  render() {
    const classes = this.state.done
      ? {
          root: this.props.classes.doneRoot,
          button: this.props.classes.doneButton,
        }
      : this.state.partial
        ? {
            root: this.props.classes.partialRoot,
            button: this.props.classes.partialButton,
          }
        : undefined;

    const aMissionIsLoaded = Boolean(this.props.missionActive);
    const thisMissionLoaded = this.props.missionActive === this.props.id;
    const onMobile = window.innerWidth < 600;
    let headerText = thisMissionLoaded ? '>>> ' : '';
    headerText += onMobile
      ? `${this.props.name.substring(0, 30)}${this.props.name.length > 30 ? '...' : ''}`
      : this.props.name;
    headerText += thisMissionLoaded ? ' <<<' : '';

    const jobNotes = this.props.job.get('Instructions for Field #ops');
    return (
      <React.Fragment>
        {(this.props.showSampled || (!this.state.done && !this.state.skipped) || this.state.offlineSync) && (
          <ListItem
            disabled={!this.state.ready}
            key={this.props.id}
            classes={classes}
            divider
            button
            data-testid={`job-browser-${this.props.order}`}
            onClick={
              !this.state.done || this.state.offlineSync
                ? async () => {
                    if (Boolean(this.props.missionActive)) {
                      alertWarn("You can't load another mission while you have one open");
                      return;
                    }
                    await this.props.loadMissionSchedule(this.props.id);
                  }
                : undefined
            }
          >
            <ListItemIcon>
              <MissionDirections
                jobId={this.props.id}
                thisMissionIsLoaded={thisMissionLoaded}
                showNavMessage={this.props.showNavMessage}
              />
              <Tooltip title={!jobNotes ? 'No Job Notes Available' : 'Job Notes'} placement="top">
                <span>
                  <IconButton
                    disabled={!jobNotes}
                    color="secondary"
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (aMissionIsLoaded && !thisMissionLoaded) {
                        const shouldLoad = await alertWarnConfirm(
                          "These notes aren't for the currently loaded mission. Are you sure you want to view the job notes?",
                        );
                        if (!shouldLoad) {
                          return;
                        }
                      }
                      await alertFullScreen('Job Notes', jobNotes, ['Close']);
                    }}
                  >
                    <FaReceipt fontSize={32} />
                  </IconButton>
                </span>
              </Tooltip>
              {/* <IconButton disabled={!this.state.pullLat || !this.state.pullLon} color="secondary" onClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();
              // await this.props.job.refresh();
              // try {
              //   await this.props.job.refresh();
              // } catch (err) {
              //   console.error(err);
              //   alertWarn("Unable to refresh job data");
              // }
              //logger.log('ACTION', `Selected Directions: ${this.state.directionsLink}`);
              // const today = (new Date()).toISOString().substring(0, 10);
              // const notificationTimeString = this.props.job.get('Operator En Route');
              // const notificationDate = notificationTimeString ? (new Date(notificationTimeString)).toISOString().substring(0, 10) : undefined;
              // const jobTextAlreadySentToday = notificationDate && notificationDate === today;
              // const operatorInGrowerTextBeta = getCurrentSession()?.getUser()?.user_flags?.includes('Grower Text Beta');
              // const jobInGrowerTextBeta = getCurrentMission()?.getJob()?.job_flags?.includes('Grower Text Beta');
              // const jobShouldBeNotified = (jobInGrowerTextBeta || operatorInGrowerTextBeta) && !jobTextAlreadySentToday;

              // // if the job shouldn't be notified, and we are confident that we have good directions...
              // if (!jobShouldBeNotified && this.state.latLonSource === 'NearestRoad') {
              //   const link = document.createElement('a');
              //   link.href = this.state.directionsLink;
              //   link.target = "_blank";
              //   document.body.appendChild(link);
              //   link.click();
              //   document.body.removeChild(link);
              // } else {
              //   this.props.showNavMessage(this.state.directionsLink, this.state.latLonSource, this.props.job.instance_id);
              // }
              this.props.showNavMessage(this.state.directionsLink, this.state.latLonSource, this.props.job.instance_id);
            }} >
              <DirectionsIcon fontSize="large" />
            </IconButton> */}
            </ListItemIcon>
            <ListItemIcon>{this.state.offlineSync ? <CheckBoxOutlined fontSize="large" /> : ''}</ListItemIcon>
            <ListItemText
              // primaryTypographyProps={{ variant: thisMissionLoaded ? 'h6' : "inherit" }}
              primary={headerText}
              style={{ overflow: 'hidden', fontWeight: thisMissionLoaded ? 'bolder' : 'normal' }}
            />
            <Box flexDirection={'column'} minWidth={100} style={{ paddingLeft: 20 }}>
              <ListItemText
                primary={
                  <Typography>
                    <b>{this.state.numSamples}</b> samples, <b>{this.state.boundAcres}</b> ac.
                  </Typography>
                }
                secondary={
                  <Typography>
                    <b>{this.state.distance || 'N/A'}</b> mi.
                  </Typography>
                }
              />
            </Box>
          </ListItem>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SampleJob);
