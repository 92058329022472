import { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';
import EventBus from '../../../EventBus';
import { getCurrentSession } from '../../../dataModelHelpers';
import { convertProjection4329 } from '../../../utils';
import { calculateArmPosition } from '../../../services/RobotArmService';
import { Coordinate } from 'ol/coordinate';
import { Sample, Waypoint } from '../../../db';
import {
  DistanceAheadMetersStorage,
  MapCalculationPositionStorage,
  MapDebugArmPosition,
  MapDebugEventBus,
  MapDebugExtent,
  MapDebugStorage,
  MapExtentStorage,
  MapResolutionStorage,
  MapRotationStorage,
  MapSizeStorage,
} from '../../../db/local_storage';
import { LineWithDistance } from '../features/line_with_distance';
import { dispatchSetCenter, dispatchSetRotation } from '../../../mapEvents';
import getConnection from '../../../RobotConnection';

interface RobotLocationLayerProps {
  rotateMapWithRobot: boolean;
  centerMapOnRobot: boolean;
  presentSample: Sample | undefined;
  closeSample: Sample | undefined;
  currentWaypoint: Waypoint | undefined;
  useArmOrigin: boolean;
  targetCoordinate: Coordinate | undefined;
}

interface RobotLocationLayerState {
  robotPosition?: Coordinate;
}

function distanceAhead() {
  const resolution = MapResolutionStorage.get();
  const size = MapSizeStorage.get();
  // calculate the distance for the height
  // Get the short dimension
  if (!size) {
    return DistanceAheadMetersStorage.get();
  }
  const totalHeight = size[1] * resolution;
  const halfTotalHeight = totalHeight / 2;
  const robotTarget = 0.9 * halfTotalHeight;

  return Math.min(DistanceAheadMetersStorage.get(), robotTarget);
}

export default class RobotLocationLayer extends PureComponent<RobotLocationLayerProps, RobotLocationLayerState> {
  AVG_WINDOW = 5;
  headings?: number[];
  lastZ: number;
  positionTimeout?: NodeJS.Timeout;

  constructor(props: RobotLocationLayerProps) {
    super(props);
    this.state = {
      robotPosition: undefined,
    };
    this.headings = undefined;
    this.lastZ = 0;
    this.positionTimeout = undefined;
    this.handlePosition = this.handlePosition.bind(this);
  }

  componentDidMount() {
    EventBus.on('ROSMSG/position', this.handlePosition);
  }

  componentWillUnmount() {
    EventBus.remove('ROSMSG/position', this.handlePosition);
    clearTimeout(this.positionTimeout);
    // this.props.setRotation({ value: 0 });
    MapRotationStorage.set({ value: 0 });
    // this.props.setRobotPosition([]); // maybe null is okay here?
    this.headings = undefined;
  }

  componentDidUpdate(prevProps: RobotLocationLayerProps, prevState: RobotLocationLayerState) {
    const centering = this.props.centerMapOnRobot;
    const justStartedRotating = !prevProps.rotateMapWithRobot && this.props.rotateMapWithRobot;
    const justStoppedRotating = prevProps.rotateMapWithRobot && !this.props.rotateMapWithRobot;
    if (justStartedRotating && centering) {
      this.setMapRotation(this.lastZ, this.state.robotPosition);
    } else if (justStoppedRotating) {
      this.setMapRotation(Math.PI / 2, this.state.robotPosition);
    }

    const justStartedCentering = !prevProps.centerMapOnRobot && centering;
    const haveValidPosition = this.state.robotPosition && this.state.robotPosition.length > 1;
    if (justStartedCentering && haveValidPosition) {
      if (this.props.rotateMapWithRobot) {
        this.setMapRotation(this.lastZ, this.state.robotPosition);
      } else {
        this.setMapRotation(Math.PI / 2, this.state.robotPosition);
      }
    }

    const justStoppedCentering = prevProps.centerMapOnRobot && !centering;
    if (justStoppedCentering) {
      this.setMapRotation(Math.PI / 2, this.state.robotPosition);
      this.headings = undefined;
    }

    if (prevState.robotPosition?.length && !haveValidPosition) {
      this.headings = undefined;
    }
  }

  setMapRotation(z: number, center?: number[]) {
    const viewHeading = z - Math.PI / 2;
    const lastViewHeading = -this.lastZ - Math.PI / 2;
    if (!this.headings || viewHeading - lastViewHeading > -6) {
      this.headings = new Array<number>(this.AVG_WINDOW).fill(viewHeading);
    }
    this.headings.shift();
    this.headings.push(viewHeading);

    const rotation = this.headings.reduce((acc, cur) => acc + cur) / this.headings.length;
    MapRotationStorage.set({ value: rotation });
    dispatchSetRotation(rotation);
    // if (center && !!this.state.robotPosition) {
    //   const centerAhead = [
    //     this.state.robotPosition[0] + Math.cos(-this.lastZ) * distanceAhead(),
    //     this.state.robotPosition[1] - Math.sin(-this.lastZ) * distanceAhead()
    //   ];
    //   MapCenterStorage.set(centerAhead);
    //   dispatchSetCenter(centerAhead);
    // }
  }

  handlePosition({ hostname, msg }) {
    //console.log('RobotLocationLayer', hostname, msg);
    const session = getCurrentSession();
    if (hostname !== session?.robot_hostname) {
      return;
    }

    clearTimeout(this.positionTimeout);
    const coordinates = convertProjection4329([msg.y, msg.x]);
    // offset the x/y coordinates by the core arm offset (meters)
    // which would be affected by the robot's heading
    // dx = 0.2302
    // dy = 0.9652

    if (JSON.stringify(coordinates) !== JSON.stringify(this.state.robotPosition)) {
      // this.props.setRobotPosition(coordinates);
      this.setState({ robotPosition: coordinates });
    }
    this.lastZ = msg.z;
    // this.props.setHeading(msg.z * -1.0);
    // this.setState({ robotRotation: (msg.z * -1.0) });
    // this.props.setRotation({ value: msg.z * -1.0 });

    if (!this.props.centerMapOnRobot) {
      // do nothing to center
      // MapRotationStorage.set({ value: 0 });
    } else {
      if (this.state.robotPosition) {
        dispatchSetCenter(this.state.robotPosition);
      }
      if (this.props.rotateMapWithRobot) {
        this.setMapRotation(msg.z, coordinates);
      }
    }

    this.positionTimeout = setTimeout(() => {
      MapRotationStorage.set({ value: 0 });
      this.setState({ robotPosition: undefined });
    }, 2000);
  }

  render() {
    if (!this.state.robotPosition) {
      return null;
    }

    let originPosition = this.state.robotPosition;
    let armPosition: Coordinate | undefined = undefined;

    const robotHeading = -this.lastZ;
    const robotPosition = this.state.robotPosition;
    const useArmOrigin = MapCalculationPositionStorage.get() === 'Arm' || this.props.useArmOrigin;
    // const originPosition = useArmOrigin ? armPosition : robotPosition;
    armPosition = calculateArmPosition(robotPosition, robotHeading);

    if (useArmOrigin) {
      originPosition = armPosition;
    }

    let { targetCoordinate } = this.props;

    if (targetCoordinate) {
      targetCoordinate = convertProjection4329([targetCoordinate[1], targetCoordinate[0]]);
    }

    const mapDebug = MapDebugStorage.get();
    const mapDebugEventBus = MapDebugEventBus.get();
    const mapDebugArmPosition = MapDebugArmPosition.get();
    const extent = MapExtentStorage.get();

    let debugItems: string[] = [
      `Close Sample: ${this.props.closeSample?.sample_id || 'None'}`,
      `Present Sample: ${this.props.presentSample?.sample_id || 'None'}`,
    ];

    if (mapDebugEventBus) {
      debugItems = debugItems.concat([
        // `Robot Angle: ${roundToIncrement(radiansToDegrees(robotHeading), 0.0001).toString()}°`,
        // `Robot->Core (ft): ${roundToIncrement(robotToCoreDistanceFt, 0.0001)} ft`,
        // `Adjustment Angle: ${roundToIncrement(radiansToDegrees(robotNavigationAngle), 0.0001)}°`,
        // `Meters Per Pixel: ${roundToIncrement(metersPerPixel, 0.0001)}`,
        // `Center Resolution: ${roundToIncrement(resCenter, 0.0001)}`,
        // `Zoom: ${roundToIncrement(zoom, 0.01)}`,
        // `Robot Position: ${roundToIncrement(robotPosition[0], 0.01)},${roundToIncrement(robotPosition[1], 0.01)}`,
        // `Arm Position: ${roundToIncrement(armPosition[0], 0.01)},${roundToIncrement(armPosition[1], 0.01)}`,
        // `Robot to Arm Distance: ${roundToIncrement(distance(armPosition, robotPosition) * FEET_PER_METER, 0.01)} ft`,
        `Event Bus Msg Hz: ${Math.round(EventBus.stats().messageFrequency)}`,
        `Robot Connection Hz: ${Math.round(
          getConnection(getCurrentSession()?.robot_hostname || '')?.counter.getAverageHz() || 0,
        )}`,
        // `Res Center: ${roundToIncrement(resCenter, 0.0001)}`,
      ]);
    }

    return (
      // @ts-ignore
      <layer.Vector zIndex={2}>
        {/* 
        // @ts-ignore */}
        <source.VectorSourceReact>
          {robotPosition?.length > 1 && robotPosition[0] !== 0 && robotPosition[1] !== 0 && (
            <>
              {/* Draw points at each extent corner */}
              {MapDebugExtent.get() && (
                <>
                  <feature.PointReact
                    coordinate={[extent[0], extent[1]]}
                    circleOptions={{
                      radius: 100,
                      strokeOptions: { color: 'black', width: 2 },
                    }}
                    textOptions={{
                      text: `extent[0]: ${Math.round(extent[0])}\nextent[1]: ${Math.round(extent[1])}`,
                      font: '24px Calibri,sans-serif',
                      fillOptions: { color: 'white' },
                      offsetX: 200,
                      offsetY: -100,
                    }}
                  />
                  <feature.PointReact
                    coordinate={[extent[0], extent[3]]}
                    circleOptions={{
                      radius: 100,
                      strokeOptions: { color: 'black', width: 2 },
                    }}
                    textOptions={{
                      text: `extent[0]: ${Math.round(extent[0])}\nextent[3]: ${Math.round(extent[3])}`,
                      font: '24px Calibri,sans-serif',
                      fillOptions: { color: 'white' },
                      offsetX: 200,
                      offsetY: 100,
                    }}
                  />
                  <feature.PointReact
                    coordinate={[extent[2], extent[1]]}
                    circleOptions={{
                      radius: 100,
                      strokeOptions: { color: 'black', width: 2 },
                    }}
                    textOptions={{
                      text: `extent[2]: ${Math.round(extent[2])}\nextent[1]: ${Math.round(extent[1])}`,
                      font: '24px Calibri,sans-serif',
                      fillOptions: { color: 'white' },
                      offsetX: -200,
                      offsetY: -100,
                    }}
                  />
                  <feature.PointReact
                    coordinate={[extent[2], extent[3]]}
                    circleOptions={{
                      radius: 100,
                      strokeOptions: { color: 'black', width: 2 },
                    }}
                    textOptions={{
                      text: `extent[2]: ${Math.round(extent[2])}\nextent[3]: ${Math.round(extent[3])}`,
                      font: '24px Calibri,sans-serif',
                      fillOptions: { color: 'white' },
                      offsetX: -200,
                      offsetY: 100,
                    }}
                  />
                </>
              )}

              <feature.PointReact
                coordinate={!useArmOrigin || mapDebugArmPosition ? this.state.robotPosition : originPosition}
                iconOptions={{
                  rotation: this.props.rotateMapWithRobot && this.props.centerMapOnRobot ? -Math.PI / 2 : robotHeading,
                  src: './images/location2.png',
                  scale: 0.25,
                }}
                textOptions={{
                  text: mapDebug ? debugItems.join('\n') : '',
                  font: '24px Calibri,sans-serif',
                  fillOptions: { color: 'white' },
                  offsetX: -120,
                  offsetY: 120,
                }}
              />

              {/* <feature.PointReact
                coordinate={robotPosition}
                iconOptions={{
                  rotation: this.state.robotRotation,
                  src: './images/location2.png',
                  zIndex: 1000,
                  scale: 0.05
                }} /> */}

              {mapDebugArmPosition && (
                <feature.PointReact
                  coordinate={armPosition}
                  hideAtZoom={20}
                  circleOptions={{
                    // displacement: [10, 5],
                    radius: 5,
                    strokeOptions: { color: 'yellow', width: 2 },
                  }}
                />
              )}

              {mapDebug && targetCoordinate && (
                <LineWithDistance coordinates={[originPosition, targetCoordinate]} color="red" />
              )}

              {/* Draw a circle that shows the tolerance for the current sample */}
              {/* {(ManualDriveAidStorage.get() && targetCoordinates) && (
                <MeasuredCircle
                  center={targetCoordinates}
                  color='rgba(255, 0, 0, 0.5)' 
                  innerRadiusMeters={presentToleranceMeters}
                  outerRadiusMeters={presentToleranceMeters + CORE_DEADBAND_WIDTH_M}
                  pixelsPerMeter={pixelsPerMeter}
                />
              )} */}

              {/* {(targetCoordinates) && (
                <feature.PointReact
                  coordinate={targetCoordinates}
                  circleOptions={{
                    // radius: Math.max(1, (robotToCoreDistance / metersPerPixel) - 9),
                    // radius: robotToCoreDistance / metersPerPixel / resCenter,
                    radius: metersToPixels(presentToleranceMeters + (CORE_DEADBAND_WIDTH_M / 2)),
                    strokeOptions: { 
                      // red transparent
                      color: 'rgba(255, 0, 0, 0.5)',
                      width: metersToPixels(CORE_DEADBAND_WIDTH_M)
                    },
                  }}
                  zIndex={-1}
                  // iconOptions={{
                  //   src: './images/bullseye.png',
                  //   scale: 20 * 0.018 * metersPerPixel,
                  //   color: 'black',
                  // }}
                  hideAtZoom={14}
                />
              )} */}

              {/* TODO debugging aid: Draw a line of the projection of the robot path based on the current heading */}
            </>
          )}
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
