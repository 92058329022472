import React, { PureComponent } from 'react';

import { TableCell, TextField } from '@material-ui/core';

interface InfoPanelCellProps {
  label: string;
  value: string | number | null | boolean;
}

export default class InfoPanelCell extends PureComponent<InfoPanelCellProps> {
  render() {
    return (
      <TableCell width={'25%'}>
        <TextField
          label={this.props.label}
          variant={'outlined'}
          value={this.props.value === null ? '?' : this.props.value}
          disabled={true}
          inputProps={{
            readOnly: true,
          }}
        />
      </TableCell>
    );
  }
}
