import React, { PureComponent } from 'react';

import { Typography } from '@material-ui/core';

import { getCurrentMission } from '../../../dataModelHelpers';

import EventBus from '../../../EventBus';
import { MISSION_EVENTS } from '../../../missionEvents';

interface MissionHeaderProps {}

interface MissionHeaderState {
  missionName: string;
}

export default class MissionHeader extends PureComponent<MissionHeaderProps, MissionHeaderState> {
  constructor(props: MissionHeaderProps) {
    super(props);

    this.state = {
      missionName: '',
    };

    this.getMissionName = this.getMissionName.bind(this);
  }

  componentDidMount() {
    this.getMissionName();
    EventBus.on(MISSION_EVENTS.UPDATED, this.getMissionName);
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.UPDATED, this.getMissionName);
  }

  getMissionName() {
    const mission = getCurrentMission();
    this.setState({ missionName: mission?.name || '' });
  }

  render() {
    return (
      <Typography variant="h5" data-testid="mission-info-name" noWrap={false} style={{ display: 'inline' }}>
        {this.state.missionName}
      </Typography>
    );
  }
}
