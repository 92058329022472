import { ChangeEvent, PureComponent } from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  Divider,
  Tooltip,
  TextField,
} from '@material-ui/core';
import { loadRecoveryZip } from '../../dataModelHelpers';
import { OnLoading } from '../../types/types';
import logger from '../../logger';
import { alertError } from '../../alertDispatcher';
import React from 'react';
import { FaRegFolderOpen } from 'react-icons/fa';
import utvIcon from '../../assets/img/utv.png';
import phoneIcon from '../../assets/img/phone.png';
import {
  OldRecoveryFileList,
  RecoveryFileListResponse,
  RecoveryFileMetadata,
  getRecoveryFile,
  getRecoveryList,
  isRecoveryServerAlive,
} from '../../recoveryServerUtils';
import { ROBOT_IP } from '../../constants';

export interface RecoveryLoadingPopupProps {
  onClose: (b: boolean) => void;
  onLoading: OnLoading;
  devMode: boolean;
  login?: (username: string, password: string, alreadyHashed: boolean) => Promise<void>;
}

export interface RecoveryLoadingPopupState {
  recoveryServerAlive: boolean;
  recoveryFiles: RecoveryFileMetadata[];
  recoveryCount: number;
  inputValue: string;
}

const DEFAULT_RECOVERY_COUNT = 4;

export default class RecoveryLoadingPopup extends PureComponent<RecoveryLoadingPopupProps, RecoveryLoadingPopupState> {
  recoveryInput: React.RefObject<HTMLInputElement>;
  constructor(props: RecoveryLoadingPopupProps) {
    super(props);

    this.state = {
      recoveryServerAlive: false,
      recoveryFiles: [],
      recoveryCount: DEFAULT_RECOVERY_COUNT,
      inputValue: '',
    };

    this.recoveryInput = React.createRef();
  }

  isOldRecoveryList = (files: RecoveryFileListResponse | OldRecoveryFileList): files is OldRecoveryFileList => {
    return (
      files &&
      'recovery_list' in files &&
      Array.isArray(files.recovery_list) &&
      typeof files.recovery_list[0] === 'string'
    );
  };

  componentDidUpdate = async (prevProps: RecoveryLoadingPopupProps, prevState: RecoveryLoadingPopupState) => {
    if (prevState.recoveryCount !== this.state.recoveryCount) {
      await this.loadRecoveryList();
    }
  };

  componentDidMount = async () => {
    await this.loadRecoveryList();
  };

  loadRecoveryList = async () => {
    const recoveryServerAlive = await isRecoveryServerAlive();
    if (recoveryServerAlive) {
      const files = await getRecoveryList({ count: this.state.recoveryCount });
      if (!files) return [];
      if (this.isOldRecoveryList(files)) {
        this.setState({
          recoveryServerAlive,
          recoveryFiles: files.recovery_list.map((file: string) => {
            const fileSplit = file.split('/');
            return {
              filename: fileSplit[fileSplit.length - 1],
            };
          }),
        });
        return files.recovery_list;
      }
      this.setState({ recoveryServerAlive, recoveryFiles: files.recovery_list });
      return files.recovery_list;
    } else {
      this.setState({ recoveryServerAlive });
      return [];
    }
  };

  loadRecovery = async (backIndex: number) => {
    return await this.props.onLoading(async () => {
      try {
        const recoveryFile = await getRecoveryFile({ backIndex });

        await logger.log(
          'HANDLE_LOAD_RECOVERY',
          `Recovery Popup: Load Recovery Server (${backIndex}) ${recoveryFile.name}`,
        );

        const user = await loadRecoveryZip(recoveryFile, true);

        const username = user ? user.name : 'guest';
        const password = user ? user.hashed_password : 'password';
        if (this.props.login) {
          await this.props.login(username, password, password === 'password' ? true : false);
        }

        this.props.onClose(true);
      } catch (err) {
        alertError(`Unable to retrieve recovery file ${backIndex}`);
      }
    });
  };

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    // update the state to reflect the search value
    this.setState({ inputValue: event.target.value });

    // use the immediate input value to search for recovery files
    this.handleSearchRecoveryFiles(event.target.value);
  };

  handleSearchRecoveryFiles = async (inputValue: string) => {
    // Implement the logic to search recovery files using fastapi recoverylist endpoint
    try {
      const searchResults = await this.searchRecoveryFiles(inputValue);
      // Update the state with the search results
      this.setState({ recoveryFiles: searchResults });
    } catch (error) {
      console.error('Error searching recovery files:', error);
      // Handle the error
    }
  };

  async searchRecoveryFiles(inputValue: string) {
    const files = await this.loadRecoveryList();
    const searchResults = files.filter((file) => file.filename.includes(inputValue));
    return searchResults;
  }

  render() {
    const showMoreFiles = this.state.recoveryFiles.length !== DEFAULT_RECOVERY_COUNT;
    return (
      <>
        <Dialog open={true} maxWidth={'lg'}>
          <DialogTitle>
            <>
              <b>Select Recovery File</b>
              <TextField
                style={{ marginLeft: 10, width: '220px' }}
                required
                value={this.state.inputValue}
                onChange={this.handleInputChange}
                // Add adornment of x button to clear input
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={() => {
                        this.setState({ inputValue: '' });
                        this.handleSearchRecoveryFiles(this.state.inputValue);
                      }}
                      disabled={!this.state.inputValue}
                    >
                      X
                    </Button>
                  ),
                }}
              />
              {/* <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={this.handleSearchRecoveryFiles}
              >
                <FaSearch />
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={this.loadRecoveryList}
              >
                Reset
              </Button> */}
            </>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={1} style={{ flexDirection: 'row', display: 'flex', flex: 1 }}></Grid>
          </DialogContent>

          <DialogActions style={{ padding: 10, display: 'block' }}>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
              }}
            >
              {this.state.recoveryServerAlive &&
                this.state.recoveryFiles.map((file, index) => (
                  <Grid
                    item
                    container
                    style={{
                      margin: 10,
                    }}
                  >
                    <Grid item>
                      <Button
                        style={{ marginRight: 10 }}
                        variant="outlined"
                        onClick={async () => await this.loadRecovery(index)}
                      >
                        {index === 0 ? <b>Load</b> : 'Load'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <img
                        style={{
                          marginRight: 10,
                          height: '40px',
                          pointerEvents: 'none',
                        }}
                        src={file.ip === ROBOT_IP || file.ip === '192.168.122.175' ? utvIcon : phoneIcon}
                        alt="source"
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={<span style={{ whiteSpace: 'pre-line' }}>{JSON.stringify(file, null, 2)}</span>}
                        placement="top"
                        arrow
                      >
                        <Typography>
                          {index === 0 ? <b>{file.filename.substring(0, 30)}</b> : file.filename.substring(0, 30)}
                          <br />
                          {!!file?.filled_samples && !!file?.total_samples
                            ? `${file.filled_samples} / ${file.total_samples} samples complete`
                            : ''}
                          {showMoreFiles && !!file?.box_count !== undefined ? (
                            <>
                              <br />
                              {file.box_count} boxes
                            </>
                          ) : (
                            ''
                          )}
                          {showMoreFiles && !!file?.open_box !== undefined ? (
                            <>
                              <br />
                              Open Box: {file?.open_box}
                            </>
                          ) : (
                            ''
                          )}
                          <Divider role="presentation" />
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}

              <Grid
                item
                style={{
                  margin: 10,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item>
                    <Button variant="outlined" component="label">
                      <input
                        type="file"
                        accept=".zip"
                        style={{ display: 'none' }}
                        ref={this.recoveryInput}
                        multiple={false}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                          // first load recovery file
                          await this.props.onLoading(async () => {
                            try {
                              const uploadFile = event.target.files![0];
                              await logger.log(
                                'HANDLE_LOAD_RECOVERY',
                                `Recovery Popup: Uploaded recovery file ${uploadFile.name}`,
                              );
                              if (!uploadFile) {
                                throw new Error('Error processing file: No file uploaded');
                              }

                              const user = await loadRecoveryZip(uploadFile, true);

                              if (this.props.login) {
                                const username = user ? user.name : 'guest';
                                const password = user ? user.hashed_password : 'password';
                                await this.props.login(username, password, password === 'password' ? true : false);
                              }

                              this.props.onClose(true);
                            } catch (err) {
                              if (typeof err === 'string') {
                                alertError(err);
                              } else if (
                                err &&
                                typeof err === 'object' &&
                                'message' in err &&
                                typeof err.message === 'string'
                              ) {
                                alertError(err.message);
                              }
                            }
                          });
                        }}
                        data-testid="recovery-upload-browse"
                      />
                      <FaRegFolderOpen style={{ marginRight: 7.5 }} />
                      Browse...
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        this.setState({ recoveryCount: this.state.recoveryCount + 10 });
                        // if (this.state.recoveryCount === DEFAULT_RECOVERY_COUNT) {
                        //   this.setState({ recoveryCount: 20 })
                        // } else {
                        //   this.setState({ recoveryCount: DEFAULT_RECOVERY_COUNT })
                        // }
                      }}
                    >
                      {/* {this.state.recoveryCount === DEFAULT_RECOVERY_COUNT ? 'Show More' : 'Show Less'} */}
                      Load More...
                    </Button>
                  </Grid>
                  {/* <Grid item>
                    <Button
                      variant='outlined'
                      onClick={() => { this.setState({ showBoxes: !this.state.showBoxes }) }}
                    >
                      {!this.state.showBoxes ? 'Show Boxes' : 'Hide Boxes'}
                    </Button>
                  </Grid> */}
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        this.props.onClose(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
