import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import EventBus from '../../EventBus';
import { getCurrentMission, getCurrentSession } from '../../dataModelHelpers';
import SliderButtonCell, { NonNullableRange, NullableRange, SliderButtonCellProps } from './SliderButtonCell';
import MissionSync from './MissionSync';
import {
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core';
import {
  SetStateAsync,
  convertDepthOffset,
  convertToPercent,
  mmToEigthInch,
  mpsToMph,
  roundToIncrement,
  setStateAsync,
  updateState,
} from '../../utils';
import SetWaypoint from './SetWaypoint';
import { LoadingButton, SwitchButton } from '../utils';
import { alertSuccess } from '../../alertDispatcher';
import InfoPanelCell from './InfoPanelCell';
import { MM_PER_INCH, MPS_TO_MPH } from '../../constants';
import getConnection, { dispatchRosMsgPub } from '../../RobotConnection';
import { ProbeSelection, SettingsAccessLevel, SetWaypointFunction } from '../../types/types';
import { RobotControlRole, RosMissionMsg } from '../../types/rosmsg';
import missionControlEventLogger from '../../logger';
import DEFAULT_PROBE_CONFIG from './configs/ProbeConfig';
import { isMobile } from 'react-device-detect';
import HMIButton from '../mission/buttons/HMIButton';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { Coordinate } from 'ol/coordinate';
import { sendROSConfig } from '../../utilities/rosUtils';

interface RobotPanelProps {
  setDepth: (depth: number) => void;
  syncMission: () => void;
  setWaypoint: SetWaypointFunction;
  updateAutoSync: (autoSync: boolean) => Promise<void>;
  toggleTrackingOverride: () => void;
  trackingOverride: boolean;
  syncInProgress: boolean;
  robotMission?: RosMissionMsg;
  outOfSync: boolean;
  missionDepth?: number;
  drillDepth?: number;
  depthLocked: boolean;
  lastSyncTimestamp?: string;
  autoSync: boolean;
  devMode: boolean;
  connectionStatus: string;
  elevatePermissions: () => void;
  accessLevel: SettingsAccessLevel;
  toggleHMIVisible: () => void;
  hmiVisible: boolean;
}

interface RobotPanelState {
  maxSpeed: number | null;
  drillDepthOffset: number | null;
  dwellTime: number | null;
  last_sample: string;
  bigMotorEnabled: number | null;
  tipToGroundDistance: number | null;
  carriageDownSpeed: number | null;
  carriageUpSpeed: number | null;
  rotationSpeed: number | null;
  dwellAtTopTime: number | null;
  dccMaxDeadband: number | null;
  dccMinDeadband: number | null;
  cdnMinDeadband: number | null;
  cdnMaxDeadband: number | null;
  cupMaxDeadband: number | null;
  cupMinDeadband: number | null;
  dcwMaxDeadband: number | null;
  dcwMinDeadband: number | null;
  brakeSafetyCheck: boolean;
  speedSafetyCheck: boolean;
  depthLockedPending: boolean;
  coreTimeout: number | null;
  thDeadband: number | null;
  brDeadband: number | null;
  thMaxPos: number | null;
  thMinPos: number | null;
  brMaxPos: number | null;
  brMinPos: number | null;
  stMaxPwm: number | null;
  stMinPwm: number | null;
  depthArrivalTarget: number | null;
  safeProx: number | null;
  dumpProx: number | null;
  doorProx: number | null;
  bucketProx: number | null;
  estopCut: number | null;
  seatbeltOn: number | null;
  showSensors: boolean;
  pressureOk: boolean | null;
  thLive: number | null;
  brLive: number | null;
  carriageLive: number | null;
  implementSelected: ProbeSelection;
  robotControlRole: RobotControlRole;
  waitingForRoleChange: boolean;
}

export default class RobotPanel extends PureComponent<RobotPanelProps, RobotPanelState> {
  setStateAsync: SetStateAsync;

  CONFIG_MESSAGES: { [topic: string]: [(value) => void] } = {};

  SLIDER_PARAMS: {
    [label: string]: [
      min: number,
      max: number,
      step: number,
      labelStep: number,
      type: string,
      measurementLabel: string,
      startLabel: string,
      endLabel: string,
    ];
  } = {
    'Max Speed': [0, 20, 1, 2, 'int', 'mph', 'slower', 'faster'],
    'Dwell At Top Time': [0, 2000, 100, 200, 'int', 'ms', 'shorter', 'longer'],
    'Core Depth Offset': [-2, 2, 0.125, 0.5, 'float', 'in', 'shallower', 'deeper'],
    'Dwell Time': [0, 2000, 10, 200, 'int', 'ms', 'shorter', 'longer'],
    'Core Timeout': [0, 40000, 100, 10000, 'int', 'ms', 'shorter', 'longer'],
    'Core Depth': [0, 12, 0.1, 1, 'float', 'in', 'shallower', 'deeper'],
    'Tip To Ground Distance': [0, 10, 0.125, 1, 'float', 'in', 'closer', 'further'],
    'Carriage Down Speed': [0, 100, 1, 10, 'int', '%', 'closer', 'further'],
    'Carriage Up Speed': [0, 100, 1, 10, 'int', '%', 'closer', 'further'],
    'Big Motor Up Deadband': [50, 100, 0.01, 10, 'float', '%', 'lower', 'higher'],
    'Big Motor Down Deadband': [0, 50, 0.01, 10, 'float', '%', 'lower', 'higher'],
    'St Pwm': [0, 65535, 1, 20000, 'int', '', 'lower', 'higher'],
    'Carriage Up Deadband': [50, 100, 0.01, 10, 'float', '%', 'lower', 'higher'],
    'Carriage Down Deadband': [0, 50, 0.01, 10, 'float', '%', 'lower', 'higher'],
    'Depth Arrival Tolerance': [0, 100, 1, 10, 'int', 'mm', 'shorter', 'longer'],
    'Throttle Deadband': [0, 65535, 1, 20000, 'int', '', 'smaller', 'larger'],
    'Brake Deadband': [0, 65535, 1, 20000, 'int', '', 'smaller', 'larger'],
    'Th Pos': [0, 65535, 1, 20000, 'int', '', 'lower', 'higher'],
    'Br Pos': [0, 65535, 1, 20000, 'int', '', 'lower', 'higher'],
    'Big Motor Sensitivity': [0, 100, 1, 10, 'int', 'in/s', 'lower', 'higher'],
  };

  INITIAL_STATE = {
    maxSpeed: null,
    drillDepthOffset: null,
    dwellTime: null,
    last_sample: '?',
    bigMotorEnabled: null,
    tipToGroundDistance: null,
    carriageDownSpeed: null,
    carriageUpSpeed: null,
    rotationSpeed: null,
    dwellAtTopTime: null,
    dccMaxDeadband: null,
    dccMinDeadband: null,
    cdnMinDeadband: null,
    cdnMaxDeadband: null,
    cupMaxDeadband: null,
    cupMinDeadband: null,
    dcwMaxDeadband: null,
    dcwMinDeadband: null,
    brakeSafetyCheck: false,
    speedSafetyCheck: false,
    depthLockedPending: false,
    coreTimeout: null,
    thDeadband: null,
    brDeadband: null,
    thMaxPos: null,
    thMinPos: null,
    brMaxPos: null,
    brMinPos: null,
    stMaxPwm: null,
    stMinPwm: null,
    depthArrivalTarget: null,
    thLive: null,
    brLive: null,
    carriageLive: null,
    pressureOk: null,
    seatbeltOn: null,
    safeProx: null,
    dumpProx: null,
    bucketProx: null,
    doorProx: null,
    estopCut: null,
    showSensors: false,
    implementSelected: '' as ProbeSelection,
  };

  constructor(props: RobotPanelProps) {
    super(props);
    console.log(`RobotPanel constructor`);
    this.state = {
      ...this.INITIAL_STATE,
      // TODO this is some ugliness...
      robotControlRole: getCurrentSession()?.robot_hostname
        ? getConnection(getCurrentSession()?.robot_hostname ?? '')?.robotControlRole || 'view'
        : 'view',
      waitingForRoleChange: false,
    };
    this.updateDepthOffsetSetting = this.updateDepthOffsetSetting.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.getSliderParams = this.getSliderParams.bind(this);

    this.setDefault = this.setDefault.bind(this);
    this.setDepthOffset = this.setDepthOffset.bind(this);
    this.setDwell = this.setDwell.bind(this);
    this.setSpeed = this.setSpeed.bind(this);
    this.setDepthLock = this.setDepthLock.bind(this);
    this.setTipToGround = this.setTipToGround.bind(this);
    this.setImplementSelected = this.setImplementSelected.bind(this);
    this.setCarriageDownSpeed = this.setCarriageDownSpeed.bind(this);
    this.setCarriageUpSpeed = this.setCarriageUpSpeed.bind(this);
    this.setBigMotorEnabled = this.setBigMotorEnabled.bind(this);
    this.setRotationSpeed = this.setRotationSpeed.bind(this);
    this.setDwellAtTopTime = this.setDwellAtTopTime.bind(this);
    this.setDccDeadband = this.setDccDeadband.bind(this);
    this.setDcwDeadband = this.setDcwDeadband.bind(this);
    this.setCupDeadband = this.setCupDeadband.bind(this);
    this.setCdnDeadband = this.setCdnDeadband.bind(this);
    this.setBrakeSafetyCheck = this.setBrakeSafetyCheck.bind(this);
    this.setSpeedSafetyCheck = this.setSpeedSafetyCheck.bind(this);
    this.setCoreTimeout = this.setCoreTimeout.bind(this);
    this.setDepthArrivalTarget = this.setDepthArrivalTarget.bind(this);
    this.setThDeadband = this.setThDeadband.bind(this);
    this.setBrDeadband = this.setBrDeadband.bind(this);
    this.setThPos = this.setThPos.bind(this);
    this.setBrPos = this.setBrPos.bind(this);
    this.setStPwm = this.setStPwm.bind(this);
    this.resetState = this.resetState.bind(this);
    this.clearLastSample = this.clearLastSample.bind(this);
    this.setShowSensors = this.setShowSensors.bind(this);
    this.resetSensors = this.resetSensors.bind(this);
    this.updateRobotControlRole = this.updateRobotControlRole.bind(this);

    this.CONFIG_MESSAGES = {
      // we have to paramterize and declare the temporary functions here so that they are persistent and can be used
      // as unique identifiers when we need to remove them from the EventBus
      'ROSMSG/mission/prev/sample_id': [(event) => this.updateValue(event, 'last_sample')],
      'ROSMSG/config/depth_offset': [this.updateDepthOffsetSetting],
      'ROSMSG/config/dwell_time': [(event) => this.updateValue(event, 'dwellTime')],
      'ROSMSG/config/max_speed': [(event) => this.updateValue(event, 'maxSpeed', mpsToMph)],
      'ROSMSG/config/tip_to_ground': [(event) => this.updateValue(event, 'tipToGroundDistance', mmToEigthInch)],
      'ROSMSG/config/carriage_up_speed': [(event) => this.updateValue(event, 'carriageUpSpeed', Math.round)],
      'ROSMSG/config/carriage_down_speed': [(event) => this.updateValue(event, 'carriageDownSpeed', Math.round)],
      'ROSMSG/config/implement_selected': [(event) => this.updateValue(event, 'implementSelected')],
      'ROSMSG/config/big_motor': [(event) => this.updateValue(event, 'bigMotorEnabled')],
      'ROSMSG/config/rotation_speed_setting': [(event) => this.updateValue(event, 'rotationSpeed', Math.round)],
      'ROSMSG/config/dwell_at_top_time': [(event) => this.updateValue(event, 'dwellAtTopTime', Math.round)],
      'ROSMSG/config/dcc_max': [(event) => this.updateValue(event, 'dccMaxDeadband', convertToPercent)],
      'ROSMSG/config/dcc_min': [(event) => this.updateValue(event, 'dccMinDeadband', convertToPercent)],
      'ROSMSG/config/dcw_max': [(event) => this.updateValue(event, 'dcwMaxDeadband', convertToPercent)],
      'ROSMSG/config/dcw_min': [(event) => this.updateValue(event, 'dcwMinDeadband', convertToPercent)],
      'ROSMSG/config/cdn_max': [(event) => this.updateValue(event, 'cdnMaxDeadband', convertToPercent)],
      'ROSMSG/config/cdn_min': [(event) => this.updateValue(event, 'cdnMinDeadband', convertToPercent)],
      'ROSMSG/config/cup_max': [(event) => this.updateValue(event, 'cupMaxDeadband', convertToPercent)],
      'ROSMSG/config/cup_min': [(event) => this.updateValue(event, 'cupMinDeadband', convertToPercent)],
      'ROSMSG/config/brake_safety_check': [(event) => this.updateValue(event, 'brakeSafetyCheck')],
      'ROSMSG/config/speed_safety_check': [(event) => this.updateValue(event, 'speedSafetyCheck')],
      'ROSMSG/config/core_timeout': [(event) => this.updateValue(event, 'coreTimeout')],
      'ROSMSG/config/depth_arrival_target': [(event) => this.updateValue(event, 'depthArrivalTarget')],
      'ROSMSG/config/th_deadband': [(event) => this.updateValue(event, 'thDeadband')],
      'ROSMSG/config/br_deadband': [(event) => this.updateValue(event, 'brDeadband')],
      'ROSMSG/config/th_max_pos': [(event) => this.updateValue(event, 'thMaxPos')],
      'ROSMSG/config/th_min_pos': [(event) => this.updateValue(event, 'thMinPos')],
      'ROSMSG/config/br_max_pos': [(event) => this.updateValue(event, 'brMaxPos')],
      'ROSMSG/config/br_min_pos': [(event) => this.updateValue(event, 'brMinPos')],
      'ROSMSG/config/st_max_pwm': [(event) => this.updateValue(event, 'stMaxPwm')],
      'ROSMSG/config/st_min_pwm': [(event) => this.updateValue(event, 'stMinPwm')],
      'ROSMSG/config/th_position': [(event) => this.updateVisSensors(event, 'thLive')],
      'ROSMSG/config/br_position': [(event) => this.updateVisSensors(event, 'brLive')],
      'ROSMSG/config/carriage_position': [(event) => this.updateVisSensors(event, 'carriageLive')],
      'ROSMSG/config/send_vis_sensors': [(event) => this.updateValue(event, 'showSensors')],
      'ROSMSG/vis/safe_prox_sensor': [(event) => this.updateVisSensors(event, 'safeProx')],
      'ROSMSG/vis/dump_prox_sensor': [(event) => this.updateVisSensors(event, 'dumpProx')],
      'ROSMSG/vis/door_prox_sensor': [(event) => this.updateVisSensors(event, 'doorProx')],
      'ROSMSG/vis/bucket_prox_sensor': [(event) => this.updateVisSensors(event, 'bucketProx')],
      'ROSMSG/vis/seatbelt_sensor': [(event) => this.updateVisSensors(event, 'seatbeltOn')],
      'ROSMSG/vis/pressure_active_sensor': [(event) => this.updateVisSensors(event, 'pressureOk')],
      'ROSMSG/vis/estop_cut': [(event) => this.updateVisSensors(event, 'estopCut')],
    };

    this.sliderChangeListener = this.sliderChangeListener.bind(this);

    this.setStateAsync = setStateAsync.bind(this);
  }

  componentDidMount() {
    // console.log(`RobotPanel componentDidMount`);
    for (const message of Object.keys(this.CONFIG_MESSAGES)) {
      EventBus.on(message, this.CONFIG_MESSAGES[message][0], true);
    }
    EventBus.on('ROSRESET', this.resetState);
    EventBus.on('ROBOT_ROLE_UPDATED', this.updateRobotControlRole);
  }

  componentWillUnmount() {
    for (const message of Object.keys(this.CONFIG_MESSAGES)) {
      EventBus.remove(message, this.CONFIG_MESSAGES[message][0]);
    }
    EventBus.remove('ROSRESET', this.resetState);
    EventBus.remove('ROBOT_ROLE_UPDATED', this.updateRobotControlRole);
  }

  componentDidUpdate(prevProps: RobotPanelProps) {
    if (this.props.connectionStatus === 'disconnected' && prevProps.connectionStatus === 'connected') {
      this.clearLastSample();
    }

    // const desiredAccessLevel: SettingsAccessLevel = !getLocalStorageDateExpired('devMode') ? 'Unprotected' : 'Locked';
    // console.log(`${desiredAccessLevel} ${this.props.accessLevel}`);
    // if (this.props.accessLevel !== desiredAccessLevel) {
    //   this.setState({ accessLevel: desiredAccessLevel });
    // }
  }

  getSliderParams(label: string) {
    const rawParams = this.SLIDER_PARAMS[label];
    return {
      label: label,
      min: rawParams[0],
      max: rawParams[1],
      step: rawParams[2],
      labelStep: rawParams[3],
      type: rawParams[4],
      measurementLabel: rawParams[5],
      startLabel: rawParams[6],
      endLabel: rawParams[7],
      preSave: this.sliderChangeListener,
      devMode: this.props.accessLevel !== 'Technician',
    } as const;
  }

  async updateRobotControlRole(role: RobotControlRole) {
    console.log(`ROSMSGPEER`, `updateRobotControlRole(${role})`);
    this.setState({
      robotControlRole: role,
      waitingForRoleChange: false,
    });
    // await this.props.updateAutoSync(role === 'control');
  }

  clearLastSample() {
    this.setState({
      last_sample: '?',
    });
  }

  resetState() {
    this.setState({ ...this.INITIAL_STATE });
  }

  async sliderChangeListener<T extends number | [number, number]>(newValue: T, props: SliderButtonCellProps<T>) {
    const { label } = props;
    await this.settingsChangeListener(label, newValue);
  }

  async settingsChangeListener(label: string, newValue: number | [number, number] | boolean | ProbeSelection) {
    const timestamp = new Date().toISOString();
    const settingsChange = [label, newValue, timestamp];
    await missionControlEventLogger.log('SETTINGS_CHANGE', settingsChange);
    const mission = getCurrentMission();
    if (mission) {
      const currentSettings: any[][] = JSON.parse(mission.settings_changes || '[]') || [];
      currentSettings.push(settingsChange);
      mission.settings_changes = JSON.stringify(currentSettings);
    }
  }

  setDefault() {
    const config = DEFAULT_PROBE_CONFIG;
    this.setCarriageDownSpeed(config['carriageDownSpeed']);
    this.setCarriageUpSpeed(config['carriageUpSpeed']);
    this.setSpeedSafetyCheck(config['speedSafetyCheck']);
    this.setBrakeSafetyCheck(config['driveSafetyCheck']);
    this.setSpeed(config['maxSpeed']);
    this.setDepthArrivalTarget(config['depthArrivalTolerance']);
    this.setCoreTimeout(config['coreTimeout']);
    this.setDwell(config['dwellTime']);
    this.setDepthOffset(config['depthOffset']);
    this.setDwellAtTopTime(config['dwellAtTopTime']);
  }

  setSpeed(speed: number) {
    this.setValue('max_speed', speed, 'Max Speed', 'maxSpeed', true, (x: number) => x / MPS_TO_MPH);
  }

  setTipToGround(distance: number) {
    this.setValue('tip_to_ground', distance, 'tip to ground distance', 'tipToGroundDistance', true, (x: number) =>
      Math.round(distance * MM_PER_INCH),
    );
  }

  setBigMotorEnabled(enabled: number) {
    this.setValue('big_motor', enabled, 'big motor', 'bigMotorEnabled', false);
  }

  setImplementSelected(implement: ProbeSelection) {
    this.setValue('implement_selected', implement, 'implement', 'implementSelected', true);
  }

  setRotationSpeed(speedPercent: number) {
    this.setValue(
      'rotation_speed',
      speedPercent,
      'rotation speed',
      'rotationSpeed',
      true,
      undefined,
      'config/rotation_speed_setting',
    );
  }

  setCarriageDownSpeed(speedPercent: number) {
    this.setValue('carriage_down_speed', speedPercent, 'carriage down speed', 'carriageDownSpeed', true);
  }

  setCarriageUpSpeed(speedPercent: number) {
    this.setValue('carriage_up_speed', speedPercent, 'carriage up speed', 'carriageDownSpeed', true);
  }

  setDepthOffset(offset: number) {
    this.setValue('depth_offset', offset, 'depth offset', 'drillDepthOffset', true, convertDepthOffset);
  }

  async setDepthLock(depthLocked: boolean) {
    const session = getCurrentSession();

    await this.setStateAsync({ depthLockedPending: true });

    dispatchRosMsgPub({
      hostname: session?.robot_hostname ?? '',
      msg: { data: depthLocked },
      topic: 'config/set_override_depth',
      tag: this.constructor.name,
      expectationParams: {
        expectedTopic: 'config/override_depth',
        messages: {
          success: depthLocked
            ? 'Successfully did an override of the mission depth!'
            : 'Successfully started using mission depth!',
          error: depthLocked ? 'Failed to override mission depth!' : 'Failed to start using mission depth!',
        },
        doneCallback: (_received: boolean, _dataCorrect: boolean) => this.setState({ depthLockedPending: false }),
      },
    });
  }

  setDwell(dwellTime: number) {
    this.setValue('dwell_time', dwellTime, 'dwell time', 'dwellTime', true, Math.round);
  }

  setDwellAtTopTime(dwellTime: number) {
    // TODO This does an extra check for undefined... I think this used to be part of
    // the defults which is handled differently now and we don't have to do this
    // if (dwellTime !== undefined && dwellTime !== this.state.dwellAtTopTime) {
    this.setValue('dwell_at_top_time', dwellTime, 'dwell at top time', 'dwellAtTopTime', true);
  }

  setThDeadband(deadband: number) {
    this.setValue('th_deadband', deadband, 'throttle deadband', 'thDeadband', false);
  }

  setBrDeadband(deadband: number) {
    this.setValue('br_deadband', deadband, 'brake deadband', 'brDeadband', false);
  }

  setThPos(pos: NullableRange) {
    this.setRange('th_min_pos', 'th_max_pos', pos, 'throttle pos', 'thMinPos', 'thMaxPos', true);
  }

  setBrPos(pos: NullableRange) {
    this.setRange('br_min_pos', 'br_max_pos', pos, 'brake pos', 'brMinPos', 'brMaxPos', true);
  }

  setStPwm(pwm: NullableRange) {
    this.setRange('st_min_pwm', 'st_max_pwm', pwm, 'pwm for steering', 'stMinPwm', 'stMaxPwm', true);
  }

  setDccDeadband(deadband: NullableRange) {
    this.setPercentageRange(
      'dcc_min',
      'dcc_max',
      deadband,
      'deadband for big motor up deadband',
      'dccMinDeadband',
      'dccMaxDeadband',
      true,
    );
  }

  setDcwDeadband(deadband: NullableRange) {
    this.setPercentageRange(
      'dcw_min',
      'dcw_max',
      deadband,
      'deadband for big motor down deadband',
      'dcwMinDeadband',
      'dcwMaxDeadband',
      true,
    );
  }

  setCupDeadband(deadband: NullableRange) {
    this.setPercentageRange(
      'cup_min',
      'cup_max',
      deadband,
      'deadband for carriage up',
      'cupMinDeadband',
      'cupMaxDeadband',
      false,
    );
  }

  setCdnDeadband(deadband: NullableRange) {
    this.setPercentageRange(
      'cdn_min',
      'cdn_max',
      deadband,
      'deadband for carriage down',
      'cdnMinDeadband',
      'cdnMaxDeadband',
      false,
    );
  }

  setBrakeSafetyCheck(selectedCheck?: boolean) {
    let check = selectedCheck;
    if (check === undefined) {
      check = !this.state.brakeSafetyCheck;
    }
    if (check !== this.state.brakeSafetyCheck) {
      const session = getCurrentSession();
      dispatchRosMsgPub({
        topic: 'config/set_brake_safety_check',
        hostname: session?.robot_hostname ?? '',
        msg: { data: check },
        tag: this.constructor.name,
        expectationParams: {
          expectedTopic: 'config/brake_safety_check',
          messages: {
            success: `Successfully changed override drive controller safety override to be ${check ? 'true' : 'false'}!`,
            error: `Failed to changed override drive controller override to be ${check ? 'true' : 'false'}!`,
          },
        },
      });
    } else {
      alertSuccess(`Drive controller override already set to ${check}`);
    }
  }

  setSpeedSafetyCheck(selectedCheck?: boolean) {
    let check = selectedCheck;
    if (check === undefined) {
      check = !this.state.speedSafetyCheck;
    }

    if (check !== this.state.speedSafetyCheck) {
      const session = getCurrentSession();
      dispatchRosMsgPub({
        topic: 'config/set_speed_safety_check',
        hostname: session?.robot_hostname ?? '',
        msg: { data: check },
        tag: this.constructor.name,
        expectationParams: {
          expectedTopic: 'config/speed_safety_check',
          messages: {
            success: `Successfully changed override speed safety override to be ${check ? 'true' : 'false'}!`,
            error: `Failed to changed override speed safety override to be ${check ? 'true' : 'false'}!`,
          },
        },
      });
    } else {
      alertSuccess(`Speed safety override is already set to ${check}!`);
    }
  }

  setCoreTimeout(timeout: number) {
    if (timeout !== this.state.coreTimeout) {
      const session = getCurrentSession();
      const conversion = Math.round(timeout);
      dispatchRosMsgPub({
        hostname: session?.robot_hostname ?? '',
        msg: { data: conversion },
        topic: 'config/set_core_timeout',
        tag: this.constructor.name,
        expectationParams: {
          expectedTopic: 'config/core_timeout',
          messages: {
            success: `Successfully set core timeout from ${this.state.coreTimeout} to ${timeout}!`,
            error: `Failed to set core timeout from ${this.state.coreTimeout} to ${timeout}!`,
          },
        },
      });
    } else {
      alertSuccess(`Core timeout already set to ${timeout}!`);
    }
  }

  setDepthArrivalTarget(target: number) {
    this.setValue('depth_arrival_target', target, 'depth arrival tolerance', 'depthArrivalTarget', true);
  }

  setShowSensors(show: boolean) {
    console.log(`RobotPanel: setShowSensors(${show})`);
    const session = getCurrentSession();
    dispatchRosMsgPub({
      hostname: session?.robot_hostname ?? '',
      msg: { data: show },
      topic: 'config/set_send_vis_sensors',
      tag: this.constructor.name,
      expectationParams: {
        expectedTopic: 'config/send_vis_sensors',
        messages: {
          success: `Successfully turned ${show ? 'on' : 'off'} show vis sensors!`,
          error: `Failed to turn ${show ? 'on' : 'off'} show vis sensors!`,
        },
        doneCallback: (success) => {
          if (success && !show) {
            this.resetSensors();
          }
        },
      },
    });
  }

  setPercentageRange<T extends NullableRange | NonNullableRange>(
    rosMinTopic: string,
    rosMaxTopic: string,
    newValue: T,
    label: string,
    minKey: keyof RobotPanelState,
    maxKey: keyof RobotPanelState,
    checkBeforeSet: boolean,
  ) {
    const convertPercentage = (newValue: number) => Math.round((newValue / 100) * 65535);
    this.setRange(rosMinTopic, rosMaxTopic, newValue, label, minKey, maxKey, checkBeforeSet, convertPercentage);
  }

  setRange<T extends NullableRange | NonNullableRange>(
    rosMinTopic: string,
    rosMaxTopic: string,
    newValue: T,
    label: string,
    minKey: keyof RobotPanelState,
    maxKey: keyof RobotPanelState,
    checkBeforeSet: boolean,
    convert?: (newValue: number) => number,
  ) {
    if (!newValue || newValue[0] === null || newValue[1] === null) {
      return;
    }
    this.setValue(rosMinTopic, newValue[0], `minimum ${label}`, minKey, checkBeforeSet, convert);
    this.setValue(rosMaxTopic, newValue[1], `maximum ${label}`, maxKey, checkBeforeSet, convert);
  }

  setValue<T extends string | number | boolean>(
    rosTopic: string,
    newValue: T,
    label: string,
    stateProp: keyof RobotPanelState,
    checkBeforeSet: boolean,
    convert?: (newValue: T) => T,
    expectTopic?: string,
  ) {
    // TODO why do we need to convert this state prop to T... I guess
    // because we don't know exactly which state variable they need, but
    // *we* should know which one it is? Still would be nice if we had a
    // better way to do this.
    sendROSConfig(rosTopic, newValue, label, this.state[stateProp] as T, checkBeforeSet, convert, expectTopic);
  }

  resetSensors() {
    this.setState({
      safeProx: null,
      dumpProx: null,
      doorProx: null,
      bucketProx: null,
      estopCut: null,
      seatbeltOn: null,
      pressureOk: null,
      thLive: null,
      brLive: null,
      carriageLive: null,
    });
  }

  updateValue(
    event: { hostname: string; msg: any },
    stateVar: keyof RobotPanelState,
    preprocess?: (data: number) => number,
  ) {
    const { hostname, msg } = event;
    const session = getCurrentSession();
    if (hostname === (session?.robot_hostname ?? '')) {
      let newValue = msg.data;
      if (preprocess) {
        newValue = preprocess(msg.data);
      }
      this.setState(updateState(stateVar, newValue));
    }
  }

  updateVisSensors(event: { hostname: string; msg: any }, stateVar: keyof RobotPanelState) {
    if (this.state.showSensors) {
      this.updateValue(event, stateVar);
    }
  }

  updateDepthOffsetSetting(event) {
    const session = getCurrentSession();
    const { hostname, msg } = event;
    if (hostname === (session?.robot_hostname ?? '')) {
      let drillDepthOffset = roundToIncrement(Math.abs(msg.data / MM_PER_INCH), 0.125);
      if (msg.data > 0) {
        drillDepthOffset = drillDepthOffset * -1;
      }
      this.setState({
        drillDepthOffset,
      });
    }
  }

  render() {
    return (
      <Grid style={{ marginTop: 10 }} container spacing={1}>
        {this.props.hmiVisible && (
          <Grid
            item
            container
            xs={12}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={0}
            style={{ marginLeft: 8, marginRight: 8 }}
          >
            {this.props.accessLevel === 'Technician' && (
              <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="butt_1" defaultLabel="Go/Core" />
            )}
            <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="butt_2" defaultLabel="Soil in Bucket" />
            <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="butt_3" defaultLabel="Dump Required" />
            {this.props.accessLevel === 'Technician' && (
              <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="butt_4" defaultLabel="Auto/Man" />
            )}
            <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="front" defaultLabel="Front Lights" />
            <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="rear" defaultLabel="Rear Lights" />
            <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="strobe" defaultLabel="Strobe Lights" />
            <HMIButton xs={6} sm={3} md={2} xl={1} height={70} buttonID="reset" defaultLabel="Reset (Tap 2x)" />
          </Grid>
        )}
        <MissionSync
          updateAutoSync={this.props.updateAutoSync}
          autoSync={this.props.autoSync}
          syncMission={this.props.syncMission}
          outOfSync={this.props.outOfSync}
          syncInProgress={this.props.syncInProgress}
          lastSyncTimestamp={this.props.lastSyncTimestamp}
          robotMission={this.props.robotMission}
          last_sample={this.state.last_sample}
          toggleTrackingOverride={this.props.toggleTrackingOverride}
          trackingOverride={this.props.trackingOverride}
          onSettingsChange={this.settingsChangeListener}
          robotControlRole={this.state.robotControlRole}
          waitingForRoleChange={this.state.waitingForRoleChange}
          waitForRoleChange={() => this.setState({ waitingForRoleChange: true })}
          toggleHMIVisible={this.props.toggleHMIVisible}
          hmiVisible={this.props.hmiVisible}
        />
        <Grid item xs={12}>
          <Paper style={{ padding: 10, margin: 10 }}>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Button style={{ marginRight: 10 }} variant="outlined" onClick={this.props.elevatePermissions}>
                {this.props.accessLevel === 'Locked' ? (
                  <FaLock style={{ marginLeft: 7.5, marginRight: 7.5 }} />
                ) : (
                  <FaLockOpen style={{ marginLeft: 7.5, marginRight: 7.5 }} />
                )}
                {this.props.accessLevel === 'Locked' ? 'Unlock' : 'Lock'}
              </Button>
              Access Level: <b> {this.props.accessLevel}</b>
              <Table>
                <TableBody>
                  <TableRow>
                    <SliderButtonCell<number>
                      {...this.getSliderParams('Core Depth Offset')}
                      showQuotient={true}
                      value={this.state.drillDepthOffset}
                      onSave={this.setDepthOffset}
                      locked={this.props.accessLevel === 'Locked'}
                    />

                    <SliderButtonCell<number>
                      {...this.getSliderParams('Core Depth')}
                      value={this.props.drillDepth ?? null}
                      showQuotient={true}
                      onSave={this.props.setDepth}
                      extraLabel={'Robot'}
                      additionalValue={this.props.missionDepth}
                      additionalValueLabel={'Mission'}
                      onToggleLock={async () => await this.setDepthLock(!this.props.depthLocked)}
                      locked={!this.props.depthLocked || this.props.accessLevel !== 'Technician'}
                      isDepthSetting={true}
                    />

                    <SliderButtonCell<number>
                      {...this.getSliderParams('Max Speed')}
                      value={this.state.maxSpeed}
                      onSave={this.setSpeed}
                      locked={false}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        width: '33.33333333%',
                        display: 'inline-block',
                        height: '100px',
                        verticalAlign: 'text-top',
                      }}
                      size={'small'}
                    >
                      <FormControl>
                        <InputLabel shrink>Implement</InputLabel>
                        <NativeSelect
                          onChange={(event) => {
                            // TODO OP ACCOUNTABILITY log setting changes
                            this.settingsChangeListener('Implement', event.target.value as ProbeSelection);
                            this.setImplementSelected(event.target.value as ProbeSelection);
                          }}
                          value={this.state.implementSelected}
                        >
                          {this.state.implementSelected === '' && <option value={''}>?</option>}
                          <option value="AUGER">Auger</option>
                          <option value="PROBE">Probe</option>
                          <option value="RING-TIP">Ring Tip</option>
                        </NativeSelect>
                      </FormControl>
                    </TableCell>

                    {this.state.implementSelected !== 'AUGER' && (
                      <SliderButtonCell<number>
                        {...this.getSliderParams('Dwell At Top Time')}
                        value={this.state.dwellAtTopTime}
                        onSave={this.setDwellAtTopTime}
                        locked={this.props.accessLevel === 'Locked'}
                      />
                    )}
                    <SliderButtonCell<number>
                      {...this.getSliderParams('Dwell Time')}
                      value={this.state.dwellTime}
                      onSave={this.setDwell}
                      locked={this.props.accessLevel === 'Locked'}
                    />
                    <SliderButtonCell<number>
                      {...this.getSliderParams('Core Timeout')}
                      value={this.state.coreTimeout}
                      onSave={this.setCoreTimeout}
                      locked={this.props.accessLevel === 'Locked'}
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        width: '33.33333333%',
                        display: 'inline-block',
                        height: isMobile ? '130px' : '100px',
                        verticalAlign: 'text-top',
                      }}
                      size={'small'}
                    >
                      <SwitchButton
                        label={'Override Drive Safety (Very Dangerous)'}
                        onChange={() => {
                          this.settingsChangeListener(
                            'Override Drive Safety (Very Dangerous)',
                            !this.state.brakeSafetyCheck,
                          );
                          this.setBrakeSafetyCheck();
                        }}
                        checked={this.state.brakeSafetyCheck}
                        disabled={this.props.accessLevel === 'Locked'}
                      />
                    </TableCell>

                    <TableCell
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        width: '33.33333333%',
                        display: 'inline-block',
                        height: isMobile ? '130px' : '100px',
                        verticalAlign: 'text-top',
                      }}
                      size={'small'}
                    >
                      <SwitchButton
                        label={'Override GPS Speed Safety (Dangerous)'}
                        onChange={() => {
                          this.settingsChangeListener(
                            'Override GPS Speed Safety (Dangerous)',
                            !this.state.speedSafetyCheck,
                          );
                          this.setSpeedSafetyCheck();
                        }}
                        checked={this.state.speedSafetyCheck}
                        disabled={this.props.accessLevel === 'Locked'}
                      />
                    </TableCell>

                    <TableCell
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                        width: '33.33333333%',
                        display: 'inline-block',
                        height: isMobile ? '130px' : '100px',
                        verticalAlign: 'text-top',
                      }}
                      size={'small'}
                    >
                      <LoadingButton
                        variant="outlined"
                        onClick={() => this.setDefault()}
                        style={{ textTransform: 'none' }}
                      >
                        Reset To Defaults
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                  {this.props.accessLevel === 'Technician' && (
                    <React.Fragment>
                      <TableRow>
                        <TableCell
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            textTransform: 'capitalize',
                            width: '100%',
                            display: 'inline-block',
                            height: '40px',
                            verticalAlign: 'text-top',
                          }}
                          size={'small'}
                        >
                          <Typography>Advanced</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <SliderButtonCell<number>
                          {...this.getSliderParams('Tip To Ground Distance')}
                          helperValue={this.state.carriageLive}
                          helperValueLabel={'Carriage Live Position'}
                          displayHelperValue={true}
                          value={this.state.tipToGroundDistance}
                          onSave={this.setTipToGround}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<number>
                          {...this.getSliderParams('Carriage Down Speed')}
                          value={this.state.carriageDownSpeed}
                          onSave={this.setCarriageDownSpeed}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                      </TableRow>

                      <TableRow>
                        <SliderButtonCell<number>
                          {...this.getSliderParams('Carriage Up Speed')}
                          value={this.state.carriageUpSpeed}
                          onSave={this.setCarriageUpSpeed}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('Big Motor Up Deadband')}
                          textfieldHelperText={['min', 'max']}
                          value={[this.state.dccMinDeadband, this.state.dccMaxDeadband]}
                          onSave={this.setDccDeadband}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('Big Motor Down Deadband')}
                          textfieldHelperText={['max', 'min']}
                          value={[this.state.dcwMaxDeadband, this.state.dcwMinDeadband]}
                          onSave={this.setDcwDeadband}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                      </TableRow>

                      <TableRow>
                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('St Pwm')}
                          textfieldHelperText={['min', 'max']}
                          value={[this.state.stMinPwm, this.state.stMaxPwm]}
                          onSave={this.setStPwm}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('Carriage Up Deadband')}
                          textfieldHelperText={['min', 'max']}
                          value={[this.state.cupMinDeadband, this.state.cupMaxDeadband]}
                          onSave={this.setCupDeadband}
                          locked={this.props.accessLevel !== 'Technician'}
                        />

                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('Carriage Down Deadband')}
                          textfieldHelperText={['max', 'min']}
                          value={[this.state.cdnMaxDeadband, this.state.cdnMinDeadband]}
                          onSave={this.setCdnDeadband}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                      </TableRow>
                      <TableRow>
                        <SliderButtonCell<number>
                          {...this.getSliderParams('Depth Arrival Tolerance')}
                          value={this.state.depthArrivalTarget}
                          onSave={this.setDepthArrivalTarget}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<number>
                          {...this.getSliderParams('Throttle Deadband')}
                          value={this.state.thDeadband}
                          onSave={this.setThDeadband}
                          locked={this.props.accessLevel !== 'Technician'}
                        />

                        <SliderButtonCell<number>
                          {...this.getSliderParams('Brake Deadband')}
                          value={this.state.brDeadband}
                          onSave={this.setBrDeadband}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                      </TableRow>

                      <TableRow>
                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('Th Pos')}
                          textfieldHelperText={['min', 'max']}
                          value={[this.state.thMinPos, this.state.thMaxPos]}
                          helperValue={this.state.thLive}
                          displayHelperValue={true}
                          helperValueLabel={'Live Pos Value'}
                          onSave={this.setThPos}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<NullableRange>
                          {...this.getSliderParams('Br Pos')}
                          textfieldHelperText={['min', 'max']}
                          helperValue={this.state.brLive}
                          displayHelperValue={true}
                          helperValueLabel={'Live Pos Value'}
                          value={[this.state.brMinPos, this.state.brMaxPos]}
                          onSave={this.setBrPos}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                        <SliderButtonCell<number>
                          {...this.getSliderParams('Big Motor Sensitivity')}
                          onSave={(value) => this.setBigMotorEnabled(value)}
                          value={this.state.bigMotorEnabled}
                          locked={this.props.accessLevel !== 'Technician'}
                        />
                      </TableRow>
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Paper>

          <Paper style={{ padding: 10, margin: 10 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <InfoPanelCell label={'Safe Prox'} value={this.state.safeProx} />
                  <InfoPanelCell label={'Dump Prox'} value={this.state.dumpProx} />
                  <InfoPanelCell label={'Door Prox'} value={this.state.doorProx} />
                  <InfoPanelCell label={'Bucket Prox'} value={this.state.bucketProx} />
                </TableRow>
                <TableRow>
                  <InfoPanelCell label={'Pressure'} value={this.state.pressureOk} />
                  <InfoPanelCell label={'Estop'} value={this.state.estopCut} />
                  <InfoPanelCell label={'Seatbelt'} value={this.state.seatbeltOn} />

                  <InfoPanelCell label={'Carriage Position'} value={this.state.carriageLive} />
                </TableRow>

                <TableRow>
                  <InfoPanelCell label={'Th Position'} value={this.state.thLive} />

                  <InfoPanelCell label={'Br Position'} value={this.state.brLive} />

                  <TableCell width={'25%'} />

                  <TableCell width={'25%'}>
                    <SwitchButton
                      label={'Show Sensor Data'}
                      checked={this.state.showSensors}
                      onChange={() => this.setShowSensors(!this.state.showSensors)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <SetWaypoint
            setWaypoint={this.props.setWaypoint}
            robotMission={this.props.robotMission}
            last_sample={this.state.last_sample}
          />
        </Grid>
      </Grid>
    );
  }
}
