import { uniq } from 'lodash';
import { createHash } from 'crypto';
import JSZip from 'jszip';
import _ from 'lodash';
import { CoordinatePoint, GeometryPoint, SettingsAccessLevel, TakenCore } from './types/types';
import { alertWarn } from './alertDispatcher';
import { Coordinate, equals } from 'ol/coordinate';
import { Component } from 'react';
import {
  BD_CORE_AUTO,
  BD_CORE_MANUAL,
  FEET_PER_MILE,
  FERTILITY_CORE_AUTO,
  KM_PER_MILE,
  MILES_TO_NM,
  MM_PER_INCH,
  MPS_TO_MPH,
  NM_TO_MILES,
  SQ_M_PER_ACRE,
} from './constants';
import { LineCoordType, PointCoordType, PolyCoordType, SketchCoordType } from 'ol/interaction/Draw';
import { MultiPolygon, Point, Polygon } from 'ol/geom';
import { FeatureCollection } from '@turf/helpers';
import { KMLElement } from './kml';
import { DownloadOptions, ZipOptions, zip as shpZip } from '@mapbox/shp-write';
import { getPointResolution } from 'ol/proj';
import { RosPosition } from './types/rosmsg';
import { SoilCoreList } from './db/SoilCoreClass';
import Feature from 'ol/Feature';
import { getArea } from 'ol/sphere';

// Utility function for rounding with precision
export function round(value: number, precision?: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function roundToIncrement(value: number, increment: number) {
  return round(Math.round(value / increment) * increment, String(increment).length);
}

export function numbersAreClose(value1: number, value2: number, margin: number) {
  return Math.abs(value1 - value2) <= margin;
}

export const updateState =
  <T extends string | boolean, U>(key: keyof U, value: T) =>
  (prevState: U): U => ({
    ...prevState,
    [key]: value,
  });

let robotList: string[] = [];
// utility function for generating  full string array of robot names
export function generateRobotList() {
  if (!!robotList.length) return robotList;
  for (let i = 0; i < 25; i++) {
    robotList.push(`K-${i + 1}`);
  }
  //robotList.push("S5-3");
  robotList.push('Other');
  return robotList;
}

export function numberToFraction(number: number) {
  let amount = Math.abs(number);
  // This is a whole number and doesn't need modification.
  if (amount === Math.round(amount)) {
    return number.toString();
  }
  // Next 12 lines are cribbed from https://stackoverflow.com/a/23575406.
  const gcd = function (a: number, b: number): number {
    if (b < 0.0000001) {
      return a;
    }
    return gcd(b, Math.floor(a % b));
  };
  let len = amount.toString().length - 2;
  let denominator = Math.pow(10, len);
  let numerator = amount * denominator;
  let divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;
  let base = 0;
  // In a scenario like 3/2, convert to 1 1/2
  // by pulling out the base number and reducing the numerator.
  if (numerator > denominator) {
    base = Math.floor(numerator / denominator);
    numerator -= base * denominator;
  }
  let amountResult = Math.floor(numerator) + '/' + Math.floor(denominator);
  if (base) {
    amountResult = base + ' ' + amount;
  }
  if (number < 0) {
    amountResult = '-' + amount;
  }
  return amountResult;
}

// Utility function to determine approximate equality of floats
export function isclose(a: number, b: number, tol?: number) {
  tol = tol || 1e-9;
  return Math.abs(a - b) < tol;
}

export function m_to_ft(x: number) {
  return x / 0.3048; // 0.3048 meters per foot
}

export class BaseShape {
  cores_shape: [number, number][];

  constructor() {
    this.cores_shape = [];
  }

  generate_core_points(easting: number, northing: number) {
    // Generate the subsample points for this sample.
    const subsamples_points: [number, number][] = [];
    for (const [, offsets] of this.cores_shape.entries()) {
      const [offset_e, offset_n] = offsets;
      const e = easting + offset_e;
      const n = northing + offset_n;
      subsamples_points.push([e, n]);
    }
    return subsamples_points;
  }

  optimize_samples(sample_points: Coordinate[], point_prev: Coordinate | null, line_mode?: boolean) {
    let optimal_points = sample_points;
    const sample_points_count = sample_points.length;
    if (sample_points_count <= 1) {
      return optimal_points;
    }

    // Choose the starting point based on the smallest entry angle.
    let min_angle: number | null = null;
    let path_start = 0;
    let path_direction = +1;

    // Consider both directions.
    for (const direction of [-1, +1]) {
      // Consider starting at each position.
      let start_positions: number[] = [];
      if (line_mode) {
        if (direction === +1) {
          start_positions = [0];
        } else {
          start_positions = [sample_points_count - 1];
        }
      } else {
        start_positions = [...Array(sample_points_count).keys()];
      }

      for (const i of start_positions) {
        if (point_prev) {
          const entry_angle = Math.abs(
            Math.PI -
              angle(
                point_prev,
                sample_points[i],
                sample_points[(i + direction + sample_points_count) % sample_points_count],
              ),
          );
          if (!min_angle || entry_angle < min_angle) {
            min_angle = entry_angle;
            path_start = i;
            path_direction = direction;
          }
        }
      }
    }

    // We have our start position. Re-order the sample points accordingly.
    optimal_points = [];
    for (const i of [...Array(sample_points_count).keys()]) {
      optimal_points.push(sample_points[(i * path_direction + path_start + sample_points_count) % sample_points_count]);
    }

    return optimal_points;
  }

  optimal_cores(
    point_current: Coordinate,
    point_prev: Coordinate | null,
    alternating_direction: boolean,
    prevSampleIDIsSame: boolean = false,
  ) {
    return this.optimize_samples(
      this.generate_core_points(point_current[0], point_current[1]),
      point_prev,
      alternating_direction,
    );
  }
}

// TODO should probably define this elsewhere
const _SECOND = 1000;
const _MINUTE = 60 * _SECOND;
const _HOUR = 60 * _MINUTE;
const _DAY = 24 * _HOUR;
const _WEEK = 7 * _DAY;
const _MONTH = 30 * _DAY;
const _YEAR = 365 * _DAY;

export function generateScheduledKey(date: string, robot: string, operator: string) {
  return `${date}/${robot}/${operator}`;
}

export function getFriendlyTime(t: string) {
  const diff = Date.now() - Date.parse(t);
  if (diff < 2 * _MINUTE) return 'A moment ago';
  if (diff < _HOUR) return Math.floor(diff / _MINUTE) + ' mins ago';
  if (diff < _DAY) return Math.floor(diff / _HOUR) === 1 ? 'an hour ago' : Math.floor(diff / _HOUR) + ' hrs ago';
  if (diff < _WEEK) return Math.floor(diff / _DAY) === 1 ? 'yesterday' : Math.floor(diff / _DAY) + ' days ago';
  if (diff < _MONTH) return Math.floor(diff / _WEEK) === 1 ? 'last week' : Math.floor(diff / _WEEK) + ' weeks ago';
  if (diff < _YEAR) return Math.floor(diff / _MONTH) === 1 ? 'last month' : Math.floor(diff / _MONTH) + ' months ago';
  return Math.floor(diff / _YEAR) === 1 ? 'an year ago' : Math.floor(diff / _YEAR) + ' yrs ago';
}

export class CircleShape extends BaseShape {
  constructor(subsamples_per_sample: number, radius: number) {
    super();
    for (let c = 0; c < subsamples_per_sample; c++) {
      const x = Math.cos(((2 * Math.PI) / subsamples_per_sample) * c) * radius;
      const y = Math.sin(((2 * Math.PI) / subsamples_per_sample) * c) * radius;
      this.cores_shape.push([x, y]);
    }
  }
}

export class LineShape extends BaseShape {
  constructor(cores_per_sample: number, length: number, angle: number) {
    super();
    if (cores_per_sample === 1) {
      this.cores_shape.push([0, 0]);

      return;
    }

    const i = (Math.cos((angle * Math.PI) / 180) * length) / (cores_per_sample - 1);
    const j = (Math.sin((angle * Math.PI) / 180) * length) / (cores_per_sample - 1);
    for (let b = 0; b < cores_per_sample; b++) {
      // Center the line.
      const c = b - (cores_per_sample - 1) / 2;
      const x = c * i;
      const y = c * j;
      this.cores_shape.push([x, y]);
    }
  }

  generate_core_points(easting: number, northing: number, alternating_direction = false) {
    // every other generation, angle is opposite
    this.cores_shape = this.cores_shape.map((pair) => [pair[0] * (alternating_direction ? -1 : 1), pair[1]]);
    return super.generate_core_points(easting, northing);
  }

  optimal_cores(point_current: Coordinate, point_prev: Coordinate | null, alternating_direction = false) {
    return this.optimize_samples(
      this.generate_core_points(point_current[0], point_current[1], alternating_direction),
      point_prev,
      true,
    );
  }
}

export function calculateFieldAngle(field: MultiPolygon) {
  const polyCoordiantes = field.getCoordinates()[0][0];
  let pointsChange = [...polyCoordiantes.slice(0, polyCoordiantes.length - 1)];
  // sort by lat
  pointsChange.sort((a, b) => a[1] - b[1]);
  console.log(pointsChange);
  // sort by lon
  // pointsChange.sort((a, b) => a[0] - b[0]);

  // sort the first half of the array
  const firstHalf = pointsChange.slice(0, pointsChange.length / 2);
  firstHalf.sort((a, b) => a[0] - b[0]);

  // sort the second half of the array
  const secondHalf = pointsChange.slice(pointsChange.length / 2);
  secondHalf.sort((a, b) => a[0] - b[0]);

  // combine the two halves
  // pointsChange = [...firstHalf, ...secondHalf];

  console.log(pointsChange);

  const fieldExtent = field.getExtent();

  const minExtentCoordinate: Coordinate = [fieldExtent[0], fieldExtent[1]];

  // select min lat
  const minLat = pointsChange.reduce((prev, current) => (prev[1] < current[1] ? prev : current));

  // select the point with that minLat
  const minLatPoint = pointsChange.find((point) => point[1] === minLat[1]);

  // select minLon
  const minLon = pointsChange.reduce((prev, current) => (prev[0] < current[0] ? prev : current));

  // select the point with that minLon
  const minLonPoint = pointsChange.find((point) => point[0] === minLon[0]);

  if (!minLatPoint || !minLonPoint) {
    alertWarn('Failed to calculate field angle. Please try again.');
    return 0;
  }

  pointsChange = [minLatPoint, minLonPoint, minExtentCoordinate];

  const angleTest = Math.atan((pointsChange[2][0] - pointsChange[0][0]) / (pointsChange[2][1] - pointsChange[1][1]));

  return angleTest;
}

export function getFeatureCounts(feature: FeatureCollection) {
  let counts = {};
  feature.features.forEach((feature) => {
    const type = feature.geometry.type;
    if (counts[type]) {
      counts[type] += 1;
    } else {
      counts[type] = 1;
    }
  });
  return counts;
}

function distance(a: Coordinate, b: Coordinate) {
  // Calculates distance between two coordinates.
  return Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2);
}

function angle(a: Coordinate, b: Coordinate, c: Coordinate) {
  // Calculate the angle described by three points.
  const x = distance(a, b);
  const y = distance(b, c);
  const z = distance(a, c);
  let angleC = 0.0;
  if (x !== 0 && y !== 0) {
    angleC = Math.acos(Math.max(-1, Math.min(1, (x ** 2 + y ** 2 - z ** 2) / (2 * x * y))));
  }
  return angleC;
}

// remove undefined, null values, de-dupe set selected through multi-level
// selections
export function cleanSet<T>(input: Array<T>) {
  return uniq(input).filter((sel) => !!sel);
}

export function cleanBoundary(boundary: Coordinate[]) {
  /* cleans boundary of duplicate points */
  const lastPoint = boundary[boundary.length - 1];
  boundary = boundary.splice(0, boundary.length - 1); // splice array

  //boundary = Array.from(new Set(boundary.map(JSON.stringify)), JSON.parse); // remove duplicates
  // TODO this is probably silly slow, should be done more efficiently
  boundary = [...new Set<string>(boundary.map((coord) => JSON.stringify(coord)))].map((b) => JSON.parse(b));

  boundary.push(lastPoint);
  return boundary;
}

export function feetToMeters(feet: number) {
  return feet * 0.3048;
}

export function distance3857(coordA: Coordinate, coordB: Coordinate) {
  return distanceLatLon(coordA[1], coordA[0], coordB[1], coordB[0]);
}

export function distanceCoordinatePoints(point1: CoordinatePoint, point2: CoordinatePoint) {
  return distanceLatLon(point1.lat, point1.lon, point2.lat, point2.lon);
}

export function distanceLatLon(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: 'Miles' | 'Kilometers' | 'Meters' | 'NMiles' | 'Feet' = 'Miles',
) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * NM_TO_MILES;
    if (unit === 'Kilometers') {
      dist = dist * KM_PER_MILE;
    } else if (unit === 'NMiles') {
      dist = dist * MILES_TO_NM;
    } else if (unit === 'Feet') {
      dist = dist * FEET_PER_MILE;
    } else if (unit === 'Meters') {
      dist = dist * KM_PER_MILE * 1000;
    }
    return dist;
  }
}

export function insidePolygon(point, vs) {
  let x = point[0],
    y = point[1];
  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    let xi = vs[i][0],
      yi = vs[i][1];
    let xj = vs[j][0],
      yj = vs[j][1];

    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function filterObject<T extends Object>(obj: T, predicate: (value: any) => boolean): Partial<T> {
  let result: Partial<T> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && predicate(obj[key])) {
      result[key] = obj[key];
    }
  }
  return result;
}

export function addAlpha(color: string, opacity: number) {
  // coerce values so it is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function generateColor(str?: string) {
  return `#${createHash('sha1')
    .update(str || 'XYZ', 'utf8')
    .digest('hex')
    .slice(0, 6)}`;
}

export function wait(ms: number) {
  return new Promise((r) => setTimeout(r, ms));
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function isUUIDv4(s: string | number | undefined | null) {
  if (typeof s !== 'string') {
    return false;
  }

  return s.match(/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
}

export function formatDirectionsUrl(lat: number, lon: number) {
  return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
}

export function formatDirectionsUrlWithAddress(address: string) {
  return `https://www.google.com/maps/dir/?api=1&destination=${address.replaceAll(' ', '+')}`;
}

export function toHex(str: string) {
  return unescape(encodeURIComponent(str))
    .split('')
    .map((v) => {
      return v.charCodeAt(0).toString(16);
    })
    .join('');
}

// takes a KMZ and returns a KML string
// content can be a String/Array of bytes/ArrayBuffer/Uint8Array/Buffer/Blob/Promise
export async function kmz2kml(content: File | Blob) {
  const zip = new JSZip();
  await zip.loadAsync(content);
  // TODO this function seems to assume just one KML file in the zip, which is true for our system right now
  // but this is a bad assumption that is prime for developing bugs later
  return await new Promise<string>((resolve, reject) => {
    zip.forEach(async (path, file) => {
      if (path.endsWith('.kml')) {
        const newZipFile = zip.file(path);
        if (newZipFile) {
          const text = await newZipFile.async('string');
          resolve(text);
        }
      }
    });
  });
}

export const fileToBase64 = (file: File | Blob | ArrayBuffer): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) {
      return resolve('');
    }

    if (
      !(file instanceof ArrayBuffer) &&
      !(file instanceof Blob) &&
      typeof file === 'object' &&
      Object.keys(file).length === 0
    ) {
      return resolve('');
    }

    // if data is arraybuffer, convert to blob first
    if (file instanceof ArrayBuffer) {
      file = new Blob([file]);
    }

    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

/**
 *
 * @param date
 * @returns
 */
export function getLastDigitOfYear(date: Date) {
  return date.getUTCFullYear().toString().split('').pop();
}

/**
 *
 * @param date
 * @returns
 */
export function daysIntoYear(date: Date) {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
}

const DEFAULT_DAYS = 5;

export async function checkFuturePasswords(
  password: string,
  passwordFunction: (dayOffset: number) => Promise<number>,
  { days = DEFAULT_DAYS, hardPassword = '' } = {},
) {
  console.log(
    `Checking ${days} future passwords for ${password} ${(await passwordFunction(0)).toString(16).toLowerCase()}`,
  );
  if (hardPassword && password.toLowerCase() === hardPassword.toLowerCase()) {
    return true;
  }
  for (let i = 0; i < days; i++) {
    const rawPassword = (await passwordFunction(i)).toString(16).toLowerCase();
    const matchPassword = rawPassword.slice(rawPassword.length - 4, rawPassword.length);
    console.log(`Checking ${password} against ${matchPassword}`);
    if (password.toLowerCase() === matchPassword) {
      return true;
    }
  }
  return false;
}

export type SetStateAsync = <P = {}, S = {}>(state: S) => Promise<void>;
export function setStateAsync<P = {}, S = {}>(this: Component<P, S>, state: S) {
  /* Bind this function to the component to use */
  return new Promise<void>((res, rej) => {
    this.setState(state, res);
  });
}

/**
 * Formats a date object into a string
 * @param {Date} date The date to format
 * @returns {string} The date formatted as a string
 */
export function formatDateAsString(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const timestamp = `${year}${month}${day}${hour}${minutes}${seconds}`;

  return timestamp;
}

export function isPointDelete(coordinate: Coordinate) {
  return coordinate[0] === 0 && coordinate[1] === 0;
}

/**
 * A javascript form of an assert statement
 * @param {boolean} condition The eval of a condition
 * @param {string} message The message to throw if the condition fails
 */
export function assert(condition: boolean, message: string) {
  if (!condition) {
    throw message || 'Assert failed';
  }
}

export function calculateRosCentroid(points: RosPosition[]): RosPosition {
  const x = points.map((point) => point.x).reduce((a, b) => a + b, 0) / points.length;
  const y = points.map((point) => point.y).reduce((a, b) => a + b, 0) / points.length;
  // this doesn't really need an average, but we'll take it
  const z = points.map((point) => point.z).reduce((a, b) => a + b, 0) / points.length;
  return { x, y, z };
}

export function calculateTakenCoreCentroid(takenCores: TakenCore[]) {
  const takenCoreCoordinates = takenCores.map((core) => core[3]);
  const centroid = calculateRosCentroid(takenCoreCoordinates);
  return centroid;
}

export function calculateDistance4329(coordA: Coordinate | undefined, coordB: Coordinate | undefined) {
  if (!coordA || !coordB) {
    return 0;
  }
  // calculate center between coordiantes
  const center = [(coordA[0] + coordB[0]) / 2, (coordA[1] + coordB[1]) / 2];
  const resCenter = getPointResolution('EPSG:3857', 1, center);
  const _distance = distance(coordA, coordB) * resCenter;
  return _distance;
}

export function calculateNavigationAngle(
  robotHeading: number,
  robotPosition: Coordinate,
  targetCoordinates: Coordinate,
) {
  // robot to core angle
  // a = 135
  // b = -135
  // c = -45
  // d = 45
  //
  //         -180
  //       a   |   b
  //           |
  // -90 ------+------- 90
  //           |
  //       d   |   c
  //          180
  //
  // robot heading
  //
  // a = 135
  // b = 45
  // c = 225
  // d = 315
  //           90
  //         a ^ b
  //          \|/
  // 180 <-----+------> 0/360
  //          /|\
  //         c v d
  //          270

  // robot heading (-180,180)
  //
  // a = 45
  // b = -135
  // c = 45
  // d = 135
  //          -90
  //         a ^ b
  //          \|/
  // 0   <-----+------> -180/180
  //          /|\
  //         c v d
  //           90
  if (!targetCoordinates || !robotPosition) {
    return 0;
  }
  let robotToCoreAngle = targetCoordinates
    ? (Math.atan2(targetCoordinates[0] - robotPosition[0], targetCoordinates[1] - robotPosition[1]) +
        (3 * Math.PI) / 2) %
      (2 * Math.PI)
    : 0;
  const robotHeading_ = robotHeading % (2 * Math.PI);

  const robotNavigationAngleTemp = (robotHeading_ + robotToCoreAngle) % (2 * Math.PI);
  const robotNavigationAngle =
    robotNavigationAngleTemp > Math.PI ? robotNavigationAngleTemp - 2 * Math.PI : robotNavigationAngleTemp;
  return robotNavigationAngle;
}

// Convert degrees to meters (ToSphericalMercatorFromWgs84)
export function convertProjection4329([longitude, latitude]: Coordinate): Coordinate {
  const x = (longitude * 20037508.34) / 180;
  let y = Math.log(Math.tan(((90 + latitude) * Math.PI) / 360)) / (Math.PI / 180);
  y = (y * 20037508.34) / 180;
  return [x, y];
}

export async function geometryToShapefile(points: Point[], polygons: Polygon[], folder: string = 'shapefiles') {
  // name zipped folder and files
  const options: DownloadOptions & ZipOptions = {
    folder,
    types: {
      point: `${folder}_pts`,
      polygon: `${folder}_bnd`,
    },
    compression: 'DEFLATE',
    outputType: 'blob',
  };

  // assemble the points and polygons into a FeatureCollection
  const geoJSON: FeatureCollection<Point | Polygon> = {
    type: 'FeatureCollection',
    // @ts-ignore
    features: [
      ...points.map((point, i) => {
        return {
          type: 'Feature' as const,
          properties: {
            id: i,
            name: `Point ${i + 1}`,
          },
          geometry: {
            type: 'Point' as const,
            coordinates: convertProjection3857(point.getCoordinates()),
          },
        };
      }),
      ...polygons.map((polygon, i) => {
        return {
          type: 'Feature' as const,
          properties: {
            id: i,
            name: `Polygon ${i + 1}`,
          },
          geometry: {
            type: 'Polygon' as const,
            coordinates: [
              polygon.getCoordinates().map((ring) => ring.map((point) => convertProjection3857(point).toReversed())),
            ],
          },
        };
      }),
    ],
  };

  try {
    // return zip contents
    const result: Blob = await shpZip<'blob'>(geoJSON as FeatureCollection<any, any>, options);
    return result;
  } catch (e) {
    console.log(e);
  }
}

export function takenCoreToKML(core: TakenCore, core_name: string) {
  const placemark = KMLElement.element('Placemark');
  const placemark_vis = KMLElement.subelement(placemark, 'visibility');
  placemark_vis.setText('1');
  const nm = KMLElement.subelement(placemark, 'name');
  nm.setText(core_name);
  const point = KMLElement.subelement(placemark, 'Point');
  const coords = KMLElement.subelement(point, 'coordinates');
  coords.setText(`${core[3].y},${core[3].x},0`);

  // stamp: HeaderTimestamp,
  // target_depth: number | null,
  // postlaunch_depth_error: number | null,
  // position: RosPosition,
  // initiator: number,
  // sample_id: string,
  // hole_depth: number | null,
  // core_length: number | null,
  // plunge_depth: number | null,
  // core_loss: number | null,

  placemark.putExtendedData('taken_core_location', true);
  placemark.putExtendedData('timestamp', `${core[0].secs}.${core[0].nsecs}`);
  placemark.putExtendedData('target_depth', core[1] || '');
  placemark.putExtendedData('postlaunch_error', core[2] || '');
  placemark.putExtendedData('position.z', core[3].z);
  placemark.putExtendedData('initiator', core[4]);
  placemark.putExtendedData('sample_id', core[5]);
  placemark.putExtendedData('hole_depth', core[6] || '');
  placemark.putExtendedData('core_length', core[7] || '');
  placemark.putExtendedData('plunge_depth', core[8] || '');
  placemark.putExtendedData('core_loss', core[9] || '');
  placemark.putExtendedData('core_diameter_inches', core[10] || '');

  const style = KMLElement.subelement(placemark, 'styleUrl');
  style.setText('#taken_core_location');
  return placemark;
}

// Convert meters to degrees (ToWgs84FromSphericalMercator
/**
 * example input: [-12423954.206080379,5452793.309251656],
 * example output: [43.918207448725724, -111.6062795407659]
 * @param {Coordinate} coordinate to convert
 * @returns {[number, number]} converted coordinates
 */
export function convertProjection3857([x, y]: Coordinate): Coordinate {
  const longitude = (x * 180) / 20037508.34;
  const latitude = (Math.atan(Math.exp((y * Math.PI) / 20037508.34)) * 360) / Math.PI - 90;
  return [latitude, longitude];
}

export function getUserPosition(options: PositionOptions = { enableHighAccuracy: true, timeout: 5000, maximumAge: 1 }) {
  return new Promise((fulfilled: (p: Coordinate) => void, rejected: (geoError: GeolocationPositionError) => void) => {
    navigator.geolocation.getCurrentPosition(
      // get current position from user
      (position) => {
        // success callback
        const userLat = position.coords.latitude;
        const userLon = position.coords.longitude;
        fulfilled([userLat, userLon]);
      },
      (e) => {
        // error callback
        const errorMessage = 'Could not obtain user position!';
        alertWarn(errorMessage);
        rejected(e);
      },
      options, // options
    );
  });
}

const SPECIAL_TYPES = new Map();
SPECIAL_TYPES.set(Blob, (blob) => blob.slice());
SPECIAL_TYPES.set(Set, (set) => new Set(set));
SPECIAL_TYPES.set(Array, (arr) => arr.slice());
SPECIAL_TYPES.set(Object, (obj) => {
  return { ...obj };
});

export function cloneObjectDeep<T extends object>(object: T) {
  var copy = {} as T;
  for (let attribute of Object.keys(object)) {
    copy[attribute] = _.cloneDeepWith(object[attribute], (value) => {
      for (let specialType of SPECIAL_TYPES.keys()) {
        if (value instanceof specialType) {
          return _.cloneWith(value, SPECIAL_TYPES.get(specialType));
        }
      }

      return value;
    });
  }

  return copy;
}

export function copyMap<T, U>(source: Map<T, U>) {
  const newMap = new Map<T, U>();
  for (const [key, value] of source.entries()) {
    newMap.set(key, value);
  }
  return newMap;
}

/**
 * Recursivly traverses array to find the max lat
 * @param {array} coordinates expects an array of any depth
 * @param {*} currentMax used for keeping track of max lat
 * @param {*} maxLon used for keeping track of max lon
 */
export function findMaxLat(
  coordinates: Coordinate | Coordinate[],
  currentMax: number | null = null,
  maxLon: number | null = null,
): Coordinate | null {
  const isCoordinateArray = (coordinates): coordinates is Coordinate[] => {
    return Array.isArray(coordinates[0]);
  };
  if (isCoordinateArray(coordinates)) {
    for (let next of coordinates) {
      const found = findMaxLat(next, currentMax);
      if (found) {
        currentMax = found[1];
        maxLon = found[0];
      }
    }
  } else if (!currentMax || currentMax < coordinates[1]) {
    return coordinates;
  } else {
    return null;
  }

  // TODO why does this function say it finds the max lat but returns max lon?
  return [currentMax!, maxLon!];
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export function isAutoCore(intiator: number) {
  return intiator === FERTILITY_CORE_AUTO || intiator === BD_CORE_AUTO;
}

export function isBulkDensityCore(intiator: number) {
  return intiator === BD_CORE_MANUAL || intiator === BD_CORE_AUTO;
}

export function isNullOrUndefined(value: any): value is null | undefined {
  return value === null || value === undefined;
}

// default typed local storage getter with optional default value
export function localStorageGet<T>(key: string, defaultValue: T): T | undefined {
  const localStorageRawValue = localStorage.getItem(key);
  if (localStorageRawValue !== null && localStorageRawValue !== undefined && localStorageRawValue !== '') {
    return JSON.parse(localStorageRawValue) as T;
  }
  // this should only happen initially, then never again
  // this is so as a dev we can make edits to the value in unique
  // circumstances. This guarantees it exists in local storage
  //localStorageSet(key, defaultValue);
  return defaultValue;
}

// default typed local storage setter
export function localStorageSet<T>(key: string, value: T) {
  if (value === null || value === undefined) {
    localStorage.removeItem(key);
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
}

export interface LocalStorageDefinition<T> {
  key: string;
  get: () => T;
  set: (value: T) => void;
  reset: () => void;
}

// default typed local storage generator
export function LocalStorageGenerator<T>(
  key: string,
  defaultValue: T,
  {
    // can force this to be undefined because we are requiring our default value
    getter = (defaultValue: T) => localStorageGet<T>(key, defaultValue)!,
    setter = (value: T | undefined) => localStorageSet(key, value),
  } = {},
): LocalStorageDefinition<T> {
  return {
    key,
    get: () => getter(defaultValue),
    set: setter,
    reset: () => setter(defaultValue),
  };
}

export const colorToString = (color: number) => {
  return `#${color.toString(16).padStart(6, '0')}`;
};

export const objectValueChanged = <T>(oldObject: T, newObject: T, key: keyof T) => {
  return JSON.stringify(oldObject[key]) !== JSON.stringify(newObject[key]);
};

export const someObjectValuesChanged = <T>(oldObject: T, newObject: T, keys: (keyof T)[]) => {
  return keys.some((key) => objectValueChanged(oldObject, newObject, key));
};

type GenericGeometryCoordinates = Coordinate | Coordinate[] | Coordinate[][] | Coordinate[][][];

export function isMultiPolygon(
  coordinates: GenericGeometryCoordinates,
  featureType: string,
): coordinates is Coordinate[][][] {
  return featureType === 'MultiPolygon';
}

export function isPolygon(coordinates: GenericGeometryCoordinates, featureType: string): coordinates is Coordinate[][] {
  return featureType === 'Polygon';
}

export function isLineString(
  coordinates: GenericGeometryCoordinates,
  featureType: string,
): coordinates is Coordinate[] {
  return featureType === 'LineString';
}

export function isPolyCoord(coords: SketchCoordType): coords is PolyCoordType {
  return Array.isArray(coords[0]) && Array.isArray(coords[0][0]);
}

export function isLineCoord(coords: SketchCoordType): coords is LineCoordType {
  return Array.isArray(coords[0]) && typeof coords[0][0] === 'number';
}

export function isPointCoord(coords: SketchCoordType): coords is PointCoordType {
  return typeof coords[0] === 'number';
}

export function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

export const AccessLevelStore = LocalStorageGenerator<{ expires: number; accessLevel: SettingsAccessLevel }>(
  'accessLevel',
  {
    expires: 0,
    accessLevel: 'Locked',
  },
);

export const MapMakingStore = LocalStorageGenerator<{ emptyZones: boolean; emptySamples: boolean }>('MapMakingStore', {
  emptyZones: false,
  emptySamples: false,
});

export const UseTestLoaderStore = LocalStorageGenerator<boolean>('UseTestLoaderStore', false);

// TODO we should move these somewhere else. We almost need a global robot settings
// object that also holds these conversions instead of keeping them just in RobotPanel
export function convertToPercent(data: number) {
  return roundToIncrement((data / 65535) * 100, 0.01);
}

export function mmToEigthInch(data: number) {
  return roundToIncrement(data / MM_PER_INCH, 0.125);
}

export function mpsToMph(data: number) {
  return Math.round(data * MPS_TO_MPH);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertDepthOffset(offset: number) {
  return Math.round(Math.abs(offset) * MM_PER_INCH) * -Math.sign(offset);
}

export function clearTimer(timer?: NodeJS.Timer | null) {
  if (timer && timer[Symbol.toPrimitive]) {
    // https://stackoverflow.com/questions/46588994/how-to-use-setinterval-and-clearinterval-in-nodejs
    clearInterval(timer[Symbol.toPrimitive]());
  }
}

export function radiansToDegrees(radians: number) {
  return (radians * 180) / Math.PI;
}

export function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

export function clamp(lower: number, value: number, upper: number) {
  return Math.max(lower, Math.min(value, upper));
}

export function getCloseCores(cores: SoilCoreList, coordinate: Coordinate, toleranceInMeters?: number) {
  const closeCores = cores
    .map((core) => {
      const distanceToCore = distanceLatLon(coordinate[0], coordinate[1], core.lat, core.lon, 'Kilometers') * 1000;

      const shouldKeep = toleranceInMeters ? distanceToCore < toleranceInMeters : true;
      return [core, distanceToCore, shouldKeep] as const;
    })
    .filter((coreTuple) => coreTuple[2]); // filter out cores that are too far away

  // calculate this outside of the return for benchmarking reasons
  const result = closeCores.sort((coreStructA, coreStructB) => coreStructA[1] - coreStructB[1]);

  return result;
}

export function coordsEqual(a: Coordinate | undefined, b: Coordinate | undefined) {
  if (!a && !b) {
    return true;
  }

  if (a && !b) {
    return false;
  }

  if (!a && b) {
    return false;
  }

  // this is a redundant check but it makes TypeScript happy
  if (a && b) {
    return equals(a, b);
  }

  return false;
}

export function calculateAcres(features: Feature<Polygon>[]) {
  let acres = 0.0;
  for (let f of features) {
    const geom = f.getGeometry();
    if (geom) {
      acres += getArea(geom) / SQ_M_PER_ACRE; // m^2 to acres
    }
  }
  return round(acres, 2);
}

// Function to rotate a point around a given origin by an angle (in degrees)

export const rotatePoint = (point: GeometryPoint, origin: GeometryPoint, angle: number): GeometryPoint => {
  // Convert angle to radians
  const theta = (angle * Math.PI) / 180;

  // Rotation matrix components
  const cosTheta = Math.cos(theta);
  const sinTheta = Math.sin(theta);

  // Translate the point to the origin
  const translatedX = point.x - origin.x;
  const translatedY = point.y - origin.y;

  // Apply the rotation matrix
  const rotatedX = translatedX * cosTheta - translatedY * sinTheta + origin.x;
  const rotatedY = translatedX * sinTheta + translatedY * cosTheta + origin.y;

  // Return the rotated point
  return { x: rotatedX, y: rotatedY };
};

export const isNumeric = (str: string) => {
  if (typeof str !== 'string') {
    return false;
  }

  return (
    !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  );
};
