import React from 'react';

import { Grid, Table, TableContainer, ButtonBase, TableBody, TableCell, TableRow, Paper } from '@material-ui/core';
import { AirtableRecord } from '../../db';

interface DropoffListProps {
  list: AirtableRecord[];
  updateZoomField: (dropoff: AirtableRecord) => void;
}

export default function DropoffList(props: DropoffListProps) {
  return (
    <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
      <TableContainer component={Paper} className="scroll" style={{ maxWidth: '100%' }}>
        <Table>
          {props.list.map((dropoff) => {
            return (
              <ButtonBase
                onClick={() => {
                  props.updateZoomField(dropoff);
                }}
                component={TableBody}
                style={{ border: '2px solid black' }}
                disableTouchRipple
                disableRipple
              >
                <TableCell colSpan={1} component="th" scope="column" align="center">
                  {dropoff.get('Name')}
                </TableCell>
                <TableRow>
                  <TableCell>{dropoff.get('Location')}</TableCell>
                </TableRow>
              </ButtonBase>
            );
          })}
        </Table>
      </TableContainer>
    </Grid>
  );
}
