import { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';

import AlertMessage from './AlertMessage';

import logger from '../../logger';

import { ALERT_DISPATCH_EVENTS, ALERT_RETURN_EVENTS, ALERT_EVENT_NAME } from '../../alertEvents';
import { ALERT_TYPES } from './alertTypes';
import EventBus from '../../EventBus';
import { Color } from '@material-ui/lab/Alert';
import { AlertEvent, CancelConfirmEvent } from '../../alertDispatcher';
import { ClassNameMap, ClassKeyOfStyles } from '@material-ui/styles/withStyles';
import { AlertsDisposeTimeout } from '../../db/local_storage';

const styles = {
  root: {
    pointerEvents: 'auto',
    position: 'relative',
    margin: 5,
  },
};

interface AlertsProps {
  showAll: boolean;
  incrementAlertBadge: () => void;
  clearAlertBadge: () => void;
  // TODO this is the best type I can determine based on the withStyles .d.ts
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
}

interface AlertsState {
  alerts: AlertEvent[];
}

class Alerts extends PureComponent<AlertsProps, AlertsState> {
  DISPOSE_TIME = AlertsDisposeTimeout.get();

  constructor(props: AlertsProps) {
    super(props);

    this.state = {
      alerts: [],
    };

    this.handleAlert = this.handleAlert.bind(this);
    this.disposeConfirm = this.disposeConfirm.bind(this);
    this.handleCancelAlert = this.handleCancelAlert.bind(this);
    this.disposeAlert = this.disposeAlert.bind(this);
  }

  componentDidMount() {
    EventBus.on(ALERT_EVENT_NAME, this.handleAlert);
  }

  componentDidUpdate(prevProps: AlertsProps) {
    if (!prevProps.showAll && this.props.showAll) {
      this.props.clearAlertBadge();
    }
  }

  componentWillUnmount() {
    EventBus.dispatch(ALERT_RETURN_EVENTS.UNMOUNT);
    EventBus.remove(ALERT_EVENT_NAME, this.handleAlert);
  }

  addConfirmAlert(newAlert: AlertEvent) {
    const alertsCopy = this.state.alerts.slice();
    const activeAlert = alertsCopy.find(
      (activeAlert) => activeAlert.active && activeAlert.message === newAlert.message,
    );
    if (!activeAlert) {
      newAlert.active = true;
      alertsCopy.unshift(newAlert);
    } else {
      console.log('FOUND DUP', newAlert.confirmNum);
      EventBus.dispatch(ALERT_RETURN_EVENTS.CONFIRM_RETURN, {
        message: newAlert.message,
        confirm: false,
        confirmNum: newAlert.confirmNum,
      });
    }
    this.setState({ alerts: alertsCopy });
  }

  async disposeConfirm(message: string, confirm: boolean, confirmNum: number) {
    EventBus.dispatch(ALERT_RETURN_EVENTS.CONFIRM_RETURN, { message, confirm, confirmNum } as CancelConfirmEvent);
    this.disposeAlert(message);
  }

  addAlert(newAlert: AlertEvent) {
    const activeAlert = this.state.alerts.find(
      (activeAlert) => activeAlert.active && activeAlert.message === newAlert.message,
    );
    const timeoutId = setTimeout(() => this.disposeAlert(newAlert.message), newAlert.timeout ?? this.DISPOSE_TIME);
    if (activeAlert) {
      clearTimeout(activeAlert.timeoutId);
      activeAlert.timeoutId = timeoutId;
    } else {
      newAlert.timeoutId = timeoutId;
      newAlert.active = true;
      this.state.alerts.unshift(newAlert);
    }
    this.setState({ alerts: this.state.alerts.slice() });
  }

  disposeAlert(message: string, tag?: string) {
    const alertsCopy = this.state.alerts.slice();
    const activeAlert = alertsCopy.find(
      (activeAlert) => message && activeAlert.active && activeAlert.message === message,
    );
    if (activeAlert) {
      clearTimeout(activeAlert.timeoutId);
      activeAlert.active = false;
    } else {
      const activeAlerts = alertsCopy.filter(
        (activeAlert) => tag && activeAlert.active && 'tag' in activeAlert && activeAlert.tag === tag,
      );
      activeAlerts.forEach((activeAlert) => {
        activeAlert.active = false;
      });
    }
    this.setState({ alerts: alertsCopy });
  }

  handleCancelAlert({ message, tag, confirm }: { message: string; tag: string; confirm: boolean }) {
    this.disposeAlert(message, tag);
  }

  async handleAlert(newAlert: AlertEvent) {
    await logger.log('ADDING_ALERT', newAlert);
    this.props.incrementAlertBadge();
    switch (newAlert.type) {
      case ALERT_DISPATCH_EVENTS.CONFIRM:
      case ALERT_DISPATCH_EVENTS.CONFIRM_WARN:
      case ALERT_DISPATCH_EVENTS.CONFIRM_ERROR:
        this.addConfirmAlert(newAlert);
        break;
      default:
        this.addAlert(newAlert);
        break;
    }
  }

  render() {
    const alerts = this.props.showAll ? this.state.alerts : this.state.alerts.filter((a) => a.active);
    const elementName = this.props.showAll ? 'allAlerts' : 'activeAlerts';
    const bottom = this.props.showAll ? '' : 0;
    const position = this.props.showAll ? 'relative' : 'fixed';
    const direction = this.props.showAll ? 'column' : 'row';
    return (
      <Portal container={document.getElementById(elementName)}>
        <Grid
          container
          spacing={1}
          style={{ position: position, zIndex: 1600, bottom: bottom, display: 'inline-block' }}
          direction={direction}
        >
          {alerts.map((activeAlert, idx) => (
            <Grid item key={idx}>
              {this.props.showAll ? (
                <Grid item justifyContent="center">
                  <AlertMessage
                    id={idx}
                    category={ALERT_TYPES[activeAlert.type] as Color}
                    message={activeAlert.message}
                    needsConfirm={Boolean(activeAlert.needsConfirm)}
                    actions={activeAlert.actions}
                    onConfirm={this.disposeConfirm}
                    confirmNum={activeAlert.confirmNum}
                    showAll={this.props.showAll}
                  />
                </Grid>
              ) : (
                <Snackbar
                  key={Math.random() * 10000}
                  open={true}
                  classes={{ root: this.props.classes.root }}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                  <AlertMessage
                    id={idx}
                    category={ALERT_TYPES[activeAlert.type] as Color}
                    message={activeAlert.message}
                    needsConfirm={Boolean(activeAlert.needsConfirm)}
                    actions={activeAlert.actions}
                    onConfirm={this.disposeConfirm}
                    disposeAlert={this.disposeAlert}
                    confirmNum={activeAlert.confirmNum}
                    showAll={this.props.showAll}
                  />
                </Snackbar>
              )}
            </Grid>
          ))}
        </Grid>
      </Portal>
    );
  }
}

// for some reason, typescript doesn't like the `position` and `pointerEvents properties
// we'll leave this for now, should probably review later
// @ts-ignore
export default withStyles(styles)(Alerts);
