import React, { Component } from 'react';

import { IconButton, Grid, Button } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CloseIcon from '@material-ui/icons/Close';
import { FacingMode } from '../../types/types';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import { alertError } from '../../alertDispatcher';

interface PhotoCaptureProps {
  handleLoadFailure(err: unknown): void;
  handleCapture(imageBlob: Blob | null): Promise<void>;
  closeCapture(): void;
  onCanPlay(): void;
}

interface PhotoCaptureState {
  ready: boolean;
  photo: Blob | null;
  cameraFacingMode: FacingMode;
  videoRef?: React.RefObject<HTMLVideoElement>;
}

export default class PhotoCapture extends Component<PhotoCaptureProps, PhotoCaptureState> {
  videoHeight: number;
  stream: MediaStream;
  videoWidth: number;

  constructor(props: PhotoCaptureProps) {
    super(props);
    this.state = {
      ready: false,
      photo: null,
      cameraFacingMode: 'environment',
      videoRef: undefined,
    };
    this.videoHeight = 600;
    this.videoWidth = 800;

    this.initializeVideo = this.initializeVideo.bind(this);
    this.uninitializeVideo = this.uninitializeVideo.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.handleLoadFailure = this.handleLoadFailure.bind(this);
  }

  async componentDidMount() {
    const element = document.getElementById('video') as HTMLVideoElement;
    if (element) element.setAttribute('playsinline', 'true');
    // set the element width and height
    const devices = await navigator.mediaDevices.enumerateDevices();
    for (const device of devices) {
      console.log(device);
    }
    const constraints: MediaStreamConstraints = { video: { facingMode: this.state.cameraFacingMode }, audio: false };
    try {
      await this.handleVideo(await navigator.mediaDevices.getUserMedia(constraints));
    } catch (err: unknown) {
      this.props.handleLoadFailure(err);
    }
  }

  componentWillUnmount() {
    this.uninitializeVideo();
  }

  /**
   * Init video, get user camera and add event handler or call event handler
   */
  async initializeVideo() {
    this.setState({ videoRef: React.createRef() });
    const constraints = { video: { facingMode: this.state.cameraFacingMode }, audio: false };
    try {
      await this.handleVideo(await navigator.mediaDevices.getUserMedia(constraints));
    } catch (err: unknown) {
      this.handleLoadFailure(err);
    }
  }

  /**
   *
   * @param {unknown} err Handle if the video fails to load
   */
  handleLoadFailure(err: unknown) {
    alertError('Something went wrong while loading video!');
    console.error(err);

    this.props.handleLoadFailure(err);
  }

  /**
   * Uninit video, stop all tracks from a steam and set the videoRef to null
   */
  uninitializeVideo() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }

  /**
   * Switch camera view, either to environment or user
   */
  switchCamera() {
    // this.takePicture();
    this.uninitializeVideo();
    this.setState(
      (prevState) => ({
        cameraFacingMode: prevState.cameraFacingMode === 'user' ? 'environment' : 'user',
      }),
      async () => {
        await this.initializeVideo();
      },
    );
  }

  async handleVideo(stream: MediaStream) {
    this.stream = stream;
    const elem = document.getElementById('video') as HTMLVideoElement;
    elem.srcObject = stream;
    elem.addEventListener(
      'loadedmetadata',
      () => {
        const canvas = document.getElementById('preview') as HTMLCanvasElement;
        if (!canvas) return;
        const ratio = elem.videoWidth / elem.videoHeight;
        this.videoWidth = elem.videoWidth - 100;
        this.videoHeight = this.videoWidth / ratio;
        canvas.width = this.videoWidth;
        canvas.height = this.videoHeight;
      },
      false,
    );
    await elem.play();
    this.setState({ ready: true });
  }

  render() {
    return (
      <div style={{ height: '100vh', alignItems: 'center' }}>
        <IconButton onClick={this.props.closeCapture} style={{ position: 'absolute', right: 10, zIndex: 100 }}>
          <CloseIcon fontSize={'large'} />
        </IconButton>
        <IconButton onClick={this.switchCamera} style={{ position: 'absolute', zIndex: 200, color: 'white' }}>
          <FlipCameraIosIcon fontSize={'large'} />
        </IconButton>
        <video
          id="video"
          ref={this.state.videoRef}
          style={{ width: '100vw', height: '100vh', objectFit: 'fill', display: this.state.photo ? 'none' : undefined }}
          onCanPlay={this.props.onCanPlay}
        ></video>
        {/* <canvas id='preview' style={{ width: '100vw', height: '100vh', display: this.state.photo ? 'block' : 'none', position: 'absolute' }} /> */}
        <canvas
          id="preview"
          style={{ width: '100vw', height: '100vh', objectFit: 'fill', display: this.state.photo ? undefined : 'none' }}
        />
        {this.state.ready && (
          <Grid container xs={12} justifyContent="center">
            {!this.state.photo && (
              <IconButton
                onClick={async () => {
                  console.log('PhotoCapture:onClick');
                  const video = document.getElementById('video') as HTMLVideoElement;
                  const canvas = document.getElementById('preview') as HTMLCanvasElement;
                  if (!canvas) return;
                  canvas.getContext('2d')?.drawImage(video, 0, 0, this.videoWidth, this.videoHeight);
                  // canvas.toBlob((blob) => { this.props.handleCapture(blob) }, 'image/png', 1);
                  canvas.toBlob(
                    (blob) => {
                      this.setState({ photo: blob });
                    },
                    'image/png',
                    1,
                  );
                }}
                style={{
                  position: 'absolute',
                  bottom: 100,
                  transform: 'scale(5)',
                }}
                color="primary"
              >
                <RadioButtonCheckedIcon color="primary" />
              </IconButton>
            )}
            {!!this.state.photo && (
              <>
                <Button
                  variant={'outlined'}
                  size={'large'}
                  style={{
                    position: 'absolute',
                    fontSize: 20,
                    marginTop: 10,
                    bottom: 40,
                    left: 0,
                    width: '50%',
                    zIndex: 1600,
                    color: 'white',
                    backgroundColor: 'red',
                  }}
                  onClick={() => {
                    this.setState({
                      photo: null,
                    });
                  }}
                >
                  Retake Photo
                </Button>
                <Button
                  variant={'outlined'}
                  size={'large'}
                  style={{
                    position: 'absolute',
                    fontSize: 20,
                    marginTop: 10,
                    bottom: 40,
                    left: '50%',
                    width: '50%',
                    zIndex: 1600,
                    color: 'white',
                    backgroundColor: 'green',
                  }}
                  onClick={async () => {
                    this.uninitializeVideo();
                    await this.props.handleCapture(this.state.photo);
                    this.setState({
                      photo: null,
                    });
                  }}
                >
                  Save Photo
                </Button>
              </>
            )}
          </Grid>
        )}
      </div>
    );
  }
}
