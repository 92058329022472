import React, { useState } from 'react';
import ShippingView from './shipping/ShippingView';

import VoidLabelDialog from './shipping/VoidLabelDialog';

export default function ShippingTask() {
  const [voidLabelPopupVisible, setVoidLabelPopupVisible] = useState(false);

  return (
    <React.Fragment key={'shipping'}>
      <ShippingView showVoidLabelPopup={() => setVoidLabelPopupVisible(true)} />
      <VoidLabelDialog
        setVoidLabelPopupVisible={setVoidLabelPopupVisible}
        voidLabelPopupVisible={voidLabelPopupVisible}
      />
    </React.Fragment>
  );
}
