import React, { PureComponent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Modal,
  Grid,
  Typography,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { FaQrcode } from 'react-icons/fa';
import BarcodeScanner from '../../utils/BarcodeScanner';

import logger from '../../../logger';
import EventBus from '../../../EventBus';
import { alertConfirm, cancelConfirmTag, alertError, alertSuccess } from '../../../alertDispatcher';
import { SampleBox } from '../../../db';
import { BarcodeProcessorStateStore, generateErrorMap, getErrorsFromMap } from '../../../barcodes';
import { dispatchActiveBoxUpdated } from '../../../boxEvents';
import { SCAN_EVENTS } from '../../../scanEvents';
import { PRINTER_CONFIG, PRINTER_OPTIONS } from '../../robot/configs/PrinterConfig';
import { BarcodeFormat } from '@zxing/library';
import { successTone } from '../../../alertTones';
import { SettingsAccessLevel } from '../../../types/types';
import { getCurrentSession } from '../../../dataModelHelpers';
import { LoadingButton } from '../../utils';

export interface BoxExitInterviewProps {
  boxInstanceId: number;
  box: SampleBox;
  onClose: (v?: boolean) => Promise<void>;
  closeBox: (box: SampleBox) => Promise<void>;
  boxUid: string;
  isReprint: boolean;
  // TODO this should be its own type that is shared with SamplingMissionView
  boxMissions: { fieldName: string; instanceId: number; numSamples: number }[];
  printerConfig: PRINTER_CONFIG;
  accessLevel: SettingsAccessLevel;
  handlePrinterSelect: (config: PRINTER_CONFIG) => void;
  openBoxExitInterview: (box?: SampleBox) => Promise<void>;
}

export type Issues = {
  dupError: boolean;
  gapError: boolean;
  invalidError: boolean;
};

export interface BoxExitInterviewState {
  activeScan: boolean;
  barcodeIssue: boolean;
  issues: Issues;
  bypassText: string;
  boxHasClosedMissions: boolean;
  boxFriendlyId: string;
}

export default class BoxExitInterview extends PureComponent<BoxExitInterviewProps, BoxExitInterviewState> {
  BYPASS_TEXT = 'Bypass';

  constructor(props: BoxExitInterviewProps) {
    super(props);
    this.state = {
      activeScan: false,
      barcodeIssue: false,
      issues: {
        dupError: false,
        gapError: false,
        invalidError: false,
      },
      bypassText: '',
      boxHasClosedMissions: false,
      boxFriendlyId: '',
    };

    this.updateDetectedCode = this.updateDetectedCode.bind(this);
    this.closeScanner = this.closeScanner.bind(this);
    this.startScanner = this.startScanner.bind(this);
    this.handleRemoveMission = this.handleRemoveMission.bind(this);
    this.handleDeleteBox = this.handleDeleteBox.bind(this);
  }

  componentDidMount() {
    console.log(`Mounting ${this.constructor.name}`);
    EventBus.on(SCAN_EVENTS.SCANNED_BOX, this.updateDetectedCode);
    BarcodeProcessorStateStore.set({ barcodeScanningPaused: true, boxScanningPaused: false });
    this.getBarcodeErrors();
    if (this.boxHasClosedMissions()) {
      this.setState({ boxHasClosedMissions: true });
    }
  }

  componentWillUnmount() {
    console.log(`Unmounting ${this.constructor.name}`);
    EventBus.remove(SCAN_EVENTS.SCANNED_BOX, this.updateDetectedCode);
    BarcodeProcessorStateStore.set({ barcodeScanningPaused: false, boxScanningPaused: false });
    cancelConfirmTag(this.constructor.name);
  }

  getBarcodeErrors() {
    const box = SampleBox.get(this.props.boxInstanceId);
    if (!box) return;
    const boxFriendlyId = box.friendlyId;
    const samples = box.getSamples();
    const errMap = generateErrorMap(samples);
    const errors = getErrorsFromMap(errMap);
    if (errors.dupError || errors.invalidError) {
      console.log('INVALID ERROR', errors.invalidError, errors.dupError);
      this.setState({ barcodeIssue: true, issues: errors, boxFriendlyId });
    } else {
      this.setState({ boxFriendlyId });
    }
  }

  boxHasClosedMissions() {
    const box = SampleBox.get(this.props.boxInstanceId);
    if (!box) return;
    const missions = box.getSampleBoxMissions();
    const session = getCurrentSession();
    if (!session) return;

    const currentMission = session.getMission();
    return missions
      .filter((mission) => mission !== currentMission)
      .some((mission) => mission.getAllSamples().every((sample) => !!sample.bag_id));
  }

  async updateDetectedCode(detectedCode: string) {
    if (this.state.barcodeIssue) return;
    await logger.log('UPDATE_DETECTED_CODE', `Detected code=${detectedCode}`);
    const box = SampleBox.get(this.props.boxInstanceId);
    if (!box) {
      throw new Error('Box not found');
    }

    const boxUid = box.uid;
    const validCode = detectedCode.includes(box.uid);
    if (validCode) {
      this.closeScanner();
      await this.props.closeBox(box);
      successTone();
      alertSuccess(`Closed box successfully, uid: ${boxUid}`);
      this.props.onClose(false);
      await logger.log('UPDATE_DETECTED_CODE', 'Valid code');
    } else {
      alertError(`Incorrect Box, uid ${boxUid}. Please ensure that you printed the correct checkin sheet.`);
    }
  }

  /**
   * Handles removal of mission from box
   */
  // TODO JobLoader types
  async handleRemoveMission(boxMission: { fieldName: string; instanceId: number; numSamples: number }) {
    const confirm = await alertConfirm(
      `Are you sure you want to clear job "${boxMission.fieldName}" from this box?`,
      this.constructor.name,
    );
    if (confirm) {
      const box = SampleBox.get(this.props.boxInstanceId);
      if (!box) {
        throw new Error('Box not found');
      }
      // TODO This was null in one test, why???
      await box.clearSamples(boxMission.instanceId);
      const samples = box.getSamples();
      if (!samples.length) {
        await this.props.onClose();
      }
    }
  }

  async handleDeleteBox() {
    await SampleBox.logBoxes('BOXES_BEFORE_DELETING_BOX');

    const confirm = await alertConfirm(
      'Are you sure you want to delete this box? You will lose all barcodes for this box.',
      this.constructor.name,
    );

    if (confirm) {
      await logger.log('DELETE_BOX', 'confirmed');

      // const box = SampleBox.getCurrentBox();
      this.props.box.clearAllSamples();
      this.props.box.Session_id = undefined;
      await this.props.onClose();
      await this.props.box.dispose();
      dispatchActiveBoxUpdated();
    }

    await SampleBox.logBoxes('BOXES_AFTER_DELETING_BOX');
  }

  closeScanner() {
    this.setState({ activeScan: false });
  }

  startScanner() {
    this.setState({ activeScan: true });
  }

  render() {
    const box = SampleBox.get(this.props.boxInstanceId);
    const printOrReprint = box?.ReprintSession_id ? 'Reprint' : 'Print';
    return (
      <>
        <Dialog open={true} maxWidth={'md'}>
          <DialogTitle>
            <div>
              <b>{printOrReprint}</b> and Scan Manifest for Box with ID{' '}
              <b>
                {this.props.boxUid.toUpperCase()} {this.state.boxFriendlyId ? ` (${this.state.boxFriendlyId})` : ''}
              </b>
            </div>
            {this.props.isReprint && (
              <Typography variant="body1">
                <b>Reprint Reason:</b> {box?.reprint_reason ?? 'N/A'}
              </Typography>
            )}

            {this.props.isReprint && (
              <Typography variant="body2" color="error">
                Please Remove Incorrect Manifest and Box Checkin Sheet and Replace with the New Print Outs
              </Typography>
            )}

            {this.state.barcodeIssue && (
              <React.Fragment>
                <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
                  There is issues with the barcodes provided, please fix before continuing.
                </Typography>
                {Object.keys(this.state.issues).map((issue) => (
                  <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
                    {issue === 'dupError' && this.state.issues[issue]
                      ? 'Duplicate barcode(s) detected!'
                      : issue === 'invalidError' && this.state.issues[issue]
                        ? 'Invalid barcode(s) detected'
                        : ''}
                  </Typography>
                ))}
              </React.Fragment>
            )}
            {this.state.boxHasClosedMissions && (
              <React.Fragment>
                <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
                  {`Warning: This box has samples from closed missions so you cannot delete it.`}
                </Typography>
                {!this.state.barcodeIssue && (
                  <Typography variant="body2" color="error" style={{ fontStyle: 'italic' }}>
                    {`You can scan the label or bypass scanning to close the box instead`}
                  </Typography>
                )}
              </React.Fragment>
            )}
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={1} style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
              {this.props.boxMissions.map((boxMission) => {
                return (
                  <Chip
                    onDelete={async () => await this.handleRemoveMission(boxMission)}
                    label={`${boxMission.fieldName ? boxMission.fieldName : 'No Field Name'} - ${boxMission.numSamples} samples`}
                    color={'secondary'}
                    style={{ margin: '5 0 5 10' }}
                    key={`${boxMission.instanceId}`}
                  />
                );
              })}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: 20, display: 'block' }}>
            <Grid
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
              }}
            >
              <Typography variant="body1" style={{ marginRight: 5, marginTop: 7 }}>
                BARCODE SCANNER READY (V2) <FaQrcode style={{ marginLeft: 7.5 }} />
              </Typography>

              <Button
                onClick={this.startScanner}
                variant="outlined"
                style={{ marginRight: 5 }}
                disabled={this.state.barcodeIssue}
              >
                Camera (V1) <FaQrcode style={{ marginLeft: 7.5 }} />
              </Button>

              {this.props.accessLevel === 'Technician' && (
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-age-native-simple">Printer Config</InputLabel>
                  <Select
                    label="Printer Config"
                    value={this.props.printerConfig}
                    onChange={(e) => this.props.handlePrinterSelect(e.target.value as PRINTER_CONFIG)}
                    style={{
                      marginRight: 5,
                      height: 38,
                      fontSize: 14,
                      overflow: 'hidden',
                      width: 156,
                    }}
                  >
                    {PRINTER_OPTIONS.map((config) => (
                      // @ts-ignore
                      <MenuItem key={config.label} value={config} style={{ fontSize: 14 }}>
                        {config.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <LoadingButton onClick={async () => await this.props.openBoxExitInterview(box)} variant="outlined">
                Show PDF
              </LoadingButton>
            </Grid>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
                margin: '10px 0 0 0',
              }}
            >
              <TextField
                label={`Scan label or type "${this.BYPASS_TEXT.toUpperCase()}"`}
                variant="outlined"
                value={this.state.bypassText}
                onChange={(e) => {
                  this.setState({ bypassText: e.target.value });
                }}
                style={{
                  marginRight: 20,
                }}
                disabled={this.state.barcodeIssue}
                data-testid="bypass-text-field"
              />

              <Button
                variant="outlined"
                onClick={async () => {
                  await logger.log('BYPASS_SCAN', 'User bypassed box scan');
                  await this.updateDetectedCode(this.props.boxUid);
                }}
                style={{
                  marginRight: 10,
                }}
                disabled={
                  this.state.barcodeIssue || this.state.bypassText.toLowerCase() !== this.BYPASS_TEXT.toLowerCase()
                }
              >
                Close Without Scan
              </Button>
              {!this.props.isReprint && (
                <>
                  <Button
                    onClick={this.handleDeleteBox}
                    variant="outlined"
                    style={{
                      marginRight: 10,
                      color: !this.state.boxHasClosedMissions ? '#cc0000' : '',
                      borderColor: !this.state.boxHasClosedMissions ? '#cc0000' : '',
                    }}
                    disabled={this.state.boxHasClosedMissions}
                  >
                    Delete
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={async () => {
                      await logger.log('CANCEL_SCAN', 'User cancelled box scan');
                      this.props.onClose(true);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </DialogActions>
        </Dialog>

        <Modal style={{ height: '100%', width: '100%' }} open={true} className={this.state.activeScan ? '' : 'hide'}>
          <div>
            <Grid container justifyContent="center">
              {this.state.activeScan && (
                <BarcodeScanner
                  updateDetectedCode={this.updateDetectedCode}
                  closeScanner={this.closeScanner}
                  handleLoadFailure={() => this.setState({ activeScan: false })}
                  fullScreen={true}
                  facingMode={'user'}
                  type={[BarcodeFormat.QR_CODE]}
                  sampleScanner={false}
                />
              )}
            </Grid>
          </div>
        </Modal>
      </>
    );
  }
}
