import { Component, ErrorInfo, PropsWithChildren } from 'react';

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
  errorString: string;
}

export default class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorString: '',
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    this.setState({ errorString: JSON.stringify(error) });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1>
          Something went wrong.
          <pre>{this.state.errorString}</pre>
          <button
            onClick={() => {
              // eslint-disable-next-line no-self-assign
              //window.location.href = window.location.href;
              window.location.reload();
            }}
          >
            Reload
          </button>
        </h1>
      );
    }
    return this.props.children;
  }
}
