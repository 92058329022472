import React, { PureComponent } from 'react';

import { Card, Button, Slide } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Property } from 'csstype';

interface SlideoutTextboxProps {
  dialogOpen: boolean;
  message: string;
  closeDialog: () => void;
  title: string;
  expandedWidth: Property.Width<(string & {}) | 0> | undefined;
  customClass?: string;
}

export default class SlideoutTextBox extends PureComponent<SlideoutTextboxProps> {
  render() {
    return (
      <Slide
        style={{
          position: 'fixed',
          bottom: '15px',
          right: '10px',
        }}
        direction="left"
        in={this.props.dialogOpen}
      >
        <Card
          className={this.props.customClass}
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            width: this.props.expandedWidth,
            padding: '10px 15px',
          }}
          variant="outlined"
        >
          <div style={{ color: 'black' }}>
            <div>{this.props.title}</div>
            {this.props.message.length ? (
              this.props.message.split('\n').map((item, key) => {
                return <span key={key} dangerouslySetInnerHTML={{ __html: item + '<br />' }}></span>;
              })
            ) : (
              // <div dangerouslySetInnerHTML={{ __html: this.props.message }} />
              <div>No notes found.</div>
            )}
          </div>

          <Button
            variant="outlined"
            style={{
              minWidth: '0px',
              width: 40,
              color: '#cc0000',
              borderColor: '#cc0000',
            }}
            onClick={this.props.closeDialog}
          >
            <CloseIcon style={{ fontSize: 18 }} />
          </Button>
        </Card>
      </Slide>
    );
  }
}
