import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { round } from '../../../utils';
import { AirtableRecord } from '../../../db';
import { Chip, Tooltip } from '@material-ui/core';
import { Jobs } from '@rogoag/airtable';

const styles = (theme) => ({
  doneRoot: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  doneButton: {
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  partialRoot: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  partialButton: {
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  },
});

interface MapsJobsProps {
  id: string;
  job: AirtableRecord<Jobs>;
  loadMissionMaps: (id: string) => Promise<void>;
  name: string;
  order: number;
}

interface MapsJobState {
  ready: boolean;
  numSamples?: number;
  boundAcres: number;
  done: string;
}

class MapsJob extends PureComponent<MapsJobsProps, MapsJobState> {
  constructor(props: MapsJobsProps) {
    super(props);
    this.state = {
      ready: false,
      numSamples: 0,
      boundAcres: 0,
      done: '',
    };
  }

  async componentDidMount() {
    await this.initJobInfo();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      await this.initJobInfo();
    }
  }

  async initJobInfo() {
    if (!this.state.ready) {
      await this.checkAttachmentsReady();
    }
    this.setState({
      numSamples: this.props.job.get('# Samples'),
      boundAcres: round(this.props.job.get('Boundary Acres') || 0, 1) || 0,
      done: this.props.job.get('Sample Date'),
    });
  }

  async checkAttachmentsReady() {
    if (this.props.job.attachments_up_to_date()) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
      await this.props.job.refresh_attachments({ map_making: true });
      this.setState({ ready: true });
      // this.props.job.refresh_attachments().then(() => {
      //   this.setState({ ready: true });
      // });
    }
  }

  getSitesTypeColor = (sitesType: string) => {
    switch (sitesType) {
      case 'Grid':
        return 'green';
      case 'Zone':
        return 'blue';
      case 'ModGrid':
        return 'purple';
      default:
        return 'red';
    }
  };

  render() {
    const jobHasMapIssue = this.props.job.get('Job Flags')?.includes('Map Issue');
    const mapIssueDescription = this.props.job.get('Job Flag Notes');
    const sitesType = this.props.job.get('Deal Sites Type')?.[0] ?? '?';
    const points = this.props.job.get('Pts Shp');
    const hasPoints = points && points.length > 0;

    const zones = this.props.job.get('Sample Zones Shp');
    const hasZones = zones && zones.length > 0;
    return (
      <React.Fragment>
        <Tooltip
          title={
            <Typography style={{ whiteSpace: 'pre-line' }}>
              {[
                `Operator: ${this.props.job.get('Operator Name')?.length > 0 ? this.props.job.get('Operator Name')[0] : 'Unassigned'}`,
                `Robot: ${this.props.job.get('Robot') ?? 'None'}`,
                `Sites Type: ${sitesType}`,
                hasPoints ? 'Includes Points' : '',
                hasZones ? 'Includes Zones' : '',
              ]
                .filter((s) => !!s)
                .join('\r\n')}
            </Typography>
          }
        >
          <ListItem
            disabled={Boolean(!this.state.ready || this.state.done)}
            key={this.props.id}
            divider
            button
            // data-testid={`job-browser-${this.props.order}`}
            onClick={async () => {
              await this.props.loadMissionMaps(this.props.id);
            }}
          >
            <ListItemText primary={this.props.name} style={{ overflow: 'hidden' }} />
            {jobHasMapIssue && (
              <Tooltip title={<Typography>{mapIssueDescription ?? ''}</Typography>}>
                <Chip size="medium" style={{ color: 'white', backgroundColor: 'red' }} label="Map Issue" />
              </Tooltip>
            )}
            <Box flexDirection={'column'} style={{ paddingLeft: 20 }}>
              <ListItemText
                primary={
                  <Typography>
                    <b>{this.state.numSamples}</b> samples, <b>{this.state.boundAcres}</b> ac.
                  </Typography>
                }
              />
            </Box>
            {/* <Chip size='medium' style={{ color: 'white', backgroundColor: this.getSitesTypeColor(sitesType) }} label={sitesType} /> */}
          </ListItem>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MapsJob);
