import 'use-sync-external-store/shim'; // polyfills useSyncExternalStore for using react-redux v.8 with react 17
import 'use-sync-external-store/shim/with-selector'; // polyfills useSyncExternalStoreWithSelector for using react-redux v.8 with react 17

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/store';
import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
  // material-ui menu drawer has an issue in strict mode; supposedly fixed in v5
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
