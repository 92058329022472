import { Mission, Sample, SampleBox } from './db';

import EventBus from './EventBus';
import { SCAN_EVENTS } from './scanEvents';
import { LocalStorageGenerator, wait } from './utils';
import { createSampleMap } from './dataModelHelpers';

import { alertError, alertWarn, alertConfirm, alertSuccess, alertFullScreen } from './alertDispatcher';
import { isBarcodeValidBag, isBarcodeValidBox } from './barcode_rules';

import logger from './logger';
import { getCurrentMission, getCurrentSession } from './dataModelHelpers';

import { successTone, errorTone, warningTone } from './alertTones';
import { SampleErrorMap, SampleErrors } from './types/types';
import { dispatchUpdatedCurrentSample } from './sessionEvents';
import { SampleList } from './db/SampleClass';

import * as Sentry from '@sentry/react';
import { dispatchRosMsgPub } from './RobotConnection';
import { CoreCountEnforcementEnabled, ScannerPauseDelayMs } from './db/local_storage';
import CoreValidator from './services/CoreValidator';

export interface BarcodeProcessorState {
  barcodeScanningPaused: boolean;
  boxScanningPaused: boolean;
}

export const BarcodeProcessorStateStore = LocalStorageGenerator<Partial<BarcodeProcessorState>>(
  'BarcodeProcessorStateStore',
  {
    barcodeScanningPaused: false,
    boxScanningPaused: false,
  },
);

let pause = false;

/**
 * Validates a barcode and saves if it is valid
 * @param {string} detectedCode The code detected
 */
export async function processScan(detectedCode: string) {
  if (pause) {
    return;
  }

  await SampleBox.logBoxes('BOXES_BEFORE_SCAN', true);

  pause = true;
  try {
    console.log('BARCODE', { detectedCode });
    if (isBarcodeValidBox(detectedCode)) {
      if (BarcodeProcessorStateStore.get().boxScanningPaused) {
        return;
      }

      EventBus.dispatch(SCAN_EVENTS.SCANNED_BOX, detectedCode);
    } else {
      if (BarcodeProcessorStateStore.get().barcodeScanningPaused) {
        alertWarn('Barcode scanning is currently paused');
        return;
      }

      const session = getCurrentSession();
      if (!session) {
        errorTone();
        alertError(`No current session! Please soft reset your robot`);
        return;
      }

      const mission = getCurrentMission();
      if (!mission) {
        errorTone();
        alertError(`No current mission found!`);

        return;
      }

      const currentSample = session.getSample();
      if (!currentSample) {
        errorTone();
        alertError(`No current sample selected for scanning!`);
        return;
      }

      const canSave = await validateCanSave(detectedCode, currentSample);
      if (!canSave) {
        return;
      }

      const totalCores = currentSample.getSoilCores();
      const pulledCores = totalCores.filter((core) => core.pulled);
      const coresValidator = new CoreValidator(mission);
      const hasEnoughCores = coresValidator.sampleHasEnoughCores(currentSample);

      if (CoreCountEnforcementEnabled.get() && !hasEnoughCores) {
        errorTone();
        alertError(
          `Please record all cores before scanning a barcode (${totalCores.length} required, only ${pulledCores.length} pulled)!`,
        );
        return;
      }

      const job = mission.getJob();
      const measurementsRequired = job?.manual_measurement_required;
      // const sampleHasAllMeasurements = currentSample.getSoilCores().every(core => core.core_length_cm !== undefined);
      const coresMissingMeasurements = currentSample.getSoilCores().filter((core) => !core.core_length_cm);
      const sampleHasAllMeasurements = coresMissingMeasurements.length === 0;
      if (CoreCountEnforcementEnabled.get() && measurementsRequired && !sampleHasAllMeasurements) {
        errorTone();
        alertError(
          `Please record all core measurements before scanning a barcode! Missing measurement for core ${coresMissingMeasurements.map((core) => core.name).join(', ')}`,
        );
        return;
      }

      const box = SampleBox.getCurrentBox();
      if (!box) {
        errorTone();
        alertError(`Unable to retrieve box!`);
        return;
      }
      const boxSamples = box.getSamples();
      const hasBarcode = Boolean(currentSample.bag_id);
      let overwrite = false;
      if (hasBarcode) {
        errorTone();
        const okToWrite = await alertConfirm(
          `Sample ${currentSample.sample_id} already has barcode! Do you want to overwrite?`,
        );
        if (!okToWrite) {
          return;
        } else {
          // TODO display large popup with barcode/bag to remove from box
          const sampleIndex = boxSamples.findIndex((sample) => sample.instance_id === currentSample.instance_id);
          const removed = await alertFullScreen(
            `Please remove bag ${currentSample.bag_id}`,
            `Box Order Packing Position: ${sampleIndex + 1}`,
            ['Ok'],
          );
          logger.log('BARCODE', `Removed sample? ${removed}`);
          overwrite = true;
        }
      }

      console.log(`Overwrite? ${overwrite}`);

      await checkBoxPackingLimits(boxSamples);

      const errMap = await setSampleBarcodes(currentSample.instance_id, detectedCode, false);

      successTone();
      alertSuccess(
        `Successfully scanned barcode ${detectedCode} for sample ${currentSample.sample_id} into ${box.short_uid}!`,
        {
          actions: {
            Reject: async () => {
              const errMap = await setSampleBarcodes(currentSample.instance_id, '', false);
              await checkGapError(errMap);
              EventBus.dispatch(SCAN_EVENTS.SCANNED_BARCODE, {
                barcode: '',
                currentSampleInstanceId: currentSample.instance_id,
                errMap,
                nextSampleInstanceId: currentSample.instance_id,
              });
              const oldSampleInstanceId = session.Sample_id;
              session.Sample_id = currentSample.instance_id;
              dispatchUpdatedCurrentSample([oldSampleInstanceId, currentSample.instance_id]);
            },
          },
        },
      );

      await checkGapError(errMap); // alerts user if scanned sample had any errors

      const nextSampleInstanceId: number | undefined = undefined;

      console.log(`barcodes.ts processScan nextSampleInstanceId`, nextSampleInstanceId);

      EventBus.dispatch(SCAN_EVENTS.SCANNED_BARCODE, {
        barcode: detectedCode,
        currentSampleInstanceId: currentSample.instance_id,
        errMap,
        nextSampleInstanceId,
      });

      const oldSampleInstanceId = session.Sample_id;
      session.Sample_id = nextSampleInstanceId;
      dispatchUpdatedCurrentSample([oldSampleInstanceId, nextSampleInstanceId]);
    }
  } catch (err) {
    Sentry.setContext('processScan', { detectedCode });
    Sentry.captureException(err);
    console.warn(err);
  } finally {
    await wait(ScannerPauseDelayMs.get()); // wait 1 second to prevent double scans
    pause = false;

    await SampleBox.logBoxes('BOXES_AFTER_SCAN', true);
  }
}

async function checkBoxPackingLimits(boxSamples: SampleList) {
  const filled = boxSamples.filter((sample) => sample.bag_id).length;
  console.log(`barcodes:processScan:box has ${filled + 1} filled samples`);
  switch (filled + 1) {
    case 30:
      alertWarn(`You now have ${filled + 1} samples in your box. Please be sure to close your box if it is full`);
      break;
    case 35:
    case 40:
    case 45:
      await alertFullScreen(
        'Did you forget to close your digital box?',
        `You have now boxed <strong>${filled + 1}</strong> samples in your box`,
        ['Ok'],
      );
  }
}

/**
 * Checks that all barcodes are valid
 */
export function allBarcodesValid() {
  const mission = getCurrentMission();
  if (!mission) {
    return false;
  }
  const samples = mission.getAllSamples();
  const errMap = generateErrorMap(samples);
  const { dupError, gapError, invalidError } = getErrorsFromMap(errMap);
  console.log('ERROR?', dupError, gapError, invalidError);
  return !dupError && !gapError && !invalidError;
}

/**
 * Creates a map of errors for a given array of samples
 * @param {Sample[]} samples
 * @returns {SampleErrorMap} Samples mapped to errors
 */
export function generateErrorMap(samples: Sample[]) {
  const errMap = createSampleMap(samples);

  if (!samples) {
    return errMap;
  }

  const bagIds: string[] = []; // used to keep track of bag ids to check for duplicates

  let lastSampleIdx: number | null = null;
  for (let sampleIdx = 0; sampleIdx < samples.length; sampleIdx++) {
    const sample = samples[sampleIdx];

    if (
      (sample.bag_id && lastSampleIdx !== null && sampleIdx - lastSampleIdx !== 1) ||
      (sample.bag_id && lastSampleIdx === null && sampleIdx !== 0)
    ) {
      for (
        let sampleGapIdx = lastSampleIdx === null ? 0 : lastSampleIdx + 1;
        sampleGapIdx < sampleIdx;
        sampleGapIdx++
      ) {
        const sampleWithGap = samples[sampleGapIdx];
        if (sampleWithGap.sample_id !== undefined && !sampleWithGap.skipped_or_deleted) {
          errMap[sampleWithGap.sample_id].gapError = true;
        }
      }
    }

    if (sample.bag_id) {
      lastSampleIdx = sampleIdx;
    }

    if (
      sample.bag_id &&
      bagIds.find((existId) => existId === sample.bag_id) &&
      !sample.skipped_or_deleted &&
      sample.sample_id !== undefined
    ) {
      errMap[sample.sample_id].dupError = true;
    }

    if (
      sample.bag_id &&
      !isBarcodeValidBag(sample.bag_id, sample.getBarcodeRegex()) &&
      sample.sample_id !== undefined
    ) {
      errMap[sample.sample_id].invalidError = true;
    }

    if (sample.bag_id) {
      bagIds.push(sample.bag_id);
    }
  }

  return errMap;
}

/**
 * Checks error map for duplicate error, gap error, and invalid error
 * @param {object} errMap Error map to check
 * @returns {object} Contains information for what type of errors are present
 */
export function getErrorsFromMap(errMap: SampleErrorMap) {
  let dupError = false;
  let gapError = false;
  let invalidError = false;
  const checkError = (sampleErr: SampleErrors) => {
    if (sampleErr.dupError) {
      dupError = true;
    }
    if (sampleErr.gapError) {
      gapError = true;
    }
    if (sampleErr.invalidError) {
      invalidError = true;
    }
  };
  for (let sampleErr of Object.values(errMap)) {
    checkError(sampleErr);
  }

  return { dupError, gapError, invalidError };
}

/**
 * Checks error map for a gap
 * @param {object} errMap Error map to check
 */
export async function checkGapError(errMap: SampleErrorMap) {
  // local function to do error checking for a single sample
  const _checkGapError = async (sampleErr: SampleErrors) => {
    if (sampleErr.gapError) {
      await errorTone();
      alertWarn(`There is a gap detected in the scanned samples! Please check the mission panel!`);
      return true;
    }
  };

  for (let sampleErr of Object.values(errMap)) {
    if (await _checkGapError(sampleErr)) break;
  }
}

/**
 * Finds the next sample given a sample
 * @param   {Sample} currentSample The current sample id
 * @returns {Sample} The next sample
 */
export function findNextSample(currentSample?: Sample) {
  const mission = getCurrentMission();

  if (!mission) return undefined;

  const allSamples = mission.getAllSamples();
  let sampleIdx = 0;
  // If we have a sample id, find the index of the sample with that id
  if (currentSample) {
    sampleIdx = allSamples.findIndex((sample: Sample) => sample.sample_id === currentSample.sample_id);
    // If we don't have a sample id, return the instance id of the first sample
  } else {
    return allSamples[0];
  }

  // If the sample index is the last index in the array, return the instance id of the last sample
  if (sampleIdx === allSamples.length - 1) {
    return allSamples[allSamples.length - 1];
    // If the sample index is -1, log an error
  } else if (sampleIdx === -1) {
    console.error(`Could not find sample! ${currentSample.sample_id}`);
    // Otherwise, return the instance id of the next sample
  } else {
    return allSamples[sampleIdx + 1];
  }
}

/**
 * Checks for any potential failures before saving, only used for scanning
 * @param {string} barcode the text of the barcode
 */

export async function validateCanSave(barcode: string, sample: Sample) {
  const session = getCurrentSession();
  if (!session) {
    return false;
  }

  const mission = getCurrentMission();
  const job = mission?.getJob();

  // Check 1: Does this bag meet our rules for what we can scan as a sample?
  const barcodeValid = isBarcodeValidBag(barcode, sample.getBarcodeRegex());

  // if it's not a valid barcode, don't bother with any additional logic
  if (!barcodeValid) {
    return false;
  }

  let box = SampleBox.getCurrentBox();

  // Check 2: Does this bag already exist in the box or mission or a past mission?
  const duplicateBarcode = await checkForDuplicates(barcode, box, mission);
  if (duplicateBarcode) {
    return false;
  }

  // we will do duplicate check even if we don't have a mission so we can
  // always identify a misplaced bag, but if we don't have a mission we don't
  // actually need to continue with any barcode validation
  if (!mission) return false;

  const spec = sample.getSamplingSpec();

  if (!spec) {
    errorTone();
    alertError('No spec found for sample!');
    return false;
  }

  const boxLabName = box?.labName.replace(/"/g, '');
  const specLabName = spec.lab_name.replace(/"/g, '');
  const specLabCode = parseInt(spec.lab_code);

  const boxLabNameMatches = boxLabName === specLabName;

  if (!boxLabNameMatches) {
    // check if we can switch boxes
    const openBoxes = SampleBox.getOpenBoxes();
    console.log(`${openBoxes.length} open boxes found (${openBoxes.map((box) => box.uid).join(', ')})`);
    const matchingOpenBoxes = openBoxes.filter((box) => box?.labCode === specLabCode && !box.closed);
    if (matchingOpenBoxes.length > 1) {
      errorTone();
      alertError(`Multiple open boxes for ${specLabName} found!`);
      return false;
    }

    if (matchingOpenBoxes.length === 1) {
      box = session.makeBoxActive(matchingOpenBoxes[0]);
    }

    if (matchingOpenBoxes.length === 0) {
      box = undefined;
    }
  }

  // Fail-safe: If we don't have a box, we can prompt to add it
  if (!box) {
    errorTone();
    const confirm = await alertConfirm(`No box is currently open for ${specLabName}, would you like to add one?`);
    if (confirm) {
      const newBox = await session.addAndSelectBox(mission, spec);
      if (!newBox) {
        errorTone();
        alertError('Failed to add box!');
      }
      box = SampleBox.getCurrentBox();
      if (!box) {
        errorTone();
        alertError('No active box in session');
      }
    }
  }

  // since we might ask the user to add a box, we can only be
  // certain we can add it if the box was truly created
  return !!box;
}

/**
 * Checks for duplicates in the box and mission
 * @param box  The box to check
 * @param barcode  The barcode to check
 * @param mission  The mission to check
 * @param allSamples  All samples in the mission
 * @returns  The sample that is duplicated
 */
async function checkForDuplicates(barcode: string, box?: SampleBox, mission?: Mission) {
  logger.log('CHECK_FOR_DUPLICATES', { barcode, box: box?.uid, mission: mission?.name });
  let duplicatedInBox: Sample | undefined = undefined;
  let duplicatedInMission: Sample | undefined = undefined;

  // If we have a box...
  if (box) {
    duplicatedInBox = checkBoxForDuplicates(barcode, box, mission);
  }

  // Check all mission samples for duplicate barcodes
  if (mission) {
    const allSamples = mission.getAllSamples();
    for (let sample of allSamples) {
      if (barcode === sample.bag_id) {
        duplicatedInMission = sample;
        break;
      }
    }
  }

  let duplicatedInPastMission: Sample | undefined = undefined;
  // if it wasn't duplicated in any of the missions in this box, then we will search all samples to be safe
  if (!duplicatedInBox && !duplicatedInMission) {
    duplicatedInPastMission = Sample.findOne((sample) => !!sample && sample.bag_id === barcode);
    const duplicatedSampleMissionInstanceId = duplicatedInPastMission?.getSampleSite()?.getMission()?.instance_id;
    const currentMissionInstanceId = mission?.instance_id;
    const sampleIdsUndefined = !duplicatedSampleMissionInstanceId || !currentMissionInstanceId;
    if (
      duplicatedInPastMission?.mission_name === mission?.name ||
      (!sampleIdsUndefined && currentMissionInstanceId === duplicatedSampleMissionInstanceId)
    ) {
      alertWarn(`Contact Stephen, he might have figured it out!`);
      logger.log('CHECK_FOR_DUPLICATES', duplicatedInPastMission);
    }
  }

  // if we have a duplicate mission barcode or a duplicate box barcode, alert the user
  if (duplicatedInBox) {
    warningTone();
    alertError(`Duplicate barcode detected in box ${box?.uid || 'UNKNOWN'}! Barcode ${barcode}`);
  } else if (duplicatedInMission || duplicatedInPastMission) {
    // we can force this null check because our boolean means one of these has to be defined, and if the first one is
    // undefined, then the second one must be defined since we're in this block
    const bag_id = duplicatedInMission?.bag_id;
    const box_uid = duplicatedInMission?.box_uid;
    const mission_name = duplicatedInMission ? duplicatedInMission.mission_name : duplicatedInPastMission!.mission_name;
    const boxQuery = SampleBox.query((box: SampleBox) => box.uid === box_uid);
    if (boxQuery.length) {
      warningTone();
      const box = boxQuery[0];
      const boxSamples = box.getSamples();
      const friendlyBoxId = box.friendlyId;
      const sampleIndex = boxSamples.findIndex((sample) => sample.bag_id === bag_id) + 1;
      await alertFullScreen(
        'We found this sample in another box',
        [
          `Bag <strong>${bag_id}</strong> is already in`,
          `Box <strong>${box_uid}</strong>, position ${sampleIndex}` + (friendlyBoxId ? ` (${friendlyBoxId})` : ''),
          `Mission <strong>${mission_name}</strong>`,
        ].join('\n'),
        ['Ok'],
      );
    }
  }

  const duplicateBarcode = duplicatedInBox || duplicatedInMission || duplicatedInPastMission;
  return duplicateBarcode;
}

function checkBoxForDuplicates(barcode: string, box: SampleBox, mission?: Mission) {
  let duplicatedInBox: Sample | undefined = undefined;
  const duplicateBoxSamples = box
    .getSamples()
    .filter((sample) => sample.bag_id === barcode && !sample.skipped_or_deleted);

  // Check the box for duplicate barcodes
  if (duplicateBoxSamples.length) {
    duplicatedInBox = duplicateBoxSamples[0];
  }

  // check the box for duplicate missions in this box
  if (mission) {
    const boxMissions = box.getSampleBoxMissions();
    if (
      boxMissions.find(
        (existMission) => existMission.job_id === mission.job_id && existMission.instance_id !== mission.instance_id,
      )
    ) {
      alertError('The mission being scanned already exists in the box! Make sure to clear the duplicate!');
    }
  }
  return duplicatedInBox;
}

/**
 * Updates all data class instance's with new barcode and SampleBox id
 * @param {number} modifiedSampleInstanceId The sample instance id being modified
 * @param {string} barcode The new barcode to replace existing barcode
 * @param {boolean} override An override switch that ignores box check
 */
export async function setSampleBarcodes(
  modifiedSampleInstanceId: number,
  barcode: string,
  override: boolean,
): Promise<SampleErrorMap> {
  logger.log('SET_SAMPLE_BARCODES', { modifiedSampleInstanceId, barcode, override });

  const modifiedSample = Sample.get(modifiedSampleInstanceId);
  if (!modifiedSample || modifiedSample.sample_id === undefined) {
    logger.log('Failed to set sample barcodes');
    return {} as SampleErrorMap;
  }

  const sampleBox = SampleBox.getCurrentBox();
  const mission = getCurrentMission();
  if (!sampleBox) {
    throw new Error('Cannot retrieve box');
  }

  if (!override && modifiedSample.SampleBox_id !== undefined && sampleBox.instance_id !== modifiedSample.SampleBox_id) {
    logger.log(
      'SET_SAMPLE_BARCODES',
      `Can not modify sample outside of current box! ${override} ${modifiedSample.SampleBox_id}, ${sampleBox.instance_id}`,
    );

    throw new Error('Can not modify sample outside of current box!');
  } else {
    if (!mission) {
      logger.log('Cannot retrieve mission');

      throw new Error('Cannot retrieve mission');
    }

    modifiedSample.setBarcode(barcode, sampleBox?.instance_id);
    const msg = { data: `${modifiedSample.sample_id};${barcode}` };
    dispatchRosMsgPub({
      hostname: getCurrentSession()?.robot_hostname || '',
      topic: 'app/barcode/completed',
      compress: false,
      tag: 'barcode',
      msg,
    });

    const allSamples = mission.getAllSamples();
    const boxSamples = sampleBox?.getSamples();
    const sampleErrMap = generateErrorMap(allSamples);
    const boxErrMap = generateErrorMap(boxSamples);

    return { ...sampleErrMap, ...boxErrMap };
  }
}
