import React, { PureComponent } from 'react';

import { TextField, Popper, Grid, Paper, IconButton, withStyles, Button, Typography, Box } from '@material-ui/core';

import MapButton from './MapButton';
import { IoMdOptions } from 'react-icons/io';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingButton } from '../../utils';

import { MAP_TYPES, MapType } from '../helpers/mapTypes';

import EventBus from '../../../EventBus';
import { dispatchMissionUpdated, MISSION_EVENTS } from '../../../missionEvents';
import { getCurrentMission } from '../../../dataModelHelpers';
import { LocalStorageGenerator, updateState } from '../../../utils';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles/withStyles';
import { SettingsAccessLevel } from '../../../types/types';
import { alertSuccess } from '../../../alertDispatcher';
import { FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB, METERS_BETWEEN_CORE_LINES_FOR_MULTI_LAB } from '../../../constants';

const styles = (theme) => ({
  label: {
    fontSize: '14px',
  },
});

interface SamplingParametersButtonProps {
  zoneMission: boolean;
  type: MapType;
  accessLevel: SettingsAccessLevel;
  // TODO this is the best type I can determine based on the withStyles .d.ts
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  addCheckpoint: (name: string) => void;
}

interface SamplingParametersButtonState {
  open: boolean;
  numCores: number;
  angle: number;
  length: number;
  anchorEl: HTMLElement | null;
  missionId: string;
  originalCores: number;
  line_separation_ft: number;
}

interface NumberOfCores {
  cores: number;
  missionId: string;
}

const coreNumberStorage = LocalStorageGenerator<NumberOfCores>('coreNumberStorage', { cores: 0, missionId: '' });

class SamplingParametersButton extends PureComponent<SamplingParametersButtonProps, SamplingParametersButtonState> {
  SERIALIZABLE_STATE_VARIABLES: (keyof SamplingParametersButtonState)[] = ['open'];
  shiftDown: boolean;
  ctrlDown: boolean;

  constructor(props: SamplingParametersButtonProps) {
    super(props);

    this.state = {
      numCores: 0,
      originalCores: coreNumberStorage.get().cores,
      missionId: coreNumberStorage.get().missionId,
      length: 0,
      open: false,
      anchorEl: null,
      angle: 0,
      line_separation_ft: FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.getParameters = this.getParameters.bind(this);
    this.handleUpdateParameters = this.handleUpdateParameters.bind(this);
    this.shiftDown = false;
    this.ctrlDown = false;
  }

  componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.getParameters);
    this.deserializeState();

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);

    const anchorEl = document.getElementById('samplingParameterButton');
    this.setState({ anchorEl });
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.UPDATED, this.getParameters);
    this.serializeState();

    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      this.shiftDown = true;
    }

    if (event.key === 'Control') {
      this.ctrlDown = true;
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      this.shiftDown = false;
    }

    if (event.key === 'Control') {
      this.ctrlDown = false;
    }
  };

  componentDidUpdate(_prevProps: SamplingParametersButtonProps, prevState: SamplingParametersButtonState) {
    if (this.state.open && !prevState.open) {
      this.getParameters();
    }
  }

  /**
   * serialize the state into session
   */
  serializeState() {
    const serializedState = {};
    for (const stateKey of this.SERIALIZABLE_STATE_VARIABLES) {
      serializedState[stateKey] = this.state[stateKey];
    }
    localStorage.setItem('SamplingParametersButtonState', JSON.stringify(serializedState));
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const serializedState = JSON.parse(localStorage.getItem('SamplingParametersButtonState') || '{}');
    if (serializedState) {
      for (const stateKey of Object.keys(serializedState)) {
        this.setState(updateState(stateKey as keyof SamplingParametersButtonState, serializedState[stateKey]));
      }
    }
  }

  getParameters() {
    const mission = getCurrentMission();
    const spec = mission?.getPrimarySpec();
    let originalCores = this.state.originalCores;
    if (this.state.missionId !== mission?.job_id) {
      originalCores = spec?.cores || 0;
    }
    this.setState({
      numCores: spec?.cores ?? 0,
      length: spec?.length ?? 0,
      missionId: mission?.job_id ?? '',
      originalCores,
      angle: spec?.angle ?? 0,
      line_separation_ft: spec?.parallel_line_distance_ft ?? 0,
    });
  }

  toggleOpen(_: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    this.setState({ open: !this.state.open });
  }

  async handleUpdateParameters() {
    try {
      this.props.addCheckpoint('Update Path');
      const mission = getCurrentMission();
      if (!mission) {
        return;
      }

      const sampling = this.props.type === MAP_TYPES.SAMPLING;

      const spec = mission.getPrimarySpec();
      await spec?.update_parameters({
        cores: this.state.numCores,
        length: this.state.length,
        angle: this.state.angle,
        parallel_line_distance_ft: this.state.line_separation_ft,
      });
      // just get core waypoints
      const cores = mission
        .getWaypoints()
        .map((waypoint) => waypoint.getCorePoint()?.getSoilCore())
        .filter((core) => !!core);

      if (sampling) {
        await mission.calculate_path_restore_cores(cores);
      } else {
        await mission.calculate_path(cores);
      }

      alertSuccess('Updated sampling parameters');
    } catch (err) {
      console.error('Could not update parameters', err);
    }
    dispatchMissionUpdated();
  }

  render() {
    const minCores = Math.max(this.state.originalCores - 1, 6);
    const maxCores =
      this.state.originalCores > 8 ? this.state.originalCores : Math.min(this.state.originalCores + 2, 8);
    const atMinCores = this.state.numCores <= minCores;
    const atMaxCores = this.state.numCores >= maxCores;
    const mapMaking = this.props.type === MAP_TYPES.MAPS;
    const canAdjustDown = this.props.accessLevel !== 'Locked' || mapMaking || !atMinCores;
    const canAdjustUp = this.props.accessLevel !== 'Locked' || mapMaking || !atMaxCores;
    const cantAdjustDown = !canAdjustDown;
    const cantAdjustUp = !canAdjustUp;
    const specCount = getCurrentMission()?.spec_count;
    const multiSpec = specCount && specCount > 1;
    return (
      <React.Fragment>
        <MapButton
          tooltip="Sampling Parameters"
          toggled={Boolean(this.state.open)}
          onClick={this.toggleOpen}
          id={'samplingParameterButton'}
        >
          <IoMdOptions fontSize={18} />
        </MapButton>
        <Popper anchorEl={this.state.anchorEl} open={this.state.open} style={{ zIndex: 2, minWidth: 230 }}>
          <Paper style={{ padding: 10 }}>
            <IconButton
              onClick={() => this.setState({ open: false })}
              size={'small'}
              style={{ position: 'absolute', right: 5, color: 'black' }}
            >
              <CloseIcon fontSize={'medium'} />
            </IconButton>
            <Grid item xs={12}>
              {/* sx={{ border: '2px solid grey' }} */}
              <Box style={{ padding: 10, marginBottom: 10, borderColor: 'black', borderWidth: 2, borderRadius: 1 }}>
                <Typography variant={'body2'} style={{ marginBottom: 10 }}>
                  Cores
                </Typography>
                <Button
                  disabled={cantAdjustDown}
                  variant={'outlined'}
                  style={{ textTransform: 'none', marginTop: 1 }}
                  onClick={() => {
                    this.setState({ numCores: this.state.numCores - 1 });
                  }}
                >
                  -
                </Button>
                <TextField
                  value={this.state.numCores}
                  inputProps={{ readOnly: true }}
                  variant={'outlined'}
                  type={'number'}
                  size={'small'}
                  style={{ marginLeft: 5, marginRight: 5, maxWidth: 80, alignContent: 'center' }}
                />
                <Button
                  disabled={cantAdjustUp}
                  variant={'outlined'}
                  style={{ textTransform: 'none', marginTop: 1 }}
                  onClick={() => {
                    this.setState({ numCores: this.state.numCores + 1 });
                  }}
                >
                  +
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {/* sx={{ border: '2px solid grey' }} */}
              <Box style={{ padding: 10, marginBottom: 10, borderColor: 'black', borderWidth: 2, borderRadius: 1 }}>
                <Typography variant={'body2'} style={{ marginBottom: 10 }}>
                  Angle
                </Typography>
                <Button
                  disabled={cantAdjustDown}
                  variant={'outlined'}
                  style={{ textTransform: 'none', marginTop: 1 }}
                  onClick={() => {
                    this.setState({ angle: this.state.angle - (this.shiftDown ? 0.1 : this.ctrlDown ? 5 : 1) });
                  }}
                >
                  -
                </Button>
                <TextField
                  value={this.state.angle}
                  inputProps={{ readOnly: true }}
                  variant={'outlined'}
                  type={'number'}
                  size={'small'}
                  style={{ marginLeft: 5, marginRight: 5, maxWidth: 80, alignContent: 'center' }}
                />
                <Button
                  disabled={cantAdjustUp}
                  variant={'outlined'}
                  style={{ textTransform: 'none', marginTop: 1 }}
                  onClick={() => {
                    this.setState({ angle: this.state.angle + (this.shiftDown ? 0.1 : this.ctrlDown ? 5 : 1) });
                  }}
                >
                  +
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {!mapMaking && atMinCores && (
                <span style={{ marginLeft: 5, color: 'red' }}>{`Minimum cores: ${minCores}`}</span>
              )}

              {!mapMaking && atMaxCores && (
                <span style={{ marginLeft: 5, color: 'red' }}>{`Maximum cores: ${maxCores}`}</span>
              )}
            </Grid>
            {!this.props.zoneMission && (
              <Grid item xs={12}>
                <TextField
                  disabled={this.props.type === MAP_TYPES.SAMPLING && this.props.accessLevel === 'Locked'}
                  value={this.state.length}
                  variant={'outlined'}
                  label={'length'}
                  type={'number'}
                  size={'small'}
                  style={{ marginLeft: 10, marginRight: 40, marginTop: 10, maxWidth: 220 }}
                  onChange={(event) => this.setState({ length: parseInt(event.target.value || '') })}
                />
              </Grid>
            )}
            {multiSpec && (
              <Grid item xs={12}>
                <TextField
                  disabled={this.props.type === MAP_TYPES.SAMPLING && this.props.accessLevel === 'Locked'}
                  value={this.state.line_separation_ft}
                  variant={'outlined'}
                  label={'line distance'}
                  type={'number'}
                  size={'small'}
                  style={{ marginLeft: 10, marginRight: 40, marginTop: 10, maxWidth: 220 }}
                  onChange={(event) => this.setState({ line_separation_ft: parseInt(event.target.value.toString()) })}
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: 'right', marginRight: 10, marginTop: 10 }}>
              <LoadingButton
                style={{ textTransform: 'none' }}
                size={'medium'}
                color={'secondary'}
                variant={'contained'}
                onClick={this.handleUpdateParameters}
              >
                Update Path
              </LoadingButton>
            </Grid>
          </Paper>
        </Popper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SamplingParametersButton);
