import { Component } from 'react';
import { Theme, withTheme } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CircularProgress from '@material-ui/core/CircularProgress';

import { wait } from '../../utils';
import { LoadingAction } from '../App';
import EventBus from '../../EventBus';

//const UPDATE_INTERVAL = 100;  // throttle updating progress

interface LoadingScreenProps {
  action: LoadingAction | undefined;
  theme?: Theme;
}

interface LoadingScreenState {
  variant: 'determinate' | 'indeterminate';
  progress: number;
  active: boolean;
  paused: boolean;
}

class LoadingScreen extends Component<LoadingScreenProps, LoadingScreenState> {
  currentProgress: number;
  lastUpdate: number;

  constructor(props: LoadingScreenProps) {
    super(props);
    this.state = {
      variant: 'indeterminate',
      progress: 0,
      active: false,
      paused: false,
    };
    // initialize to garbage, might need to do something different?
    this.lastUpdate = 0;
    this.currentProgress = 0;
    this.handleUpdateProgress = this.handleUpdateProgress.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.doneLoading = this.doneLoading.bind(this);
  }

  handleUpdateProgress(progress: number) {
    if (!isNaN(progress)) {
      // ignore if not a number
      this.currentProgress = Math.max(0, Math.min(100, Math.abs(progress)));
      this.setState(
        {
          progress: this.currentProgress,
          paused: progress < 0,
        },
        () => {
          this.lastUpdate = Date.now();
        },
      );
    }
  }

  pause = () => {
    this.setState({ paused: true });
  };

  resume = () => {
    this.setState({ paused: false });
  };

  handleCancel() {
    if (this.props.action) {
      this.props.action.onError(new Error('Operation was cancelled by user'));
    }
    this.doneLoading();
  }

  async doneLoading() {
    // slight delay prevents flickering effect
    await wait(250);
    this.setState({ active: false, progress: 0 });
  }

  async initialize() {
    if (this.props.action) {
      const { monitorProgress } = this.props.action;
      this.setState(
        {
          variant: monitorProgress ? 'determinate' : 'indeterminate',
          active: true,
        },
        this.runAction,
      ); // wait until after component re-renders to assure user cannot click
    }
  }

  async runAction() {
    if (this.props.action) {
      const { action, onSuccess, onError } = this.props.action;
      try {
        action(this.handleUpdateProgress).then(onSuccess).catch(onError).finally(this.doneLoading);
      } catch (err) {
        // TODO types this is not ideal...
        onError(err as Error);
        this.doneLoading();
      }
    } else {
      this.doneLoading();
    }
  }

  async componentDidMount() {
    await this.initialize();
    EventBus.on('LOADING_SCREEN:PAUSE', this.pause);
    EventBus.on('LOADING_SCREEN:RESUME', this.resume);
  }

  async componentDidUpdate(prevProps: LoadingScreenProps) {
    if (this.props.action !== prevProps.action) {
      await this.initialize();
    }
  }

  async componentWillUnmount() {
    EventBus.remove('LOADING_SCREEN:PAUSE', this.pause);
    EventBus.remove('LOADING_SCREEN:RESUME', this.resume);
  }

  render() {
    const loadingClasses =
      this.props.theme?.palette.type === 'light'
        ? 'fullscreen-fixed on-top loading-light'
        : 'fullscreen-fixed on-top loading-dark';
    return (
      <div className={!this.state.active || this.state.paused ? 'hide' : loadingClasses} data-testid="loading-screen">
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <IconButton onClick={this.handleCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-50px',
            marginLeft: '-50px',
          }}
        >
          <CircularProgress
            color="secondary"
            size={100}
            thickness={2}
            variant={this.state.variant}
            value={this.state.progress}
          />
        </div>
      </div>
    );
  }
}

export default withTheme(LoadingScreen);
