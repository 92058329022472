import React, { PureComponent } from 'react';

import MapButton from './MapButton';

import { AiOutlineClose } from 'react-icons/ai';

interface DeselectAllButtonProps {
  onClick: () => void;
}

export default class DeselectAllButton extends PureComponent<DeselectAllButtonProps> {
  render() {
    return (
      <MapButton tooltip="Deselect" style={{ backgroundColor: 'red', width: 200 }} onClick={this.props.onClick}>
        <AiOutlineClose size={20} />
      </MapButton>
    );
  }
}
