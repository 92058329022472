import { PureComponent } from 'react';
import BaseMap from './BaseMap';

import GeometryType from 'ol/geom/GeometryType';
import Collection from 'ol/Collection';
import { GREEN, RED, BROWN, PLUM, BURLY_WOOD } from '../../rgbColors';
import {
  SampleChangeType,
  CoordinatePoint,
  DRAW_TYPES,
  JobType,
  OnLoading,
  SampleSource,
  eventIsDrawEvent,
  ChangeReason,
  SetWaypointFunction,
  SetZoneWaypointFunction,
} from '../../types/types';

import { DiCelluloid } from 'react-icons/di';
import { IoMdAddCircleOutline } from 'react-icons/io';

import CenterMissionButton from './buttons/CenterMissionButton';
import AssignmentButton from './buttons/AssignmentButton';
import DeselectAllButton from './buttons/DeselectAllButton';
import MapButton from './buttons/MapButton';
import KmlLayer from './layers/KmlLayer';
import DrawLayer from './layers/DrawLayer';
import DrawButton from './buttons/DrawButton';
import DeleteButton from './buttons/DeleteButton';
import UndoButton from './buttons/UndoButton';
import WaypointSetButton from './buttons/WaypointSetButton';
import { KML_FEATURE_TYPE, KmlFeatureType } from '../../featureTypes';
import { alertError, alertInfo, alertSuccess, alertWarn, alertWarnConfirm } from '../../alertDispatcher';
import { createCheckpoint, loadCheckpoint } from '../../db/checkpoint';
import { GridPatterns, Mission, SoilCore, ZoneType } from '../../db';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import { Extent, getCenter } from 'ol/extent';
import { getCurrentMission } from '../../dataModelHelpers';
import { dispatchMissionUpdated, MISSION_EVENTS } from '../../missionEvents';
import {
  SetStateAsync,
  convertProjection3857,
  convertProjection4329,
  isPointDelete,
  setStateAsync,
  updateState,
} from '../../utils';
import { calculateArmPosition } from '../../services/RobotArmService';
import EventBus from '../../EventBus';
import SamplingParametersButton from './buttons/SamplingParametersButton';
import MapOverlay from './overlays/MapOverlay';
import { MAP_TYPES, MapType } from './helpers/mapTypes';
import {
  SelectCandidate,
  DrawConfig,
  RobotConnectionStatus,
  RobotNavControl,
  SettingsAccessLevel,
} from '../../types/types';
import { RosMissionMsg, RosPosition } from '../../types/rosmsg';
import { Coordinate } from 'ol/coordinate';
import { getFeatureType } from './helpers/features';
import { ModifyEvent } from 'ol/interaction/Modify';
import Feature, { FeatureLike } from 'ol/Feature';
import { LineString, Point, SimpleGeometry, Geometry } from 'ol/geom';
import MapLoadingButton from './buttons/MapLoadingButton';
import { FaBarcode } from 'react-icons/fa';
import { BsArrowsMove } from 'react-icons/bs';
import { findSampleById, setSampleSelected } from '../../sampleSelectionHelpers';
import BaseEvent from 'ol/events/Event';
import DrawingMarkersLayer from './layers/DrawingMarkersLayer';
import SkipButton from './buttons/SkipButton';
import { ShowChangeTypeSelectorPopup, promptForChangeReason } from '../../changeReasonHelpers';
import { setSampleBarcodes } from '../../barcodes';
import logger from '../../logger';
import PlotGenerationButton from './buttons/PlotGenerationButton';
import GridLayer, { GridLayerFeature } from './layers/GridLayer';
import GridGenerationButton from './buttons/GridGenerationButton';
import { CREATE_NEW_POLYGON } from '../../constants';
import { CoreCountEnforcementEnabled } from '../../db/local_storage';
import { dispatchSetCenter, dispatchSetExtent, MAP_EVENTS } from '../../mapEvents';
import { GiMultipleTargets } from 'react-icons/gi';
import { BiRename } from 'react-icons/bi';
import { ManualMeasurementDialogParameters } from '../../services/ManualMeasurementsChecker';
import CoresDialog from './dialogs/CoresDialog';
import { TbShovel } from 'react-icons/tb';

const featureIsSkippedOrDeleted = (feature: Feature<Geometry>) => {
  const properties = feature.getProperties();
  if ('change_type' in properties) {
    const changeType = properties.change_type;
    return changeType === 'Skip' || changeType === 'Delete';
  }
  return false;
};
/**
 * Common Map Functionality between the sampling map and map maker map
 */
interface CommonMapProps {
  type: MapType;
  buttons: React.ReactNode[];
  layers: React.ReactNode[];
  absoluteOverlays?: React.ReactNode[];
  secondRowElements?: React.ReactNode[];
  rotation?: { value: number };
  setWaypoint?: SetWaypointFunction;
  setZoneWaypoint?: SetZoneWaypointFunction;
  fit?: { value: Extent };
  // zoom?: { value: number };
  center?: { value: Coordinate };
  robotMission?: RosMissionMsg;
  connectionStatus?: RobotConnectionStatus;
  trackingButton?: React.ReactNode;
  accessLevel: SettingsAccessLevel;
  robotNavControlState?: RobotNavControl;
  onLoading: OnLoading;
  missionActive: string;
  openManualMeasurementDialog?: (manualMeasurementDialogParameters: ManualMeasurementDialogParameters) => void;
}

interface CommonMapState {
  coreLine: Coordinate[];
  center?: { value: Coordinate };
  fit?: { value: Extent };
  zoom?: { value: number };
  rotation?: { value: number };
  deleteCandidate: SelectCandidate | null;
  skipCandidate: SelectCandidate | null;
  waypointCandidate: SelectCandidate | null;
  moveCandidate: SelectCandidate | null;
  drawConfig: DrawConfig;
  addSampleActive: Coordinate | null;
  selectedSample: SelectCandidate | null;
  reorderLine: Coordinate[];
  selectedReorder: Coordinate[];
  renumber: boolean;
  checkpoints: number[];
  zoneMission: boolean;
  jobType: JobType;
  settingWaypoint: boolean;
  showZones: boolean;
  selectedCoreLine: Collection<FeatureLike>;
  reorderCentroids?: Set<CoordinatePoint>;
  assignmentLoading: boolean;
  enterNewSampleId: boolean;
  gridLayerFeatures: GridLayerFeature[];
  overlayHidden: boolean;
  renameSampleId: boolean;
  togglingDrawSample: boolean;
  coresDialogOpen: boolean;
  coresForDialog: SoilCore[];
}

export default class CommonMap extends PureComponent<CommonMapProps, CommonMapState> {
  SERIALIZABLE_STATE_VARIABLES: (keyof CommonMapState)[] = [
    'reorderLine',
    'renumber',
    'showZones',
    'drawConfig',
    'addSampleActive',
  ];

  SUPPORTED_ZONE_TYPES: KmlFeatureType[] = [KML_FEATURE_TYPE.PULLIN, KML_FEATURE_TYPE.SLOW, KML_FEATURE_TYPE.UNSAFE];

  setStateAsync: SetStateAsync;
  shiftState: boolean;

  constructor(props: CommonMapProps) {
    super(props);

    this.state = {
      center: undefined,
      rotation: undefined,
      fit: undefined,
      zoom: undefined,
      deleteCandidate: null,
      skipCandidate: null,
      waypointCandidate: null,
      drawConfig: { geometryType: null, featureType: null, styleParams: {} },
      addSampleActive: null,
      selectedSample: null,
      moveCandidate: null,
      reorderLine: [],
      selectedReorder: [],
      renumber: false,
      checkpoints: [],
      zoneMission: false,
      jobType: 'Grid',
      settingWaypoint: false,
      showZones: true,
      coreLine: [],
      selectedCoreLine: new Collection([]),
      reorderCentroids: new Set([]),
      assignmentLoading: false,
      enterNewSampleId: false,
      gridLayerFeatures: [],
      overlayHidden: false,
      renameSampleId: false,
      togglingDrawSample: false,
      coresDialogOpen: false,
      coresForDialog: [],
    };

    this.shiftState = false;

    this.zoomToMission = this.zoomToMission.bind(this);
    this.updateBoundary = this.updateBoundary.bind(this);
    this.updatePath = this.updatePath.bind(this);
    this.updatePoints = this.updatePoints.bind(this);
    this.updatePullin = this.updatePullin.bind(this);
    this.resetDrawConfig = this.resetDrawConfig.bind(this);
    this.handleDraw = this.handleDraw.bind(this);
    this.handleModify = this.handleModify.bind(this);
    //this.addSampleAtPathEnd = this.addSampleAtPathEnd.bind(this);
    this.addSampleAfterPoint = this.addSampleAfterPoint.bind(this);
    this.handleSetWaypoint = this.handleSetWaypoint.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.updateSelectedReorder = this.updateSelectedReorder.bind(this);
    this.handleReorder = this.handleReorder.bind(this);
    this.handleKeydownCheckpoints = this.handleKeydownCheckpoints.bind(this);
    this.handleKeydownDelete = this.handleKeydownDelete.bind(this);
    this.getZoneType = this.getZoneType.bind(this);
    this.updateRenumber = this.updateRenumber.bind(this);
    this.cancelDrawingChange = this.cancelDrawingChange.bind(this);
    this.handleCoreLine = this.handleCoreLine.bind(this);
    this.handleCoreLineModify = this.handleCoreLineModify.bind(this);
    this.updateSelectedCoreLine = this.updateSelectedCoreLine.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.setStateAsync = setStateAsync.bind(this);
    this.updateGridLayerFeatures = this.updateGridLayerFeatures.bind(this);
  }

  componentDidMount() {
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.getZoneType);
    EventBus.on(MAP_EVENTS.ZOOM_TO_MISSION, this.zoomToMission);
    this.getZoneType();
    this.deserializeState();
    document.addEventListener('keydown', this.handleKeydownDraw);
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.getZoneType);
    EventBus.remove(MAP_EVENTS.ZOOM_TO_MISSION, this.zoomToMission);
    document.removeEventListener('keydown', this.handleKeydownCheckpoints);
    document.removeEventListener('keydown', this.handleKeydownDelete);
    document.removeEventListener('keydown', this.handleKeydownDraw);
    this.serializeState();
  }

  componentDidUpdate(prevProps: CommonMapProps, prevState: CommonMapState) {
    if (this.props.fit !== prevProps.fit) {
      this.setState({ fit: this.props.fit });
    }
    if (prevState.checkpoints !== this.state.checkpoints) {
      if (this.state.checkpoints.length && !prevState.checkpoints.length) {
        document.addEventListener('keydown', this.handleKeydownCheckpoints);
      } else if (prevState.checkpoints.length && !this.state.checkpoints.length) {
        document.removeEventListener('keydown', this.handleKeydownCheckpoints);
      }
    }

    if (prevState.deleteCandidate !== this.state.deleteCandidate) {
      if (this.state.deleteCandidate) {
        document.addEventListener('keydown', this.handleKeydownDelete);
      } else {
        document.removeEventListener('keydown', this.handleKeydownDelete);
      }
    }
  }

  /**
   * serialize the state into session
   */
  serializeState() {
    const serializedState: { [key: string]: any } = {};
    for (const stateKey of this.SERIALIZABLE_STATE_VARIABLES) {
      serializedState[stateKey] = this.state[stateKey];
    }
    localStorage.setItem('CommonMapState', JSON.stringify(serializedState));
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const commonMapStateString = localStorage.getItem('CommonMapState');
    if (!commonMapStateString) {
      return;
    }
    const serializedState = JSON.parse(commonMapStateString);
    if (serializedState) {
      for (const stateKey of Object.keys(serializedState)) {
        this.setState(updateState(stateKey as keyof CommonMapState, serializedState[stateKey]));
      }
    }
  }

  /**
   * Updates gridLayerFeatures state
   * @param {array} gridLayerFeatures Array containing grid layer geo json features
   */
  updateGridLayerFeatures(gridLayerFeatures: GridLayerFeature[]) {
    this.setState({ gridLayerFeatures });
  }

  getZoneType() {
    const mission = getCurrentMission();
    const missionSpec = mission?.getPrimarySpec();
    if (missionSpec) {
      const zoneMission = missionSpec.pattern_type === GridPatterns.ZONE;
      const jobType: JobType = missionSpec.pattern_type === GridPatterns.ZONE ? 'Zone' : 'Grid';
      this.setState({ zoneMission, jobType });
    } else {
      this.setState({ zoneMission: false });
    }
  }

  handleKeydownDraw = (event: KeyboardEvent) => {
    if (event.ctrlKey) {
      switch (event.key) {
        case '1':
          this.toggleDrawUnsafeZone();
          break;
        case '2':
          this.toggleDrawSlowZone();
          break;
        case '3':
          this.toggleDrawGenericZone();
          break;
        case '4':
          this.toggleDrawReorder();
          break;
      }
    }
    this.shiftState = event.shiftKey;
  };

  handleKeydownCheckpoints(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'z') {
      this.handleUndo();
    }
  }

  async handleKeydownDelete(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      await this.handleDelete();
    }

    // toggleDrawGenericZone={this.toggleDrawGenericZone}
    // toggleDrawReorder={this.toggleDrawReorder}
    // toggleDrawSlowZone={this.toggleDrawSlowZone}
    // toggleDrawCores={this.toggleDrawCores}
    // updateRenumber={this.updateRenumber}
    // toggleDrawUnsafeZone={this.toggleDrawUnsafeZone}
    // toggleDrawSample={this.toggleDrawSample}
  }

  /**
   * Zooms to mission center
   */
  async zoomToMission() {
    const mission = getCurrentMission();
    if (!mission) {
      alertWarn('No mission loaded');
      return;
    }
    const fieldZone = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);

    // TODO This doesn't seem to work, and really isn't used because we basically never
    // have a field without a boundary. This was just an attempt at making it work
    // once with a unique data situation
    if (!fieldZone) {
      alertError('Field appears to have no boundaries, likely did not load correctly!');
      const pullin = convertProjection4329([mission.pullin_lon, mission.pullin_lat]);
      alertInfo(`Setting center to pullin point: ${pullin}`);
      this.setState({ center: { value: pullin } });
      return;
    }
    const outerBoundaries = await fieldZone.to_feature();
    if (outerBoundaries.length === 0) {
      alertError('Field appears to have no boundaries, likely did not load correctly!');
      return;
    }
    const fields = outerBoundaries.map((feature) => {
      const coords = feature.geometry.coordinates.map((coords) =>
        coords.map((ring) => {
          return ring;
        }),
      );
      return new Polygon(coords);
    });
    const field = new MultiPolygon(fields);
    const fieldExtent = field.getExtent();
    const center = getCenter(fieldExtent);
    // MapExtentStorage.set(fieldExtent);
    dispatchSetExtent(fieldExtent);
    dispatchSetCenter(center);
  }

  addDeleteCandidate = (deleteCandidate: SelectCandidate | null) => {
    this.setState({ deleteCandidate, selectedSample: deleteCandidate });
  };
  addSkipCandidate = (skipCandidate: SelectCandidate | null) => {
    this.setState({ skipCandidate, selectedSample: skipCandidate });
  };
  addWaypointCandidate = (waypointCandidate: SelectCandidate | null) => {
    this.setState({ waypointCandidate, selectedSample: waypointCandidate });
  };
  addMoveCandidate = (moveCandidate: SelectCandidate | null) => {
    if (this.state.togglingDrawSample) {
      return;
    }

    this.setState({ moveCandidate, selectedSample: moveCandidate });
  };

  handleSkip = async () => {
    if (!this.state.skipCandidate) {
      return;
    }

    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    const { feature, type } = this.state.skipCandidate;
    switch (type) {
      case KML_FEATURE_TYPE.CENTROID:
      case KML_FEATURE_TYPE.SAMPLE_ZONE:
      case KML_FEATURE_TYPE.CORE_POINT:
        const featureName = feature.getProperties().name;

        const sample = findSampleById(featureName);
        if (!sample) {
          alertWarn("Sample not found. Make sure you didn't unselect the sample too soon");

          return;
        }

        if (sample.bag_id) {
          const response = await alertWarnConfirm(
            `Warning! Sample ${sample.sample_id} already has a barcode. Skipping will DELETE this barcode. Are you sure? `,
          );

          if (!response) {
            return;
          }
        }

        const previousChangeType = sample?.change_type;

        const selectorOptions: ShowChangeTypeSelectorPopup = {
          sampleCoreOrZoneId: featureName,
          changeTypeOptions: ['Skip', 'Delete', 'None'],
          defaultChangeType: 'Skip',
        };
        const changeSampleResult = await promptForChangeReason(selectorOptions);
        if (changeSampleResult.result === 'confirm') {
          // if the skip type has changed...
          if (changeSampleResult.selectedChangeType !== previousChangeType) {
            this.addCheckpoint(changeSampleResult.selectedChangeType ?? 'Unknown');

            sample.change_type = changeSampleResult.selectedChangeType ?? 'None';
            sample.change_reason = changeSampleResult.reason ?? '';
            // get all sample waypoints and dispose
            if (sample) {
              await sample.removeFromPath();
            }

            sample.resetPulled();
            await mission.calculate_path_restore_cores();
          }

          if (sample.skipped_or_deleted) {
            try {
              const errMap = await setSampleBarcodes(sample.instance_id, '', true); // set samples in db
              console.log('CommonMap - handleSkip: errMap', errMap);
              // TODO should we do something with the error map?
            } catch (err) {
              await logger.log(`HANDLE_SKIP`, `sample.instance_id=${sample.instance_id}, err=${JSON.stringify(err)}`);
            }
          }
        }
        await this.closeOverlay();
        dispatchMissionUpdated();
        break;
    }
  };

  /**
   * Handles feature deletion on the data model end based on feature type
   */
  handleDelete = async () => {
    if (!this.state.deleteCandidate) return;
    const { feature, type } = this.state.deleteCandidate;
    switch (type) {
      case KML_FEATURE_TYPE.CENTROID:
      case KML_FEATURE_TYPE.CORE_POINT:
      case KML_FEATURE_TYPE.SAMPLE_ZONE:
        const featureName = feature.getProperties().name;
        const zone_id = feature.getProperties().zone_id;
        await this.handleDeleteCentroidOrCore(featureName, type, zone_id);
        break;
      case KML_FEATURE_TYPE.FIELD:
      case KML_FEATURE_TYPE.GENERIC_ZONE:
      case KML_FEATURE_TYPE.UNSAFE:
      case KML_FEATURE_TYPE.SLOW:
      case KML_FEATURE_TYPE.COLLECTED_FIELD:
        const properties = feature.getProperties();
        // TODO need to revisit this type overlap
        // TODO potential maps bug with deletion
        await this.closeOverlay();
        await this.updateBoundary([], type, properties.zone_id);
        break;
      default:
    }
  };

  handleDeleteCentroidOrCore = async (featureName: string, type: KmlFeatureType, zone_id?: number) => {
    console.log(`handleDeleteCentroidOrCore: ${featureName}`);
    const sample = findSampleById(featureName);
    const mission = getCurrentMission();
    if (this.props.type === MAP_TYPES.SAMPLING && sample?.sample_site_source !== 'Rogo Field Ops') {
      const selectorOptions: ShowChangeTypeSelectorPopup = {
        sampleCoreOrZoneId: featureName,
        changeTypeOptions: ['Skip', 'Delete', 'None'],
        defaultChangeType: 'Delete',
      };

      const sample = findSampleById(featureName);
      if (!sample) {
        alertWarn("Sample not found. Make sure you didn't unselect the sample too soon");
        return;
      }
      if (sample?.bag_id) {
        const response = await alertWarnConfirm(
          `Warning! Sample ${sample.sample_id} already has a barcode. Skipping will DELETE this barcode. Are you sure? `,
        );
        if (!response) {
          return;
        }
      }

      const previousChangeType = sample?.change_type;

      const changeSampleResult = await promptForChangeReason(selectorOptions);
      if (changeSampleResult.result === 'confirm') {
        if (changeSampleResult.selectedChangeType !== previousChangeType) {
          // TODO if changed, update path?
          // update path
          this.addCheckpoint(changeSampleResult.selectedChangeType?.toString() ?? 'Unknown');
          sample.change_type = changeSampleResult.selectedChangeType ?? 'None';
          sample.change_reason = changeSampleResult.reason ?? '';
          // get all sample waypoints and dispose
          if (sample && changeSampleResult.selectedChangeType !== 'None') {
            mission?.getWaypoints().forEach((wp) => {
              if (wp.getCorePoint()?.getSoilCore()?.Sample_id === sample.instance_id) {
                wp.dispose();
              }
            });
          }

          if (sample?.skipped_or_deleted) {
            try {
              const errMap = await setSampleBarcodes(sample?.instance_id, '', true); // set samples in db
              console.log('CommonMap - handleDeleteCentroidOrCore: errMap', errMap);
              // TODO should we do something with the error map?
            } catch (err) {
              await logger.log(`HANDLE_SKIP`, `sample.instance_id=${sample?.instance_id}, err=${JSON.stringify(err)}`);
            }
          }

          await mission?.calculate_path();
        }
        await this.closeOverlay();
        dispatchMissionUpdated();
      }
    } else {
      // TODO why did I delete a boundary item in a "delete centroid or core" function?
      if (mission?.is_zone_mission() && zone_id) {
        await this.updateBoundary([], type, zone_id);
      } else {
        // setting the coordinates to 0, 0 is actually how we tell the underlying function
        // to delete the point...
        console.log(`immediate delete`);
        await this.closeOverlay();
        await this.props.onLoading(async () => {
          await this.updatePoints([0.0, 0.0], featureName);
        });
      }
    }
  };

  /**
   * Resets the draw configuration
   */
  resetDrawConfig = () => {
    this.setState({ drawConfig: { geometryType: null, featureType: null, styleParams: {} } });
  };

  /**
   * Toggle the draw unsafe zone draw config
   */
  toggleDrawUnsafeZone = () => {
    if (this.state.drawConfig.featureType !== DRAW_TYPES.UNSAFE_ZONE) {
      this.setState({
        drawConfig: {
          geometryType: GeometryType.POLYGON,
          featureType: DRAW_TYPES.UNSAFE_ZONE,
          styleParams: { linestringColor: RED, pointColor: RED, polygonColor: RED.concat(0.3) },
        },
      });
    } else {
      this.resetDrawConfig();
    }
  };

  /**
   * Toggle the draw slow zone draw config
   */
  toggleDrawSlowZone = () => {
    if (this.state.drawConfig.featureType !== DRAW_TYPES.SLOW_ZONE) {
      this.setState({
        drawConfig: {
          geometryType: GeometryType.POLYGON,
          featureType: DRAW_TYPES.SLOW_ZONE,
          styleParams: { linestringColor: BROWN, pointColor: BROWN, polygonColor: BROWN.concat(0.3) },
        },
      });
    } else {
      this.resetDrawConfig();
    }
  };

  toggleDrawCores = () => {
    if (this.state.drawConfig.featureType !== DRAW_TYPES.CORE_LINE) {
      this.setState({
        drawConfig: {
          geometryType: GeometryType.LINE_STRING,
          featureType: DRAW_TYPES.CORE_LINE,
          styleParams: { linestringColor: BROWN, pointColor: BROWN, polygonColor: BROWN },
        },
      });
    } else {
      this.resetDrawConfig();
    }
  };

  /**
   * Toggle the draw generic zone draw config
   */
  toggleDrawGenericZone = () => {
    if (this.state.drawConfig.featureType !== DRAW_TYPES.PULLIN_ZONE) {
      this.setState({
        drawConfig: {
          geometryType: GeometryType.POLYGON,
          featureType: DRAW_TYPES.PULLIN_ZONE,
          styleParams: { linestringColor: GREEN, pointColor: GREEN, polygonColor: GREEN.concat(0.3) },
        },
      });
    } else {
      this.resetDrawConfig();
    }
  };

  /**
   * Toggle the draw reorder draw config
   * @param {boolean} renumber Whether to renumber
   */
  toggleDrawReorder = () => {
    if (this.state.drawConfig.featureType !== DRAW_TYPES.REORDER_LINE) {
      this.setState({
        drawConfig: {
          geometryType: GeometryType.LINE_STRING,
          featureType: DRAW_TYPES.REORDER_LINE,
          styleParams: { linestringColor: BURLY_WOOD, pointColor: BURLY_WOOD, polygonColor: BURLY_WOOD },
        },
      });
    } else {
      this.resetDrawConfig();
    }
  };

  toggleMoveCore = () => {
    if (this.state.drawConfig.featureType !== DRAW_TYPES.CORE_POINT) {
      this.setState({
        drawConfig: {
          geometryType: GeometryType.POINT,
          featureType: DRAW_TYPES.CORE_POINT,
          styleParams: { pointColor: PLUM },
          action: 'Moving',
        },
      });
    } else {
      this.resetDrawConfig();
    }
  };

  /**
   * Toggle the draw sample draw config
   */
  toggleDrawSample = async (action: 'Moving' | 'Adding') => {
    this.setState({ togglingDrawSample: true });

    // TODO should have a better check condition here
    if (this.state.drawConfig.featureType !== DRAW_TYPES.CENTROID) {
      if (action === 'Moving') {
        const { feature } = this.state.moveCandidate!;
        const properties = feature.getProperties();
        const sampleId = properties.name;
        const sample = findSampleById(sampleId);

        // Check if sample (or related sample - for multi-lab scenario) has a barcode.
        const sampleWithBarcode = sample?.getSampleSite()?.getSampleCentroid()?.findSampleWithBarcode();

        if (sampleWithBarcode) {
          const confirmed = await alertWarnConfirm(
            `Warning! Sample ${sampleWithBarcode.sample_id} already has a barcode. Moving will DELETE this barcode. Are you sure? `,
          );

          if (!confirmed) {
            return false;
          }
        }
      }

      const job = getCurrentMission()?.getJob();
      const mapmaking = this.props.type !== MAP_TYPES.SAMPLING;
      if (job && mapmaking) {
        if (action === 'Adding' && !job.allowed_to_create) {
          if (
            !(await alertWarnConfirm(
              'Samples are not allowed to be created for this job. Are you sure you want to continue?',
            ))
          ) {
            this.setState({ togglingDrawSample: false });

            return false;
          }
        }
      }
      if (job && !mapmaking) {
        if (action === 'Moving' && !job.allowed_to_move) {
          if (
            !(await alertWarnConfirm(
              'Samples are not allowed to be moved for this job. Are you sure you want to continue?',
            ))
          ) {
            this.setState({ togglingDrawSample: false });

            return false;
          }
        }
      }

      this.setState({
        drawConfig: {
          geometryType: GeometryType.POINT,
          featureType: DRAW_TYPES.CENTROID,
          styleParams: { pointColor: PLUM },
          action,
        },
        togglingDrawSample: false,
      });

      return true;
    } else {
      this.resetDrawConfig();
      this.setState({ togglingDrawSample: false });

      return false;
    }
  };

  addCheckpoint = (name: string) => {
    const checkpoints = this.state.checkpoints.slice();
    checkpoints.push(createCheckpoint(name));
    this.setState({ checkpoints });
  };

  /**
   * Updates or creates boundary in the data model
   * @param {array} points New points
   * @param {string} boundary_type Type of boundary
   * @param {number} id The id
   */
  async updateBoundary(points: Coordinate[][], boundary_type: KmlFeatureType, id: number, addCheckpoint = true) {
    if (addCheckpoint) this.addCheckpoint('Update Boundary');
    const newPoints = points.map((ring) => ring.map((coordSet) => convertProjection3857(coordSet)));
    const mission = getCurrentMission();
    await mission?.update_boundary(id, boundary_type, newPoints);
    dispatchMissionUpdated();
  }

  // who calls this?
  // true delete: await this.updatePoints([0.0, 0.0], featureName);
  // operator move: await this.updatePoints(newSampleCoordinates, sampleId, false);
  // case KML_FEATURE_TYPE.CENTROID: await this.props.updatePoints(coordinates, properties.name, true); break;
  // case KML_FEATURE_TYPE.CORE_POINT: await this.props.updatePoints(coordinates, properties.name, true); break;
  /**
   * Updates points in the data model
   * @param {Coordinate} point New points
   * @param {string} name The id
   */
  async updatePoints(point: Coordinate, name: string, { addCheckpoint = true, centroid = false } = {}) {
    console.log(`updatePoints: points=${point}, name=${name}`);
    const t1 = performance.now();

    point = convertProjection3857(point);
    if (addCheckpoint) {
      this.addCheckpoint(isPointDelete(point) ? 'Delete Point' : 'Update Points');
    }

    const newPoints = [{ id: name, coords: { lat: point[0], lon: point[1] } }];
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    await mission.update_points(newPoints, this.props.type === MAP_TYPES.SAMPLING);
    const job = mission.getJob();
    const mapmaking = this.props.type !== MAP_TYPES.SAMPLING;
    const deletingPoint = point[0] === 0.0 && point[1] === 0.0;
    if (job && mapmaking && !deletingPoint && !job.allowed_to_move) {
      alertWarn('This job does not allow moving samples. Please make sure any moves are safety critical');
    }
    const waypoints = mission.getWaypoints();

    const havePath = waypoints.length;
    if (havePath && centroid && deletingPoint) {
      // only trigger a calculate on deletion now
      await mission.calculate_path();
    } else {
      // mission update happens with calculate_path so only call here if we didn't have waypoints
      dispatchMissionUpdated();
    }

    const t2 = performance.now();

    console.log(`updatePoints: ${point} took ${t2 - t1} milliseconds`);
  }

  /**
   * Updates pullin point in the data model
   * @param {array} point New pullin point
   */
  async updatePullin(point: Coordinate, addCheckpoint = true) {
    if (addCheckpoint) this.addCheckpoint('Update Pullin');
    const mission = getCurrentMission();
    await mission?.update_pullin(convertProjection3857(point));
  }

  /**
   * Updates the path points in the data model
   * @param {array} points New path points
   */
  async updatePath(points: Coordinate[], addCheckpoint = true) {
    console.log(`updatePath: points=${points}`);
    if (addCheckpoint) this.addCheckpoint('Update Path');
    const newPoints = points.map((coordSet) => convertProjection3857(coordSet));
    const mission = getCurrentMission();
    await mission?.update_path(newPoints);

    // this should happen when we call update_path now
    dispatchMissionUpdated();
  }

  /**
   * Adds a new sample in the data model
   * @param {array} point New sample point
   * @param {string} sampleId The new sample id
   */
  addSample = async (point: Coordinate, sampleId: string, sampleSource: SampleSource, addCheckpoint = true) => {
    if (addCheckpoint) this.addCheckpoint(`Add Sample ${sampleId}`);
    point = convertProjection3857(point);
    const newPoint = { coords: { lat: point[0], lon: point[1] } };
    const mission = getCurrentMission();
    await mission?.add_sample(newPoint, sampleId, sampleSource);
    this.setState({ addSampleActive: null });
    dispatchMissionUpdated();
  };

  /**
   * Adds a new core in the data model
   * @param {array} point New core point
   * @param {string} sampleId The new core id
   */
  addCore = async (point: Coordinate, sampleId: string, sampleSource: SampleSource, addCheckpoint = true) => {
    if (addCheckpoint) this.addCheckpoint(`Add Core ${sampleId}`);
    point = convertProjection3857(point);
    const newPoint = { coords: { lat: point[0], lon: point[1] } };
    const mission = getCurrentMission();
    await mission?.add_core(newPoint, sampleId, sampleSource);
    this.setState({ addSampleActive: null });
    dispatchMissionUpdated();
  };

  renameSample = async (point: Coordinate, newSampleId: string, addCheckpoint = true) => {
    if (addCheckpoint) this.addCheckpoint(`Rename Sample To ${newSampleId}`);
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    const feature = this.state.skipCandidate?.feature;
    if (!feature) {
      return;
    }

    const sampleOrCoreIdString = feature?.getProperties()?.name;

    try {
      mission.rename_sample(point, sampleOrCoreIdString, newSampleId);
    } catch (error) {
      alertError(`Error renaming sample: ${error}`);
    }
    this.setState({ addSampleActive: null, enterNewSampleId: false, renameSampleId: false });
    dispatchMissionUpdated();
  };

  /**
   * Adds a new sample to the path in the data model directly after the currently selected point
   * (the currently selected point is stored in this.state.selectedSample)
   * @param {array} point New sample point
   * @param {string} sampleId The new sample id
   */
  async addSampleAfterPoint(
    point: Coordinate,
    sampleId: string,
    sampleSource: SampleSource,
    { addReason = '', addCheckpoint = true } = {},
  ) {
    if (!this.state.selectedSample) {
      alertError('No sample selected to add after');
      return;
    }
    console.log(
      `addSampleAfterPoint: point=${point}, sampleId=${sampleId}, this.state.selectedSample=`,
      this.state.selectedSample,
    );
    if (addCheckpoint) {
      this.addCheckpoint(`Add Sample ${sampleId} After ${this.state.selectedSample?.feature.getProperties().name}`);
    }
    const mission = getCurrentMission();
    if (!mission) {
      alertError('No mission found');
      return;
    }
    const _point = convertProjection3857(point);
    const existingSampleId = this.state.selectedSample?.feature.getProperties().name;
    const newPoint = { coords: { lat: _point[0], lon: _point[1] } };
    const centroidPath = mission.getCentroidPath();
    const selectedIndex = centroidPath.findIndex((sample) => sample.sample_id === existingSampleId);
    centroidPath.splice(selectedIndex + 1, 0, { coord: _point, sample_id: sampleId });
    console.log('centroidPath', centroidPath);

    // coords from selectedSample and centroidPath don't exactly match, but are very close
    // find the closest centroid in the centroidPath to find where to insert the new point
    // let closestCoordsIndex = 0;
    // let latDiff = Math.abs(centroidPath[0][0] - selectedSampleCoords[0]);
    // let lonDiff = Math.abs(centroidPath[0][1] - selectedSampleCoords[1]);
    // for (let i = 1; i < centroidPath.length; i++) {
    //     if (Math.abs(centroidPath[i][0] - selectedSampleCoords[0]) < latDiff &&
    //         Math.abs(centroidPath[i][1] - selectedSampleCoords[1]) < lonDiff) {
    //         closestCoordsIndex = i;
    //         latDiff = Math.abs(centroidPath[i][0] - selectedSampleCoords[0]);
    //         lonDiff = Math.abs(centroidPath[i][1] - selectedSampleCoords[1]);
    //     }
    // }
    //console.log(`closestCoordsIndex=${closestCoordsIndex}, centroidPath[closestCoordsIndex]=${centroidPath[closestCoordsIndex]}`);
    //centroidPath.splice(closestCoordsIndex + 1, 0, _point);

    const sample = await mission.add_sample(newPoint, sampleId, sampleSource);
    if (addReason) {
      sample.change_type = 'Add';
      sample.change_reason = addReason;
    }
    this.setState({ addSampleActive: null });
    await mission.reorder(
      centroidPath.map((sample) => sample.coord),
      false,
      this.props.type === MAP_TYPES.SAMPLING,
    );
    const job = mission.getJob();
    if (job && job.auto_renumber) {
      await mission.renumber_samples(this.props.type === MAP_TYPES.SAMPLING, true);
    }
    dispatchMissionUpdated();
  }

  /**
   * Handle for draw event that will create a new object in the data model based on type
   * @param {*} event
   */
  async handleDraw(event: BaseEvent, sampleCentroids?: Set<CoordinatePoint>) {
    if (!eventIsDrawEvent(event)) return;
    const { geometryType, featureType } = this.state.drawConfig;
    if (!featureType) {
      throw new Error(`No featureType set in drawConfig`);
    }
    const coords = (event.feature.getGeometry() as SimpleGeometry).getCoordinates();
    console.log(
      `handleDraw: geometryType=${geometryType}, featureType=${featureType}, ${coords.length} coordinates}, sampleCentroids`,
      sampleCentroids,
    );
    if (geometryType === GeometryType.POLYGON && this.SUPPORTED_ZONE_TYPES.includes(featureType)) {
      const feature = event.feature as Feature<Polygon>;
      const coordinates = feature.getGeometry()?.getCoordinates() ?? [];
      await this.updateBoundary(coordinates, featureType, CREATE_NEW_POLYGON);
      this.resetDrawConfig();
    } else if (geometryType === GeometryType.LINE_STRING && featureType === DRAW_TYPES.REORDER_LINE) {
      this.addCheckpoint(`Reorder Line`);
      const feature = event.feature as Feature<LineString>;
      const coordinates = feature.getGeometry()?.getCoordinates() ?? [];
      this.setState({ reorderLine: coordinates, renumber: this.state.renumber, reorderCentroids: sampleCentroids });
      this.resetDrawConfig();
    } else if (geometryType === GeometryType.LINE_STRING && featureType === DRAW_TYPES.CORE_LINE) {
      this.addCheckpoint(`Core Line`);
      const feature = event.feature as Feature<LineString>;
      const coordinates = feature.getGeometry()?.getCoordinates() ?? [];

      // make sure we "pop" the last layer drawing marker off the map to account for the double click
      EventBus.dispatch('DRAW_LAYER:REMOVE_LAST_MARKER');
      this.setState({ coreLine: coordinates });
      this.resetDrawConfig();
    } else if (geometryType === GeometryType.POINT && featureType === DRAW_TYPES.CENTROID) {
      // we don't reset the drawing config or add a checkpoint here because we want the user
      // to be able to click around the map for the point they want to place
      // the target centroid. We will add a checkpoint and reset the drawing config once the
      // user accepts the change
      const feature = event.feature as Feature<Point>;
      const coordinates = feature.getGeometry()?.getCoordinates() ?? [];
      this.setState({ addSampleActive: coordinates });
    } else if (geometryType === GeometryType.POINT && featureType === DRAW_TYPES.CORE_POINT) {
      // we don't reset the drawing config or add a checkpoint here because we want the user
      // to be able to click around the map for the point they want to place
      // the target centroid. We will add a checkpoint and reset the drawing config once the
      // user accepts the change
      const feature = event.feature as Feature<Point>;
      const coordinates = feature.getGeometry()?.getCoordinates() ?? [];
      this.setState({ addSampleActive: coordinates });
    } else {
      this.resetDrawConfig();
      throw new Error(`Unsupported draw configuration.\ngeometryType: ${geometryType}\nfeatureType: ${featureType}`);
    }
  }

  /**
   * Type guard to check if the event is a modify event
   * @param event  The event that triggered the modify
   * @returns  Whether the event is a modify event
   */
  eventIsModifyEvent(event: BaseEvent | ModifyEvent): event is ModifyEvent {
    return (event as ModifyEvent).features !== undefined;
  }

  /**
   * Handle for modifying reorder line
   * @param {*} event
   */
  handleModify(event: BaseEvent) {
    if (!this.eventIsModifyEvent(event)) return;
    console.log(`handleModify`);
    // because we know what we put this one, we know this geometry we receive is a LineString
    const line = event.features.getArray()[0].getGeometry() as LineString;
    const coordinates = line.getCoordinates();
    this.setState({ reorderLine: coordinates });
  }

  /**
   * Handle for modifying core line
   * @param {*} event
   */
  handleCoreLineModify(event: BaseEvent) {
    if (!this.eventIsModifyEvent(event)) return;
    // because we know what we put this one, we know this geometry we receive is a LineString
    const line = event.features.getArray()[0].getGeometry() as LineString;
    const coordinates = line.getCoordinates();
    this.setState({ coreLine: coordinates });
  }

  /**
   * Handle for setting the waypoint
   */
  async handleSetWaypoint() {
    // if we don't have a robot mission, we definitely aren't in manual
    const waypointFeature = this.state.waypointCandidate?.feature;
    const properties = waypointFeature?.getProperties();

    const doneCallback = () => this.setState({ settingWaypoint: false });

    this.setState({ settingWaypoint: true });

    const featureType = getFeatureType(properties);

    if (this.state.zoneMission) {
      if (featureType === KML_FEATURE_TYPE.SAMPLE_ZONE) {
        this.props.setZoneWaypoint &&
          (await this.props.setZoneWaypoint(
            properties?.name,
            null,
            this.constructor.name,
            doneCallback,
            this.state.waypointCandidate?.coordinates,
          ));
      } else if (featureType === KML_FEATURE_TYPE.CORE_POINT) {
        this.props.setZoneWaypoint &&
          (await this.props.setZoneWaypoint(
            null,
            properties?.name,
            this.constructor.name,
            doneCallback,
            this.state.waypointCandidate?.coordinates,
          ));
      }
    } else {
      this.props.setWaypoint &&
        (await this.props.setWaypoint(
          2,
          properties?.name,
          null,
          this.constructor.name,
          doneCallback,
          this.state.waypointCandidate?.coordinates,
        ));
    }
    // TODO we had a Sentry error that waypointCandidate was null, but I'm not sure how. We'll at least
    // check to make sure it's not null/undefined before trying to call it
    this.state.waypointCandidate?.removeFunc();
    this.setState({ waypointCandidate: null, deleteCandidate: null, skipCandidate: null, moveCandidate: null });
  }

  /**
   * Handle for undoing an action
   */
  async handleUndo() {
    const { featureType } = this.state.drawConfig;
    if (featureType !== null) {
      if (featureType === DRAW_TYPES.REORDER_LINE) {
        this.setState({ reorderLine: this.state.reorderLine.slice(0, -1) });
        EventBus.dispatch('DRAW_LAYER:REMOVE_LAST_MARKER');
      } else if (featureType === DRAW_TYPES.CORE_LINE) {
        this.setState({ coreLine: this.state.coreLine.slice(0, -1) });
        EventBus.dispatch('DRAW_LAYER:REMOVE_LAST_MARKER');
      }
    } else if (this.state.checkpoints.length) {
      const checkpoints = this.state.checkpoints.slice();
      const checkpointId = checkpoints.pop();
      this.setState({ checkpoints });
      if (checkpointId === undefined) {
        alertWarn('No checkpoint to undo');

        return;
      }
      const checkpoint = await loadCheckpoint(checkpointId);

      // theoretically these have nothing to do with undos, but we want to make sure we clean up local state
      // so that we get an appropriate rerender of the map
      await this.cancelDrawingChange();
      dispatchMissionUpdated();
      alertSuccess(`Undo ${checkpoint.name} successful!`);
    }
  }

  /**
   * Updates the selected reorder line
   * @param {Collection<FeatureLike>} selectedReorder
   */
  updateSelectedReorder(selectedReorder: Coordinate[]) {
    console.log(`CommonMap.updateSelectedReorder: ${selectedReorder.length} features selected`);
    this.setState({ selectedReorder });
  }

  /**
   * Updates the selected core line
   * @param {Collection<FeatureLike>} selectedCoreLine
   */
  updateSelectedCoreLine(selectedCoreLine: Collection<FeatureLike>) {
    console.log(`CommonMap.updateSelectedCoreLine: ${selectedCoreLine.getLength()} features selected`);
    this.setState({ selectedCoreLine });
  }

  async cancelDrawingChange() {
    await this.closeOverlay();
    this.setState({
      coreLine: [],
      selectedReorder: [],
      selectedCoreLine: new Collection([]),
      reorderCentroids: new Set([]),
      addSampleActive: null,
      selectedSample: null,
      reorderLine: [],
      enterNewSampleId: false,
    });
    EventBus.dispatch(`DRAW_LAYER:CLEAR_DRAWING_MARKERS`);
    this.resetDrawConfig();
  }

  async handleCoreLine() {
    const mission = getCurrentMission();
    const coordinates = this.state.coreLine.map((coord) => convertProjection3857(coord));
    console.log(`handleCoreLine: ${coordinates.length} coordinates`);

    if (!mission) {
      return;
    }

    if (!mission.getPrimarySpec()) {
      alertWarn('Handle core line - primary spec not found');

      return;
    }

    await mission.create_zone_cores(coordinates);

    this.setState({ coreLine: [], selectedCoreLine: new Collection([]) });
    EventBus.dispatch(`DRAW_LAYER:CLEAR_DRAWING_MARKERS`);
    dispatchMissionUpdated();
  }

  handleSampleChange = async () => {
    if (!this.state.addSampleActive) {
      await this.cancelDrawingChange();
      return;
    }

    const { action } = this.state.drawConfig;
    if (!action) {
      await this.cancelDrawingChange();
      return;
    }

    const moving = action === 'Moving';
    const selectedSampleId = moving ? this.state.moveCandidate?.feature?.getProperties()?.name : '';

    // const selectedSampleId = this.state.selectedSample?.feature?.getProperties()?.name;
    const mission = getCurrentMission();
    const addSampleId = mission?.getNextSampleNumber();

    // TODO disallow move if skipped/deleted? should probably be checked in KmlLayer?
    const currentChangeIsMove = this.state.selectedSample?.feature?.getProperties()?.change_type === 'Move';
    const movingAndAlreadyMoved = moving && currentChangeIsMove;
    const isSamplingMap = this.props.type === MAP_TYPES.SAMPLING;
    let changeType: SampleChangeType = 'None';
    let changeReason = '';
    //const featureIsNotCorePoint = featureType !== KML_FEATURE_TYPE.CORE_POINT;
    if (!movingAndAlreadyMoved && isSamplingMap) {
      const result = await promptForChangeReason({
        changeTypeOptions: [action === 'Moving' ? 'Move' : 'Add'],
        defaultChangeType: 'Move',
        sampleCoreOrZoneId: action === 'Moving' ? selectedSampleId : addSampleId,
      });

      changeType = result.selectedChangeType ?? 'None';
      changeReason = result.reason ?? '';

      // TODO might need to handle 'extra' result, but for now
      // we only care about confirm
      if (result.result !== 'confirm') {
        await this.cancelDrawingChange();
        return;
      }
    }

    const newSampleCoordinates = [...this.state.addSampleActive];
    if (action === 'Moving') {
      if (!this.state.moveCandidate) {
        await this.cancelDrawingChange();

        return;
      }

      const { feature } = this.state.moveCandidate;
      const sampleId = feature?.getProperties()?.name;
      if (sampleId) {
        const sample = findSampleById(sampleId);
        if (sample) {
          this.addCheckpoint(`Move Sample ${sampleId}`);
          sample.change_type = changeType;
          sample.change_reason = changeReason;
          await this.updatePoints(newSampleCoordinates, sampleId, {
            addCheckpoint: false,
            centroid: !!feature?.getProperties()?.centroid,
          });
        } else {
          alertError(`Could not find sample with id ${sampleId}`);
        }
      }
    } else {
      if (this.props.type === MAP_TYPES.SAMPLING && addSampleId) {
        await this.addSampleAfterPoint(newSampleCoordinates, addSampleId.toString(), 'Rogo Field Ops', {
          addReason: changeReason,
        });
      } else {
        // check if the shift key is being held down
        if (!this.shiftState && !this.state.zoneMission && addSampleId) {
          await this.addSample(newSampleCoordinates, addSampleId.toString(), 'Rogo Map Maker');
        } else {
          this.setState({ enterNewSampleId: true });
          return;
        }
      }
      // this just resets all drawing states
    }
    await this.cancelDrawingChange();
    dispatchMissionUpdated();
  };

  /**
   * Handles reorder
   */
  async handleReorder() {
    const mission = getCurrentMission();
    console.log('RENUMBER', this.state.renumber);
    // if (this.state.reorderCentroids) {
    //     await mission.reorder2(this.state.reorderCentroids);
    //     this.setState({ reorderLine: [], selectedReorder: [] });
    //     return;
    // }
    const coordinates = this.state.reorderLine.map((coord) => convertProjection3857(coord));

    const errors = await mission?.reorder(
      coordinates,
      this.state.renumber,
      this.props.type === MAP_TYPES.SAMPLING,
      this.state.reorderCentroids,
    );

    // TODO use this new method once tested...
    //const errors = await mission.reorder2(this.state.reorderCentroids, false);
    if (errors?.length) {
      console.log(errors);
      const maxWarnings = 5;
      let count = 0;
      let noReorder = false;
      for (const error of errors) {
        if (error.includes('interleaved')) {
          // TODO allow admin to override?
          noReorder = true;
          alertError(error);
        } else {
          if (count < maxWarnings) {
            alertWarn(error);
            count++;
          }
        }
      }

      if (!noReorder) {
        alertSuccess('Successfully reordered path (noreorder)');
        this.setState({ reorderLine: [], selectedReorder: [] });
      }
    } else {
      alertSuccess('Successfully reordered path!');
      this.setState({ reorderLine: [], selectedReorder: [] });
    }
    dispatchMissionUpdated();
    EventBus.dispatch(`DRAW_LAYER:CLEAR_DRAWING_MARKERS`);
  }

  updateRenumber(renumber: boolean) {
    this.setState({ renumber });
  }

  async closeOverlay() {
    this.state.deleteCandidate?.removeFunc();
    this.state.waypointCandidate?.removeFunc();
    this.state.skipCandidate?.removeFunc();
    this.state.moveCandidate?.removeFunc();
    this.setState({ deleteCandidate: null, waypointCandidate: null, skipCandidate: null, moveCandidate: null });
  }
  get currentDrawType() {
    if (this.state.drawConfig.featureType !== null) {
      return this.state.drawConfig.featureType;
    }
    if (this.state.selectedReorder.length) {
      return DRAW_TYPES.REORDER_LINE;
    }
    if (this.state.coreLine.length) {
      return DRAW_TYPES.CORE_LINE;
    }
    if (this.state.addSampleActive) {
      // TOOD also core point??
      return DRAW_TYPES.CENTROID;
    }
    // TOOD any other intermediate drawing objects??
    return undefined;
  }

  showPlotTool(mission: Mission | undefined): boolean {
    // missionActive is a string and can equal to "no id". Old logic would still display
    // the plot tool in this case. So, I'm preserving that behavior.
    // see Dashboard.tsx for more info
    if (!Boolean(this.props.missionActive)) {
      return false;
    }

    if (this.props.type === 'maps') {
      return true;
    }

    if (!mission) {
      return false;
    }

    return Boolean(mission.getJob()?.plot_mission);
  }

  render() {
    const mission = getCurrentMission();
    const robotConnected = this.props.connectionStatus === 'connected';
    const canSetWaypoint = this.state.waypointCandidate && robotConnected;
    const haveOverlayAction =
      this.state.deleteCandidate || this.state.skipCandidate || this.state.moveCandidate || canSetWaypoint;
    const drawing = this.currentDrawType !== undefined;
    const overlayVisible = haveOverlayAction && !drawing;
    const showPlotTool = this.showPlotTool(mission);

    return (
      <>
        {this.state.coresDialogOpen && this.props.openManualMeasurementDialog && (
          <CoresDialog
            handleClose={() =>
              this.setState({
                coresDialogOpen: false,
              })
            }
            cores={this.state.coresForDialog}
            openManualMeasurementDialog={this.props.openManualMeasurementDialog}
          />
        )}
        <BaseMap
          // fit={this.state.fit}
          absoluteOverlays={this.props.absoluteOverlays}
          trackingButton={this.props.trackingButton}
          enableSoilMap={this.props.type === MAP_TYPES.MAPS}
          overlays={[
            overlayVisible && (
              <MapOverlay
                position={
                  this.state.deleteCandidate?.coordinates ||
                  this.state.waypointCandidate?.coordinates ||
                  this.state.skipCandidate?.coordinates ||
                  this.state.moveCandidate?.coordinates!
                }
                onClose={this.closeOverlay}
              >
                {/* // TODO BARCODE this will now be used for manual on-screen navigation or auto nav? */}
                {this.state.waypointCandidate &&
                  this.props.connectionStatus === 'connected' &&
                  !featureIsSkippedOrDeleted(this.state.waypointCandidate.feature) && (
                    <WaypointSetButton
                      onClick={async () => {
                        await this.handleSetWaypoint();
                        await this.closeOverlay();
                      }}
                      loading={!!this.state.settingWaypoint}
                      textColor={'black'}
                    />
                  )}

                {this.state.waypointCandidate &&
                  !featureIsSkippedOrDeleted(this.state.waypointCandidate.feature) &&
                  this.props.type === MAP_TYPES.SAMPLING && (
                    <MapLoadingButton
                      tooltip="Select for scanning"
                      onClick={async () => {
                        const feature = this.state.waypointCandidate?.feature;
                        if (!feature) {
                          return;
                        }

                        const sampleOrCoreIdString = feature?.getProperties()?.name;
                        if (!sampleOrCoreIdString) {
                          return;
                        }

                        if (CoreCountEnforcementEnabled.get()) {
                          const sample = findSampleById(sampleOrCoreIdString);
                          const soilCores = sample?.getSoilCores() ?? [];
                          const pulledSoilCores = soilCores.filter((core) => core.pulled);
                          if (pulledSoilCores.length < soilCores.length) {
                            alertWarn(
                              `Not all cores have been pulled (${pulledSoilCores.length} / ${soilCores.length}). Please pull all cores before scanning.`,
                            );
                            return;
                          }
                        }

                        if (sampleOrCoreIdString) {
                          await setSampleSelected({
                            sampleInstanceId: findSampleById(sampleOrCoreIdString)?.instance_id,
                            timestamp: new Date(),
                          });
                        }

                        await this.closeOverlay();
                      }}
                    >
                      <FaBarcode size={20} color={'black'} />
                    </MapLoadingButton>
                  )}

                {this.props.type === MAP_TYPES.MAPS &&
                  this.state.skipCandidate &&
                  this.props.accessLevel === 'Technician' && (
                    <MapLoadingButton
                      onClick={async () => {
                        const feature = this.state.skipCandidate?.feature;
                        if (!feature) {
                          return;
                        }

                        const sampleOrCoreIdString = feature?.getProperties()?.name;
                        if (!sampleOrCoreIdString) {
                          return;
                        }

                        const pointFeature = feature as Feature<Point>;
                        const coordinates = pointFeature.getGeometry()?.getCoordinates() ?? [];

                        this.setState({
                          enterNewSampleId: true,
                          renameSampleId: true,
                          addSampleActive: coordinates,
                        });
                      }}
                    >
                      <BiRename size={20} color={'black'} />
                    </MapLoadingButton>
                  )}

                {this.props.type === MAP_TYPES.SAMPLING && this.state.skipCandidate && (
                  <SkipButton onClick={this.handleSkip} textColor={'black'} />
                )}

                {this.state.deleteCandidate && (
                  <DeleteButton
                    onClick={this.handleDelete}
                    textColor={'black'}
                    // setting to false because now all this does in sampling mode
                    // is provides a popup which can be cancelled
                    confirm={false} //{this.props.type !== MAP_TYPES.MAPS}
                  />
                )}

                {this.state.moveCandidate &&
                  this.props.type === MAP_TYPES.SAMPLING &&
                  !featureIsSkippedOrDeleted(this.state.moveCandidate.feature) && (
                    <MapLoadingButton
                      onClick={async () => {
                        console.log('Moving');
                        if (!this.state.moveCandidate) {
                          alertError('No sample selected to move!');
                          return;
                        }
                        const { feature } = this.state.moveCandidate;
                        const properties = feature.getProperties();
                        const type = getFeatureType(properties);
                        if (type === KML_FEATURE_TYPE.CORE_POINT) {
                          this.toggleMoveCore();
                        } else if (type === KML_FEATURE_TYPE.CENTROID) {
                          await this.toggleDrawSample('Moving');
                        }
                      }}
                    >
                      <BsArrowsMove size={20} color={'black'} />
                    </MapLoadingButton>
                  )}

                {this.state.deleteCandidate?.type === DRAW_TYPES.CENTROID &&
                  !this.state.zoneMission && // TODO SVH FIX THIS TO USE STATE INSTEAD OF PROPS
                  this.props?.type === MAP_TYPES.SAMPLING &&
                  !featureIsSkippedOrDeleted(this.state.deleteCandidate.feature) && (
                    <MapButton
                      key={'AddPointMapButton'}
                      onClick={async () => {
                        this.setState({
                          deleteCandidate: null,
                          waypointCandidate: null,
                          skipCandidate: null,
                          moveCandidate: null,
                        });
                        await this.toggleDrawSample('Adding');
                      }}
                      style={{
                        backgroundColor:
                          this.state.drawConfig.featureType === DRAW_TYPES.CENTROID ? '#e2e0e0' : undefined,
                      }}
                      tooltip="Add Sample Point"
                    >
                      <IoMdAddCircleOutline size={22} color={'black'} />
                    </MapButton>
                  )}

                {this.props.openManualMeasurementDialog &&
                  this.state.selectedSample &&
                  mission &&
                  mission.isManualMeasurementRequired() && (
                    <MapButton
                      key={'CoresInfoButton'}
                      onClick={async () => {
                        if (!this.state.selectedSample) {
                          return;
                        }
                        const sampleID = this.state.selectedSample.feature.getProperties()?.name;
                        const sample = findSampleById(sampleID);
                        if (!sample) {
                          return;
                        }

                        this.setState({
                          coresDialogOpen: true,
                          coresForDialog: sample.getSoilCores(),
                        });
                      }}
                      tooltip="Cores info"
                    >
                      <TbShovel size={22} color={'black'} />
                    </MapButton>
                  )}

                {this.state.moveCandidate &&
                  this.props.type === MAP_TYPES.SAMPLING &&
                  !featureIsSkippedOrDeleted(this.state.moveCandidate.feature) && (
                    <MapLoadingButton
                      tooltip={'Move Sample to Robot Position'}
                      onClick={async () => {
                        try {
                          if (!this.state.moveCandidate) return;
                          const { feature } = this.state.moveCandidate;
                          const properties = feature.getProperties();
                          const sampleId = properties.name;

                          const selectorOptions: ShowChangeTypeSelectorPopup = {
                            sampleCoreOrZoneId: sampleId,
                            changeTypeOptions: ['Move'],
                            defaultChangeType: 'Move',
                          };

                          const mission = getCurrentMission();

                          if (!mission) {
                            alertError('No mission available');
                            return;
                          }

                          const client = mission?.getJob()?.client;

                          let changeType: SampleChangeType = 'Move';
                          let changeReason: ChangeReason | string = '';
                          if (client?.toLowerCase().includes('loam')) {
                            changeReason = 'Old Core Location';
                          }

                          const changeSampleResult = await promptForChangeReason(selectorOptions);
                          if (changeSampleResult.result !== 'confirm') {
                            return;
                          }

                          changeType = changeSampleResult.selectedChangeType ?? 'None';
                          changeReason = changeSampleResult.reason ?? '';

                          const sample = findSampleById(sampleId);
                          if (!sample) {
                            alertError('Could not find sample to move');
                            return;
                          }

                          const positionMessage = EventBus.recent('ROSMSG/position');
                          const position: RosPosition = positionMessage?.detail?.msg;
                          if (!position) {
                            alertError('No robot position available');
                            return;
                          }

                          const newSampleCoordinates = convertProjection4329([position.y, position.x]);
                          if (!newSampleCoordinates) {
                            alertError('Could not convert robot position to map coordinates');
                            return;
                          }

                          const armPosition = calculateArmPosition(newSampleCoordinates, -position.z, '4329');
                          sample.change_type = changeType;
                          sample.change_reason = changeReason;
                          await this.updatePoints(armPosition, sampleId, {
                            addCheckpoint: true,
                            centroid: !!feature?.getProperties()?.centroid,
                          });
                        } catch (error) {
                          alertError(error?.toString() ?? 'Error moving sample');
                        } finally {
                          await this.closeOverlay();
                        }
                      }}
                    >
                      <GiMultipleTargets size={20} color={'black'} />
                    </MapLoadingButton>
                  )}
              </MapOverlay>
            ),
          ]}
          layers={[
            mission && (
              <KmlLayer
                key={'KmlLayer'}
                updatePath={this.updatePath}
                updatePoints={this.updatePoints}
                updateBoundary={this.updateBoundary}
                updatePullin={this.updatePullin}
                // TODO these should just become a single function with an additional parameter
                // really we can just have a "selected" with an array of properties on it
                // because this almost always ends up just being the same thing over and over again
                addDeleteCandidate={this.addDeleteCandidate}
                addSkipCandidate={this.addSkipCandidate}
                addWaypointCandidate={this.addWaypointCandidate}
                addMoveCandidate={this.addMoveCandidate}
                selectInteractionDisabled={!!this.state.drawConfig.geometryType || !!this.state.reorderLine.length}
                modifyInteractionDisabled={!!this.state.drawConfig.geometryType || !!this.state.reorderLine.length}
                zoneMission={this.state.zoneMission}
                jobType={this.state.jobType}
                showZones={this.state.showZones} // && (this.currentDrawType !== DRAW_TYPES.CORE_LINE)}
                type={this.props.type}
              />
            ),
            mission && (
              <DrawLayer
                key={'DrawLayer'}
                handleDraw={this.handleDraw}
                handleModify={this.handleModify}
                handleCoreLineModify={this.handleCoreLineModify}
                updateSelectedReorder={this.updateSelectedReorder}
                updateSelectedCoreLine={this.updateSelectedCoreLine}
                selectedCoreLine={this.state.selectedCoreLine}
                reorderLine={this.state.reorderLine}
                placedSample={this.state.addSampleActive}
                coreLine={this.state.coreLine}
                selectedReorder={this.state.selectedReorder}
                drawConfig={this.state.drawConfig}
              />
            ),
            <GridLayer gridLayerFeatures={this.state.gridLayerFeatures} />,
            <DrawingMarkersLayer
              key={'ReorderMarkersLayer'}
              drawConfig={this.state.drawConfig}
              currentDrawType={this.currentDrawType}
              showZones={this.state.showZones}
            />,
            ...(this.props.layers || []),
          ]}
          buttons={[
            mission && <CenterMissionButton key={'CenterMissionButton'} onClick={this.zoomToMission} />,
            mission && (
              <SamplingParametersButton
                key={'SamplingParametersButton'}
                zoneMission={this.state.zoneMission}
                accessLevel={this.props.accessLevel}
                type={this.props.type}
                addCheckpoint={this.addCheckpoint}
              />
            ),
            this.state.zoneMission && (
              <MapButton
                tooltip={this.state.showZones ? 'Hide Zones' : 'Show Zones'}
                key={'ShowZonesMapButton'}
                toggled={this.state.showZones}
                onClick={() => this.setState({ showZones: !this.state.showZones })}
              >
                <DiCelluloid size={36} />
              </MapButton>
            ),
            mission && (
              <DrawButton
                key={'DrawButton'}
                drawConfig={this.state.drawConfig}
                addSampleActive={this.state.addSampleActive}
                coreLine={this.state.coreLine}
                reorderLine={this.state.reorderLine}
                addSample={this.addSample}
                addCore={this.addCore}
                addSampleAfterPoint={this.addSampleAfterPoint}
                mapType={this.props.type}
                zoneMission={this.state.zoneMission}
                renumber={this.state.renumber}
                cancelAddSample={this.cancelDrawingChange}
                toggleDrawGenericZone={this.toggleDrawGenericZone}
                renameSampleId={this.state.renameSampleId}
                // TODO not totally happy with this implemention, we should be passing in the existing
                // sample ID somehow since we know it at the time we are doing a drawing command (assuming
                // we are editing and not moving a core or sample)
                renameSample={this.renameSample}
                deleteAllCoresOrSamples={() => {
                  if (this.state.zoneMission) {
                    this.addCheckpoint('Delete All Cores');
                    mission.delete_zone_cores();
                  } else {
                    this.addCheckpoint('Delete All Samples');
                    mission.delete_all_samples_and_cores();
                  }
                }}
                toggleDrawReorder={this.toggleDrawReorder}
                toggleDrawSlowZone={this.toggleDrawSlowZone}
                toggleDrawCores={this.toggleDrawCores}
                updateRenumber={this.updateRenumber}
                toggleDrawUnsafeZone={this.toggleDrawUnsafeZone}
                toggleDrawSample={this.toggleDrawSample}
                addCheckpoint={this.addCheckpoint}
                enterNewSampleId={this.state.enterNewSampleId}
                accessLevel={this.props.accessLevel}
              />
            ),
            Boolean(this.props.missionActive) && this.props.type === 'maps' && (
              <GridGenerationButton
                updateGridLayerFeatures={this.updateGridLayerFeatures}
                gridLayerFeatures={this.state.gridLayerFeatures}
              />
            ),
            showPlotTool && (
              <PlotGenerationButton
                addCheckpoint={this.addCheckpoint}
                updateGridLayerFeatures={this.updateGridLayerFeatures}
                gridLayerFeatures={this.state.gridLayerFeatures}
                mapType={this.props.type}
                accessLevel={this.props.accessLevel}
              />
            ),
            ...(this.props.buttons || []),
            mission && this.state.checkpoints.length > 0 && <UndoButton key={'UndoButton'} onClick={this.handleUndo} />,
            (this.state.reorderLine.length > 0 || this.state.coreLine.length > 0 || this.state.addSampleActive) && (
              <AssignmentButton
                key={'AssignmentButton'}
                loading={this.state.assignmentLoading}
                onClick={async () => {
                  if (this.state.reorderLine.length > 0) {
                    await this.handleReorder();
                  } else if (this.state.coreLine.length > 0) {
                    await this.handleCoreLine();
                  } else {
                    await this.handleSampleChange();
                  }
                }}
              />
            ),
            (this.state.reorderLine.length > 0 ||
              this.state.coreLine.length > 0 ||
              this.state.addSampleActive ||
              this.state.drawConfig.geometryType !== null) && (
              <DeselectAllButton key={'DeselectAllButton'} onClick={this.cancelDrawingChange} />
            ),
          ]}
          secondRowElements={this.props.secondRowElements ?? undefined}
        />
      </>
    );
  }
}
