import React, { PureComponent } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles/withStyles';

import RobotStatus from './RobotStatus';
import { isMobile } from 'react-device-detect';
import { UITab } from './helpers/task';
import { SampleBox } from '../db';

const tabStyles = (theme) => ({
  // for entire tab bar
  tabsRoot: {
    minHeight: '40px',
    height: '40px',
    width: 'calc(100% - 58px)',
  },
  // for each tab
  tabRoot: {
    width: isMobile ? '' : '300px',
    minHeight: '40px',
    height: '40px',
  },
});

interface AppBarTabsProps {
  sessionActive: boolean;
  currentTab: UITab;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  availableTabs: string[];
  connectionStatus: string;
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
}

class AppBarTabs extends PureComponent<AppBarTabsProps> {
  render() {
    const currentBox = SampleBox.getCurrentBox();

    return (
      <div style={{ display: 'flex' }}>
        <div id="menudrawer-container-1" style={{ display: 'inline-flex' }}></div>
        {this.props.availableTabs.length > 0 && this.props.sessionActive && (
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant={isMobile ? 'scrollable' : 'fullWidth'}
            value={this.props.currentTab}
            onChange={this.props.onChange}
            classes={{ root: this.props.classes.tabsRoot }}
          >
            {this.props.availableTabs.includes('mission') && (
              <Tab
                value="mission"
                label="Mission"
                data-testid="tab-mission"
                classes={{ root: this.props.classes.tabRoot }}
                key={'mission'}
              />
            )}
            {this.props.availableTabs.includes('schedule') && (
              <Tab
                value="schedule"
                label={'Schedule'}
                data-testid="tab-schedule"
                classes={{ root: this.props.classes.tabRoot }}
              />
            )}
            <Tab value="map" label="Map" data-testid="tab-map" classes={{ root: this.props.classes.tabRoot }} />
            {/* // TODO comment this section out to remove the box history tab */}
            {this.props.availableTabs.includes('boxes') && (
              <Tab
                value="boxes"
                label={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `Boxes${currentBox ? ` - <b>${currentBox.short_uid}</b>` : ''}`,
                    }}
                  />
                }
                classes={{ root: this.props.classes.tabRoot }}
              />
            )}
            {this.props.availableTabs.includes('robot') && (
              <Tab
                value="robot"
                label={<RobotStatus connectionStatus={this.props.connectionStatus} />}
                classes={{ root: this.props.classes.tabRoot }}
              />
            )}
          </Tabs>
        )}
      </div>
    );
  }
}

export default withStyles(tabStyles)(AppBarTabs);
