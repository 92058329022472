import React, { PureComponent } from 'react';

import { Grid } from '@material-ui/core';

import { LoadingButton } from '../../utils';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { allBarcodesValid } from '../../../barcodes';
import { getCurrentMission } from '../../../dataModelHelpers';
import download from 'downloadjs';
import { alertConfirmDownload } from '../helpers/downloads';
import { cancelConfirmTag } from '../../../alertDispatcher';

interface DownloadCSVButtonProps {}

export default class DownloadCSVButton extends PureComponent<DownloadCSVButtonProps> {
  constructor(props: DownloadCSVButtonProps) {
    super(props);

    this.downloadCSV = this.downloadCSV.bind(this);
  }

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
  }

  async downloadCSV() {
    try {
      if (allBarcodesValid() || (await alertConfirmDownload(this.constructor.name))) {
        const mission = getCurrentMission();
        if (!mission) {
          console.error('No mission available');
          return;
        }
        const missioncsv = await mission.to_csv();

        if (missioncsv) {
          download(missioncsv, `${mission.name}.csv`, 'text/csv');
        } else {
          console.error('Could not export CSV', missioncsv);
        }
      }
    } catch (err) {
      console.error('Could not export CSV', err);
    }
  }

  render() {
    return (
      <Grid item>
        <LoadingButton variant="outlined" onClick={this.downloadCSV} data-testid="mission-download-csv">
          <CloudDownloadIcon style={{ paddingRight: 7.5 }} />
          CSV
        </LoadingButton>
      </Grid>
    );
  }
}
