import { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';

import { RED } from '../../../rgbColors';
import { RobotConnectionStatus, RobotNavControl, RobotState } from '../../../types/types';
import { Coordinate } from 'ol/coordinate';
import { Sample, Waypoint } from '../../../db';
import { convertProjection4329 } from '../../../utils';

interface NavigationLayerProps {
  connectionStatus: RobotConnectionStatus;
  robotPosition: Coordinate;
  robotState: RobotState;
  robotControlState: RobotNavControl;
  currentPath: Coordinate[];
  currentWaypoint?: Waypoint;
  presentSample: Sample | undefined;
}

export default class NavigationLayer extends PureComponent<NavigationLayerProps> {
  render() {
    // const coreCoordinates = this.props.presentSample?.getNextSoilCore()?.getCoordinates();

    // let distance = 0;
    // const convertedCoreCoordinates = coreCoordinates ? convertProjection4329([coreCoordinates[1], coreCoordinates[0]]) : undefined;
    // if (coreCoordinates) {
    //   distance = Math.sqrt(
    //     Math.pow(convertedCoreCoordinates[0] - this.props.robotPosition[0], 2) +
    //     Math.pow(convertedCoreCoordinates[1] - this.props.robotPosition[1], 2)
    //   )
    // }

    // console.log(distance);

    // const zoomLevel = MapZoomStorage.get().value;

    return (
      // @ts-ignore
      <layer.VectorImage zIndex={2}>
        {/* 
        // @ts-ignore */}
        <source.VectorSourceReact>
          {this.props.currentPath.length > 0 && (
            <feature.LineStringReact
              coordinates={this.props.currentPath}
              strokeOptions={{ color: RED, width: 2 }}
              hideAtZoom={14}
            />
          )}
          {!!this.props.currentWaypoint && (
            <feature.PointReact
              coordinate={convertProjection4329([this.props.currentWaypoint.lon, this.props.currentWaypoint.lat])}
              iconOptions={{
                src: './images/pin2.png',
                anchor: [0.5, 1.1],
                scale: 0.2,
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                opacity: 1,
              }}
              hideAtZoom={14}
            />
          )}
        </source.VectorSourceReact>
      </layer.VectorImage>
    );
  }
}
