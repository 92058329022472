import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import SampleSite, { SampleSiteList } from './SampleSiteClass';
import Zone, { ZoneList } from './ZoneClass';
import { ISampleZone } from './types';

export default class SampleZone extends ObjectClassGenerator<SampleZone>('SampleZone') implements ISampleZone {
  // attributes

  // relationships
  #SampleSite_id?: number;
  #Zone_id?: number;
  static tableName = 'SampleZone';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SampleZone, 'SampleSite_id');
    this.publishAttribute(SampleZone, 'Zone_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SampleZone> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#SampleSite_id = state.SampleSite_id;
    this.#Zone_id = state.Zone_id;
  }

  dispose() {
    const samplesite = this.getSampleSite();
    if (samplesite) {
      samplesite.dispose();
      this.SampleSite_id = undefined;
    }
    const zone = this.getZone();
    if (zone) {
      this.Zone_id = undefined;
    }

    SampleZone.delete(this.instance_id);
  }

  contains(lat: number, lon: number) {
    return !!this.getZone()?.contains(lat, lon);
  }

  get mission_name() {
    return this.getSampleSite()?.mission_name;
  }

  get last_modified() {
    return this.getSampleSite()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getSampleSite();
    if (inst) {
      inst.last_modified = value;
    }
  }

  get sample_id() {
    return this.getSampleSite()?.getSamples()[0]?.sample_id;
  }

  set SampleSite_id(value) {
    if (this.#SampleSite_id) {
      const relateObj = SampleSite.get(this.#SampleSite_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SampleZone', this.instance_id);
      }
    }
    this.#SampleSite_id = value;
    if (value) {
      const relateObj = SampleSite.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SampleZone', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SampleSite_id() {
    return this.#SampleSite_id;
  }

  getSampleSite() {
    return SampleSite.get(this.SampleSite_id);
  }

  set Zone_id(value) {
    if (this.#Zone_id) {
      const relateObj = Zone.get(this.#Zone_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SampleZone', this.instance_id);
      }
    }
    this.#Zone_id = value;
    if (value) {
      const relateObj = Zone.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SampleZone', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Zone_id() {
    return this.#Zone_id;
  }

  getZone() {
    return Zone.get(this.Zone_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SampleZone.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.last_modified === this.last_modified &&
        sel.sample_id === this.sample_id,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate sample zone found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`sample zone: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getSampleSite()) {
      problems.push(
        `sample zone: Could not find sample site instance across unconditional relationship R17: ${this.instance_id}`,
      );
    }
    if (!this.getZone()) {
      problems.push(
        `sample zone: Could not find zone instance across unconditional relationship R18: ${this.instance_id}`,
      );
    }
    return problems;
  }
}

export class SampleZoneList extends DataObjectList<SampleZone> {
  getSampleSites() {
    return new SampleSiteList(...this.map((samplezone) => samplezone.getSampleSite()).filter((sel) => !!sel));
  }

  getZones() {
    return new ZoneList(...this.map((samplezone) => samplezone.getZone()).filter((sel) => !!sel));
  }
}
