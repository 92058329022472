import React, { PureComponent } from 'react';
import CommonMap from './CommonMap';
import MapButton from './buttons/MapButton';
import { MdArrowUpward, MdLocationSearching } from 'react-icons/md';

import OperatorScheduleLayer from './layers/OperatorScheduleLayer';
import RobotLocationLayer from './layers/RobotLocationLayer';
import RecordZoneLayer from './layers/RecordZoneLayer';
import NavigationLayer from './layers/NavigationLayer';

import ScannerButton from './buttons/ScannerButton';
import RecordZoneButton from './buttons/RecordZoneButton';
import ScheduleFieldZoomButton from './buttons/ScheduleFieldZoomButton';

import EventBus from '../../EventBus';

import MapProgressBar from '../utils/MapProgressBar';

import { custom } from 'react-openlayers';
import { MAP_TYPES } from './helpers/mapTypes';
import { dispatchMissionUpdated, MISSION_EVENTS } from '../../missionEvents';
import { getCurrentMission, getCurrentSession } from '../../dataModelHelpers';

import RobotRotateButton from './buttons/RobotRotateButton';
import {
  CoringAllowedState,
  OnLoading,
  RecordingCoords,
  RobotConnectionStatus,
  RobotNavControl,
  RobotState,
  ScheduleFeature,
  ScheduleViewMode,
  SettingsAccessLevel,
  SetWaypointFunction,
  SetZoneWaypointFunction,
} from '../../types/types';
import HMIButton from '../mission/buttons/HMIButton';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import { RosMissionMsg } from '../../types/rosmsg';
import { convertProjection4329, roundToIncrement, updateState } from '../../utils';
import LineToSampleLayer from './layers/LineToSampleLayer';
import { Sample, SoilCore, Waypoint } from '../../db';
import MapOverlayText from '../utils/MapOverlayText';
import { SCAN_EVENTS } from '../../scanEvents';
import SampleDistanceIndicator from '../utils/SampleDistanceIndicator';
import {
  MapManualDriveAidStorage,
  MapDebugStorage,
  MapDebugZoomLevel,
  MapZoomStorage,
  MapCalculationPositionStorage,
  ScheduleViewDisabled,
  ScheduleViewState,
} from '../../db/local_storage';
import RobotNavigationIndicator from '../utils/RobotNavigationIndicator';
import MapOverlayButton from '../utils/MapOverlayButton';
import { alertConfirm } from '../../alertDispatcher';
import CloseIcon from '@material-ui/icons/Close';
import { dispatchRotationUpdated, dispatchSetCenter, MAP_EVENTS } from '../../mapEvents';
import { ManualMeasurementDialogParameters } from '../../services/ManualMeasurementsChecker';
import { SamplingMapStateStorage } from './SamplingMapStateStorage';
import ChangeScheduleViewButton from './buttons/ChangeScheduleViewButton';

interface SamplingMapProps {
  setWaypoint: SetWaypointFunction;
  setZoneWaypoint: SetZoneWaypointFunction;
  loadMissionSchedule: (id: string) => Promise<void>;
  toggleScanner: () => void;
  scannerActive: boolean;
  scheduleFeatures: ScheduleFeature[];
  robotMission?: RosMissionMsg;
  connectionStatus: RobotConnectionStatus;
  missionActive: string;
  totalSamples?: number;
  filledSamples?: number;
  trackingOverride: boolean;
  hmiVisible: boolean;
  accessLevel: SettingsAccessLevel;
  robotNavControlState: RobotNavControl;
  navigatingToSampleLocation?: Coordinate;
  robotState: RobotState;
  presentSample?: Sample;
  closeSample?: Sample;
  coresInBucket: SoilCore[];
  drillDepth?: number;
  drillDepthOffset?: number;
  onLoading: OnLoading;
  inBounds: boolean;
  openManualMeasurementDialog: (manualMeasurementDialogParameters: ManualMeasurementDialogParameters) => void;
  targetCoordinate?: Coordinate;
}

export interface SamplingMapState {
  rotation?: { value: number };
  center?: { value: Coordinate };
  fit?: { value: Extent };
  zoom?: { value: number };
  recordingCoords: RecordingCoords;
  tracking: boolean;
  missionJobId: string;
  robotPos: Coordinate;
  robotHeading: number;
  followRobot: boolean;
  wideScreen: boolean;
  currentPath: Coordinate[];
  currentWaypoint?: Waypoint;
  scanningState?: CoringAllowedState;
  manualDriveAid: boolean;
  showScheduleView: boolean;
}

export default class SamplingMap extends PureComponent<SamplingMapProps, SamplingMapState> {
  SERIALIZABLE_STATE_VARIABLES: (keyof SamplingMapState)[] = ['followRobot', 'tracking', 'manualDriveAid'];

  constructor(props: SamplingMapProps) {
    super(props);

    this.state = {
      rotation: undefined,
      recordingCoords: { id: undefined, coords: [] },
      tracking: false,
      center: undefined,
      fit: undefined,
      zoom: undefined,
      missionJobId: '',
      robotPos: [],
      robotHeading: 0,
      //robotPos: [39.5319, -85.56451666422635],
      //robotPos: [-85.56451666422635, 39.5319],
      followRobot: false,
      wideScreen: false,
      currentPath: [],
      currentWaypoint: undefined,
      scanningState: undefined,
      manualDriveAid: false,
      showScheduleView: !ScheduleViewDisabled(),
    };

    this.setRotation = this.setRotation.bind(this);
    this.updateRecordingCoords = this.updateRecordingCoords.bind(this);
    this.toggleTracking = this.toggleTracking.bind(this);
    this.setCenter = this.setCenter.bind(this);
    this.setRobotHeading = this.setRobotHeading.bind(this);
    this.setFit = this.setFit.bind(this);
    this.getMissionJobId = this.getMissionJobId.bind(this);
    this.setRobotPosition = this.setRobotPosition.bind(this);
    this.clearCurrentPath = this.clearCurrentPath.bind(this);
  }

  componentDidUpdate(prevProps: SamplingMapProps) {
    this.setState({ wideScreen: window.innerWidth >= 600 });
    if (this.props.connectionStatus === 'disconnected' && prevProps.connectionStatus === 'connected') {
      this.clearCurrentPath();
    }
    this.serializeState();
  }

  async componentDidMount() {
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.getMissionJobId);
    EventBus.on(SCAN_EVENTS.BARCODE_SCAN_STATE, this.updateBarcodeScanState);

    EventBus.on('ROSMSG/mission/waypoint', this.indicateCurrentWaypoint, true);
    EventBus.on('ROSMSG/navigation/waypoints', this.indicateCurrentPath, true);
    EventBus.on('ROSMSG/mission/set_waypoint', this.clearCurrentPath);
    EventBus.on('ROSMSG/robot_state', this.handleRobotState, true);

    this.getMissionJobId();
    this.deserializeState();
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.getMissionJobId);
    EventBus.remove(SCAN_EVENTS.BARCODE_SCAN_STATE, this.updateBarcodeScanState);

    EventBus.remove('ROSMSG/mission/waypoint', this.indicateCurrentWaypoint);
    EventBus.remove('ROSMSG/navigation/waypoints', this.indicateCurrentPath);
    EventBus.remove('ROSMSG/mission/set_waypoint', this.clearCurrentPath);
    EventBus.remove('ROSMSG/robot_state', this.handleRobotState);
  }

  updateBarcodeScanState = (state: CoringAllowedState) => {
    this.setState({ scanningState: state });
  };

  handleRobotState = ({ hostname, msg }) => {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      const robotState = msg.data;
      if (robotState !== 0) {
        this.clearCurrentPath();
      }
    }
  };

  indicateCurrentWaypoint = ({ hostname, msg }) => {
    const session = getCurrentSession();
    const mission = getCurrentMission();
    if (session?.robot_hostname !== hostname || !mission) {
      this.setState({ currentWaypoint: undefined });
      return;
    }

    const currentWaypointIndex = msg.data;
    const waypoint = mission.getWaypoints().find((waypoint) => waypoint.waypoint_number === currentWaypointIndex);
    this.setState({ currentWaypoint: waypoint });

    // if (waypoint) {
    //     const coordsFormatted = convertProjection4329([waypoint.lon, waypoint.lat]);
    //     if (JSON.stringify(coordsFormatted) !== JSON.stringify(this.state.currentWaypoint)) {

    //     }
    // }
  };

  indicateCurrentPath = ({ hostname, msg }) => {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname && msg.header.frame_id === 'latlon') {
      const poses = msg.poses;
      // TODO because this maps to a Coordinate object we know we get a coordinate array but it would be nice if we knew what
      // the shape of the robot message itself was so we didn't have to be so explicit here
      const currentPath: Coordinate[] = poses.map((data) =>
        convertProjection4329([data.pose.position.x, data.pose.position.y]),
      );
      this.setState({ currentPath });
    }
  };

  clearCurrentPath() {
    this.setState({ currentPath: [] });
  }

  /**
   * serialize the state into session
   */
  serializeState() {
    const serializedState: Record<string, any> = {};
    for (const stateKey of this.SERIALIZABLE_STATE_VARIABLES) {
      serializedState[stateKey] = this.state[stateKey];
    }
    SamplingMapStateStorage.set(serializedState);
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const serializedState = SamplingMapStateStorage.get();
    if (serializedState) {
      for (const stateKey of Object.keys(serializedState)) {
        this.setState(updateState(stateKey as keyof SamplingMapState, serializedState[stateKey]));
      }
    }
  }

  getMissionJobId() {
    const mission = getCurrentMission();
    if (mission) {
      const job = mission.getJob();
      this.setState({
        missionJobId: job?.job_id ?? '',
        manualDriveAid: job?.enable_manual_drive_aid ?? false,
      });
    } else {
      this.setState({
        missionJobId: '',
        manualDriveAid: false,
      });
    }
  }

  setRobotPosition(robotPos: Coordinate) {
    this.setState({ robotPos });
  }

  /**
   * Sets the rotation of the map
   * @param {object} rotation
   */
  setRotation(rotation: { value: number }) {
    dispatchRotationUpdated(rotation.value);
  }

  /**
   * Sets the center of the map
   * @param {object} center
   */
  setCenter(center: { value: Coordinate }) {
    dispatchSetCenter(center.value);
  }

  setRobotHeading(heading: number) {
    this.setState({ robotHeading: heading });
  }

  setFit(fit: { value: Extent }) {
    this.setState({ fit });
  }

  /**
   * Updates the boundary recording coordinates
   * @param {RecordingCoords} recordingCoords
   */
  updateRecordingCoords(recordingCoords: RecordingCoords) {
    this.setState({ recordingCoords });
  }

  /**
   * Toggle the geolocation tracking
   */
  toggleTracking() {
    // if (this.state.tracking) {
    //     this.setState({ rotation: { value: 0 } })
    // }
    this.setState({ tracking: !this.state.tracking });
  }

  render() {
    // TODO removed used for now, not needed at this component level right now. If needed will possibly add a listener here and save
    // a class variable to avoid rerendering issues
    // const validRobotLocation = Boolean(this.state.robotPos.length) && (this.state.robotPos[0] !== 0 && this.state.robotPos[1] !== 0);

    let drillDepthLabel = '';
    if (this.props.drillDepth) {
      drillDepthLabel = `Current Depth: ${this.props.drillDepth}"`;
      if (this.props.drillDepthOffset) {
        drillDepthLabel += `${Math.sign(this.props.drillDepthOffset) === 1 ? '+' : ''}${this.props.drillDepthOffset}"`;
      }
    }

    let waypointSampleId = this.state.currentWaypoint?.getCorePoint()?.getSoilCore()?.sample_id || '';

    const mission = getCurrentMission();
    const offset = Boolean(this.props.presentSample) ? 0 : 40;
    const robotConnected = this.props.connectionStatus === 'connected';

    const manualDriveAid = MapManualDriveAidStorage.get() || this.state.manualDriveAid;

    return (
      <CommonMap
        openManualMeasurementDialog={this.props.openManualMeasurementDialog}
        rotation={this.state.rotation}
        missionActive={this.props.missionActive}
        type={MAP_TYPES.SAMPLING}
        setWaypoint={this.props.setWaypoint}
        setZoneWaypoint={this.props.setZoneWaypoint}
        robotMission={this.props.robotMission}
        // center={this.state.center}
        fit={this.state.fit}
        connectionStatus={this.props.connectionStatus}
        trackingButton={
          robotConnected &&
          this.state.tracking &&
          !this.props.trackingOverride && (
            <RobotRotateButton
              toggled={this.state.followRobot}
              onClick={() => this.setState({ followRobot: !this.state.followRobot })}
              marginTop={this.props.hmiVisible && !this.state.wideScreen ? 90 : 50}
            />
          )
        }
        absoluteOverlays={this.genereateAbsoluteOverlays(
          drillDepthLabel,
          offset,
          waypointSampleId,
          manualDriveAid,
          robotConnected,
        )}
        buttons={[
          Boolean(this.props.missionActive) && (
            <RecordZoneButton
              key={'RecordZoneButton'}
              updateRecordingCoords={this.updateRecordingCoords}
              recordingCoords={this.state.recordingCoords}
              connectionStatus={this.props.connectionStatus}
            />
          ),
          Boolean(this.props.missionActive) && (
            <ScannerButton
              key={'ScannerButton'}
              toggleScanner={this.props.toggleScanner}
              scannerActive={this.props.scannerActive}
            />
          ),
          <MapButton
            tooltip={this.state.tracking ? 'Disable Robot Tracking' : 'Enable Robot Tracking'}
            key={'ToggleTrackingMapButton'}
            onClick={this.toggleTracking}
            toggled={this.state.tracking}
          >
            <MdLocationSearching size={22} />
          </MapButton>,
          this.props.scheduleFeatures.length > 0 &&
            this.props.scheduleFeatures.filter(
              (feature) =>
                ['Polygon', 'MultiPolygon'].includes(feature.geometry.type) &&
                this.state.missionJobId !== feature?.properties?.['Rogo Job ID'],
            ).length > 0 && (
              <> 
                <ScheduleFieldZoomButton
                  key={'ScheduleFieldZoomButton'}
                  updateCenter={this.setCenter}
                  updateFit={this.setFit}
                  scheduleFeatures={this.props.scheduleFeatures}
                />
                <ChangeScheduleViewButton 
                  onChange={(mode: ScheduleViewMode) => {
                    this.setState({ showScheduleView: mode !== 'Disabled' });
                  }}
                />
              </>
            ),
          <MapButton
            key={'ManualDriveAid'}
            onClick={() => this.setState({ manualDriveAid: !this.state.manualDriveAid })}
            tooltip={this.state.manualDriveAid ? 'Disable Manual Drive Aids' : 'Enable Manual Drive Aids'}
            toggled={this.state.manualDriveAid}
          >
            <MdArrowUpward size={22} />
          </MapButton>,
          this.props.hmiVisible && this.state.wideScreen && (
            <HMIButton width={120} buttonID="butt_1" defaultLabel="Go/Core" height={40} fontSize={20} key="butt_1" />
          ),
          this.props.hmiVisible && this.state.wideScreen && (
            <HMIButton width={120} buttonID="butt_4" defaultLabel="Auto/Man" height={40} fontSize={20} key="butt_4" />
          ),
        ]}
        secondRowElements={
          this.props.hmiVisible && !this.state.wideScreen
            ? [
                <HMIButton
                  width={120}
                  buttonID="butt_1"
                  defaultLabel="Go/Core"
                  height={40}
                  fontSize={20}
                  key="butt_1"
                />,
                <HMIButton
                  width={120}
                  buttonID="butt_4"
                  defaultLabel="Auto/Man"
                  height={40}
                  fontSize={20}
                  key="butt_4"
                />,
              ]
            : undefined
        }
        layers={[
          Boolean(this.props.missionActive) && robotConnected && (
            <NavigationLayer
              connectionStatus={this.props.connectionStatus}
              robotPosition={this.state.robotPos}
              robotState={this.props.robotState}
              robotControlState={this.props.robotNavControlState}
              currentPath={this.state.currentPath}
              currentWaypoint={this.state.currentWaypoint}
              presentSample={this.props.presentSample}
            />
          ),
          (this.props.scheduleFeatures.length > 0) && this.state.showScheduleView && (
            <OperatorScheduleLayer
              loadMissionSchedule={this.props.loadMissionSchedule}
              scheduleFeatures={this.props.scheduleFeatures}
              missionActive={this.props.missionActive}
            />
          ),
          (!robotConnected || this.props.trackingOverride) && (
            <custom.GeolocationReact tracking={this.state.tracking} />
          ),
          robotConnected && !this.props.trackingOverride && (
            <RobotLocationLayer
              rotateMapWithRobot={this.state.followRobot}
              centerMapOnRobot={this.state.tracking}
              presentSample={this.props.presentSample}
              closeSample={this.props.closeSample}
              currentWaypoint={this.state.currentWaypoint}
              useArmOrigin={
                mission?.getJob()?.implement_centerted_navigation || MapCalculationPositionStorage.get() === 'Arm'
              }
              targetCoordinate={this.props.targetCoordinate}
            />
          ),
          Boolean(this.props.missionActive) && this.state.recordingCoords.coords.length > 0 && (
            <RecordZoneLayer recordingCoords={this.state.recordingCoords.coords} />
          ),
          // this is really just a debug function right now as it can cause confusion in the field because it "auto advances" in manual mode
          Boolean(this.props.missionActive) &&
            !this.state.currentPath.length &&
            this.props.robotNavControlState === 'Auto' &&
            !getCurrentMission()?.is_zone_mission() && (
              <LineToSampleLayer robotPosition={this.state.robotPos} sampleLocation={this.state.currentWaypoint} />
            ),
        ]}
        accessLevel={this.props.accessLevel}
        robotNavControlState={this.props.robotNavControlState}
        onLoading={this.props.onLoading}
      />
    );
  }

  private genereateAbsoluteOverlays(
    drillDepthLabel: string,
    offset: number,
    waypointSampleId: string,
    manualDriveAid: boolean,
    robotConnected: boolean,
  ): React.ReactNode[] {
    const out: React.ReactNode[] = [];
    const missionActive = this.props.missionActive;
    const coresInBucket = this.props.coresInBucket;

    if (missionActive && this.props.totalSamples !== undefined) {
      out.push(
        <MapProgressBar
          total={this.props.totalSamples}
          filled={this.props.filledSamples}
          marginTop={this.props.hmiVisible && !this.state.wideScreen ? 90 : 50}
          preText="S"
        />,
      );
    }

    if (missionActive && Boolean(this.props.presentSample)) {
      out.push(
        <MapProgressBar
          total={this.props.presentSample?.getSoilCores().length}
          filled={coresInBucket.length}
          marginTop={this.props.hmiVisible && !this.state.wideScreen ? 130 : 90}
          preText="C"
        />,
      );
    }

    if (missionActive && Boolean(drillDepthLabel)) {
      out.push(<MapOverlayText marginTop={(this.props.hmiVisible && !this.state.wideScreen ? 170 : 130) - offset} />);
    }

    if (missionActive) {
      out.push(
        <MapOverlayText
          text={`Next Sample: ${waypointSampleId || '?'}`}
          marginTop={(this.props.hmiVisible && !this.state.wideScreen ? 210 : 170) - offset}
        />,
      );

      out.push(
        <MapOverlayText
          text={`Sample in bucket: ${coresInBucket.length ? (coresInBucket[coresInBucket.length - 1].sample_id ?? 'Test') : '?'}`}
          marginTop={(this.props.hmiVisible && !this.state.wideScreen ? 250 : 210) - offset}
        />,
      );
    }

    if (Boolean(this.props.missionActive) && !!this.state.scanningState && this.state.scanningState !== 'Good') {
      out.push(
        <MapOverlayText
          text={`${this.state.scanningState}`}
          marginTop={this.props.hmiVisible && !this.state.wideScreen ? 270 : 230}
          blink={true}
          style={{
            right: undefined,
            left: 20,
            fontSize: '32px',
          }}
        />,
      );
    }

    if (MapDebugStorage.get() && MapDebugZoomLevel.get()) {
      out.push(
        <MapOverlayText
          text={`Map Zoom Level: ${roundToIncrement(MapZoomStorage.get().value, 0.1)}`}
          marginTop={(this.props.hmiVisible && !this.state.wideScreen ? 400 : 360) - offset}
        />,
      );
    }

    if (missionActive && MapDebugStorage.get()) {
      out.push(
        <MapOverlayText
          text={`Cores in bucket:`}
          marginTop={(this.props.hmiVisible && !this.state.wideScreen ? 340 : 290) - offset}
        >
          {coresInBucket.length ? (
            coresInBucket.map((core, index) => {
              return (
                <MapOverlayButton
                  text={core.name}
                  icon={<CloseIcon />}
                  marginTop={10 + index * 60}
                  marginLeft={400}
                  onClick={async () => {
                    const response = await alertConfirm('Are you sure you want to remove this core from the bucket?');
                    if (response) {
                      core.resetPulled();
                      if (core.test_core_Mission_id) {
                        // delete core
                        core.dispose();
                      }
                      dispatchMissionUpdated();
                    }
                  }}
                />
              );
            })
          ) : (
            <MapOverlayText text={'None'} marginTop={10} />
          )}
        </MapOverlayText>,
      );
    }

    if (manualDriveAid && robotConnected) {
      out.push(
        <SampleDistanceIndicator
          range={[-20, 20]}
          markerValue={-10}
          presentSample={this.props.presentSample}
          height={window.innerHeight}
          currentWaypoint={this.state.currentWaypoint}
          targetCoordinate={this.props.targetCoordinate}
        />,
      );

      out.push(
        <RobotNavigationIndicator
          range={[-20, 20]}
          markerValue={-10}
          presentSample={this.props.presentSample}
          height={window.innerHeight}
          currentWaypoint={this.state.currentWaypoint}
          targetCoordinate={this.props.targetCoordinate}
        />,
      );
    }

    return out;
  }
}
