import { ObjectValues } from './types/types';

export const KML_FEATURE_TYPE = {
  PULLIN: 'pullin',
  CORE_POINT: 'core point',
  DUMP_EVENT: 'dump event',
  CENTROID: 'centroid',
  FIELD: 'field',
  COLLECTED_FIELD: 'collected field',
  UNSAFE: 'unsafe',
  SLOW: 'slow',
  GENERIC_ZONE: 'generic_zone',
  SAMPLE_ZONE: 'sample_zone',
  PATH: 'path',
  REORDER_LINE: 'reorder line',
  CORE_LINE: 'core line',
} as const;

export type KmlFeatureType = ObjectValues<typeof KML_FEATURE_TYPE>;
