import React, { PureComponent } from 'react';

import MapLoadingButton from './MapLoadingButton';

import { FaCarAlt } from 'react-icons/fa';

interface WaypointSetButtonProps {
  onClick: () => Promise<void>;
  loading: boolean;
  textColor?: string;
}

export default class WaypointSetButton extends PureComponent<WaypointSetButtonProps> {
  render() {
    return (
      <MapLoadingButton tooltip="Set as next waypoint" onClick={this.props.onClick} loading={this.props.loading}>
        <FaCarAlt size={20} color={this.props.textColor ? this.props.textColor : ''} />
      </MapLoadingButton>
    );
  }
}
