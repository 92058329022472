import React from 'react';

import {
  Grid,
  Table,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Paper,
  TableBody,
  TableHead,
  withStyles,
} from '@material-ui/core';
import { AirtableRecord } from '../../db';

interface JobListProps {
  list: AirtableRecord[];
  updateZoomField: (job: AirtableRecord) => void;
}

const CustomTable = withStyles((theme) => ({
  root: {
    '& td, & th': {
      padding: '10px',
    },
  },
}))(Table);

const ClickableRow = withStyles((theme) => ({
  root: { cursor: 'pointer' },
}))(TableRow);

export default function JobList(props: JobListProps) {
  const jobList = props.list;

  return (
    <Grid container justifyContent="center" style={{ paddingTop: 20 }}>
      <TableContainer component={Paper} style={{ maxWidth: '100%', width: '100%' }} className="scroll">
        <CustomTable style={{ maxWidth: '100%', width: '100%', tableLayout: 'auto' }}>
          <TableHead style={{ width: '100%', maxWidth: '100%' }}>
            <TableRow style={{ width: '100%', border: 0 }}>
              <TableCell>
                <Typography>
                  <b>Late</b>
                </Typography>
              </TableCell>

              <TableCell>
                <Typography>
                  <b>Samples</b>
                </Typography>
              </TableCell>

              <TableCell>
                <Typography>
                  <b>Acres</b>
                </Typography>
              </TableCell>

              <TableCell>
                <Typography>
                  <b>Grid</b>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobList.map((job) => {
              return (
                <React.Fragment key={job.id}>
                  <ClickableRow onClick={() => props.updateZoomField(job)}>
                    <TableCell colSpan={4} scope="column" align="center" style={{ maxWidth: '95%' }}>
                      {`${job.table === 'Jobs' ? 'Job' : 'Dropoff'} -- `}
                      {job.get('Name')}
                    </TableCell>
                  </ClickableRow>

                  <TableRow style={{ borderBottom: '2px solid black' }}>
                    <TableCell style={{ maxWidth: '95%' }}>
                      {job.table === 'Jobs' && job.get('Late (days)') ? job.get('Late (days)') : 'DNE'}
                    </TableCell>

                    <TableCell style={{ maxWidth: '95%' }}>
                      {job.table === 'Jobs' && job.get('# Samples') ? job.get('# Samples') : 'DNE'}
                    </TableCell>

                    <TableCell style={{ maxWidth: '95%' }}>
                      {job.table === 'Jobs' && job.get('Boundary Acres') ? job.get('Boundary Acres') : 'DNE'}
                    </TableCell>

                    <TableCell style={{ maxWidth: '95%' }}>
                      {job.table === 'Jobs' && job.get('Grid') ? job.get('Grid') : 'DNE'}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </Grid>
  );
}
