import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { AppBar } from '@material-ui/core';

import Tasks from './Tasks';

import { AlertEvent, alertError, alertInfo, alertWarn, cancelConfirmTag } from '../alertDispatcher';

import { initScanner, deinitScanner } from '../scanner';

import { getCurrentMission, getCurrentSession, loadUsers } from '../dataModelHelpers';
import EventBus from '../EventBus';
import { MISSION_EVENTS } from '../missionEvents';
import Login from './Login';
import { TASK_EVENTS } from '../taskEvents';
import { UITab, getAvailableTabs } from './helpers/task';
import AppBarTabs from './AppBarTabs';
import Logout from './Logout';
import { dispatchSessionUpdated, SESSION_EVENTS } from '../sessionEvents';

import SamplingTask from './SamplingTask/SamplingTask';
import MapsTask from './MapsTask';
import ShippingTask from './ShippingTask';
import SchedulingTask from './SchedulingTask';
import { OnLoading, RobotConnectionStatus, SettingsAccessLevel } from '../types/types';
import { TaskName } from '../db/TaskClass';
import RecoveryLoadingPopup from './mission/RecoveryLoadingPopup';
import { Session } from '../db';
import * as Sentry from '@sentry/react';
import logger from '../logger';
import PasswordPrompt from './PasswordPrompt';
import { ALERT_RETURN_EVENTS, FULL_SCREEN_ALERT } from '../alertEvents';
import { SAMPLING_TASK } from '../constants';
import { FullScreenAlert } from './alerts/FullScreenAlert';
import { BOX_EVENTS } from '../boxEvents';

const styles = {
  root: {
    margin: 0,
    width: '100%',
  },
};

interface DashboardProps {
  appBarHidden: boolean;
  devMode: boolean;
  hideAppBar: (hidden: boolean) => void;
  onLoading: OnLoading;
  setSideBarText: (welcomeString: string) => void;
  sidebarToggleFunction: () => void;
  buttonsRef?: HTMLDivElement;
  taskRef?: HTMLDivElement;
  disableSentry: boolean;
  passwordPromptOpen: boolean;
  validatePassword?: (password: string) => Promise<SettingsAccessLevel>;
  passwordLength: number;
  onPasswordClose?: (result: SettingsAccessLevel) => void;
  elevatePermissions: () => void;
  accessLevel: SettingsAccessLevel;
}

interface DashboardState {
  missionActive: string;
  sessionActive: boolean;
  showRecoveryPopup: boolean;
  currentTab: UITab;
  connectionStatus: RobotConnectionStatus;
  task?: TaskName;
  availableTabs: string[];
  jobId: string;
  forceShapefileLoad: boolean;
  fullScreenAlertConfirm?: (result: string | [string, string]) => void;
  fullScreenAlertCancel?: () => void;
  alertEvent?: AlertEvent;
  renderCount: number;
}

class Dashboard extends PureComponent<DashboardProps, DashboardState> {
  showPDF: React.RefObject<unknown>;
  firstInit: boolean = true;
  constructor(props: DashboardProps) {
    super(props);
    // get the last par of the url
    let task: TaskName | undefined = TaskName.SAMPLING;
    let currentTab = UITab.NONE;
    let jobId = '';
    let forceShapefileLoad = false;
    try {
      const [, pathAndQuery] = window.location.href.split('#');
      const [path, query] = pathAndQuery.split('?');
      const pathTokens = path.split('/').filter((t) => t.length > 0);
      task = pathTokens[0] as TaskName;
      currentTab = pathTokens[1] as UITab;
      const params = new URLSearchParams(query);
      jobId = params.get('jobid') || '';
      forceShapefileLoad = params.has('shapefile');
    } catch (e) {
      // specifically ignore any errors related to url parsing
    }
    // get the task name from the last part of the url
    // const task = lastPart?.split('/')[0];
    // // get the tab name from the last part of the url
    // const tab = lastPart?.split('/')[1];
    // set the state
    this.state = {
      missionActive: '',
      sessionActive: false,
      showRecoveryPopup: false,
      currentTab,
      connectionStatus: 'disconnected',
      task,
      availableTabs: [],
      jobId,
      forceShapefileLoad,
      fullScreenAlertConfirm: undefined,
      fullScreenAlertCancel: undefined,
      alertEvent: undefined,
      renderCount: 0,
    };

    this.updateMission = this.updateMission.bind(this);
    this.updateSessionActive = this.updateSessionActive.bind(this);
    this.loadCurrentTask = this.loadCurrentTask.bind(this);
    this.handleConnectionStatus = this.handleConnectionStatus.bind(this);
    this.handleFullScreenAlert = this.handleFullScreenAlert.bind(this);
    this.login = this.login.bind(this);
    this.showPDF = React.createRef();
  }

  async componentDidMount() {
    EventBus.on('CONNECTION_STATUS_HEADER', this.handleConnectionStatus);
    await this.updateSessionActive();
    this.updateMission();
    await loadUsers();

    // listens for mission to be created or deleted
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.updateMission);
    EventBus.on(TASK_EVENTS.UPDATED, this.loadCurrentTask);
    EventBus.on(SESSION_EVENTS.UPDATED, this.updateSessionActive);
    EventBus.on(FULL_SCREEN_ALERT, this.handleFullScreenAlert);
    EventBus.on(SAMPLING_TASK.CLOSE_SAMPLE_SELECTION_POPUP, this.closeSampleSelectionPopup);
    EventBus.on(BOX_EVENTS.ACTIVE_UPDATED, this.updateActiveBox);

    // TODO we are now doing this last because it is causing an out of order bug and we're not getting rosstatus connection updates...
    await this.loadCurrentTask();
  }

  updateActiveBox = () => {
    this.setState({ renderCount: this.state.renderCount + 1 });
  };

  closeSampleSelectionPopup = () => {
    this.setState({ alertEvent: undefined });
  };

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.updateMission);
    EventBus.remove(TASK_EVENTS.UPDATED, this.loadCurrentTask);
    EventBus.remove(SESSION_EVENTS.UPDATED, this.updateSessionActive);
    EventBus.remove(FULL_SCREEN_ALERT, this.handleFullScreenAlert);
    EventBus.remove('CONNECTION_STATUS_HEADER', this.handleConnectionStatus);
    EventBus.remove(SAMPLING_TASK.CLOSE_SAMPLE_SELECTION_POPUP, this.closeSampleSelectionPopup);
    EventBus.remove(BOX_EVENTS.ACTIVE_UPDATED, this.updateActiveBox);
  }

  componentDidUpdate(_prevProps: DashboardProps, prevState: DashboardState) {
    if (prevState.task !== this.state.task || this.firstInit) {
      this.firstInit = false;
      // init/deinit external barcode scanner
      Sentry.setTag('task', this.state.task || 'none');
      if (this.state.task === TaskName.SAMPLING) {
        initScanner();
      } else {
        deinitScanner();
      }
    }

    if (this.state.currentTab === 'map' && !this.props.appBarHidden) {
      this.props.hideAppBar(true);
    } else if (this.state.currentTab !== 'map' && this.props.appBarHidden) {
      this.props.hideAppBar(false);
    }

    if (this.state.connectionStatus !== 'connected' && this.state.currentTab === 'robot') {
      //this.setState({ currentTab: 'mission' });
    }
  }

  async handleConnectionStatus(data: { hostname: string; status: RobotConnectionStatus }) {
    const session = getCurrentSession();
    if (session && data.hostname === session.robot_hostname) {
      this.setState({ connectionStatus: data.status });
    }
  }

  async loadCurrentTask(newTaskName?: TaskName) {
    const session = getCurrentSession();
    if (!session) {
      return;
    }

    const taskName = newTaskName || this.state.task || session.getTask()?.name;
    if (!taskName) {
      this.setState({ task: undefined, availableTabs: [], currentTab: UITab.NONE });

      return;
    }

    const availableTabs = getAvailableTabs(taskName);
    let currentTab = this.state.currentTab;
    if (!availableTabs.includes(currentTab)) {
      currentTab = availableTabs[0];
    }

    const jobId = this.state.jobId;
    const forceShapefileLoad = this.state.forceShapefileLoad;
    this.setState({ task: taskName, availableTabs, currentTab, jobId: '', forceShapefileLoad: false });
    window.location.replace(`/#/${taskName}/${currentTab || ''}`);

    if (!jobId) {
      return;
    }

    if (!session.Mission_id) {
      setTimeout(() => {
        const dispatchTarget = `${taskName}:LOAD_MISSION`;
        EventBus.dispatch(dispatchTarget, JSON.stringify({ jobId, forceShapefileLoad }));
      }, 2000);
      alertInfo(`Requesting ${taskName} task to open mission ${jobId}`);
      // reload the page
    } else if (session.getMission()?.job_id !== jobId) {
      alertWarn('Another mission is already loaded, please close it before opening another one');
    } else {
      alertWarn('This mission is already loaded! If you want to reload it, please close it first.');
    }
  }

  handleFullScreenAlert(this: Dashboard, alertEvent: AlertEvent) {
    this.setState({
      alertEvent,
      fullScreenAlertConfirm: (result: string | [string, string]) => {
        EventBus.dispatch(ALERT_RETURN_EVENTS.CONFIRM_RETURN, {
          message: alertEvent.message,
          confirm: true,
          confirmNum: alertEvent.confirmNum,
          tag: alertEvent.tag,
          result,
        });

        this.setState({ alertEvent: undefined });
      },
    });
  }

  /**
   * Callback used for updating mission display data
   */
  async updateMission() {
    const mission = getCurrentMission();
    // const missionActive = Boolean(mission);
    this.setState({ missionActive: mission ? mission?.job_id || 'no id' : '' });
  }

  async login(username: string, password: string, alreadyHashed = false) {
    await logger.log('LOGGING_IN', { username });

    try {
      let session = getCurrentSession();
      if (!session || (session && !session.User_id)) {
        session = await Session.newSession(username, password, alreadyHashed);
      }

      if (session) {
        if (!this.props.disableSentry) {
          try {
            Sentry.setUser({ username });
          } catch (e) {
            console.error('Error setting username in login', e);
          }
        }

        logger.setUser(session.username);
        this.props.setSideBarText(`Welcome, ${session.username}!`);
        dispatchSessionUpdated();
      } else {
        logger.setUser();
        await logger.log('LOGIN_FAILED', `${username},${password}`);
        alertError('Invalid username or password');
        // this.setState({
        //     loginErrorMessage: 'Invalid username or password',
        //     password: '',
        // });
      }
    } catch (err) {
      console.error(err);
      alertError('Problem executing login.');
    }
  }

  /**
   * Callback used for updating session display data
   */
  async updateSessionActive() {
    const session = getCurrentSession();
    if (!session) {
      this.setState({ sessionActive: false });

      return;
    }

    const user = session.getUser();
    if (!user) {
      this.setState({ sessionActive: false });

      return;
    }

    this.props.setSideBarText(`Welcome, ${session.username}!`);
    Sentry.setUser({ username: session.username });
    logger.setUser(session.username);
    this.setState({ sessionActive: true });
  }

  render() {
    return (
      <React.Fragment>
        <AppBar position="sticky" color="default">
          <AppBarTabs
            availableTabs={this.state.availableTabs}
            currentTab={this.state.currentTab}
            connectionStatus={this.state.connectionStatus}
            sessionActive={this.state.sessionActive}
            onChange={(_e, v) => {
              this.setState({ currentTab: v });
              window.location.replace(`/#/${this.state.task}/${v}`);
            }}
          />
        </AppBar>

        <FullScreenAlert
          alertEvent={this.state.alertEvent}
          fullScreenAlertConfirm={this.state.fullScreenAlertConfirm}
        />

        {this.state.task === 'sampling' && this.state.sessionActive && (
          <SamplingTask
            tab={this.state.currentTab}
            task={this.state.task}
            onLoading={this.props.onLoading}
            missionActive={this.state.missionActive}
            connectionStatus={this.state.connectionStatus}
            devMode={this.props.accessLevel === 'Technician'}
            accessLevel={this.props.accessLevel}
            showRecoveryPopup={() => {
              this.setState({ showRecoveryPopup: true });
            }}
            elevatePermissions={this.props.elevatePermissions}
          />
        )}

        {this.state.task === 'maps' && this.state.sessionActive && (
          <MapsTask
            tab={this.state.currentTab}
            task={this.state.task}
            onLoading={this.props.onLoading}
            missionActive={this.state.missionActive}
            accessLevel={this.props.accessLevel}
            updateCurrentTab={(tab: UITab) => {
              this.setState({ currentTab: tab });
              window.location.replace(`/#/${this.state.task}/${tab}`);
            }}
          />
        )}

        {this.state.task === 'scheduling' && this.state.sessionActive && <SchedulingTask />}

        {this.state.task === 'shipping' && this.state.sessionActive && <ShippingTask />}

        {this.props.passwordPromptOpen && this.props.onPasswordClose && this.props.validatePassword && (
          <PasswordPrompt
            accessLevel={this.props.accessLevel}
            close={this.props.onPasswordClose}
            passwordLength={this.props.passwordLength}
            validatePassword={this.props.validatePassword}
          />
        )}

        {/* Dialogs start */}
        {!this.state.sessionActive && !this.state.showRecoveryPopup && (
          <Login
            setSideBarText={this.props.setSideBarText}
            onLoading={this.props.onLoading}
            disableSentry={this.props.disableSentry}
            showRecoveryPopup={() => {
              this.setState({ showRecoveryPopup: true });
            }}
            login={this.login}
          />
        )}

        {this.state.showRecoveryPopup && (
          <RecoveryLoadingPopup
            onClose={() => {
              this.setState({ showRecoveryPopup: false });
            }}
            onLoading={this.props.onLoading}
            devMode={this.props.accessLevel === 'Technician'}
            login={!this.state.sessionActive ? this.login : undefined}
          />
        )}
        {/* Dialogs end */}

        {/* Sidebar portals start */}
        {this.state.sessionActive && (
          <Logout sidebarToggleFunction={this.props.sidebarToggleFunction} buttonsRef={this.props.buttonsRef} />
        )}

        {this.state.sessionActive && (
          <Tasks
            task={this.state.task}
            taskRef={this.props.taskRef}
            sidebarToggleFunction={this.props.sidebarToggleFunction}
          />
        )}

        {/* Sidebar portals end */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Dashboard);
