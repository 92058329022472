import React from 'react';

import List from '@material-ui/core/List';
import Pagination from '@material-ui/lab/Pagination';

import SampleJob from './SampleJob';
import ShippingDropoff from './ShippingDropoff';
import AirtableRecord from '../../../db/AirtableRecordClass';
import { Accordion, AccordionSummary, Box, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { FaAngleRight } from 'react-icons/fa';
import { LatLonSource } from '../../../types/types';

const PAGE_SIZE = 20;

interface ScheduleCollectionProps {
  currentLat: number;
  currentLon: number;
  filterText: string;
  items: AirtableRecord[];
  loadMissionSchedule: (id: string) => Promise<void>;
  missionActive: string;
  showNavMessage: (directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) => void;
  showSampled: boolean;
  title?: string;
  acres?: string;
  equivalentAcres?: string;
  sampleCount?: string;
  resubmitButton?: JSX.Element;
  defaultExpanded: boolean;
  sortFunc: (a: AirtableRecord, b: AirtableRecord) => number;
  reverse: boolean;
}

function ScheduleCollection(props: ScheduleCollectionProps) {
  const [page, setPage] = React.useState(1);
  let allItems = props.items
    .filter((sel) => !props.filterText || sel.get('Name').toLowerCase().includes(props.filterText.toLowerCase()))
    .sort(props.sortFunc);
  const count = Math.ceil(allItems.length / PAGE_SIZE);
  const p = Math.min(page, count);
  const items = allItems.slice((p - 1) * PAGE_SIZE, p * PAGE_SIZE);
  const onMobile = window.innerWidth < 600;
  return (
    <Accordion defaultExpanded={props.defaultExpanded}>
      <AccordionSummary expandIcon={<FaAngleRight />} aria-controls="panel1a-content" id="panel1a-header">
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '20%' }}>
                <Typography style={{ fontWeight: 'bold' }}>{props.title}</Typography>
              </TableCell>
              {!onMobile && (
                <>
                  <TableCell style={{ width: '20%' }}>
                    <Box display="flex" flexDirection="row">
                      <Typography style={{ fontWeight: 'bold', marginRight: 10 }}>{props.acres}</Typography>
                      <Typography>Acres</Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <Box display="flex" flexDirection="row">
                      <Typography style={{ fontWeight: 'bold', marginRight: 10 }}>{props.equivalentAcres}</Typography>
                      <Typography>Ops Acres</Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <Box display="flex" flexDirection="row">
                      <Typography style={{ fontWeight: 'bold', marginRight: 10 }}>{props.sampleCount}</Typography>
                      <Typography>Samples</Typography>
                    </Box>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <Typography>{props.resubmitButton}</Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
        </Table>
        {/* <Grid container alignItems='stretch' alignContent='flex-start' direction='row'>
        <Grid item>
          <Typography style={{ marginRight: 20 }}>
              {props.header1}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ marginLeft: 80 }}>
          {props.header2}
          </Typography>
        </Grid>
        {props.header3 ? (
          <Grid item style={{ marginLeft: 80 }}>
            {props.header3}
          </Grid>
          ) : (null)
        }
      </Grid> */}
      </AccordionSummary>
      {allItems.length > PAGE_SIZE ? (
        <Pagination
          count={count}
          page={p}
          variant="outlined"
          shape="rounded"
          onChange={(_e, p) => {
            setPage(p);
          }}
        />
      ) : null}
      <List dense>
        {items.map((item, _idx) => {
          if (item.table === 'Shift Dropoffs') {
            // TODO we should reformat this Dropoff Locaiton field to pull from separate lat/lon
            return <ShippingDropoff key={item.id} id={item.id} location={item.get('Dropoff Location')} {...props} />;
          } else {
            return (
              <SampleJob
                key={item.id}
                job={item}
                id={item.id}
                name={item.get('Name')}
                order={item.get('Order')}
                {...props}
              />
            );
          }
        })}
      </List>
    </Accordion>
  );
}

ScheduleCollection.defaultProps = {
  sortFunc: (a: AirtableRecord, b: AirtableRecord) => (a.get('Order') as number) - (b.get('Order') as number),
  reverse: false,
  header1: undefined,
  header2: undefined,
  header3: undefined,
  header4: undefined,
  header5: undefined,
};

export default ScheduleCollection;
