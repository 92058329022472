import React, { PureComponent } from 'react';
import LoadingButton from '../../utils/LoadingButton';
import { Grid } from '@material-ui/core';
// import { getCurrentMission } from '../../../dataModelHelpers';

import CloseIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

interface ShowNotesButtonProps {
  isShown: boolean;
  handleClick: () => void;
}

export default class ShowNotesButton extends PureComponent<ShowNotesButtonProps> {
  render() {
    return (
      <>
        <Grid item>
          {this.props.isShown ? (
            <LoadingButton
              variant="outlined"
              style={{ color: '#cc0000', borderColor: '#cc0000' }}
              onClick={() => {
                this.props.handleClick();
              }}
            >
              <CloseIcon style={{ paddingRight: 7.5 }} />
              Hide Notes
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="outlined"
              onClick={() => {
                this.props.handleClick();
              }}
            >
              <UnfoldMoreIcon style={{ paddingRight: 7.5 }} />
              Show Notes
            </LoadingButton>
          )}
        </Grid>
      </>
    );
  }
}
