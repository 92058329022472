import { Coordinate } from 'ol/coordinate';
import { feature } from 'react-openlayers';
import { LineWithDistance } from './line_with_distance';
import { MapPointResolution } from '../../../db/local_storage';
import { ColorLike } from 'ol/colorlike';

interface MeasuredCircleProps {
  innerRadiusMeters?: number;
  outerRadiusMeters: number;
  pixelsPerMeter: number;
  center: Coordinate;
  circleMeasurements?: boolean;
  color?: ColorLike;
  key?: string;
}

export function MeasuredCircle(props: MeasuredCircleProps) {
  let { innerRadiusMeters, outerRadiusMeters, color, center, circleMeasurements, pixelsPerMeter } = props;

  if (!color || !innerRadiusMeters) {
    if (innerRadiusMeters && innerRadiusMeters > 0) {
      console.warn('MeasuredCircle: color is not set, making inner circle invisible');
    }
    innerRadiusMeters = 0;
  }

  const radiusMeters = innerRadiusMeters ? (outerRadiusMeters + innerRadiusMeters) / 2 : outerRadiusMeters / 2;
  const radiusPixels = pixelsPerMeter * radiusMeters;

  const widthMeters = innerRadiusMeters ? outerRadiusMeters - innerRadiusMeters : outerRadiusMeters;
  const widthPixels = pixelsPerMeter * widthMeters;

  return (
    <>
      <feature.PointReact
        coordinate={center}
        circleOptions={{
          radius: radiusPixels,
          strokeOptions: color
            ? {
                color: color,
                width: widthPixels,
              }
            : {},
        }}
        zIndex={-1}
        hideAtZoom={14}
      />
      {!!innerRadiusMeters && circleMeasurements && (
        <LineWithDistance
          coordinates={[center, [center[0] + innerRadiusMeters / MapPointResolution.get(), center[1]]]}
          color={'blue'}
        />
      )}
      {circleMeasurements && (
        <LineWithDistance
          coordinates={[
            [center[0] + innerRadiusMeters / MapPointResolution.get(), center[1]],
            [center[0] + outerRadiusMeters / MapPointResolution.get(), center[1]],
          ]}
          color={'black'}
        />
      )}
    </>
  );
}
