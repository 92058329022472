import { AirtableRecord, Mission, Session, User } from '../../db';
import { ShowAutoGenHelper } from '../../types/types';
import { MissionState } from '../../constants';
import { alertConfirm, alertFullScreen, alertWarn } from '../../alertDispatcher';
import { getFriendlyTime } from '../../utils';
import { getCurrentUser } from '../../dataModelHelpers';

import { Jobs } from '@rogoag/airtable';
import * as Sentry from '@sentry/react';

export const DataSourceOptions = [
  'Shapefiles',
  'Int Mission',
  'Exe Mission',
  'Partial Mission',
  'Cancel Load',
] as const;

export type DataSourceOption = (typeof DataSourceOptions)[number];

export interface IMissionLoader {
  load(
    session: Session,
    jobID: string,
    sampling: boolean,
    setAutogenHelper: ShowAutoGenHelper | undefined,
  ): Promise<Mission | undefined>;
}

export async function mapMakingLock(job: AirtableRecord<Jobs>): Promise<DataSourceOption> {
  try {
    await job.refresh();

    // first check if there is an INT Mission
    const intMissionFiles = await job.get_attachments('Int Mission');
    const partialMissionFiles = await job.get_attachments('Partial Mission');
    const exeMissionFiles = await job.get_attachments('Exe Mission');
    const mapmakers = job.get('Mapmaker') || [];
    const mapmakerLastModifiedTime = job.get('Mapmaker Last Modified');
    // parse the string time as a Date
    const missionState = job.get('Mission Status');
    const missionFinalized = missionState === MissionState._4_Mission_Finalized;
    let dataSource: DataSourceOption = 'Shapefiles';
    const options: DataSourceOption[] = ['Shapefiles'];
    if (intMissionFiles.length > 0) {
      options.push('Int Mission');
    }
    if (exeMissionFiles.length > 0) {
      options.push('Exe Mission');
    }
    if (partialMissionFiles.length > 0) {
      options.push('Partial Mission');
    }
    options.push('Cancel Load');
    if (intMissionFiles.length > 0 || exeMissionFiles.length > 0 || partialMissionFiles.length > 0) {
      dataSource = await alertFullScreen(
        'Select Data Source',
        'This mission has a map' +
          (missionFinalized ? '. ' : ` but is not finalized (mission state = ${missionState}). `) +
          'Please select the data source to open.',
        options,
      );

      if (dataSource === 'Cancel Load') {
        return 'Cancel Load' as const;
      }
    } else {
      if (mapmakers?.length > 0) {
        // get user from ID
        const user = User.findOne((u) => u?.id === mapmakers[0]);
        if (user) {
          const friendlyTime = mapmakerLastModifiedTime
            ? `${getFriendlyTime(mapmakerLastModifiedTime.toString())}`
            : 'previously';
          const definitelyOpen = await alertConfirm(
            `This mission was opened by ${user.name} ${friendlyTime}. Are you sure you want to open it?`,
          );
          if (!definitelyOpen) {
            return 'Cancel Load';
          }
        }
      }
    }

    const currentUser = getCurrentUser();
    // filter to only unique mapmaker entries
    const newMapmakers = (mapmakers || [])
      .concat(currentUser ? currentUser.id : [])
      .filter((value, index, self) => self.indexOf(value) === index);

    await job.set('Mapmaker', newMapmakers);
    await job.sync();

    return dataSource;
  } catch (err) {
    alertWarn('Error when checking mapmaking lockout conditions');
    console.log(err);
    Sentry.captureException(err);
  }

  return 'Cancel Load';
}
