import React, { PureComponent } from 'react';

import { IconButton } from '@material-ui/core';

import DirectionsIcon from '@material-ui/icons/Directions';
import EventBus from '../../../EventBus';
import { MISSION_EVENTS } from '../../../missionEvents';

import logger from '../../../logger';
import Airtable from '../../../airtable';
import { getCurrentMission, getCurrentSession } from '../../../dataModelHelpers';
import { LatLonSource } from '../../../types/types';
import { formatDirectionsUrl } from '../../../utils';
import { alertWarnConfirm } from '../../../alertDispatcher';

interface MissionDirectionsProps {
  showNavMessage: (directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) => void;
  jobId: string;
  thisMissionIsLoaded: boolean;
}

interface MissionDirectionsState {
  directionsLink?: string;
  warning: boolean;
  noDirectionsAvailable: boolean;
  directionsSource: LatLonSource;
  jobInstanceId: number;
}

export default class MissionDirections extends PureComponent<MissionDirectionsProps, MissionDirectionsState> {
  constructor(props: MissionDirectionsProps) {
    super(props);

    this.state = {
      directionsLink: '',
      warning: false,
      noDirectionsAvailable: true,
      directionsSource: '',
      jobInstanceId: 0,
    };

    this.getDirections = this.getDirections.bind(this);
  }

  async componentDidMount() {
    await this.getDirections();
    if (!this.props.jobId) {
      EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.getDirections);
    }
  }

  componentWillUnmount() {
    if (!this.props.jobId) {
      EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.getDirections);
    }
  }

  async getDirections() {
    let directionsLink: string | undefined = '';
    let directionsSource: LatLonSource = '';
    let noDirectionsAvailable = true;
    let jobInstanceId: number = 0;

    const currentMission = getCurrentMission();
    if (this.props.jobId || currentMission) {
      const jobRecord = this.props.jobId
        ? await Airtable.getRecord('Jobs', this.props.jobId)
        : await currentMission?.get_airtable_job_record();

      if (jobRecord) {
        const session = getCurrentSession();
        jobInstanceId = jobRecord.instance_id;
        directionsSource = jobRecord.get('Lat Lon Source') as LatLonSource;
        directionsLink = await session?.getDirections(this.props.jobId);
        if (directionsLink) {
          noDirectionsAvailable = false;
        }
      }
    }

    // if we didn't get good direction information from the job, and we are using this
    // component as the "loaded mission", then try to get the currently loaded mission and
    // use it for navigation. This allows us to navigate with mission pullin lat and pullin lon
    // so we could nav with a loaded recovery file if we didn't have internet (unlikely but possible)
    if (noDirectionsAvailable && this.props.thisMissionIsLoaded) {
      const mission = getCurrentMission();

      if (mission && mission.pullin_lon && mission.pullin_lat) {
        directionsLink = formatDirectionsUrl(mission.pullin_lat, mission.pullin_lon);
        // technically, this is an automated value we use from an AirTable enum,
        // but if the mission file has a pullin point, we can set this to ensure
        // the operator reviews their navigation point
        directionsSource = 'Pullin';
      }
    }

    // TODO we also need to check the mission if we have one...
    this.setState({ directionsLink, noDirectionsAvailable, directionsSource, jobInstanceId });
  }

  render() {
    return (
      <IconButton
        disabled={!this.state.directionsLink}
        color="secondary"
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();

          if (getCurrentMission() && !this.props.thisMissionIsLoaded) {
            const shouldLoad = await alertWarnConfirm(
              'This mission is not loaded. Are you sure you want directions for this mission?',
            );
            if (!shouldLoad) {
              return;
            }
          }

          const job = await Airtable.getRecord('Jobs', this.props.jobId);
          logger.log('CLICK_DIRECTIONS', `Selected Directions: ${this.state.directionsLink}`);

          // TODO directionsLink should never be undefined here because we disable the button if it is
          this.props.showNavMessage(this.state.directionsLink!, this.state.directionsSource, job.instance_id);
        }}
      >
        <DirectionsIcon fontSize="large" />
      </IconButton>
    );
  }
}
