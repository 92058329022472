import { SampleErrorMap } from './types/types';

export const SCAN_EVENTS = {
  SCANNED_BARCODE: 'SCANNED_BARCODE',
  SCANNED_BARCODE_ALT: 'SCANNED_BARCODE_ALT',
  SCANNED_BOX: 'SCANNED_BOX',
  BACKGROUND_SCANNER_CREATE: 'BACKGROUND_SCANNER_CREATE',
  BACKGROUND_SCANNER_DESTROY: 'BACKGROUND_SCANNER_DESTROY',
  DEFAULT_SCANNER_CREATE: 'DEFAULT_SCANNER_CREATE',
  DEFAULT_SCANNER_DESTROY: 'DEFAULT_SCANNER_DESTROY',
  BARCODE_SCAN_STATE: 'BARCODE_SCAN_STATE',
};

export interface ScannedBarcodeEvent {
  barcode: string;
  currentSampleInstanceId: number;
  errMap: SampleErrorMap;
  nextSampleInstanceId: number | null;
}
