import { freezeTableInCheckpoint } from './checkpoint';
import { cloneObjectDeep } from '../utils';
import { DataObject } from './dataobject';
import { IDBPTransaction } from 'idb';

export type DataList<T extends DataObject> = Record<number, T>; // { [key: number]: T }

export const enum CHANGE_STATE {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
}

export interface TableIntegrityResult {
  table: string;
  results: {
    goodRecordIDs: number[];
    badRecordIDs: number[];
    valid: boolean;
  };
}

export interface IndexedDBInterface<T extends DataObject> {
  restoreData: (tx?: IDBPTransaction<any, string[], 'readwrite'>) => Promise<void>;
  syncData: () => Promise<number | undefined>;
  forceSyncData: () => Promise<number>;
  getAllItems: () => Promise<T[]>;
  getItem: (dataKey: number) => Promise<T | undefined>;
}

export default abstract class BaseTable<T extends DataObject> implements IndexedDBInterface<T> {
  tableName: string;
  lastDataIndex: number;
  requiresForceSync: boolean;
  dataList: DataList<T>;
  // deletedList: DataList<T>;
  defunctList: Set<number>;
  changedListKey: Record<number, CHANGE_STATE>;

  constructor(tableName: string) {
    this.tableName = tableName;
    this.lastDataIndex = 0;
    this.requiresForceSync = false; // set to true to sync everything, not just changes
    this.dataList = {}; // Use object since it give us direct access to the data from key
    // this.deletedList = {};
    this.defunctList = new Set();
    this.changedListKey = {}; // store the key of data we need to update
  }

  async dataIntegriyCheck(this: BaseTable<T>): Promise<TableIntegrityResult> {
    const allIDBItems = await this.getAllItems();
    if (allIDBItems.length === 0) {
      return {
        table: this.tableName,
        results: {
          goodRecordIDs: [],
          badRecordIDs: [],
          valid: true,
        },
      } as const;
    }

    const validateObject = (ibdObject: unknown, inMemoryItem: unknown, idbKeys: any[]) => {
      for (const key of idbKeys) {
        // @ts-ignore TODO we know this is unknown, could add validation later
        const idbObjectValue = ibdObject[key];
        // @ts-ignore TODO we know this is unknown, could add validation later
        const inMemoryItemValue = inMemoryItem[key];
        const idbObjectFalse = !idbObjectValue;
        const inMemoryItemFalse = !inMemoryItemValue;
        const bothObjectsFalse = idbObjectFalse && inMemoryItemFalse;
        // if both are object...
        if (!bothObjectsFalse && typeof idbObjectValue === 'object' && typeof inMemoryItemValue === 'object') {
          if (JSON.stringify(idbObjectValue) !== JSON.stringify(inMemoryItemValue)) {
            console.log(
              `2 Failed on key: ${key} - ${JSON.stringify(idbObjectValue)} !== ${JSON.stringify(inMemoryItemValue)}`,
            );
            return false;
          }
          continue;
        } else if (idbObjectValue !== inMemoryItemValue) {
          console.log(`3 Failed on key: ${key} - ${idbObjectValue} !== ${inMemoryItemValue}`);
          return false;
        }
      }
      return true;
    };
    // get all keys of data
    const keys = Object.keys(allIDBItems[0]);
    const goodRecordIDs: number[] = [];
    const badRecordIDs: number[] = [];
    let objectsChecked = 0;
    for (const ibdObject of allIDBItems) {
      const inMemoryItem = this.dataList[ibdObject._instance_id];
      if (!inMemoryItem) {
        continue;
      }

      if (!validateObject(ibdObject, inMemoryItem, keys)) {
        badRecordIDs.push(inMemoryItem._instance_id);
      } else {
        goodRecordIDs.push(inMemoryItem._instance_id);
      }

      objectsChecked += 1;
    }

    console.log(`Table: ${this.tableName} - Checked ${objectsChecked} objects, bad IDs: ${badRecordIDs.join(',')}`);
    return {
      table: this.tableName,
      results: {
        goodRecordIDs,
        badRecordIDs,
        valid: badRecordIDs.length === 0,
      },
    } as const;
  }

  add(this: BaseTable<T>, data: T, alreadySynced: boolean = false) {
    let index: number;
    if (data._instance_id) {
      index = data._instance_id;
    } else {
      // Append the Data to the last Index
      this.lastDataIndex += 1;
      data._instance_id = this.lastDataIndex;
      index = this.lastDataIndex;
    }
    if (!data._refs) {
      data._refs = {};
    }

    if (!data._dirty) {
      data._dirty = true;
    }

    if (!data._version) {
      data._version = 0;
    }

    if (!alreadySynced) {
      this.changedListKey[index] = CHANGE_STATE.CREATED;
    }
    this.freezeTableInCheckpoint();
    // in this case, we know that we definitely assign an _instance_id, but we just wanted to do
    // the optional typing trick to allow us to enter data without _instance_id properties

    // @ts-ignore
    if (!index || index === 'undefined') {
      console.log('add _instance_id', index);
    }

    this.dataList[index] = cloneObjectDeep(data as T);
    return this.dataList[index];
  }

  put(this: BaseTable<T>, data: T) {
    const dataKey = data._instance_id;
    // @ts-ignore
    if (!dataKey || dataKey === 'undefined') {
      console.log(`put ${this.tableName} _instance_id`, dataKey);
    }
    if (!this.defunctList.has(dataKey)) {
      // refuse to update an instance that has been previously deleted
      if (
        this.changedListKey[dataKey] !== CHANGE_STATE.CREATED &&
        this.changedListKey[dataKey] !== CHANGE_STATE.DELETED
      ) {
        this.changedListKey[dataKey] = CHANGE_STATE.UPDATED;
      }
      this.freezeTableInCheckpoint();
      this.dataList[dataKey] = cloneObjectDeep(data);
    } else {
      console.warn(`Attempting to update an instance that has been deleted: ${this.tableName} ${dataKey}`);
    }
  }

  deleteAll(this: BaseTable<T>) {
    for (const instance_id in this.dataList) {
      // parse back to int since using `in` gets strings...
      this.delete(parseInt(instance_id));
    }
  }

  delete(this: BaseTable<T>, dataKey?: number) {
    if (dataKey && !this.defunctList.has(dataKey)) {
      this.defunctList.add(dataKey);
      if (this.changedListKey[dataKey] === CHANGE_STATE.CREATED) {
        // if the instance was created in the same transaction, simply remove it
        // from the changed list
        delete this.changedListKey[dataKey];
      } else {
        // this.deletedList[dataKey] = this.dataList[dataKey];
        this.changedListKey[dataKey] = CHANGE_STATE.DELETED;
      }
      this.freezeTableInCheckpoint();
      delete this.dataList[dataKey];
    } else {
      console.warn(`Attempting to delete an instance that has been deleted: ${this.tableName} ${dataKey}`);
    }
  }

  freezeTableInCheckpoint() {
    freezeTableInCheckpoint(this.tableName, this.dataList);
  }

  getOne(this: BaseTable<T>, dataKey: number | undefined): T | undefined {
    if (dataKey && !this.defunctList.has(dataKey)) {
      return this.dataList[dataKey];
    }
    return undefined;
  }

  findOne(this: BaseTable<T>, func: (sel: T) => boolean): T | undefined {
    // console.log('findOne all data', this.tableName, this.dataList);
    return Object.values(this.dataList).find((sel: T) => !this.defunctList.has(sel._instance_id) && func(sel));
  }

  query(this: BaseTable<T>, func: (sel: T) => boolean = (_) => true): T[] | undefined {
    return Object.values(this.dataList).filter((sel: T) => !this.defunctList.has(sel._instance_id) && func(sel));
  }

  abstract restoreData(this: BaseTable<T>, tx?: IDBPTransaction<any, string[], 'readwrite'>): Promise<void>;
  abstract syncData(this: BaseTable<T>, tx?: IDBPTransaction<any, string[], 'readwrite'>): Promise<number | undefined>;
  abstract forceSyncData(this: BaseTable<T>, tx?: IDBPTransaction<any, string[], 'readwrite'>): Promise<number>;
  abstract getItem(this: BaseTable<T>, dataKey: number): Promise<T | undefined>;
  abstract getAllItems(this: BaseTable<T>): Promise<T[]>;

  needsSync(this: BaseTable<T>) {
    return this.requiresForceSync || Object.keys(this.changedListKey).length > 0;
  }

  resetSync(this: BaseTable<T>) {
    this.changedListKey = {};
  }

  resetData(dataList: DataList<T>) {
    this.dataList = dataList;
    this.defunctList = new Set();

    this.requiresForceSync = true;
  }
}
