import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import SelectedRecord, { SelectedRecordList } from './SelectedRecordClass';
import Attachment, { AttachmentList } from './AttachmentClass';

import {
  refresh,
  get,
  set,
  sync,
  refresh_attachments,
  get_attachments,
  clear_attachments,
  new_attachment,
  attachments_up_to_date,
  getRecord,
  createRecord,
  has,
} from '../db_ops/airtable_record_ops';
import { AirtableRecordFields, IAirtableRecord } from './types';
import { getField } from '@rogoag/airtable';

export default class AirtableRecord<T extends AirtableRecordFields = any>
  extends ObjectClassGenerator<AirtableRecord>('AirtableRecord')
  implements IAirtableRecord<T>
{
  // attributes
  #table: string;
  #id: string;
  // TODO what would these be? dict? object?
  #fields: T;
  #modified_fields: Set<keyof T>;
  #dirty: boolean;
  #currentRefresh: Promise<void> | undefined;
  #currentAttachmentRefresh: Promise<void> | undefined;
  #currentSync: Promise<void> | undefined;

  static tableName = 'AirtableRecord';

  // relationships
  constructor(state = {}) {
    super(state);

    // publish persistent attributes
    this.publishAttribute(AirtableRecord, 'table');
    this.publishAttribute(AirtableRecord, 'id');
    this.publishAttribute(AirtableRecord, 'fields');
    this.publishAttribute(AirtableRecord, 'modified_fields');
    this.publishAttribute(AirtableRecord, 'dirty');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<AirtableRecord> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#table = state.table || '';
    this.#id = state.id || '';
    this.#fields = state.fields || undefined;
    // @ts-ignore it thinks that the Set can be a Set of other types (which it can be) but we will ignore this for now
    this.#modified_fields =
      state.modified_fields && state.modified_fields.constructor !== Object
        ? state.modified_fields
        : new Set<keyof T>();
    this.#dirty = state.dirty || false;
    this.#currentRefresh = state.currentRefresh || undefined;
    this.#currentAttachmentRefresh = state.currentAttachmentRefresh || undefined;
    this.#currentSync = state.currentSync || undefined;
  }

  dispose() {
    for (const selectedrecord of this.getSelectedRecords()) {
      selectedrecord.AirtableRecord_id = undefined;
    }
    for (const attachment of this.getAttachments()) {
      attachment.AirtableRecord_id = undefined;
      attachment.dispose();
    }

    AirtableRecord.delete(this.instance_id);
  }

  set table(value) {
    this.#table = value;
    this.syncToDB();
  }

  get table() {
    return this.#table;
  }

  set id(value) {
    this.#id = value;
    this.syncToDB();
  }

  get id() {
    return this.#id;
  }

  set fields(value) {
    this.#fields = value;
    this.syncToDB();
  }

  get fields() {
    return this.#fields;
  }

  set modified_fields(value) {
    this.#modified_fields = value;
    this.syncToDB();
  }

  get modified_fields() {
    return this.#modified_fields;
  }

  set dirty(value) {
    this.#dirty = value;
    this.syncToDB();
  }

  get dirty() {
    return this.#dirty;
  }

  set currentRefresh(value) {
    this.#currentRefresh = value;
  }

  get currentRefresh() {
    return this.#currentRefresh;
  }

  set currentAttachmentRefresh(value) {
    this.#currentAttachmentRefresh = value;
  }

  get currentAttachmentRefresh() {
    return this.#currentAttachmentRefresh;
  }

  set currentSync(value) {
    this.#currentSync = value;
  }

  get currentSync() {
    return this.#currentSync;
  }

  getSelectedRecords() {
    if (this._refs && this._refs.SelectedRecord) {
      return new SelectedRecordList(
        ...Array.from(this._refs.SelectedRecord)
          .map((id) => SelectedRecord.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const selectedrecords = SelectedRecord.query((sel) => sel && sel.AirtableRecord_id === this.instance_id);
      for (const selectedrecord of selectedrecords) {
        selectedrecord.AirtableRecord_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SelectedRecordList(...selectedrecords);
    }
  }

  getAttachments() {
    if (this._refs && this._refs.Attachment) {
      return new AttachmentList(
        ...Array.from(this._refs.Attachment)
          .map((id) => Attachment.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const attachments = Attachment.query((sel) => sel && sel.AirtableRecord_id === this.instance_id);
      for (const attachment of attachments) {
        attachment.AirtableRecord_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new AttachmentList(...attachments);
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = AirtableRecord.query(
      (sel) => sel.instance_id !== this.instance_id && sel.id === this.id && sel.table === this.table,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate airtable record found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`airtable record: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (this.getSelectedRecords().length < 1) {
      problems.push(
        `airtable record: Could not find selected record instance across unconditional relationship R1000: ${this.instance_id}`,
      );
    }
    return problems;
  }

  async refresh() {
    await (refresh<T>).bind(this)();
  }

  has<U extends keyof T>(field: U) {
    return (has<T>).bind(this)(field);
  }

  get<U extends keyof T>(field: U) {
    return getField<T, U>({ table: this.table, id: this.id, ...this.fields }, field);
  }

  // TODO we could probably limit value a little bit, maybe to
  // string, number, string[], Attachment[], etc?
  async set(field: keyof T, value: any) {
    await (set<T>).bind(this)(field, value);
  }

  async sync() {
    await (sync<T>).bind(this)();
  }

  async refresh_attachments({ download_all = false, map_making = false } = {}) {
    await refresh_attachments.bind(this)({ download_all, map_making });
  }

  async get_attachments(this: AirtableRecord<T>, field: string): Promise<Attachment[]> {
    return await (get_attachments<T>).bind(this)(field);
  }

  async clear_attachments(field: string) {
    await clear_attachments.bind(this)(field);
  }

  async new_attachment(field: string): Promise<Attachment> {
    return await new_attachment.bind(this)(field);
  }

  hasAttachments(field: string) {
    return this.fields[field] && Array.isArray(this.fields[field]) && !!this.fields[field].length;
  }

  attachments_up_to_date() {
    return attachments_up_to_date.bind(this)();
  }

  static async getRecord<T extends AirtableRecordFields = AirtableRecordFields>(tableName: string, recordId: string) {
    return await getRecord<T>(tableName, recordId);
  }

  static createRecord(tableName: string, fields: AirtableRecordFields, recordId: string) {
    return createRecord(tableName, fields, recordId);
  }

  static async recover(allATRecords: AirtableRecord[]) {
    for (const record of allATRecords) {
      const exitingRecords = AirtableRecord.query((atRecord) => atRecord.id === record.id);
      if (exitingRecords.length) continue;

      const newRecord = AirtableRecord.create();
      for (const key of Object.keys(record).filter((key) => key === '_instance_id')) {
        newRecord[key] = record[key];
      }
    }
  }
}

export class AirtableRecordList extends DataObjectList<AirtableRecord> {
  getSelectedRecords() {
    return new SelectedRecordList(
      ...this.reduce(
        (airtablerecords: SelectedRecord[], airtablerecord) =>
          airtablerecords.concat(airtablerecord.getSelectedRecords()),
        [],
      ).filter((sel) => !!sel),
    );
  }

  getAttachments() {
    return new AttachmentList(
      ...this.reduce(
        (airtablerecords: Attachment[], airtablerecord) => airtablerecords.concat(airtablerecord.getAttachments()),
        [],
      ).filter((sel) => !!sel),
    );
  }
}
