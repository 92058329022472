import React, { PropsWithChildren, PureComponent } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import './styles/mapbutton.css';

interface MapButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown> | void;
  toggled?: boolean;
  style?: React.CSSProperties;
  id?: string;
  disabled?: boolean;
  onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltip?: string;
  processFileInput?: (data: string | ArrayBuffer) => Promise<void> | void;
}

export default class MapButton extends PureComponent<PropsWithChildren<MapButtonProps>> {
  render() {
    const { toggled, processFileInput, ...buttonProps } = this.props;
    if (processFileInput) {
      if (!!this.props.onClick) {
        // console.error("MapButton: Both onClick and processFileInput are provided, only one should be provided");
      }
      buttonProps.onClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
          const files = (e.target as HTMLInputElement)?.files;
          const file = files && files[0];
          if (file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (readerEvent) => {
              const content = readerEvent.target?.result;
              if (processFileInput && content) {
                processFileInput(content);
              }
            };
          }
        };
        input.click();
      };
    }
    return (
      <Tooltip title={this.props.tooltip || 'No tooltip available'}>
        <Button {...buttonProps} className={'mapbutton'} color={this.props.toggled ? 'primary' : 'default'}>
          {this.props.children}
        </Button>
      </Tooltip>
    );
  }
}
