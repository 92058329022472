import S3_AWS from 'aws-sdk/clients/s3';

export const DEFAULT_UPLOAD_BUCKET = 'airtableuploads';
class S3 {
  REGION: string;
  BUCKET: string;
  s3: any;

  constructor() {
    this.REGION = 'us-east-2';
    this.BUCKET = DEFAULT_UPLOAD_BUCKET;
    this.s3 = new S3_AWS({
      apiVersion: '2006-03-01',
      region: this.REGION,
      credentials: {
        accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
        secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
      },
    });
  }

  async checkIfS3FileExists(filename: string) {
    try {
      const url = this.urlFor(filename);
      const response = await fetch(url, {
        // TODO we need to confirm that this is the right behavior...
        cache: 'reload',
        method: 'HEAD',
      });
      return response.ok;
    } catch (ex) {
      return false;
    }
  }

  upload(filename: string, body: Blob, contentType: string, { bucket = this.BUCKET, acl = 'public-read' } = {}) {
    return new Promise((res, rej) => {
      const params = {
        Bucket: bucket,
        Key: filename,
        Body: body,
        ContentType: contentType,
      };

      if (acl) {
        params['ACL'] = acl;
      }

      console.log(`aws - upload:`, filename, body, contentType, acl);

      this.s3.upload(params, (err: any, data: any) => {
        if (err) {
          if (err.code.includes('RequestTimeTooSkewed')) {
            rej(
              'AWS error: Your device time seems incorrect. Please enable automatic date and time settings and try again.',
            );
          } else {
            rej(err);
          }
        }

        if (!data || !data.ETag) {
          rej('Invalid response from S3');
          // we need to return here, otherwise this function will keep executing even though the promise is rejected
          // found this via Sentry error when operator was shipping
          return;
        }
        res(data.ETag);
      });
    });
  }

  isEncoded(uri: string) {
    if (uri.includes('%')) {
      if (uri.includes(' ')) {
        return false;
      }
      return true;
    }
    return false;
    // uri = uri || '';

    // return uri !== decodeURIComponent(uri);
  }

  fullyDecodeURI(uri: string) {
    while (this.isEncoded(uri)) {
      uri = decodeURIComponent(uri);
    }

    return uri;
  }

  urlFor(filename: string) {
    if (this.isEncoded(filename)) {
      return `https://${this.BUCKET}.s3.${this.REGION}.amazonaws.com/${filename}`;
    } else {
      return `https://${this.BUCKET}.s3.${this.REGION}.amazonaws.com/${encodeURIComponent(filename)}`;
    }
  }
}

const s3 = new S3();
Object.freeze(s3);

export default s3;
