// this file has a lot of static bindings to data classes
// so TS don't know what "this" is. Need to investigate
import BaseTable from './basetable';
import { getMemoryTable, requestDBSync } from './datamanager';
import { DBPeriodicSync } from './local_storage';

type Refs = Record<string, number[]>;
export interface IDataObject {
  _instance_id?: number;
  _version?: number;
  _refs?: Refs;
  _dirty?: boolean;
}

export interface IDataManager {
  getOne(id: number): IDataObject | undefined;
  findOne(filterFunc: (sel: IDataObject) => boolean): IDataObject | undefined;
  query(filterFunc: (sel: IDataObject) => boolean): IDataObject[];
  add(data: IDataObject): void;
  put(data: IDataObject): void;
  delete(id: number): void;
}

export abstract class DataObject implements IDataObject {
  _instance_id: number;
  _version: number;
  _refs: Refs;
  _dirty: boolean;

  get instance_id() {
    return this._instance_id!;
  }

  abstract checkIntegrity(): Promise<string[]>;

  abstract initializeState<T>(this: DataObject, state: DataObject & Partial<T>);

  // abstract checksum(): boolean;

  publishAttribute<T>(cls: new () => T, attr: keyof T) {
    Object.defineProperty(this, attr, {
      enumerable: true,
      // @ts-ignore
      set: Object.getOwnPropertyDescriptor(cls.prototype, attr).set,
      // @ts-ignore
      get: Object.getOwnPropertyDescriptor(cls.prototype, attr).get,
    });
  }
}

export class DataObjectList<T extends DataObject> extends Array<T> {
  constructor(...items: T[]) {
    const m = new Map<number, T>();
    items.forEach((i) => m.set(i.instance_id, i));
    super(...m.values());
  }
}

export const ObjectClassGenerator = <T>(tableName: string) =>
  class extends DataObject {
    checkIntegrity(): Promise<string[]> {
      throw new Error('Method not implemented.');
    }

    initializeState<T>(state: Partial<T & DataObject>) {
      throw new Error('Method not implemented.');
    }

    constructor(data: Partial<T & DataObject>) {
      super();
      Object.defineProperty(this, 'checksum', {
        get: this._checksum,
      });
    }

    static instance_population: { [key: number]: T } = {}; // assure only one object reference exists for each unique instance_id

    // this to access the table for static method
    static getCurrentTable<T extends DataObject>() {
      return getMemoryTable(tableName) as BaseTable<T>;
    }

    _checksum() {
      const table = getMemoryTable(tableName);

      // @ts-ignore
      const data = table.getItem(this._instance_id);
      //write a checksum function to check if the data is correct
      for (const key in data) {
        if (data[key] !== this[key]) {
          return false;
        }
      }

      return true;
    }

    setMulti(updates: Partial<T>) {
      for (const key in updates) {
        // @ts-ignore TODO this should be technically true but doesn't seem to be always true
        this[key] = updates[key];
      }
      this.syncToDB();
    }

    syncToDB() {
      this._dirty = true;

      getMemoryTable(tableName).put(this);

      if (!DBPeriodicSync.get()) {
        requestDBSync();
      }
    }

    addRelationshipData(relationKey: string, instance_id?: number) {
      if (!this._refs) {
        this._refs = {};
      }
      if (!this._refs[relationKey]) {
        this._refs[relationKey] = [];
      }
      if (!instance_id) {
        return;
      }
      this._refs[relationKey] = Array.from(new Set(this._refs[relationKey]).add(instance_id));
      this.syncToDB();
    }

    removeRelationshipData(relationKey: string, instance_id?: number) {
      if (instance_id && this._refs && this._refs[relationKey]) {
        const refs = new Set(this._refs[relationKey]);
        refs.delete(instance_id);
        if (refs.size > 0) {
          this._refs[relationKey] = Array.from(refs);
        } else {
          delete this._refs[relationKey];
        }
        this.syncToDB();
      }
    }

    static findOne<U extends T>(filterFunc = (sel: U | undefined) => true): U | undefined {
      const table = this.getCurrentTable();
      if (!table) {
        throw new Error('Table not found');
      }

      const data = table.findOne((data) => filterFunc(this.get_instance(data)));
      if (data) {
        return this.get_instance(data);
      }
      return undefined;
    }

    static create<U extends T>(instance_id?: number) {
      // @ts-ignore
      const record = new this(instance_id ? ({ _instance_id: instance_id } as U) : {}) as U;
      // `this` is an anonymous class so we don't
      // get the benefit of the function
      this.getCurrentTable().add(record as DataObject);
      if (!DBPeriodicSync.get()) {
        requestDBSync();
      }
      return record;
    }

    static get(id: number | undefined): T | undefined {
      // This gets a DataObject from the in memory table, which should be a complete object...
      // but then we pass that data object to get_instance which will return the same object
      // but only after it call initializeState on that object... Why can't we just return
      // `data` here?
      const currentTable = this.getCurrentTable();
      if (!currentTable) {
        return undefined;
      }

      const data = currentTable.getOne(id);
      if (data) {
        return this.get_instance(data);
      }

      return undefined;
    }

    static isDataObject(data: T | DataObject): data is DataObject {
      return (data as DataObject)._instance_id !== undefined;
    }

    static get_instance<U extends T = T>(data: DataObject): U | undefined {
      const dataKey = data && data._instance_id;
      if (dataKey) {
        // get or create the instance
        let inst: DataObject | T = this.instance_population[dataKey];
        if (inst && this.isDataObject(inst)) {
          inst.initializeState(data);
        } else {
          // @ts-ignore
          inst = new this(data);
          this.instance_population[dataKey] = inst as U;
        }
        return inst as U;
      }

      return undefined;
    }

    static delete(id?: number) {
      this.getCurrentTable().delete(id);
      if (!DBPeriodicSync.get()) {
        requestDBSync();
      }
    }

    static query<U extends T>(filterFunc = (sel: U) => true): U[] {
      const table = this.getCurrentTable();
      const queryResult = table.query((data) => !!filterFunc(this.get_instance(data)!));
      if (!queryResult) {
        return [];
      }
      return queryResult.map((data) => this.get_instance(data)!);
    }
  };
