import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';

import { getCurrentMission } from '../../dataModelHelpers';
import { SetStateAsync, setStateAsync } from '../../utils';

import { alertError, cancelConfirmTag } from '../../alertDispatcher';

import { Box, Button, IconButton, List, ListItem, Modal, Typography } from '@material-ui/core';
import { AirtableRecord } from '../../db';
import EventBus from '../../EventBus';
import { PhotoCapture } from '../utils';
import logger from '../../logger';
import { IoCloseOutline } from 'react-icons/io5';
import { getWebsocketConnection, proxyGetFile, proxyGetFiles, proxyPostFile } from '../../proxyServerUtils';
import { MissionView, ScheduleItem } from '../../types/types';
import { Jobs, ShiftDropoffs } from '@rogoag/airtable';

interface SamplingHelperViewProps {
  scheduleItems: ScheduleItem[];
  missionActive: string;
  updateMissionView: (view: MissionView) => void;
}

interface SamplingHelperViewState {
  helperJobId: string;
  helperSampleId: string;
  photoModalActive: boolean;
  lastPicture: Blob | null;
  lastPicturePreviewVisible: boolean;
  loadingCapture: boolean;
  retakeOption: boolean;
  cameraError: string;
  cameraDomError: string;
  currentJobPhotos: string[];
  websocketOpen: boolean;
}

export default class SamplingHelperView extends PureComponent<SamplingHelperViewProps, SamplingHelperViewState> {
  showPDF: React.RefObject<unknown>;
  ocrRef: React.RefObject<unknown>;
  uploadBoxRef: React.RefObject<unknown>;
  setStateAsync: SetStateAsync;
  websocket: WebSocket | undefined;
  position: { x: number; y: number; z: number };
  constructor(props: SamplingHelperViewProps) {
    super(props);

    this.state = {
      helperJobId: '',
      helperSampleId: '',
      photoModalActive: false,
      lastPicture: null,
      lastPicturePreviewVisible: false,
      loadingCapture: false,
      retakeOption: false,
      cameraError: '',
      cameraDomError: '',
      currentJobPhotos: [],
      websocketOpen: false,
    };

    this.showPDF = React.createRef();
    this.ocrRef = React.createRef();
    this.uploadBoxRef = React.createRef();
    this.websocket = undefined;

    this.setStateAsync = setStateAsync.bind(this);
  }

  async componentDidMount() {
    EventBus.on('ROSMSG/current_job_sample', this.updateJobSample, true);
    EventBus.on('ROSMSG/position', this.updatePosition, true);
    this.openWebsocket();
  }

  openWebsocket = () => {
    const _openwebsocket = async () => {
      try {
        // close websocket if it's already open
        if (this.state.websocketOpen) {
          this.websocket?.close();
        }

        this.websocket = getWebsocketConnection();
        this.websocket.onopen = () => {
          console.log('WebSocket connected');
          this.setState({ websocketOpen: true });
        };
        this.websocket.onerror = (error) => {
          console.log('WebSocket error');
          console.log(error);
          this.setState({
            websocketOpen: false,
            currentJobPhotos: [],
          });
          this.openWebsocket();
        };
        this.websocket.onclose = () => {
          console.log('WebSocket disconnected');
          this.setState({
            websocketOpen: false,
            currentJobPhotos: [],
          });
        };
        this.websocket.onmessage = (event) => {
          console.log('WebSocket message received');
          const payload = JSON.parse(event.data);
          this.updateJobSample({ msg: payload.body, hostname: 'proxy' });
        };
      } catch {
        console.log('Failed to connect to WebSocket');
        this.setState({ websocketOpen: false });
        this.openWebsocket();
      }
    };

    setTimeout(() => {
      _openwebsocket();
    }, 1000);
  };

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
    EventBus.remove('ROSMSG/current_job_sample', this.updateJobSample);
    EventBus.remove('ROSMSG/position', this.updatePosition);

    this.websocket?.close();
  }

  async componentDidUpdate(
    prevProps: Readonly<SamplingHelperViewProps>,
    prevState: Readonly<SamplingHelperViewState>,
    snapshot?: any,
  ) {
    if (this.state.helperJobId !== prevState.helperJobId) {
      const photos =
        this.state.websocketOpen && this.state.helperJobId ? await proxyGetFiles(this.state.helperJobId) : [];
      this.setState({
        currentJobPhotos: photos,
      });
    }
  }

  updatePosition = ({ msg, hostname }: { msg: any; hostname: string }) => {
    this.position = { ...msg };
  };

  updateJobSample = ({ msg, hostname }: { msg: any; hostname: string }) => {
    console.log(msg);
    this.setState({
      helperJobId: msg.data.job_id,
      helperSampleId: msg.data.sample_id,
    });
  };

  getCompletedSamples(includeSkips: boolean = false) {
    let completeSamples = 0;
    const mission = getCurrentMission();
    if (mission) {
      const samples = mission.getAllSamples();
      completeSamples = samples.filter(
        (sample) => (includeSkips || !sample.skipped_or_deleted) && Boolean(sample.bag_id),
      ).length;
    }
    return completeSamples;
  }

  getTotalSamples(includeSkips: boolean = false) {
    let totalSamples = 0;
    const mission = getCurrentMission();
    if (mission) {
      const samples = mission.getAllSamples().filter((sample) => includeSkips || !sample.skipped_or_deleted);
      totalSamples = samples.length;
    }
    return totalSamples;
  }

  /**
   *
   * @param capturedImage
   */
  async handleCapture(capturedImage: Blob) {
    await logger.log('HANDLE_CAPTURE', 'Dropoff picture taken');
    // TODO send to photo upload server
    let filenameTokens = [
      this.state.helperJobId,
      this.state.helperSampleId,
      this.position?.x || 0,
      this.position?.y || 0,
      this.position?.z || 0,
      new Date().toISOString(),
    ];
    let filename = filenameTokens.join('_') + '.png';
    await proxyPostFile(capturedImage, this.state.helperJobId, filename);
    const photos = this.state.helperJobId ? await proxyGetFiles(this.state.helperJobId) : [];
    this.setState({
      lastPicture: capturedImage,
      photoModalActive: false,
      currentJobPhotos: photos,
    });
  }

  handleLoadFailure(err: unknown) {
    // DOMException: Permission denied
    // DOMException: Requested device not found
    // if (typeof err === 'object' && 'name' in err && 'message' in err) {
    //   const domException = err as DOMException;
    //   if (domException.message === 'NotAllowedError') {
    //       const errObj = { name: domException.name, message: 'Permission denied' };
    //       this.props.handleLoadFailure(errObj);
    //       return;
    //   }
    //   console.log(errObj.message);        // Permission denied, Requested device not found
    //   console.log(errObj.name);           // NotAllowedError, NotFoundError
    // }
    if (err && typeof err === 'object' && 'message' in err && 'name' in err) {
      const domError = err as DOMException;
      let error = '';
      if (domError.name === 'NotAllowedError') {
        error = "You didn't allow camera permissions.";
      } else if (domError.name === 'NotFoundError') {
        error = 'No camera was found.';
      }
      alertError(error);
      this.setState({ cameraError: error });
    } else {
      alertError('Something went wrong while loading video!');
    }

    if (this.state.photoModalActive) {
      this.setState({ loadingCapture: false, photoModalActive: false });
    }
  }

  render() {
    const wsConnected = this.state.websocketOpen;
    const connectedToMainApp = !!this.state.helperJobId;
    return (
      <React.Fragment>
        <Grid container item xs={12} spacing={1}>
          {/* center control */}
          <Box
            padding={5}
            display={'flex'}
            // justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            // width={'100%'}
            // height={'100vh'}
          >
            <Typography variant={'h4'}>Sampling Helper</Typography>
            <Typography variant={'h4'}>{`Sample ID: ${this.state.helperSampleId || 'None'}`}</Typography>
            <Typography variant={'h6'}>{`Job ID: ${this.state.helperJobId || 'None'}`}</Typography>
            <Typography variant={'h5'}></Typography>
            <Button
              variant={'outlined'}
              size={'large'}
              disabled={!wsConnected}
              style={{
                fontSize: 20,
                marginTop: 10,
                background: connectedToMainApp && wsConnected ? 'green' : wsConnected ? 'orange' : undefined,
              }}
              onClick={() => {
                this.setState({ photoModalActive: true, loadingCapture: true });
              }}
            >
              Capture Sample Photo
            </Button>
            <Button
              variant={'outlined'}
              size={'large'}
              style={{ fontSize: 20, marginTop: 10 }}
              onClick={() => {
                this.setState({ helperJobId: '', helperSampleId: '' });
                window.location.reload();
              }}
            >
              Reload
            </Button>
            {/* <Button variant={'outlined'} size={'large'} style={{ fontSize: 20, marginTop: 10 }} disabled={!this.state.lastPicture} onClick={() => {
              // show last photo
              this.setState({ lastPicturePreviewVisible: true });
            }
            }>Show Last Photo</Button> */}
            <List>
              {this.state.currentJobPhotos.map((photo, index) => {
                const [_, _root, _folderJobId, filename] = photo.split('/');
                console.log(filename);
                const [_jobId, sampleId, _x, _y, _z, _date] = filename.split('_');
                return (
                  <ListItem key={index}>
                    <Button
                      key={index}
                      variant={'outlined'}
                      size={'large'}
                      style={{ fontSize: 20, marginTop: 10 }}
                      onClick={async () => {
                        const photoBlob = await proxyGetFile(photo);
                        this.setState({
                          lastPicture: photoBlob,
                          lastPicturePreviewVisible: true,
                        });
                      }}
                    >
                      {`Show Sample ${sampleId} Picture`}
                    </Button>
                  </ListItem>
                );
              })}
            </List>

            {/* Put this at the bottom of the control */}
            <Button
              variant={'outlined'}
              size={'large'}
              fullWidth
              style={{
                marginBottom: 0,
                height: 50,
                position: 'fixed',
                bottom: 0,
                backgroundColor: 'blue',
                color: 'white',
              }}
              onClick={() => {
                this.props.updateMissionView('standard');
              }}
            >
              Standard View
            </Button>
          </Box>
        </Grid>

        {this.state.photoModalActive && (
          <Modal
            key={'modal 2'}
            style={{ height: '100vh', width: '100vw' }}
            open={true}
            className={this.state.loadingCapture ? 'hide' : ''}
          >
            <div>
              <Grid container justifyContent="center">
                <PhotoCapture
                  handleCapture={this.handleCapture.bind(this)}
                  handleLoadFailure={this.handleLoadFailure.bind(this)}
                  closeCapture={() => this.setState({ photoModalActive: false })}
                  onCanPlay={() =>
                    this.setState({
                      loadingCapture: false,
                      cameraError: '',
                      cameraDomError: '',
                    })
                  }
                />
              </Grid>
            </div>
          </Modal>
        )}

        {this.state.lastPicturePreviewVisible && (
          // display the last picture taken
          <Modal
            key={'last picture taken'}
            style={{ height: '100%' }}
            open={true}
            className={this.state.loadingCapture ? 'hide' : ''}
          >
            <div>
              {this.state.retakeOption ? (
                <>
                  <Button
                    variant={'outlined'}
                    size={'large'}
                    style={{
                      position: 'absolute',
                      fontSize: 20,
                      marginTop: 10,
                      bottom: 10,
                      left: 0,
                      width: '50%',
                      zIndex: 1600,
                      color: 'white',
                      backgroundColor: 'red',
                    }}
                    onClick={() => {
                      this.setState({
                        photoModalActive: true,
                        loadingCapture: true,
                        lastPicturePreviewVisible: false,
                        retakeOption: false,
                      });
                    }}
                  >
                    Retake Photo
                  </Button>
                  <Button
                    variant={'outlined'}
                    size={'large'}
                    style={{
                      position: 'absolute',
                      fontSize: 20,
                      marginTop: 10,
                      bottom: 10,
                      left: '50%',
                      width: '50%',
                      zIndex: 1600,
                      color: 'white',
                      backgroundColor: 'green',
                    }}
                    onClick={() => {
                      this.setState({
                        photoModalActive: false,
                        loadingCapture: false,
                        lastPicturePreviewVisible: false,
                        retakeOption: false,
                      });
                    }}
                  >
                    Save Photo
                  </Button>
                </>
              ) : (
                <IconButton
                  onClick={() => this.setState({ lastPicturePreviewVisible: false })}
                  style={{ position: 'absolute', right: 10, zIndex: 100 }}
                >
                  <IoCloseOutline size={64} fontSize={'large'} />
                </IconButton>
              )}

              {this.state.lastPicture && (
                <img
                  style={{ width: '100vw', height: '100vh', objectFit: 'fill' }}
                  src={URL.createObjectURL(this.state.lastPicture)}
                  alt={'Last Picture'}
                />
              )}
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
