import { Coordinate } from 'ol/coordinate';
import { distanceLatLon } from '../utils';
import { Mission, SoilCore } from '../db';
import { SoilCoreList } from '../db/SoilCoreClass';
import { isCloseEnoughToCoreInZoneMission } from './TargetCoordinateService';
import { CORE_STOP_MAX_DISTANCE_FT } from '../db_ops/mission_ops';

class CorePickerForZoneMissions {
  private CORE_STOP_MAX_DISTANCE_FT_EPSILON = CORE_STOP_MAX_DISTANCE_FT * 0.1;

  constructor(private mission: Mission) {}

  pick(targetPosition: Coordinate, coresSortedByDistance: SoilCoreList): SoilCore | undefined {
    const closestCore = coresSortedByDistance[0];
    if (!closestCore) {
      return undefined;
    }

    const [closeSampleToleranceMeters, presentToleranceMeters] = this.mission.get_close_present_tolerance();

    const closeCores = this.mission.getCloseCores2(targetPosition, closeSampleToleranceMeters);
    const closeEnoughToCoreInZoneMission = isCloseEnoughToCoreInZoneMission(
      targetPosition,
      closeCores,
      presentToleranceMeters,
    );

    if (!closeEnoughToCoreInZoneMission) {
      return undefined;
    }

    const allCoresAtTheStop = this.getcoresAtTheStop(closestCore, coresSortedByDistance);

    // If we have an unpulled core within the core stop, return it.
    const unpulledCore = allCoresAtTheStop.find((core) => !core.pulled);
    if (unpulledCore) {
      return unpulledCore;
    }

    // Return the core that was pulled the earliest.
    return allCoresAtTheStop.sort((c1, c2) => {
      return c1.pulled_at - c2.pulled_at;
    })[0];
  }

  private getcoresAtTheStop(firstCore: SoilCore, coresSortedByDistance: SoilCoreList): SoilCore[] {
    const alreadyAtTheStop = [firstCore];

    const isCloseToCurrentCoresAtTheStop = (currentCores: SoilCore[], coreStopCandidate: SoilCore) => {
      return currentCores.some((core) => {
        const distanceFt = distanceLatLon(core.lat, core.lon, coreStopCandidate.lat, coreStopCandidate.lon, 'Feet');

        return distanceFt <= CORE_STOP_MAX_DISTANCE_FT + this.CORE_STOP_MAX_DISTANCE_FT_EPSILON; // we're adding this epsilon because of floating point errors when those cores are placed on the map
      });
    };

    coresSortedByDistance.forEach((core) => {
      if (isCloseToCurrentCoresAtTheStop(alreadyAtTheStop, core) && !alreadyAtTheStop.includes(core)) {
        alreadyAtTheStop.push(core);
      }
    });

    return alreadyAtTheStop;
  }
}

export default CorePickerForZoneMissions;
