import React, { PureComponent } from 'react';
import SchedulingList from './scheduling/SchedulingList';

import SchedulingMap from './map/SchedulingMap';
import { convertProjection4329, isMultiPolygon } from '../utils';
import { AirtableRecord } from '../db';

import { Boundary, ScheduleMap, JobBoundsMap } from '../types/types';

import S3 from '../aws';
import { alertWarn } from '../alertDispatcher';
import { Coordinate } from 'ol/coordinate';
import { Robot, ShiftDropoffs } from '@rogoag/airtable';

interface ScheduledTaskProps {}
interface SchedulingTaskState {
  unassigned: AirtableRecord[];
  scheduled: ScheduleMap;
  robotRefreshRateSeconds: number;
  loading: boolean;
  selectedShift: string;
  hiddenScheduled: string[];
  filtered: AirtableRecord[];
  jobBounds: JobBoundsMap;
  allRobots: AirtableRecord<Robot>[];
  availableShifts: AirtableRecord[];
  shiftDropoffs: AirtableRecord<ShiftDropoffs>[];
  allDropoffs: AirtableRecord[];
  showDropoffs: boolean;
  showJobs: boolean;
  zoomField?: AirtableRecord;
}

export default class SchedulingTask extends PureComponent<ScheduledTaskProps, SchedulingTaskState> {
  constructor(props: ScheduledTaskProps) {
    super(props);

    this.state = {
      showDropoffs: false,
      showJobs: true,
      selectedShift: 'Unassigned',
      robotRefreshRateSeconds: 10,
      zoomField: undefined,
      loading: false,
      availableShifts: [],
      hiddenScheduled: [],
      allRobots: [],
      jobBounds: {},
      unassigned: new Array<AirtableRecord>(),
      shiftDropoffs: [],
      scheduled: {},
      filtered: [],
      allDropoffs: [],
    };

    this.updateScheduled = this.updateScheduled.bind(this);
    this.updateUnassigned = this.updateUnassigned.bind(this);
    this.updateDisplayFilter = this.updateDisplayFilter.bind(this);
    this.processBoundary = this.processBoundary.bind(this);
    this.processScheduling = this.processScheduling.bind(this);
    this.updateLoading = this.updateLoading.bind(this);
    this.updateAvailableShifts = this.updateAvailableShifts.bind(this);
    this.updateShiftDropoffs = this.updateShiftDropoffs.bind(this);
    this.updateAllRobots = this.updateAllRobots.bind(this);
    this.updateHiddenScheduled = this.updateHiddenScheduled.bind(this);
    this.updateAllDropoffs = this.updateAllDropoffs.bind(this);
    this.toggleShowJobs = this.toggleShowJobs.bind(this);
    this.toggleShowDropoffs = this.toggleShowDropoffs.bind(this);
    this.updateZoomField = this.updateZoomField.bind(this);
    this.updateSelectedShift = this.updateSelectedShift.bind(this);
    this.updateRobotRefreshRate = this.updateRobotRefreshRate.bind(this);
  }

  async processBoundary(job: AirtableRecord) {
    const bounds: Boundary[] = [];
    if (job.table === 'Shift Dropoffs' || job.table === 'Dropoffs') {
      return [];
    }

    if (!job.fields['Bnd GeoJSON']) {
      return [];
    }

    // TODO This is bad practice, technically we only store one file here but direct referencing is a bad idea
    // we should put a warning here for this case
    if (job.fields['Bnd GeoJSON'].length > 1) {
      alertWarn('More than one boundary file found for job: ' + job.id);
    }

    const jobAttachment = job.fields['Bnd GeoJSON'][0]; // Have to directly retrieve this because it is an attachment
    if (!jobAttachment) {
      return [];
    }

    let url: string;

    // Some attachments already have the job id part in the filename. If not, add it.
    const jobFileKey = jobAttachment.filename.includes(`${job.id}/`)
      ? jobAttachment.filename
      : `${job.id}/${jobAttachment.filename}`;

    if (await S3.checkIfS3FileExists(jobFileKey)) {
      url = S3.urlFor(jobFileKey);
    } else {
      url = jobAttachment.url;
    }

    try {
      const fetched = await fetch(url);
      const parsedShp = await fetched.json();
      if (!parsedShp) {
        return [];
      }

      for (const feature of parsedShp.features) {
        if (!feature.geometry) {
          continue;
        }

        let geom_coordinates: Coordinate[][] | Coordinate[][][] = feature.geometry.coordinates;
        if (geom_coordinates.length === 0) {
          continue;
        }

        if (isMultiPolygon(geom_coordinates, feature.geometry.type)) {
          bounds.push({
            type: 'MultiPolygon',
            coords: geom_coordinates.map((poly) =>
              poly.map((coords) => coords.map((ring) => convertProjection4329(ring))),
            ),
          });
        } else if (feature.geometry.type === 'Polygon') {
          bounds.push({
            type: 'Polygon',
            coords: geom_coordinates.map((coords) => coords.map((ring) => convertProjection4329(ring))),
          });
        }
      }
    } catch (e) {
      console.error(e);
    }

    return bounds;
  }

  async processScheduling(unassigned: AirtableRecord[], scheduled: ScheduleMap) {
    const jobBounds = {};

    await Promise.all(
      Object.keys(scheduled).map((key) =>
        scheduled[key].map(async (job) => {
          await new Promise(async (resolve, reject) => {
            resolve((jobBounds[job.id] = await this.processBoundary(job)));
          });
        }),
      ),
    );

    await Promise.all(
      unassigned.map(async (job) => {
        await new Promise(async (resolve, reject) => {
          resolve((jobBounds[job.id] = await this.processBoundary(job)));
        });
      }),
    );

    this.setState({ jobBounds });
  }

  updateLoading(loading: boolean) {
    this.setState({ loading });
  }

  updateSelectedShift(selectedShift: string) {
    this.setState({ selectedShift });
  }

  updateAvailableShifts(availableShifts) {
    this.setState({ availableShifts });
  }

  updateHiddenScheduled(hiddenScheduled: string[]) {
    this.setState({ hiddenScheduled });
  }

  updateAllRobots(allRobots) {
    this.setState({ allRobots });
  }

  updateRobotRefreshRate(robotRefreshRateSeconds: number) {
    this.setState({ robotRefreshRateSeconds });
  }

  updateZoomField(zoomField) {
    this.setState({ zoomField });
  }

  updateScheduled(scheduled: ScheduleMap) {
    this.setState({ scheduled });
  }

  updateUnassigned(unassigned: AirtableRecord[]) {
    this.setState({ unassigned });
  }

  updateDisplayFilter(filtered: AirtableRecord[]) {
    this.setState({ filtered });
  }

  updateShiftDropoffs(shiftDropoffs: AirtableRecord<ShiftDropoffs>[]) {
    this.setState({ shiftDropoffs });
  }

  updateAllDropoffs(allDropoffs) {
    this.setState({ allDropoffs });
  }

  toggleShowDropoffs() {
    this.setState({ showDropoffs: !this.state.showDropoffs });
  }

  toggleShowJobs() {
    this.setState({ showJobs: !this.state.showJobs });
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <SchedulingList
          updateAllRobots={this.updateAllRobots}
          updateRobotRefreshRate={this.updateRobotRefreshRate}
          updateZoomField={this.updateZoomField}
          updateHiddenScheduled={this.updateHiddenScheduled}
          updateLoading={this.updateLoading}
          toggleShowDropoffs={this.toggleShowDropoffs}
          updateDisplayFilter={this.updateDisplayFilter}
          updateAvailableShifts={this.updateAvailableShifts}
          updateShiftDropoffs={this.updateShiftDropoffs}
          updateAllDropoffs={this.updateAllDropoffs}
          processScheduling={this.processScheduling}
          updateUnassigned={this.updateUnassigned}
          updateScheduled={this.updateScheduled}
          toggleShowJobs={this.toggleShowJobs}
          updateSelectedShift={this.updateSelectedShift}
          loading={this.state.loading}
          hiddenScheduled={this.state.hiddenScheduled}
          allRobots={this.state.allRobots}
          selectedShift={this.state.selectedShift}
          unassigned={this.state.unassigned}
          scheduled={this.state.scheduled}
          filtered={this.state.filtered}
          shiftDropoffs={this.state.shiftDropoffs}
          availableShifts={this.state.availableShifts}
          allDropoffs={this.state.allDropoffs}
          showJobs={this.state.showJobs}
          showDropoffs={this.state.showDropoffs}
          robotRefreshRate={this.state.robotRefreshRateSeconds}
        />
        <SchedulingMap
          updateLoading={this.updateLoading}
          updateScheduled={this.updateScheduled}
          updateUnassigned={this.updateUnassigned}
          selectedShift={this.state.selectedShift}
          availableShifts={this.state.availableShifts}
          allRobots={this.state.allRobots}
          unassigned={this.state.unassigned}
          filtered={this.state.filtered}
          zoomField={this.state.zoomField}
          hiddenScheduled={this.state.hiddenScheduled}
          scheduled={this.state.scheduled}
          showJobs={this.state.showJobs}
          showDropoffs={this.state.showDropoffs}
          jobBounds={this.state.jobBounds}
          robotRefreshRate={this.state.robotRefreshRateSeconds}
        />
      </div>
    );
  }
}
