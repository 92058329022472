import { Grid, Typography } from '@material-ui/core';
import { LoadingButton } from '../utils';
import RefreshIcon from '@material-ui/icons/Refresh';

interface ShippingRefreshBoxesProps {
  loadBoxesForShipping: (forceRefresh: boolean) => Promise<void>;
  loading: boolean;
  geoErrorMessage: string;
}
export function ShippingRefreshBoxes({ loadBoxesForShipping, loading, geoErrorMessage }: ShippingRefreshBoxesProps) {
  return (
    <>
      <Grid item xs={6} sm={1}>
        <LoadingButton variant="outlined" onClick={async () => await loadBoxesForShipping(true)} loading={loading}>
          1. Refresh
          <RefreshIcon style={{ marginLeft: 7.5 }} fontSize="small" />
        </LoadingButton>
      </Grid>
      <Grid item xs={6} sm={2}>
        <Typography>{geoErrorMessage}</Typography>
      </Grid>
    </>
  );
}
