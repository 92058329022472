import React, { PureComponent } from 'react';
import MapButton from './MapButton';

import { BiUndo } from 'react-icons/bi';

interface UndoButtonProps {
  onClick: () => void;
}

export default class UndoButton extends PureComponent<UndoButtonProps> {
  render() {
    return (
      <MapButton tooltip="Undo" style={{ backgroundColor: 'yellow', width: 200 }} onClick={this.props.onClick}>
        <BiUndo size={28} />
      </MapButton>
    );
  }
}
