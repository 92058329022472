import { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';
import { Coordinate } from 'ol/coordinate';
import { Waypoint } from '../../../db';
import { convertProjection4329 } from '../../../utils';

interface LineToSampleLayerProps {
  robotPosition: Coordinate;
  sampleLocation?: Waypoint;
}

export default class LineToSampleLayer extends PureComponent<LineToSampleLayerProps> {
  render() {
    return (
      // @ts-ignore
      <layer.VectorImage zIndex={1}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          {!!this.props.sampleLocation && (
            <feature.LineStringReact
              coordinates={convertProjection4329([this.props.sampleLocation.lon, this.props.sampleLocation.lat])}
              strokeOptions={{ color: '#33274b', width: 2 }}
              zIndex={8}
            />
          )}
        </source.VectorSourceReact>
      </layer.VectorImage>
    );
  }
}
