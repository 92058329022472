import { KMLElement } from '../kml';
import { Sample, SampleBox } from '../db';
import { convertProjection4329 } from '../utils';
import { dispatchActiveBoxUpdated } from '../boxEvents';
import { KMLFeature, KMLSampleFeatureProperties } from '../types/types';
import GeometryType from 'ol/geom/GeometryType';

export function to_kml(this: Sample, { use_pulled_locations = false } = {}) {
  const sampleSite = this.getSampleSite();
  const centroid = sampleSite.getSampleCentroid();

  if (!centroid) {
    return;
  }

  const placemark = KMLElement.element('Placemark');
  const placemark_vis = KMLElement.subelement(placemark, 'visibility');
  const sampleBox = this.getSampleBox();
  placemark_vis.setText('1');
  const nm = KMLElement.subelement(placemark, 'name');
  nm.setText(this.sample_id.toString());
  const point = KMLElement.subelement(placemark, 'Point');
  const coords = KMLElement.subelement(point, 'coordinates');
  if (use_pulled_locations && this.hasCoresPulled()) {
    const calculatedCoordinates = this.calculatePulledCoresCentroid();
    coords.setText(`${calculatedCoordinates[1]},${calculatedCoordinates[0]},0`);
  } else {
    coords.setText(`${centroid.lon},${centroid.lat},0`);
  }
  coords.setText(`${centroid.lon},${centroid.lat},0`);
  placemark.putExtendedData('centroid', true);
  placemark.putExtendedData('order', this.order);
  placemark.putExtendedData('previous_order', this.previous_order);
  placemark.putExtendedData('bag_id', this.bag_id);
  placemark.putExtendedData('original_sample_id', sampleSite.original_sample_id);
  placemark.putExtendedData('external_reference_id', sampleSite.external_reference_id);
  placemark.putExtendedData('sampled_at', new Date((this.sampled_at || 0.0) * 1000).toISOString());
  placemark.putExtendedData('scanned_at', new Date((this.scanned_at || 0.0) * 1000).toISOString());
  placemark.putExtendedData('pulled_at', new Date((this.pulled_at || 0.0) * 1000).toISOString());
  placemark.putExtendedData('sample_site_source', this.sample_site_source);

  const sampling_spec = this.getSamplingSpec();
  if (!sampling_spec) {
    throw new Error(`Sample ${this.sample_id} has no sampling spec`);
  }

  placemark.putExtendedData('spec_id', sampling_spec.spec_id);
  placemark.putExtendedData('cores', sampling_spec.cores);

  if (sampleBox) {
    placemark.putExtendedData('box_uid', sampleBox.uid);
    placemark.putExtendedData('operator', sampleBox.username);
  }

  placemark.putExtendedData('pattern_type', sampling_spec.pattern_type);
  placemark.putExtendedData('radius', sampling_spec.radius);
  placemark.putExtendedData('length', sampling_spec.length);
  placemark.putExtendedData('angle', sampling_spec.angle);
  placemark.putExtendedData('start_depth', sampling_spec.start_depth);
  placemark.putExtendedData('end_depth', sampling_spec.end_depth);
  placemark.putExtendedData('change_reason', this.change_reason);
  placemark.putExtendedData('change_type', this.change_type);
  const style = KMLElement.subelement(placemark, 'styleUrl');
  style.setText('#centroid');

  return placemark;
}

export function to_feature(
  this: Sample,
  showBasedOnPulledCoreLocations: boolean = false,
): KMLFeature<KMLSampleFeatureProperties> | null {
  const sampleSite = this.getSampleSite();
  const centroid = sampleSite.getSampleCentroid();
  if (centroid) {
    const sampling_spec = this.getSamplingSpec();

    const feature: KMLFeature<KMLSampleFeatureProperties> = {
      type: 'Feature',
      geometry: {},
      properties: {
        name: this.sample_id || '',
        visibility: true,
        centroid: true,
        order: this.order,
        bag_id: this.bag_id || '',
        pulled_at: this.pulled_at,
        skipped: this.skipped_or_deleted,
        change_type: this.change_type,
        sample_site_source: this.sample_site_source,
        spec_id: sampling_spec?.spec_id || '',
        cores: sampling_spec?.cores || 0,
        pattern_type: sampling_spec?.pattern_type || 0,
        radius: sampling_spec?.radius || 0,
        length: sampling_spec?.length || 0,
        angle: sampling_spec?.angle || 0,
        start_depth: sampling_spec?.start_depth || 0,
        end_depth: sampling_spec?.end_depth || 0,
        site_order: sampleSite.sampleOrder(this),
      },
    };

    let coordinates = [centroid.lon, centroid.lat];
    if (showBasedOnPulledCoreLocations && this.hasCoresPulled()) {
      const calculatedCoordinates = this.calculatePulledCoresCentroid();
      coordinates = [calculatedCoordinates[1], calculatedCoordinates[0]];
    }

    feature.geometry = {
      type: GeometryType.POINT,
      coordinates: convertProjection4329(coordinates),
    };

    return feature;
  } else {
    return null;
  }
}

export function setBarcode(this: Sample, barcode?: string, boxInstanceId?: number) {
  this.bag_id = barcode;

  if (!barcode) {
    this.SampleBox_id = undefined;
    this.pulled_at = 0;
    this.scanned_at = 0;
  } else {
    this.SampleBox_id = boxInstanceId;
    const time = new Date().getTime() / 1000;
    const mission = this.getSampleSite().getMission();
    if (mission) {
      mission.sample_date = time;
    }
    this.pulled_at = time;
    this.scanned_at = time;
    // this.getSoilCores().forEach((core) => core.pulled_at = time);
  }

  const box = SampleBox.get(boxInstanceId);
  if (box) {
    box.needsUpdated = true;
    dispatchActiveBoxUpdated();
  }
}
