import download from 'downloadjs';
import { AirtableRecord, AirtableSearch, Attachment, Mission, SampleBox, SelectedRecord } from '../db';
import { BasicJsonRecord } from '../types/types';
import JSZip from 'jszip';
import logger from '../logger';
import EventBus from '../EventBus';
import { fileToBase64 } from '../utils';
import { getCurrentSession } from '../dataModelHelpers';
import { isRecoveryServerAlive, postRecoveryFile } from '../recoveryServerUtils';
import { alertSuccess, alertWarn, alertWarnConfirm } from '../alertDispatcher';
import { errorTone } from '../alertTones';

const BOX_DATA_SUFFIX = '_box.json';

function getTodaysBoxes(allBoxes: SampleBox[]) {
  const currentDay = new Date().toLocaleDateString();
  return allBoxes.filter((box) => box.createdTimestamp.toLocaleDateString() === currentDay);
}

/**
 *
 * @returns {string} a timestamp in the format of MMMDDhhmmAM/PM
 */
function generateTimestamp() {
  const date = new Date();
  // split off the milliseconds and add the Z back to the end to indicate UTC time
  return date.toISOString().replace(/[:-]/g, '').split('.')[0] + 'Z';

  // const month = date.toLocaleString(undefined, { month: 'long' }).substring(0, 3);
  // const day = date.toLocaleString(undefined, { day: '2-digit' });
  // let hour = date.toLocaleString(undefined, { hour: '2-digit' }).replace(' ', '');
  // hour = hour.substring(0, hour.length - 2);
  // const hourType = hour.substring(hour.length - 2, hour.length);
  // const minute = date.getMinutes().toString().padStart(2, "0");
  // const timestamp = `${month}${day}${hour}${minute}${hourType}`;
  // return timestamp;
}

/**
 *
 * @param mission The mission we want to save. If this is undefined, we will save all missions
 * @returns
 */
function generateFilename(mission: Mission | undefined = undefined, { tag = '', offlineSync = false }) {
  const tokens: string[] = [];

  if (offlineSync) tokens.push('OFFLINE');

  if (mission) {
    tokens.push(mission.name.substring(0, 30));
    tokens.push(mission.job_id || '(none)');
  } else {
    tokens.push('recovery');
  }

  if (tag) tokens.push(tag);

  tokens.push(generateTimestamp());

  return tokens.join('_');
}

/**
 * Save a recovery zip file of crucial mission and local data. This file allows a user to recover
 * mission data if something goes wrong with the app, and also will let them login and reload their
 * schedule in an offline situation. It can also be tagged as an offline sync recovery file and it
 * will then be sent by the recovery file server to the cloud for further processing.
 *
 * @param mission The mission file we want to save. This can be null and we will just save all missions
 * @param tag This is the tag that tells us how this save was initiated. This provides a quick human
 * readable way to know if this is an auto generated file or the file generated at the close of the mission
 * @param offlineSync This flag indicates whether or not this should be an offline synced file.
 * @param forceDownload This will force a "traditional" download to the browser in addition to using the
 * local file recovery server
 */
export async function saveRecoveryZip(
  mission: Mission | undefined = undefined,
  {
    tag = '',
    offlineSync = false,
    uploadToAWS = false,
    forceDownload = false,
    additionalParams = {} as BasicJsonRecord,
  },
) {
  await SampleBox.logBoxes('BOXES_BEFORE_SAVING_RECOVERY');

  const zip = new JSZip();

  const fileName = generateFilename(mission, { tag, offlineSync });

  await logger.log('SAVE_RECOVERY_ZIP', fileName);

  const missionsToSave = mission ? Mission.query((sel) => sel.job_id === mission.job_id) : Mission.query();

  const allBoxes = SampleBox.query();
  const todaysBoxes = getTodaysBoxes(allBoxes);
  // reduce all missions from boxes
  const missionsInTodaysBoxes = [
    ...new Set(todaysBoxes.map((box) => box.getSamples().getSampleSites().getMissions()).flat()),
  ];
  missionsToSave.push(...missionsInTodaysBoxes);
  const boxesToSave: SampleBox[] = [];
  if (mission) {
    // get total and filled samples for recovery server metadata
    const samples = mission.getAllSamples();
    const total = samples.length;
    const filled = samples.filter((sample) => sample.bag_id).length;
    additionalParams['total_samples'] = total;
    additionalParams['filled_samples'] = filled;
    additionalParams['job_id'] = mission.job_id || '(none)';

    // iterate through all boxes to figure out which boxes we need to save
    let missionAdded = true;
    while (missionAdded) {
      missionAdded = false;
      for (const box of allBoxes) {
        // get all missions in this box
        const boxMissions = box.getSamples().getSampleSites().getMissions();

        // if any of the missions in this box are in our list of missions to save...
        if (boxMissions.some((boxMission) => missionsToSave.includes(boxMission))) {
          // check if this box is not already included in our box list
          if (!boxesToSave.includes(box)) {
            boxesToSave.push(box);
          }

          // add any other missions from this box if it spans multiple missions
          // this solves mission recovery loading. However, this means we need to
          for (const boxMission of boxMissions) {
            if (!missionsToSave.includes(boxMission)) {
              missionsToSave.push(boxMission);
              missionAdded = true;
            }
          }
        }
      }
    }
  } else {
    // if a mission wasn't specified to save, just save all boxes
    boxesToSave.push(...allBoxes);
  }

  // save AirTable data to load schedule
  // right now, this is
  // - AirtableRecord
  // - AirtableSearch
  // - SelectedRecord
  // - Attachment
  const allATRecords = AirtableRecord.query();
  const transformedATRecords = allATRecords.map((atRecord) => {
    //const modified = atRecord.modified_fields;
    if (!atRecord.modified_fields || atRecord.modified_fields.constructor === Object) {
      atRecord.modified_fields = new Set();
    }
    atRecord['modified_fields_list'] = [...atRecord.modified_fields];
    return atRecord;
  });
  zip.file(`${fileName}_atRecords.json`, JSON.stringify(transformedATRecords));

  const allATSearch = AirtableSearch.query();
  zip.file(`${fileName}_atSearch.json`, JSON.stringify(allATSearch));

  // TODO we should ONLY get attachments that are in our schedule
  // or at least only get CONTENT for those that are in our schedule
  const selectedRecords = SelectedRecord.query();
  zip.file(`${fileName}_selectedRecord.json`, JSON.stringify(selectedRecords));

  const attachments = Attachment.query();
  const transformedAttachments = await Promise.all(
    attachments.map(async (attachment) => {
      const { content, ...newAttachment } = attachment;
      newAttachment['contentB64'] = content ? await fileToBase64(content) : null;
      return newAttachment;
    }),
  );
  zip.file(`${fileName}_attachments.json`, JSON.stringify(transformedAttachments));

  additionalParams['box_count'] = boxesToSave.length;
  const box = SampleBox.getCurrentBox();
  additionalParams['open_box'] = box?.uid || 'none';

  additionalParams['operator'] = getCurrentSession()?.username || 'none';

  if (boxesToSave.length) {
    // save boxes and add checksum
    const boxJSON = JSON.stringify(boxesToSave);
    zip.file(`${fileName}${BOX_DATA_SUFFIX}`, boxJSON);
  }

  const ebReport = EventBus.report();
  zip.file(`${fileName}_eventBus.json`, JSON.stringify(ebReport));

  zip.file(`localStorage.json`, JSON.stringify(localStorage));

  for (let mission of missionsToSave) {
    // why would we wait here? No explanation in the code
    // would be better to have speed up of saving files to avoid problems in the code
    // could have also been the issue that was experienced by ops with downloads
    // taking forever in map making if we were accidentally saving off a TON of missions
    // all at once
    //await wait(20);
    const kmlString = mission.to_kml({ insert_boxes: true })?.toString();
    if (!kmlString) {
      alertWarn("Couldn't serialize KML for mission: " + mission.name);
      continue;
    }
    zip.file(`${mission.name}.kml`, kmlString);
    //console.timeLog("Serialize KMLs", 'zip');
  }

  const user = getCurrentSession()?.getUser();

  if (user) {
    const userJSON = JSON.stringify(user);
    zip.file(`${user.name}.user.json`, userJSON);
  }

  const content = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
  let zipFilename = `${fileName}.zip`;

  let fileSent = false;
  try {
    if (!(await isRecoveryServerAlive())) {
      throw Error('Recovery server is not alive');
    }
    // TODO since we are doing a pre-flight check, we can probably rmove
    fileSent = await postRecoveryFile(content, zipFilename, {
      uploadToAWS: offlineSync || uploadToAWS,
      additionalParams,
    });
    alertSuccess('Recovery file successfully saved!');
  } catch (error) {
    await logger.log('SAVE_RECOVERY', `Unable to post to recovery server: ${error}`);
  }

  if (forceDownload || !fileSent) {
    download(content, zipFilename, 'application/zip');
  }

  if (!fileSent && offlineSync) {
    // warn the operator that we couldn't sync their file
    await errorTone();
    const result = await alertWarnConfirm(
      'PLEASE EMAIL YOUR RECOVERY FILE TO OPS. File may not be synced correctly. You may be at risk of losing CRITICAL data. Do you understand?',
    );
    await logger.log('SAVE_RECOVERY_ZIP', `User stated they did ${result ? '' : 'NOT '}understand the instructions`);
  }

  // only do the waiting if we don't have a mission
  // that means this might be a recovery file download
  // when the app is updating or being reset, and we
  // want to make sure we give time for this to complete
  // before finishing the reset

  // TODO I don't think this needs to happen at all... even in a reset situation. We just need to
  // actually await this function
  // if (!mission) {
  //     await wait(2500);  // give some time for download to execute
  // }

  await logger.log(`SAVE_RECOVERY_ZIP`, `${Date.now()} saveRecoveryZip finish`);

  await SampleBox.logBoxes('BOXES_AFTER_SAVING_RECOVERY');

  return { filename: zipFilename, content };
}
