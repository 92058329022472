import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Mission, { MissionList } from './MissionClass';
import SampleCentroid, { SampleCentroidList } from './SampleCentroidClass';
import SampleZone, { SampleZoneList } from './SampleZoneClass';
import Sample, { SampleList } from './SampleClass';
import { SampleChangeType, SampleSource } from '../types/types';
import { ISampleSite } from './types';

export default class SampleSite extends ObjectClassGenerator<SampleSite>('SampleSite') implements ISampleSite {
  // attributes
  #original_sample_id: string;
  #external_reference_id: string;
  #change_reason: string;
  #change_type: SampleChangeType;
  #sample_site_source: SampleSource;

  // relationships
  #Mission_id: number | undefined;

  static tableName = 'SampleSite';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SampleSite, 'original_sample_id');
    this.publishAttribute(SampleSite, 'external_reference_id');
    this.publishAttribute(SampleSite, 'change_reason');
    this.publishAttribute(SampleSite, 'change_type');
    this.publishAttribute(SampleSite, 'sample_site_source');
    this.publishAttribute(SampleSite, 'Mission_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SampleSite> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#original_sample_id = state.original_sample_id || '';
    this.#external_reference_id = state.external_reference_id || '';
    this.#change_reason = state.change_reason || '';
    this.#change_type = state.change_type || 'None';
    this.#sample_site_source = state.sample_site_source || 'Unknown';
    this.#Mission_id = state.Mission_id;
  }

  dispose() {
    const mission = this.getMission();

    if (mission) {
      this.Mission_id = undefined;
    }
    const samplecentroid = this.getSampleCentroid();
    if (samplecentroid) {
      samplecentroid.SampleSite_id = undefined;
      samplecentroid.dispose();
    }
    const samplezone = this.getSampleZone();
    if (samplezone) {
      samplezone.SampleSite_id = undefined;
      samplezone.dispose();
    }
    for (const sample of this.getSamples()) {
      sample.SampleSite_id = undefined;
      sample.dispose();
    }

    SampleSite.delete(this.instance_id);
  }

  set original_sample_id(value) {
    this.#original_sample_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get original_sample_id() {
    return this.#original_sample_id;
  }

  set external_reference_id(value) {
    this.#external_reference_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get external_reference_id() {
    return this.#external_reference_id;
  }

  get mission_name() {
    return this.getMission()?.name;
  }

  get last_modified() {
    return this.getMission()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getMission();
    if (inst && value) {
      inst.last_modified = value;
    }
  }

  set change_reason(value) {
    this.#change_reason = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get change_reason() {
    return this.#change_reason;
  }

  set change_type(value) {
    this.#change_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get change_type() {
    return this.#change_type;
  }

  get skipped_or_deleted() {
    return this.change_type === 'Skip' || this.change_type === 'Delete';
  }

  set sample_site_source(value) {
    this.#sample_site_source = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sample_site_source() {
    return this.#sample_site_source;
  }

  sampleOrder(sample: Sample) {
    const samples = this.getSamples().sort((a, b) => parseInt(b.sample_id) - parseInt(a.sample_id));
    return samples.findIndex((sel) => sel.sample_id === sample.sample_id);
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SampleSite', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SampleSite', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  // @ts-ignore
  getMission() {
    return Mission.get(this.Mission_id);
  }

  getSampleCentroid() {
    if (this._refs && this._refs.SampleCentroid) {
      const result = Array.from(this._refs.SampleCentroid).map((id) => SampleCentroid.get(id));
      return result[0];
    } else {
      const samplecentroid = SampleCentroid.findOne((sel) => sel?.SampleSite_id === this.instance_id);
      if (samplecentroid) {
        samplecentroid.SampleSite_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return samplecentroid;
    }
  }

  getSampleZone() {
    if (this._refs && this._refs.SampleZone) {
      const result = Array.from(this._refs.SampleZone).map((id) => SampleZone.get(id));
      return result[0];
    } else {
      const samplezone = SampleZone.findOne((sel) => sel?.SampleSite_id === this.instance_id);
      if (samplezone) {
        samplezone.SampleSite_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return samplezone;
    }
  }

  // @ts-ignore
  getSamples() {
    if (this._refs && this._refs.Sample) {
      return new SampleList(
        ...Array.from(this._refs.Sample)
          .map((id) => Sample.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const samples = Sample.query((sel) => sel && sel.SampleSite_id === this.instance_id);
      for (const sample of samples) {
        sample.SampleSite_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SampleList(...samples);
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SampleSite.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.last_modified === this.last_modified &&
        sel.original_sample_id === this.original_sample_id,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate sample site found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`sample site: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getMission()) {
      problems.push(
        `sample site: Could not find mission instance across unconditional relationship R10: ${this.instance_id}`,
      );
    }
    let found_subtype = false;
    if (this.getSampleCentroid()) {
      if (found_subtype) {
        problems.push(
          `sample site: Multiple subtype instances found across unconditional relationship R17: ${this.instance_id}`,
        );
      }
      found_subtype = true;
    }
    if (this.getSampleZone()) {
      if (found_subtype) {
        problems.push(
          `sample site: Multiple subtype instances found across unconditional relationship R17: ${this.instance_id}`,
        );
      }
      found_subtype = true;
    }
    if (!found_subtype) {
      problems.push(
        `sample site: Could not find subtype instance across unconditional relationship R17: ${this.instance_id}`,
      );
    }
    return problems;
  }

  static findByOriginalSampleID(mission: Mission, originalSampleID: string): SampleSite | undefined {
    return mission
      .getSampleSites()
      .find((sampleSite: SampleSite) => sampleSite.original_sample_id === originalSampleID);
  }

  static createWithCentroid(
    mission: Mission,
    originalSampleId: string,
    lat: number,
    lon: number,
    externalReferenceId: string,
  ) {
    const sampleSite = SampleSite.create();
    sampleSite.original_sample_id = originalSampleId;
    sampleSite.external_reference_id = externalReferenceId;
    sampleSite.Mission_id = mission.instance_id;

    const sampleCentroid = SampleCentroid.create();
    sampleCentroid.SampleSite_id = sampleSite.instance_id;
    sampleCentroid.lat = lat;
    sampleCentroid.lon = lon;

    return sampleSite;
  }
}

export interface ISampleSiteList {
  getMissions: () => MissionList;
  getSampleCentroids: () => SampleCentroidList;
  getSampleZones: () => SampleZoneList;
  getSamples: () => SampleList;
}
export class SampleSiteList extends DataObjectList<SampleSite> implements ISampleSiteList {
  getMissions() {
    return new MissionList(...this.map((samplesite) => samplesite.getMission()).filter((sel) => !!sel));
  }

  getSampleCentroids() {
    return new SampleCentroidList(...this.map((samplesite) => samplesite.getSampleCentroid()).filter((sel) => !!sel));
  }

  getSampleZones() {
    return new SampleZoneList(...this.map((samplesite) => samplesite.getSampleZone()).filter((sel) => !!sel));
  }

  getSamples() {
    return new SampleList(
      ...this.reduce((samples: Sample[], samplesite) => samples.concat(samplesite.getSamples()), []).filter(
        (sel) => !!sel,
      ),
    );
  }
}
