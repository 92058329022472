import { Component } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { PaperArea } from '../../utils';

import { StandardMissionButton, UploadKmlButton, UploadRecoveryButton } from '../buttons';
import { MissionView, OnLoading, SettingsAccessLevel } from '../../../types/types';
import { SampleBox } from '../../../db';
import { dispatchActiveBoxUpdated } from '../../../boxEvents';
import { getCurrentMission, getCurrentSession } from '../../../dataModelHelpers';

interface LoadSamplingMissionProps {
  openBoxExitInterview: (box?: SampleBox) => Promise<void>;
  onLoading: OnLoading;
  box?: SampleBox;
  accessLevel: SettingsAccessLevel;
  showRecoveryPopup: () => void;
  addNewBox: () => Promise<void>;
  updateMissionView: (view: MissionView) => void;
}

export default class LoadSamplingMission extends Component<LoadSamplingMissionProps> {
  componentDidUpdate(prevProps: Readonly<LoadSamplingMissionProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (!this.props.box && !getCurrentMission()) {
      // If the box was closed, and there is no mission loaded, then look for an open box and select it
      const openBoxes = SampleBox.getOpenBoxes();
      if (openBoxes.length > 0) {
        openBoxes[0].Session_id = getCurrentSession()?.instance_id;
        dispatchActiveBoxUpdated();
      }
    }
  }
  render() {
    return (
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <PaperArea>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            <b>Load Recovery</b>
          </Typography>
          <UploadRecoveryButton onClick={this.props.showRecoveryPopup} onLoading={this.props.onLoading} />
        </PaperArea>

        <PaperArea>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Upload Mission
          </Typography>
          <UploadKmlButton onLoading={this.props.onLoading} sampling={true} />
        </PaperArea>

        <PaperArea>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Test Grid Mission
          </Typography>
          <StandardMissionButton
            text="Load"
            filePath="./static/standard_mission.kmz"
            onLoading={this.props.onLoading}
          />
        </PaperArea>

        <PaperArea>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Test Zone Mission
          </Typography>
          <StandardMissionButton
            text="Load"
            filePath="./static/standard_zone_mission.kmz"
            onLoading={this.props.onLoading}
          />
        </PaperArea>
        <PaperArea>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Test BD Mission
          </Typography>
          <StandardMissionButton text="Load" filePath="./static/1m_bd_mission.kmz" onLoading={this.props.onLoading} />
        </PaperArea>
        <PaperArea>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Switch View
          </Typography>
          <Button variant="outlined" onClick={() => this.props.updateMissionView('helper')}>
            Helper View
          </Button>
        </PaperArea>
      </Grid>
    );
  }
}
