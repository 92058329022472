import { Button, Grid } from '@material-ui/core';
import { AiOutlineCamera } from 'react-icons/ai';
import { MdFileUpload } from 'react-icons/md';
import { LoadingButton } from '../utils';
import { alertConfirm } from '../../alertDispatcher';
import CheckIcon from '@material-ui/icons/Check';

interface Props {
  cameraError: string;
  needsScanned: boolean;
  dropoffPicture?: Blob;
  setDropoffPictureCaptureActive: () => void;
  handleCapture: (file: File) => Promise<void>;
  loading: boolean;
  loadingCapture: boolean;
}

export function ShippingDropoffPictureCapture({
  cameraError,
  needsScanned,
  dropoffPicture,
  setDropoffPictureCaptureActive,
  handleCapture,
  loading,
  loadingCapture,
}: Props) {
  return (
    <Grid item xs={6} sm={1}>
      {cameraError ? (
        <Button variant="outlined" component="label" disabled={needsScanned}>
          4. Dropoff{' '}
          {dropoffPicture ? (
            <CheckIcon style={{ marginLeft: 7.5, color: '#c1cd23' }} fontSize="small" />
          ) : cameraError ? (
            <AiOutlineCamera style={{ marginLeft: 7.5 }} />
          ) : (
            <MdFileUpload style={{ marginLeft: 7.5 }} fontSize="medium" />
          )}
          <input
            type="file"
            accept=".jpg"
            style={{ display: 'none' }}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              if (!event.target.files) {
                // TODO perhaps we should throw an error or call anyways to get it to cancel?
                return;
              }
              const file = event.target.files[0];
              await handleCapture(file);
            }}
            multiple={false}
            data-testid="dropoff-picture-upload"
          />
        </Button>
      ) : (
        <LoadingButton
          variant="outlined"
          onClick={async () => {
            // if we DON'T have a dropoff picture, we will enter the capture screen
            // if we DO have a dropoff picture, we will do an alertConfirm and wait
            // for the user to answer
            if (!dropoffPicture || (await alertConfirm('You have already taken a dropoff picture. Retake?'))) {
              setDropoffPictureCaptureActive();
            }
          }}
          disabled={loading}
          loading={loadingCapture}
        >
          4. Dropoff
          {dropoffPicture ? (
            <CheckIcon style={{ marginLeft: 7.5, color: '#c1cd23' }} fontSize="small" />
          ) : (
            <AiOutlineCamera style={{ marginLeft: 7.5 }} />
          )}
        </LoadingButton>
      )}
    </Grid>
  );
}
