import { TaskName } from '../../db/TaskClass';

export const enum UITab {
  NONE = '',
  MISSION = 'mission',
  SCHEDULE = 'schedule',
  MAP = 'map',
  BOXES = 'boxes',
  ROBOT = 'robot',
}

/**
 * Gets the available tabs for a given task
 * @param {string} task task to get available tabs for
 * @returns {array} available tasks
 */
export function getAvailableTabs(task: TaskName) {
  switch (task) {
    case TaskName.SAMPLING:
      return [UITab.MISSION, UITab.SCHEDULE, UITab.MAP, UITab.BOXES, UITab.ROBOT];
    case TaskName.MAPS:
      return [UITab.MISSION, UITab.MAP];
    case TaskName.DIRECTIONS:
      return [UITab.MISSION, UITab.SCHEDULE];
    default:
      return [];
  }
}
