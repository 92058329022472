import React from 'react';
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';

interface PDFViewerProps {
  fileUrl: string;
  downloadName: string;
  onClose: () => void;
  printURL?: string;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function PDFViewer(props: PDFViewerProps) {
  const pdfFiles = [{ source: props.fileUrl, title: props.downloadName }];
  return (
    // @ts-ignore
    <PdfMultiViewer
      pdfs={pdfFiles}
      autoZoom={true}
      controls={true}
      downloadName={props.downloadName}
      onClose={props.onClose}
      printURL={props.printURL}
      onSuccess={props.onSuccess}
      onFailure={props.onFailure}
    />
  );
}
