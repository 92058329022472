import React, { PureComponent } from 'react';
import { layer, source, feature, interaction } from 'react-openlayers';
import GeometryType from 'ol/geom/GeometryType';
import { generateColor } from '../../../utils';
import { WHITE } from '../../../rgbColors';
import { getCurrentMission } from '../../../dataModelHelpers';
import olFeature from 'ol/Feature';
import { Feature, Geometry, Properties } from '@turf/helpers';

interface OperatorScheduleLayerProps {
  loadMissionSchedule: (jobId: string) => Promise<void>;
  scheduleFeatures: Feature<Geometry>[];
  missionActive: string;
}

export default class OperatorScheduleLayer extends PureComponent<OperatorScheduleLayerProps> {
  FIELD_TRANSPARENCY = '40'; // 25% opacity

  /**
   * Handle that calls and returns output from gen function based on type.
   * @param {object} scheduleFeature GeoJSON object
   * @returns {PolygonReact | MultiPolygonReact | PointReact} The react component generated
   */
  genScheduleFeature(scheduleFeature: Feature<Geometry, Properties>, idx: number) {
    const mission = getCurrentMission();

    switch (scheduleFeature.geometry.type) {
      case GeometryType.MULTI_POLYGON:
      case GeometryType.POLYGON:
        return (
          (!mission || mission.job_id !== scheduleFeature.properties?.['Rogo Job ID']) &&
          this.genFieldBoundary(scheduleFeature, idx)
        );
      case GeometryType.POINT:
        if (scheduleFeature?.properties?.name === 'dropoff') {
          return this.genDropoffPoint(scheduleFeature);
        } else if (scheduleFeature.properties?.name === 'pullin') {
          return (
            (!mission || mission.job_id !== scheduleFeature.properties['Rogo Job ID']) &&
            this.genPullinPoint(scheduleFeature)
          );
        } else {
          return this.genInitialPoint(scheduleFeature);
        }
      default:
    }
  }

  /**
   * Generate operator schedule field boundary
   * @param {object} scheduleFeature GeoJSON object
   * @returns {MultiPolygonReact | PolygonReact} The react component generated
   */
  genFieldBoundary(scheduleFeature, idx) {
    const FieldFeature =
      scheduleFeature.geometry.type === GeometryType.MULTI_POLYGON ? feature.MultiPolygonReact : feature.PolygonReact;
    const fieldColor = generateColor(scheduleFeature.properties['Field from Form']);

    return (
      <FieldFeature
        coordinates={scheduleFeature.geometry.coordinates}
        strokeOptions={{ color: fieldColor, width: 4 }}
        fillOptions={{ color: `${fieldColor}${this.FIELD_TRANSPARENCY}` }}
        // @ts-ignore
        textOptions={{
          text: scheduleFeature.properties['Field from Form'],
          font: '24px Calibri,sans-serif',
          overflow: true,
        }}
        hideTextZoom={12}
        key={`${scheduleFeature.properties['Field from Form']}-Field${idx}`}
        properties={scheduleFeature.properties}
      />
    );
  }

  /**
   * Generate operator schedule dropoff point
   * @param {object} scheduleFeature GeoJSON object
   * @returns {PointReact} The react component generated
   */
  genDropoffPoint(scheduleFeature) {
    const dropoffColor = generateColor(scheduleFeature.properties['Dropoff Location']);
    return (
      <feature.PointReact
        coordinate={scheduleFeature.geometry.coordinates}
        circleOptions={{ radius: 6, fillOptions: { color: dropoffColor } }}
        key={`${scheduleFeature.properties['Dropoff Location']}-Dropoff`}
      />
    );
  }

  /**
   * Generate the initial point for the operator schedule field
   * @param {object} scheduleFeature GeoJSON object
   * @returns {PointReact} The react component generated
   */
  genInitialPoint(scheduleFeature) {}

  /**
   * Generate the pullin point for the operator schedule field
   * @param {object} scheduleFeature GeoJSON object
   * @returns {PointReact} The react component generated
   */
  genPullinPoint(scheduleFeature) {
    const pullinColor = generateColor(scheduleFeature.properties['Field from Form']);
    return (
      <feature.PointReact
        coordinate={scheduleFeature.geometry.coordinates}
        circleOptions={{ radius: 6, fillOptions: { color: pullinColor }, strokeOptions: { width: 1, color: WHITE } }}
        key={`${scheduleFeature.properties['Field from Form']}-Pullin`}
      />
    );
  }

  /**
   * Select handle that allows user to select a field to load from the map
   * @param {*} event
   */
  async handleSelectFeature(feature: olFeature) {
    console.log('feat', feature);
    if (feature) {
      const properties = feature.getProperties();
      console.log('PROPERTIES FIELD', properties.name);
      if (properties.name === 'field') {
        await this.props.loadMissionSchedule(properties['Rogo Job ID']);
      }
    }

    return false;
  }

  render() {
    return (
      // @ts-ignore
      <layer.VectorImage zIndex={1}>
        {/*@ts-ignore */}
        <source.VectorSourceReact>
          {this.props.scheduleFeatures.map((feature, idx) => this.genScheduleFeature(feature, idx))}
          {!Boolean(this.props.missionActive) && (
            <interaction.SelectReact
              // @ts-ignore
              filter={this.handleSelectFeature.bind(this)}
              style={null}
            />
          )}
        </source.VectorSourceReact>
      </layer.VectorImage>
    );
  }
}
