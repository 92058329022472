import React, { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';
import { toLonLat } from 'ol/proj';
import { addAlpha, convertProjection4329, generateColor } from '../../../utils';
import { LineString, Point, Polygon } from 'ol/geom';

export type GridLayerFeature = Point | Polygon | LineString;

interface GridLayerProps {
  gridLayerFeatures: GridLayerFeature[];
}

export default class GridLayer extends PureComponent<GridLayerProps> {
  /**
   *
   * @param {Point} layerFeature The feature used to generate the react component
   * @param {number} idx Index used to id the point
   * @returns {PointReact} The grid point component to show
   */
  genGridFeature(layerFeature: GridLayerFeature, idx: number) {
    const coordinates = layerFeature.getCoordinates();

    const properties = layerFeature.getProperties();
    const id = 'id' in properties ? properties.id : idx;

    if (layerFeature instanceof Polygon) {
      const x = properties.x * 1000;
      const y = properties.y;
      return (
        <feature.PolygonReact
          key={id}
          // @ts-ignore
          coordinates={coordinates} //coordinates.map(convertProjection3857).map(convertProjection4329)}
          fillOptions={{
            // make a random fill color with 0.1 opacity
            color: addAlpha(generateColor((x + y).toString()), 0.3),
            // color: 'valid' in properties && properties.valid ? addAlpha(generateColor((x + y).toString()), 0.5) : 'rgba(0, 0, 0, 0.0)'
          }}
          strokeOptions={{
            color: 'valid' in properties && !properties.valid ? 'red' : 'rgba(0, 0, 0, 0.0)',
            width: 'valid' in properties && !properties.valid ? 3 : 0,
          }}
        />
      );
    } else if (layerFeature instanceof LineString) {
      return (
        <feature.LineStringReact
          key={id}
          // @ts-ignore
          coordinates={coordinates}
          strokeOptions={{
            color: 'rgba(0, 0, 0, 0.5)',
            width: 3,
            lineDash: [10, 10],
          }}
          textOptions={{
            text: properties.id.toString(),
            rotation: properties.rotation || 0,
            font: 'Bold 24px Calibri',
            fillOptions: { color: 'yellow' },
            offsetY: properties.offsetY,
            offsetX: properties.offsetX,
          }}
        />
      );
    }

    const layerCoordinates = convertProjection4329(toLonLat(layerFeature.getCoordinates()));

    return (
      <feature.PointReact
        key={id}
        coordinate={layerCoordinates}
        circleOptions={{
          strokeOptions: { color: 'black', width: 1 },
          fillOptions: { color: 'white' },
          radius: 6,
        }}
        textOptions={{
          text: id.toString(),
          font: 'Bold 18px Calibri',
          fillOptions: { color: 'black' },
          offsetX: 15,
        }}
      />
    );
  }

  render() {
    return (
      // @ts-ignore
      <layer.Vector zIndex={1}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          {/* // add 1 to the index so that our grid generation number matches what is ultimately applied */}
          {this.props.gridLayerFeatures.map((layerFeature, idx) => this.genGridFeature(layerFeature, idx + 1))}
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
