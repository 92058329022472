// @ts-strict-ignore
import { PureComponent } from 'react';
import '../css/missioncontrol.css';
import { withStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';

import Dashboard from './Dashboard';
import ErrorBoundary from './utils/ErrorBoundary';
import LoadingScreen from './utils/LoadingScreen';
import MenuDrawer from './MenuDrawer';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

import registerClasses from '../db';
import { initDatabase, loadAllTables, loadPEI } from '../db';
import { alertConfirm, alertError, alertInfo, alertSuccess, cancelConfirmTag } from '../alertDispatcher';
import PEI from '../PEI.json';
import { getCurrentSession, checkAllEngineeringPasswords, checkAllTuningPasswords } from '../dataModelHelpers';
import { AES } from 'crypto-js';
import { AccessLevelStore, SetStateAsync, clearTimer, isLocalhost, setStateAsync, wait } from '../utils';
import { setLocalStorageExpiring } from '../db/local_storage';
import { AppBar, Portal } from '@material-ui/core';
import logger from '../logger';
import * as buffer from 'buffer';
import { RogoTheme, SettingsAccessLevel, ValidatePasswordFunction } from '../types/types';

import { currentUserIsAdmin } from '../db_ops/session_ops';
import { ClassKeyOfStyles, ClassNameMap } from '@material-ui/styles/withStyles/withStyles';
import { z } from 'zod';
import { _MS_PER_DAY } from '../constants';
import { isMobile } from 'react-device-detect';
import { saveRecoveryZip } from '../services/RecoveryZipService';
import getVersion from '../version';

// regex for AWS keys pulled from https://aws.amazon.com/blogs/security/a-safer-way-to-distribute-aws-credentials-to-ec2/
const envVariables = z.object({
  // TODO apparently Safari has a problem with lookbehind regexes which causes the app not to load
  // in iOS. The currently used regex without lookbehind was generated by an LLM
  // .regex(/(?<![A-Z0-9])[A-Z0-9]{20}(?![A-Z0-9])/),
  VITE_AWS_ACCESS_KEY_ID: z.string().regex(/(?:^|[^\w])([A-Z0-9]{20})(?:$|[^\w])/),
  //.regex(/(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/),
  VITE_AWS_SECRET_ACCESS_KEY: z.string().regex(/(?:^|[^A-Za-z0-9/+=])([A-Za-z0-9/+=]{40})(?:$|[^A-Za-z0-9/+=])/),
  // could just do z.string().startsWitb('pat'),
  VITE_AIRTABLE_API_KEY: z.string().regex(/pat[A-Za-z0-9]{14}\.[a-f0-9]{64}/),
  // could just do z.string().startsWitb('app'),
  VITE_AIRTABLE_BASE_ID: z.string().regex(/app[A-Za-z0-9]{14}/),
  VITE_EVENT_LOGGING: z.enum(['on', 'off']),
  VITE_SENTRY_DSN: z.string().startsWith('https://'),
  VITE_USE_RECOVERY_SERVER: z.enum(['true', 'false']),
});

envVariables.parse(import.meta.env);

declare global {
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof envVariables> {}
  }
}

const rogoTheme = createTheme({
  palette: {
    primary: {
      main: '#b5734e',
    },
    secondary: {
      main: '#274052',
    },
    error: {
      main: '#cc0000',
    },
  },
});

const rogoDarkTheme = createTheme({
  palette: {
    primary: {
      main: '#a5562b',
    },
    secondary: {
      main: '#829AB1',
    },
    type: 'dark',
  },
});

const styles = {
  root: {
    margin: 0,
    width: '100%',
  },
};

interface AppProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
}

export type LoadingAction = {
  action: (updater: (progress: number) => void) => Promise<any>;
  monitorProgress: boolean;
  onSuccess: (result: any) => void;
  onError: (err: Error) => void;
};

interface AppState {
  appBarHidden: boolean;
  buttonsRef: HTMLDivElement | undefined;
  camPermission: boolean;
  cipherString: string;
  dbInitialized: boolean;
  devMode: boolean;
  accessLevel: SettingsAccessLevel;
  loadingAction: LoadingAction | undefined;
  locPermission: boolean;
  offlineCount: number;
  sideBarText: string;
  sidebarToggleFunction: () => void;
  taskRef: HTMLDivElement | undefined;
  theme: RogoTheme;
  showHelpLinksPopup: boolean;
  passwordPromptOpen: boolean;
  validatePassword?: ValidatePasswordFunction;
  passwordLength: number;
  onPasswordClose?: (result: SettingsAccessLevel) => void;
}

const NEW_AUTHENTICATION_SYSTEM = true;

class App extends PureComponent<AppProps, AppState> {
  POLL_ONLINE = 15000;
  MAX_POLL_TIMEOUT = 10000;
  MAX_OFFLINE_COUNT = 10;
  pollConnectionTimeout: NodeJS.Timeout | undefined;
  updateInterval: NodeJS.Timer | undefined;
  loading: boolean;
  setStateAsync: SetStateAsync;
  setRecoveryDownloadFunction: any;
  disableSentry: boolean = false;

  constructor(props: AppProps) {
    super(props);
    const accessLevel = this.getAccessLevel();
    this.state = {
      appBarHidden: false,
      buttonsRef: undefined,
      camPermission: false,
      cipherString: '',
      dbInitialized: false,
      devMode: accessLevel === 'Technician',
      loadingAction: undefined,
      locPermission: false,
      offlineCount: 0,
      sideBarText: '',
      sidebarToggleFunction: () => {},
      taskRef: undefined,
      theme: (localStorage.getItem('theme') as RogoTheme) || 'light',
      showHelpLinksPopup: false,
      passwordPromptOpen: false,
      passwordLength: 0,
      validatePassword: undefined,
      onPasswordClose: undefined,
      accessLevel: accessLevel,
    };
    //this.pollConnectionTimeout = undefined;
    this.updateInterval = undefined;
    this.loading = false;
    this.startUpdateLoop = this.startUpdateLoop.bind(this);
    this.onLoading = this.onLoading.bind(this);
    this.updateApp = this.updateApp.bind(this);
    this.checkForUpdates = this.checkForUpdates.bind(this);
    this.generateUserLoginQr = this.generateUserLoginQr.bind(this);
    this.updateTaskRef = this.updateTaskRef.bind(this);
    this.updateAdditionalButtonsRef = this.updateAdditionalButtonsRef.bind(this);
    this.initRobotName = this.initRobotName.bind(this);
    this.setStateAsync = setStateAsync.bind(this);
    this.elevatePermissions = this.elevatePermissions.bind(this);
    window.Buffer = buffer.Buffer;

    // if we find 'localhost' in the window hostname, don't init sentry
    this.disableSentry = isLocalhost;
  }

  async updateApp(sw: ServiceWorker) {
    // notify of update
    const confirm = await alertConfirm(
      'A newer version is available. Would you like to update?',
      this.constructor.name,
    );

    if (confirm) {
      if (!isMobile) {
        await saveRecoveryZip(undefined, { tag: 'update' });
      }
      // Service Worker-based solution
      sw.postMessage({ type: 'SKIP_WAITING' });
      // TODO sleep here to wait for message deliver
      await wait(500);
      window.location.reload();
    }
  }

  async checkForUpdates() {
    if (navigator.serviceWorker) {
      await this.onLoading(async () => {
        // update service worker
        navigator.serviceWorker.ready.then(async (registration) => {
          if (registration.waiting) {
            await this.updateApp(registration.waiting);
          } else {
            await registration.update();
          }
        });
        // give the user the feeling that theyre doing something ;)
        await new Promise((r) => setTimeout(r, 250));
      });
    }
  }

  startUpdateLoop(registration: ServiceWorkerRegistration) {
    if (!this.updateInterval) {
      this.updateInterval = setInterval(
        () => {
          if (registration.waiting) {
            this.updateApp(registration.waiting);
          } else {
            registration.update();
          }
        },
        10 * 60 * 1000,
      ); // check for update every 10 minutes
    }
  }

  // initialize application database
  async initializeData() {
    registerClasses();
    let dataBackup: any;

    await this.onLoading(async () => {
      await logger.log('Initializing database');

      const t1 = performance.now();
      initDatabase();
      const t2 = performance.now();
      await logger.log(`Database initalized ${t2 - t1} ms`);

      dataBackup = await loadPEI(PEI);
      if (!dataBackup) {
        await logger.log('LOAD_PEI - No dataBackup returned');

        return;
      }

      const t3 = performance.now();
      await logger.log(`Loaded PEI in ${t3 - t2} ms`);

      await loadAllTables();
      const t4 = performance.now();
      await logger.log(`Loaded table data in ${t4 - t3} ms`);
    });

    if (dataBackup) {
      this.setState({ dbInitialized: true });
      this.initRobotName();
    }
  }

  updateTaskRef(taskRef?: HTMLDivElement) {
    this.setState({ taskRef });
  }

  updateAdditionalButtonsRef(buttonsRef: HTMLDivElement) {
    this.setState({ buttonsRef });
  }

  /*
   * This function absracts the process of waiting for something to load. It
   * takes an async action function or promise as an argument and returns a
   * `Promise` which resolves or rejects with the results of the action
   * function.  If monitor progress is `true`, the action function will be
   * passed a function that takes one integer argument from 0-100. The value
   * will be used to determine the current progress of the operation. In this
   * way, long running processes can report progress to the user while running.
   */
  onLoading<T>(action: () => Promise<T>, monitorProgress = false): Promise<T> {
    if (!this.loading) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.setState({
          loadingAction: {
            // if a promise, convert to a function
            action,
            monitorProgress,
            onSuccess: (result) => {
              this.loading = false;
              resolve(result);
            },
            onError: (err) => {
              this.loading = false;
              reject(err);
            },
          },
        });
      });
    } else {
      return Promise.reject(new Error('Cannot start loading while already loading'));
    }
  }

  async initSentry() {
    if (this.disableSentry) {
      return;
    }

    // init sentry
    Sentry.init({
      release: getVersion(),
      dsn: import.meta.env.VITE_SENTRY_DSN,
      normalizeDepth: 10, // for sending Redux states
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [
            'https://.*.s3.amazonaws.com/', // all S3 requests
          ],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header'],
        }),
        // Disabling this for now because we are getting the following error:
        // Uncaught SecurityError: Failed to execute 'getImageData' on 'CanvasRenderingContext2D': The canvas has been tainted by cross-origin data
        // Sentry.replayCanvasIntegration(),
      ],
      ignoreErrors: [
        /AbortError: AbortError/,
        /WebSocket connection/,
        /AxiosError: Network Error/,
        /AxiosError: timeout exceeded/,
        /Failed to fetch/,
      ],
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });

    //const origError = console.error;
    // console.error = function (...args) {

    //   if (args.length === 0) { throw new Error('Error while handling console.error()... at least one argument must be provided.') }

    //   let error: Error;
    //   let extras = {};

    //   // TODO need to review this, this was already here...
    //   if (args.length === 1 && args[0] instanceof Error) {
    //     // only an error was provided (most common)
    //     error = args[0];
    //   } else if (args.length === 2 && typeof args[0] === 'string' && args[1] instanceof Error) {
    //     // a comment was made on what caused the error (example: console.error('Could not refresh airtable jobs.', err);)
    //     error = args[1];
    //     error.message = `${args[0]} :: ${args[1].message}`;
    //   } else if (typeof args[0] === 'string') {
    //     // example: console.error('Could not export Shapefile', shp1, shp2, shp3);
    //     error = new Error(args[0]);
    //   } else {
    //     // if these are seen on Sentry, we should attempt to change it to one of the formats above
    //     error = new Error('Ill defined console.error message.');
    //   }

    //   for (const [i, arg] of args.entries()) {
    //     extras[`arg${i}`] = arg;
    //     if (arg instanceof Object) {
    //       // nested objects aren't unpacked, so try to stringify it
    //       try {
    //         extras[`arg${i}`] = JSON.stringify(arg);
    //       } catch (e) { }
    //     }
    //   }

    //   // Log console.error to sentry. Mark these so that we can rewrite to try/catch in the future.
    //   Sentry.withScope(scope => {
    //     scope.setContext('console.error arguments', extras);
    //     Sentry.captureException(error, {
    //       tags: {
    //         'from': 'console',
    //       },
    //     });
    //   });

    //   return origError(...args);
    // }
  }

  async initRobotName() {
    // clear robot from session
    const session = getCurrentSession();
    logger.setRobot();
    if (session) {
      session.robot_name = '';
    }
  }

  componentDidUpdate(_prevProps: AppProps, prevState: AppState) {
    localStorage.setItem('theme', this.state.theme);
    // TODO DEVMODE restriction
    // const devModeActive = !getLocalStorageDateExpired('devMode');
    // if (devModeActive !== this.state.devMode) {
    //   this.setState({ devMode: devModeActive });
    // }

    // we've been offline for too long
    if (prevState.offlineCount === this.MAX_OFFLINE_COUNT - 1 && this.state.offlineCount === this.MAX_OFFLINE_COUNT) {
      // alertWarn('Internet Offline');
    }
    // we've come back online
    else if (prevState.offlineCount >= this.MAX_OFFLINE_COUNT && this.state.offlineCount === 0) {
      // alertSuccess('Internet Online');
    }
  }

  async componentDidMount() {
    // start update loop if service worker already registered
    if (navigator.serviceWorker) {
      // we specifically don't await this... it must not work normally because it holds up the whole app
      navigator.serviceWorker.ready.then(this.startUpdateLoop);
    }

    // initialize Sentry logging
    try {
      await this.initSentry();
    } catch (err) {
      await logger.log('SENTRY_INIT_ERROR', { err });
    }

    // initialize the DB
    try {
      await this.initializeData();
    } catch (err) {
      Sentry.captureException(err);
      logger.log('DB_INIT_ERROR', { err });

      // TODO We really can't just continue on when this happens, but this really is also a show stopper
      // if we can't initalize the data, so we should really re-throw
      throw err;
    }
  }

  componentWillUnmount() {
    // cancel update timer
    clearTimer(this.updateInterval);
    clearTimeout(this.pollConnectionTimeout);
    cancelConfirmTag(this.constructor.name);
  }

  async generateUserLoginQr() {
    try {
      const session = getCurrentSession();
      if (!session) throw Error('Session undefined');
      const user = session.getUser();
      if (!user) throw Error('Userr undefined');
      const loginString = `${user.name}|${user.hashed_password}`;
      const aesPass = import.meta.env.VITE_AES_PASSPHRASE;
      const cipherString = AES.encrypt(loginString, aesPass).toString();
      await this.setStateAsync({ cipherString });
      const canvas = document.getElementById('loginQr') as HTMLCanvasElement;
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${user.airtable_name || user.name}QrLogin.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.error(err);
      alertError('Failed to download QR code');
    }
  }

  /**
   *
   * @returns {SettingsAccessLevel} Get the access level based on the local storage saved values
   */
  getAccessLevel() {
    const { expires, accessLevel } = AccessLevelStore.get();

    if (expires < Date.now()) {
      AccessLevelStore.reset();
      return AccessLevelStore.get().accessLevel;
    }

    return accessLevel;
  }

  alertAccessLevelChanged(accessLevel: SettingsAccessLevel) {
    if (accessLevel === 'Technician' || accessLevel === 'Operator Tuning') {
      alertSuccess(`Logged in as ${accessLevel}`);
    } else {
      alertInfo(`Access level now set to Locked`);
    }
  }

  async elevatePermissions() {
    // Case 0: BYPASS
    // this will be removed once we implement full protection settings
    if (!NEW_AUTHENTICATION_SYSTEM) {
      let accessLevel = this.state.accessLevel;
      if (accessLevel === 'Operator Tuning') {
        accessLevel = 'Technician';
      } else {
        accessLevel = 'Operator Tuning';
      }

      AccessLevelStore.set({
        expires: Date.now() + 365 * 24 * 60 * 60 * 1000,
        accessLevel,
      });

      this.alertAccessLevelChanged(accessLevel);
      this.setState({ accessLevel });
      return;
    }

    // Case 1: if admin, toggle on/off tech mode like normal
    if (currentUserIsAdmin(getCurrentSession())) {
      const accessLevel = this.state.accessLevel === 'Technician' ? 'Locked' : 'Technician';

      this.alertAccessLevelChanged(accessLevel);
      this.setState({
        devMode: !this.state.devMode,
        accessLevel,
      });
      AccessLevelStore.set({
        expires: Date.now() + 365 * 24 * 60 * 60 * 1000,
        accessLevel,
      });
      // localStorage.setItem('accessLevel', JSON.stringify({
      //   expires: Date.now() + 12*60*60*1000,
      //   accessLevel
      // }));
      return;
    }

    // Case 2: if permissions are elevated, just lower to Locked
    // TODO for password feature
    if (this.state.accessLevel !== 'Locked') {
      alertInfo(`Access level now set to Locked`);

      this.alertAccessLevelChanged('Locked');
      this.setState({ accessLevel: 'Locked' });
      // localStorage.setItem('accessLevel', JSON.stringify({
      //   expires: Date.now() + 12*60*60*1000,
      //   accessLevel: 'Locked'
      // }))
      AccessLevelStore.set({
        expires: Date.now() + 365 * 24 * 60 * 60 * 1000,
        accessLevel: 'Locked',
      });
      return;
    }

    // Case 3: if permissions are locked, show password prompt
    // show password prompt
    this.setState({
      onPasswordClose: (result) => {
        this.alertAccessLevelChanged(result);
        this.setState({
          devMode: result === 'Technician',
          accessLevel: result,
          onPasswordClose: undefined,
          passwordPromptOpen: false,
        });
        if (result === 'Technician') {
          setLocalStorageExpiring('devMode', _MS_PER_DAY, 'start_of_day');
        }

        AccessLevelStore.set({
          expires: Date.now() + _MS_PER_DAY,
          accessLevel: result,
        });
      },
      passwordPromptOpen: true,
      validatePassword: async (password): Promise<SettingsAccessLevel> => {
        if (await checkAllTuningPasswords(password)) {
          return 'Operator Tuning';
        } else if (await checkAllEngineeringPasswords(password)) {
          return 'Technician';
        }

        return this.state.accessLevel;
      },
      passwordLength: 4,
    });
  }

  render() {
    return (
      <ThemeProvider theme={'dark' === this.state.theme ? rogoDarkTheme : rogoTheme}>
        <CssBaseline />
        <Container className="rootcontainer" disableGutters style={{ maxWidth: '100vw' }}>
          <Grid container spacing={1} justifyContent="center" classes={{ root: this.props.classes.root }}>
            <Grid container item xs={12} spacing={1} style={{ flex: 1, display: 'flex' }}>
              <Portal
                container={document.getElementById(
                  this.state.dbInitialized ? 'menudrawer-container-1' : 'menudrawer-container-2',
                )}
              >
                <MenuDrawer
                  sideBarText={this.state.sideBarText}
                  darkMode={this.state.theme === 'dark'}
                  toggleTheme={() => {
                    this.setState({ theme: this.state.theme === 'dark' ? 'light' : 'dark' });
                  }}
                  devMode={this.state.accessLevel === 'Technician'}
                  elevatePermissions={this.elevatePermissions}
                  checkForUpdates={this.checkForUpdates}
                  onLoading={this.onLoading}
                  setToggleFunction={(fn) => this.setState({ sidebarToggleFunction: fn })}
                  generateUserLoginQr={this.generateUserLoginQr}
                  updateTaskRef={this.updateTaskRef}
                  updateAdditionalButtonsRef={this.updateAdditionalButtonsRef}
                  accessLevel={this.state.accessLevel}
                />
              </Portal>

              {!this.state.dbInitialized && (
                <AppBar position="static" color="default" id="menudrawer-container-2"></AppBar>
              )}

              <div id="activeAlerts" style={{ position: 'absolute', display: 'block', overflow: 'auto' }}></div>

              {this.state.dbInitialized ? (
                <ErrorBoundary>
                  <Dashboard
                    onLoading={this.onLoading}
                    devMode={this.state.devMode}
                    // TODO these props werent used in Dashboard...
                    // locPermission={this.state.locPermission}
                    // darkMode={!(this.state.theme === 'light')}
                    // setRecoveryDownloadFunction={this.setRecoveryDownloadFunction}
                    passwordPromptOpen={this.state.passwordPromptOpen}
                    validatePassword={this.state.validatePassword}
                    passwordLength={this.state.passwordLength}
                    onPasswordClose={this.state.onPasswordClose}
                    setSideBarText={(t: string) => {
                      this.setState({ sideBarText: t ? t.toString() : 'undefined' });
                    }}
                    hideAppBar={(h: boolean) => {
                      this.setState({ appBarHidden: h });
                    }}
                    appBarHidden={this.state.appBarHidden}
                    sidebarToggleFunction={this.state.sidebarToggleFunction}
                    taskRef={this.state.taskRef}
                    buttonsRef={this.state.buttonsRef}
                    disableSentry={this.disableSentry}
                    elevatePermissions={this.elevatePermissions}
                    accessLevel={this.state.accessLevel}
                  />
                </ErrorBoundary>
              ) : null}
            </Grid>
          </Grid>

          {/* loading screen */}
          <LoadingScreen action={this.state.loadingAction} />

          {/* 
          // We will disable this for now since it isn't used */}
          {/* {this.state.cipherString !== null && (
            <div style={{position: 'absolute'}} className={'hide'}>
              <QRCode
                id='loginQr'
                value={this.state.cipherString}
                size={100}
              />
            </div>
          )} */}
        </Container>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
