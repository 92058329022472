import { PropsWithChildren, PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingButtonProps {
  color?: 'secondary' | 'primary' | 'default';
  disabled?: boolean;
  loading?: boolean;
  onClick: () => Promise<void> | void;
  size?: 'small' | 'medium' | 'large';
  style?: any;
  variant?: 'text' | 'outlined' | 'contained';
}

interface LoadingButtonState {
  loading: boolean;
}

export default class LoadingButton extends PureComponent<PropsWithChildren<LoadingButtonProps>, LoadingButtonState> {
  mounted: boolean;

  constructor(props: LoadingButtonProps) {
    super(props);
    this.state = {
      loading: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onClick(event: React.SyntheticEvent) {
    event.stopPropagation();
    this.setState({ loading: true }, async () => {
      try {
        await this.props.onClick();
      } catch (e) {
        // https://stackoverflow.com/questions/42754270/re-throwing-exception-in-nodejs-and-not-losing-stack-trace
        const stack = new Error('Errors should be caught in the click handler, not propagated to the button.').stack;
        if (!!e && typeof e === 'object' && 'stack' in e && typeof e.stack === 'string') {
          e.stack = e.stack + '\nFrom previous: ' + stack?.split('\n').slice(0, 2).join('\n') + '\n';
        }
        throw e;
      } finally {
        if (this.mounted) {
          this.setState({ loading: false });
        }
      }
    });
  }

  render() {
    const { onClick, loading, ...props } = this.props;

    return (
      // TODO  we were sending the props to the button but `size` wasn't compatible...
      // {...props}
      // size={this.props.size === 'small' ? '18px': '20px'}
      <Button {...props} onClick={this.onClick} disabled={this.state.loading || this.props.loading || props.disabled}>
        {this.props.loading || this.state.loading ? <CircularProgress size="24px" /> : this.props.children}
      </Button>
    );
  }
}
