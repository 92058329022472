import { MS_PER_S } from './constants';

interface ObjectWithTimestamp {
  timestamp: number;
}

export class FrequencyCounter<T> extends Array<ObjectWithTimestamp & T> {
  private averageWindowSeconds: number;

  constructor(averageWindowSeconds: number) {
    super();
    this.averageWindowSeconds = averageWindowSeconds;
  }

  push(...items: T[]): number {
    const now = Date.now();
    const newItems = items.map((item) => ({ timestamp: now, ...item }));
    super.push(...newItems);
    return this.length;
  }

  getAverageHz(): number {
    const now = Date.now();
    const windowStart = now - MS_PER_S * this.averageWindowSeconds;
    const windowEnd = now;
    const windowItems = this.filter((item) => item.timestamp >= windowStart && item.timestamp <= windowEnd);

    // Delete extra items
    this.splice(0, this.length - windowItems.length);

    const actualWindowTimeMs = windowItems[windowItems.length - 1].timestamp - windowItems[0].timestamp;
    const actualSeconds = actualWindowTimeMs / MS_PER_S;

    if (windowItems.length === 0) return 0;
    return windowItems.length / Math.min(this.averageWindowSeconds, actualSeconds);
  }

  getAllTimeHz(): number {
    if (this.length === 0) return 0;
    const totalTimeWindowMs = this[this.length - 1].timestamp - this[0].timestamp;
    const totalSeconds = totalTimeWindowMs / MS_PER_S;
    return this.length / totalSeconds;
  }
}
