import { Button, Grid, Menu } from '@material-ui/core';
import DropoffList from './DropoffList';
import { DropoffLocationDistance } from '../../types/types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface DropoffSelectionProps {
  anchors: Record<string, any>;
  dropoffName: string;
  updateAnchors: (anchors: Record<string, any>) => void;
  unknownDropoff: boolean;
  filteredDropoffs: DropoffLocationDistance[];
  switchDropoff: (dropoff: DropoffLocationDistance, dropoffName: string) => void;
  noFilterText?: boolean;
  dropoffInResults?: boolean;
  distance?: number;
}

export function DropoffSelection({
  anchors,
  dropoffName,
  updateAnchors,
  unknownDropoff,
  filteredDropoffs,
  switchDropoff,
  noFilterText,
  dropoffInResults,
  distance,
}: DropoffSelectionProps) {
  return (
    <Grid item xs={10}>
      <Button
        variant="outlined"
        disabled={unknownDropoff}
        style={{ paddingRight: 5 }}
        onClick={(event) => {
          const newAnchors = { ...anchors };
          newAnchors[`${dropoffName}Anchor`] = event.currentTarget;
          updateAnchors(newAnchors);
        }}
      >
        {(noFilterText || dropoffInResults) &&
          `${dropoffName}${distance !== undefined ? ` - ${Math.round(distance)} mi` : ''}`}
        <ArrowDropDownIcon style={{ marginLeft: 2.5 }} />
      </Button>
      <Menu
        open={Boolean(anchors[`${dropoffName}Anchor`])}
        anchorEl={anchors[`${dropoffName}Anchor`]}
        onClose={() => {
          const newAnchors = { ...anchors };
          newAnchors[`${dropoffName}Anchor`] = null;
          updateAnchors(newAnchors);
        }}
      >
        <DropoffList
          disabled={unknownDropoff}
          key={dropoffName}
          list={filteredDropoffs}
          onClick={switchDropoff}
          dropoffName={dropoffName}
        />
      </Menu>
    </Grid>
  );
}
