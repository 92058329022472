import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Waypoint, { WaypointList } from './WaypointClass';
import { IPathPoint } from './types';

export default class PathPoint extends ObjectClassGenerator<PathPoint>('PathPoint') implements IPathPoint {
  // attributes

  // relationships
  #Waypoint_id?: number;

  static tableName = 'PathPoint';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(PathPoint, 'Waypoint_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<PathPoint> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#Waypoint_id = state.Waypoint_id;
  }

  dispose() {
    const waypoint = this.getWaypoint();
    if (waypoint) {
      waypoint.dispose();
      this.Waypoint_id = undefined;
    }

    PathPoint.delete(this.instance_id);
  }

  get mission_name() {
    return this.getWaypoint()?.mission_name;
  }

  get last_modified() {
    return this.getWaypoint()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getWaypoint();
    if (inst) {
      inst.last_modified = value;
    }
  }

  get waypoint_number() {
    return this.getWaypoint()?.waypoint_number;
  }

  set Waypoint_id(value) {
    if (this.#Waypoint_id) {
      const relateObj = Waypoint.get(this.#Waypoint_id);
      if (relateObj) {
        relateObj.removeRelationshipData('PathPoint', this.instance_id);
      }
    }
    this.#Waypoint_id = value;
    if (value) {
      const relateObj = Waypoint.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('PathPoint', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Waypoint_id() {
    return this.#Waypoint_id;
  }

  getWaypoint() {
    return Waypoint.get(this.Waypoint_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = PathPoint.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.waypoint_number === this.waypoint_number &&
        sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate path point found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`path point: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getWaypoint()) {
      problems.push(
        `path point: Could not find waypoint instance across unconditional relationship R12: ${this.instance_id}`,
      );
    }
    return problems;
  }
}

export class PathPointList extends DataObjectList<PathPoint> {
  getWaypoints() {
    return new WaypointList(...this.map((pathpoint) => pathpoint.getWaypoint()).filter((sel) => !!sel));
  }
}
