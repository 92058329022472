import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';
import logger from '../logger';
import Role, { RoleList } from './RoleClass';
import Session, { SessionList } from './SessionClass';
import { recoverUsers } from '../db_ops/user_ops';
import { AppRole, AppRoleToTasks } from '../types/types';
import Task, { TaskName } from './TaskClass';
import AirtableRecord from './AirtableRecordClass';
import { IUser } from './types';
import { TeamUserFlags } from '../types/rogo.at.zod';
import { Team } from '@rogoag/airtable';

export default class User extends ObjectClassGenerator<User>('User') implements IUser {
  // attributes
  #name: string;
  #hashed_password: string;
  #app_roles: AppRole[];
  #airtable_name: string;
  #user_id: number;
  #id: string;
  #email: string;
  #user_flags: TeamUserFlags[];

  // relationships
  #Role_id?: number;

  static tableName = 'User';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(User, 'name');
    this.publishAttribute(User, 'hashed_password');
    this.publishAttribute(User, 'airtable_name');
    this.publishAttribute(User, 'user_id');
    this.publishAttribute(User, 'id');
    this.publishAttribute(User, 'email');
    this.publishAttribute(User, 'app_roles');
    this.publishAttribute(User, 'user_flags');
    this.publishAttribute(User, 'Role_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<User> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#name = state.name || '';
    this.#hashed_password = state.hashed_password || '';
    this.#airtable_name = state.airtable_name || '';
    this.#user_id = state.user_id || 0;
    this.#id = state.id || '';
    this.#app_roles = state.app_roles || [];
    this.#user_flags = state.user_flags || [];
    this.#email = state.email || '';
    this.#Role_id = state.Role_id;
  }

  dispose() {
    logger.log('USER_DISPOSE', `${this.#name}, ${this.#user_id}, ${this.email}`);

    const role = this.getRole();
    if (role) {
      this.Role_id = undefined;
    }

    const session = this.getSession();
    if (session) {
      session.User_id = undefined;
      session.dispose();
    }

    User.delete(this.instance_id);
  }

  set name(value) {
    this.#name = value;
    this.syncToDB();
  }

  get name() {
    return this.#name;
  }

  set hashed_password(value) {
    this.#hashed_password = value;
    this.syncToDB();
  }

  get hashed_password() {
    return this.#hashed_password;
  }

  set airtable_name(value) {
    this.#airtable_name = value;
    this.syncToDB();
  }

  get airtable_name() {
    return this.#airtable_name;
  }

  set user_id(value) {
    this.#user_id = value;
    this.syncToDB();
  }

  get user_id() {
    return this.#user_id;
  }

  set id(value) {
    this.#id = value;
    this.syncToDB();
  }

  get id() {
    return this.#id;
  }

  set email(value) {
    this.#email = value;
    this.syncToDB();
  }

  get email() {
    return this.#email;
  }

  get app_roles() {
    return this.#app_roles;
  }

  set app_roles(value) {
    this.#app_roles = value;
    this.syncToDB();
  }

  get customer_boundary_recording() {
    // TODO make this not Loam specific
    return this.user_flags.includes('Customer Boundary Recording');
  }

  get user_flags() {
    return this.#user_flags;
  }

  set user_flags(value) {
    this.#user_flags = value;
    this.syncToDB();
  }

  get role_name() {
    return this.getRole()?.name;
  }

  set Role_id(value) {
    if (this.#Role_id) {
      const relateObj = Role.get(this.#Role_id);
      if (relateObj) {
        relateObj.removeRelationshipData('User', this.instance_id);
      }
    }
    this.#Role_id = value;
    if (value) {
      const relateObj = Role.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('User', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Role_id() {
    return this.#Role_id;
  }

  getTasks = () => {
    const appRoles = this.app_roles || ['Operators'];
    const taskNames = [
      ...new Set(
        appRoles.reduce<TaskName[]>((acc, appRole) => {
          const appRoleTasks = AppRoleToTasks[appRole];
          return acc.concat(appRoleTasks);
        }, []),
      ),
    ];
    const tasks = taskNames.map((taskName) => Task.findOne((task) => task?.name === taskName)).filter((task) => !!task);

    return tasks.sort((task1, task2) => task1.order - task2.order);
  };

  getRole() {
    return Role.get(this.Role_id);
  }

  getSession() {
    if (this._refs && this._refs.Session) {
      const result = Array.from(this._refs.Session).map((id) => Session.get(id));
      return result[0];
    } else {
      const session = Session.findOne((sel) => sel?.User_id === this.instance_id);
      if (session) {
        session.User_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return session;
    }
  }

  getAirtableRecord() {
    return this.id ? AirtableRecord.findOne<AirtableRecord<Team>>((sel) => sel?.id === this.id) : undefined;
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = User.query((sel) => sel.instance_id !== this.instance_id && sel.name === this.name);
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate user found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check ID2
    const id2_duplicates = User.query(
      (sel) => sel.instance_id !== this.instance_id && sel.hashed_password === this.hashed_password,
    );
    for (const dup of id2_duplicates) {
      problems.push(`Duplicate user found with ID2 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`user: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getRole()) {
      problems.push(`user: Could not find role instance across unconditional relationship R500: ${this.instance_id}`);
    }
    return problems;
  }

  static async recoverUsers(usersParsed: any[]) {
    return await recoverUsers.bind(this)(usersParsed);
  }
}

export class UserList extends DataObjectList<User> {
  getRoles() {
    return new RoleList(...this.map((user) => user.getRole()).filter((sel) => !!sel));
  }

  getSessions() {
    return new SessionList(...this.map((user) => user.getSession()).filter((sel) => !!sel));
  }
}
