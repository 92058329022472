import React, { PureComponent } from 'react';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { LoadingButton } from '../../utils';
import { getCurrentMission } from '../../../dataModelHelpers';
import pdfMake from '../../../services/PdfService';
import logger from '../../../logger';
import { alertConfirmDownload } from '../helpers/downloads';
import { allBarcodesValid } from '../../../barcodes';
import { cancelConfirmTag } from '../../../alertDispatcher';
import { PRINTER_CONFIG, PRINTER_OPTIONS, PRINTER_SIZE_4_6 } from '../../robot/configs/PrinterConfig';
import { SettingsAccessLevel } from '../../../types/types';

interface DownloadPDFButtonProps {
  accessLevel: SettingsAccessLevel;
}

interface DownloadPDFButtonState {
  printerConfig: PRINTER_CONFIG;
}

export default class DownloadPDFButton extends PureComponent<DownloadPDFButtonProps, DownloadPDFButtonState> {
  constructor(props: DownloadPDFButtonProps) {
    super(props);

    this.state = {
      printerConfig: PRINTER_SIZE_4_6,
    };

    this.downloadPDF = this.downloadPDF.bind(this);
    this.handlePrinterSelect = this.handlePrinterSelect.bind(this);
  }

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
  }

  /**
   * Changes printer configuration to be used for PDF
   */
  handlePrinterSelect(printerConfig: PRINTER_CONFIG) {
    //console.log(`DownloadPDFButton:handlePrinterSelect`);
    this.setState({ printerConfig: printerConfig });
  }

  async downloadPDF() {
    try {
      const mission = getCurrentMission();
      if (!mission) {
        console.error('Could not export PDF: No mission found');
        return;
      }
      if (allBarcodesValid() || (await alertConfirmDownload(this.constructor.name))) {
        const logoblob = await fetch('./images/logoBlack.png').then((r) => r.blob());
        const logoUrl = await new Promise<string | ArrayBuffer>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result ?? '');
          reader.readAsDataURL(logoblob);
        });

        const missionpdf = await mission.to_pdf(logoUrl, 'drive', this.state.printerConfig);

        if (missionpdf) {
          pdfMake.createPdf(missionpdf).download(`${mission.name}.pdf`);
          await logger.log('DOWNLOAD_PDF', { mission: mission.name });
        } else {
          console.error('Could not export PDF', missionpdf);
        }
      }
    } catch (err) {
      console.error('Could not export PDF', err);
    }
  }

  render() {
    return (
      <Grid item>
        {this.props.accessLevel === 'Technician' && (
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Printer Config</InputLabel>
            <Select
              label="Printer Config"
              value={this.state.printerConfig}
              onChange={(e) => {
                this.handlePrinterSelect(e.target.value as PRINTER_CONFIG);
              }}
              style={{
                marginRight: 15,
                height: 36,
                fontSize: 14,
                overflow: 'hidden',
                width: 156,
              }}
            >
              {PRINTER_OPTIONS.map((config) => (
                // @ts-ignore right now we are attaching the value to this item
                <MenuItem key={config.pageSize} value={config} style={{ fontSize: 14 }}>
                  {config.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <LoadingButton variant="outlined" onClick={this.downloadPDF} data-testid="mission-download-pdf">
          <CloudDownloadIcon style={{ paddingRight: 7.5 }} /> PDF
        </LoadingButton>
      </Grid>
    );
  }
}
