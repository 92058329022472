import { isMobile } from 'react-device-detect';
import { MobileHelperEnabled } from '../db/local_storage';
import { IRobotConnection } from '../types/types';
import { LocalStorageGenerator } from '../utils';

const hostname = isMobile && MobileHelperEnabled.get() ? 'year.rogoag.com' : 'local.rogoag.com';
const endpoint = '/';
const port = 9090;

const defaultRobotConnection: IRobotConnection = {
  hostname,
  endpoint,
  port,
  insecure: false,
};

export const RobotConnectionStore = LocalStorageGenerator<IRobotConnection>('robot_connection', defaultRobotConnection);
