export const ALERT_DISPATCH_EVENTS = {
  ERROR: 'ALERT_ERROR',
  SUCCESS: 'ALERT_SUCCESS',
  WARNING: 'ALERT_WARNING',
  WARNING_IGNORABLE: 'ALERT_WARNING_IGNORABLE',
  CONFIRM: 'ALERT_CONFIRM',
  CONFIRM_WARN: 'ALERT_CONFIRM_WARN',
  CONFIRM_ERROR: 'ALERT_CONFIRM_ERROR',
  INFO: 'ALERT_INFO',
} as const;

export const ALERT_RETURN_EVENTS = {
  CONFIRM_RETURN: 'USER_CONFIRM_RETURN',
  UNMOUNT: 'UNMOUNT_RETURN',
} as const;

export const ALERT_EVENT_NAME = 'ALERT';
export const FULL_SCREEN_ALERT = 'FULL_SCREEN_ALERT';
