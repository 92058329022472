import { PureComponent } from 'react';
import getConnection from '../RobotConnection';
import { RobotConnectionStore } from './RobotConnectionStore';

interface RobotsProps {
  // TODO not sure how this component is really used...
  // this component seems to be a placeholder for robot
  // connection status but doesn't actually render anything
  // this is also only used in sampling (I think) so it seems
  // that the logic to check if the task is different
  // doesn't seem to accopmlish anything super helpful
  task: string;
}

interface RobotsState {
  hostname: string;
  endpoint: string;
  port: number;
  insecure: boolean;
}

export default class Robots extends PureComponent<RobotsProps, RobotsState> {
  constructor(props: RobotsProps) {
    super(props);
    this.state = {
      hostname: '',
      endpoint: '',
      port: 0,
      insecure: false,
    };
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  componentDidMount() {
    // get the robot
    this.getRobot();
  }

  componentWillUnmount() {
    // deactivate connection
    const conn = getConnection(this.state.hostname);
    if (conn) {
      conn.deactivateConnection();
    }
  }

  // currently expect only one robot to be in the system
  getRobot() {
    this.setState(RobotConnectionStore.get());
  }

  handleRefresh() {
    if (this.state.hostname) {
      const conn = getConnection(this.state.hostname);
      if (conn && !conn.active) {
        console.log('Refresh connection');
        conn.openConnection();
      }
    }
  }

  componentDidUpdate(prevProps: RobotsProps, prevState: RobotsState) {
    // update the robot if the task ID changes
    if (this.props.task !== prevProps.task) {
      this.getRobot();
    }
    if (prevState.hostname !== this.state.hostname) {
      // switch the robot in the session
      // TODO Probably don't need this anymore but will leave for now
      // const session = getCurrentSession();
      // if (session) {
      //   session.switch_robot(this.state.hostname);
      // }
      // deactivate previous connection
      if (prevState.hostname) {
        const oldConn = getConnection(prevState.hostname);
        if (oldConn) {
          oldConn.deactivateConnection();
        }
      }

      const currentConn = getConnection(
        this.state.hostname,
        { insecure: this.state.insecure, port: this.state.port, endpoint: this.state.endpoint },
        true,
      );
      if (currentConn && !currentConn.active) {
        currentConn.openConnection();
      }
    }
  }

  render() {
    return null;
  }
}
