// these are some custom Rogo types for use in the app
// export declare type Coordinates = [latitude: number, longitude: number];

import { Coordinate } from 'ol/coordinate';
import { AirtableRecord } from '../db';
import GeometryType from 'ol/geom/GeometryType';
import { HeaderTimestamp, RobotControlRole, RosMissionMsg, RosPosition } from './rosmsg';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import { KML_FEATURE_TYPE, KmlFeatureType } from '../featureTypes';
import BaseEvent from 'ol/events/Event';
import { DrawEvent } from 'ol/interaction/Draw';
import { TaskName } from '../db/TaskClass';
import { Jobs, ShiftDropoffs, Dropoffs, Shifts, Box } from '@rogoag/airtable';
import * as turf from '@turf/turf';

// export declare type Coordinate[] = Coordinates[];
export declare type RogoShapefileType = 'bnd' | 'pts';
export declare type RogoShapefile = { mode: RogoShapefileType; source: string; data: ArrayBuffer; name: string };
export declare type RecordingDevice = '' | 'robot' | 'tablet';
export declare type MissionStatus = 'completed' | 'partial';
export declare type LatLonSource = '' | 'NearestRoad' | 'CenterBoundary' | 'Pullin';
export declare type RobotConnectionStatus = 'disconnected' | 'connecting' | 'connected';
export declare type RogoTheme = 'light' | 'dark';
export declare type Boundary = { type: string; coords: Coordinate[][] | Coordinate[][][] };
export declare type SchedulingRecordTypes =
  | AirtableRecord<Shifts>
  | AirtableRecord<ShiftDropoffs>
  | AirtableRecord<Jobs>;
export declare type ScheduleMap = Record<string, SchedulingRecordTypes[]>;
export declare type ScheduleFeature = turf.Feature<turf.Point | turf.Polygon | turf.MultiPolygon>;
export declare type ScheduleItem = AirtableRecord<Jobs> | AirtableRecord<ShiftDropoffs>;
export declare type JobBoundsMap = Record<string, Boundary[]>;
export declare type RecordingCoords = { id?: number; coords: Coordinate[] };

export declare type ProbeSelection = '' | 'PROBE' | 'AUGER' | 'RING-TIP';

export declare type SettingsAccessLevel = 'Unprotected' | 'Operator Tuning' | 'Technician' | 'Locked';
export declare type SettingsPersistence = 'Daily' | 'Job' | 'Permanent';

export type ObjectValues<T> = T[keyof T];

export declare type OnLoading = <T>(action: () => Promise<T>, monitorProgress?: boolean) => Promise<T>;
export interface DropoffLocationDistance {
  distance?: number;
  record: AirtableRecord<Dropoffs>;
}

export interface BarcodeIssue {
  partialIgnore: boolean;
  message: string;
}

export interface KMLFeatureProperties {
  name: string;
  visibility: boolean;
}

export interface KMLSampleFeatureProperties extends KMLFeatureProperties {
  centroid: boolean;
  bag_id: string;
  pulled_at: number;
  skipped: boolean;
  order: number;
  spec_id: string;
  cores: number;
  sample_site_source: SampleSource;
  pattern_type: number;
  radius: number;
  length: number;
  angle: number;
  start_depth: number;
  end_depth: number;
  change_type: SampleChangeType;
  site_order: number;
}

export interface KMLSoilCoreFeatureProperties extends KMLFeatureProperties {
  core_location: boolean;
  command: number;
  waypoint_index: number;
  waypoint_number: number;
  sample_id: string;
  zone_core?: boolean;
  order?: number;
  bag_id?: string;
  start_depth?: number;
  end_depth?: number;
  skipped?: boolean;
  source: CoreSource;
  pulled: boolean;
}

export interface KMLZoneFeatureProperties extends KMLFeatureProperties {
  poly_id: number;
  zone_id: number;
  generic_zone?: boolean;
  sample_zone?: boolean;
  zone_color?: [number, number, number];
}

export type DefaultKMLFeatureProperties = { [key: string]: any };

// TODO this can likely be replaced with Feature from turf

// would prefer to have this typed better. Probably number[][] | number[][][] is the most accurate
type DefaultCoordinatesType = {
  coordinates?: any[];
};

// TODO This seems to have an overlap with standard GeoJSON types and we should migrate to those
export interface KMLFeature<T = DefaultKMLFeatureProperties, C = DefaultCoordinatesType> {
  type?: string;
  properties: T;
  geometry: {
    type?: GeometryType;
  } & C;
}

export interface BoxJobSamples {
  [jobId: string]: {
    jobInfo: {
      field: string;
    };
    samples: {
      sample_id: string;
      bag_id: string;
      pulled_at: number;
      order: number;
    }[];
  };
}

export const DRAW_TYPES = {
  SLOW_ZONE: KML_FEATURE_TYPE.SLOW,
  PULLIN_ZONE: KML_FEATURE_TYPE.PULLIN,
  REORDER_LINE: KML_FEATURE_TYPE.REORDER_LINE,
  UNSAFE_ZONE: KML_FEATURE_TYPE.UNSAFE,
  CENTROID: KML_FEATURE_TYPE.CENTROID,
  CORE_LINE: KML_FEATURE_TYPE.CORE_LINE,
  CORE_POINT: KML_FEATURE_TYPE.CORE_POINT,
} as const;

export type DrawType = ObjectValues<typeof DRAW_TYPES>;

export interface BasicJsonRecord {
  [key: string]: string | number | boolean | BasicJsonRecord | BasicJsonRecord[];
}

export declare type SampleErrors = { dupError: boolean; gapError: boolean; invalidError: boolean };
export declare type SampleErrorMap = Record<string, SampleErrors>;

export declare type TakenCore = [
  stamp: HeaderTimestamp,
  target_depth: number | null,
  postlaunch_depth_error: number | null,
  position: RosPosition,
  initiator: number,
  sample_id: string,
  hole_depth: number | null,
  core_length: number | null,
  plunge_depth: number | null,
  core_loss: number | null,
  core_diameter_inches: string | null,
];

export interface BarcodeScannedPayload {
  barcode: string;
  currentSampleInstanceId: number;
  errMap: SampleErrorMap;
  nextSampleInstanceId: string;
}

export interface SelectCandidate {
  feature: Feature<Geometry>;
  type: KmlFeatureType;
  removeFunc: () => void;
  coordinates: Coordinate;
}

export type RobotNavControl = 'Auto' | 'Manual';

export const enum RobotArmType {
  'NormalArm' = 0,
  'BulkArm' = 1,
}

export declare type DrawConfig = {
  geometryType: GeometryType | null;
  featureType: KmlFeatureType | null; // TODO should be DRAW_TYPES
  styleParams: any;
  action?: 'Adding' | 'Moving';
};

export type RobotEventType = 'arriving' | 'leaving' | 'nav_leg' | 'core_complete' | 'dump_complete' | 'scan';
export type RobotEvent = {
  type: RobotEventType;
  sample_id: string;
  timestamp: number;
  location: RosPosition;
  meta: string | number | boolean;
};
export type MissionRobotEvents = { missionId: string; events: RobotEvent[] };

export type ConfirmType = 'Yes/No' | 'Ok' | 'Ok/Cancel';

export interface FullScreenAlert {
  tag: string;
  message: string;
  type: ConfirmType;
  confirmNum: number;
  confirm: boolean;
}

export interface RobotMockMessage {
  topic: string;
  payload: object;
}

export interface RoleMsg {
  role: RobotControlRole;
}
export interface HelperData {
  sample_id: string;
  job_id: string;
}

export type RosDataMsgType = string | number | boolean | RoleMsg | HelperData;

export type RosDataMsg = {
  data: RosDataMsgType;
};

export declare interface RosMsgPubData {
  hostname: string;
  msg: RosDataMsg | RosMissionMsg | string;
  topic: string;
  tag: string;
  compress?: boolean;
  expectationParams?: {
    expectedTopic: string;
    expectedValue?: any;
    messages?: {
      success: string;
      error: string;
    };
    expectedTimeout?: number;
    doneCallback?: (success: boolean, dataCorrect: boolean) => void | Promise<void>;
  };
}

export declare interface RosMsgConnectionData {
  hostname: string;
  status: string;
}

export enum BoundaryChangeType {
  None = '',
  Major = 'Major',
  Minor = 'Minor',
}

export interface WebsocketPacket {
  type: 'data' | 'error' | 'warning' | 'heartbeat' | 'peer_msg';
  topic: string;
  data: any;
  compressed: boolean;
}

export interface BasePeerMessage {
  dest_ip: string;
  sender_ip: string;
}

export interface RolePeerMessage extends BasePeerMessage {
  data: { role: RobotControlRole };
}

export interface PublishClientListMessage extends BasePeerMessage {
  clients: { ip: string; last_seen_time: number }[];
}

export const enum RobotState {
  // // Automatic mode robot states
  '0A Navigating' = 0,
  '1A Coring' = 1,
  '2A Awaiting Dump' = 2,
  '3A Coring' = 3,
  '5A Rdy to go' = 5,
  '6A Unrdy to do' = 6,
  '10M Driving' = 10,
  '11M Coring' = 11,
  '13M Homing' = 17,
  '16M Unrdy 2 Core' = 16,
  '15M Rdy 2 Core' = 15,
  '20 ESTOPPED' = 20,
  '21 Unsafe Cond' = 21,
  'Unknown' = 99,
}

export interface ClientAddedOrRemoved extends BasePeerMessage {
  ip: string;
}

export type ShowAutoGenHelper = (data: AutoGenHelperParams | undefined) => void;

export interface AutoGenHelperParams {
  title: string;
  selection: string;
  source: string;
  jobType: JobType;
  headers: string[];
  data: string[][];
  featureTypes: any;
  onSelection: (value: string) => void;
  autoFilter?: boolean;
}

export interface ConfirmColumn {
  filename: string;
  source: string;
  selection: string;
  headers: string[];
  data: string[][];
  featureTypes: any;
}

export interface SchedulingBaseProps {
  job_id: string;
  field_name?: string;
  lab?: string;
  client?: string;
  grower?: string;
  pullinType: string;
  dropoff_name?: string;
  dropoff_lab?: string;
  address?: string;
  unassigned?: boolean;
  robot?: string;
  schedDate?: string;
  operatorName?: string;
  scheduleOrder?: number;
  first?: boolean;
  selected?: boolean;
  type?: 'Pullin' | 'Field';
  internalOrder?: number;
}

// TODO I don't like this name...
// this interface is a way to associate the classes we have that have lat/lon properties
// So far that is:
// - SoilCore
// - SampleCentroid
// - Waypoint
export interface CoordinatePoint {
  lat: number;
  lon: number;
  getCoordinates(): Coordinate;
}

export type WebsocketPeerList = { [ip: string]: { ip: string; role: RobotControlRole } };

export const SampleChangeTypes = ['None', 'Add', 'Move', 'Delete', 'Skip'] as const;
export type SampleChangeType = (typeof SampleChangeTypes)[number];
export interface HasChangeReason {
  change_reason: string;
  change_type: SampleChangeType;
  skipped_or_deleted: boolean;
}

export const ChangeReasons = [
  'Boundary Change',
  'Too Wet',
  'Standing Crop',
  'Inaccessible',
  'Other',
  "Didn't Finish",
  'Not Extractable',
  'Old Core Location',
];
// we are explicitly not making SkipReasons 'as const' because we want to do string checks against it.
// there is probably a better way to handle this but it works for now

export type ChangeReason = (typeof ChangeReasons)[number];

export const JobTypes = ['Zone', 'Grid', 'Modgrid'] as const;
export type JobType = (typeof JobTypes)[number];

export const SampleSources = ['Unknown', 'Customer', 'Rogo Map Maker', 'Rogo Field Ops'] as const;
export type SampleSource = (typeof SampleSources)[number];
export type CoreSource = SampleSource;

// this is loosely based on the available enum in the Role field in the Airtable Team table
export const AppRoles = [
  'Office',
  'Mapmaker',
  'Fine Manager',
  'Operators',
  'Ops Manager',
  'App admin',
  'Engineering',
] as const;
export type AppRole = (typeof AppRoles)[number];

export const AppRoleToTasks: Record<AppRole, TaskName[]> = {
  Office: [TaskName.MAPS, TaskName.SAMPLING, TaskName.SHIPPING, TaskName.SCHEDULING, TaskName.DIRECTIONS],
  Mapmaker: [TaskName.MAPS, TaskName.SAMPLING],
  'Fine Manager': [],
  Operators: [TaskName.SAMPLING, TaskName.SHIPPING, TaskName.DIRECTIONS],
  'Ops Manager': [TaskName.SCHEDULING],
  'App admin': [TaskName.MAPS, TaskName.SAMPLING, TaskName.SHIPPING, TaskName.SCHEDULING, TaskName.DIRECTIONS],
  Engineering: [TaskName.MAPS, TaskName.SAMPLING, TaskName.SHIPPING, TaskName.SCHEDULING, TaskName.DIRECTIONS],
};
// export const enum TaskName {
//     MAPS='maps',
//     SAMPLING='sampling',
//     SHIPPING='shipping',
//     SCHEDULING='scheduling',
//     DIRECTIONS='directions'
//   }

export const BarcodeSelectionModes = ['Automatic', 'Manual'] as const;
export type BarcodeSelectionMode = (typeof BarcodeSelectionModes)[number];

export function eventIsDrawEvent(event: BaseEvent | DrawEvent): event is DrawEvent {
  return (event as DrawEvent).feature !== undefined;
}

export interface IRobotConnection {
  hostname: string;
  endpoint: string;
  port: number;
  insecure: boolean;
}

const CoreDiameters = ['0.75 in', '2 in'] as const;

export type CoreDiameter = (typeof CoreDiameters)[number];

export type FacingMode = 'user' | 'environment';

export type MissionView = 'standard' | 'helper';

export const getIssueType = (issue: CoringAllowedState): string => {
  switch (issue) {
    case 'Missing Barcode':
    case 'Soil Mix Risk':
      return 'Scan Issue';
    case 'Need Field Info':
    case 'Not Close Enough':
      return 'Coring';
    default:
      return issue;
  }
};

export const CoringAllowedStates = [
  'Good', // 0
  'Missing Barcode', // 1
  'Soil Mix Risk', // 2
  'Need Field Info', // 3
  'Not Close Enough', // 4
] as const;
export type CoringAllowedState = (typeof CoringAllowedStates)[number];

export type ValidatePasswordFunction = (password: string) => Promise<SettingsAccessLevel>;

export type SetWaypointFunction = (
  startAt: number,
  sampleId: string | null,
  targetWaypoint: string | null,
  tag: string,
  doneCallback: () => void,
  coordinates?: Coordinate,
) => Promise<void>;

export type SetZoneWaypointFunction = (
  zoneName: string | null,
  sampleId: string | null,
  tag: string,
  doneCallback: () => void,
  coordinates?: Coordinate,
) => Promise<void>;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// {
//     "rec48x7fOxnCPbwu6": {
//         "jobInfo": {
//             "field": "South 1"
//         },
//         "samples": [
//             {
//                 "sample_id": "1",
//                 "bag_id": "RG300000524-699",
//                 "pulled_at": 1677095122.497,
//                 "order": 1
//             },
//             {
//                 "sample_id": "2",
//                 "bag_id": "RG300000524-700",
//                 "pulled_at": 1677095230.111,
//                 "order": 2
//             },
//             {
//                 "sample_id": "3",
//                 "bag_id": "RG300000524-701",
//                 "pulled_at": 1677095339.453,
//                 "order": 3
//             },
//             {
//                 "sample_id": "4",
//                 "bag_id": "RG300000524-680",
//                 "pulled_at": 1677095428.462,
//                 "order": 4
//             }
//         ]
//     },
//     "recYyJ4LQWA4tLuPb": {
//         "jobInfo": {
//             "field": "South 2"
//         },
//         "samples": [
//             {
//                 "sample_id": "1",
//                 "bag_id": "RG300000524-681",
//                 "pulled_at": 1677095855.458,
//                 "order": 1
//             },
//             {
//                 "sample_id": "2",
//                 "bag_id": "RG300000524-633",
//                 "pulled_at": 1677096239.967,
//                 "order": 2
//             },
//             {
//                 "sample_id": "3",
//                 "bag_id": "RG300000524-634",
//                 "pulled_at": 1677096458.883,
//                 "order": 3
//             },
//             {
//                 "sample_id": "4",
//                 "bag_id": "RG300000524-635",
//                 "pulled_at": 1677096551.429,
//                 "order": 4
//             }
//         ]
//     }
// }

//   {
//     "rec8vru6huUnwIGFK": {
//         "jobInfo": {
//             "field": "West 3"
//         },
//         "samples": [
//             {
//                 "sample_id": "1",
//                 "bag_id": "RG201000296537",
//                 "pulled_at": 1677163561.498,
//                 "order": 1
//             },
//             {
//                 "sample_id": "2",
//                 "bag_id": "RG201000296538",
//                 "pulled_at": 1677163764.261,
//                 "order": 2
//             },
//             {
//                 "sample_id": "3",
//                 "bag_id": "RG201000296539",
//                 "pulled_at": 1677164070.013,
//                 "order": 3
//             },
//             {
//                 "sample_id": "4",
//                 "bag_id": "RG201000296540",
//                 "pulled_at": 1677164079.061,
//                 "order": 4
//             }
//         ]
//     }
// }

export type GeometryPoint = {
  x: number;
  y: number;
};

export const ScheduleViewModes = [
  'Disabled',
  'Scheduled Jobs Only',
  // "Sampled + Scheduled Jobs" // TODO this is currently not supported
] as const;
export type ScheduleViewMode = (typeof ScheduleViewModes)[number];

export interface Dropoff {
  distance?: number;
  labBoxes: LabToBoxMap;
  method?: string;
  id: string;
  valid: boolean;
}
export interface LocationMap extends Map<string, DropoffLocationDistance[]> {}
export interface LabToBoxMap extends Map<string, AirtableRecord<Box>[]> {}
export interface DropoffBoxMap extends Map<string, string> {}
export interface DropoffSmartGroup extends Map<string, Dropoff> {}
/**
 * - dropoff (name)
 *    - labs
 *        - lab 1 (name)
 *            - box 1
 *            - box 2
 *            ...
 *        - lab 2 (name)
 *            - box 3
 *            - box 4
 *            ...
 *        ...
 *    - method              Shipping method: Courier vs UPS
 *    - id                  Airtable Dropoff record ID
 *    - valid               We have a calculate distance and within a half mile?
 *    - distance            Distance from user to dropoff location
 *
 */
