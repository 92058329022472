import { Mission, Sample } from '../db';

export const MIN_CORES_COUNT = 3;
export const ALLOW_CORES_DIFF_COUNT = 2;

export default class CoreValidator {
  mission: Mission;

  constructor(mission: Mission) {
    this.mission = mission;
  }

  sampleHasEnoughCores(sample: Sample): boolean {
    const totalCores = sample.getSoilCores();
    const pulledCores = totalCores.filter((core) => core.pulled);

    return this.isEnoughCores(totalCores.length, pulledCores.length);
  }

  private isEnoughCores(totalCoresCount: number, pulledCoresCount: number): boolean {
    if (this.mission.isBDMission()) {
      return totalCoresCount === pulledCoresCount;
    }

    if (pulledCoresCount < MIN_CORES_COUNT) {
      return false;
    }

    return pulledCoresCount >= totalCoresCount - ALLOW_CORES_DIFF_COUNT;
  }
}
