const regexRules = [
  /**
   * Rogo bag
   * Format:  RG[12 digits]
   * Example: RG201000033898
   * Example: RG000000000-000
   */
  /^RG[0-9]{9}-?[0-9]{3}$/,
  /^[0-9]{9}-?[0-9]{3}$/,
  /^[0-9]{6} [0-9]{2} [0-9]{1} [0-9]{3}$/,

  /**
   * Suretech bag
   * Format:  7 to 10 digit incremented, potential - at position 5
   * Example: 0388744
   * Example: 0000388876
   * Example: 0000-388876
   */
  /^[0-9]{4}-?[0-9]{3,6}$/,

  /**
   * Agvise barcode
   * Format:  8 digit incremented
   * Example: 00003888
   */
  /^[0-9]{7,8}$/,

  /**
   * Not sampled
   * Format:  Not sampled
   * Example: Not sampled
   */
  /^Not sampled$/,
];

export const isBarcodeValidBag = (barcodeText: string, barcodeRegex?: string | null) => {
  if (barcodeRegex) {
    try {
      const regex = new RegExp(barcodeRegex);

      return regex.test(barcodeText);
    } catch (e) {
      console.log('barcodeRegex is wrong, falling back to default rules: ', barcodeRegex);
    }
  }

  return regexRules.some((regex) => regex.test(barcodeText));
};

export const boxRegexRules = [
  /**
   * Rogo box URL format
   * Format:  URL with box ID at end
   * Example: https://lab.rogoag.com/1b725g00
   * Example: l.rogo.ag/1b725g3600
   */
  // TODO this older format can likely be removed soon since we really only scan boxes that we've
  // recently created, which will be created with the newer format
  /https:\/\/lab\.rogoag\.com\/([a-zA-Z0-9]{8,10})/,

  /**
   * also identify the same style as above, except for url l.rogo.ag (no https://)
   * this will be the primary format moving forward (@see {@link file://./pdfcheckin.ts} for QR code generation)
   */
  /https:\/\/?l\.rogo\.ag\/([a-zA-Z0-9]{8,10})/,
];

export const isBarcodeValidBox = (barcodeText: string) => {
  return boxRegexRules.some((regex) => regex.test(barcodeText));
};

export const extractBoxID = (barcodeText: string) => {
  console.log(barcodeText);
  const result = barcodeText.match(boxRegexRules[1]);
  if (result) {
    // the way our regex is written, our result is the second item in the array
    // the first is the full result
    return result[1];
  }

  return undefined;
};
