import { ZoneRecording } from '../db';
import { ZoneRecordingType } from '../db/ZoneTypesDatatype';

export async function createRecording(zoneType: ZoneRecordingType, missionId: number) {
  const newZone = ZoneRecording.create();
  newZone.type = zoneType;
  newZone.coordinates = [];
  newZone.Mission_id = missionId;
  return newZone;
}
