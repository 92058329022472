import format from 'xml-formatter';
import { parseString, Builder } from 'xml2js';
import xpath from 'xml2js-xpath';
import { cloneDeep, get } from 'lodash';
import JSZip from 'jszip';
import { toKMLObject } from './kml2';

const KML_NAMESPACE = 'http://www.opengis.net/kml/2.2';
const XML2JS_CHAR_KEY = '_';
const kmlNsTag = 'kml';

const createSingleXml2JsElement = (data: string | {}, tagName: string): { [key: string]: string | {} } => {
  if (typeof data === 'string') {
    return {
      [tagName]: {
        [XML2JS_CHAR_KEY]: data,
      },
    };
  } else {
    // Object instance or else
    return {
      [tagName]: data,
    };
  }
};

export const kmlToString = (document: KMLDocumentContent, ugly: boolean) => {
  const xmlBuilder = new Builder({
    headless: true,
  });
  return (
    '<?xml version="1.0" encoding="UTF-8"?>' +
    (ugly ? '' : '\n') +
    format(xmlBuilder.buildObject(document), {
      indentation: ugly ? '' : '  ',
      collapseContent: true,
      lineSeparator: ugly ? '' : '\n',
    })
  );
};

export type KMLDocumentContent = {
  kml: {
    $: {
      xmlns: string;
    };
  };
};

export interface IKMLElement {
  [key: string]: any;
  tagName: string;
  doc: IKMLDocument;
  parentElement: IKMLElement;
  element: IKMLElement;
  getElementContent(): any;
  append(el: IKMLElement): void;
  extendFromText(text: string): void;
  getText(): string;
  setText(text: string): void;
  find(tag: string, include_grandchildren?: boolean, where?: (element: IKMLElement) => boolean): IKMLElement | null;
  findAll(tag: string, include_grandchildren: boolean, where?: (element: IKMLElement) => boolean): IKMLElement[];
  getExtendedData(): { [key: string]: any };
  putExtendedData(key: string, val: string | boolean | number | IKMLElement | undefined): any;
  findParent(tag: string, where: (element: IKMLElement) => boolean): IKMLElement | null;
}

export interface IKMLDocument {
  document: any;
  getRoot(): IKMLElement;
  find(tag: string, include_grandchildren?: boolean, where?: (element: IKMLElement) => boolean): IKMLElement | null;
  findAll(tag: string, include_grandchildren: boolean, where?: (element: IKMLElement) => boolean): IKMLElement[];
  createElement(tag: string): IKMLElement;
  toString(ugly?: boolean): string;
  toKMZ(filename?: string): Promise<Uint8Array>;
}

class KMLDocument implements IKMLDocument {
  document: KMLDocumentContent;

  constructor(content?: KMLDocument | string) {
    if (content) {
      if (content instanceof Object) {
        // recreate the object from web worker
        this.document = content.document;
      } else {
        const kmlObject = toKMLObject(content);
        //console.log(kmlObject);
        //console.log(JSON.stringify(kmlObject));
        parseString(content, (err, result) => {
          // This callback run synchronously
          if (err) {
            console.error(err);
          } else {
            this.document = result;
          }
        });
      }
    } else {
      this.document = {
        kml: {
          $: {
            xmlns: KML_NAMESPACE,
          },
        },
      };
    }
  }

  getRoot(): KMLElement {
    return new KMLElement(this.document, this);
  }

  // find(tag: string, include_grandchildren?: boolean, where?: (element: IKMLElement) => boolean): IKMLElement;
  find(tag: string, include_grandchildren?: boolean, where?: (element: IKMLElement) => boolean) {
    return this.getRoot().find(tag, include_grandchildren, where);
  }

  findAll(tag: string, include_grandchildren: boolean, where?: (element: IKMLElement) => boolean) {
    return this.getRoot().findAll(tag, include_grandchildren, where);
  }

  createElement(tag: string) {
    if (kmlNsTag === tag) {
      return {
        [tag]: {
          $: {
            xmlns: KML_NAMESPACE,
          },
        },
      } as unknown as IKMLElement;
    } else {
      return {
        [tag]: {
          $: {},
        },
      } as unknown as IKMLElement;
    }
  }

  toString(ugly = true) {
    return kmlToString(this.document, ugly);
  }

  toKMZ(filename = 'document.kml') {
    const kmlstring = this.toString();
    const zip = new JSZip();
    zip.file(filename, kmlstring);
    return zip.generateAsync({ type: 'uint8array', compression: 'DEFLATE' });
  }
}

class KMLElement implements IKMLElement {
  tagName: string;
  doc: IKMLDocument;
  parentElement: IKMLElement;
  element: IKMLElement;

  // KMLDocumentContent
  // TODO
  constructor(element: any, doc: IKMLDocument) {
    this.element = element;
    this.tagName = Object.keys(element)[0];
    this.doc = doc;
  }

  static element(tag: string) {
    const doc = new KMLDocument();
    return new KMLElement(doc.createElement(tag), doc);
  }

  static subelement(parent: IKMLElement, tag: string) {
    const element = new KMLElement(parent.doc.createElement(tag), parent.doc);
    parent.append(element);
    return element;
  }

  getElementContent() {
    return this.element[this.tagName];
  }

  append(el: IKMLElement) {
    if (this.doc !== el.doc) {
      el = new KMLElement(cloneDeep(el.element), this.doc);
    }

    const elemContent = this.getElementContent();
    if (!elemContent[el.tagName]) {
      elemContent[el.tagName] = [];
    }
    el.parentElement = this; // Store parent Element to use in findParent
    elemContent[el.tagName].push(el.getElementContent());
  }

  extendFromText(text: string) {
    const textWithRootNode = `<AppendData>${text}</AppendData>`; // Create AppendData root node to make sure the node has one root
    parseString(textWithRootNode, (err, result) => {
      if (!err) {
        for (const [key, value] of Object.entries(result.AppendData)) {
          if (!this.getElementContent()[key]) {
            this.getElementContent()[key] = [];
          }
          this.getElementContent()[key] = this.getElementContent()[key].concat(value);
        }
      }
    });
  }

  getText() {
    if (typeof this.getElementContent() === 'string') {
      return this.getElementContent();
    } else {
      return this.getElementContent()[XML2JS_CHAR_KEY];
    }
  }

  setText(text: string) {
    this.getElementContent()[XML2JS_CHAR_KEY] = text;
  }

  find(tag: string, include_grandchildren?: boolean, where?: (element: IKMLElement) => boolean) {
    for (let child of this._findAllElementByTagName(tag, include_grandchildren)) {
      if (!where || where(new KMLElement(child, this.doc))) {
        return new KMLElement(child, this.doc) as IKMLElement;
      }
    }
    return null;
  }

  findAll(tag: string, include_grandchildren: boolean, where?: (element: IKMLElement) => boolean) {
    const elements: IKMLElement[] = [];
    for (let child of this._findAllElementByTagName(tag, include_grandchildren)) {
      if (
        (!where || where(new KMLElement(child, this.doc))) &&
        (include_grandchildren || child['parentElement'] === this.element)
      ) {
        elements.push(new KMLElement(child, this.doc));
      }
    }
    return elements;
  }

  _findAllElementByTagName(tag: string, include_grandchildren?: boolean) {
    let xpathQuery = include_grandchildren ? `//${tag}` : `/${this.tagName}/${tag}`;
    const result = xpath.find(this.element, xpathQuery).map((data) => createSingleXml2JsElement(data, tag));
    return result;
  }

  // TODO TS we could probably type this based on our known KML data
  getExtendedData(): { [key: string]: any } {
    if (this.tagName === 'Placemark' || this.tagName === 'Document') {
      const extended_data = {};
      const allData = get(this.getElementContent(), 'ExtendedData[0].Data') || [];
      for (let datum of allData) {
        const value = datum.value ? datum.value[0] : undefined;
        extended_data[datum.$.name] = value || '';
      }
      return extended_data;
    } else {
      return {};
    }
  }

  get ExtendedDataElement() {
    return this.getElementContent().ExtendedData;
  }

  putExtendedData(key: string, val: string | boolean | number | IKMLElement | undefined) {
    if (this.tagName === 'Placemark' || this.tagName === 'Document') {
      if (!this.ExtendedDataElement) {
        this.getElementContent().ExtendedData = [
          {
            Data: [],
          },
        ];
      }

      let extended_data = this.ExtendedDataElement[0];
      const allData = extended_data.Data;
      let indexFound = -1;
      let datum = allData.find((d, index) => {
        if (d.name === key) {
          indexFound = index;
          return true;
        } else {
          return false;
        }
      });

      if (val === undefined) {
        if (indexFound > -1) {
          allData.splice(indexFound, 1);
        }
      } else {
        if (!datum) {
          datum = {
            $: {
              name: key,
            },
          };
          allData.push(datum);
        }

        datum.value = [
          {
            [XML2JS_CHAR_KEY]: val.toString(),
          },
        ];
      }
      return datum;
    } else {
      console.error('Cannot put extended data in a non-Placemark or non-Document element');
    }
  }

  findParent(tag: string, where: (element: IKMLElement) => boolean) {
    let parent = this.parentElement;
    while (parent) {
      if (parent.tagName === tag && (!where || where(parent))) {
        return parent;
      } else {
        parent = parent.parentElement || null;
      }
    }

    return null;
  }
}

export { KMLDocument, KMLElement };
