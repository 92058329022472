import AttachmentClass, { SupportedFileExtension } from '../db/AttachmentClass';
import { formatDateAsString } from '../utils';

export async function write(
  this: AttachmentClass,
  friendlyName: string,
  extension: SupportedFileExtension,
  content: Blob,
) {
  // upload the file
  const now = new Date();
  const timestamp = formatDateAsString(now);

  this.filename = `${friendlyName}__${timestamp}.${extension}`;
  this.content = content;
  this.dirty = true;
  const record = this.getAirtableRecord();
  if (record) {
    record.dirty = true;
  } else {
    console.error('Attachment lost reference to AirtableRecord.', this);
  }

  return { filename: this.filename, content };
}

export async function text(this: AttachmentClass) {
  let txt: string;
  try {
    // @ts-ignore TODO allow this to error intentionally? Is this still a Safari bug?
    txt = await this.content.text();
  } catch (_) {
    // safari back up
    txt = await new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = function (e) {
        // we will coerce this to be a string since we know
        // that we call .readAsText() below
        resolve((e.target?.result as string) || '');
      };
      fr.onerror = function (e) {
        reject(e);
      };
      fr.readAsText(this.content!, 'utf-8');
    });
  }

  return txt;
}
