import React, { PureComponent } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { MapReact, layer, control, Controls, Layers, Interactions, source, Overlays } from 'react-openlayers';
import { Units } from 'ol/control/ScaleLine';

import MapButton from './buttons/MapButton';

import { MdSatellite } from 'react-icons/md';
import { TbShovel } from 'react-icons/tb';
import { Extent, getCenter } from 'ol/extent';
import MapEvent from 'ol/MapEvent';
import BaseEvent from 'ol/events/Event';
import {
  MapCenterStorage,
  MapExtentStorage,
  MapPixelsPerMeterStorage,
  MapPointResolution,
  MapResolutionStorage,
  MapRotationStorage,
  MapSizeStorage,
  MapSmoothZoomEnabled,
  MapZoomStorage,
  MaxZoom,
} from '../../db/local_storage';
import EventBus from '../../EventBus';
import { Coordinate } from 'ol/coordinate';
import { getPointResolution } from 'ol/proj';
import {
  dispatchCloseSampleNeedsUpdate,
  dispatchMapExtentUpdated,
  dispatchMapPointResolutionUpdated,
  dispatchMapResolutionUpdated,
  dispatchPixelsPerMeterUpdated,
  dispatchZoomUpdated,
  MAP_EVENTS,
} from '../../mapEvents';

interface BaseMapProps {
  absoluteOverlays?: React.ReactNode[];
  trackingButton?: React.ReactNode;
  overlays?: React.ReactNode[];
  layers: React.ReactNode[];
  buttons: React.ReactNode[];
  secondRowElements?: React.ReactNode[];
  enableSoilMap?: boolean;
  robotConnected?: boolean;
}

interface BaseMapState {
  sourceUrl: string;
  tracking: boolean;
  soilMapEnabled: boolean;
  zoom?: { value: number };
  center: { value: Coordinate };
  rotation?: { value: number };
  extent?: { value: Extent };
}

export default class BaseMap extends PureComponent<BaseMapProps, BaseMapState> {
  SOIL_MAP =
    'https://casoilresource.lawr.ucdavis.edu/cgi-bin/mapserv?map=/soilmap2/website/gmap/mapunit_wms.map&layers=ssurgo&layers=ssa&mode=tile&tilemode=gmap&tile={x}+{y}+{z}';
  GOOGLE_SAT = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';
  GOOGLE_ROAD = 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}';

  ATTRIBUTION = '@ Google';
  PROJECTION = 'EPSG:3857';
  INITIAL_ROTOATION = 0;
  renderCount = 0;

  constructor(props: BaseMapProps) {
    super(props);

    this.state = {
      sourceUrl: this.GOOGLE_SAT,
      tracking: false,
      soilMapEnabled: false,
      center: { value: MapCenterStorage.get() },
      rotation: MapRotationStorage.get(),
      extent: { value: MapExtentStorage.get() },
      zoom: MapZoomStorage.get(),
    };

    this.switchSource = this.switchSource.bind(this);
    this.handleMapMove = this.handleMapMove.bind(this);
  }

  /**
   * Switch the map source
   */
  switchSource() {
    if (this.state.sourceUrl === this.GOOGLE_SAT) {
      this.setState({ sourceUrl: this.GOOGLE_ROAD });
    } else {
      this.setState({ sourceUrl: this.GOOGLE_SAT });
    }
  }

  componentDidMount() {
    EventBus.on(MAP_EVENTS.SET_CENTER, this.centerUpdated);
    EventBus.on(MAP_EVENTS.SET_ROTATION, this.rotationUpdated);
    EventBus.on(MAP_EVENTS.SET_ZOOM, this.setZoom);
    EventBus.on(MAP_EVENTS.SET_FIT, this.updateFit);
  }

  componentWillUnmount() {
    EventBus.remove(MAP_EVENTS.SET_CENTER, this.centerUpdated);
    EventBus.remove(MAP_EVENTS.SET_ROTATION, this.rotationUpdated);
    EventBus.remove(MAP_EVENTS.SET_ZOOM, this.setZoom);
    EventBus.remove(MAP_EVENTS.SET_FIT, this.updateFit);
  }

  setZoom = (zoom: number) => {
    if (!MapSmoothZoomEnabled.get()) {
      // instant zoom
      this.setState({ zoom: { value: zoom } });
    } else {
      // staggered zoom
      const currentZoom = this.state.zoom?.value;
      if (currentZoom === undefined) {
        return;
      }
      const diff = zoom - currentZoom;
      const steps = 100;
      const delay_s = 2;
      const delay_ms = delay_s * 1000;
      const ms_per_loop = delay_ms / steps;
      let i = 0;
      const interval = setInterval(() => {
        const newZoom = currentZoom + (diff / steps) * i;
        this.setState({ zoom: { value: newZoom } });
        i++;
        if (i > steps) {
          clearInterval(interval);
        }
      }, ms_per_loop);
    }
  };

  updateFit = (extent: Extent) => {
    this.setState({ extent: { value: extent } });
  };

  centerUpdated = (center: Coordinate) => {
    this.setState({ center: { value: center } });
  };

  rotationUpdated = (rotation: number) => {
    this.setState({ rotation: { value: rotation } });
  };

  /**
   * Handle for map onmoveend that grabs the center and zoom of map view and saves it to localstorage
   * @param {*} event
   */
  handleMapMove(event: MapEvent | BaseEvent) {
    if (!('map' in event)) return;
    const view = event.map.getView();
    if (!view) {
      return;
    }
    const extent = view.calculateExtent();
    const resolution = view.getResolution();

    const StateUpdates: Partial<BaseMapState> = {};

    if (JSON.stringify(extent) !== JSON.stringify(MapExtentStorage.get())) {
      MapExtentStorage.set(extent);
      dispatchMapExtentUpdated(extent);
    }

    let zoomUpdated = false;
    let zoom = view.getZoom();
    if (!!zoom && zoom !== MapZoomStorage.get().value) {
      MapZoomStorage.set({ value: zoom });
      StateUpdates.zoom = { value: zoom };
      dispatchZoomUpdated(zoom);
      zoomUpdated = true;
    }

    const center = view.getCenter();
    if (center) {
      MapCenterStorage.set(center);
    }

    MapSizeStorage.set(event.map.getSize());
    if (!resolution) {
      if (zoomUpdated) {
        dispatchCloseSampleNeedsUpdate();
      }

      return;
    }

    if (resolution !== MapResolutionStorage.get()) {
      MapResolutionStorage.set(resolution);
      dispatchMapResolutionUpdated(resolution);
    }

    const resCenter = getPointResolution('EPSG:3857', 1, getCenter(extent));
    if (resCenter !== MapPointResolution.get()) {
      MapPointResolution.set(resCenter);
      dispatchMapPointResolutionUpdated(resCenter);
    }

    const pixelsPerMeter = 1 / resolution / resCenter;
    if (pixelsPerMeter !== MapPixelsPerMeterStorage.get()) {
      MapPixelsPerMeterStorage.set(pixelsPerMeter);
      dispatchPixelsPerMeterUpdated(pixelsPerMeter);
    }

    // @ts-ignore
    this.setState(StateUpdates);

    // Basically when we change zoom or move we have to
    // redraw core circles because they are drawn with a
    // relationship to the pixel size to physical distance ratio of the map.
    // We need to call this below MapPixelsPerMeterStorage.set(pixelsPerMeter);
    // because KMLLayer (and maybe other files) use pixelsPerMeter storage value.
    // If we call dispatchCoresNeedUpdate before setting this value, KMLLayer uses the old
    // pixelsPerMeter value and ends up drawing circles with wrong scale.
    // TODO: refactor - we shouldn't rely on the order of those events firing.
    // Maybe come up with one combined event with all up-to-date values.
    if (zoomUpdated) {
      dispatchCloseSampleNeedsUpdate();
    }
  }

  render() {
    this.renderCount++;
    // console.log(this.renderCount,
    //     'extent', JSON.stringify(this.state.extent),
    //     'zoom', this.state.zoom?.value,
    //     'center', this.state.center.value,
    //     'rotation', this.state.rotation?.value);
    return (
      <Grid container direction="row">
        <Grid container direction="row" style={{ maxHeight: 40, overflow: 'hidden' }}>
          <Paper
            style={{ width: '100%', height: 40, display: 'flex', overflow: 'auto', paddingRight: 15 }}
            elevation={5}
            className={'scroll-show'}
          >
            <MapButton
              tooltip={this.state.sourceUrl === this.GOOGLE_SAT ? 'Switch to Road Map' : 'Switch to Satellite Map'}
              key={'SwitchMapSourceButton'}
              onClick={this.switchSource}
            >
              <MdSatellite size={22} />
            </MapButton>
            {this.props.enableSoilMap && (
              <MapButton
                tooltip={'Enable Soil Map'}
                key={'EnableSoilMapButton'}
                toggled={this.state.soilMapEnabled}
                onClick={() => {
                  this.setState({ soilMapEnabled: !this.state.soilMapEnabled });
                }}
              >
                <TbShovel size={22} />
              </MapButton>
            )}
            {this.props.buttons}
          </Paper>
        </Grid>
        {this.props.secondRowElements && (
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            direction="row"
            style={{ height: 60, overflow: 'hidden' }}
          >
            {this.props.secondRowElements}
          </Grid>
        )}
        {this.props.absoluteOverlays}
        {this.props.trackingButton}
        <Grid container>
          {/* 
                    // @ts-ignore */}
          <MapReact
            style={{
              height: this.props.secondRowElements ? 'calc(100vh - 100px)' : 'calc(100vh - 80px)',
              width: '100%',
            }}
            view={{
              projection: this.PROJECTION,
              maxZoom: MaxZoom.get(),
            }}
            center={this.state.center}
            rotation={this.state.rotation}
            zoom={this.state.zoom}
            fit={this.state.extent}
            onMoveend={this.handleMapMove}
            renderer={'webgl'}
          >
            <Overlays>{this.props.overlays}</Overlays>
            {/*
                        // @ts-ignore */}
            <Controls>
              {/*
                            // @ts-ignore */}
              <control.ScaleLineReact units={Units.US} />
              {/* // this was removed because no one was passing in this prop */}
              {/* {this.props.controls} */}
            </Controls>
            <Layers>
              {/*
                            // @ts-ignore */}
              <layer.TileReact>
                {/*
                                // @ts-ignore */}
                <source.XYZReact url={this.state.sourceUrl} attributions={this.ATTRIBUTION} />
              </layer.TileReact>
              {this.state.soilMapEnabled && (
                <layer.TileReact>
                  <source.XYZReact url={this.SOIL_MAP} attributions={this.ATTRIBUTION} />
                </layer.TileReact>
              )}
              {this.props.layers}
            </Layers>
            {/*
                        // @ts-ignore */}
            <Interactions>
              {/* // this was removed because no one was passing in this prop */}
              {/* {this.props.interactions} */}
            </Interactions>
          </MapReact>
        </Grid>
      </Grid>
    );
  }
}
