import { JobLoader, JobChecker } from '@rogoag/kml-error-checker';
import { orderBy, flatten } from 'lodash';
import { alertWarn } from '../../../../alertDispatcher';
import {
  CorePoint,
  GridPatterns,
  Job,
  Mission,
  PathPoint,
  Sample,
  SoilCore,
  SoilDumpEvent,
  Waypoint,
  Zone,
} from '../../../../db';
import logger from '../../../../logger';
import { errorTone } from '../../../../alertTones';
import { IKMLElement, KMLDocument } from '../../../../kml';
import { BoundaryChangeType } from '../../../../types/types';
import { checkPointCoordinates } from './checks';
import { cleanSet } from '../../../../utils';
import { calculate_path_distance } from '../../../../db_ops/mission_ops';
import SpecKmlLoader from './SpecKmlLoader';
import { SampleBoxDeSerialized, SoilDumpEventCreateParams } from '../../../../db/types';

class MissionKmlLoader {
  private mission: Mission;
  private errorMessages: string[] = [];
  private doc: IKMLElement;
  private extendedData: { [key: string]: any };
  private deSerializedBoxes: SampleBoxDeSerialized[];

  async load(
    text: string,
    jobId?: string,
    { sampling = false, ignore_loading_errors = false } = {},
  ): Promise<[Mission, boolean]> {
    const loadKmlTimer = logger.start('loadKML', { jobId });

    let [mission, errors, active] = await this.loadKml(text, jobId, { sampling, ignore_loading_errors });
    if (!mission) {
      await logger.stop(loadKmlTimer, { errors });

      throw new Error('Error while loading kml');
    }

    await logger.stop(loadKmlTimer, { success: true, file: mission.name });

    for (const [i, error] of errors.entries()) {
      console.warn('MissionKmlLoader -> load error', error);
      if (i < 10) {
        alertWarn(error);
      } else if (i === 10) {
        alertWarn('Too many errors. Ignoring remaining.');
      }
    }

    return [mission, active];
  }

  private async loadKml(
    text: string,
    jobId?: string,
    { sampling = false, ignore_loading_errors = false } = {},
  ): Promise<[Mission, string[], boolean]> {
    await this.verifyKml(text, ignore_loading_errors);

    const kmlDoc = new KMLDocument(text);
    const doc = kmlDoc.find('Document');
    if (!doc) {
      throw new Error('No Document found in KML');
    }

    this.doc = doc;
    this.extendedData = doc.getExtendedData();

    console.debug('MissionKmlLoader -> loadKml -> ED ALL', this.extendedData);

    this.loadMission(jobId);
    this.deSerializeBoxes();
    await this.loadSpecs();
    this.loadJob();
    await this.loadPolygons();

    this.loadDumpEvents();

    const samples: Sample[] = await this.loadPoints();

    // if no pullin point in the mission set it to the location of the first sample
    if (this.mission.pullin_lat === 0.0 && this.mission.pullin_lon === 0.0) {
      this.setPullInPoint();
    }

    // if samples do not specify order, create the order numerically || alphanumerically
    this.setSamplesOrder();
    this.applyDefaultSpecsToSamplesWithNoSpec(samples);

    // warn if the mission has more than one sampling spec
    if (cleanSet(this.mission.getSamplingSpecs().filter((sel) => sel.pattern_type !== GridPatterns.ZONE)).length > 1) {
      this.errorMessages.push('Warning: this grid mission has more than one sampling spec');
    }

    this.loadNavigationPath();
    this.mission.path_distance = calculate_path_distance(this.mission);

    // set last_modifed last to make sure that it is not updated on every load
    // everything after this are legitimate changes to the mission
    this.mission.last_modified = this.extendedData.last_modified
      ? parseFloat(this.extendedData.last_modified)
      : Date.now() / 1000;

    this.fixPolygonIDs();
    await this.normalizeZones();
    await this.loadSpecialPaths(sampling);
    this.checkSamplesOrder();

    // call 'to_ros_msg' to re-calculate the nav checksum
    this.mission.to_ros_msg();

    // return loaded mission && error/warning messages
    return [this.mission, this.errorMessages, JSON.parse(this.extendedData.active || 'false') as boolean];
  }

  private deSerializeBoxes(): SampleBoxDeSerialized[] {
    let boxes: any;
    try {
      boxes = JSON.parse(this.extendedData.boxes || '[]');
    } catch (err) {
      console.log('could not parse boxes', err);
      this.deSerializedBoxes = [];

      return this.deSerializedBoxes;
    }

    if (!Array.isArray(boxes)) {
      this.deSerializedBoxes = [];

      return this.deSerializedBoxes;
    }

    this.deSerializedBoxes = boxes;

    return this.deSerializedBoxes;
  }

  private loadDumpEvents() {
    let dumps: any[] = [];
    try {
      dumps = JSON.parse(this.extendedData.dumps || '[]');
    } catch (err) {
      console.log('could not parse dumps', err);

      return;
    }

    if (!Array.isArray(dumps)) {
      return;
    }

    dumps
      .sort((d1, d2) => d1.timestamp - d2.timestamp)
      .forEach((dump: SoilDumpEventCreateParams) => {
        const lastDump = this.mission.getLastDump();

        SoilDumpEvent.createDump({
          Mission_id: this.mission.instance_id,
          guid: dump.guid,
          timestamp: dump.timestamp,
          lat: dump.lat,
          lon: dump.lon,
          heading: dump.heading,
          PreviousDump_id: lastDump?.instance_id,
        });
      });
  }

  private async verifyKml(text: string, ignore_loading_errors: boolean = false) {
    if (ignore_loading_errors) {
      return;
    }

    let loadKmlStep = 0;
    try {
      const loader = new JobLoader({ data: text, filename: '.kml' });
      loadKmlStep = 1;
      const job = await loader.parse();
      loadKmlStep = 2;
      const jobChecker = new JobChecker(job);
      loadKmlStep = 3;
      const errors = jobChecker.validateRunnable();
      loadKmlStep = 4;

      if (errors.length) {
        errorTone();
      }

      for (const error of errors) {
        alertWarn(`Found mission problem: ${JSON.stringify(error)}`);
      }
    } catch (err) {
      errorTone();

      if (err && typeof err === 'object' && 'rogo' in err && 'message' in err) {
        alertWarn(`Found mission problem (${loadKmlStep}): ${err.message}`);
      } else {
        console.error('MissionKmlLoader -> loadKml error', err);
        console.error(JSON.stringify(err));
        alertWarn(`Unknown error hit during validation ${loadKmlStep} ${JSON.stringify(err)}`);
      }
    }
  }

  private loadMission(jobId: string | undefined) {
    this.mission = Mission.create();

    const docName = this.doc.find('name');
    const docDescription = this.doc.find('description');
    this.mission.name = docName?.getText() || '';
    this.mission.job_id = jobId || this.extendedData.job_id || '';
    this.mission.description = docDescription ? docDescription.getText() : '';
    this.mission.sample_date = parseFloat(this.extendedData.sample_date || '0.0');
    this.mission.nav_checksum = this.extendedData.nav_checksum;
    this.mission.cores = this.extendedData.cores || '';
  }

  private applyDefaultSpecsToSamplesWithNoSpec(samples: Sample[]) {
    const defaultSpec = this.mission.getPrimarySpec();

    samples
      .filter((sel) => !sel.getSamplingSpec())
      .forEach((sample) => {
        sample.SamplingSpec_id = defaultSpec.instance_id;
      });
  }

  private checkSamplesOrder() {
    const waypoints = cleanSet(this.mission.getWaypoints());
    let order = 0;
    let current_sample_id: string | undefined = undefined;
    for (const waypoint of orderBy(waypoints, ['waypoint_number'], ['asc'])) {
      const corepoint = waypoint.getCorePoint();
      const soilcore = corepoint ? corepoint.getSoilCore() : null;
      if (soilcore && soilcore.sample_id !== current_sample_id) {
        // TODO this should potentially be inside the sample check block...
        order += 1;
        current_sample_id = soilcore.sample_id;
        const sample = soilcore.getSample();
        if (sample) {
          sample.order = order;
        }
      }
    }
  }

  private async loadSpecialPaths(sampling: boolean) {
    const paths = this.doc.findAll(
      'Placemark',
      true,
      (sel) => !!sel.find('LineString') && (!sel.find('name') || sel.find('name')?.getText() !== 'path'),
    );

    for (const path of paths) {
      const pathNameElement = path.find('name');
      const name = pathNameElement ? pathNameElement.getText() : '';
      const nameLowerCased = name.toLowerCase();
      if (nameLowerCased !== 'order' && nameLowerCased !== 'cores') {
        this.errorMessages.push(`Warning: ignoring unsupported path '${name}'`);

        continue;
      }

      const pathCoordinates = path.find('coordinates', true);
      const coords = pathCoordinates ? pathCoordinates.getText() : '';
      if (nameLowerCased === 'order') {
        const err = await this.mission.reorder(
          coords
            .trim()
            .split(' ')
            .map((coordSet) => coordSet.split(',').map((coord) => parseFloat(coord))),
          false,
          sampling,
        );
        this.errorMessages = this.errorMessages.concat(err);
      } else if (nameLowerCased === 'cores') {
        await this.mission.create_zone_cores(
          coords
            .trim()
            .split(' ')
            .map((coordSet) => coordSet.split(',').map((coord) => parseFloat(coord))),
        );
      }
    }
  }

  private async normalizeZones() {
    for (const zone of cleanSet(this.mission.getZones())) {
      await zone.normalize();
    }
  }

  private fixPolygonIDs() {
    for (const polyBnd of cleanSet(
      flatten(this.mission.getZones().map((zone) => zone.getOuterZoneBoundarys())).filter((sel) => sel.poly_id < 0),
    )) {
      const outerZone = polyBnd.getOuterZone();
      if (!outerZone) {
        continue;
      }

      const ibs = cleanSet(outerZone.getInnerZoneBoundarys().filter((sel) => sel.poly_id === polyBnd.poly_id));
      polyBnd.poly_id = this.mission.assign_id();
      for (const ib of ibs) {
        ib.poly_id = polyBnd.poly_id;
      }
    }
  }

  private loadNavigationPath() {
    const path = this.doc.find(
      'Placemark',
      true,
      (sel) => !!sel.find('name') && sel.find('name')?.getText() === 'path',
    );
    if (!path) {
      return;
    }

    const coordRegex = /((-?\d*\.?\d*),(-?\d*\.?\d*),(-?\d*\.?\d*))/g;

    const coords: string = path.find('coordinates', true) ? path.find('coordinates', true)?.getText() || '' : '';
    const coordMatch = coords.match(coordRegex);
    if (coordMatch) {
      const allCores = cleanSet(
        flatten(
          flatten(this.mission.getSampleSites().map((site) => site.getSamples())).map((sample) =>
            sample.getSoilCores(),
          ),
        ),
      );

      // TODO we should also be able to unpack these coordinates based on the regex groups
      for (const [i, coordSet] of coordMatch.entries()) {
        if (!coordSet) {
          continue;
        }
        const waypoint = Waypoint.create();
        waypoint.Mission_id = this.mission.instance_id;
        waypoint.waypoint_number = i + 1;
        waypoint.lat = parseFloat(coordSet.split(',')[1]);
        waypoint.lon = parseFloat(coordSet.split(',')[0]);

        const core = allCores.find((sel) => sel.waypoint_index === i);
        if (core) {
          const corePoint = CorePoint.create();
          corePoint.Waypoint_id = waypoint.instance_id;
          corePoint.SoilCore_id = core.instance_id;
        } else {
          const pathPoint = PathPoint.create();
          pathPoint.Waypoint_id = waypoint.instance_id;
        }
      }
    } else {
      this.errorMessages.push('ERROR: no coordinates found for path');
    }
  }

  private setSamplesOrder() {
    let orderlessSamples = cleanSet(
      flatten(this.mission.getSampleSites().map((sample) => sample.getSamples())).filter((sel) => sel.order === 0),
    );

    try {
      orderlessSamples = orderBy(
        orderlessSamples,
        [
          (sel) => {
            if (isNaN(parseInt(sel.sample_id))) {
              throw new Error('cannot parse int');
            }
            return sel.sample_id;
          },
        ],
        ['asc'],
      );
    } catch (err) {
      orderlessSamples = orderBy(orderlessSamples, ['sample_id'], ['asc']);
    }
    for (const [i, sample] of orderlessSamples.entries()) {
      sample.order = i + 1;
    }
  }

  private setPullInPoint() {
    const firstSample = cleanSet(
      orderBy(flatten(this.mission.getSampleSites().map((site) => site.getSamples())), ['order'], ['asc']),
    )[0];

    if (!firstSample) {
      return;
    }

    if (firstSample.getSampleSite().getSampleCentroid()) {
      this.mission.pullin_lat = firstSample.getSampleSite()?.getSampleCentroid()?.lat || 0;
      this.mission.pullin_lon = firstSample.getSampleSite()?.getSampleCentroid()?.lon || 0;
    } else {
      const firstCore = cleanSet(orderBy(firstSample.getSoilCores(), ['core_number'], ['asc']))[0];
      if (firstCore) {
        this.mission.pullin_lat = firstCore.lat;
        this.mission.pullin_lon = firstCore.lon;
      }
    }
  }

  private async loadPoints() {
    const points = this.doc.findAll('Placemark', true, (sel) => !!sel.find('Point'));
    const samples: Sample[] = [];

    for (const point of points) {
      const nameElement = point.find('name');
      const name = nameElement ? nameElement.getText() : '';

      if (name.toLowerCase() === 'pullin') {
        const [lat, lon] = checkPointCoordinates(point, this.errorMessages);
        this.mission.pullin_lat = lat;
        this.mission.pullin_lon = lon;
      } else if (name.match(/^[a-zA-Z0-9]+$/) && !point.getExtendedData().core_location) {
        // sample
        const [sample, err] = await Sample.load_kml(point, this.mission, this.deSerializedBoxes);
        samples.push(sample);
        this.errorMessages = this.errorMessages.concat(err);
      } else if (name.match(/^([a-zA-Z0-9]+)([,.;-]([1-9][0-9]*))?$/)) {
        // core
        const [, err] = await SoilCore.load_kml(point, this.mission, samples, this.deSerializedBoxes);
        this.errorMessages = this.errorMessages.concat(err);
      } else {
        this.errorMessages.push(`Warning: ignoring unsupported point '${name}'`);
      }
    }

    return samples;
  }

  private async loadPolygons() {
    const polys = this.doc.findAll('Placemark', true, (sel) => !!sel.find('Polygon'));

    for (const poly of polys) {
      const kmlPoly = poly.find('name');
      const name = kmlPoly ? kmlPoly.getText() : '';

      if (
        ['field', 'unsafe', 'slow', 'pullin', 'collected field', 'pullin_zone'].includes(name.toLowerCase()) ||
        name.match(/^[a-zA-Z0-9]+$/)
      ) {
        const [, err] = await Zone.load_kml(poly, this.mission);
        this.errorMessages = this.errorMessages.concat(err);
      } else {
        this.errorMessages.push(`Warning: ignoring unsupported zone '${name}'`);
      }
    }
  }

  private loadJob() {
    const job = Job.create();

    job.Mission_id = this.mission.instance_id;
    job.attachment_id = this.extendedData.attachment_id || '';
    job.test_package = this.extendedData.test_package || '';
    job.event_id = this.extendedData.event_id || '';
    job.client = this.extendedData.client || '';
    job.grower = this.extendedData.grower || '';
    job.grower_id = this.extendedData.grower_id || '';
    job.farm = this.extendedData.farm || '';
    job.field = this.extendedData.field || '';
    job.billing_account = this.extendedData.billing_account || '';
    job.sampling_company_name = this.extendedData.sampling_company_name || '';
    job.sampling_company_id = this.extendedData.sampling_company_id || '';
    job.response_email = this.extendedData.response_email || '';
    job.lab_name = this.extendedData.lab_name || '';
    job.lab_code = this.extendedData.lab_code || 0;
    job.tilled_field_at_sampling = JSON.parse(this.extendedData.tilled_field_at_sampling || 'false');
    job.in_field_notes_ops = this.extendedData.in_field_notes_ops || '';
    job.boundary_change_type = this.extendedData.boundary_change_type || BoundaryChangeType.None;
    job.boundary_change_notes = this.extendedData.boundary_change_notes || '';
    job.lab_address = this.extendedData.lab_address || '';
    job.lab_primary_delivery = this.extendedData.lab_primary_delivery || '';
    job.field_id = this.extendedData.field_id || '';
    job.add_on_freq = this.extendedData.add_on_freq ? parseInt(this.extendedData.add_on_freq) : 1;
    job.sample_order_type = this.extendedData.sample_order_type || '';
    job.lab_submittal_id = this.extendedData.lab_submittal_id || '';
    job.lab_qrs_required = JSON.parse(this.extendedData.lab_qrs_required || 'false');
    job.auto_renumber = JSON.parse(this.extendedData.auto_renumber || 'false');
    job.lab_instructions = this.extendedData.lab_instructions || '';
    job.submitter_notified_id = this.extendedData.submitter_notified_id || '';
    job.sites_type = this.extendedData.sites_type || '';
    job.job_flags = JSON.parse(this.extendedData.job_flags || '[]');
    job.required_fields = this.extendedData.required_fields || '';
    job.allowed_to_renumber = JSON.parse(this.extendedData.allowed_to_renumber || 'false');
    job.allowed_to_create = JSON.parse(this.extendedData.allowed_to_create || 'false');
    job.allowed_to_move = JSON.parse(this.extendedData.allowed_to_move || 'false');
    job.core_diameter = this.extendedData.core_diameter || '0.75 in';
    job.field_aligned_sample_path = JSON.parse(this.extendedData.field_aligned_sample_path || 0);
    job.manual_hole_depth_measurement = JSON.parse(this.extendedData.manual_hole_depth_measurement || 'false');
    // job.manual_plunge_depth_measurement = JSON.parse(ed.manual_plunge_depth_measurement || 'false');
    job.manual_core_length_measurement = JSON.parse(this.extendedData.manual_core_length_measurement || 'false');
    job.manual_core_loss_measurement = JSON.parse(this.extendedData.manual_core_loss_measurement || 'false');
    job.use_original_sample_id = JSON.parse(this.extendedData.use_original_sample_id || 'false');
    job.plot_settings = this.extendedData.plot_settings || '';
    job.plot_mission = JSON.parse(this.extendedData.plot_mission || 'false');
    job.disable_core_line_alteration = JSON.parse(this.extendedData.disable_core_line_alteration || 'false');
    job.entrance_interview_residue = this.extendedData.entrance_interview_residue || '';
    job.entrance_interview_crop = this.extendedData.entrance_interview_crop || '';
    job.sampling_tolerance_ft = JSON.parse(this.extendedData.sampling_tolerance_ft || '0');
    job.sampling_type_special = this.extendedData.sampling_type_special || '';
    job.implement_centerted_navigation = JSON.parse(this.extendedData.implement_centerted_navigation || 'false');
    job.enable_manual_drive_aid = JSON.parse(this.extendedData.enable_manual_drive_aid || 'false');
    job.auto_zoom_near_sample = JSON.parse(this.extendedData.auto_zoom_near_sample || 'false');
    job.strict_core_enforcement = JSON.parse(this.extendedData.strict_core_enforcement || 'false');
    job.lab_short_name = this.extendedData.lab_short_name || '';
  }

  async loadSpecs() {
    const samplingSpecLoader = new SpecKmlLoader(this.mission, this.doc, this.extendedData);

    await samplingSpecLoader.load();
  }
}

export default MissionKmlLoader;
