import { Paper } from '@material-ui/core';
import React, { PropsWithChildren, PureComponent } from 'react';

interface LayerCheckContainerProps {
  customClassName?: string;
}

export default class LayerCheckContainer extends PureComponent<PropsWithChildren<LayerCheckContainerProps>> {
  render() {
    const { customClassName, children } = this.props;
    return (
      <Paper
        square
        className={customClassName}
        style={{ position: 'absolute', top: 85, right: 10, zIndex: 1000, width: 200, padding: 5 }}
      >
        {children}
      </Paper>
    );
  }
}
