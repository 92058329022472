import React, { PureComponent } from 'react';
import { getCurrentMission, getCurrentSession } from '../dataModelHelpers';

import MapMakerMap from './map/MapMakerMap';
import MapsMissionView from './mission/MapsMissionView';

import { AutoGenHelper } from './utils';

import { alertSuccess, alertError } from '../alertDispatcher';
import logger from '../logger';

import Airtable from '../airtable';
import { AutoGenHelperParams, OnLoading, SettingsAccessLevel } from '../types/types';
import { AirtableRecord, Attachment, SampleBox } from '../db';
import SlideoutTextBox from './mission/sampling/OperatorNotes';
import { convertProjection4329, wait } from '../utils';
import EventBus from '../EventBus';
import { dispatchMissionUpdated, MISSION_EVENTS } from '../missionEvents';
import { genPullinGeoJSON, updateBoundaryProperties } from './schedule/helpers/geojson';
import { Feature, GeometryObject, LineString } from '@turf/helpers';
import * as Sentry from '@sentry/react';
import MapsExitInterview from './mission/maps/MapsExitInterview';
import { MAP_EVENTS } from '../mapEvents';
import { UITab } from './helpers/task';
import { Coordinate } from 'ol/coordinate';
import { Jobs } from '@rogoag/airtable';

interface MapsTaskProps {
  tab: string;
  task: string;
  onLoading: OnLoading;
  accessLevel: SettingsAccessLevel;
  missionActive: string;
  updateCurrentTab: (tab: UITab) => void;
}

interface MapsTaskState {
  autogenHelper: AutoGenHelperParams | undefined;
  loadingSchedule: boolean;
  scheduleItems: AirtableRecord<Jobs>[];
  mapmakingRulesDialogOpen: boolean;
  mapmakingExitInterviewOpen: boolean;
  mapmakingRules: string;
  scheduleFeatures: Feature<GeometryObject>[];
}

export default class MapsTask extends PureComponent<MapsTaskProps, MapsTaskState> {
  REVIEW_VIEW_NAME = 'App View - Review Missions';
  lastMissionID: string;

  constructor(props: MapsTaskProps) {
    super(props);

    this.state = {
      loadingSchedule: false,
      scheduleItems: [],
      autogenHelper: undefined,
      mapmakingRulesDialogOpen: true,
      mapmakingExitInterviewOpen: false,
      mapmakingRules: '',
      scheduleFeatures: [],
    };
  }

  async componentDidMount() {
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.setMapmakingNotes);
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.generateGeoJSON);
    const mission = getCurrentMission();
    const job = mission?.getJob();
    if (job) {
      this.setState({ mapmakingRules: job.map_making_rules });
    }
    await this.loadSchedule();
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.setMapmakingNotes);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.generateGeoJSON);
  }

  setMapmakingNotes = async () => {
    const mission = getCurrentMission();
    const job = mission?.getJob();
    const mapmakingRules = job?.map_making_rules || '';
    this.setState({
      mapmakingRulesDialogOpen: true,
      mapmakingRules,
    });
  };

  setAutogenHelper = (autogenHelper: AutoGenHelperParams | undefined) => {
    this.setState({ autogenHelper });
  };

  closeNotesDialog = () => {
    this.setState({ mapmakingRulesDialogOpen: false });
    //localStorage.setItem('notesClosed', "true");
  };

  toggleNotesDialog = () => {
    this.setState({ mapmakingRulesDialogOpen: !this.state.mapmakingRulesDialogOpen });
  };

  openExitInterview = () => {
    this.setState({ mapmakingExitInterviewOpen: true });
  };

  loadMissionMaps = async (idOrJsonPayload: string) => {
    const trimmedPayload = idOrJsonPayload.trim();
    let id = trimmedPayload;
    if (!trimmedPayload.startsWith('rec')) {
      let payload: { jobId: string };
      try {
        payload = JSON.parse(trimmedPayload);
      } catch (e) {
        alertError('Wrong JSON');

        return;
      }
      id = payload.jobId;
    }

    console.log(`loadMissionMaps ${id}`);
    Sentry.setTag('job_info', id);

    const session = getCurrentSession();
    if (!session) {
      return;
    }

    await this.props.onLoading(async () => {
      await SampleBox.logBoxes('BOXES_BEFORE_LOADING_MISSION_IN_MAPS');
      const loadedMission = await session.loadMission(id, false, this.setAutogenHelper);
      await SampleBox.logBoxes('BOXES_AFTER_LOADING_MISSION_IN_MAPS');

      if (loadedMission) {
        this.props.updateCurrentTab(UITab.MAP);

        EventBus.dispatch(MAP_EVENTS.ZOOM_TO_MISSION);
        dispatchMissionUpdated();
      }
    });
  };

  /**
   * Loads the jobs from the schedule to be displayed
   * @param {boolean} forceRefresh Force airtable to pull from airtable api, pulls from cache by default
   */
  loadSchedule = async (forceRefresh?: boolean) => {
    const logTimer = logger.start('LOAD_SCHEDULE', { forceRefresh });
    this.setState({ loadingSchedule: true });
    try {
      console.time('MapsTask loadSchedule');
      const jobsQuery = await Airtable.search<Jobs>('Jobs', this.REVIEW_VIEW_NAME, '', false);
      console.timeLog('MapsTask loadSchedule', 'jobsQuery');

      const shouldRefreshJobs = forceRefresh || jobsQuery?.getRecords().length === 0;

      if (shouldRefreshJobs) {
        await jobsQuery?.refresh({ waitForAttachments: false });
        console.timeLog('MapsTask loadSchedule', 'jobsQuery.refresh');
      }

      const jobs = jobsQuery?.getRecords() || [];
      console.timeLog('MapsTask loadSchedule', 'jobsQuery.getRecords');
      console.log(`MapsTask jobs.length = ${jobs.length}`);

      const labsQuery = await Airtable.search('Labs', 'App View - Labs', ''); // search for all of the labs
      console.timeLog('MapsTask loadSchedule', 'labsQuery');

      const shouldRefreshLabs = forceRefresh || labsQuery?.getRecords().length === 0;
      if (shouldRefreshLabs) {
        await labsQuery?.refresh({ waitForAttachments: false });
        console.timeLog('MapsTask loadSchedule', 'labsQuery.refresh');
      }

      console.log(`MapsTask jobs.length=${jobs.length}`);
      this.setState({ scheduleItems: jobs });

      await this.generateGeoJSON(forceRefresh);
      console.timeEnd('MapsTask loadSchedule');
      if (shouldRefreshJobs) {
        alertSuccess('Schedule successfully refreshed');
      }

      await logger.stop(logTimer, { success: true });
    } catch (err) {
      console.error('MapsTask - loadSchedule - error', err);
      alertError('Schedule (m) failed to load. Check internet.');
      await logger.stop(logTimer, { success: false });
    }
    this.setState({ loadingSchedule: false });
  };

  generateGeoJSON = async (forceRefresh = false) => {
    const items = this.state.scheduleItems;
    let features: Feature<GeometryObject>[] = [];
    const mission = getCurrentMission();
    if (mission || this.lastMissionID) {
      let currentMissionId: string | undefined = undefined;
      if (mission) {
        currentMissionId = mission.job_id;
      } else {
        currentMissionId = this.lastMissionID;
      }
      const currentMission = items.find((item) => item.get('Rogo Job ID') === currentMissionId);
      if (currentMission) {
        this.lastMissionID = currentMission.get('Rogo Job ID');
      }

      let addedJobs: AirtableRecord<Jobs>[] = [];
      for (const item of items) {
        if (mission && item.get('Rogo Job ID') === mission.job_id) {
          addedJobs.push(item);
          continue;
        } else if (item.table === 'Jobs' && item.get('Shift') && currentMission) {
          const done = Boolean(item.get('Sample Date'));
          const jobShifts = item.get('Shift');
          const shift = jobShifts?.length ? jobShifts[0] : undefined;
          const currentMissionShifts = currentMission.get('Shift');
          const currentMissionShift = currentMissionShifts?.length ? currentMissionShifts[0] : undefined;
          if (!done && currentMissionShift && shift === currentMissionShift) {
            if (forceRefresh) {
              await item.refresh();
            }
            const pullinGeoJSON = genPullinGeoJSON(item);

            let result: Attachment | undefined;
            try {
              result = (await item.get_attachments('Bnd GeoJSON'))[0];
            } catch (error) {
              console.error('MapsTask - generateGeoJSON - get_attachments error', error);
            }

            if (!result) {
              await item.refresh();
              result = (await item.get_attachments('Bnd GeoJSON'))[0];
            }

            if (result) {
              const jsonText = await result.text();
              const boundaryGeoJSON = JSON.parse(jsonText);
              updateBoundaryProperties(item, boundaryGeoJSON);
              features.push(...boundaryGeoJSON.features);
            } else {
              if (!pullinGeoJSON.properties) {
                pullinGeoJSON.properties = {};
              }
              pullinGeoJSON.properties['noBoundary'] = true;
            }

            features.push(pullinGeoJSON as any);
            addedJobs.push(item);

            await wait(100);
          }
        }
      }

      if (addedJobs.length > 1) {
        const coordinates: Coordinate[] = [];

        addedJobs = addedJobs.sort((a, b) => {
          return a.get('Order')! - b.get('Order')!;
        });

        let loadedMissionIdx = -1;

        for (const job of addedJobs) {
          if (job.get('Lat') && job.get('Lon')) {
            coordinates.push(
              convertProjection4329([parseFloat(job.get('Lon').toString()), parseFloat(job.get('Lat').toString())]),
            );
          }
          if (mission && job.get('Rogo Job ID') === mission.job_id) {
            loadedMissionIdx = coordinates.length - 1;
          }
        }

        if (coordinates.length > 1) {
          const orderLine: Feature<LineString> = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates,
            },
            properties: {
              loadedMissionIdx,
              complete: addedJobs.filter((job) => job.fields['Int Mission']).length === addedJobs.length,
            },
          };

          features.push(orderLine);
        }
      }
    }

    console.log(`features has ${features.length} items`);

    this.setState({ scheduleFeatures: features.slice() });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.tab === 'mission' && (
          <MapsMissionView
            missionActive={this.props.missionActive}
            onLoading={this.props.onLoading}
            loadMissionMaps={this.loadMissionMaps}
            loadSchedule={this.loadSchedule}
            loadingSchedule={this.state.loadingSchedule}
            scheduleItems={this.state.scheduleItems}
            accessLevel={this.props.accessLevel}
            mapmakingNotesVisible={this.state.mapmakingRulesDialogOpen}
            toggleMapmakingNotes={this.toggleNotesDialog}
            lastMissionId={this.lastMissionID}
            openExitInterview={this.openExitInterview}
          />
        )}
        {this.props.tab === 'map' && (
          <MapMakerMap
            missionActive={this.props.missionActive}
            accessLevel={this.props.accessLevel}
            scheduleFeatures={this.state.scheduleFeatures}
            loadMissionMaps={this.loadMissionMaps}
            onLoading={this.props.onLoading}
            openExitInterview={this.openExitInterview}
          />
        )}

        {this.state.autogenHelper && <AutoGenHelper autogenHelper={this.state.autogenHelper} />}
        <SlideoutTextBox
          dialogOpen={Boolean(this.props.missionActive) && this.state.mapmakingRulesDialogOpen}
          message={this.state.mapmakingRules}
          closeDialog={this.closeNotesDialog}
          title="Mapmaking Notes:"
          expandedWidth="400px"
          customClass="mapmaking-notes"
        />
        {/* TODO normally I'd just let this be present and hidden all the time
                but it has a sublte dependency on being moutned with a mission loaded... */}
        {Boolean(this.props.missionActive) && (
          <MapsExitInterview
            open={this.state.mapmakingExitInterviewOpen}
            closeForm={() => this.setState({ mapmakingExitInterviewOpen: false })}
          />
        )}
      </React.Fragment>
    );
  }
}
