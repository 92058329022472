import React, { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';
import Airtable from '../../../airtable';
import { clearTimer, convertProjection4329, generateColor } from '../../../utils';
import { AirtableRecord } from '../../../db';
import { Robot } from '@rogoag/airtable';

interface LiveRobotLayerProps {
  allRobots: AirtableRecord[];
  robotRefreshRate: number;
}

interface LiveRobotLayerState {
  robotFeatures: {
    [key: string]: {
      coords: number[];
      robotHeading: number;
    };
  };
}

export default class LiveRobotLayer extends PureComponent<LiveRobotLayerProps, LiveRobotLayerState> {
  robotRefreshInterval: NodeJS.Timer;
  prevRefreshRate: number;

  constructor(props: LiveRobotLayerProps) {
    super(props);

    this.state = {
      robotFeatures: {},
    };
  }

  async componentDidMount() {
    this.startLiveRobotInterval();
    await this.loadAllRobots();
  }

  componentWillUnmount() {
    this.clearRobotInterval();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.allRobots !== prevProps.allRobots) {
      await this.loadAllRobots();
    }
  }

  /**
   * Loads all of the robot data and parses it into a nice format
   */
  async loadAllRobots() {
    const robotFeatures = {};

    for (let staleRobot of this.props.allRobots) {
      const robot = await Airtable.getRecord<Robot>(staleRobot.table, staleRobot.id);
      let coords = [0, 0];
      // these are, stupidly, strings. So we must parse them into floats
      const robotLat = robot.get('Lat');
      const robotLon = robot.get('Lon');
      const robotHeading = robot.get('Heading');
      if (robotLat && robotLon) {
        coords = convertProjection4329([parseFloat(robotLon), parseFloat(robotLat)]);
      }
      const robotName = robot.get('Name');
      if (!robotName) {
        console.error('Robot missing name', robot);
        continue;
      }

      robotFeatures[robotName] = { robotHeading, coords };
    }

    this.setState({ robotFeatures });
  }

  clearRobotInterval = () => {
    clearTimer(this.robotRefreshInterval);
  };

  /**
   * Starts the live robot interval
   */
  startLiveRobotInterval() {
    this.prevRefreshRate = this.props.robotRefreshRate;
    this.robotRefreshInterval = setInterval(async () => {
      await this.loadAllRobots();
      if (this.props.robotRefreshRate !== this.prevRefreshRate) {
        this.clearRobotInterval();
        this.startLiveRobotInterval();
      }
    }, this.props.robotRefreshRate * 1000);
  }

  render() {
    console.log(`LiveRobotLayer: ${Object.keys(this.state.robotFeatures).length} robots`);
    return (
      // for some reason, layer.Vector doesn't have a children prop? So we have to TS-ignore it...
      // @ts-ignore
      <layer.Vector zIndex={2}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          {Object.keys(this.state.robotFeatures).map((robot) => (
            <feature.PointReact
              coordinate={this.state.robotFeatures[robot].coords}
              iconOptions={{
                src: './images/location_nocolor.png',
                scale: 0.65,
                color: generateColor(robot),
                rotation: this.state.robotFeatures[robot].robotHeading
                  ? this.state.robotFeatures[robot].robotHeading * -1
                  : 0,
              }}
              key={robot}
            />
          ))}
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
