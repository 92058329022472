const DEFAULT_PROBE_CONFIG = {
  carriageDownSpeed: 100,
  carriageUpSpeed: 100,
  maxSpeed: 20,
  driveSafetyCheck: false,
  speedSafetyCheck: false,
  depthArrivalTolerance: 4,
  coreTimeout: 10000,
  dwellTime: 250,
  dwellAtTopTime: 500,
  depthOffset: 0,
};

export default DEFAULT_PROBE_CONFIG;
