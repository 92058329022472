import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    minHeight: '20px',
  },
  colorPrimary: {
    color: '',
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.grey[150],
  },
}));

interface ProgressBarProps {
  completedNumber: number;
  totalNumber: number;
  zIndex?: number;
}

export default function ProgressBar(props: ProgressBarProps) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" pt={1} pb={2} style={{ padding: 0, flex: 1, zIndex: props.zIndex }}>
      <Box mr={1} style={{ padding: 0, minWidth: '100%' }}>
        <BorderLinearProgress
          variant="determinate"
          color="secondary"
          className={classes.root}
          value={props.totalNumber ? Math.round((props.completedNumber / props.totalNumber) * 100) : 0}
          style={{ opacity: 100 }}
        />
      </Box>
      <Typography variant="body2" style={{ position: 'absolute', right: 10, color: 'white' }}>
        {`${props.completedNumber}/${props.totalNumber}`}
      </Typography>
    </Box>
  );
}
