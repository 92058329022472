import React, { PropsWithChildren, PureComponent } from 'react';

import { IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { Overlay } from 'react-openlayers';
import { Coordinate } from 'ol/coordinate';

interface MapOverlayProps {
  position: Coordinate;
  onClose: () => Promise<void>;
}

export default class MapOverlay extends PureComponent<PropsWithChildren<MapOverlayProps>> {
  render() {
    return (
      // @ts-ignore this claims that Overlay does not have a children prop but we are using it
      <Overlay position={this.props.position} stopEvent={true}>
        <div className={'ol-popup'}>
          {this.props.children}
          <div style={{ marginRight: 20 }} />
          <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={this.props.onClose}>
            <CloseIcon fontSize={'small'} style={{ color: 'black' }} />
          </IconButton>
        </div>
      </Overlay>
    );
  }
}
