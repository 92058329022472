import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    backdropFilter: (props: { backgroundColor: string; blur: string }) => props.blur,
    backgroundColor: (props: { backgroundColor: string; blur: string }) => props.backgroundColor,
  },
});

interface BlurDialogProps {
  open: boolean;
  disableEscapeKeyDown?: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  backgroundColor?: string;
}

// const STEP = 3
const RANGE = 100;

export default function BlurDialog(props: PropsWithChildren<BlurDialogProps>) {
  const transparency = RANGE;
  //const [transparency, setTransparency] = useState(RANGE); //props.backgroundColor || 'transparent');
  //const [colorDirection, setColorDirection] = useState(1);
  //const [backgroundColor]

  //if (props.backgroundColor) {
  // useEffect(() => {
  //   let interval = null;
  //   interval = setInterval(() => {
  //     if (transparency <= 0) {
  //       setColorDirection(STEP);
  //     }
  //     if (transparency >= RANGE) {
  //       setColorDirection(-STEP);
  //     }
  //     setTransparency(transparency + colorDirection);
  //   }, 5);
  //   return () => clearInterval(interval);
  // }, [transparency, colorDirection]);

  // } else {

  // }

  const backgroundColorProp = props.backgroundColor
    ? alpha(props.backgroundColor, Math.min(1, Math.abs(transparency / RANGE)))
    : 'transparent';

  const classes = useStyles({ backgroundColor: backgroundColorProp, blur: 'blur(10px)' });

  const { backgroundColor, ...validDomProps } = props;

  return <Dialog {...validDomProps} BackdropProps={{ classes: { root: classes.root } }} style={{ zIndex: 1500 }} />;
}
