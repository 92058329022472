import { PureComponent } from 'react';

import { Typography } from '@material-ui/core';

interface MapTextOnlyButtonProps {
  text: string;
}

export default class MapTextOnlyButton extends PureComponent<MapTextOnlyButtonProps> {
  render() {
    return (
      <Typography variant="body1" style={{ margin: 8 }}>
        {this.props.text}
      </Typography>
    );
  }
}
