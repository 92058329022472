import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Mission, { MissionList } from './MissionClass';

import { createRecording } from '../db_ops/zone_recording_ops';
import { ZoneRecordingType } from './ZoneTypesDatatype';
import { RecordingDevice } from '../types/types';
import { Coordinate } from 'ol/coordinate';

export default class ZoneRecording extends ObjectClassGenerator<ZoneRecording>('ZoneRecording') {
  // attributes
  #coordinates: Coordinate[];
  #type: ZoneRecordingType;
  #device: RecordingDevice;

  // relationships
  #Mission_id?: number;

  static tableName = 'ZoneRecording';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(ZoneRecording, 'coordinates');
    this.publishAttribute(ZoneRecording, 'type');
    this.publishAttribute(ZoneRecording, 'device');
    this.publishAttribute(ZoneRecording, 'Mission_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<ZoneRecording> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#coordinates = state.coordinates || [];
    this.#type = state.type || 'generic_zone';
    this.#device = state.device || '';
    this.#Mission_id = state.Mission_id;
  }

  dispose() {
    const mission = this.getMission();
    if (mission) {
      this.Mission_id = undefined;
    }

    ZoneRecording.delete(this.instance_id);
  }

  set coordinates(value: Coordinate[]) {
    this.#coordinates = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get coordinates() {
    return this.#coordinates;
  }

  set type(value: ZoneRecordingType) {
    this.#type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get type() {
    return this.#type;
  }

  set device(value: RecordingDevice) {
    this.#device = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get device() {
    return this.#device;
  }

  get name() {
    return this.getMission()?.name;
  }

  get last_modified() {
    return this.getMission()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getMission();
    if (inst && value) {
      inst.last_modified = value;
    }
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('ZoneRecording', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('ZoneRecording', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  getMission() {
    return Mission.get(this.Mission_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = ZoneRecording.query(
      (sel) =>
        sel.instance_id !== this.instance_id && sel.name === this.name && sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate zone recording found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`zone recording: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getMission()) {
      problems.push(
        `zone recording: Could not find mission instance across unconditional relationship R25: ${this.instance_id}`,
      );
    }
    return problems;
  }

  static async createRecording(zoneType: ZoneRecordingType, missionId: number) {
    return await createRecording(zoneType, missionId);
  }
}

export class ZoneRecordingList extends DataObjectList<ZoneRecording> {
  getMissions() {
    return new MissionList(...this.map((zonerecording) => zonerecording.getMission()).filter((sel) => !!sel));
  }
}
