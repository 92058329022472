import User from '../db/UserClass';
import logger from '../logger';

/**
 *
 * @param boxes this array is typed as any because this is the raw JSON that is
 * parsed from a saved box file
 */
export async function recoverUsers(usersParsed: any[]) {
  await logger.log('RECOVER_USERS', `${usersParsed.length} users to recover...`);
  const result: User[] = [];
  for (const userData of usersParsed) {
    const user = User.create();
    user.name = userData['name'];
    user.id = userData['id'];
    user.hashed_password = userData['hashed_password'];
    user.airtable_name = userData['airtable_name'];
    user.email = userData['email'];
    user.user_flags = userData['user_flags'] || [];
    // TODO do we need to recover these as well???
    user.Role_id = userData['Role_id'];
    user.user_id = userData['user_id'];
    result.push(user);
  }
  return result;
}
