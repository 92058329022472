import { flattenDeep, orderBy } from 'lodash';
import { Mission, Sample, SampleCentroid, SampleSite, SamplingTypes, SoilCore } from '../db';
import logger from '../logger';
import { cleanSet } from '../utils';
import { alertFullScreen } from '../alertDispatcher';
import { dispatchMissionUpdated } from '../missionEvents';

class PointsUpdater {
  constructor(
    private mission: Mission,
    private sampling: boolean,
  ) {}

  async update(
    pointsToUpdate: {
      id: string;
      coords: { lat: number; lon: number };
    }[],
  ) {
    await logger.log('UPDATE_POINTS', `sampling=${this.sampling}, points=${JSON.stringify(pointsToUpdate)}`);

    const t1 = performance.now();
    // order the points by sample ID
    try {
      pointsToUpdate = orderBy(
        pointsToUpdate,
        [
          (sel) => {
            if (isNaN(parseInt(sel.id))) {
              throw new Error('cannot parse int');
            }
            return parseInt(sel.id);
          },
        ],
        ['asc'],
      );
    } catch (err) {
      pointsToUpdate = orderBy(pointsToUpdate, ['id'], ['asc']);
    }

    for (const point of pointsToUpdate) {
      const pointSample = this.mission.getSamples().find((sel) => sel.sample_id === point.id);
      const pointSampleSite = pointSample?.getSampleSite();
      const sampleCentroid = pointSampleSite && pointSampleSite.getSampleCentroid();
      if (sampleCentroid) {
        this.updateCentroid(point, sampleCentroid);
      } else {
        // if we couldn't find a centroid, try to find a core (zone sampling)
        const m = point.id.toString().match(/^([a-zA-Z0-9]+)([,.;-]([1-9][0-9]*))?$/);
        if (!m) {
          continue;
        }

        const allSamples = this.mission.getSamples().filter((sample) => sample.sample_id === m[1]);
        const allCores = cleanSet(flattenDeep(allSamples.map((sel) => sel.getSoilCores())));
        const core = allCores.find((sel) => sel.core_number === parseInt(m[3]));
        if (core) {
          this.updateCore(point, core, m);
        } else {
          // if we couldn't find a centroid and we couldn't find a core, create a sample
          const sample = Sample.create();
          sample.sample_id = point.id.toString();

          const sampleSite = SampleSite.createWithCentroid(
            this.mission,
            sample.sample_id,
            point.coords.lat,
            point.coords.lon,
            '',
          );

          sample.SampleSite_id = sampleSite.instance_id;
          sample.sample_type = SamplingTypes.REGULAR;

          const samplingSpec = this.mission.getPrimarySpec();
          if (samplingSpec) {
            sample.SamplingSpec_id = samplingSpec.instance_id;
          }

          const samplesInSite = cleanSet(flattenDeep(this.mission.getSampleSites().map((site) => site.getSamples())));
          sample.order = Math.max(...samplesInSite.map((sel) => sel.order)) + 1;
        }
      }
    }

    const hasDeletes = pointsToUpdate.some((point) => point.coords.lat === 0.0 && point.coords.lon === 0.0);
    const job = this.mission.getJob();
    if (job && job.auto_renumber) {
      if (!this.sampling && hasDeletes && !this.mission.sample_date) {
        await this.mission.renumber_samples(this.sampling);
      }
    }

    const t2 = performance.now();

    // call 'to_ros_msg' to re-calculate the nav checksum
    this.mission.to_ros_msg();

    const t3 = performance.now();

    dispatchMissionUpdated();

    const t4 = performance.now();
    console.log(
      `PointsUpdater.update() took ${t2 - t1} ms to update points, ${t3 - t2} ms to re-calculate checksum, and ${t4 - t3} ms to dispatch event`
    );
  }

  private updateCore(point: { id: string; coords: { lat: number; lon: number } }, core: SoilCore, m: RegExpMatchArray) {
    if (point.coords.lat === 0.0 && point.coords.lon === 0.0) {
      // remove point
      core.dispose();

      // remove sample site if all cores removed
      const sample = this.mission.getSamples().find((sample) => sample.sample_id === m[1]);
      if (sample?.getSoilCores().length === 0) {
        const sampleSite = sample.getSampleSite();
        sampleSite?.dispose();
      }

      // renumber waypoints
      for (const [i, waypoint] of cleanSet(
        orderBy(this.mission.getWaypoints(), ['waypoint_number'], ['asc']),
      ).entries()) {
        waypoint.waypoint_number = i + 1;
      }

      return;
    }

    // TODO SVH getZone() is null, so no sampleZone. Why?
    const zone = core.getSample()?.getSampleSite()?.getSampleZone()?.getZone();
    if (zone && zone.contains(point.coords.lat, point.coords.lon)) {
      core.lat = point.coords.lat;
      core.lon = point.coords.lon;
      const waypoint = core.getCorePoint()?.getWaypoint();
      if (waypoint) {
        waypoint.lat = point.coords.lat;
        waypoint.lon = point.coords.lon;
      }
    }
  }

  private async updateCentroid(
    point: { id: string; coords: { lat: number; lon: number } },
    sampleCentroid: SampleCentroid,
  ) {
    if (point.coords.lat === 0.0 && point.coords.lon === 0.0) {
      // remove point
      sampleCentroid.dispose();
      // renumber waypoints
      for (const [i, waypoint] of cleanSet(
        orderBy(this.mission.getWaypoints(), ['waypoint_number'], ['asc']),
      ).entries()) {
        waypoint.waypoint_number = i + 1;
      }
    } else {
      const sampleWithBarcode = sampleCentroid.findSampleWithBarcode();
      let barcode: string = '';
      if (sampleWithBarcode) {
        barcode = sampleWithBarcode.getBarCode()!;
      }

      // update centroid
      sampleCentroid.displace(point.coords.lat - sampleCentroid.lat, point.coords.lon - sampleCentroid.lon);

      if (barcode) {
        await alertFullScreen('Warning', `Please remove bag ${barcode}`, ['Ok']);
      }
    }
  }
}

export default PointsUpdater;
