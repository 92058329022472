import axios from 'axios';
import JSZip from 'jszip';
import { RobotConnectionStore } from './components/RobotConnectionStore';
import logger from './logger';

const LABEL_SERVER_HTTPS_PORT = 8084;
// TODO I should override this to use a local server for development
export const LABEL_SERVER_HOSTNAME = () => `${RobotConnectionStore.get().hostname}:${LABEL_SERVER_HTTPS_PORT}`;
export const LABEL_SERVER_HTTPS_URL = () => `https://${LABEL_SERVER_HOSTNAME()}`;

const GET_TIMEOUT = 2000;

export type LabelResponse = [filename: string, data: ArrayBuffer];

export const getLabel = async (labIdentifier: string, { requestor = '', timeout = GET_TIMEOUT } = {}) => {
  try {
    const labels = await getLabels(labIdentifier, { count: 1, requestor, timeout });
    console.log(`getLabel, result=${labels.length}`);
    return labels[0];
  } catch (err) {
    await logger.log('GET_LABEL', { err });
    return undefined;
  }
};

export const getReclaimedLabel = async (
  labIdentifier: string,
  trackingNumber: string,
  { requestor = '', timeout = GET_TIMEOUT } = {},
) => {
  try {
    const url = `${LABEL_SERVER_HTTPS_URL()}/labels/claimed/${encodeURIComponent(labIdentifier)}/${encodeURIComponent(
      trackingNumber,
    )}?requestor=${requestor}`;
    await logger.log('GET_RECLAIMED_LABEL', { labIdentifier, trackingNumber, requestor, timeout, url });
    const response = await axios.get<ArrayBuffer>(url, {
      headers: {
        'Cache-Control': 'no-cache',
        Expires: '0',
      },
      responseType: 'arraybuffer',
      timeout,
    });

    return [trackingNumber, response.data] as LabelResponse;
  } catch (err) {
    await logger.log('GET_RECLAIMED_LABEL', { err });
    return undefined;
  }
};

export const getLabels = async (labIdentifier: string, { count = 1, requestor = '', timeout = GET_TIMEOUT }) => {
  if (!labIdentifier) {
    const message = 'labelServerUtils - trying to getLabels without a lab identifier';
    await logger.log(message);
    console.log(message);

    return [];
  }

  try {
    const url = `${LABEL_SERVER_HTTPS_URL()}/labels/${encodeURIComponent(
      labIdentifier,
    )}?requestor=${requestor}&count=${count}`;
    await logger.log('GET_LABELS', { labIdentifier, requestor, timeout, url });
    const response = await axios.get<ArrayBuffer>(url, {
      headers: {
        'Cache-Control': 'no-cache',
        Expires: '0',
      },
      responseType: 'arraybuffer',
      timeout,
    });

    // get the response.data (which is an array buffer) and unzip it to get the PDF
    const zip = new JSZip();
    const unzipped = await zip.loadAsync(response.data);

    const files: LabelResponse[] = [];
    // we only requested one file, so just get the first file
    for (const file of Object.keys(unzipped.files)) {
      const unzippedFile = unzipped.files[file];
      const fileData = await unzippedFile.async('arraybuffer');
      files.push([unzippedFile.name, fileData]);
    }
    await logger.log(
      'GET_LABELS',
      `result=${files.length}, labels=${files.map(([name, data]: LabelResponse) => name).join(',')}`,
    );
    return files;
  } catch (err) {
    // TODO Possibly raise an exception...
    await logger.log('GET_LABELS', { err });
    return [];
  }
};
