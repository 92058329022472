import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Mission, { MissionList } from './MissionClass';
import CorePoint, { CorePointList } from './CorePointClass';
import PathPoint, { PathPointList } from './PathPointClass';
import Sample from './SampleClass';
import { IWaypoint } from './types';
import { Coordinate } from 'ol/coordinate';

export default class Waypoint extends ObjectClassGenerator<Waypoint>('Waypoint') implements IWaypoint {
  // attributes
  #waypoint_number: number;
  #lat: number;
  #lon: number;

  // relationships
  #Mission_id: number | undefined;

  static tableName = 'Waypoint';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Waypoint, 'waypoint_number');
    this.publishAttribute(Waypoint, 'lat');
    this.publishAttribute(Waypoint, 'lon');
    this.publishAttribute(Waypoint, 'Mission_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Waypoint> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#waypoint_number = state.waypoint_number || 0;
    this.#lat = state.lat || 0.0;
    this.#lon = state.lon || 0.0;
    this.#Mission_id = state.Mission_id;
  }

  dispose() {
    const mission = this.getMission();
    if (mission) {
      this.Mission_id = undefined;
    }
    const corepoint = this.getCorePoint();
    if (corepoint) {
      corepoint.Waypoint_id = undefined;
      corepoint.dispose();
    }
    const pathpoint = this.getPathPoint();
    if (pathpoint) {
      pathpoint.Waypoint_id = undefined;
      pathpoint.dispose();
    }

    Waypoint.delete(this.instance_id);
  }

  set waypoint_number(value) {
    this.#waypoint_number = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get waypoint_number() {
    return this.#waypoint_number;
  }

  set lat(value) {
    this.#lat = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lat() {
    return this.#lat;
  }

  set lon(value) {
    this.#lon = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lon() {
    return this.#lon;
  }

  get mission_name() {
    return this.getMission()?.name;
  }

  get last_modified() {
    return this.getMission()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getMission();
    if (inst && value) {
      inst.last_modified = value;
    }
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Waypoint', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Waypoint', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  getMission() {
    return Mission.get(this.Mission_id);
  }

  getCorePoint() {
    if (this._refs && this._refs.CorePoint) {
      const result = Array.from(this._refs.CorePoint).map((id) => CorePoint.get(id));
      return result[0];
    } else {
      const corepoint = CorePoint.findOne((sel) => sel?.Waypoint_id === this.instance_id);
      if (corepoint) {
        corepoint.Waypoint_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return corepoint;
    }
  }

  getRelatedSample() {
    const corePoint = this.getCorePoint();
    if (corePoint) {
      const sample = Sample.get(corePoint.getSoilCore()?.Sample_id);
      return sample;
    }
    return undefined;
  }

  getPathPoint() {
    if (this._refs && this._refs.PathPoint) {
      const result = Array.from(this._refs.PathPoint).map((id) => PathPoint.get(id));
      return result[0];
    } else {
      const pathpoint = PathPoint.findOne((sel) => sel?.Waypoint_id === this.instance_id);
      if (pathpoint) {
        pathpoint.Waypoint_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return pathpoint;
    }
  }

  getCoordinates(): Coordinate {
    return [this.lat, this.lon];
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Waypoint.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.waypoint_number === this.waypoint_number &&
        sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate waypoint found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`waypoint: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getMission()) {
      problems.push(
        `waypoint: Could not find mission instance across unconditional relationship R11: ${this.instance_id}`,
      );
    }
    let found_subtype = false;
    if (this.getCorePoint()) {
      if (found_subtype) {
        problems.push(
          `waypoint: Multiple subtype instances found across unconditional relationship R12: ${this.instance_id}`,
        );
      }
      found_subtype = true;
    }
    if (this.getPathPoint()) {
      if (found_subtype) {
        problems.push(
          `waypoint: Multiple subtype instances found across unconditional relationship R12: ${this.instance_id}`,
        );
      }
      found_subtype = true;
    }
    if (!found_subtype) {
      problems.push(
        `waypoint: Could not find subtype instance across unconditional relationship R12: ${this.instance_id}`,
      );
    }
    return problems;
  }
}

export class WaypointList extends DataObjectList<Waypoint> {
  getMissions() {
    return new MissionList(...this.map((waypoint) => waypoint.getMission()).filter((sel) => !!sel));
  }

  getCorePoints() {
    return new CorePointList(...this.map((waypoint) => waypoint.getCorePoint()).filter((sel) => !!sel));
  }

  getPathPoints() {
    return new PathPointList(...this.map((waypoint) => waypoint.getPathPoint()).filter((sel) => !!sel));
  }
}
