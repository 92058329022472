import { Coordinate } from 'ol/coordinate';
import { RobotArmOffsetX, RobotArmOffsetY } from '../db/local_storage';
import { convertProjection4329, convertProjection3857 } from '../utils';
import { RosPosition } from '../types/rosmsg';
import { TakenCore } from '../types/types';

// sin(13.5) * x = 0.2302
// cos(13.5) * x = 0.9652
// x = 0.9652 / cos(13.5) = 0.992626345
// y = 0.2302 / sin(13.5) = 0.984988896

export function calculateArmPosition(
  robotPosition: Coordinate,
  robotHeading: number,
  projection: '3857' | '4329' = '4329',
): Coordinate {
  const armOffset = { dx: RobotArmOffsetX.get(), dy: RobotArmOffsetY.get() };
  const origin = projection === '3857' ? convertProjection4329(robotPosition) : robotPosition;
  const dx = Math.cos(robotHeading) * armOffset.dx + Math.sin(robotHeading) * armOffset.dy;
  const dy = Math.cos(robotHeading) * armOffset.dy - Math.sin(robotHeading) * armOffset.dx;
  const armPosition = [origin[0] + dx, origin[1] + dy];
  return projection === '3857' ? convertProjection3857(armPosition) : armPosition;
}

function calculateArmPositionRosPosition(position: RosPosition, projection: '3857' | '4329' = '4329'): RosPosition {
  const coord = calculateArmPosition([position.y, position.x], -position.z, projection);
  return { x: coord[0], y: coord[1], z: position.z };
}

export function transformTakenCoresToArmPosition(
  takenCores: TakenCore[],
  projection: '3857' | '4329' = '3857',
): TakenCore[] {
  for (const core of takenCores) {
    core[3] = calculateArmPositionRosPosition(core[3], projection);
  }

  return takenCores;
}
