import { GridPatterns, Mission, Sample, SampleBox, SoilCore, SoilDumpEvent } from '../../../../db';
import SamplingSpec, { SAMPLING_SPEC_DEFAULT_DEPTH } from '../../../../db/SamplingSpecClass';
import { IKMLElement } from '../../../../kml';
import { cleanSet } from '../../../../utils';
import { checkPointCoordinates } from './checks';
import logger from '../../../../logger';
import { SampleBoxDeSerialized } from '../../../../db/types';

class CoreKmlLoader {
  constructor(private deSerializedBoxes: SampleBoxDeSerialized[]) {}

  async load(point: IKMLElement, mission: Mission, samples: Sample[]): Promise<[SoilCore | null, string[]]> {
    let errorMessages: string[] = [];

    const primarySpec = mission.getPrimarySpec();
    if (!primarySpec) {
      errorMessages.push(`ERROR: No primary spec found`);

      return [null, errorMessages];
    }

    let core: SoilCore | null = null;
    const pointName = point.find('name');
    const name = pointName ? pointName.getText() : '';
    const m = name.match(/^([a-zA-Z0-9]+)([,.;-]([1-9][0-9]*))?$/);
    if (!m) {
      errorMessages.push(`ERROR: core '${name}' does not match the expected format`);

      return [null, errorMessages];
    }
    const sampleID = m[1];
    const coreNumber = m[3];

    let sample = samples.find((sel) => sel.sample_id === sampleID);

    ({ sample, core } = this.getSampleAndCore(
      name,
      sample,
      core,
      mission,
      sampleID,
      point,
      primarySpec,
      errorMessages,
    ));

    if (!core) {
      return [core, errorMessages];
    }

    const [lat, lon] = checkPointCoordinates(point, errorMessages);
    core.lat = lat;
    core.lon = lon;

    const ed = point.getExtendedData();
    core.pulled_at = ed.pulled_at_timestamp && ed.pulled_at_timestamp !== '' ? parseFloat(ed.pulled_at_timestamp) : 0.0;
    core.waypoint_index = ed.waypoint_index ? parseInt(ed.waypoint_index) : -1;
    core.pulled_lat = ed.pulled_lat ? parseFloat(ed.pulled_lat) : 0.0;
    core.pulled_lon = ed.pulled_lon ? parseFloat(ed.pulled_lon) : 0.0;
    core.pulled_heading = ed.pulled_heading ? parseFloat(ed.pulled_heading) : 0.0;
    core.initiator = ed.initiator ? parseInt(ed.initiator) : undefined;
    core.core_length_cm = ed.core_length_cm ? parseFloat(ed.core_length_cm) : 0.0;
    core.source = ed.source ? ed.source : 'Unknown';
    core.core_diameter_inches = ed.core_diameter_inches ? ed.core_diameter_inches : '';

    // link to dump event
    if (ed.dump_guid) {
      const dump = mission.getSoilDumpEvents().find((event: SoilDumpEvent) => event.guid === ed.dump_guid);
      if (dump) {
        core.SoilDumpEvent_id = dump.instance_id;
      }
    }

    if (coreNumber) {
      core.core_number = parseInt(coreNumber);
    }

    if (!sample) {
      return [core, errorMessages];
    }

    if (!core.core_number) {
      const cores = cleanSet(sample.getSoilCores());
      core.core_number = cores.length > 0 ? Math.max(...cores.map((sel) => sel.core_number)) + 1 : 1;
    }

    core.Sample_id = sample.instance_id;
    const sampleSite = sample.getSampleSite();
    sampleSite && (sampleSite.change_type = ed.change_type ? ed.change_type : '');
    sampleSite && (sampleSite.change_reason = ed.change_reason ? ed.change_reason : '');

    const spec = sample.getSamplingSpec();
    if (!spec || spec.pattern_type !== GridPatterns.ZONE) {
      return [core, errorMessages];
    }

    // For zone missions, sample info will be in the core
    if (ed.start_depth) {
      spec.start_depth = parseFloat(ed.start_depth);
    } else {
      spec.start_depth = 0.0;
      errorMessages.push('Warning: could not find start depth in specification; using default');
    }

    if (ed.end_depth) {
      spec.end_depth = parseFloat(ed.end_depth);
    } else {
      spec.end_depth = SAMPLING_SPEC_DEFAULT_DEPTH;
      errorMessages.push('Warning: could not find end depth in specification; using default');
    }

    let sampleBoxUid = ed.box_uid;
    if (sampleBoxUid) {
      sampleBoxUid = sampleBoxUid.toString().trim();
      const box = SampleBox.query((sampleBox) => sampleBox.uid === sampleBoxUid);
      if (box.length) {
        sample.SampleBox_id = box[0].instance_id;
        await logger.log(
          'LOAD_KML - CoreKmlLoader',
          `Linked box with uid ${sampleBoxUid} to sample: ${JSON.stringify(sample)}`,
        );
      } else {
        await SampleBox.recreateFromKml(sample, sampleBoxUid, this.deSerializedBoxes);
      }
    }

    // update the number of cores;
    const cores = cleanSet(sample.getSoilCores());
    spec.cores = cores.length;
    sample.order = ed.order ? parseInt(ed.order) : 0;
    sample.bag_id = ed.bag_id ? ed.bag_id : '';

    return [core, errorMessages];
  }

  private getSampleAndCore(
    name: any,
    sample: Sample | undefined,
    core: SoilCore | null,
    mission: Mission,
    sampleID: string,
    point: IKMLElement,
    samplingSpec: SamplingSpec,
    errorMessages: string[],
  ) {
    if (name.startsWith('TEST')) {
      return { sample, core };
    }

    if (sample) {
      core = SoilCore.create();

      return { sample, core };
    }

    sample = Sample.create();
    sample.sample_id = sampleID;

    const sampleZone = mission.getSampleZoneWithSampleID(sampleID);
    if (!sampleZone) {
      errorMessages.push(`ERROR: core '${name}' does not match any samples`);

      return { sample, core };
    }

    let [lat, lon] = checkPointCoordinates(point, errorMessages);

    const zone = sampleZone.getZone();
    if (!zone || !zone.contains(lat, lon)) {
      errorMessages.push(`ERROR: core '${name}' is not within the sample zone`);

      return { sample, core };
    }

    const sampleType = Sample.getSampleTypeByPoint(point);

    const sampleSite = sampleZone.getSampleSite();
    sample = sampleSite ? cleanSet(sampleSite.getSamples()).find((sel) => sel.sample_type === sampleType) : undefined;
    if (sampleSite && !sample) {
      sample = Sample.createSample(mission, sampleSite, samplingSpec, sampleID, sampleType);
    }
    core = SoilCore.create();

    return { sample, core };
  }
}

export default CoreKmlLoader;
