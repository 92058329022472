import React, { PureComponent } from 'react';

import { Button, Grid, GridSize, Typography } from '@material-ui/core';
import EventBus from '../../../EventBus';
import { getCurrentSession } from '../../../dataModelHelpers';
import { dispatchRosMsgPub } from '../../../RobotConnection';
import { colorToString } from '../../../utils';

interface HMIButtonProps {
  buttonID: string;
  defaultLabel: string;
  height?: number;
  width?: string | number;
  minWidth?: string | number;
  fontSize?: number;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

interface HMIButtonState {
  buttonLabel: string;
  buttonColor: string;
}

export default class HMIButton extends PureComponent<HMIButtonProps, HMIButtonState> {
  constructor(props: HMIButtonProps) {
    super(props);

    this.state = {
      buttonLabel: this.props.defaultLabel,
      buttonColor: '',
    };

    this.updateColor = this.updateColor.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
    this.sendButtonPress = this.sendButtonPress.bind(this);
    this.getFontColor = this.getFontColor.bind(this);
  }

  async componentDidMount() {
    EventBus.on(`ROSMSG/hmi/${this.props.buttonID}/color`, this.updateColor, true);
    EventBus.on(`ROSMSG/hmi/${this.props.buttonID}/label`, this.updateLabel, true);
  }

  async componentWillUnmount() {
    EventBus.remove(`ROSMSG/hmi/${this.props.buttonID}/color`, this.updateColor);
    EventBus.remove(`ROSMSG/hmi/${this.props.buttonID}/label`, this.updateLabel);
  }

  updateColor({ hostname, msg }: { hostname: string; msg: { data: number } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      const color = colorToString(msg.data);
      if (color === this.state.buttonColor) {
        return;
      }
      this.setState({ buttonColor: color });
    }
  }

  getFontColor(buttonBackground: string) {
    if (buttonBackground === undefined) {
      return 'black';
    }

    if (buttonBackground.toUpperCase() === '#FF0000') {
      return 'white';
    }

    if (buttonBackground.toUpperCase() === '#0000FF') {
      return 'white';
    }

    return 'black';
  }

  updateLabel({ hostname, msg }: { hostname: string; msg: { data: string } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      this.setState({ buttonLabel: msg.data });
      //console.log(msg.data);
    }
  }

  sendButtonPress() {
    dispatchRosMsgPub({
      hostname: getCurrentSession()?.robot_hostname || '',
      msg: { data: true },
      topic: `hmi/${this.props.buttonID}/pressed`,
      tag: this.constructor.name,
    });

    this.setState({ buttonColor: 'white' });
  }

  // #define GREEN  0x00FF00
  // #define RED    0xFF0000
  // #define WHITE  0xFAF9F6
  // #define PURPLE 0xA020F0
  // #define BLUE   0x0000FF
  // #define YELLOW 0xFFFF00
  // #define ORBRWN 0xBE6400

  render() {
    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl}>
        <Button
          onClick={this.sendButtonPress}
          style={{
            minWidth: this.props.minWidth,
            backgroundColor: this.state.buttonColor,
            height: this.props.height || 50,
          }}
          size="large"
          variant="outlined"
        >
          <Typography
            style={{
              fontSize: this.props.fontSize || 24,
              color: this.getFontColor(this.state.buttonColor),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {this.state.buttonLabel.replaceAll('/', ' / ').padStart(9, ' ').padEnd(10, ' ')}
          </Typography>
        </Button>
      </Grid>
    );
  }
}
