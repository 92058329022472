import React, { PureComponent } from 'react';
import * as Sentry from '@sentry/react';
import { alertError } from '../alertDispatcher';
import BlurDialog from './utils/BlurDialog';

import { AES, enc } from 'crypto-js';

import { FaRegFolderOpen, FaRegUser } from 'react-icons/fa';
import { MdOutlineLogin } from 'react-icons/md';

import { SetStateAsync, setStateAsync } from '../utils';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Button,
  Box,
} from '@material-ui/core';
import { BarcodeScanner } from './utils';
import { OnLoading } from '../types/types';
import { BarcodeFormat } from '@zxing/library';
import { isRecoveryServerAlive } from '../recoveryServerUtils';
import logger from '../logger';

interface LoginProps {
  setSideBarText: (welcomeString: string) => void;
  onLoading: OnLoading;
  disableSentry: boolean;
  login: (username: string, password: string, alreadyHashed: boolean) => Promise<void>;
  showRecoveryPopup: () => void;
}

interface LoginState {
  username: string;
  password: string;
  loginUsernameError: string;
  loginPasswordError: string;
  deviceId: string;
  scannerOpen: boolean;
  recoveryServerAlive: boolean;
}

export default class Login extends PureComponent<LoginProps, LoginState> {
  _isMounted = false;
  setStateAsync: SetStateAsync;
  recoveryInput: React.RefObject<unknown>;

  constructor(props: LoginProps) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loginUsernameError: '',
      loginPasswordError: '',
      deviceId: '',
      scannerOpen: false,
      recoveryServerAlive: false,
    };

    this.setDeviceId = this.setDeviceId.bind(this);
    this.handleQrLogin = this.handleQrLogin.bind(this);
    this.openScanner = this.openScanner.bind(this);
    this.closeScanner = this.closeScanner.bind(this);
    this.setStateAsync = setStateAsync.bind(this);
    this.recoveryInput = React.createRef();
  }

  async handleQrLogin(detectedCode: string) {
    const passphrase = import.meta.env.VITE_AES_PASSPHRASE;
    try {
      const deciphered = AES.decrypt(detectedCode, passphrase).toString(enc.Utf8);
      const [username, password] = deciphered.split('|');
      await this.setStateAsync({ username, password });
      await this.props.login(username, password, true);
    } catch (err) {
      console.error(err);
      alertError('Invalid login code');
      this.setState({ username: '', password: '' });
      this.closeScanner();
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      const recoveryServerAlive = await isRecoveryServerAlive();

      if (this._isMounted) {
        this.setState({ recoveryServerAlive });
      }
    } catch (error) {
      console.error('Login -> componenetDidMount error:', error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openScanner() {
    this.setState({ scannerOpen: true });
  }

  closeScanner() {
    this.setState({ scannerOpen: false });
  }

  async setDeviceId(deviceId: string) {
    this.setState({ deviceId: deviceId });
    localStorage.setItem('deviceId', deviceId);
    try {
      await logger.log('LOGIN:SET_DEVICE_ID', { deviceId });
      Sentry.setTag('robot', deviceId);
    } catch (e) {
      console.error('Error setting username in login', e);
    }
  }

  login = async () => {
    let usernameError = this.state.username === '' ? 'Please enter a username' : '';
    let passwordError = this.state.password === '' ? 'Please enter a password' : '';
    if (usernameError !== '' || passwordError !== '') {
      this.setState({ loginUsernameError: usernameError, loginPasswordError: passwordError });
      return;
    }
    await this.props.login(this.state.username, this.state.password, false);
  };

  render() {
    return (
      <React.Fragment>
        <BlurDialog disableEscapeKeyDown open={true}>
          <DialogTitle>
            <Grid container justifyContent="center" alignContent="center" alignItems="center">
              <Grid item xs={12}>
                {/* <Badge overlap="rectangular"> */}
                <img className="responsive" src={'./images/logo.png'} alt="Rogo" style={{ margin: 'auto' }} />
                {/* </Badge> */}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await this.login();
              }}
            >
              <TextField
                type="text"
                label="user:"
                value={this.state.username}
                autoFocus={this.state.deviceId !== ''}
                error={this.state.loginUsernameError !== ''}
                helperText={this.state.loginUsernameError}
                fullWidth
                autoComplete="username"
                onChange={(e) => {
                  this.setState({
                    username: e.target.value,
                    loginUsernameError: e.target.value !== '' ? '' : 'Please enter a username',
                  });
                }}
                data-testid="login-username"
              />
              <TextField
                type="password"
                label="password:"
                value={this.state.password}
                fullWidth
                autoComplete="current-password"
                error={this.state.loginPasswordError !== ''}
                helperText={this.state.loginPasswordError}
                onChange={(e) => {
                  this.setState({
                    password: e.target.value,
                    loginPasswordError: e.target.value !== '' ? '' : 'Please enter a password',
                  });
                }}
                data-testid="login-password"
                // TODO Left this here because this caused the enter to submit functionality to work
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" data-testid="login-submit" />
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Box
              display={'flex'}
              sx={{
                p: 3,
                gridGap: 10,
                // m: 3,
                flexWrap: 'wrap',
              }}
              style={{
                width: '100%',
                justifyContent: 'space-evenly',
                alignContent: 'center',
              }}
            >
              <Button
                onClick={() => {
                  this.setState(
                    { deviceId: this.state.deviceId || 'Other', username: 'guest', password: 'password' },
                    async () => {
                      await this.props.login('guest', 'password', false);
                    },
                  );
                }}
                variant="outlined"
                style={{ fontSize: 16 }}
              >
                <FaRegUser size={32} style={{ marginRight: 7.5 }} />
                Guest
              </Button>
              <Button
                variant="outlined"
                component="label"
                onClick={this.props.showRecoveryPopup}
                style={{ fontSize: 16 }}
              >
                <FaRegFolderOpen size={32} style={{ marginRight: 7.5 }} />
                Load Recovery
              </Button>

              <Button
                type="submit"
                variant="outlined"
                style={{ fontSize: 16, backgroundColor: 'green', color: 'white' }}
                onClick={async (e) => {
                  e.preventDefault();
                  await this.login();
                }}
              >
                <MdOutlineLogin size={32} style={{ marginRight: 7.5 }} />
                Login
              </Button>
            </Box>
          </DialogActions>
        </BlurDialog>

        {this.state.scannerOpen && (
          <BarcodeScanner
            updateDetectedCode={this.handleQrLogin}
            handleLoadFailure={this.closeScanner}
            closeScanner={this.closeScanner}
            facingMode={'environment'}
            fullScreen={true}
            type={[BarcodeFormat.QR_CODE]}
            sampleScanner={false}
          />
        )}
      </React.Fragment>
    );
  }
}
