import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Zone, { ZoneList } from './ZoneClass';

import { to_kml, load_kml, contains, new_boundary, simplify } from '../db_ops/boundary_ops';
import { IKMLElement, KMLElement } from '../kml';
import { Coordinate } from 'ol/coordinate';
import { IBoundary } from './types';

export default class Boundary extends ObjectClassGenerator<Boundary>('Boundary') implements IBoundary {
  // attributes
  #boundary_id: number;
  #poly_id: number;
  #coordinates: Coordinate[];

  // relationships
  #OuterZone_id: number | undefined;
  #InnerZone_id: number | undefined;

  static tableName = 'Boundary';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Boundary, 'boundary_id');
    this.publishAttribute(Boundary, 'poly_id');
    this.publishAttribute(Boundary, 'coordinates');
    this.publishAttribute(Boundary, 'OuterZone_id');
    this.publishAttribute(Boundary, 'InnerZone_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Boundary> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#boundary_id = state.boundary_id || 0;
    this.#poly_id = state.poly_id || 0;
    this.#coordinates = state.coordinates || [];
    // TODO this naming may not be ideal and should be reconsidered
    // original name: R15_Zone_id
    this.#OuterZone_id = state.OuterZone_id;
    // TODO this naming may not be ideal and should be reconsidered
    // original name: R16_Zone_id
    this.#InnerZone_id = state.InnerZone_id;
  }

  dispose() {
    const outerZone = this.getOuterZone();
    if (outerZone) {
      if (outerZone.getOuterZoneBoundarys().length <= 1) {
        outerZone.dispose();
      }
      this.OuterZone_id = undefined;
    }
    const innerZone = this.getInnerZone();
    if (innerZone) {
      this.InnerZone_id = undefined;
    }

    Boundary.delete(this.instance_id);
  }

  set boundary_id(value) {
    this.#boundary_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get boundary_id() {
    return this.#boundary_id;
  }

  set poly_id(value) {
    this.#poly_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get poly_id() {
    return this.#poly_id;
  }

  set coordinates(value) {
    this.#coordinates = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get coordinates() {
    return this.#coordinates;
  }

  get mission_name() {
    let inst: Zone | undefined;
    if (Boolean((inst = this.getOuterZone())) && !!inst) {
      return inst.mission_name;
    } else if (Boolean((inst = this.getInnerZone())) && !!inst) {
      return inst.mission_name;
    } else {
      return undefined;
    }
  }

  get last_modified() {
    let inst: Zone | undefined;
    if (Boolean((inst = this.getOuterZone())) && !!inst) {
      return inst.last_modified;
    } else if (Boolean((inst = this.getInnerZone())) && !!inst) {
      return inst.last_modified;
    } else {
      return undefined;
    }
  }

  set last_modified(value) {
    let inst: Zone | undefined;
    if (Boolean((inst = this.getOuterZone())) && !!inst) {
      inst.last_modified = value;
    } else if (Boolean((inst = this.getInnerZone())) && !!inst) {
      inst.last_modified = value;
    }
  }

  get zone_name() {
    let inst: Zone | undefined;
    if (Boolean((inst = this.getOuterZone())) && !!inst) {
      return inst.zone_name;
    } else if (Boolean((inst = this.getInnerZone())) && !!inst) {
      return inst.zone_name;
    } else {
      return undefined;
    }
  }

  set OuterZone_id(value) {
    if (this.#OuterZone_id) {
      const relateObj = Zone.get(this.#OuterZone_id);
      if (relateObj) {
        relateObj.removeRelationshipData('OuterZone', this.instance_id);
      }
    }
    this.#OuterZone_id = value;
    if (value) {
      const relateObj = Zone.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('OuterZone', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get OuterZone_id() {
    return this.#OuterZone_id;
  }

  getOuterZone() {
    return Zone.get(this.OuterZone_id);
  }

  set InnerZone_id(value: number | undefined) {
    if (this.#InnerZone_id) {
      const relateObj = Zone.get(this.#InnerZone_id);
      if (relateObj) {
        relateObj.removeRelationshipData('InnerZone', this.instance_id);
      }
    }
    this.#InnerZone_id = value;
    if (value) {
      const relateObj = Zone.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('InnerZone', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  getInnerZone() {
    return Zone.get(this.InnerZone_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Boundary.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.boundary_id === this.boundary_id &&
        sel.zone_name === this.zone_name &&
        sel.mission_name === this.mission_name &&
        sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate boundary found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`boundary: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    return problems;
  }

  to_kml() {
    return to_kml.bind(this)();
  }

  static async load_kml(linearring: IKMLElement, zone: Zone, outer: boolean, poly_id: number) {
    return await load_kml(linearring, zone, outer, poly_id);
  }

  contains(lat: number, lon: number) {
    return contains.bind(this)(lat, lon);
  }

  static async new_boundary(points: Coordinate[], zone: Zone, outer: boolean, poly_id: number) {
    return await new_boundary(points, zone, outer, poly_id);
  }

  simplify(tolerance: number) {
    simplify.bind(this)(tolerance);
  }
}

export class BoundaryList extends DataObjectList<Boundary> {
  getOuterZones() {
    return new ZoneList(...this.map((boundary) => boundary.getOuterZone()).filter((sel) => !!sel));
  }

  getInnerZones() {
    return new ZoneList(...this.map((boundary) => boundary.getInnerZone()).filter((sel) => !!sel));
  }
}
