import { cloneObjectDeep } from '../utils';
import { DataList } from './basetable';
import { getMemoryTable, requestDBSync } from './datamanager';
import { DataObject } from './dataobject';
import { DBPeriodicSync } from './local_storage';

let checkpoints: Checkpoint<DataObject>[] = [];

/**
 * @constant the max number of checkpoints allowed; a value of 0 indicates no limit
 */
const MAX_CHECKPOINTS: number = 0;

/**
 * Create a new named checkpoint
 * @param {string} [name=Unnamed Checkpoint] common name of the new checkpoint
 * @returns {number} the unique ID of the checkpoint
 */
export function createCheckpoint(name = 'Unnamed Checkpoint') {
  const checkpoint = new Checkpoint(name);
  if (MAX_CHECKPOINTS && checkpoints.length >= MAX_CHECKPOINTS) {
    checkpoints.pop(); // remove the oldest checkpoint to make room
  }
  checkpoints.unshift(checkpoint);
  return checkpoint.id;
}

/**
 * Clear all saved checkpoints
 */
export function clearCheckpoints() {
  checkpoints = [];
}

/**
 * Load the most recently saved checkpoint
 * @returns {Checkpoint} the loaded checkpoint
 * @throws {CheckpointError} if there are no checkpoints on the stack
 */
export async function loadLastCheckpoint() {
  if (checkpoints[0]) {
    const checkpoint = checkpoints.shift();
    await checkpoint?.load();
    return checkpoint;
  } else {
    throw new CheckpointError('No checkpoint to load');
  }
}

/**
 * Load the most recent saved checkpoints sequentially until the desired
 * checkpoint is loaded
 * @param {number} checkpointId the checkpoint ID to load
 * @returns {Checkpoint} the loaded checkpoint
 * @throws {CheckpointError} if there are no checkpoints on the stack or the
 * requested checkpoint ID does not exist in the stack
 */
export async function loadCheckpoint(checkpointId: number) {
  // load checkpoints until the one we want is reached
  let checkpoint: Checkpoint<DataObject> | undefined = undefined;
  while (!checkpoint || checkpoint.id !== checkpointId) {
    checkpoint = await loadLastCheckpoint();
  }
  return checkpoint as Checkpoint<DataObject>;
}

/**
 * Freeze a table in the current checkpoint (if it exists) before a data change
 */
export function freezeTableInCheckpoint<T extends DataObject>(tableName: string, dataList: DataList<T>) {
  if (checkpoints[0]) {
    checkpoints[0].update(tableName, dataList);
  }
}

/**
 * Get an array of savedcheckpoint names (newest to oldest)
 * @returns {string[]} the names of the saved checkpoints
 */
export function getCheckpointNames() {
  return checkpoints.map((c) => c.name);
}

class Checkpoint<T extends DataObject> {
  static currentId = 0;

  id: number;
  name: string;

  _dataTableList: Record<string, DataList<T>> = {};

  constructor(name: string) {
    this.id = ++Checkpoint.currentId;
    this.name = name;
  }

  async load() {
    if (Object.keys(this._dataTableList).length > 0) {
      // reset all tables that changed
      for (const [tableName, dataList] of Object.entries(this._dataTableList)) {
        const table = getMemoryTable(tableName);
        table.resetData(dataList);
      }

      // request a data sync
      if (!DBPeriodicSync.get()) {
        requestDBSync();
      }
    }
  }

  update(tableName: string, dataList: DataList<T>) {
    // set the data list
    if (!this._dataTableList[tableName]) {
      this._dataTableList[tableName] = cloneObjectDeep(dataList);
    }
  }
}

class CheckpointError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CheckpointError';
  }
}
