// @ts-strict-ignore
import _ from 'lodash';
import { assert, round, truncate } from './utils';
import { Job, Mission, Sample, SampleBox, SamplingSpec } from './db';
import { PRINTER_CONFIG } from './components/robot/configs/PrinterConfig';
import {
  CanvasElement,
  CanvasLine,
  Column,
  Content,
  ContentBase,
  ContentCanvas,
  ContentColumns,
  ContentImage,
  ContentQr,
  ContentStack,
  ContentTable,
  ContentText,
  ContentUnorderedList,
  ContextPageSize,
  CustomTableLayout,
  TableCell,
} from 'pdfmake/interfaces';
import pdfMake from './services/PdfService';
import { getLabel, getReclaimedLabel } from './labelServerUtils';
import { PDFDocument } from 'pdf-lib';
import { alertError, alertWarn } from './alertDispatcher';
import logger from './logger';
import { EnableExperimentalUPSManifestLogo } from './db/local_storage';
import { storeDispatch } from './redux/store';
import { updateBoxTrackingNumber } from './redux/features/boxes/boxesSlice';

/**
 * NOTE: If this is changed, then the box detection semantics in {@link file://./barcode_rules.ts}
 * should also be revisited. The current regex looks for the entire QR code contents
 * including the URL.
 */
//const LAB_DOMAIN = 'lab.rogoag.com';
const LAB_DOMAIN = 'l.rogo.ag';

// NOTE: We are removing https:// as of 2023-06 in an effort to reduce
// the QR code size. This should still work for mobile devices that need
// to scan this QR as HTTPS redirect should be automatic
const LAB_FEEDBACK_LINK = `https://${LAB_DOMAIN}`;
//const LAB_FEEDBACK_LINK = `${LAB_DOMAIN}`;

/**
 * Generates a pdf from the box data and updates state with the data url to display to the user
 * @param {SampleBox} box Data class instance of the SampleBox that will be displayed
 * @param {PRINTER_CONFIG} printerConfig Printer configuration object
 */
export async function generateBoxCheckin(box: SampleBox, printerConfig: PRINTER_CONFIG, labelOnly: boolean = false) {
  try {
    const logoBlob = await fetch('./images/logoBlack.png').then((r) => r.blob());
    const logoDataUrl = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string); // we can force this to a string because we know that we called
      // readAsDataURL(...) to load the blob
      reader.readAsDataURL(logoBlob);
    });

    const upsLogoBlob = await fetch('./images/UPS-Emblem.png').then((r) => r.blob());
    const upsLogoDataUrl = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string); // we can force this to a string because we know that we called
      // readAsDataURL(...) to load the blob
      reader.readAsDataURL(upsLogoBlob);
    });

    const checkinPdf = box.toPdf(logoDataUrl, upsLogoDataUrl, printerConfig);
    const uid = box.uid;
    if (checkinPdf) {
      const createdPdf = pdfMake.createPdf(checkinPdf);
      const label = box.trackingNumber
        ? await getReclaimedLabel(box.labName, box.trackingNumber)
        : await getLabel(box.labName);

      if (!label) {
        await logger.log(
          'CLOSE_BOX',
          box.trackingNumber
            ? `Box had label ${box.trackingNumber}, but unable to retrieve`
            : 'Box had no label, and unable to retrieve',
        );
      }

      return new Promise<{ dataUrl: string; downloadName: string }>((resolve) => {
        createdPdf.getBuffer(async (buffer) => {
          // convert to ArrayBuffer
          const arrayBuffer = new Uint8Array(buffer).buffer;

          const mergedPdf = await PDFDocument.create();
          const originalLabel = await PDFDocument.load(arrayBuffer);

          const originalPages = await mergedPdf.copyPages(originalLabel, originalLabel.getPageIndices());

          // Get width of first portrait page
          // Sentry error from this page: Not sure why our generated PDF was zero?
          // most likely was test mission
          // Error: `index` must be at least 0 and at most 0, but was actually 1
          //   at Y (../../../src/utils/validators.ts:189:11)
          //   at e.prototype.getPage (../../../src/api/PDFDocument.ts:571:5)
          //   at <anonymous> (pdfcheckin.ts:77:52)
          const originalLabelWidth = originalLabel.getPage(originalLabel.getPageCount() - 1).getWidth();
          const originalLabelHeight = originalLabel.getPage(originalLabel.getPageCount() - 1).getHeight();

          originalPages.forEach((page) => {
            mergedPdf.addPage(page);
          });

          // if we managed to retrieve a new label or the existing label...
          if (label) {
            const [filename, data] = label;
            const [trackingNumber] = filename.split('.');

            if (box.trackingNumber !== trackingNumber) {
              if (box.trackingNumber) {
                alertWarn(`Tracking number mismatch! Expected ${box.trackingNumber} but got ${trackingNumber}`);
                box.trackingNumberTime = '0000-00-00 00:00:00';
              } else {
                box.trackingNumberTime = new Date().toISOString();
              }
              box.trackingNumber = trackingNumber;
              box.needsUpdated = true;

              storeDispatch(updateBoxTrackingNumber(box));
            }

            const shipmentLabel = await PDFDocument.load(data);
            const shipmentLabelWidth = shipmentLabel.getPage(0).getWidth();
            const scale = originalLabelWidth / shipmentLabelWidth;

            // crop to 4x6 aspect ratio
            // const oldHeight = shipmentLabel.getPage(0).getHeight();

            // We want to take the larger value. If the label is shorter, we will create a normal height page.
            // If the label is taller, we will create a taller page and crop it later
            const shipmentLabelHeight = Math.max(shipmentLabel.getPage(0).getHeight() * scale, originalLabelHeight);

            // Create a new page with the same size as the original page
            const newPage = mergedPdf.addPage([originalLabelWidth, shipmentLabelHeight]);

            // Embed the page from another PDF document
            const embeddedPage = await mergedPdf.embedPage(shipmentLabel.getPage(0));

            // Move the content of the original page to the new page with a margin of 50 units
            newPage.drawPage(embeddedPage, {
              x: 0,
              y: -10, // This seems like a reasonable value based on testing, might need calibration
              xScale: scale,
              yScale: scale,
              width: originalLabelWidth,
              height: shipmentLabelHeight,
            });

            // calculate the height we need to trim the label down. For 4x7 labels, this works fine
            // because the extra space is just white space
            const heightToTrim = shipmentLabelHeight - originalLabelHeight;
            // if this is just the original label height, this will be zero and we won't bother with a crop
            if (heightToTrim > 0) {
              newPage.setCropBox(0, heightToTrim, originalLabelWidth, originalLabelHeight);
            }
          }

          const mergedPdfFile = await mergedPdf.save();

          if (labelOnly) {
            if (!label) {
              alertWarn(`No label available for ${uid}`);
              return;
            }

            // get the last page of mergedPdfFile
            const labelOnlyPdf = await PDFDocument.create();
            const [labelPage] = await labelOnlyPdf.copyPages(mergedPdf, [mergedPdf.getPageCount() - 1]);
            labelOnlyPdf.addPage(labelPage);
            const labelOnlyPdfFile = await labelOnlyPdf.save();
            const url = URL.createObjectURL(new Blob([labelOnlyPdfFile], { type: 'application/pdf' }));

            return resolve({ dataUrl: url, downloadName: `Label_${uid}.pdf` });
          }
          // Get a url for the uint8array
          const url = URL.createObjectURL(new Blob([mergedPdfFile], { type: 'application/pdf' }));

          let downloadName = `Box_${uid}`;
          if (box.trackingNumber) {
            downloadName += `_${box.trackingNumber}`;
          }
          if (box.ReprintSession_id) {
            downloadName += `_reprint_${Date.now()}`;
          }
          return resolve({ dataUrl: url, downloadName: `${downloadName}.pdf` });
        });
      });
    } else {
      console.error('Could not export PDF', checkinPdf);
    }
  } catch (err) {
    console.error('Could not export PDF', err);
  }
  return undefined;
}

// TODO this should be in sample_ops or utils
const _sort_samples = (sel: Sample) => {
  try {
    if (isNaN(parseInt(sel.sample_id))) {
      throw new Error('cannot parse int');
    }
    return parseInt(sel.sample_id);
  } catch (err) {
    return sel.sample_id;
  }
};

function genInfoTable3(missions: Mission[], start_index: number, box_samples: Sample[]) {
  assert(missions.length <= 3, 'genInfoTable3: missions.length > 3');

  const missionColumns: Content[][] = [
    [
      { text: '', bold: true },
      { text: 'Rogo ID', bold: true },
      { text: 'Samples', bold: true },
      { text: 'Field', bold: true },
      { text: 'Grower', bold: true },
      { text: 'Acct #', bold: true },
      { text: 'Sync #', bold: true },
      { text: 'Client', bold: true },
      { text: 'Test', bold: true },
      { text: 'Depth', bold: true },
      { text: 'Instr', bold: true },
    ],
  ];

  // if any jobs have lab_submittal_id, include that column
  const has_lab_submittal_id = missions.some((mission) => mission?.getJob()?.lab_submittal_id);

  if (has_lab_submittal_id) {
    missionColumns[0].push({ text: 'Lab ID', bold: true });
  }

  // generate info tables (1 page)
  for (let i = 0; i < missions.length; i++) {
    const mission = missions[i];
    const job = mission.getJob();
    if (!job) {
      alertError(`Mission ${mission.job_id} has no job`);
      return;
    }
    const samplingSpec = mission.getPrimarySpec();
    const { start_depth, end_depth } = samplingSpec || { start_depth: 0, end_depth: 0 };
    const depth = `${round(start_depth, 2)}" - ${round(end_depth, 2)}"`;

    const normal_tests: string[] = [];
    const micro_tests: string[] = [];
    const test_package = job.test_package || '';

    for (const t of test_package.split('+')) {
      // non-micro test
      if (!!t && !t.endsWith('*')) {
        normal_tests.push(t);
      }

      // micro-test
      else {
        micro_tests.push(t.slice(0, -1));
      }
    }

    const normal_test = normal_tests.join('+');
    let sample_options = `(-): ${normal_test}`;
    let index = 0;
    for (const micro_test of micro_tests) {
      const testId = String.fromCharCode(65 + index++);
      sample_options += `, ${testId}: ${normal_test}+${micro_test}`;
    }

    const missionSamples = mission.getAllSamples();
    const missionSamplesInBox = missionSamples.filter((sample) => sample.box_uid === box_samples[0].box_uid);

    // columnar mission data
    missionColumns.push([
      { text: (start_index + i).toString(), bold: true },
      { text: mission.job_id ?? '(none)', bold: true, fontSize: 7 },
      `${missionSamplesInBox.length} of ${missionSamples.length}`,
      job.field || '',
      job.grower?.substring(0, 18) || '',
      job.billing_account || '',
      job.event_id || job.field_id.toString() || '',
      job.client?.substring(0, 18) || '',
      job.test_package ? sample_options : '',
      depth,
      job.lab_instructions || '',
    ]);

    if (has_lab_submittal_id) {
      missionColumns[missionColumns.length - 1].push(job.lab_submittal_id || '');
    }
  }

  const transposed = _.zip(...missionColumns);
  const widths = new Array(1 + missions.length);
  widths[0] = 'auto';
  widths.fill('30%', 1, 1 + widths.length);
  // iterate through mission columns and add as rows to table
  return {
    table: {
      headerRows: 1,
      widths,
      // transpose the columns
      body: transposed,
      keepWithHeaderRows: 5, // this should prevent wieird breaking for 7+ mission boxes
    },
    marginTop: 5,
  } as ContentTable;
}

function formatBarcode(bag_id: string) {
  //const first_length = bag_id.startsWith('RG') ? 8 : 6;
  const columns: Column[] = [];
  // start with all extra characters that we don't separate out
  if (bag_id.length > 6) {
    columns.push({
      text: bag_id.slice(0, bag_id.length - 6),
      width: 36,
      fontSize: 7,
      marginTop: 2,
    });
  }

  // now add the rest of the columns
  columns.push({
    text: bag_id.slice(bag_id.length - 6, bag_id.length - 4),
    width: 10 + 1,
    fontSize: 7,
    marginTop: 2,
  });

  columns.push({
    text: bag_id.slice(bag_id.length - 4, bag_id.length - 3),
    width: 6 + 1,
    fontSize: 8,
    marginTop: 1,
  });

  columns.push({
    text: bag_id.slice(bag_id.length - 3),
    width: 27,
    fontSize: 9,
    marginTop: 0,
  });

  return {
    columns,
    columnGap: 0,
    margin: 0,
  } as ContentColumns;
}

function genSamplesTable3(missions: Mission[], pdfSampleOrder: string, boxUid: string | null) {
  const NUMBER_OF_SAMPLE_COLUMNS: number = 2;
  const COLUMNS_PER_SAMPLE_COLUMN: number = 3;
  const EMPTY_ROW = new Array<string>(COLUMNS_PER_SAMPLE_COLUMN);
  EMPTY_ROW.fill('', 0, COLUMNS_PER_SAMPLE_COLUMN);

  // const sampleColumnHeaders = [
  //   { text: 'Barcode', bold: true },
  //   { text: 'S ID', bold: true },
  //   { text: 'Test', bold: true }
  // ];
  const missionSampleTable1Rows: TableCell[][] = [
    [
      { text: 'Barcode', bold: true },
      { text: 'S ID', bold: true },
      { text: 'Test', bold: true },
    ],
  ];
  const missionSampleTable2Rows: TableCell[][] = [
    [
      { text: 'Barcode', bold: true },
      { text: 'S ID', bold: true },
      { text: 'Test', bold: true },
    ],
  ];

  // iterate through the missions and add samples to the table
  // make a data structure of the following format
  // { bag_id, sample_id, job_index }[]
  //const samples: { bag_id: string, sample_id: string, job_index: number }[] = [];
  const sampleRows: TableCell[][] = [];
  const jobTitleIndeces: number[] = [];
  for (let i = 0; i < missions.length; i++) {
    const mission = missions[i];
    const job = mission.getJob();
    if (!job) {
      alertError(`Mission ${mission.job_id} has no job`);
      return;
    }
    const hasMicros = job.test_package?.includes('*');

    const boxMissionSamples = mission.getAllSamples().filter((sample) => sample.box_uid === boxUid);

    const unskippedSamples = boxMissionSamples.filter((s) => !s.skipped_or_deleted && !!s.bag_id);

    const samples =
      pdfSampleOrder === 'sample_id'
        ? _.orderBy(unskippedSamples, [_sort_samples])
        : _.orderBy(unskippedSamples, ['order', _sort_samples], ['asc']);

    const friendlyMissionTitle = truncate(`${job.field + ', ' + job.grower}`, 24);
    sampleRows.push([{ text: `${i + 1}: ${friendlyMissionTitle}`, bold: true, underline: true, colSpan: 3 }, '', '']);
    jobTitleIndeces.push(sampleRows.length);
    for (let j = 0; j < samples.length; j++) {
      const sample = samples[j];

      // assume to be truthy because we checked above but TypeScript can't tell that
      let bag_id = sample.bag_id?.replace('-', '')!;
      let sample_id = sample.sample_id;

      // TODO This is just temporary, it works for now since we mainly just have
      // one test package and one micro test package. We will need to revisit this
      // for multiple tests or multiple micros
      let textPackageColumn: ContentText;
      if (hasMicros && j % job.add_on_freq === 0) {
        textPackageColumn = {
          text: 'A',
          fontSize: 9,
        };
      } else {
        textPackageColumn = {
          text: '-',
          fontSize: 9,
        };
      }

      const bag_id_formatted = formatBarcode(bag_id);

      sampleRows.push([bag_id_formatted, { text: sample_id, fontSize: 9 }, textPackageColumn]);
    }
  }

  const maxColumnLength = Math.ceil(sampleRows.length / NUMBER_OF_SAMPLE_COLUMNS);
  for (let i = 0; i < maxColumnLength; i++) {
    const secondColumnIndex = i + maxColumnLength;
    const outOfBounds = secondColumnIndex >= sampleRows.length;

    missionSampleTable1Rows.push([...sampleRows[i]]);

    missionSampleTable2Rows.push([...(!outOfBounds ? sampleRows[secondColumnIndex] : EMPTY_ROW)]);
  }

  const sampleColumnWidths = ['50%', '18%', '18%'];
  const widths = [...sampleColumnWidths];

  const vLineWidth = (i: number, node: ContentTable) => {
    return 1;
  };
  return {
    columnGap: 0,
    columns: [
      {
        table: {
          headerRows: 1,
          widths,
          body: missionSampleTable1Rows,
          dontBreakRows: true,
        },
        layout: {
          vLineWidth,
          hLineWidth: (i: number, node: ContentTable) => {
            //console.log(`hLineWidth`, i, node.table.body[i]);
            if (
              // this is the header row
              i === 0 ||
              i === 1 ||
              // this is for each mission "header"
              jobTitleIndeces.includes(i) ||
              jobTitleIndeces.includes(i - 1) ||
              // this is for the end of the table
              i === node.table.body.length
            ) {
              return 1;
            }
            return 0;
          },
        } as CustomTableLayout,
        marginTop: 5,
        marginBottom: 5,
      } as ContentTable,
      {
        table: {
          headerRows: 1,
          widths,
          body: missionSampleTable2Rows,
        },
        layout: {
          vLineWidth,
          hLineWidth: (i: number, node: ContentTable) => {
            if (
              // this is the header row
              i === 0 ||
              i === 1 ||
              // this is for each mission "header"
              jobTitleIndeces.includes(i + maxColumnLength - 1) ||
              jobTitleIndeces.includes(i + maxColumnLength) ||
              // this is for the end of the table
              i === node.table.body.length
            ) {
              return 1;
            }
            return 0;
          },
        } as CustomTableLayout,
        marginTop: 5,
        marginBottom: 5,
      } as ContentTable,
    ],
  };
}

function genQrCode(mission: Mission) {
  const job = mission.getJob();
  if (!job) {
    throw new Error(`Mission ${mission.job_id} has no job`);
  }
  const qrlabel = job.lab_submittal_id?.length > 0 ? 'Lab ID:' : 'Rogo ID:';
  const qrvalue = job.lab_submittal_id?.length > 0 ? job.lab_submittal_id : mission.job_id;
  const qr_code: ContentStack = {
    alignment: 'center',
    marginBottom: 20,
    stack: [
      {
        qr: !qrvalue || qrvalue.length === 0 ? 'null' : qrvalue,
        fit: 100,
      },
      {
        text: qrlabel,
        fontSize: 10,
      },
      {
        text: !qrvalue || qrvalue.length === 0 ? 'null' : qrvalue,
        bold: true,
      },
    ],
  };

  return qr_code;
}

function splitAddress(address: string) {
  // Example: 3505 Conestoga Dr, Fort Wayne, IN 46808
  // should be
  // 3505 Conestoga Dr
  // Fort Wayne, IN 46808

  const commaIndex = address.indexOf(',');
  if (commaIndex === -1) {
    return [address];
  }
  const firstLine = address.slice(0, commaIndex);
  const secondLine = address.slice(commaIndex + 1);
  return [firstLine, secondLine];
}

function genHeader3(
  job: Job,
  config: PRINTER_CONFIG,
  boxId: string | null,
  logo: string | ArrayBuffer,
  samplingSpec?: SamplingSpec,
) {
  const stack: Content[] = [
    {
      text: (samplingSpec?.lab_short_name ?? job.lab_short_name ?? job.lab_name).replace(/"/g, ''),
    },
  ];

  const lab_split_address = splitAddress(samplingSpec?.lab_address ?? job.lab_address);

  for (const address_part of lab_split_address) {
    stack.push({
      text: address_part,
      fontSize: config.lab.font_size + 2,
    });
  }
  const logo_section = [
    {
      stack,
      bold: true,
      fontSize: config.lab.font_size + 4,
    } as ContentStack,
  ] as Content[];

  let checkInInfo: ContentStack | undefined = undefined;
  if (boxId) {
    // only generate this part when in the context of a box
    checkInInfo = {
      stack: [
        {
          image: logo,
          width: config.lab.logo_size,
          alignment: 'left',
        } as ContentImage,
        {
          text: `Box ID: ${boxId}`,
          fontSize: config.lab.font_size + 1,
          alignment: 'left',
        },
      ],
    };
  }

  return {
    columns: [logo_section, boxId ? checkInInfo : {}],
    marginBottom: 5,
    columnGap: 0,
  } as ContentColumns;
}

export function genPdfCheckin3(
  missions: Mission[],
  logo: string | ArrayBuffer,
  pdfSampleOrder: string,
  boxId: string | null,
  sampleBoxSamples: Sample[],
  config: PRINTER_CONFIG,
) {
  const stack: (Content | undefined)[] = [];

  const samplingSpec = sampleBoxSamples[0].getSamplingSpec();
  const job = missions[0].getJob();
  if (!job) {
    alertError(`Mission ${missions[0].job_id} has no job`);
    return;
  }
  stack.push(genHeader3(job, config, boxId, logo, samplingSpec));

  for (let i = 0; i < missions.length; i += 3) {
    stack.push(genInfoTable3(missions.slice(i, i + 3), i + 1, sampleBoxSamples));
  }

  if (missions.length + sampleBoxSamples.length > 46) {
    stack.push({
      // TOOD under box ID, "Ask for large format"
      text: 'Please follow columns across pages for correct order. Ask for large format',
      fontSize: 8,
      marginTop: 2,
    });
  }

  // for now, we will almost always break unless we have only
  if (missions.length > 3 || sampleBoxSamples.length > 20) {
    (stack[stack.length - 1] as ContentBase).pageBreak = 'after';
  }

  // generate sample tables (1-2 pages)
  stack.push(genSamplesTable3(missions, pdfSampleOrder, boxId));

  // generate QR codes (1 page, if requested)
  // make two columns
  const qr_codes: Content = [];

  // TODO should this be moved to the spec as well as part of the lab properties?
  const lab_qrs_required = job.lab_qrs_required;
  if (lab_qrs_required) {
    // add page break after sample table
    (stack[stack.length - 1] as ContentBase).pageBreak = 'after';

    for (let i = 0; i < missions.length; i++) {
      qr_codes.push(genQrCode(missions[i]));
    }

    const qr_code_columns = {
      columns: [
        {
          stack: qr_codes.slice(0, Math.ceil(qr_codes.length / 2)),
          width: '50%',
        },
        {
          stack: qr_codes.slice(Math.ceil(qr_codes.length / 2)),
          width: '50%',
        },
      ],
      marginTop: 20,
      alignment: 'center',
    } as ContentColumns;

    stack.push(qr_code_columns);
  }

  // if (sampleBoxSamples.length + missions.length)
  // stack.push({
  //   // unicode for arrow down
  //   text: 'vvvvv      if table continues on next page, please follow down column      vvvvv'
  // });

  return stack;
}

// /* Generate a pdfmake document definition for a box manifest for the given
//  * missions. `missions` is an array of objects each with a 'mission'
//  * and 'samples' field.
//  *
//  * Structure of missions:
//  *
//  * {
//  *   name: string,
//  *   job_id: string,
//  *   partial: boolean,
//  *   num_boxes: number,
//  *   job: object,
//  *   start_depth: number,
//  *   end_depth: number,
//  *   sample_date: number,
//  *   num_samples: number,
//  *   samples: [
//  *     {
//  *       sample_id: string,
//  *       bag_id: string
//  *     }
//  *   ]
//  * }
//  */
export function genPdfBoxManifest(
  missions: Mission[],
  logoPath: string,
  upsLogoPath: string,
  box: SampleBox,
  config: PRINTER_CONFIG,
) {
  if (missions.length < 1) {
    return undefined;
  }
  // NEW APP: missions[0].getJob() -> missions[0]
  const job = missions[0].getJob();
  if (!job) {
    throw new Error(`Mission ${missions[0].job_id} has no job`);
  }

  // const rogo_logo: ContentImage = {
  //   image: logoPath,
  //   width: 200,
  //   margin: [0, 0, 0, 10]
  // };

  // NEW APP: job.lab_name -> _.get(job, 'lab.address')
  const boxId = box.uid;
  const firstComma = job.lab_address.indexOf(',');
  const labName = (job.lab_short_name || job.lab_name).replace(/"/g, '');
  const address1 = firstComma >= 0 ? job.lab_address?.slice(0, firstComma)?.trim() : 'No Lab Address';
  const address2 = firstComma >= 0 ? job.lab_address?.slice(firstComma + 1)?.trim() : '';
  const addressFontSize = config.manifest.font_size + 2;
  // const lab_delivery_method = job.lab_primary_delivery.toLowerCase().includes("courier") ? "Courier" : "UPS";
  const delivery_address: Content = {
    stack: [
      { text: 'Deliver to:', fontSize: config.manifest.font_size },
      { text: labName, fontSize: addressFontSize + 4 } as Content,
      { text: address1, fontSize: addressFontSize } as Content,
      { text: address2, fontSize: addressFontSize } as Content,
      // { text: lab_delivery_method, fontSize: addressFontSize + 12 } as Content,
    ],
    bold: true,
    fontSize: config.manifest.font_size + 6,
    margin: [0, 0, 0, config.manifest.font_size + 6],
  };

  const upsLogo: ContentImage = {
    image: upsLogoPath,
    width: 60,
    color: 'black',
    // margin: [0, 3, 0, 0]
  };

  const columns: Content[] = [delivery_address];

  const ups_delivery = job.lab_primary_delivery.toLowerCase().includes('ups');
  if (ups_delivery && EnableExperimentalUPSManifestLogo.get()) {
    columns.push(upsLogo);
  }

  const topLeftSection: ContentColumns = { columns };

  // NEW APP: ${boxId} -> ${_.get(job, 'lab.idCode')}
  // get the day of the month
  const qr_code: Content = {
    stack: [
      // {
      //   text: "Courier",
      //   fontSize: 24,
      // } as ContentText,
      {
        text: box.friendlyId,
        fontSize: 30,
      } as ContentText,
      {
        text: `Box: ${boxId}`,
        fontSize: 16,
        bold: true,
        margin: [0, 0, 0, 2],
      } as Content,
      // {
      //   text: [
      //     { text: 'Can\'t scan?', bold: true } as Content,
      //     ' ',
      //     { text: 'lab.rogoag.com', italics: true } as Content
      //   ],
      //   fontSize: config.manifest.font_size,
      //   margin: [0, 0, 0, 5]
      // } as Content,
      {
        qr: `${LAB_FEEDBACK_LINK}/${boxId}`,
        fit: config.manifest.qr_width,
        eccLevel: 'Q',
      } as ContentQr,
    ],
    margin: [0, 0, 0, 2],
  } as ContentStack;

  const horizontal_rule: ContentCanvas = {
    canvas: [
      {
        type: 'line',
        x1: 0,
        y1: 0,
        x2: config.manifest.horizontal_rule,
        y2: 0,
        lineWidth: 1,
      } as CanvasElement,
    ],
    margin: [0, 0, 0, 10],
  };

  const vertical_rule: ContentCanvas | ContextPageSize = {
    canvas: [
      {
        type: 'line',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: config.manifest.vertical_rule,
        lineWidth: 1,
      } as CanvasLine,
    ],
    // TODO so this property is definitely not on ContentCanvas, and the only other interface I can find with this
    // property is ContextPageSize, so we will combine the classes for this purpose. I have a feeling
    // that perhaps this might need set somewhere else though
    width: 1,
  };

  const job_list = genJobList(missions, box, config);

  const lab_instructions = genLabInstructions(boxId, false, config, logoPath);

  // return document structure
  return {
    columns: [
      {
        stack: [topLeftSection, horizontal_rule, job_list],
        width: '*',
      } as ContentStack,
      vertical_rule,
      {
        stack: [qr_code, lab_instructions],
        width: config.manifest.qr_width,
      } as ContentStack,
    ],
    columnGap: config.manifest.col_gap,
  } as ContentColumns;
}

function genJobList(missions: Mission[], sampleBox: SampleBox, config: PRINTER_CONFIG) {
  const sampleBoxSamples = sampleBox.getSamples();
  const all_job_list = {
    stack: [],
    fontSize: missions.length < 5 ? config.manifest.font_size : config.manifest.font_size - 2,
  } as ContentStack;

  all_job_list.stack.push({
    text: `Contents (${sampleBoxSamples.length} total samples)`,
    bold: true,
    fontSize: 16,
  } as ContentText);

  // NEW APP: m.getJob().client.trim() -> m.client
  const clientMap: Record<string, Mission[]> = {};
  for (const m of missions) {
    const clientName = m.getJob()?.client.trim() || 'No Client';
    if (!clientMap[clientName]) {
      clientMap[clientName] = [];
    }
    clientMap[clientName].push(m);
  }

  Object.entries(clientMap).forEach(([client, client_missions]) => {
    all_job_list.stack.push({
      text: `${client}:`,
      decoration: 'underline',
      margin: [0, 0, 0, 5],
    });
    const job_list = {
      ul: [],
      margin: [0, 0, 0, 10],
    } as ContentUnorderedList;
    all_job_list.stack.push(job_list);

    for (const mission of client_missions) {
      // NEW APP: various data extraction changes
      const job = mission.getJob();
      if (!job) {
        throw new Error(`Mission ${mission.job_id} has no job`);
      }
      const samples = mission.getSampleSites().getSamples();

      // because of the reprint logic, we now have to be more detailed with our understanding of "partial"
      // there are two things we can do to determine if this is partial or not
      // first, we can see if all samples from the mission have filled out bag_ids
      const allBagsFilled = samples.every((sample) => Boolean(sample.bag_id));

      // secondly, we can check our jobBoxCountIndex and determine if there are any other boxes from the mission
      // with a higher jobBoxCountIndex than ours
      const sampleBoxes = mission.getAllSampleBoxes();
      const greaterJobBoxCountIndex =
        sampleBoxes.filter((otherBox) => otherBox.jobBoxCountIndex > sampleBox.jobBoxCountIndex).length > 0;
      const partial = !allBagsFilled || greaterJobBoxCountIndex;

      const partialText = partial
        ? `partial\xa0#${sampleBox.jobBoxCountIndex}`
        : `final\xa0of\xa0${sampleBoxes.length}\xa0boxes`;
      const fullPartialText = partial || samples.getSampleBoxes().length > 1 ? ` [${partialText}]` : '';
      const boxSamplesForMission = sampleBoxSamples.filter(
        (s) => !s.skipped_or_deleted && s.getSampleSite().getMission()?.instance_id === mission.instance_id,
      );

      job_list.ul.push({
        text: [
          `${job.field.trim() || 'No Field Name'}, ${job.grower.trim() || 'No Grower'}`,
          { text: ` (${boxSamplesForMission.length})`, italics: true } as ContentText,
          { text: fullPartialText, bold: true } as ContentText,
        ],
      } as ContentText);
    }
  });

  return all_job_list;
}

function genLabInstructions(id: string, includeQrCode: boolean, config: PRINTER_CONFIG, logoPath: string) {
  const rogo_logo: ContentImage = {
    image: logoPath,
    width: 120,
    margin: [0, 3, 0, 0],
  };

  return {
    stack: [
      {
        text: 'ISSUES? SCAN QR',
        bold: true,
        fontSize: config.lab.font_size + 6,
        margin: [0, 0, 0, 3],
      } as ContentText,
      {
        text: 'or visit lab.rogoag.com for any issues or feedback for us!',
      },
      rogo_logo,
    ],
    fontSize: config.lab.font_size + 2,
    alignment: 'left',
    width: 160,
  } as ContentStack;
}
