import { IKMLElement } from '../../../../kml';

export const checkPointCoordinates = (point: IKMLElement, errorMessages: string[]): [number, number] => {
  let [lat, lon] = [0.0, 0.0];
  const pointCoordinates = point.find('coordinates', true);

  const coords = pointCoordinates ? pointCoordinates.getText() : '';
  if (coords !== '') {
    lat = parseFloat(coords.split(',')[1]);
    lon = parseFloat(coords.split(',')[0]);
  } else {
    const pointName = point.find('name');
    const name = pointName ? pointName.getText() : '';

    errorMessages.push(`ERROR: no coordinates found for point '${name}'`);
  }

  return [lat, lon];
};
