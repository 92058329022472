import { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import MapsScheduleCollection from './maps/MapsScheduleCollection';
import ScheduleFilter from './common/ScheduleFilter';
import { AirtableRecord } from '../../db';

interface MapsScheduleViewProps {
  loadingSchedule: boolean;
  loadMissionMaps: (id: string) => Promise<void>;
  loadSchedule: (forceRefresh: boolean) => Promise<void>;
  scheduleItems: AirtableRecord[];
}

interface MapsScheduleViewState {
  filterText: string;
}

export default class MapsScheduleView extends Component<MapsScheduleViewProps, MapsScheduleViewState> {
  constructor(props: MapsScheduleViewProps) {
    super(props);
    this.state = {
      filterText: '',
    };

    this.updateFilter = this.updateFilter.bind(this);
  }

  updateFilter(filterText: string) {
    this.setState({ filterText });
  }

  render() {
    return (
      <Paper style={{ width: '100%', marginTop: 10 }}>
        <Grid container spacing={1}>
          <ScheduleFilter
            updateFilter={this.updateFilter}
            loadSchedule={this.props.loadSchedule}
            filterText={this.state.filterText}
          />

          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: 5, margin: 10 }}>
              {/* missions loading */}
              {this.props.loadingSchedule ? (
                <LinearProgress color="secondary" style={{ margin: 20 }} />
              ) : this.props.scheduleItems.length === 0 ? (
                <List dense>No missions found.</List>
              ) : (
                <MapsScheduleCollection
                  items={this.props.scheduleItems}
                  filterText={this.state.filterText}
                  loadMissionMaps={this.props.loadMissionMaps}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
