import { Button, ListItem, Portal } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { alertConfirm } from '../alertDispatcher';
import { getCurrentSession } from '../dataModelHelpers';
import logger from '../logger';

interface LogoutProps {
  sidebarToggleFunction: () => void;
  buttonsRef?: HTMLDivElement;
}

export default class Logout extends PureComponent<LogoutProps> {
  constructor(props: LogoutProps) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  async logout() {
    const confirm = await alertConfirm(
      'Logging out will cause you to lose your data, are you sure you want to logout?',
    );
    if (confirm) {
      // TODO download recovery file?
      this.props.sidebarToggleFunction();
      const session = getCurrentSession();
      if (!session) {
        await logger.log(`LOGOUT`, `Experienced no valid session on logout, is this normal?`);
      }
      await session?.logout();
    }
  }

  render() {
    return (
      <Portal container={this.props.buttonsRef}>
        <ListItem>
          <Button
            style={{ color: 'white', textTransform: 'capitalize', width: '200px', justifyContent: 'left' }}
            onClick={this.logout}
          >
            Logout
          </Button>
        </ListItem>
      </Portal>
    );
  }
}
