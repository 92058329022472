import axios from 'axios';
import logger from './logger';
import { Coordinate } from 'ol/coordinate';

// TODO I should override this to use a local server for development
const SNS_GATEWAY_URL = () => 'https://xaz7vyhu5ax2bmkioaul76nsim0vssxa.lambda-url.us-east-2.on.aws';

const DEFAULT_TIMEOUT = 5000;

export const postCustomerNotificiation = async (
  jobId: string,
  userPosition: Coordinate,
  onRobot = false,
  timeout = DEFAULT_TIMEOUT,
) => {
  return await postSNSMessage(
    'customer-notifier-topic.fifo',
    JSON.stringify({ job_id: jobId, time: new Date().toISOString(), position: userPosition, onRobot }),
    timeout,
  );
};

export const postSNSMessage = async (snsTopic: string, message: string, timeout = DEFAULT_TIMEOUT) => {
  try {
    // curl -X 'POST' \
    //   publish_message?topic=customer-notifier-topic.fifo&message=%7B%20%22job_id%22%3A%20%22recMnAPJ1LiJGa1fw%22%20%7D&group_id=default' \
    //   -H 'accept: application/json' \
    //   -d ''

    // const response = await axios.post(`${SNS_GATEWAY_URL()}/publish_message`, {
    //     topic: snsTopic,
    //     message,
    //     group_id: 'default'
    // }, {
    //     headers: {
    //         'Cache-Control': 'no-cache',
    //         'Expires': '0'
    //     },
    //     timeout
    // });

    const requestUrl = `${SNS_GATEWAY_URL()}/publish_message?topic=${encodeURIComponent(snsTopic)}&message=${encodeURIComponent(message)}&group_id=default`;
    console.log(requestUrl);
    const response = await axios.post(
      requestUrl,
      {},
      {
        headers: {
          'Cache-Control': 'no-cache',
          Expires: '0',
        },
        timeout,
      },
    );

    return response.status >= 200 && response.status < 300;
  } catch (err) {
    await logger.log('SNS_UTILITIES', { timeout, err });
    return false;
  }
};
