import React, { PureComponent, KeyboardEvent } from 'react';

import { TextField, Grid } from '@material-ui/core';

interface DropoffFilterProps {
  filterText: string;
  updateFilter: (filter: string) => void;
}

export default class DropoffFilter extends PureComponent<DropoffFilterProps> {
  handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') (event.target as HTMLDivElement).blur();
  };

  render() {
    return (
      <Grid item xs={12} style={{ textAlign: 'center', marginBottom: 5 }}>
        <TextField
          type="text"
          label="filter..."
          value={this.props.filterText}
          onChange={(e) => {
            this.props.updateFilter(e.target.value);
          }}
          inputProps={{ enterKeyHint: 'search' }}
          style={{ width: '95%' }}
          onKeyDown={this.handleKeyDown}
        />
      </Grid>
    );
  }
}
