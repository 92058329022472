import React, { PureComponent } from 'react';
import { Grid, FormControl, Checkbox } from '@material-ui/core';
import { CoreCountEnforcementEnabled } from '../../../db/local_storage';

interface CoreEnforcementCheckboxState {
  checked: boolean;
}

export default class CoreEnforcementCheckbox extends PureComponent<{}, CoreEnforcementCheckboxState> {
  constructor(props) {
    super(props);

    this.state = {
      checked: CoreCountEnforcementEnabled.get(),
    };
  }

  render() {
    return (
      <Grid item>
        <FormControl variant="outlined">
          <div>
            <Checkbox
              checked={this.state.checked}
              onChange={(e) => {
                console.log('CoreEnforcementCheckbox', e.target.checked, localStorage.getItem('coreCheckEnabled'));
                this.setState({ checked: e.target.checked });
                CoreCountEnforcementEnabled.set(e.target.checked);
                console.log('CoreEnforcementCheckbox', e.target.checked, localStorage.getItem('coreCheckEnabled'));
              }}
            />
            Core Enforcement
          </div>
        </FormControl>
      </Grid>
    );
  }
}
