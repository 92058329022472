import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';
import logger from '../logger';
import Permission, { PermissionList } from './PermissionClass';
import Session, { SessionList } from './SessionClass';
import { ITask } from './types';

export const enum TaskName {
  MAPS = 'maps',
  SAMPLING = 'sampling',
  SHIPPING = 'shipping',
  SCHEDULING = 'scheduling',
  DIRECTIONS = 'directions',
}
export default class Task extends ObjectClassGenerator<Task>('Task') implements ITask {
  // attributes
  #name: TaskName;
  #order: number;
  #screen_name: string;
  #local: boolean;
  #sampling: boolean;
  #robot: boolean;

  static tableName = 'Task';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Task, 'name');
    this.publishAttribute(Task, 'order');
    this.publishAttribute(Task, 'screen_name');
    this.publishAttribute(Task, 'local');
    this.publishAttribute(Task, 'sampling');
    this.publishAttribute(Task, 'robot');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Task> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#name = state.name || TaskName.SAMPLING;
    this.#order = state.order || state._instance_id!;
    this.#screen_name = state.screen_name || '';
    this.#local = state.local || false;
    this.#sampling = state.sampling || false;
    this.#robot = state.robot || false;
  }

  dispose() {
    logger.log('TASK_DISPOSE', `${this.#name}, ${this.#sampling}, ${this.#screen_name}`);

    for (const permission of this.getPermissions()) {
      permission.Task_id = undefined;
    }

    const session = this.getSession();
    if (session) {
      session.Task_id = undefined;
      session.dispose();
    }

    Task.delete(this.instance_id);
  }

  set name(value) {
    this.#name = value;
    this.syncToDB();
  }

  get name() {
    return this.#name;
  }

  set order(value) {
    this.#order = value;
    this.syncToDB();
  }

  get order() {
    return this.#order;
  }

  set screen_name(value) {
    this.#screen_name = value;
    this.syncToDB();
  }

  get screen_name() {
    return this.#screen_name;
  }

  set local(value) {
    this.#local = value;
    this.syncToDB();
  }

  get local() {
    return this.#local;
  }

  set sampling(value) {
    this.#sampling = value;
    this.syncToDB();
  }

  get sampling() {
    return this.#sampling;
  }

  set robot(value) {
    this.#robot = value;
    this.syncToDB();
  }

  get robot() {
    return this.#robot;
  }

  getPermissions() {
    if (this._refs && this._refs.Permission) {
      return new PermissionList(
        ...Array.from(this._refs.Permission)
          .map((id) => Permission.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const permissions = Permission.query((sel) => sel && sel.Task_id === this.instance_id);
      for (const permission of permissions) {
        permission.Task_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new PermissionList(...permissions);
    }
  }

  getSession() {
    if (this._refs && this._refs.Session) {
      const result = Array.from(this._refs.Session).map((id) => Session.get(id));
      return result[0];
    } else {
      const session = Session.findOne((sel) => sel?.Task_id === this.instance_id);
      if (session) {
        session.Task_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return session;
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Task.query((sel) => sel.instance_id !== this.instance_id && sel.name === this.name);
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate task found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`task: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    return problems;
  }
}

export class TaskList extends DataObjectList<Task> {
  getPermissions() {
    return new PermissionList(
      ...this.reduce((permissions: Permission[], task) => permissions.concat(task.getPermissions()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getSessions() {
    return new SessionList(...this.map((task) => task.getSession()).filter((sel) => !!sel));
  }
}
