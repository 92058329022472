import React, { PureComponent } from 'react';

import { Grid, Paper, TextField, RadioGroup, FormControlLabel } from '@material-ui/core';
import StyledRadio from '../utils/CustomRadioButton';

import { alertError } from '../../alertDispatcher';
import { LoadingButton } from '../utils';
import { SetStateAsync, setStateAsync } from '../../utils';
import { Coordinate } from 'ol/coordinate';
import { SetWaypointFunction } from '../../types/types';

interface SetWaypointProps {
  setWaypoint: SetWaypointFunction;
  robotMission: any;
  last_sample: string;
}

type SelectedRadio = 'sample id' | 'waypoint number' | 'go to start' | 'go to end';

interface SetWaypointState {
  selectedRadio: SelectedRadio; // TODO This could be an enum...
  waypointNumber: string;
  sampleId: string;
  redoingSample: boolean;
  settingWaypoint: boolean;
}

export default class SetWaypoint extends PureComponent<SetWaypointProps, SetWaypointState> {
  setStateAsync: SetStateAsync;

  constructor(props: SetWaypointProps) {
    super(props);

    this.state = {
      selectedRadio: 'sample id',
      waypointNumber: '',
      sampleId: '',
      redoingSample: false,
      settingWaypoint: false,
    };

    this.setWaypoint = this.setWaypoint.bind(this);
    this.redoLastSample = this.redoLastSample.bind(this);
    this.setStateAsync = setStateAsync.bind(this);
  }

  async setWaypoint() {
    const doneCallback = () => this.setState({ settingWaypoint: false });

    await this.setStateAsync({ settingWaypoint: true });

    if (this.state.selectedRadio === 'sample id') {
      await this.props.setWaypoint(2, this.state.sampleId, null, this.constructor.name, doneCallback, undefined);
    } else if (this.state.selectedRadio === 'waypoint number') {
      await this.props.setWaypoint(4, null, this.state.waypointNumber, this.constructor.name, doneCallback, undefined);
    } else if (this.state.selectedRadio === 'go to start') {
      await this.props.setWaypoint(1, null, null, this.constructor.name, doneCallback, undefined);
    } else if (this.state.selectedRadio === 'go to end') {
      await this.props.setWaypoint(5, null, null, this.constructor.name, doneCallback, undefined);
    }
  }

  async redoLastSample() {
    const lastSample = this.props.robotMission.waypoints.find((wp) => wp.sample_id === this.props.last_sample);
    if (lastSample) {
      await this.props.setWaypoint(
        2,
        this.props.last_sample,
        null,
        this.constructor.name,
        () => this.setState({ redoingSample: false }),
        undefined,
      );
    } else {
      alertError('Last sample does not exist!');
    }
  }

  render() {
    return (
      <Paper style={{ padding: 10, margin: 10 }}>
        <RadioGroup
          row
          value={this.state.selectedRadio}
          onChange={(e) => this.setState({ selectedRadio: e.target.value as SelectedRadio })}
          style={{ marginLeft: 10 }}
        >
          <FormControlLabel control={<StyledRadio value={'sample id'} color={'primary'} />} label={'Start at sample'} />
          <FormControlLabel
            control={<StyledRadio value={'waypoint number'} color={'primary'} />}
            label={'Start at waypoint'}
          />
          <FormControlLabel control={<StyledRadio value={'go to start'} color={'primary'} />} label={'Go to start'} />
          <FormControlLabel control={<StyledRadio value={'go to end'} color={'primary'} />} label={'Go to end'} />
        </RadioGroup>
        {(this.state.selectedRadio === 'sample id' || this.state.selectedRadio === 'waypoint number') && (
          <Grid container style={{ marginTop: 20, marginBottom: 20, marginLeft: 10 }}>
            {this.state.selectedRadio === 'sample id' && (
              <TextField
                variant="outlined"
                label={'Sample ID'}
                size={'small'}
                value={this.state.sampleId}
                onChange={(e) => this.setState({ sampleId: e.target.value })}
              />
            )}
            {this.state.selectedRadio === 'waypoint number' && (
              <TextField
                variant="outlined"
                label={'Waypoint Number'}
                size={'small'}
                value={this.state.waypointNumber}
                onChange={(e) => this.setState({ waypointNumber: e.target.value })}
              />
            )}
          </Grid>
        )}
        <Grid container direction="row" style={{ padding: 10 }}>
          <LoadingButton
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={this.setWaypoint}
            loading={Boolean(this.state.settingWaypoint)}
            disabled={Boolean(this.state.redoingSample)}
          >
            Set Waypoint
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            onClick={this.redoLastSample}
            disabled={Boolean(this.state.settingWaypoint)}
            loading={Boolean(this.state.redoingSample)}
          >
            Redo Last Sample
          </LoadingButton>
        </Grid>
      </Paper>
    );
  }
}
