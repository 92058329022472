import React, { PureComponent } from 'react';

import EventBus from '../EventBus';

import { FaCircle } from 'react-icons/fa';

import { Grid } from '@material-ui/core';
import { getCurrentSession } from '../dataModelHelpers';
import logger from '../logger';
import { RobotControlRole } from '../types/rosmsg';

interface RobotStatusProps {
  connectionStatus: string;
}

interface RobotStatusState {
  robotStatus: string;
  robotName: string;
  controlRole: RobotControlRole;
}

export default class RobotStatus extends PureComponent<RobotStatusProps, RobotStatusState> {
  constructor(props: RobotStatusProps) {
    super(props);

    this.state = {
      robotStatus: '',
      robotName: '',
      controlRole: 'unknown',
    };

    this.updateRobotStatus = this.updateRobotStatus.bind(this);
    this.updateRobotName = this.updateRobotName.bind(this);
    this.updateRobotControlRole = this.updateRobotControlRole.bind(this);
  }

  componentDidMount() {
    EventBus.on('ROSMSG/robot_state', this.updateRobotStatus, true);
    EventBus.on('ROSMSG/robot_name', this.updateRobotName, true);
    EventBus.on('ROBOT_ROLE_UPDATED', this.updateRobotControlRole, true);
  }

  componentWillUnmount() {
    EventBus.remove('ROSMSG/robot_state', this.updateRobotStatus);
    EventBus.remove('ROSMSG/robot_name', this.updateRobotName);
    EventBus.remove('ROBOT_ROLE_UPDATED', this.updateRobotControlRole);
  }

  updateRobotControlRole(role: RobotControlRole) {
    this.setState({
      controlRole: role,
    });
  }

  async updateRobotStatus({ hostname, msg }: { hostname: string; msg: { data: string } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      this.setState({ robotStatus: msg.data });
    }
  }

  async updateRobotName({ hostname, msg }: { hostname: string; msg: { data: string } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      const robotName = msg.data;
      logger.setRobot(robotName);
      session.robot_name = robotName;
      this.setState({ robotName });
    }
  }

  render() {
    let className = 'disconnected-status';
    switch (this.props.connectionStatus) {
      case 'connected':
        if (this.state.controlRole === 'view') {
          className = 'Blink connected-view-status';
        } else {
          className = 'connected-status';
        }
        break;
      case 'connecting':
        className = 'Blink connecting-status';
        break;
      case 'disconnected':
        className = 'disconnected-status';
        break;
    }
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginRight: 15 }}>
        <FaCircle className={className} />
        {this.state.robotName !== undefined && this.props.connectionStatus === 'connected'
          ? `${this.state.robotName}`
          : 'Robot'}
        &nbsp;
        {this.state.robotStatus !== undefined && this.props.connectionStatus === 'connected'
          ? `(${this.state.robotStatus})`
          : ''}
        {/* <Grid item xs={2}>
                    <FaCircle
                        className={
                            this.props.connectionStatus === 'connected' ?
                                'connected-status' : this.props.connectionStatus === 'connecting' ?
                                    'Blink connecting-status' : 'Blink disconnected-status'
                        }
                    />
                </Grid>

                <Grid item xs={10}>
                    {this.state.robotName !== undefined && this.props.connectionStatus === 'connected' ? `${this.state.robotName}` : 'Robot'}&nbsp;
                    {this.state.robotStatus !== undefined && this.props.connectionStatus === 'connected' ? `(${this.state.robotStatus})` : ''}
                </Grid> */}
      </Grid>
    );
  }
}
