import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Mission, { MissionList } from './MissionClass';
import Sample, { SampleList } from './SampleClass';

import { update_parameters } from '../db_ops/sampling_spec_ops';
import GridPatterns from './GridPatternsDatatype';
import { ISamplingSpec, SamplingSpecCreateParams } from './types';
import { KMLElement } from '../kml';
import { uuidv4 } from '../utils';
import { FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB } from '../constants';

export const SAMPLING_SPEC_DEFAULT_DEPTH = 8.0;

export default class SamplingSpec extends ObjectClassGenerator<SamplingSpec>('SamplingSpec') implements ISamplingSpec {
  // attributes
  #spec_id: string;
  #deal_id: string;
  #cores: number;
  #pattern_type: GridPatterns;
  #radius: number;
  #length: number;
  #angle: number;
  #start_depth: number;
  #end_depth: number;
  #test_package: string;
  #lab_name: string;
  #lab_short_name: string;
  #lab_address: string;
  #lab_code: string;
  #primary_spec: boolean;
  #parallel_line_distance_ft: number;
  #barcode_regex: string | undefined;
  // relationships
  #Mission_id?: number;

  static tableName = 'SamplingSpec';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SamplingSpec, 'spec_id');
    this.publishAttribute(SamplingSpec, 'deal_id');
    this.publishAttribute(SamplingSpec, 'cores');
    this.publishAttribute(SamplingSpec, 'pattern_type');
    this.publishAttribute(SamplingSpec, 'radius');
    this.publishAttribute(SamplingSpec, 'length');
    this.publishAttribute(SamplingSpec, 'angle');
    this.publishAttribute(SamplingSpec, 'start_depth');
    this.publishAttribute(SamplingSpec, 'end_depth');
    this.publishAttribute(SamplingSpec, 'Mission_id');
    this.publishAttribute(SamplingSpec, 'test_package');
    this.publishAttribute(SamplingSpec, 'lab_name');
    this.publishAttribute(SamplingSpec, 'lab_short_name');
    this.publishAttribute(SamplingSpec, 'lab_address');
    this.publishAttribute(SamplingSpec, 'lab_code');
    this.publishAttribute(SamplingSpec, 'primary_spec');
    this.publishAttribute(SamplingSpec, 'parallel_line_distance_ft');
    this.publishAttribute(SamplingSpec, 'barcode_regex');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SamplingSpec> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#spec_id = state.spec_id || uuidv4();
    this.#cores = state.cores || 0;
    this.#pattern_type = state.pattern_type || 0;
    this.#radius = state.radius || 0.0;
    this.#length = state.length || 0.0;
    this.#angle = state.angle || 0.0;
    this.#start_depth = state.start_depth || 0.0;
    this.#end_depth = state.end_depth || 0.0;
    this.#Mission_id = state.Mission_id;
    this.#test_package = state.test_package || '';
    this.#lab_name = state.lab_name || '';
    this.#lab_short_name = state.lab_short_name || '';
    this.#lab_address = state.lab_address || '';
    this.#lab_code = state.lab_code || '';
    this.#deal_id = state.deal_id || '';
    this.#primary_spec = state.primary_spec || false;
    this.#parallel_line_distance_ft = state.parallel_line_distance_ft ?? FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB;
    this.#barcode_regex = state.barcode_regex || undefined;
  }

  dispose() {
    const mission = this.getMission();
    if (mission) {
      this.Mission_id = undefined;
    }
    for (const sample of this.getSamples()) {
      sample.SamplingSpec_id = undefined;
      sample.dispose();
    }

    SamplingSpec.delete(this.instance_id);
  }

  set spec_id(value) {
    this.#spec_id = String(value);
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get spec_id() {
    return this.#spec_id;
  }

  set deal_id(value) {
    this.#deal_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get deal_id() {
    return this.#deal_id;
  }

  get barcode_regex() {
    return this.#barcode_regex;
  }

  set barcode_regex(value) {
    this.#barcode_regex = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  set cores(value) {
    this.#cores = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get cores() {
    return this.#cores;
  }

  set pattern_type(value) {
    this.#pattern_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pattern_type() {
    return this.#pattern_type;
  }

  set radius(value) {
    this.#radius = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get radius() {
    return this.#radius;
  }

  set length(value) {
    this.#length = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get length() {
    return this.#length;
  }

  set angle(value) {
    this.#angle = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get angle() {
    return this.#angle;
  }

  set start_depth(value) {
    this.#start_depth = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get start_depth() {
    return this.#start_depth;
  }

  set end_depth(value) {
    this.#end_depth = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get end_depth() {
    return this.#end_depth;
  }

  set test_package(value) {
    this.#test_package = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get test_package() {
    return this.#test_package;
  }

  set lab_name(value) {
    this.#lab_name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_name() {
    return this.#lab_name;
  }

  set lab_short_name(value) {
    this.#lab_short_name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_short_name() {
    return this.#lab_short_name;
  }

  set lab_address(value) {
    this.#lab_address = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_address() {
    return this.#lab_address;
  }

  set lab_code(value) {
    this.#lab_code = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_code() {
    return this.#lab_code;
  }

  get mission_name() {
    return this.getMission()?.name;
  }

  get last_modified() {
    return this.getMission()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getMission();
    if (inst && value) {
      inst.last_modified = value;
    }
  }

  get primary_spec() {
    return this.#primary_spec;
  }

  set primary_spec(value) {
    this.#primary_spec = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get parallel_line_distance_ft() {
    return this.#parallel_line_distance_ft;
  }

  set parallel_line_distance_ft(value) {
    this.#parallel_line_distance_ft = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SamplingSpec', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SamplingSpec', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  getMission() {
    return Mission.get(this.Mission_id);
  }

  getSamples() {
    if (this._refs && this._refs.Sample) {
      return new SampleList(
        ...Array.from(this._refs.Sample)
          .map((id) => Sample.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const samples = Sample.query((sel) => sel && sel.SamplingSpec_id === this.instance_id);
      for (const sample of samples) {
        sample.SamplingSpec_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SampleList(...samples);
    }
  }

  /**
   *
   * @param mission_id
   * @param doc
   */
  static toKml(mission_id: number, doc: KMLElement) {
    const mission = Mission.get(mission_id);
    const specs = mission?.getSamplingSpecs();
    // TODO it would be nice to serialize more helpful data right now we just
    const specsString = JSON.stringify(specs);
    doc.putExtendedData('specs', specsString);
  }

  /**
   * There are only two places where we create sampling specs:
   * 1. SpecKmlLoader - when loading a mission from KML 
   * 2. mission_ops -> update_mission_details - when loading a mission from shapefiles (i.e. creating it for the 1st time)
   */
  static createWithParams(params: SamplingSpecCreateParams) {
    const spec = SamplingSpec.create();

    spec.spec_id = params.spec_id;
    spec.Mission_id = params.Mission_id;
    spec.deal_id = params.deal_id;
    spec.primary_spec = params.primary_spec;
    spec.pattern_type = params.pattern_type;
    spec.cores = params.cores;
    spec.radius = params.radius;
    spec.length = params.length;
    spec.angle = params.angle;
    spec.start_depth = params.start_depth;
    spec.end_depth = params.end_depth || SAMPLING_SPEC_DEFAULT_DEPTH;
    spec.parallel_line_distance_ft = params.parallel_line_distance_ft;
    spec.barcode_regex = params.barcode_regex || undefined;
    spec.lab_name = params.lab_name || '';
    spec.lab_short_name = params.lab_short_name || '';
    spec.lab_address = params.lab_address || '';
    spec.lab_code = params.lab_code || '';
    spec.test_package = params.test_package || '';

    return spec;
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SamplingSpec.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.last_modified === this.last_modified &&
        sel.spec_id === this.spec_id,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate sampling spec found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`sampling spec: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getMission()) {
      problems.push(
        `sampling spec: Could not find mission instance across unconditional relationship R20: ${this.instance_id}`,
      );
    }
    return problems;
  }

  async update_parameters(params: Record<string, any>) {
    update_parameters.bind(this)(params);
  }
}

export class SamplingSpecList extends DataObjectList<SamplingSpec> {
  getMissions() {
    return new MissionList(...this.map((samplingspec) => samplingspec.getMission()).filter((sel) => !!sel));
  }

  getSamples() {
    return new SampleList(
      ...this.reduce((samples: Sample[], samplingspec) => samples.concat(samplingspec.getSamples()), []).filter(
        (sel) => !!sel,
      ),
    );
  }
}
