import {
  createCheckpoint,
  clearCheckpoints,
  loadLastCheckpoint,
  loadCheckpoint,
  getCheckpointNames,
} from './checkpoint';

import {
  DB_NAME,
  getDatabase,
  loadAllTables,
  loadPEI,
  requestDBSync,
  exportDB,
  tableList,
  DataLayerStorage,
} from './datamanager';

import AirtableRecord from './AirtableRecordClass';
import AirtableSearch from './AirtableSearchClass';
import Attachment from './AttachmentClass';
import Boundary from './BoundaryClass';
import CorePoint from './CorePointClass';
import Job from './JobClass';
import Mission from './MissionClass';
import PathPoint from './PathPointClass';
import Permission from './PermissionClass';
import Robot from './RobotClass';
import Role from './RoleClass';
import Sample from './SampleClass';
import SampleBox from './SampleBoxClass';
import SampleCentroid from './SampleCentroidClass';
import SampleSite from './SampleSiteClass';
import SampleZone from './SampleZoneClass';
import SamplingSpec from './SamplingSpecClass';
import SelectedRecord from './SelectedRecordClass';
import Session from './SessionClass';
import SoilCore from './SoilCoreClass';
import SoilDumpEvent from './SoilDumpEventClass';
import Task from './TaskClass';
import User from './UserClass';
import Waypoint from './WaypointClass';
import Zone from './ZoneClass';
import ZoneRecording from './ZoneRecordingClass';

import GridPatterns from './GridPatternsDatatype';
import SamplingTypes from './SamplingTypesDatatype';
import { ZoneType } from './ZoneTypesDatatype';

import { DexieTable } from './dexie_table';
import { IDBTable } from './idb_table';
import { DataObject } from './dataobject';
import BaseTable from './basetable';

export function createTable<T extends DataObject>(name: string): BaseTable<T> {
  if (DataLayerStorage.get() === 'idb') {
    return new IDBTable<T>(name);
  } else {
    return new DexieTable<T>(name);
  }
}

export {
  DB_NAME,
  getDatabase as initDatabase,
  loadAllTables,
  loadPEI,
  requestDBSync,
  createCheckpoint,
  clearCheckpoints,
  loadLastCheckpoint,
  loadCheckpoint,
  getCheckpointNames,
  exportDB,
  AirtableRecord,
  AirtableSearch,
  Attachment,
  Boundary,
  CorePoint,
  Job,
  Mission,
  PathPoint,
  Permission,
  Robot,
  Role,
  Sample,
  SampleBox,
  SampleCentroid,
  SampleSite,
  SampleZone,
  SamplingSpec,
  SelectedRecord,
  Session,
  SoilCore,
  SoilDumpEvent,
  Task,
  User,
  Waypoint,
  Zone,
  ZoneRecording,
  GridPatterns,
  SamplingTypes,
  ZoneType,
};

export function registerTables() {
  tableList['AirtableRecord'] = createTable<AirtableRecord>('AirtableRecord');
  tableList['AirtableSearch'] = createTable<AirtableSearch>('AirtableSearch');
  tableList['Attachment'] = createTable<Attachment>('Attachment');
  tableList['Boundary'] = createTable<Boundary>('Boundary');
  tableList['CorePoint'] = createTable<CorePoint>('CorePoint');
  tableList['Job'] = createTable<Job>('Job');
  tableList['Mission'] = createTable<Mission>('Mission');
  tableList['PathPoint'] = createTable<PathPoint>('PathPoint');
  tableList['Permission'] = createTable<Permission>('Permission');
  tableList['Robot'] = createTable<Robot>('Robot');
  tableList['Role'] = createTable<Role>('Role');
  tableList['Sample'] = createTable<Sample>('Sample');
  tableList['SampleBox'] = createTable<SampleBox>('SampleBox');
  tableList['SampleCentroid'] = createTable<SampleCentroid>('SampleCentroid');
  tableList['SampleSite'] = createTable<SampleSite>('SampleSite');
  tableList['SampleZone'] = createTable<SampleZone>('SampleZone');
  tableList['SamplingSpec'] = createTable<SamplingSpec>('SamplingSpec');
  tableList['SelectedRecord'] = createTable<SelectedRecord>('SelectedRecord');
  tableList['Session'] = createTable<Session>('Session');
  tableList['SoilCore'] = createTable<SoilCore>('SoilCore');
  tableList['SoilDumpEvent'] = createTable<SoilDumpEvent>('SoilDumpEvent');
  tableList['Task'] = createTable<Task>('Task');
  tableList['User'] = createTable<User>('User');
  tableList['Waypoint'] = createTable<Waypoint>('Waypoint');
  tableList['Zone'] = createTable<Zone>('Zone');
  tableList['ZoneRecording'] = createTable<ZoneRecording>('ZoneRecording');
}

export default function registerClasses() {
  registerTables();
}
