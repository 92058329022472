import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import User, { UserList } from './UserClass';
import Permission, { PermissionList } from './PermissionClass';
import { IRole } from './types';

export default class Role extends ObjectClassGenerator<Role>('Role') implements IRole {
  // attributes
  #name: string;
  #default_task: string;
  #session_expiration_days: number;

  static tableName = 'Role';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Role, 'name');
    this.publishAttribute(Role, 'default_task');
    this.publishAttribute(Role, 'session_expiration_days');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Role> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#name = state.name || '';
    this.#default_task = state.default_task || '';
    this.#session_expiration_days = state.session_expiration_days || 0;
  }

  dispose() {
    for (const user of this.getUsers()) {
      user.Role_id = undefined;
      user.dispose();
    }
    for (const permission of this.getPermissions()) {
      permission.Role_id = undefined;
    }

    Role.delete(this.instance_id);
  }

  get isAdmin() {
    return this.name === 'admin';
  }

  set name(value) {
    this.#name = value;
    this.syncToDB();
  }

  get name() {
    return this.#name;
  }

  set default_task(value) {
    this.#default_task = value;
    this.syncToDB();
  }

  get default_task() {
    return this.#default_task;
  }

  set session_expiration_days(value) {
    this.#session_expiration_days = value;
    this.syncToDB();
  }

  get session_expiration_days() {
    return this.#session_expiration_days;
  }

  getUsers() {
    if (this._refs && this._refs.User) {
      return new UserList(
        ...Array.from(this._refs.User)
          .map((id) => User.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const users = User.query((sel) => sel && sel.Role_id === this.instance_id);
      for (const user of users) {
        user.Role_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new UserList(...users);
    }
  }

  getPermissions() {
    if (this._refs && this._refs.Permission) {
      return new PermissionList(
        ...Array.from(this._refs.Permission)
          .map((id) => Permission.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const permissions = Permission.query((sel) => sel && sel.Role_id === this.instance_id);
      for (const permission of permissions) {
        permission.Role_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new PermissionList(...permissions);
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Role.query((sel) => sel.instance_id !== this.instance_id && sel.name === this.name);
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate role found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`role: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    return problems;
  }
}

export class RoleList extends DataObjectList<Role> {
  getUsers() {
    return new UserList(
      ...this.reduce((users: User[], role) => users.concat(role.getUsers()), []).filter((sel) => !!sel),
    );
  }

  getPermissions() {
    return new PermissionList(
      ...this.reduce((permissions: Permission[], role) => permissions.concat(role.getPermissions()), []).filter(
        (sel) => !!sel,
      ),
    );
  }
}
