import React, { PureComponent } from 'react';

import MapLoadingButton from './MapLoadingButton';

import { FiCheck } from 'react-icons/fi';

interface AssignmentButtonProps {
  onClick: () => Promise<void>;
  loading?: boolean;
}

export default class AssignmentButton extends PureComponent<AssignmentButtonProps> {
  render() {
    return (
      <MapLoadingButton
        style={{ backgroundColor: 'green', width: 200 }}
        onClick={this.props.onClick}
        loading={this.props.loading}
      >
        <FiCheck size={22} />
      </MapLoadingButton>
    );
  }
}
