import success_tone from './assets/sounds/apple_pay.wav';
import error_tone from './assets/sounds/buzzer.wav';
import warning_beep from './assets/sounds/warning_beep.wav';

export const successTone = () => playTone(success_tone);
export const warningTone = () => playTone(warning_beep);
export const errorTone = () => playTone(error_tone);

async function playTone(src: string) {
  const audio = new Audio(src);
  await audio.play();
}
