import { getDatabase, IntegrityError } from './datamanager';
import { DataObject } from './dataobject';
import { IDBPTransaction } from 'idb';
import BaseTable, { CHANGE_STATE } from './basetable';
import logger from '../logger';
import { DBPeriodicSync, DexieInterfaceV2, ForceSyncEnabled } from './local_storage';
import * as Sentry from '@sentry/react';

export class DexieTable<T extends DataObject> extends BaseTable<T> {
  async getAllItems(): Promise<T[]> {
    const db = getDatabase();
    const allData = (await db.table(this.tableName).toArray()) as T[];
    return allData;
  }

  async loadDataFromMemoryBackup(backIndex: number = 0) {
    const db = getDatabase();
    const memoryBackupToRestore = await db.MemoryBackup.orderBy('timestamp').offset(backIndex).last();
    if (!memoryBackupToRestore) {
      throw new Error(`No memory backup found at index ${backIndex}`);
    }
    const allData = JSON.parse(await memoryBackupToRestore.data.text())[this.tableName];

    return allData;
  }

  async restoreData(tx?: IDBPTransaction<any, string[], 'readwrite'>) {
    // new dexie interface
    let allData: any[] = [];
    if (DBPeriodicSync.get()) {
      try {
        allData = await this.loadDataFromMemoryBackup(0);
      } catch (err) {
        Sentry.captureException(err);
        allData = await this.loadDataFromMemoryBackup(2);
      }
    } else {
      const db = getDatabase();
      allData = await db.table(this.tableName).toArray();
    }

    if (!allData) return;

    this.dataList = {}; //new Map<number, T>;
    for (const value of allData) {
      this.lastDataIndex = value._instance_id;
      this.dataList[value._instance_id] = value;
    }
  }

  async getItem(dataKey: number): Promise<T | undefined> {
    const db = getDatabase();
    return db.table(this.tableName).get(dataKey);
  }

  async syncData() {
    console.log(`Syncing ${this.tableName}, ${Object.keys(this.changedListKey).length} records`);
    const db = getDatabase();
    const store = db.table(this.tableName);
    let recordsProcessed = 0;

    const changedList = { ...this.changedListKey };

    if (DexieInterfaceV2.get()) {
      for (const dataKey in changedList) {
        if (!dataKey || dataKey === 'undefined') {
          console.log('dataKey is undefined');
          return;
        }

        const changedState = changedList[dataKey];
        try {
          if (changedState === CHANGE_STATE.CREATED) {
            const newObj = this.dataList[dataKey];
            await store.add(newObj, newObj._instance_id);
          } else if (changedState === CHANGE_STATE.UPDATED) {
            const newObj = this.dataList[dataKey];
            newObj._version = (newObj._version || 0) + 1; // increment version
            await store.put(newObj, newObj._instance_id);
          } else if (changedState === CHANGE_STATE.DELETED) {
            await store.delete(parseInt(dataKey));
          }
          recordsProcessed += 1;
        } catch (e) {
          console.log('DEXIE_DB', `Error updating ${store.name} ${dataKey}: ${e}`);

          // If we have an error, do nothing?
          // throw e;
        }

        // delete this.deletedList[dataKey]
        delete this.changedListKey[dataKey];
      }

      this.defunctList = new Set();
    } else {
      if (this.requiresForceSync && ForceSyncEnabled.get()) {
        recordsProcessed = await this.forceSyncData();
      } else {
        for (const dataKey in this.changedListKey) {
          const changedState = this.changedListKey[dataKey];

          // generate an event for the update
          // TODO removing for now as no one listesn for it
          //EventBus.dispatch(DATA_SYNC_EVENT, { tableName: this.tableName, dataKey, changedState });
          // sync the object with the database

          let obj: DataObject | undefined = undefined;
          if (!dataKey || dataKey === 'undefined') {
            console.log('dataKey is undefined');
            return;
          }

          try {
            obj = await store.get(parseInt(dataKey));
          } catch (e) {
            // ignore
          }
          const newObj = this.dataList[dataKey];
          if (changedState === CHANGE_STATE.CREATED) {
            if (obj) {
              throw new IntegrityError(`Attempt to add ${store.name} that already exists: ${dataKey}.`);
            }

            await store.add(newObj, newObj._instance_id);
            // try {
            //   await store.add(newObj, newObj._instance_id);
            // } catch (e) {
            //   logger.log('DEXIE_BD', `Error adding to ${store.name} ${dataKey}: ${e}`);
            //   throw e;
            // }
            recordsProcessed += 1;
          } else if (changedState === CHANGE_STATE.UPDATED) {
            if (!obj) {
              throw new IntegrityError(`Attempt to update ${store.name} that does not exist: ${dataKey}`);
            } else if (obj._version && obj._version !== newObj._version) {
              // TODO temporarily disable this integrity error
              //throw new IntegrityError(`Attempt to update ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj._version}, Old: ${obj._version}`);
              // console.warn(`Attempt to update ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj?._version}, Old: ${obj?._version}`);
            }
            if (!newObj) return;
            newObj._version = (newObj._version || 0) + 1; // increment version
            await store.put(newObj, newObj._instance_id);
            // try {
            //   await store.put(newObj, newObj._instance_id);
            // } catch (e) {
            //   logger.log('DEXIE_BD', `Error updating ${store.name} ${dataKey}: ${e}`);
            //   throw e;
            // }
            recordsProcessed += 1;
          } else if (changedState === CHANGE_STATE.DELETED) {
            // const oldObj = this.deletedList[dataKey];
            // if (!obj) {
            //   throw new IntegrityError(`Attempt to delete ${store.name} that does not exist: ${dataKey}`);
            // } else if (obj?._version !== oldObj?._version) {
            //   // TODO temporarily disable this integrity error
            //   //throw new IntegrityError(`Attempt to delete ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj._version}, Old: ${obj._version}`);
            //   console.warn(`Attempt to delete ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj?._version}, Old: ${obj?._version}`);
            // }
            try {
              await store.delete(parseInt(dataKey));
            } catch (e) {
              // deletions aren't a huge deal so we'll let these go
              logger.log('DEXIE_BD', `Error deleting ${store.name} ${dataKey}: ${e}`);
              console.error(`Error deleting ${store.name} ${dataKey}: ${e}`);
            }
            recordsProcessed += 1;
          }
          // delete this.deletedList[dataKey]
          delete this.changedListKey[dataKey];
        }
        this.defunctList = new Set();
      }
    }

    // Attempt to delete Attachment that does not exist: 26629
    // Attempt to delete SampleBox that does not exist: 1
    // Failed to execute 'delete' on 'IDBObjectStore': The parameter is not a valid key. DataError: Failed to execute 'delete' on 'IDBObjectStore': The parameter is not a valid key.

    return recordsProcessed;
  }

  async forceSyncData() {
    const db = getDatabase();
    const store = db.table(this.tableName);
    let recordCount = 0;
    if (store) {
      // delete all not in the data list
      await store.clear();
      const toDelete = await store.toArray();
      recordCount = toDelete.length;
      const keysToDelete = toDelete.filter((inst) => !this.dataList[inst._instance_id]);
      // console.log('toDelete', toDelete);
      await store.bulkDelete(keysToDelete);

      // store the entire data list
      // await Promise.all(Object.values(this.dataList).map(inst => store.put(inst)));
      await store.bulkPut(Object.values(this.dataList));
    }
    // this.deletedList = {}; //new Map<number, T>;
    this.defunctList = new Set();
    this.changedListKey = {};
    this.requiresForceSync = false;

    return recordCount;
  }
}
