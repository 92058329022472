import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Sample, { SampleList } from './SampleClass';
import CorePoint, { CorePointList } from './CorePointClass';

import { to_kml, to_feature } from '../db_ops/soil_core_ops';
import { IKMLElement } from '../kml';
import { Coordinate } from 'ol/coordinate';
import { ISoilCore, SampleBoxDeSerialized } from './types';
import { CoreSource } from '../types/types';
import Mission from './MissionClass';
import SoilDumpEvent from './SoilDumpEventClass';
import { MM_PER_INCH, TARGET_DEPTH_CM_DEFAULT } from '../constants';
import CoreKmlLoader from '../services/mission-loading/original/from-kml/CoreKmlLoader';
import { isBulkDensityCore } from '../utils';

type CorePulledAttributes = {
  pulled_at: number;
  pulled_lat: number;
  pulled_lon: number;
  pulled_heading: number;
  SoilDumpEvent_id?: number;
  core_length_cm: number;
  depth_error: number;
  initiator?: number;
  test_core_Mission_id?: number;
  core_diameter_inches: string;
};

type CoreRestoreAttributes = CorePulledAttributes & {
  source: CoreSource;
};

export default class SoilCore extends ObjectClassGenerator<SoilCore>('SoilCore') implements ISoilCore {
  // attributes
  #core_number: number;
  #lat: number;
  #lon: number;
  #waypoint_index: number;

  // Attributes that we should save when moving a core/recalculating a path
  // and that we need to reset (except for #source) when skipping a sample/core
  #source: CoreSource;
  #pulled_at: number;
  #pulled_lat: number;
  #pulled_lon: number;
  #pulled_heading: number;
  #SoilDumpEvent_id?: number;
  #core_length_cm: number;
  #depth_error: number;
  #initiator?: number;
  #test_core_Mission_id?: number;
  #core_diameter_inches: string;

  // relationships
  #Sample_id?: number;

  static tableName = 'SoilCore';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SoilCore, 'core_number');
    this.publishAttribute(SoilCore, 'lat');
    this.publishAttribute(SoilCore, 'lon');
    this.publishAttribute(SoilCore, 'pulled_at');
    this.publishAttribute(SoilCore, 'pulled_lat');
    this.publishAttribute(SoilCore, 'pulled_lon');
    this.publishAttribute(SoilCore, 'pulled_heading');
    this.publishAttribute(SoilCore, 'source');
    this.publishAttribute(SoilCore, 'waypoint_index');
    this.publishAttribute(SoilCore, 'core_length_cm');
    this.publishAttribute(SoilCore, 'depth_error');
    this.publishAttribute(SoilCore, 'initiator');
    this.publishAttribute(SoilCore, 'test_core_Mission_id');
    this.publishAttribute(SoilCore, 'Sample_id');
    this.publishAttribute(SoilCore, 'SoilDumpEvent_id');
    this.publishAttribute(SoilCore, 'core_diameter_inches');

    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SoilCore> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#core_number = state.core_number || 0;
    this.#lat = state.lat || 0.0;
    this.#lon = state.lon || 0.0;
    this.#pulled_at = state.pulled_at || 0.0;
    this.#pulled_lat = state.pulled_lat || 0.0;
    this.#pulled_lon = state.pulled_lon || 0.0;
    this.#pulled_heading = state.pulled_heading || 0.0;
    this.#source = state.source || 'Unknown';
    this.#waypoint_index = state.waypoint_index || 0;
    this.#core_length_cm = state.core_length_cm || 0.0;
    this.#depth_error = state.depth_error || 0.0;
    this.#initiator = state.initiator || 0;
    this.#test_core_Mission_id = state.test_core_Mission_id;
    this.#Sample_id = state.Sample_id;
    this.#SoilDumpEvent_id = state.SoilDumpEvent_id;
    this.#core_diameter_inches = state.core_diameter_inches || '';
  }

  dispose() {
    const sample = this.getSample();
    if (sample) {
      this.Sample_id = undefined;
    }
    const corepoint = this.getCorePoint();
    if (corepoint) {
      corepoint.SoilCore_id = undefined;
      // because soil cores have a 1:1 relationship with corepoints,
      // then soil cores will dispose corepoints
      corepoint.dispose();
    }

    if (this.SoilDumpEvent_id) {
      this.SoilDumpEvent_id = undefined;
    }

    this.test_core_Mission_id = undefined;

    SoilCore.delete(this.instance_id);
  }

  set core_number(value) {
    this.#core_number = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get core_number() {
    return this.#core_number;
  }

  get name() {
    return `${this.getSample()?.sample_id ?? 'TEST'}.${this.core_number}`;
  }

  set lat(value) {
    this.#lat = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lat() {
    return this.#lat;
  }

  set lon(value) {
    this.#lon = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lon() {
    return this.#lon;
  }

  getCoordinates(): Coordinate {
    return [this.lat, this.lon];
  }

  set pulled_at(value) {
    this.#pulled_at = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pulled_at() {
    return this.#pulled_at;
  }

  set pulled_lat(value) {
    this.#pulled_lat = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pulled_lat() {
    return this.#pulled_lat;
  }

  set pulled_lon(value) {
    this.#pulled_lon = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pulled_lon() {
    return this.#pulled_lon;
  }

  set pulled_heading(value) {
    this.#pulled_heading = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pulled() {
    return !!this.pulled_lat && !!this.pulled_lon;
  }

  get dumped() {
    return !!this.SoilDumpEvent_id;
  }

  set source(value) {
    this.#source = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get source() {
    return this.#source;
  }

  set waypoint_index(value) {
    this.#waypoint_index = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get waypoint_index() {
    return this.#waypoint_index;
  }

  get waypoint_number(): number | undefined {
    return this.getCorePoint()?.waypoint_number;
  }

  set core_length_cm(value) {
    this.#core_length_cm = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get core_length_cm() {
    return this.#core_length_cm;
  }

  set core_diameter_inches(value) {
    this.#core_diameter_inches = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get core_diameter_inches() {
    return this.#core_diameter_inches;
  }

  set depth_error(value) {
    this.#depth_error = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get depth_error() {
    return this.#depth_error;
  }

  set initiator(value) {
    this.#initiator = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get initiator() {
    return this.#initiator;
  }

  set test_core_Mission_id(value) {
    if (this.#test_core_Mission_id) {
      const relateObj = Mission.get(this.#test_core_Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SoilCore', this.instance_id);
      }
    }
    this.#test_core_Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SoilCore', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get test_core_Mission_id() {
    return this.#test_core_Mission_id;
  }

  get last_modified() {
    return this.getSample()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getSample();
    if (inst) {
      inst.last_modified = value;
    }
  }

  get sample_id() {
    return this.getSample()?.sample_id;
  }

  get sample_type() {
    return this.getSample()?.sample_type;
  }

  get skipped_or_deleted() {
    return this.getSample()?.skipped_or_deleted;
  }

  set Sample_id(value: number | undefined) {
    if (this.#Sample_id) {
      const relateObj = Sample.get(this.#Sample_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SoilCore', this.instance_id);
      }
    }
    this.#Sample_id = value;
    if (value) {
      const relateObj = Sample.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SoilCore', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  set SoilDumpEvent_id(value) {
    if (this.#SoilDumpEvent_id) {
      const relateObj = SoilDumpEvent.get(this.#SoilDumpEvent_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SoilCore', this.instance_id);
      }
    }
    this.#SoilDumpEvent_id = value;
    if (value) {
      const relateObj = SoilDumpEvent.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SoilCore', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SoilDumpEvent_id() {
    return this.#SoilDumpEvent_id;
  }

  get Sample_id() {
    return this.#Sample_id;
  }

  restorePulledInfo(attributes: CoreRestoreAttributes) {
    this.#source = attributes.source;
    this.#pulled_at = attributes.pulled_at;
    this.#pulled_lat = attributes.pulled_lat;
    this.#pulled_lon = attributes.pulled_lon;
    this.#pulled_heading = attributes.pulled_heading;
    this.#SoilDumpEvent_id = attributes.SoilDumpEvent_id;
    this.#core_length_cm = attributes.core_length_cm;
    this.#depth_error = attributes.depth_error;
    this.#initiator = attributes.initiator;
    this.#test_core_Mission_id = attributes.test_core_Mission_id;
    this.#core_diameter_inches = attributes.core_diameter_inches;

    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  resetPulled() {
    this.#pulled_at = 0;
    this.#pulled_lat = 0;
    this.#pulled_lon = 0;
    this.#pulled_heading = 0;
    this.#SoilDumpEvent_id = undefined;
    this.#core_length_cm = 0;
    this.#depth_error = 0;
    this.#initiator = undefined;
    this.#test_core_Mission_id = undefined;
    this.core_diameter_inches = '';

    this.last_modified = Date.now() / 1000;
    this.syncToDB();

    const waypoint = this.getCorePoint()?.getWaypoint();
    if (waypoint) {
      waypoint.lat = this.lat;
      waypoint.lon = this.lon;
    }
  }

  displace(diffLat: number, diffLon: number) {
    return this.move(this.lat + diffLat, this.lon + diffLon);
  }

  move(lat: number, lon: number) {
    this.#lat = lat;
    this.#lon = lon;

    this.resetPulled();
  }

  getSample() {
    return Sample.get(this.Sample_id);
  }

  getCorePoint() {
    if (this._refs && this._refs.CorePoint) {
      const result = Array.from(this._refs.CorePoint).map((id) => CorePoint.get(id));
      return result[0];
    } else {
      const corepoint = CorePoint.findOne((sel) => sel?.SoilCore_id === this.instance_id);
      if (corepoint) {
        corepoint.SoilCore_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return corepoint;
    }
  }

  // TODO is this a good approach to this? Probably faster than the isPulledBDCore logic?
  // isBDCore() {
  //   return parseFloat(this.core_diameter_inches) === 2.0;
  // }

  isPulledBDCore() {
    return this.pulled && this.initiator !== undefined && isBulkDensityCore(this.initiator);
  }

  isInBucket() {
    return this.pulled && !this.dumped;
  }

  getSoilDumpEvent() {
    return SoilDumpEvent.get(this.SoilDumpEvent_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SoilCore.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.last_modified === this.last_modified &&
        sel.sample_id === this.sample_id &&
        sel.sample_type === this.sample_type &&
        sel.core_number === this.core_number,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate soil core found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`soil core: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getSample()) {
      problems.push(
        `soil core: Could not find sample instance across unconditional relationship R22: ${this.instance_id}`,
      );
    }
    return problems;
  }

  to_kml(use_pulled_core_locations: boolean = false) {
    return to_kml.bind(this)(use_pulled_core_locations);
  }

  static async load_kml(
    point: IKMLElement,
    mission: Mission,
    samples: Sample[],
    deSerializeBoxes: SampleBoxDeSerialized[],
  ) {
    const coreKmlLoader = new CoreKmlLoader(deSerializeBoxes);

    return await coreKmlLoader.load(point, mission, samples);
  }

  to_feature(use_pulled_location: boolean = false) {
    return to_feature.bind(this)(use_pulled_location);
  }

  getTargetDepth() {
    const samplingSpec = this.getSample()?.getSamplingSpec();
    if (!samplingSpec) {
      return TARGET_DEPTH_CM_DEFAULT;
    }
    return Math.round((MM_PER_INCH * samplingSpec.end_depth) / 10);
  }
}

export class SoilCoreList extends DataObjectList<SoilCore> {
  getSamples() {
    return new SampleList(...this.map((soilcore) => soilcore.getSample()).filter((sel) => !!sel));
  }

  getCorePoints() {
    return new CorePointList(...this.map((soilcore) => soilcore.getCorePoint()).filter((sel) => !!sel));
  }
}
