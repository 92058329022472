import React from 'react';

import List from '@material-ui/core/List';
import Pagination from '@material-ui/lab/Pagination';

import AirtableRecord from '../db/AirtableRecordClass';
import { SampleBox } from '../db';
import BoxHistoryItem from './BoxHistoryItem';
import { SettingsAccessLevel } from '../types/types';

const PAGE_SIZE = 10;

interface BoxHistoryCollectionProps {
  boxes: SampleBox[];
  showBoxCheckin: (box: SampleBox) => Promise<void>;
  showShipppingLabelPDF(box: SampleBox): Promise<void>;
  filterText?: string;
  title?: string;
  resubmitButton?: JSX.Element;
  defaultExpanded?: boolean;
  sortFunc?: (a: AirtableRecord, b: AirtableRecord) => number;
  showBoxCloseScreen: (box: SampleBox) => Promise<void>;
  accessLevel: SettingsAccessLevel;
}

function BoxHistoryCollection(props: BoxHistoryCollectionProps) {
  const [page, setPage] = React.useState(1);
  // TODO use filter function...
  //let allItems = props.items.filter(sel => !props.filterText || (sel.get('Name') as string).toLowerCase().includes(props.filterText.toLowerCase())).sort(props.sortFunc);
  let allItems = props.boxes.toSorted((boxA, boxB) => {
    // if (boxA.Session_id) {
    //   return -1;
    // }
    // if (boxB.Session_id) {
    //   return 1;
    // }
    if (!boxA.closed) {
      return -1;
    }
    if (!boxB.closed) {
      return 1;
    }
    return boxB.createdTimestamp.getTime() - boxA.createdTimestamp.getTime();
  });
  const count = Math.ceil(allItems.length / PAGE_SIZE);
  const p = Math.min(page, count);
  const items = allItems.slice((p - 1) * PAGE_SIZE, p * PAGE_SIZE);
  return (
    <>
      {allItems.length > PAGE_SIZE && (
        <Pagination
          count={count}
          page={p}
          variant="outlined"
          shape="rounded"
          onChange={(_e, p) => {
            setPage(p);
          }}
        />
      )}
      <List dense>
        {items.map((item) => {
          return (
            <BoxHistoryItem
              key={item.uid}
              box={item}
              showBoxCheckin={props.showBoxCheckin}
              showShippingLabelPDf={props.showShipppingLabelPDF}
              showBoxCloseScreen={props.showBoxCloseScreen}
              accessLevel={props.accessLevel}
            />
          );
        })}
      </List>
    </>
  );
}

export default BoxHistoryCollection;
