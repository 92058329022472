export const BLACK = [0, 0, 0];
export const WHITE = [255, 255, 255];
export const BLUE = [0, 0, 224];
export const LIGHT_BLUE = [82, 110, 224];
export const RED = [164, 22, 35];
export const BROWN = [141, 103, 8];
export const GREEN = [50, 100, 50];
export const MAGENTA = [255, 0, 255];
export const PLUM = [221, 160, 221];
export const BURLY_WOOD = [222, 184, 135];
export const ORANGE = [255, 165, 0];
export const YELLOW = [255, 255, 0];
