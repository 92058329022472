import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, TextField } from '@material-ui/core';
import { useState } from 'react';

interface UnknownDropoffDialogProps {
  unknownDropoffPopupVisible: boolean;
  setUnknownDropoffPopupVisible: (visible: boolean) => void;
  dropoffNotes: string;
  setDropoffNotes: (notes: string) => void;
}

export const UnknownDropoffDialog = ({
  unknownDropoffPopupVisible,
  setUnknownDropoffPopupVisible,
  dropoffNotes,
  setDropoffNotes,
}: UnknownDropoffDialogProps) => {
  const [notes, setNotes] = useState(dropoffNotes);
  return (
    <Dialog open={unknownDropoffPopupVisible} maxWidth={'md'} title="Dropoff Notes">
      <DialogTitle>
        <Typography variant="h5" align="center">
          Please type the name of your dropoff
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* // Text input box */}
        <TextField
          label="Dropoff Name, Additional Notes"
          multiline
          minRows={3}
          fullWidth
          variant="outlined"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            setDropoffNotes('');
            setUnknownDropoffPopupVisible(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            setDropoffNotes(notes);
            setUnknownDropoffPopupVisible(false);
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};
