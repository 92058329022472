import { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { getCurrentMission } from '../../../dataModelHelpers';
import { CropTypes, Residue, ResidueDepthMap, ResidueHelperText, ResidueOptions } from '../../../db/types';
import {
  Box,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { sendROSConfig } from '../../../utilities/rosUtils';
import { convertDepthOffset } from '../../../utils';
import { SettingsAccessLevel } from '../../../types/types';
import { FaLockOpen } from 'react-icons/fa';
import DeepRipped from '../../../assets/img/DeepRippedSmall.png';
import MediumResidue from '../../../assets/img/MediumResidueSmall.png';
import HeavyResidue from '../../../assets/img/HeavyResidueSmall.png';
import { alertWarn } from '../../../alertDispatcher';

const ResidueImages: Record<Residue, string> = {
  '': '',
  'Super Wet Bare Ground': '',
  Normal: '',
  'Deep Ripped': DeepRipped,
  'Medium Residue': MediumResidue,
  'Heavy Residue': HeavyResidue,
};
interface SamplingEntranceInterviewProps {
  open: boolean;
  closeForm: () => void;
  currentDepthOffset?: number;
  accessLevel: SettingsAccessLevel;
  elevatePermissions: () => void;
}

interface SamplingEntranceInterviewState {
  completeOnOpen: boolean;
  cropType: string;
  residue: Residue;
  depthOffset: number;
}

export default class SamplingEntranceInterview extends Component<
  SamplingEntranceInterviewProps,
  SamplingEntranceInterviewState
> {
  constructor(props: SamplingEntranceInterviewProps) {
    super(props);
    this.state = {
      completeOnOpen: false,
      cropType: '',
      residue: '',
      depthOffset: 0,
    };
  }

  componentDidUpdate(oldProps: SamplingEntranceInterviewProps, oldState: SamplingEntranceInterviewState) {
    if (this.props.open && !oldProps.open) {
      // Do some stuff
      const mission = getCurrentMission();
      if (!mission) {
        console.error('Mission not found');
        return;
      }

      const job = mission.getJob();
      if (!job) {
        console.error('Job not found');
        return;
      }

      const completeOnOpen = job.entrance_interview_complete;
      const residue = job.entrance_interview_residue;
      const cropType = job.entrance_interview_crop;

      this.setState({ completeOnOpen, residue, cropType });
    }

    if (oldState.residue !== this.state.residue) {
      this.setState({ depthOffset: ResidueDepthMap[this.state.residue] });
    }
  }

  handleSubmission = async () => {
    const mission = getCurrentMission();
    if (!mission) {
      alertWarn('Mission not found');
      console.error('Mission not found');

      return;
    }

    const job = mission.getJob();
    if (!job) {
      alertWarn('Job not found');
      console.error('Job not found');

      return;
    }

    job.entrance_interview_residue = this.state.residue;
    job.entrance_interview_crop = this.state.cropType;
    sendROSConfig(
      'depth_offset',
      this.state.depthOffset,
      'Depth Offset',
      this.props.currentDepthOffset || 0,
      true,
      convertDepthOffset,
    );

    this.props.closeForm();
  };

  handleClear = () => {
    this.props.closeForm();
  };

  render() {
    // Show an error depending on completed or partial mission
    const { cropType, residue } = this.state;
    const otherSelected = cropType.startsWith('Other');
    const cropTokens = cropType.split(':');
    const otherCropEntered = cropTokens.length > 1 && cropTokens[1].length >= 4;
    const cropComplete = cropType !== '' && (!otherSelected || otherCropEntered);
    const formComplete = residue !== '' && cropComplete;
    const accessLocked = this.props.accessLevel === 'Locked';
    const deepRippedBlocked = this.state.residue === 'Deep Ripped' && accessLocked;
    const saveDisabled = !formComplete || deepRippedBlocked;
    const residueImage = ResidueImages[this.state.residue];
    const deeperOrShallower = this.state.depthOffset > 0 ? '(deeper)' : this.state.depthOffset < 0 ? '(shallower)' : '';
    const offsetSign = this.state.depthOffset > 0 ? '+' : '';

    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth={'lg'}
        open={this.props.open || false}
        fullWidth
        onClose={(_event, reason) => {
          if (reason === 'backdropClick') return;
          this.props.closeForm();
        }}
      >
        <DialogTitle>Entrance Interview</DialogTitle>
        <DialogContent>
          <hr style={{ border: '1px solid black', width: '100%' }} />
          <InputLabel style={{ fontSize: 20 }}>Previous Crop</InputLabel>
          <RadioGroup
            row
            value={this.state.cropType.split(':')[0]}
            onChange={(e) => {
              this.setState({ cropType: e.target.value });
            }}
          >
            {CropTypes.filter((s) => !!s).map((crop) => (
              <FormControlLabel
                key={crop}
                value={crop}
                control={<Radio />}
                label={
                  crop === 'Other' && otherSelected ? (
                    <Box>
                      <Typography>{crop}</Typography>
                      <TextField
                        disabled={!this.state.cropType.startsWith('Other')}
                        type="text"
                        placeholder="Enter Crop"
                        onChange={(e) => {
                          this.setState({ cropType: `Other: ${e.target.value}` });
                        }}
                      />
                    </Box>
                  ) : (
                    crop
                  )
                }
              />
            ))}
          </RadioGroup>

          {/* Make a black line to separate these questions */}
          <hr style={{ border: '1px solid black', width: '100%' }} />
          {/* Use a layout here to split between the radio group selection and a picture box */}
          <Box display="flex" flexDirection="row" justifyContent="left" alignItems="justify" style={{ gap: 10 }}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="left" style={{ gap: 10 }}>
              <InputLabel style={{ fontSize: 20 }}>Select Field Residue</InputLabel>
              <RadioGroup
                value={this.state.residue}
                onChange={(e) => {
                  this.setState({ residue: e.target.value as Residue });
                }}
              >
                {ResidueOptions.filter((r) => !!r).map((residue) => (
                  <Box key={residue} sx={{ m: 1, border: 1, borderColor: 'black', borderRadius: 5 }}>
                    <FormControlLabel
                      // disabled={residue === "Deep Ripped" && this.props.accessLevel === "Locked"}
                      key={residue}
                      value={residue}
                      control={<Radio />}
                      // label={`${residue} (${ResidueHelperText[residue]})`}
                      label={
                        <Typography>
                          {residue}
                          <br />
                          {ResidueHelperText[residue] ? `(${ResidueHelperText[residue]})` : ''}
                        </Typography>
                      }
                    />
                  </Box>
                ))}
              </RadioGroup>
            </Box>
            {/* Add a textbox here for the helper text */}
            {/* <Typography>{ResidueHelperText[this.state.residue]}</Typography> */}
            <Box
              style={{
                width: '70%',
                height: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',

                // width: 50%;
                // /* 50% of the viewport width */
                // height: 300px;
                // /* 50% of the viewport height */
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // overflow: hidden;
              }}
            >
              {!!residueImage && <img src={residueImage} alt="Crop Type" className="responsive-image" />}
            </Box>
            {/* {!!residueImage &&
                            <img src={residueImage} alt="Crop Type" />
                        } */}
          </Box>
          <Typography>
            Depth offset: {offsetSign}
            {this.state.depthOffset}" {deeperOrShallower}
          </Typography>
          {this.state.residue && this.state.residue !== 'Normal' && (
            <Typography style={{ color: 'red', fontSize: 32 }}>Unusual, call ops if unsure!</Typography>
          )}
        </DialogContent>
        <DialogActions>
          {deepRippedBlocked && (
            <Button
              variant="outlined"
              onClick={() => {
                this.props.elevatePermissions();
              }}
            >
              Unlock <FaLockOpen style={{ marginLeft: 7.5, marginRight: 7.5 }} />
            </Button>
          )}
          <Button variant="outlined" color="default" onClick={this.props.closeForm}>
            {this.state.completeOnOpen ? 'Cancel Update' : 'Snooze (5 minutes)'}
          </Button>
          <Button variant="contained" color="primary" disabled={saveDisabled} onClick={this.handleSubmission}>
            {this.state.completeOnOpen ? 'Update Interview' : 'Save Interview'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
