import React from 'react';

import Grid, { GridSize } from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

interface MissionInfoItemProps {
  label: string;
  value?: string | number;
  disabled?: boolean;
  multiline: boolean;
  type: string;
  width: {
    xs: GridSize;
    sm: GridSize;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MissionInfoItem(props: MissionInfoItemProps) {
  return (
    <Grid item xs={props.width.xs} sm={props.width.sm}>
      <InputLabel htmlFor={props.label}>{props.label}</InputLabel>
      <Input
        type={props.type}
        id={props.label}
        value={props.value ?? ''}
        disabled={props.disabled}
        multiline={props.multiline}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            e.target.blur();
          }
        }}
        inputProps={{
          enterKeyHint: 'done',
        }}
        onChange={props.onChange}
        fullWidth
      />
    </Grid>
  );
}
