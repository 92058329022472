import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import LoadingButton from '../../utils/LoadingButton';

import Airtable from '../../../airtable';
import logger from '../../../logger';
import { alertConfirm, alertSuccess, alertWarn } from '../../../alertDispatcher';

import { getCurrentMission, getCurrentSession, getCurrentUser } from '../../../dataModelHelpers';
import { dispatchMissionCreatedDeleted, MISSION_EVENTS } from '../../../missionEvents';
import { CustomCheckbox } from '../../utils/CustomCheckbox';
import { JobLoader, JobChecker } from '@rogoag/kml-error-checker';
import { Mission } from '../../../db';
import { MissionState } from '../../../constants';
import { Jobs } from '@rogoag/airtable';
import EventBus from '../../../EventBus';

const SYNC_CANCELLED = 4;
const SYNC_SUCCESS = 3;
const SYNC_FAILED = 0;

interface MapsExitInterviewProps {
  closeForm: () => void;
  open: boolean;
}

interface MapsExitInterviewState {
  confirmNoButton: boolean;
  managerReviewed: boolean;
  pointsModified: boolean;
  mapIssue: boolean;
  newMissionState: MissionState;
  opNotes: string | undefined;
  fieldNotes: string;
  submitFailed: boolean;
  checkSuccess: boolean;
  mapNotes: string | undefined;
}

export default class MapsExitInterview extends Component<MapsExitInterviewProps, MapsExitInterviewState> {
  closeTimeout: NodeJS.Timeout;

  constructor(props: MapsExitInterviewProps) {
    super(props);
    this.state = {
      confirmNoButton: false,
      managerReviewed: false,
      pointsModified: false,
      mapIssue: false,
      newMissionState: MissionState._4_Mission_Finalized,
      opNotes: '',
      fieldNotes: '',
      submitFailed: false,
      checkSuccess: false,
      mapNotes: '',
    };

    this.handleComplete = this.handleComplete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.finalizeMission = this.finalizeMission.bind(this);
  }

  async componentDidMount() {
    await this.initializeFromAirtable();
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.initializeFromAirtable);
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimeout);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.initializeFromAirtable);
  }

  handleCheck = async () => {
    const mission = getCurrentMission();
    if (!mission) {
      throw new Error('No mission found');
    }

    const kml = mission.to_kml({ insert_boxes: true });
    if (!kml) {
      throw new Error('No KML found');
    }
    let errors: any[] = [];
    try {
      const loader = new JobLoader({ data: kml.toString(), filename: '.kml' }); //, { expectedType: mission.getJob()?.sites_type, sampleIdColumn: "SampleID" });
      const job = await loader.parse();
      const validator = new JobChecker(job);
      errors = validator.validateRunnable();

      // TODO this should be a check in the kml error checker
      if (job.type === 'ZONE' && job.sampleZones.length) {
        const zonesWithNoCores = job.sampleZones
          .filter((zone) => zone.cores.length === 0)
          .filter((zone, index, zones) => zones.findIndex((z) => z.name === zone.name) === index);
        if (zonesWithNoCores.length) {
          errors.push(
            `Found ${zonesWithNoCores.length} zones with no cores (Zone IDs: ${zonesWithNoCores.map((zone) => zone.name).join(', ')})`,
          );
        }
      }

      this.setState({ checkSuccess: errors.length === 0 });
    } catch (err) {
      errors.push(err);
    }

    if (errors.length) {
      for (const error of errors) {
        if (typeof error === 'object' && 'rogo' in error && 'message' in error) {
          alertWarn(`Found mission problem: ${error.message}`);
        } else {
          alertWarn(`Found issue in mission: ${JSON.stringify(error)}`);
        }
      }
      const confirm = await alertConfirm('There are issues with this mission, are you sure you want to upload?');
      if (!confirm) {
        return false;
      }
    }

    return true;
  };

  async handleComplete() {
    this.setState({ submitFailed: false });
    const mission = getCurrentMission();
    if (!mission) {
      throw new Error('No mission found');
    }
    const jobId = mission.job_id;

    const result = await this.finalizeMission(mission);

    if (result === SYNC_SUCCESS) {
      // TODO if something failed, we should revert somehow...
      await this.handleClear({ resetMapmaker: false });
      alertSuccess(`Successfully updated job in Airtable ${jobId || ''}`);
    } else if (result === SYNC_FAILED) {
      this.setState({ submitFailed: true });
    }
  }

  async handleClose() {
    await logger.log('HANDLE_CLOSE');
    if (!this.state.confirmNoButton) {
      this.setState({ confirmNoButton: true });
      this.closeTimeout = setTimeout(() => {
        this.setState({ confirmNoButton: false });
      }, 2000);
    } else {
      await this.handleClear({ resetMapmaker: true });
    }
  }

  async handleClear({ resetMapmaker = false }) {
    await logger.log('HANDLE_CLEAR');
    const session = getCurrentSession();
    if (!session) {
      throw new Error('No session found');
    }
    const currentMission = getCurrentMission();
    if (!currentMission) {
      throw new Error('No mission found');
    }
    const job = await currentMission.get_airtable_job_record();

    // we will only enter this if this mapmaker did NOT create the mission
    const user = getCurrentUser();
    const mapmakers = job?.get('Mapmaker') as string[];
    const multipleMapmakers = mapmakers?.length > 1;
    const hasIntMission = job
      ? job.getAttachments().filter((attachment) => attachment?.field_name === 'Int Mission').length > 0
      : false;
    logger.log('HANDLE_CLEAR', { jobId: job?.id, user: user?.name, resetMapmaker, hasIntMission, multipleMapmakers });

    // A user can skip upload or upload a mission, which is what determins the resetMapmaker flag
    // However, if a user re-opens a map that THEY made, we don't want to reset the mapmaker
    // if they skip upload and don't change anything. In this case, they would be the only mapmaker
    // set because we filtered for unique mapmakers when we opened the map. So the "multipleMapmakers"
    // flag can tell us that.
    if (job && mapmakers && resetMapmaker && (!hasIntMission || multipleMapmakers)) {
      // remove the current user from the mapmakers
      if (user && user.id) {
        const index = mapmakers.indexOf(user.id);
        if (index > -1) {
          mapmakers.splice(index, 1);
        }
      }
      await job.set('Mapmaker', mapmakers);
    }

    if (job && this.state.mapIssue) {
      await job.set('Job Flag Notes', this.state.mapNotes);
      const jobFlags = currentMission.getJob()?.job_flags || [];
      jobFlags.push('Map Issue');
      await job.set('Job Flags', [...new Set(jobFlags)]);
    }

    if (job && job.dirty) {
      await job.sync();
    }

    session.Mission_id = undefined;
    dispatchMissionCreatedDeleted();

    // update the UI
    this.props.closeForm();
  }

  async finalizeMission(mission: Mission) {
    const missionName = mission.getMissionName('INT');
    try {
      let record = await Airtable.getRecord<Jobs>('Jobs', mission.job_id!);
      if (!record) {
        await Airtable.search<Jobs>('Jobs', '', `{Rogo Job ID} = '${mission.job_id}'`, true);
        record = await Airtable.getRecord('Jobs', mission.job_id!);
      }

      if (!record) {
        alertWarn('AT record not found');

        return SYNC_FAILED;
      }

      const kml = mission.to_kml({ insert_boxes: true });
      if (!kml) {
        throw new Error('No KML found');
      }

      const readyToUpload = await this.handleCheck();
      if (!readyToUpload) {
        return SYNC_CANCELLED;
      }

      await record.clear_attachments('Int Mission');
      const attachment = await record.new_attachment('Int Mission');

      const kmz = await kml.toKMZ(`${missionName}.kml`);

      await attachment.write(`${missionName}`, 'kmz', new Blob([kmz], { type: 'application/vnd.google-earth.kmz' }));

      await record.set('Mission Status', this.state.newMissionState);

      if (this.state.managerReviewed) {
        await record.set('Ops Mgmt Reviewed?', 'Reviewed');
      }

      let jobFlags = (record.get('Job Flags') as string[]) || [];
      if (this.state.mapIssue) {
        // add to data flags
        if (!jobFlags.includes('Map Issue')) {
          jobFlags.push('Map Issue');
        }
      } else {
        // remove from data flags
        jobFlags = jobFlags.filter((flag) => flag !== 'Map Issue');
      }
      await record.set('Job Flags', jobFlags);

      await record.set('Int Miss Edit?', this.state.pointsModified);

      await record.set('Error Notes (auto)', '');

      if (this.state.opNotes !== undefined && this.state.opNotes !== '') {
        await record.set('Warnings/Instructions for Field (custom) #ops', this.state.opNotes);
      }

      // set the map maker
      const user = getCurrentUser();

      if (user && user.id) {
        console.log('ADDING', user);
        await record.set('Mapmaker', [user.id]);
      }

      await record.sync();

      return SYNC_SUCCESS;
    } catch (e) {
      console.error('MapsExitInterview - finalizeMission: error', e);

      return SYNC_FAILED;
    }
  }

  async initializeFromAirtable() {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    const job = await Airtable.getRecord<Jobs>('Jobs', mission.job_id!);
    if (job) {
      this.setState({
        managerReviewed: job.get('Ops Mgmt Reviewed?') === 'Reviewed',
        pointsModified: job.get('Int Miss Edit?') as boolean,
        opNotes: job.get('Warnings/Instructions for Field (custom) #ops') as string | undefined,
        mapIssue: job.get('Job Flags')?.includes('Map Issue') || false,
        mapNotes: job.get('Job Flag Notes') as string | undefined,
      });
    }
  }

  sanitizeMapmakingNotes = (mapMakingNotes: string | undefined) => {
    if (!mapMakingNotes) return '';
    // remove all undefined strings
    const sanitizedNotes = mapMakingNotes.replace(/undefined/g, '');
    // remove all <b>, </b>, and all <a> tags and content
    return sanitizedNotes.replace(/<b>|<\/b>|<a.*?>.*?<\/a>/g, '');
  };

  render() {
    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth={'lg'}
        open={this.props.open || false}
        fullWidth
        onClose={(_event, reason) => {
          if (reason === 'backdropClick') return;

          // doesn't actually do anything, not used elsewhere
          // this.setState({
          //   open: false
          // });
        }}
      >
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item style={{ marginRight: '12px' }}>
              <Select
                value={this.state.newMissionState}
                onChange={(e) => {
                  this.setState({ newMissionState: e.target.value as MissionState });
                }}
              >
                {Object.keys(MissionState).map((item, idx) => (
                  <MenuItem key={idx} value={MissionState[item]}>
                    {MissionState[item]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={this.state.managerReviewed}
                    color="primary"
                    onChange={(e) => {
                      this.setState({ managerReviewed: e.target.checked });
                    }}
                  />
                }
                label="Manager reviewed?"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={this.state.pointsModified}
                    color="primary"
                    onChange={(e) => {
                      this.setState({ pointsModified: e.target.checked });
                    }}
                  />
                }
                label="Points modified?"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={this.state.mapIssue}
                    color="primary"
                    onChange={(e) => {
                      this.setState({ mapIssue: e.target.checked });
                    }}
                  />
                }
                label="Map Issue?"
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={() => {
                  let opNotes = this.state.opNotes;
                  if (!this.state.opNotes) {
                    opNotes = '';
                  } else {
                    opNotes += '\n';
                  }
                  opNotes += this.sanitizeMapmakingNotes(getCurrentMission()?.getJob()?.map_making_rules);
                  this.setState({ opNotes });
                }}
              >
                Insert Mapmaking Notes
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '12px' }}>
              <TextField
                label="Field instructions/warnings for operator"
                multiline
                minRows={3}
                fullWidth
                variant="outlined"
                value={this.state.opNotes}
                onChange={(e) => {
                  this.setState({ opNotes: e.target.value });
                }}
              />
            </Grid>
            {this.state.mapIssue && (
              <Grid item xs={12} style={{ marginBottom: '12px' }}>
                <TextField
                  label="Backoffice Notes / Map Issues (Required)"
                  multiline
                  minRows={3}
                  fullWidth
                  variant="outlined"
                  value={this.state.mapNotes}
                  onChange={(e) => {
                    this.setState({ mapNotes: e.target.value });
                  }}
                />
              </Grid>
            )}
          </Grid>
          {this.state.submitFailed && (
            <div style={{ color: 'red' }}>
              <b>Something went wrong submitting to Airtable. Try again or email misison file to ops support.</b>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <React.Fragment>
            <LoadingButton
              variant="outlined"
              color="secondary"
              data-testid="exit-interview-yes"
              disabled={this.state.mapIssue && !this.state.mapNotes}
              onClick={this.handleComplete}
            >
              Check & Upload
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              color="secondary"
              data-testid="exit-interview-no"
              disabled={this.state.mapIssue && !this.state.mapNotes}
              onClick={this.handleClose}
            >
              {this.state.confirmNoButton ? 'Are you sure?' : this.state.mapIssue ? 'Skip + Flag' : 'Skip Upload'}
            </LoadingButton>
          </React.Fragment>
          <Button variant="outlined" color="default" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
