import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { PropsWithChildren, PureComponent } from 'react';
import BlurDialog from './BlurDialog';

export const CONFIRM_DIALOG_REUSULTS = ['confirm', 'deny', 'extra'] as const;

export type ConfirmDialogResult = (typeof CONFIRM_DIALOG_REUSULTS)[number];
interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  confirmAction?: string;
  denyAction?: string;
  extraAction?: string;
  onConfirm: (result: ConfirmDialogResult) => Promise<void> | void;
  onExtra?: (extra: boolean) => void;
  confirmDisabled?: boolean;
}

export default class ConfirmDialog extends PureComponent<PropsWithChildren<ConfirmDialogProps>> {
  render() {
    return (
      <BlurDialog open={this.props.open} maxWidth={'sm'} fullWidth>
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: this.props.message }} />
          </DialogContentText>
          {this.props.children}
        </DialogContent>
        <DialogActions>
          {this.props.confirmAction && (
            <Button
              onClick={async () => await this.props.onConfirm('confirm')}
              style={{ fontSize: 15, textTransform: 'capitalize' }}
              color={'secondary'}
              variant={'outlined'}
              disabled={this.props.confirmDisabled}
            >
              {this.props.confirmAction}
            </Button>
          )}
          {this.props.extraAction && (
            <Button
              onClick={() => this.props.onExtra && this.props.onExtra(true)}
              style={{ fontSize: 15, textTransform: 'capitalize' }}
              color={'secondary'}
              variant={'text'}
            >
              {this.props.extraAction}
            </Button>
          )}
          {this.props.denyAction && (
            <Button
              onClick={async () => await this.props.onConfirm('deny')}
              style={{ fontSize: 15, textTransform: 'capitalize' }}
              variant={'contained'}
              color={'secondary'}
            >
              {this.props.denyAction}
            </Button>
          )}
        </DialogActions>
      </BlurDialog>
    );
  }
}
