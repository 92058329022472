import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';

export default withStyles((theme) => {
  const backgroundColorDefault = theme.palette.type === 'light' ? 'white' : theme.palette.grey[900];
  return {
    root: {
      backgroundColor: backgroundColorDefault,
    },
  };
})(Accordion);
