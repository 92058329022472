import { Coordinate } from 'ol/coordinate';
import { HeaderTimestamp, RosPosition } from '../types/rosmsg';
import { alertSuccess } from '../alertDispatcher';
import { dispatchRosMsgPub } from '../RobotConnection';
import { getCurrentSession } from '../dataModelHelpers';
import { capitalizeFirstLetter } from '../utils';

export function rosPositionToCoordinates(rosPosition: RosPosition): Coordinate {
  return [rosPosition.x, rosPosition.y];
}

export function rosTimestampToJsDate(rosTimestamp: HeaderTimestamp) {
  return new Date(rosTimestamp.secs * 1000 + rosTimestamp.nsecs / 1000000);
}

export function sendROSConfig<T extends string | number | boolean>(
  rosTopic: string,
  newValue: T,
  label: string,
  currentValue: T,
  checkBeforeSet: boolean,
  convert?: (newValue: T) => T,
  expectTopic?: string,
) {
  if (checkBeforeSet && newValue === currentValue) {
    alertSuccess(`${capitalizeFirstLetter(label)} already set to ${newValue}!`);
    return;
  }
  const session = getCurrentSession();
  dispatchRosMsgPub({
    hostname: session?.robot_hostname || '',
    msg: { data: convert ? convert(newValue) : newValue },
    topic: `config/set_${rosTopic}`,
    tag: Math.random().toString(36),
    expectationParams: {
      expectedTopic: expectTopic ? expectTopic : `config/${rosTopic}`,
      messages: {
        success: `Successfully set ${label} from ${currentValue} to ${newValue}!`,
        error: `Failed to set ${label} from ${currentValue} to ${newValue}!`,
      },
    },
  });
}
