import React, { PureComponent } from 'react';

import MapButton from './MapButton';
import ContrastPaper from '../../utils/ContrastPaper';

import CloseIcon from '@material-ui/icons/Close';

import {
  Popover,
  Grid,
  Typography,
  IconButton,
  Box,
  Fab,
  FormGroup,
  InputAdornment,
  TextField,
  InputLabel,
} from '@material-ui/core';

import { FaRegCompass } from 'react-icons/fa';

import { generateColor, roundToIncrement } from '../../../utils';

// import './styles/legendbutton.css';
import { DROPOFF_COLORS } from '../helpers/dropoffColors';
import { MapType } from '../helpers/mapTypes';
import Label from '../../mission/sampling/Label';

const FAB_SIZE_MAPS = 'small';
const FAB_SIZE_SAMPLING = 'medium';

type ValidationMapResult = {
  density: {
    error: boolean;
    text: string;
  };
  angle: {
    error: boolean;
    text: string;
  };
  xCount: {
    error: boolean;
    text: string;
  };
  yCount: {
    error: boolean;
    text: string;
  };
};

interface ValueChangerPairProps {
  value1: number;
  value2: number;
  increment1: number;
  increment2: number;
  precision?: number;
  label: string;
  validate: () => ValidationMapResult;
  update1(newValue: number): void;
  update2(newValue: number): void;
  decrementFragment?: React.ReactNode;
  incrementFragment?: React.ReactNode;
  mapType: MapType;
  units?: string;
  direction1?: string;
  direction2?: string;
  icon1?: React.ReactNode;
  icon2?: React.ReactNode;
}

interface ValueChangerPairState {
  anchorEl: null | Element | ((element: Element) => Element);
}

export default class ValueChangerPair extends PureComponent<ValueChangerPairProps, ValueChangerPairState> {
  constructor(props: ValueChangerPairProps) {
    super(props);

    this.state = {
      anchorEl: null,
    };

    this.toggleContainer = this.toggleContainer.bind(this);
  }

  toggleContainer(event) {
    if (!this.state.anchorEl) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ anchorEl: null });
    }
  }

  render() {
    return (
      <React.Fragment>
        <FormGroup row className="value-changer">
          <Box pt={1} pl={1} className="label">
            <Typography className="text">{this.getLabel()}</Typography>
          </Box>
          <Box pt={1} pl={1} className="paired-values">
            <Box pt={1} pl={1} className="values">
              <Box pt={1} pl={1} className="button">
                <Fab
                  size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                  onClick={(e) => {
                    if (!isNaN(this.props.value1)) {
                      this.props.update1(
                        roundToIncrement(this.props.value1 - this.props.increment1, this.props.precision || 0.1),
                      );
                    }
                  }}
                >
                  {this.props.decrementFragment || '-'}
                </Fab>
              </Box>
              <Box pt={1} pl={1} className="value">
                <TextField
                  type="number"
                  value={this.props.value1}
                  size="medium"
                  inputProps={{ size: '6' }}
                  InputProps={{ endAdornment: this.getUnits1() }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    this.props.update1(parseFloat(e.target.value));
                  }}
                />
              </Box>
              <Box pt={1} pl={1} pr={1} className="button">
                <Fab
                  size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                  onClick={(e) => {
                    if (!isNaN(this.props.value1)) {
                      this.props.update1(
                        roundToIncrement(this.props.value1 + this.props.increment1, this.props.precision || 0.1),
                      );
                    }
                  }}
                >
                  {this.props.incrementFragment || '+'}
                </Fab>
              </Box>
            </Box>
            <Box pt={1} pl={1} className="values">
              <Box pt={1} pl={1} className="button">
                <Fab
                  size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                  onClick={(e) => {
                    if (!isNaN(this.props.value2)) {
                      this.props.update2(
                        roundToIncrement(this.props.value2 - this.props.increment2, this.props.precision || 0.1),
                      );
                    }
                  }}
                >
                  {this.props.decrementFragment || '-'}
                </Fab>
              </Box>
              <Box pt={1} pl={1} className="value">
                <TextField
                  type="number"
                  value={this.props.value2}
                  size="medium"
                  inputProps={{ size: '6' }}
                  InputProps={{ endAdornment: this.getUnits2() }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    this.props.update2(parseFloat(e.target.value));
                  }}
                />
              </Box>
              <Box pt={1} pl={1} pr={1} className="button">
                <Fab
                  size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                  onClick={(e) => {
                    if (!isNaN(this.props.value2)) {
                      this.props.update2(
                        roundToIncrement(this.props.value2 + this.props.increment2, this.props.precision || 0.1),
                      );
                    }
                  }}
                >
                  {this.props.incrementFragment || '+'}
                </Fab>
              </Box>
            </Box>
          </Box>
        </FormGroup>
      </React.Fragment>
    );
  }

  private getUnits1(): React.ReactNode {
    return (
      <InputAdornment className="units" position="end">
        {this.props.icon1 ? this.props.icon1 : `${this.props.units || ''} ${this.props.direction1 || ''}`}
      </InputAdornment>
    );
  }

  private getUnits2(): React.ReactNode {
    return (
      <InputAdornment className="units" position="end">
        {this.props.icon2 ? this.props.icon2 : `${this.props.units || ''} ${this.props.direction2 || ''}`}
      </InputAdornment>
    );
  }

  private getLabel(): React.ReactNode {
    return (
      <>
        <div className="big-screen-label">{`${this.props.label}`}</div>
        <div className="small-screen-label">
          <div>{`${this.props.label}`}</div>
          <div className="label-units">
            {`${this.props.direction1} x ${this.props.direction2}` + (this.props.units ? ` (${this.props.units})` : '')}
          </div>
        </div>
      </>
    );
  }
}
