import EventBus from './EventBus';
import { AlertEvent } from './alertDispatcher';
import { ALERT_DISPATCH_EVENTS, ALERT_EVENT_NAME } from './alertEvents';

export class RogoError extends Error {
  name: string;
  message: string;
  alertType: string;

  constructor({ message, alertType = ALERT_DISPATCH_EVENTS.ERROR }: { message: string; alertType?: string }) {
    super();
    this.name = this.constructor.name;
    this.message = message;
    this.alertType = alertType;
    Error.captureStackTrace(this, this.constructor);
  }

  showError() {
    EventBus.dispatch(ALERT_EVENT_NAME, { type: this.alertType, message: this.message } as AlertEvent);
  }
}
