import { getCenter, Extent } from 'ol/extent';
import React, { PureComponent } from 'react';
import MapButton from './MapButton';

import { GiMultipleTargets } from 'react-icons/gi';
import { getCurrentMission } from '../../../dataModelHelpers';
import { alertWarn } from '../../../alertDispatcher';
import { Coordinate } from 'ol/coordinate';
import { Polygon, MultiPolygon } from 'ol/geom';
import { ScheduleFeature } from '../../../types/types';

interface ScheduleFieldZoomButtonProps {
  scheduleFeatures: ScheduleFeature[];
  updateCenter: (center: { value: Coordinate }) => void;
  updateFit: (fit: { value: Extent }) => void;
}

interface ScheduleFieldZoomButtonState {
  toggled: boolean;
}

export default class ScheduleFieldZoomButton extends PureComponent<
  ScheduleFieldZoomButtonProps,
  ScheduleFieldZoomButtonState
> {
  constructor(props: ScheduleFieldZoomButtonProps) {
    super(props);

    this.state = {
      toggled: false,
    };

    this.zoomToFields = this.zoomToFields.bind(this);
  }

  zoomToFields() {
    const mission = getCurrentMission();
    const scheduleFeatures = this.props.scheduleFeatures;
    const fields = scheduleFeatures
      .filter(
        (feature) =>
          ['Polygon', 'MultiPolygon'].includes(feature.geometry.type) &&
          (!mission || mission.job_id !== feature.properties?.['Rogo Job ID']),
      )
      .map((feature) => {
        console.log(typeof feature.geometry.coordinates);
        // convert all polygons to multipolygons and then flatten
        if (feature.geometry.type === 'Polygon') {
          return [feature.geometry.coordinates];
        } else {
          return feature.geometry.coordinates;
        }
      })
      .flat()
      .map((coords) => {
        // now all flattened coordinates can be polygons
        return new Polygon(coords as any);
      });

    if (fields && fields.length) {
      const field = new MultiPolygon(fields);
      const fieldExtent = field.getExtent();
      const center = getCenter(fieldExtent);
      console.log('CENTER', center, 'EXTENT', fieldExtent);
      if (!center.includes(NaN)) {
        this.props.updateCenter({ value: center });
      } else {
        alertWarn('Center for polygon is not valid!');
      }
      if (!fieldExtent.includes(Infinity)) {
        this.props.updateFit({ value: fieldExtent });
      } else {
        alertWarn('Extent for polygon is not valid!');
      }
      console.log('CENTER', center, 'EXTENT', fieldExtent);
    } else {
      alertWarn('No schedule fields found, wait for boundaries to load onto map!');
    }
  }

  render() {
    return (
      <MapButton tooltip="Zoom to Schedule Fields" onClick={this.zoomToFields}>
        <GiMultipleTargets size={25} />
      </MapButton>
    );
  }
}
