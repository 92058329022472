import { PureComponent } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, TextField, Button, Grid } from '@material-ui/core';
import React from 'react';
import { FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { isNumber } from 'lodash';
import { SettingsAccessLevel, ValidatePasswordFunction } from '../types/types';
import _ from 'lodash';

const ACCEPTABLE_CHARS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
export interface PasswordPromptPopupProps {
  validatePassword: ValidatePasswordFunction;
  passwordLength: number;
  close: (result: SettingsAccessLevel) => void;
  accessLevel: SettingsAccessLevel;
}

export interface PasswordPromptPopupState {
  password: string;
}

export default class PasswordPrompt extends PureComponent<PasswordPromptPopupProps, PasswordPromptPopupState> {
  recoveryInput: React.RefObject<HTMLInputElement>;
  constructor(props: PasswordPromptPopupProps) {
    super(props);

    this.state = {
      password: '',
    };

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  async componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  async handleKeyPress(event: KeyboardEvent) {
    console.log(event.key);
    console.log(this.state.password);
    if (event.key === 'Enter') {
      const loginResult = await this.props.validatePassword(this.state.password);
      event.preventDefault();
      console.log(loginResult);
      this.props.close(loginResult);
      return;
    } else if (event.key === 'Escape') {
      this.props.close(this.props.accessLevel);
      return;
    } else if (event.key === 'Backspace' || event.key === 'Delete') {
      this.setState({ password: this.state.password.slice(0, -1) });
      return;
    } else if (_.includes(ACCEPTABLE_CHARS, event.key.toUpperCase())) {
      this.setState({ password: this.state.password + event.key.toUpperCase() });
      return;
    }
  }

  classes = {
    app: { margin: '0 auto', width: '80%', height: '100vh' },
    root: {
      width: '50%',
      margin: '0 auto',
      // minWidth: "200px"
      // height:'100vh',
      // alignContent: 'center'
    },
    button: {
      height: '100%',
    },
  };
  // useStyles = makeStyles({
  //   app: { margin: "0 auto", width: "80%", height: "100vh" },
  //   root: {
  //     width: "50%",
  //     margin: "0 auto"
  //     // minWidth: "200px"
  //     // height:'100vh',
  //     // alignContent: 'center'
  //   },
  //   button: {
  //     height: "100%"
  //   }
  // });

  render() {
    //let classes = this.useStyles();
    const nums = ['A', 1, 2, 3, 'B', 4, 5, 6, 'C', 7, 8, 9, 'D', '', 0, '<', 'E', '', '', 'X', 'F', '', '', 'Y'];
    return (
      <>
        <Dialog open={true} maxWidth={'sm'}>
          <DialogTitle>
            <div>
              <b>Enter Password</b>
            </div>
          </DialogTitle>

          <DialogContent>
            <Grid xs={12} container justifyContent="center" spacing={0}>
              <Grid item xs={12}>
                <TextField type="password" disabled={true} value={this.state.password} fullWidth variant="outlined" />
              </Grid>
              {nums.map((num) => {
                if (num === '') {
                  return <Grid item xs={3} />;
                }
                let color = 'primary';
                if (!isNumber(num)) {
                  color = 'secondary';
                }
                return (
                  <Grid item xs={3}>
                    <Button
                      key={num}
                      variant="contained"
                      color={color as any}
                      fullWidth
                      style={{ height: 50 }}
                      disabled={
                        (num === 'Y' && this.state.password.length !== this.props.passwordLength) ||
                        (num === '<' && this.state.password.length === 0)
                      }
                      onClick={async () => {
                        if (num === 'Y') {
                          this.props.close(await this.props.validatePassword(this.state.password));
                          return;
                        }

                        if (num === 'X') {
                          this.props.close(this.props.accessLevel);
                          return;
                        }

                        if (num === '<') {
                          this.setState({ password: this.state.password.substring(0, this.state.password.length - 1) });
                          return;
                        }

                        this.setState({ password: this.state.password + num.toString() });
                      }}
                    >
                      {num === 'Y' ? (
                        <FaCheckCircle size={20} />
                      ) : num === 'X' ? (
                        <FaRegTimesCircle color="red" size={20} />
                      ) : (
                        num
                      )}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: 10, display: 'block' }}></DialogActions>
        </Dialog>
      </>
    );
  }
}
