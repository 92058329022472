import { getDatabase, initOldDatabase, IntegrityError } from './datamanager';
import { DataObject } from './dataobject';
import { IDBPTransaction } from 'idb';
import BaseTable, { CHANGE_STATE } from './basetable';

export class IDBTable<T extends DataObject> extends BaseTable<T> {
  async restoreData(tx?: IDBPTransaction<any, string[], 'readwrite'>) {
    const allData = await tx?.objectStore(this.tableName).getAll();

    if (!allData) return;

    this.dataList = {}; //new Map<number, T>;
    for (const value of allData) {
      this.lastDataIndex = value._instance_id;
      this.dataList[value._instance_id] = value;
    }
  }

  async getAllItems(): Promise<T[]> {
    const db = await initOldDatabase();
    const tx = db.transaction(this.tableName, 'readonly');
    const allData = await tx?.objectStore(this.tableName).getAll();
    return allData;
  }

  async getItem(dataKey: number): Promise<T | undefined> {
    const db = await initOldDatabase();
    const store = db.transaction(this.tableName, 'readonly').objectStore(this.tableName);
    return store.get(dataKey);
  }

  async syncData(tx?: IDBPTransaction<any, string[], 'readwrite'>) {
    if (!tx) {
      throw new Error('No transaction provided for syncData');
    }
    let writes = 0;
    if (this.requiresForceSync) {
      await this.forceSyncData(tx);
    } else {
      const store = tx && tx.objectStore(this.tableName);
      for (const dataKey in this.changedListKey) {
        const changedState = this.changedListKey[dataKey];
        // generate an event for the update
        // TODO removing for now as no one listesn for it
        //EventBus.dispatch(DATA_SYNC_EVENT, { tableName: this.tableName, dataKey, changedState });
        // sync the object with the database
        if (store) {
          const obj = await new Promise<any>((resolve, reject) => {
            resolve(store.get(parseInt(dataKey)));
            // req.onerror = reject;
            // req.onsuccess = event => resolve(req.result);
          });
          const newObj = this.dataList[dataKey];
          if (changedState === CHANGE_STATE.CREATED) {
            if (obj) {
              throw new IntegrityError(`Attempt to add ${store.name} that already exists: ${dataKey}.`);
            }
            await store.add(newObj);
            writes += 1;
          } else if (changedState === CHANGE_STATE.UPDATED) {
            if (!obj) {
              throw new IntegrityError(`Attempt to update ${store.name} that does not exist: ${dataKey}`);
            } else if (obj?._version && obj?._version !== newObj?._version) {
              // TODO temporarily disable this integrity error
              //throw new IntegrityError(`Attempt to update ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj._version}, Old: ${obj._version}`);
              console.warn(
                `Attempt to update ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj?._version}, Old: ${obj?._version}`,
              );
            }
            if (!newObj) return;
            newObj._version = (newObj?._version || 0) + 1; // increment version
            await store.put(newObj);
            writes += 1;
          } else if (changedState === CHANGE_STATE.DELETED) {
            // const oldObj = this.deletedList[dataKey];
            // if (!obj) {
            //   throw new IntegrityError(`Attempt to delete ${store.name} that does not exist: ${dataKey}`);
            // } else if (obj?._version !== oldObj?._version) {
            //   // TODO temporarily disable this integrity error
            //   //throw new IntegrityError(`Attempt to delete ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj._version}, Old: ${obj._version}`);
            //   console.warn(`Attempt to delete ${store.name} that has been modified by another process: ${dataKey}. New: ${newObj?._version}, Old: ${obj?._version}`);
            // }
            await store.delete(parseInt(dataKey));
            writes += 1;
          }
        }
        // delete this.deletedList[dataKey]
        delete this.changedListKey[dataKey];
      }
      this.defunctList = new Set();
    }
    return writes;
  }

  async forceSyncData(tx?: IDBPTransaction<any, string[], 'readwrite'>) {
    if (!tx) {
      throw new Error('No transaction provided for forceSyncData');
    }

    const db = getDatabase();
    const store = db.table(this.tableName);
    let recordCount = 0;
    if (store) {
      // delete all not in the data list
      const toDelete = await store.toArray();
      recordCount = toDelete.length;
      const keysToDelete = toDelete.filter((inst) => !this.dataList[inst._instance_id]);
      // console.log('toDelete', toDelete);
      await store.bulkDelete(keysToDelete);

      // store the entire data list
      // await Promise.all(Object.values(this.dataList).map(inst => store.put(inst)));
      await store.bulkPut(Object.values(this.dataList));
    }
    // this.deletedList = {}; //new Map<number, T>;
    this.defunctList = new Set();
    this.changedListKey = {};
    this.requiresForceSync = false;

    return recordCount;
  }
}
