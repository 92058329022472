import { FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB } from '../../../../constants';
import { GridPatterns, Mission, SamplingSpec } from '../../../../db';
import { SAMPLING_SPEC_DEFAULT_DEPTH } from '../../../../db/SamplingSpecClass';
import { SamplingSpecCreateParams } from '../../../../db/types';
import { IKMLElement } from '../../../../kml';
import { PartialBy } from '../../../../types/types';
import { uuidv4 } from '../../../../utils';
import logger from '../../../../logger';

class SpecKmlLoader {
  constructor(
    private mission: Mission,
    private doc: IKMLElement,
    private extendedData: any,
  ) {}

  async load() {
    if (this.extendedData.specs) {
      await logger.log('SpecKmlLoader', `loading from extendedData.specs`);
      
      await this.loadFromSpecs();
    } else {
      await logger.log('SpecKmlLoader', `loading from common extended data - deprecated`);
      // for backwards compatibility, load sampling spec out of document extended data
      this.loadFromCommonExtendedData();
    }
  }

  async loadFromSpecs() {
    type SpecWithMissingKeys = PartialBy<
      SamplingSpecCreateParams,
      | 'parallel_line_distance_ft'
      | 'primary_spec'
      | 'lab_name'
      | 'lab_short_name'
      | 'lab_address'
      | 'lab_code'
      | 'test_package'
    >;

    const isPrimarySpec = (
      spec: SpecWithMissingKeys,
      specIndex: number,
      specsCount: number,
      primarySpec?: SpecWithMissingKeys,
    ) => {
      if (specsCount === 1) {
        return true;
      }

      if (primarySpec && primarySpec.spec_id === spec.spec_id) {
        return true;
      }

      return typeof spec.primary_spec === undefined ? specIndex === 0 : spec.primary_spec!;
    };

    let specsFromExtendedData: SpecWithMissingKeys[];
    try {
      specsFromExtendedData = JSON.parse(this.extendedData.specs);
      /**
       * Example of a kml file with specs but not with all required keys.
       *
       * specsFromExtendedData = [
       * {
       *  _instance_id: 1,
       *  _version: 1,
       *  _refs: { Sample: [Array] },
       *  _dirty: true,
       *  spec_id: 27,
       *  cores: 8,
       *  pattern_type: 2,
       *  radius: 20,
       *  length: 20,
       *  angle: 45,
       *  start_depth: 0,
       *  end_depth: 8,
       *  Mission_id: 1
       * }
       * ]
       */
    } catch (e) {
      throw new Error('Error parsing specs from extended data');
    }

    const foundPrimarySpec = specsFromExtendedData.find((s) => s.primary_spec);

    await logger.log('SpecKmlLoader', `loading ${specsFromExtendedData.length} specs`);

    for (const [index, spec] of specsFromExtendedData.entries()) {
      SamplingSpec.createWithParams({
        ...spec,
        Mission_id: this.mission.instance_id,
        spec_id: spec.spec_id || uuidv4(),
        // for backwards compatibility with kml files that have specs but not with all required keys
        primary_spec: isPrimarySpec(spec, index, specsFromExtendedData.length, foundPrimarySpec),
        parallel_line_distance_ft: spec.parallel_line_distance_ft || FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB,
        lab_name: spec.lab_name || this.extendedData.lab_name,
        lab_short_name: spec.lab_short_name || this.extendedData.lab_short_name,
        lab_address: spec.lab_address || this.extendedData.lab_address,
        lab_code: spec.lab_code || this.extendedData.lab_code,
        test_package: spec.test_package || this.extendedData.test_package,
      });
    }
  }

  loadFromCommonExtendedData() {
    let coresNumber: number;
    let patternType: number;
    let radius: number;
    let length: number;
    let angle: number;
    let startDepth: number;
    let endDepth: number;
    let spec_id = this.extendedData.spec_id || uuidv4();

    if (this.extendedData.number_of_subsamples) {
      coresNumber = this.extendedData.number_of_subsamples ? parseInt(this.extendedData.number_of_subsamples) : 0;
      patternType = this.extendedData.pattern_type ? parseInt(this.extendedData.pattern_type) : 0;
      radius = this.extendedData.radius ? parseFloat(this.extendedData.radius) : 0.0;
      length = this.extendedData.length ? parseFloat(this.extendedData.length) : 0.0;
      angle = this.extendedData.angle ? parseFloat(this.extendedData.angle) : 0.0;
    } else {
      coresNumber = 8;
      patternType = GridPatterns.LINE;
      radius = 0.0;
      length = 20.0;
      angle = 45.0;
    }

    const anyCentroid = this.doc.find('Placemark', true, (sel) => sel.getExtendedData().centroid);
    const centroidExtendedData: Record<string, string> | undefined = anyCentroid?.getExtendedData();

    startDepth = centroidExtendedData?.start_depth ? parseFloat(centroidExtendedData?.start_depth) : 0.0;
    endDepth = centroidExtendedData?.end_depth
      ? parseFloat(centroidExtendedData?.end_depth)
      : SAMPLING_SPEC_DEFAULT_DEPTH;

    // if the centroid still has a spec_id, use it
    if (centroidExtendedData?.spec_id) {
      spec_id = centroidExtendedData?.spec_id;
    }

    const spec = SamplingSpec.createWithParams({
      Mission_id: this.mission.instance_id,
      spec_id: spec_id,
      primary_spec: true,
      deal_id: '',
      parallel_line_distance_ft: FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB,
      cores: coresNumber,
      pattern_type: patternType,
      radius,
      length,
      angle,
      start_depth: startDepth,
      end_depth: endDepth,
    });

    const job = this.mission.getJob();
    if (!job) {
      return;
    }

    spec.test_package = job.test_package;
    spec.lab_name = job.lab_name.replace(/"/g, '');
    spec.lab_short_name = job.lab_short_name;
    spec.lab_address = job.lab_address;
    spec.lab_code = job.lab_code;
  }
}

export default SpecKmlLoader;
