import AirtableModule, { FieldSet, Record, RecordData } from 'airtable';
import { AirtableBase } from 'airtable/lib/airtable_base';
import { AirtableRecord, AirtableSearch } from './db';

import DEFAULT_FIELDS from './default_airtable_fields.json';
import { AirtableRecordFields } from './db/types';

// CRM
export const AirtableIDLookup = {
  Jobs: {
    id: 'tblXij9xpNwUCLRIM',
    fields: {},
    views: { 'App View - Run Missions': 'viwAvI4Wr3aSbmggL', 'App View - Sampled Missions': 'viwR528VULoTPzMD0' },
  },
  'Shift Dropoffs': {
    id: 'tblrK7K9WfxIEbtqy',
    fields: {},
    views: { 'App View - Scheduled Dropoffs': 'viwgBFPyCbQdggKiy' },
  },
  'Box Shipments': { id: 'tblqn2nZHojfQe2Zf', fields: {} },
  Companies: {
    id: 'tblDrzNazvQ3cSezu',
    fields: {},
    views: { 'App View - Map Making': 'viwxwkCZ4CiGaFXxr' },
  },
} as const;

class Airtable {
  base: AirtableBase;

  constructor() {
    this.base = new AirtableModule({
      apiKey: import.meta.env.VITE_AIRTABLE_API_KEY,
      // TODO this is here for testing to see if we hit the rate limit
      // noRetryIfRateLimited: true
    }).base(import.meta.env.VITE_AIRTABLE_BASE_ID!);
  }

  assertFieldIncluded<T extends AirtableRecordFields = AirtableRecordFields>(tableName: string, fieldName: keyof T) {
    if (!(tableName in DEFAULT_FIELDS)) {
      return; // if table is not in the map, all fields are included
    }
    if (DEFAULT_FIELDS[tableName].includes(fieldName)) {
      return; // if the field is in the map
    }
    //throw new Error(`Field '${fieldName}' is not included in '${tableName}' records by default`);
    console.error(`Field '${fieldName.toString()}' is not included in '${tableName}' records by default`);
  }

  async search<T extends AirtableRecordFields = AirtableRecordFields>(
    tableName: string,
    viewName = '',
    query = '',
    refreshOnCreate = true,
  ) {
    // TODO EFFICIENCY
    //console.count(`airtable.ts:search (indexeddb)`);
    await AirtableSearch.search(tableName, viewName, query, refreshOnCreate);

    // we will force this return result to be undefined because the Airtable.search method will check if it exists
    // and will create one if it doesn't.
    // we should probably return that function then since it will know that the search is undefined...
    return AirtableSearch.findOne<AirtableSearch<T>>(
      (sel) => !!sel && sel.table === tableName && sel.view === viewName && sel.query === query,
    )!;
  }

  async getRecord<T extends AirtableRecordFields>(tableName: string, recordId: string) {
    // TODO EFFICIENCY
    //console.count(`airtable.ts:getRecord (indexeddb)`);
    // search for a single record
    return (await AirtableRecord.getRecord(tableName, recordId)) as AirtableRecord<T>;
  }

  // TODO
  async createRecord<T extends FieldSet>(tableName: string, fields: T) {
    // TODO EFFICIENCY
    this.remoteAirtableAPICall();
    console.count(`airtable.ts:createRecord`);
    const atData = await this.base(tableName).create([{ fields: fields }]);
    const newRecord = await AirtableRecord.createRecord(tableName, atData[0].fields, atData[0].id);
    return newRecord;
  }

  remoteAirtableAPICall = () => console.count('airtable.ts:Remote airtable API call');

  async deleteRecord(tableName: string, recordId: string) {
    // TODO EFFICIENCY
    this.remoteAirtableAPICall();
    console.count(`airtable.ts:deleteRecord`);
    return await this.base(tableName).find(recordId);
  }

  async _find(tableName: string, recordId: string) {
    // TODO EFFICIENCY
    this.remoteAirtableAPICall();
    console.count(`airtable.ts:_find(tableName=${tableName}, recordId=${recordId})`);

    return await this.base(tableName).find(recordId);
  }

  async _select(tableName: string, viewName: string, query: string, options = {}) {
    // TODO EFFICIENCY
    this.remoteAirtableAPICall();
    console.count(`airtable.ts:_select ${tableName}`);
    let _fields = [];
    if (tableName in DEFAULT_FIELDS) {
      _fields = DEFAULT_FIELDS[tableName];
    }

    const records: Record<FieldSet>[] = [];
    await this.base(tableName)
      .select({
        filterByFormula: query || '',
        view: viewName,
        fields: _fields,
        ...options,
      })
      .eachPage((_records, fetchNextPage: () => void) => {
        records.push(..._records); // add records to list
        fetchNextPage(); // go to next page
      });

    return records;
  }

  async _update<T extends AirtableRecordFields>(tableName: string, recordsToUpdate: RecordData<T>[]) {
    // TODO EFFICIENCY
    this.remoteAirtableAPICall();
    console.count(`airtable.ts:_update`);

    // @ts-ignore
    return (await this.base(tableName).update(recordsToUpdate)) as Record<T>[];
  }
}

const airtable = new Airtable();
Object.freeze(airtable);
export default airtable;
