import { z } from 'zod';
import { LocalStorageGenerator, localStorageGet, localStorageSet } from './utils';
import { getCurrentMission, getCurrentSession } from './dataModelHelpers';
import { dispatchUpdatedCurrentSample } from './sessionEvents';
import { alertFullScreen, alertWarn } from './alertDispatcher';
import logger from './logger';
import { Sample } from './db';
import { BarcodeSelectionMode, MissionRobotEvents, RobotEvent } from './types/types';
import { SAMPLING_TASK } from './constants';
import { BarcodeProcessorStateStore } from './barcodes';
import EventBus from './EventBus';
import { SCAN_EVENTS, ScannedBarcodeEvent } from './scanEvents';
import { errorTone } from './alertTones';

export const SampleSelectionStates = ['CORING', 'PULLED', 'DUMPED'] as const;
export type SampleSelectionState = (typeof SampleSelectionStates)[number];

interface SampleSelection {
  sampleId: string;
  state: SampleSelectionState;
}

interface SampleSelectionStateMachine {
  jobId: string;
  currentSampleState?: SampleSelection;
  nextSampleState?: SampleSelection;
}

export const SampleSelectionStateMachineStore = LocalStorageGenerator<SampleSelectionStateMachine>(
  'sampleSelectionStateMachine',
  {
    jobId: '',
    currentSampleState: undefined,
    nextSampleState: undefined,
  },
);

export function sampleSelectionAdvance(isNextSampleState = true) {
  const stateKey = isNextSampleState ? 'nextSampleState' : 'currentSampleState';
  const currentState = SampleSelectionStateMachineStore.get();
  const stateToAdvance = currentState[stateKey]?.state;

  if (!stateToAdvance) {
    return;
  }

  const index = SampleSelectionStates.indexOf(stateToAdvance);
  if (index === -1) {
    return;
  }

  const nextIndex = (index + 1) % SampleSelectionStates.length;
  const newState = SampleSelectionStates[nextIndex];

  SampleSelectionStateMachineStore.set({
    ...currentState,
    [stateKey]: {
      ...currentState[stateKey],
      state: newState,
    },
  });
}

const LastSampleSelectionKey = 'lastSampleSelection';

// make zod object for LastSampleSelected
export const LastSampleSelected = z.object({
  sampleInstanceId: z.optional(z.number()),
  timestamp: z.date(),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LastSampleSelected = z.infer<typeof LastSampleSelected>;

export const SampleToNavigateTo = z.object({
  sampleOrCoreId: z.string(),
  lat: z.number(),
  lon: z.number(),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SampleToNavigateTo = z.infer<typeof SampleToNavigateTo>;

// export const SampleSelectionLogic = z.object({
//     currentSampleInstanceId: z.string().optional(),
//     currentSampleState: z.enum(SampleSelectionStates).optional(),
//     nextSampleInstanceId: z.string().optional(),
//     nextSampleState: z.enum(SampleSelectionStates).optional(),
// });
// // eslint-disable-next-line @typescript-eslint/no-redeclare
// export type SampleSelectionLogic = z.infer<typeof SampleSelectionLogic>;

export const BarcodeSelectionModeStore = LocalStorageGenerator<BarcodeSelectionMode>(
  'barcodeSelectionMode',
  'Automatic',
);

/**
 * Find a sample instance ID given a sample ID
 * @param sampleOrZoneOrCoreId
 * @returns Sample the sample we want
 */
export function findSampleById(sampleOrZoneOrCoreId?: string) {
  if (!sampleOrZoneOrCoreId) return;
  const mission = getCurrentMission();
  if (!mission) {
    return;
  }
  // if zone mission, then find the sample by core_id
  if (mission.is_zone_mission()) {
    const cores = mission.getSampleSites().getSamples().getSoilCores();
    const core = cores.find((core) => `${core.sample_id}.${core.core_number}` === sampleOrZoneOrCoreId);
    if (core) {
      return mission.getSamples().find((sample) => sample.sample_id === core.sample_id);
      //return await Sample.findOne((sample) => sample.sample_id === core.sample_id);
    } else {
      // could be a zone identifier
      const sampleZones = mission
        .getZones()
        .getSampleZones()
        .filter((zone) => zone.sample_id === sampleOrZoneOrCoreId);
      if (sampleZones.length === 1) {
        return sampleZones[0]?.getSampleSite()?.getSamples()[0];
      }
      return undefined;
    }
  }
  // if not zone mission, then find the sample by sample_id
  const samples = mission.getAllSamples();
  return samples.find((sample) => sample.sample_id === sampleOrZoneOrCoreId);
}

export const getSampleSelected = () => {
  const result = localStorageGet<LastSampleSelected | undefined>(LastSampleSelectionKey, undefined);
  console.log(`sampleSelectionHelpers.ts getSampleSelected() = ${JSON.stringify(result)}`);
  return result;
};

export const setSampleSelected = async (value?: LastSampleSelected) => {
  console.log(`sampleSelectionHelpers.ts setSampleSelected(${JSON.stringify(value || '')})`);
  const session = getCurrentSession();
  if (!session) {
    return;
  }

  const nextSample = Sample.get(value?.sampleInstanceId);

  // get last scanned sample from mission
  const mission = session.getMission();
  if (!mission) {
    return;
  }
  // order from newest to oldest
  const sortedSamples = mission
    .getAllSamples()
    .filter((sample) => sample.bag_id)
    .sort((sampleA, sampleB) => sampleA.pulled_at - sampleB.pulled_at);
  const lastScanned = sortedSamples.length ? sortedSamples[0] : undefined;

  const validValueReceived = !!value;
  const sessionHasCurrentSample = Boolean(session.Sample_id);
  const sampleIdChanged = session.Sample_id !== value?.sampleInstanceId;

  if (validValueReceived && sessionHasCurrentSample && sampleIdChanged) {
    const currentSample = session.getSample();
    // if the currently selected sample does NOT have a bag_id, then alert the user
    if (currentSample && !currentSample?.bag_id) {
      if (nextSample) {
        if (mission) {
          if (BarcodeSelectionModeStore.get() === 'Automatic') {
            BarcodeProcessorStateStore.set({ barcodeScanningPaused: true });
            //const SCAN_SAMPLE = `SCAN LAST SAMPLE (Sample ${currentSample.sample_id}) NOW`;
            const SCAN_SAMPLE = 'SCAN';
            //const SKIP_SAMPLE: [string, string] = [`I SKIPPED SAMPLE ${currentSample.sample_id}`, `ARE YOU SURE?`];
            const SKIP_SAMPLE: [string, string] = [`CANCEL`, `ARE YOU SURE?`];
            await errorTone();
            const result = await alertFullScreen(
              `2 UNSCANNED SAMPLES`,
              `Stop Robot and Decide!\n` +
                `In Bucket/Just Dumped: <b>Sample ${nextSample.sample_id}</b>\n` +
                `Previous Sample: <b>Sample ${currentSample.sample_id}</b>\n` +
                (lastScanned ? `Last Scanned: <b> Sample ${lastScanned.sample_id} (${lastScanned.bag_id})</b>\n` : '') +
                `How do you want to handle <b>Sample ${currentSample.sample_id}</b>?`,
              [SCAN_SAMPLE, SKIP_SAMPLE],
              '#FF0000',
              'Explanation (Call ops if unclear)\n' +
                '<b>SCAN</b> - You SAMPLED but have NOT scanned yet (look around cab & box)\n' +
                '<b>CANCEL</b> - you did NOT SAMPLE or have NO BAG (you will sample later)',
            );
            if (result === SCAN_SAMPLE) {
              BarcodeProcessorStateStore.set({ barcodeScanningPaused: false });
              const alertScan = alertFullScreen(
                `Scan Sample ${currentSample.sample_id} now`,
                'Or skip by pressing the button',
                [['SKIP this sample', 'Are you sure?']],
              );

              const barcodeScannedPromise = new Promise<boolean>((resolve) => {
                const barcodeScanned = (scanEvent: ScannedBarcodeEvent) => {
                  EventBus.remove(SCAN_EVENTS.SCANNED_BARCODE, barcodeScanned);

                  // request full screen popup to close
                  EventBus.dispatch(SAMPLING_TASK.CLOSE_SAMPLE_SELECTION_POPUP);
                  resolve(true);
                };
                EventBus.on(SCAN_EVENTS.SCANNED_BARCODE, barcodeScanned);
              });

              await Promise.any([alertScan, barcodeScannedPromise]);
            }
            BarcodeProcessorStateStore.set({ barcodeScanningPaused: false });
            await logger.log('SAMPLE_SELECTION', `Missed Sample? ${result}`);
          }
        }
      }
    }
  }

  // if the NEXT sample already has a barcode, then we will warn the user
  if (nextSample?.bag_id) {
    alertWarn('WARNING: You are selecting a sample that ALREADY has a scanned barcode');
  }

  // TODO will leave this for now if we want to add the timeout feature
  // if (value?.sampleInstanceId && !DisableBarcodeEnforcement.get()) {
  //     const sampleSelectionTimeout = setTimeout(async () => {
  //         const session = getCurrentSession();
  //         if (!session) return;
  //         const currentSample = session.getSample();

  //         // we should have correctly captured the `value` state such that if for some reason
  //         // the sample is undefined or the sampleInstanceId is not the same as the currentSample
  //         // then we should not alert the user
  //         if (currentSample && (currentSample.instance_id === value.sampleInstanceId)) {
  //             await setSampleSelected(undefined);
  //             alertWarn(`Sample ${currentSample.sample_id} was not scanned in time and was unselected (1)`);
  //         }
  //         clearTimeout(sampleSelectionTimeout);
  //     }, SAMPLE_SELECTION_TIMEOUT_MS);
  // }

  const oldSampleInstanceId = session.Sample_id;
  session.Sample_id = value?.sampleInstanceId;
  localStorageSet(LastSampleSelectionKey, value);
  dispatchUpdatedCurrentSample([oldSampleInstanceId, value?.sampleInstanceId]);
};

export const DisableBarcodeEnforcement = LocalStorageGenerator<boolean>('disableBarcodeEnforcement', false);

export const MissionEventStore = LocalStorageGenerator<MissionRobotEvents>('missionEventStore', {
  missionId: '',
  events: [],
});
export function AddMissionEvent(currentMissionId: string, event: RobotEvent) {
  let { missionId, events } = MissionEventStore.get();
  console.log(`AddMissionEvent missionId=${missionId}, currentMissionId=${currentMissionId}`);
  if (currentMissionId !== missionId) {
    events = [];
  }
  MissionEventStore.set({
    missionId: currentMissionId,
    events: [event, ...events],
  });
}

// function sortByTimestamp(events: RobotEvent[], { descending = false } = {}) {
//     const sortedEvents = events.sort((eventA, eventB) => eventA.timestamp - eventB.timestamp);
//     if (descending) {
//         sortedEvents.reverse();
//     }
//     return sortedEvents;
// }

// function getMostRecent(events: RobotEvent[], type?: RobotEventType) {
//     if (events.length === 0) {
//         return undefined;
//     }
//     if (type) {
//         return getMostRecent(getEventsByType(events, type));
//     }
//     return sortByTimestamp(events)[events.length - 1];
// }

// function getOldestEvent(events: RobotEvent[]) {
//     if (events.length === 0) {
//         return undefined;
//     }
//     return sortByTimestamp(events)[0];
// }

// export function getLastPulledSample(events: RobotEvent[]) {
//     const pulledEvents = events.filter(event => event.type === 'core_complete');
//     const mostRecentPulledEvent = getMostRecent(pulledEvents);
//     if (mostRecentPulledEvent) {
//         const allRecentCoresMatch = pulledEvents.every((event) => event.sample_id === mostRecentPulledEvent.sample_id);
//         if (allRecentCoresMatch) {
//             return findSampleById(mostRecentPulledEvent.sample_id);
//         }
//     }
// }

// export function getEventsByType(events: RobotEvent[], type: RobotEventType) {
//     return events.filter(event => event.type === type);
// }

// export function getLastDump() {
//     const { events } = MissionEventStore.get();
//     return getMostRecent(events, 'dump_complete');
// }

// function checkSoilMixRisk(undumpedCores: RobotEvent[]) {
//     const mostRecentUndumpedCore = getMostRecent(undumpedCores, 'core_complete');
//     const soilMixRisk = !undumpedCores.every(core => core.sample_id === mostRecentUndumpedCore.sample_id);
//     // console.log(`checkSoilMixRisk mostRecentUndumpedCore=${JSON.stringify(mostRecentUndumpedCore)}, soilMixRisk=${soilMixRisk}`)
//     return soilMixRisk;
// }

// export function getSampleInBucket(events?: RobotEvent[]) {
//     if (!events) {
//         events = MissionEventStore.get().events;
//     }
//     const { cores: coresInBucket } = getCoresInBucket(events);
//     const mostRecentCore = getMostRecent(coresInBucket, 'core_complete');
//     if (mostRecentCore) {
//         return findSampleById(mostRecentCore.sample_id);
//     }
// }

// export function getCoresInBucket(events?: RobotEvent[]) {
//     if (!events) {
//         events = MissionEventStore.get().events;
//     }
//     const reverseEvents = sortByTimestamp(events, { descending: true });

//     // Using the reversed list, we can quickly filter for the most recent dump_complete event
//     const dumpEvents = reverseEvents.filter(event => event.type === 'dump_complete');
//     const coreEvents = reverseEvents.filter(event => event.type === 'core_complete');

//     // if we didn't have nay recent dump events, just get most recent sequential cores
//     if (dumpEvents.length === 0) {
//         return { cores: coreEvents, hasMixedSamples: checkSoilMixRisk(coreEvents) };
//     }

//     // get the most recent dump event
//     const mostRecentDump = dumpEvents[0];

//     // bifurcate recent cores into dumped and undumped. Anything newer than the last dump should be in the bucket still
//     const undumpedCores = reverseEvents.filter(event => event.type === 'core_complete' && event.timestamp >= mostRecentDump.timestamp);
//     return { cores: undumpedCores, hasMixedSamples: checkSoilMixRisk(undumpedCores) };
// }

// function debugDumpEvents(events?: RobotEvent[], selectedEvents: RobotEvent[] = []) {
//     if (!events) {
//         events = MissionEventStore.get().events;
//     }

//     let output = '';
//     let secondOutput = '';
//     for (const event of events) {
//         switch (event.type) {
//             case 'arriving':
//                 output += `A ${event.sample_id.toString().padStart(2, ' ')}|`;
//                 break;
//             case 'leaving':
//                 output += `L ${event.sample_id.toString().padStart(2, ' ')}|`;
//                 break;
//             case 'dump_complete':
//                 output += `D ${event.sample_id.toString().padStart(2, ' ')}|`;
//                 break;
//             case 'core_complete':
//                 output += `C ${event.sample_id.toString().padStart(2, ' ')}|`;
//                 break;
//         }
//         if (selectedEvents.includes(event)) {
//             secondOutput += `^   |`;
//         } else {
//             secondOutput += `    |`;
//         }
//     }

//     console.log(output);
//     console.log(secondOutput);
// }

// export function getCoreMap(events?: RobotEvent[]) {
//     // get all events
//     if (!events) {
//         events = MissionEventStore.get().events;
//     }

//     const { cores: coresInBucket, hasMixedSamples } = getCoresInBucket(events);

//     // get dumped cores
//     const undumpedCoreEvents = events.filter(event => event.type === 'core_complete' && !coresInBucket.includes(event));

//     const undumped: Record<string, RobotEvent[]> = _.groupBy(coresInBucket, "sample_id");
//     const dumped: Record<string, RobotEvent[]> = _.groupBy(undumpedCoreEvents, "sample_id");

//     return {
//         undumped,
//         dumped,
//         soilMixRisk: hasMixedSamples
//     };
// }

// export function getRecentSoilCores(sampleId: string, events?: RobotEvent[]) {
//     if (!events) {
//         events = MissionEventStore.get().events;
//     }
//     const { dumped, undumped } = getContinuousCoresInBucketAndDumped(sampleId, events);
//     return [...dumped, ...undumped];
// }

// interface ContinuousCoresInBucketAndDumped {
//     undumped: RobotEvent[];
//     dumped: RobotEvent[];
//     soilMixRisk: boolean;
// }

// export function getContinuousCoresInBucketAndDumped(sampleId?: string, events?: RobotEvent[]): ContinuousCoresInBucketAndDumped {
//     // Step 1: Get all events and reverse
//     if (!events) {
//         events = MissionEventStore.get().events;
//     }
//     // make events newest -> oldest
//     const reverseEvents = sortByTimestamp(events, { descending: true });

//     // const mostRecentCore = getMostRecent(reverseEvents, 'core_complete')
//     // if (!sampleId || !mostRecentCore || mostRecentCore.sample_id !== sampleId) {
//     //     return {
//     //         undumped: [],
//     //         dumped: [],
//     //         // this is subtle, but if we actually have a core but we are entering this state (nothing sampled for the current sample)
//     //         // then we definitely have a soil mix risk
//     //         // if we have no cores, then we have no mix risk
//     //         soilMixRisk: mostRecentCore ? true : false
//     //     }
//     // }

//     // Using the reversed list, we can quickly filter for the most recent dump_complete event
//     const dumpEvents = reverseEvents.filter(event => event.type === 'dump_complete');

//     const { cores: undumpedCores, hasMixedSamples } = getCoresInBucket(events);

//     const sequentialUndumpedCores = [];
//     for (const coreEvent of undumpedCores) {
//         if (coreEvent.sample_id === sampleId) {
//             sequentialUndumpedCores.push(coreEvent);
//         } else {
//             break;
//         }
//     }

//     // if the bucket check
//     const soilMixRisk = hasMixedSamples || (sequentialUndumpedCores.length !== undumpedCores.length);

//     // if we didn't have any recent dump events, just get most recent sequential cores (in bucket)
//     const noDumpEvents = dumpEvents.length === 0;
//     const dumpEventMismatch = sampleId && !noDumpEvents && dumpEvents[0].sample_id !== sampleId;
//     if (noDumpEvents || dumpEventMismatch) {
//         debugDumpEvents(sortByTimestamp(events.splice(0, 30)), events.filter(event => event.sample_id === sampleId));
//         return {
//             undumped: sequentialUndumpedCores,
//             dumped: [],
//             soilMixRisk
//         };
//     }

//     // get the most recent dump event
//     const mostRecentDump = dumpEvents[0];
//     const coreEvents = reverseEvents.filter(event => event.type === 'core_complete');

//     // bifurcate recent cores into dumped and undumped. Anything newer than the last dump should be in the bucket still
//     const dumpedCores = coreEvents.filter(event => event.timestamp < mostRecentDump.timestamp);

//     // iterate backwards from most recent dump until we find a core that DOESN'T belong to this sample
//     const sequentialDumpedCores = [];
//     for (const coreEvent of dumpedCores) {
//         if (coreEvent.sample_id === mostRecentDump.sample_id) {
//             sequentialDumpedCores.push(coreEvent);
//         } else {
//             break;
//         }
//     }

//     const oldestCountedCore = sequentialDumpedCores[sequentialDumpedCores.length - 1];

//     debugDumpEvents(sortByTimestamp(events.splice(0, 30)), [oldestCountedCore, mostRecentDump]);

//     return {
//         dumped: sequentialDumpedCores,
//         undumped: sequentialUndumpedCores,
//         soilMixRisk
//     };
// }

// ended up not using this function for serious logic so commented out
// export function coresForSample(sample: Sample) {
//     const { events } = MissionEventStore.get();
//     const allArrivalEvents = events.filter(event => event.type === 'arriving' && event.sample_id === sample.sample_id);
//     const arrivalsAtOurSample = allArrivalEvents.filter((event) => event.sample_id === sample.sample_id);
//     if (arrivalsAtOurSample.length === 0) {
//         return [];
//     }
//     else if (arrivalsAtOurSample.length === 1) {
//         return events.filter(event => event.type === 'core_complete' && event.sample_id === sample.sample_id);
//     }
//     else {
//         // if we have more than one arrival event for a sample, we need to search through the events more thoroughly to filter out old events

//         // get the "newest" arrival of them all
//         const arrivalEvent = getMostRecent(arrivalsAtOurSample)
//         const relevantCoreEvents: RobotEvent[] = []
//         if (arrivalEvent) {
//             const nextArrivalEvent = getOldestEvent(allArrivalEvents.filter((event) => event.timestamp > arrivalEvent.timestamp));

//             // first get all cores that are older than the first arrival event
//             relevantCoreEvents.push(...events.filter((event) => event.type === 'core_complete' && event.timestamp > arrivalEvent.timestamp))
//             if (nextArrivalEvent) {
//                 // filter any cores that are newer than the next arrival event
//                 return relevantCoreEvents.filter(event => event.timestamp <= nextArrivalEvent.timestamp);
//             }
//         }
//         return relevantCoreEvents;
//     }
// }
