import React, { PureComponent } from 'react';

import { layer, source, feature, interaction } from 'react-openlayers';
import Feature from 'ol/Feature';
import { SCHEDULE_TYPES } from '../helpers/scheduleTypes';
import { generateColor } from '../../../utils';
import { BLACK, WHITE, PLUM } from '../../../rgbColors';
import GeometryType from 'ol/geom/GeometryType';
import Collection from 'ol/Collection';
import { helpers } from '@turf/turf';
import booleanContains from '@turf/boolean-contains';
import BaseEvent from 'ol/events/Event';
import { eventIsDrawEvent } from '../../../types/types';
import { Geometry, Polygon } from 'ol/geom';

interface SchedulingLayerProps {
  selectedFeatures: Collection<Feature>;
  scheduleFeatures: Feature[];
  updateSelectedFeatures: (features: Collection<Feature>) => void;
  statsDrawActive: boolean;
  displayStats: (fields: Feature<Geometry>[]) => Promise<void>;
}

export default class SchedulingLayer extends PureComponent<SchedulingLayerProps> {
  FIELD_TRANSPARENCY = '40'; // 25% opacity

  HIDE_TEXT_ZOOM = 12;

  VALID_TYPES = [SCHEDULE_TYPES.PULLIN, SCHEDULE_TYPES.ORDER_LINE, SCHEDULE_TYPES.FIELD];
  selectable: boolean;

  constructor(props: SchedulingLayerProps) {
    super(props);

    this.filterSelection = this.filterSelection.bind(this);

    this.selectable = true;
  }

  /**
   * Switch handle that will call gen function depending on type.
   * @param {object} scheduleFeature GeoJSON object
   * @returns {MultiPolygonReact | PolygonReact | LineStringReact} The generated react component
   */
  genScheduleFeature(scheduleFeature) {
    switch (scheduleFeature.properties.type) {
      case SCHEDULE_TYPES.FIELD:
        return this.genFieldFeature(scheduleFeature);
      case SCHEDULE_TYPES.PULLIN:
        return this.genPullinFeature(scheduleFeature);
      case SCHEDULE_TYPES.ORDER_LINE:
        return this.genOrderLineFeature(scheduleFeature);
      default:
    }
  }

  /**
   * Generates react component for order line
   * @param {object} scheduleFeature GeoJSON object containing field boundary feature
   * @returns {LineStringReact} The feature to show
   */
  genOrderLineFeature(scheduleFeature) {
    const lineColor = generateColor(scheduleFeature.properties.robot);
    return (
      <feature.LineStringReact
        coordinates={scheduleFeature.geometry.coordinates}
        strokeOptions={{ color: lineColor, width: 2 }}
      />
    );
  }

  /**
   * Generates react component for field boundary
   * @param {object} scheduleFeature GeoJSON object containing field boundary feature
   * @returns {MultiPolygonReact | PolygonReact} The feature to show
   */
  genFieldFeature(scheduleFeature) {
    const fieldColor = generateColor(scheduleFeature.properties.robot || '');
    const isFeatureMultipolygon = scheduleFeature.geometry.type === GeometryType.MULTI_POLYGON;
    const FieldFeature = isFeatureMultipolygon ? feature.MultiPolygonReact : feature.PolygonReact;
    const firstCoords = isFeatureMultipolygon
      ? scheduleFeature.geometry.coordinates[0][0]
      : scheduleFeature.geometry.coordinates[0];
    const coordsChecksum = firstCoords[0] + firstCoords[1];
    const selected = Boolean(
      this.props.selectedFeatures
        .getArray()
        .find((selectedFeature) => selectedFeature.getProperties().job_id === scheduleFeature.properties.job_id),
    );
    return (
      <FieldFeature
        coordinates={scheduleFeature.geometry.coordinates}
        strokeOptions={{ color: BLACK, width: selected ? 2 : 1 }}
        fillOptions={{ color: `${fieldColor}${this.FIELD_TRANSPARENCY}` }}
        // @ts-ignore
        textOptions={{
          fillOptions: { color: BLACK },
          text: scheduleFeature.properties.field_name,
          font: '25px Calibri,sans-serif',
        }}
        key={`${scheduleFeature.properties.field_name}-${scheduleFeature.properties.job_id}-${coordsChecksum}-Field`}
        properties={scheduleFeature.properties}
      />
    );
  }

  /**
   * Generates react component for pullin
   * @param {object} scheduleFeature GeoJSON object containing a pullin feature
   * @returns {PointReact} The feature to show
   */
  genPullinFeature(scheduleFeature) {
    let fieldColor: string = '';
    const selectedIdx = this.props.selectedFeatures
      .getArray()
      .findIndex((selectedFeature) => selectedFeature.getProperties().job_id === scheduleFeature.properties.job_id);
    const selected = selectedIdx !== -1;
    const selectedText = selected ? ` / ${selectedIdx}` : '';
    if (scheduleFeature.properties.pullinType === 'Dropoffs')
      fieldColor = generateColor(scheduleFeature.properties.operatorName || scheduleFeature.properties.pullinType);
    else fieldColor = generateColor(scheduleFeature.properties.robot || '');
    const sizeAddition = scheduleFeature.properties.selected && scheduleFeature.properties.first ? 2 : 0;
    return (
      <feature.PointReact
        coordinate={scheduleFeature.geometry.coordinates}
        textOptions={{
          // @ts-ignore
          text: (scheduleFeature.properties.field_name || scheduleFeature.properties.dropoff_name) + selectedText,
          fillOptions: { color: WHITE },
          font: '20px Calibri,sans-serif',
          offsetY: 20,
          strokeOptions: { width: 2, color: BLACK },
        }}
        circleOptions={{
          strokeOptions: { color: WHITE, width: 2 },
          // @ts-ignore
          fillOptions: { color: fieldColor },
          radius: (selected ? 8 : 6) + sizeAddition,
        }}
        key={`${scheduleFeature.properties.field_name}-${scheduleFeature.properties.job_id}-Pullin`}
        hideTextZoom={selected ? 1 : this.HIDE_TEXT_ZOOM}
        properties={scheduleFeature.properties}
      />
    );
  }

  /**
   * Workaround to add and remove multiple features that aren't necessarily selected, but are related
   * @param {object} feature Feature provided by openlayers
   */
  filterSelection(feature) {
    if (this.selectable) {
      this.selectable = false;

      const selectedFeatures = this.props.selectedFeatures.getArray().slice();
      const pullinSelectedIdx = selectedFeatures.findIndex(
        (selectedFeature) =>
          selectedFeature.getProperties().type === SCHEDULE_TYPES.PULLIN &&
          selectedFeature.getProperties().field_name === feature.getProperties().field_name,
      );
      const fieldSelectedIdx = selectedFeatures.findIndex(
        (selectedFeature) =>
          selectedFeature.getProperties().type === SCHEDULE_TYPES.FIELD &&
          selectedFeature.getProperties().field_name === feature.getProperties().field_name,
      );
      if (pullinSelectedIdx !== -1 || fieldSelectedIdx !== -1) {
        if (pullinSelectedIdx !== -1) {
          selectedFeatures.splice(pullinSelectedIdx, 1);
        }
        if (fieldSelectedIdx !== -1) {
          selectedFeatures.splice(fieldSelectedIdx, 1);
        }
      } else {
        if (this.VALID_TYPES.includes(feature.getProperties().type)) {
          selectedFeatures.push(feature);
        }
      }

      const newSelected = new Collection(selectedFeatures);

      this.props.updateSelectedFeatures(newSelected);

      // prevents multiple features getting selected at once
      setTimeout(() => (this.selectable = true), 500);
    }

    return false;
  }

  showStatsForFields = async (event: BaseEvent) => {
    if (!eventIsDrawEvent(event)) return;
    const coordinates = (event.feature.getGeometry() as Polygon).getCoordinates();
    const statsPolygon = helpers.polygon(coordinates);
    // TODO temporay cheeat
    const fieldsInPolygon = this.props.scheduleFeatures.filter((scheduleFeature: any) => {
      if (scheduleFeature.geometry.type === GeometryType.POLYGON) {
        const fieldPolygon = helpers.polygon(scheduleFeature.geometry.coordinates);
        return booleanContains(statsPolygon, fieldPolygon);
      } else if (scheduleFeature.geometry.type === GeometryType.MULTI_POLYGON) {
        for (const polygon of scheduleFeature.geometry.coordinates) {
          const fieldPolygon = helpers.polygon(polygon);
          if (booleanContains(statsPolygon, fieldPolygon)) {
            return true;
          }
        }
      }

      return false;
    });
    await this.props.displayStats(fieldsInPolygon);
  };

  render() {
    const scheduleMapFeatures = this.props.scheduleFeatures.map((scheduleFeature) =>
      this.genScheduleFeature(scheduleFeature),
    );
    // get only the items that appear more than once (identical keys)
    const duplicateKeys = scheduleMapFeatures
      .map((scheduleFeature) => (scheduleFeature ? scheduleFeature.key : ''))
      .filter((key, index, self) => self.indexOf(key) !== index);

    return (
      // @ts-ignore
      <layer.Vector zIndex={1}>
        {/* @ts-ignore */}
        <source.VectorSourceReact>
          {scheduleMapFeatures}
          <interaction.SelectReact
            // @ts-ignore
            filter={this.filterSelection}
            style={null}
            multi={false}
          />
          {this.props.statsDrawActive && (
            <interaction.DrawReact
              type={'Polygon'}
              onDrawend={this.showStatsForFields}
              styleOptions={{
                linestringColor: PLUM,
                pointColor: PLUM,
                polygonColor: PLUM.concat(0.3),
              }}
            />
          )}
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
