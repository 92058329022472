import { Coordinate } from 'ol/coordinate';
import { CoreMsg, RosPosition } from '../types/rosmsg';
import { distanceLatLon, isAutoCore, isBulkDensityCore } from '../utils';
import { calculateArmPosition } from './RobotArmService';
import {
  CoreMatchingUsingRobotInAutoMode,
  MapCalculationPositionStorage,
  MapNearbyAutoZoomEnabled,
  MapZoomLevelNotNearSampleStorage,
  UseClosestCoreStrategyStorage,
  UseClosestEdgeCoreStrategyStorage,
} from '../db/local_storage';
import { rosPositionToCoordinates, rosTimestampToJsDate } from '../utilities/rosUtils';
import { Mission, Sample, SoilCore } from '../db';
import { DEFAULT_CORE_PRESENT_DISTANCE_METERS, M_PER_FT, MM_PER_FT } from '../constants';
import { SoilCoreList } from '../db/SoilCoreClass';
import logger from '../logger';
import { AddMissionEvent, findSampleById, setSampleSelected } from '../sampleSelectionHelpers';
import { dispatchMissionUpdated } from '../missionEvents';
import { SamplingMapStateStorage } from '../components/map/SamplingMapStateStorage';
import EventBus from '../EventBus';
import { MAP_EVENTS } from '../mapEvents';
import { TakenCore } from '../types/types';
import { alertInfo, alertWarn } from '../alertDispatcher';
import { Mark } from '@material-ui/core';
import ManualMeasurementsChecker, { ManualMeasurementDialogParameters } from './ManualMeasurementsChecker';
import CorePickerForZoneMissions from './CorePickerForZoneMissions';

export type ManualMeasurementParameters = {
  initialValue?: number;
  suggestedMin?: number;
  suggestedMax?: number;
  absoluteMin?: number;
  absoluteMax?: number;
  benchmarks?: boolean | Mark[];
};

class CoreCompletion {
  private mission: Mission;
  private position: RosPosition;
  private presentSample: Sample | undefined;
  private onDumpComplete: () => Promise<void>;
  private onCoreCompletionSuccess: (presentSample: Sample) => void;
  private openManualMeasurementDialog: (params: ManualMeasurementDialogParameters) => void;

  constructor(
    mission: Mission,
    position: RosPosition,
    presentSample: Sample | undefined,
    onDumpComplete: () => Promise<void>,
    onCoreCompletionSuccess: (presentSample: Sample) => void,
    openManualMeasurementDialog: (params: ManualMeasurementDialogParameters) => void,
  ) {
    this.mission = mission;
    this.position = position;
    this.presentSample = presentSample;
    this.onDumpComplete = onDumpComplete;
    this.onCoreCompletionSuccess = onCoreCompletionSuccess;
    this.openManualMeasurementDialog = openManualMeasurementDialog;
  }

  async handle(event: { hostname: string; msg: CoreMsg }) {
    if (this.abortOnCoreFailure(event)) {
      return;
    }

    const coreDiameterInches = event.msg.core_diameter || '';
    const targetPosition = this.getTargetPosition();
    const now = (event.msg.header.stamp ? rosTimestampToJsDate(event.msg.header.stamp) : new Date()).getTime();
    const depthError = event.msg.postlaunch_depth_error;
    const depthErrorMessage = this.generateDepthErrorMessage(depthError);

    const [presentSample, pulledCore] = this.choosePresentSampleAndCore(event.msg, targetPosition);

    // Record the taken core - update the mission.
    this.recordTakenCore(event, coreDiameterInches);

    // Record Test core if we are within Deadband but outside Present.
    if (!presentSample || !pulledCore) {
      this.takeTestCore(targetPosition, now, depthErrorMessage, coreDiameterInches);

      return;
    }

    this.actualizeCore(pulledCore, presentSample, targetPosition, event, now, coreDiameterInches);

    console.debug(`Core ${pulledCore.name} pulled? ${pulledCore.pulled}`);

    const isBulkDensity = isBulkDensityCore(event.msg.initiator!);
    if (isBulkDensity && this.successfullyPulled(event)) {
      await new ManualMeasurementsChecker().check(this.openManualMeasurementDialog, pulledCore);
    }

    const sampleID = presentSample.sample_id;
    AddMissionEvent(this.mission.job_id || '(none)', {
      type: 'core_complete',
      sample_id: sampleID,
      timestamp: Date.now(),
      location: this.position,
      meta: depthError,
    });

    if (isBulkDensity) {
      const sample = findSampleById(sampleID);
      if (!sample) {
        alertWarn(`Got a bulk density core but couldn't find sample ${sampleID}`);

        return;
      }

      await this.onDumpComplete();
      await setSampleSelected({ timestamp: new Date(), sampleInstanceId: sample.instance_id });

      return;
    }

    dispatchMissionUpdated();

    const allCores = presentSample.getSoilCores();
    const pulledCores = allCores.filter((core) => core.pulled);
    if (pulledCores.length >= allCores.length && this.shouldAutoZoom()) {
      // TODO could just let base map respond to the arrive/leave events...
      EventBus.dispatch(MAP_EVENTS.ZOOM_UPDATED, MapZoomLevelNotNearSampleStorage.get());
    }

    alertInfo(
      `Core ${pulledCore.name} pulled (${pulledCores.length} / ${allCores.length}) ${depthErrorMessage} (sample ${sampleID})`,
    );

    // presentSample is the sample that was just cored and it, in theory, might be different from this.presentSample
    // This can potentially happen if the core was taken in auto mode, so we will send the presentSample to the callback
    this.onCoreCompletionSuccess(presentSample);
  }

  private isWaypointReportedInAutoMode(msg: CoreMsg): boolean {
    const initiator = msg.initiator;
    const waypointNumber = msg.sample_sn;
    if (typeof initiator === 'undefined' || !waypointNumber) {
      return false;
    }

    return isAutoCore(initiator);
  }

  private choosePresentSampleAndCore(
    msg: CoreMsg,
    targetPosition: Coordinate,
  ): [Sample | undefined, SoilCore | undefined] {
    const missionCores = this.mission.getAllCores();

    if (CoreMatchingUsingRobotInAutoMode.get() && this.isWaypointReportedInAutoMode(msg)) {
      logger.log('CoreCompletion', `Waypoint reported in auto-mode ${msg.sample_sn}`);

      const [autoModeSample, autoModeCore] = this.getSampleAndCoreInAutoMode(missionCores, msg.sample_sn!);
      if (autoModeSample && autoModeCore) {
        logger.log(
          'CoreCompletion',
          `Core and sample found by wp number ${msg.sample_sn}, core ${autoModeCore.name}, sample with sample_id ${autoModeSample.sample_id}`,
        );

        return [autoModeSample, autoModeCore];
      } else {
        alertWarn(
          `Could not find mission core and/or sample for wp number ${msg.sample_sn!}, ${
            autoModeCore ? `core found ${autoModeCore.name}` : 'core not found'
          }, ${autoModeSample ? `sample found with sample_id ${autoModeSample.sample_id}` : 'sample not found'}`,
        );
      }
    }

    logger.log('CoreCompletion', 'Choosing a core "manually"');

    // Check for being far away from the sample.
    if (this.isTooFarFromSample(targetPosition, this.presentSample)) {
      // We have presentSample but we are too far from it. How can that be?
      alertWarn(`This is too far from sample ${this.presentSample?.sample_id || '(none)'}`);

      return [this.presentSample, undefined];
    }

    if (!this.presentSample) {
      return [undefined, undefined];
    }

    // From this point on we are within Present.

    // Get the next intended core (choose smartly).
    const presentSampleCores = this.presentSample.getSoilCores();
    const pulledCore: SoilCore = this.getNextIntendedCore(this.presentSample, targetPosition, presentSampleCores);

    return [this.presentSample, pulledCore];
  }

  private getSampleAndCoreInAutoMode(
    missionCores: SoilCore[],
    waypointNumber: number,
  ): [Sample | undefined, SoilCore | undefined] {
    const core = missionCores.find((c) => c.getCorePoint()?.waypoint_number === waypointNumber);

    return core ? [core.getSample(), core] : [undefined, undefined];
  }

  private recordTakenCore(event: { hostname: string; msg: CoreMsg }, coreDiameterInches: string) {
    const currentCores: TakenCore[] = JSON.parse(this.mission.cores || '[]') || [];
    const newCore: TakenCore = [
      event.msg.header.stamp!,
      event.msg.target_depth!,
      event.msg.postlaunch_depth_error,
      this.position,
      event.msg.initiator!,
      this.presentSample?.sample_id || '',
      null,
      null,
      null,
      null,
      coreDiameterInches,
      // TODO add core type
    ];

    currentCores.push(newCore);
    this.mission.cores = JSON.stringify(currentCores);
  }

  private abortOnCoreFailure(event: { msg: CoreMsg }): boolean {
    const coreSuccess = this.successfullyPulled(event);

    // Right now we are ignoring unsuccessful cores
    return !coreSuccess;
  }

  private getTargetPosition(): Coordinate {
    if (MapCalculationPositionStorage.get() === 'Arm') {
      return calculateArmPosition([this.position.y, this.position.x], -this.position.z, '3857');
    }

    return rosPositionToCoordinates(this.position);
  }

  private isTooFarFromSample(targetPosition: Coordinate, presentSample: Sample | undefined) {
    if (!presentSample) {
      return false;
    }

    const sampling_tolerance_ft = this.mission.getJob()?.sampling_tolerance_ft;
    const toleranceM = sampling_tolerance_ft ? sampling_tolerance_ft * M_PER_FT : DEFAULT_CORE_PRESENT_DISTANCE_METERS;
    if (this.withinDistanceToSampleCores(presentSample, toleranceM, targetPosition)) {
      return false;
    }

    if (this.mission.is_zone_mission() && this.mission.getEnclosedSampleZone(targetPosition)) {
      return false;
    }

    return true;
  }

  private withinDistanceToSampleCores(sample: Sample, tolerance_m: number, position: Coordinate): boolean {
    const sampleCores = sample.getSoilCores();
    if (!sampleCores || !sampleCores.length || !sampleCores[0]) {
      return false;
    }

    if (
      !sampleCores.some((core) => {
        const lat = core.lat;
        const lon = core.lon;
        const distance_m = distanceLatLon(position[0], position[1], lat, lon, 'Meters');

        return distance_m <= tolerance_m;
      })
    ) {
      return false;
    }

    return true;
  }

  private getNextIntendedCore(
    presentSample: Sample,
    targetPosition: Coordinate,
    presentSampleCores: SoilCoreList,
  ): SoilCore {
    let pulledCore = this.pickUnpulledCore(presentSample, targetPosition, presentSampleCores);

    if (!pulledCore) {
      pulledCore = SoilCore.create();
      pulledCore.source = 'Rogo Field Ops';
      pulledCore.core_number = presentSampleCores.length + 1;
      pulledCore.lat = targetPosition[0];
      pulledCore.lon = targetPosition[1];
    }

    return pulledCore;
  }

  private pickUnpulledCore(presentSample: Sample, targetPosition: Coordinate, presentSampleCores: SoilCoreList) {
    const sortCoresByDistance = (cores: SoilCoreList) => {
      return cores.sort((a, b) => {
        const aDistance = distanceLatLon(targetPosition[0], targetPosition[1], a.lat, a.lon, 'Feet');
        const bDistance = distanceLatLon(targetPosition[0], targetPosition[1], b.lat, b.lon, 'Feet');

        return aDistance - bDistance;
      });
    };

    const coresSortedByDistance = sortCoresByDistance(presentSampleCores);

    if (this.mission.is_zone_mission()) {
      const corePickerForZoneMissions = new CorePickerForZoneMissions(this.mission);

      return corePickerForZoneMissions.pick(targetPosition, coresSortedByDistance);
    }

    if (UseClosestEdgeCoreStrategyStorage.get()) {
      return this.pickUnpulledCoreUsingClosestEdgeStrategy(presentSample, targetPosition);
    }

    if (UseClosestCoreStrategyStorage.get()) {
      return coresSortedByDistance.find((core) => !core.pulled);
    }

    return presentSample.getNextSoilCoreTarget();
  }

  private pickUnpulledCoreUsingClosestEdgeStrategy(
    presentSample: Sample,
    targetPosition: Coordinate,
  ): SoilCore | undefined {
    const edgeUnpulledCores = presentSample.getEdgeUnpulledSoilCores();
    if (edgeUnpulledCores.length === 0) {
      return;
    }

    if (edgeUnpulledCores.length === 1) {
      return edgeUnpulledCores[0];
    }

    const closestUnpulledEdgeCore = edgeUnpulledCores.sort((a, b) => {
      const aDistance = distanceLatLon(targetPosition[0], targetPosition[1], a.lat, a.lon, 'Feet');
      const bDistance = distanceLatLon(targetPosition[0], targetPosition[1], b.lat, b.lon, 'Feet');

      return aDistance - bDistance;
    });

    return closestUnpulledEdgeCore[0];
  }

  private generateDepthErrorMessage(depthError: number) {
    if (depthError > 0) {
      return `, ${Math.abs(depthError)}mm short`;
    }

    if (depthError < 0) {
      return `, ${Math.abs(depthError)}mm too deep`;
    }

    return ', depth OK';
  }

  private takeTestCore(targetPosition: Coordinate, now: number, depthErrorMessage: string, coreDiameterInches: string) {
    logger.log('CORE_COMPLETE', 'No sample ID for core');
    const core = SoilCore.create();
    core.source = 'Rogo Field Ops';
    core.core_number = this.mission.getTestCores().length + 1;
    core.test_core_Mission_id = this.mission.instance_id;
    core.lat = targetPosition[0];
    core.lon = targetPosition[1];
    core.pulled_lat = targetPosition[0];
    core.pulled_lon = targetPosition[1];
    core.pulled_heading = this.position.z;
    core.pulled_at = now / 1000;
    core.core_diameter_inches = coreDiameterInches;

    alertInfo(`Test core complete${depthErrorMessage} (Not close enough to a sample)`);
  }

  private actualizeCore(
    pulledCore: SoilCore,
    presentSample: Sample,
    targetPosition: Coordinate,
    event: { hostname: string; msg: CoreMsg },
    now: number,
    coreDiameterInches: string,
  ) {
    pulledCore.Sample_id = presentSample.instance_id;
    pulledCore.pulled_lat = targetPosition[0];
    pulledCore.pulled_lon = targetPosition[1];
    pulledCore.pulled_heading = this.position.z;
    pulledCore.initiator = event.msg.initiator;
    pulledCore.pulled_at = now;
    pulledCore.core_diameter_inches = coreDiameterInches;
  }

  private shouldAutoZoom() {
    return MapNearbyAutoZoomEnabled.get() && SamplingMapStateStorage.get().tracking;
  }

  private successfullyPulled(event: { msg: CoreMsg }): boolean {
    return event.msg.result === 0 && event.msg.status_code === 0;
  }
}

export default CoreCompletion;
