import { PureComponent } from 'react';
import MapButton from './MapButton';
import { MdNotInterested } from 'react-icons/md';

interface SkipButtonProps {
  textColor?: string;
  onClick: () => Promise<void>;
}

export default class SkipButton extends PureComponent<SkipButtonProps> {
  render() {
    return (
      <MapButton onClick={this.props.onClick} tooltip="Skip / Unskip">
        <MdNotInterested size={18} color={this.props.textColor} />
      </MapButton>
    );
  }
}
