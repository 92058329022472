import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';

import ProgressBar from '../utils/ProgressBar';

import SamplingMissionLoaded from './sampling/SamplingMissionLoaded';
import LoadSamplingMission from './sampling/LoadSamplingMission';

import { getCurrentMission, getCurrentSession } from '../../dataModelHelpers';
import { SetStateAsync, setStateAsync } from '../../utils';

import { cancelConfirmTag } from '../../alertDispatcher';

import { SampleBox } from '../../db';
import { LatLonSource, MissionView, OnLoading, SettingsAccessLevel } from '../../types/types';
import { ManualMeasurementDialogParameters } from '../../services/ManualMeasurementsChecker';

interface SamplingMissionViewProps {
  toggleScanner: () => void;
  scannerActive: boolean;
  missionActive: string;
  onLoading: OnLoading;
  accessLevel: SettingsAccessLevel;
  operatorNotesDialogOpen: boolean;
  toggleNotesDialog: () => void;
  toggleHMIVisible: () => void;
  hmiVisible: boolean;
  showNavMessage: (directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) => void;
  showRecoveryPopup: () => void;
  drillDepth?: number;
  drillDepthOffset?: number;
  activeBox?: SampleBox;
  allBoxes: SampleBox[];
  handleCloseBox: (box?: SampleBox) => Promise<void>;
  photosBySampleId: Record<string, any[]>;
  updateMissionView: (view: MissionView) => void;
  robotConnected: boolean;
  openEntranceInterview: () => void;
  openManualMeasurementDialog: (manualMeasurementDialogParameters: ManualMeasurementDialogParameters) => void;
}

interface SamplingMissionViewState {}

export default class SamplingMissionView extends PureComponent<SamplingMissionViewProps, SamplingMissionViewState> {
  showPDF: React.RefObject<unknown>;
  uploadBoxRef: React.RefObject<unknown>;
  setStateAsync: SetStateAsync;
  constructor(props: SamplingMissionViewProps) {
    super(props);

    this.showPDF = React.createRef();
    this.uploadBoxRef = React.createRef();

    this.addNewBox = this.addNewBox.bind(this);
    this.setStateAsync = setStateAsync.bind(this);
  }

  async componentDidMount() {}

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
  }

  /**
   * Creates a new box
   */
  async addNewBox() {
    const session = getCurrentSession();
    await session?.addAndSelectBox();
  }

  getCompletedSamples(includeSkips: boolean = false) {
    let completeSamples = 0;
    const mission = getCurrentMission();
    if (mission) {
      const samples = mission.getAllSamples();
      completeSamples = samples.filter(
        (sample) => (includeSkips || !sample.skipped_or_deleted) && Boolean(sample.bag_id),
      ).length;
    }
    return completeSamples;
  }

  getTotalSamples(includeSkips: boolean = false) {
    let totalSamples = 0;
    const mission = getCurrentMission();
    if (mission) {
      const samples = mission.getAllSamples().filter((sample) => includeSkips || !sample.skipped_or_deleted);
      totalSamples = samples.length;
    }
    return totalSamples;
  }

  render() {
    const mission = getCurrentMission();
    const samples = mission ? mission.getAllSamples(false) : [];
    return (
      <React.Fragment>
        {/* Progress Bar */}
        {Boolean(this.props.missionActive) && (
          <Grid container item xs={12} style={{ marginTop: 5 }}>
            <ProgressBar
              completedNumber={samples.filter((sample) => Boolean(sample.bag_id)).length}
              totalNumber={samples.length}
            />
          </Grid>
        )}

        <Grid container item xs={12} spacing={1}>
          {Boolean(this.props.missionActive) ? (
            <SamplingMissionLoaded
              addNewBox={this.addNewBox}
              openBoxExitInterview={this.props.handleCloseBox}
              toggleScanner={this.props.toggleScanner}
              scannerActive={this.props.scannerActive}
              activeBox={this.props.activeBox}
              accessLevel={this.props.accessLevel}
              allBoxes={this.props.allBoxes}
              onLoading={this.props.onLoading}
              operatorNotesDialogOpen={this.props.operatorNotesDialogOpen}
              toggleNotesDialog={this.props.toggleNotesDialog}
              toggleHMIVisible={this.props.toggleHMIVisible}
              hmiVisible={this.props.hmiVisible}
              showNavMessage={this.props.showNavMessage}
              samplingDepth={this.props.drillDepth}
              samplingDepthOffset={this.props.drillDepthOffset}
              photosBySampleId={this.props.photosBySampleId}
              updateMissionView={this.props.updateMissionView}
              robotConnected={this.props.robotConnected}
              openEntranceInterview={this.props.openEntranceInterview}
              openManualMeasurementDialog={this.props.openManualMeasurementDialog}
            />
          ) : (
            <LoadSamplingMission
              openBoxExitInterview={this.props.handleCloseBox}
              accessLevel={this.props.accessLevel}
              box={this.props.activeBox}
              onLoading={this.props.onLoading}
              showRecoveryPopup={this.props.showRecoveryPopup}
              addNewBox={this.addNewBox}
              updateMissionView={this.props.updateMissionView}
            />
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
