import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import { SimpleGeometry } from 'ol/geom';
import { KML_FEATURE_TYPE, KmlFeatureType } from '../../../featureTypes';
import { KMLFeature } from '../../../types/types';

export type FeatureProperties = {
  [key: string]: string | number | undefined;
  name?: string;
};

export function getFeatureType(properties: FeatureProperties | undefined): KmlFeatureType | undefined {
  if (!properties) return;
  const name = properties.name && properties.name.toLowerCase();
  if (properties.generic_zone) return KML_FEATURE_TYPE.GENERIC_ZONE;
  else if (properties.core_location) return KML_FEATURE_TYPE.CORE_POINT;
  else if (properties.sample_zone) return KML_FEATURE_TYPE.SAMPLE_ZONE;
  else if (properties.dump_event) return KML_FEATURE_TYPE.DUMP_EVENT;
  else if (properties.centroid) return KML_FEATURE_TYPE.CENTROID;
  else if (name === KML_FEATURE_TYPE.SLOW) return KML_FEATURE_TYPE.SLOW;
  else if (name === KML_FEATURE_TYPE.COLLECTED_FIELD) return KML_FEATURE_TYPE.COLLECTED_FIELD;
  else if (name === KML_FEATURE_TYPE.PATH) return KML_FEATURE_TYPE.PATH;
  else if (name === KML_FEATURE_TYPE.PULLIN) return KML_FEATURE_TYPE.PULLIN;
  else if (name === KML_FEATURE_TYPE.FIELD) return KML_FEATURE_TYPE.FIELD;
  else if (name === KML_FEATURE_TYPE.UNSAFE) return KML_FEATURE_TYPE.UNSAFE;
}

export function getZoneKey(properties: FeatureProperties) {
  return properties.poly_id;
}

export function getPullinKey(properties: FeatureProperties) {
  return properties.name;
}

export function getPathKey(properties: FeatureProperties) {
  return properties.name;
}

export function getFieldKey(properties: FeatureProperties) {
  return properties.name;
}

export function getCollectedFieldKey(properties: FeatureProperties) {
  return properties.poly_id;
}

export function getCorePointKey(properties: FeatureProperties) {
  return properties.name;
}

export function getCentroidKey(properties: FeatureProperties) {
  return properties.name;
}

export function getKey(properties: FeatureProperties, type: KmlFeatureType) {
  switch (type) {
    case KML_FEATURE_TYPE.SAMPLE_ZONE:
    case KML_FEATURE_TYPE.GENERIC_ZONE:
    case KML_FEATURE_TYPE.UNSAFE:
    case KML_FEATURE_TYPE.SLOW:
      return getZoneKey(properties);
    case KML_FEATURE_TYPE.COLLECTED_FIELD:
      return getCollectedFieldKey(properties);
    case KML_FEATURE_TYPE.PATH:
      return getPathKey(properties);
    case KML_FEATURE_TYPE.CENTROID:
      return getCentroidKey(properties);
    case KML_FEATURE_TYPE.CORE_POINT:
      return getCorePointKey(properties);
    case KML_FEATURE_TYPE.DUMP_EVENT:
      return `${properties.sample}-${properties.date}`;
    default:
      return undefined;
  }
}

export function getSelectedFeature(selectedCollection: Collection<Feature<SimpleGeometry>>) {
  const selectedFeature = selectedCollection.getArray()[0];
  const featureType = selectedFeature && getFeatureType(selectedFeature.getProperties() as FeatureProperties);
  return { selectedFeature, featureType };
}

export function transformObjectOl(selectedFeature: Feature<SimpleGeometry>) {
  const feature: KMLFeature = {
    properties: selectedFeature.getProperties(),
    geometry: {
      type: selectedFeature.getGeometry()?.getType(),
      coordinates: selectedFeature.getGeometry()?.getCoordinates(),
    },
  };

  return feature;
}
