import { AirtableRecord, AirtableSearch, Job, Mission, SampleBox, SamplingSpec, Session, User } from '../db';

import logger from '../logger';
import { AccessLevelStore, formatDirectionsUrl, wait } from '../utils';

import bcrypt from 'bcryptjs';
import { getCurrentMission, getCurrentUser, loadUsers } from '../dataModelHelpers';
import { alertError, alertSuccess, alertWarn, alertInfo, alertConfirm, alertFullScreen } from '../alertDispatcher';
import { dispatchSessionUpdated } from '../sessionEvents';
import { dispatchMissionCreatedDeleted } from '../missionEvents';
import Airtable from '../airtable';
import * as Sentry from '@sentry/react';
import getConnection from '../RobotConnection';
import { dispatchActiveBoxUpdated, dispatchBoxesUpdated } from '../boxEvents';
import { ShowAutoGenHelper, ConfirmColumn, JobType, RogoShapefile } from '../types/types';
import { MissionEventStore } from '../sampleSelectionHelpers';
import { JobChecker, JobLoader, BaseError } from '@rogoag/kml-error-checker';
import { errorTone } from '../alertTones';
import OriginalMissionLoader from '../services/mission-loading/original/OriginalMissionLoader';
import { Jobs } from '@rogoag/airtable';
import { IMissionLoader } from '../services/mission-loading/MissionLoaderCommon';
import boxCreator from '../services/BoxCreator';
import { purgeStore } from '../redux/store';

export async function newSession(username: string, password: string, alreadyHashed = false) {
  username = username.toLowerCase(); // usernames not case sensitive
  let userIds = User.query((user) => user.name.toLowerCase() === username).map((user) => user.instance_id);
  let session = Session.findOne((sel) => !!sel?.User_id && userIds.includes(sel.User_id));
  if (!session) {
    const getUser = () => {
      if (alreadyHashed) {
        return User.findOne((sel) => sel?.name === username && password === sel?.hashed_password);
      } else {
        return User.findOne(
          (sel) => sel?.name === username && bcrypt.compareSync(`${username}:${password}`, sel.hashed_password),
        );
      }
    };

    let user = getUser();

    if (!user) {
      // let's just try to refresh the users from the remote in case we didnt't get the users at startup
      await loadUsers();
      user = getUser();
    }

    if (user) {
      session = Session.create();
      session.token = bcrypt.hashSync(`${username}:${password}:${new Date()}`);
      const role = user.getRole();

      if (!role) {
        throw new Error(`User ${username} has no role`);
      }
      const d = new Date();
      d.setDate(d.getDate() + role.session_expiration_days);
      if (!user.instance_id) {
        throw new Error(`User ${username} has no instance_id`);
      }
      session.User_id = user.instance_id;
    }
  }

  return session;
}

// export function switch_robot(this: Session, robot_name: string) {
//   if (robot_name === 'none') {
//     this.Robot_id = undefined;
//   } else {
//     const robot await Robot.findOne(sel => sel.hostname === robot_name);
//     if (robot) {
//       this.Robot_id = robot.instance_id;
//       return robot;
//     }
//   }
//   return undefined;
// }

/**
 *
 * @returns true if the user is an admin
 */
export function currentUserIsAdmin(session?: Session) {
  if (!session) return false;
  const currentUser = session.getUser();
  if (currentUser) {
    const role = currentUser.getRole();
    if (!role) return false;
    return role.isAdmin;
  }
  return false;
}

/**
 * Loads the kml into the data model and links it with the session
 * @param {+} text text of the kml file
 * @param {*} jobId the job id being loaded
 */
export async function loadKml(
  this: Session,
  text: string,
  jobId?: string,
  {
    sampling = false,
    dealSitesType = undefined as JobType | undefined,
    fieldName = undefined as string | undefined,
    expectedDensity = undefined as number | undefined,
  } = {},
): Promise<Mission | undefined> {
  await logger.log('LOAD_KML', 'Start');

  const ignore_loading_errors = this.getUser()?.customer_boundary_recording;
  try {
    const loader = new JobLoader(
      { data: text, filename: 'kml' },
      { fieldName, expectedDensity, expectedType: dealSitesType },
    );
    const job = await loader.parse();
    const jobChecker = new JobChecker(job);
    let errors: BaseError[] = [];
    if (sampling) {
      errors = jobChecker.validateRunnable();
    } else {
      errors = jobChecker.validateSubmittable();
    }
    if (!ignore_loading_errors) {
      if (errors.length && sampling) {
        errorTone();
      }
      for (const error of errors) {
        if (error.severity === 2) {
          alertError(`Critical Error | ${error.message}`);
        } else if (error.severity === 1) {
          alertWarn(`Warning | ${error.message}`);
        }
      }
    }
  } catch (err) {
    errorTone();
    if (!!err && typeof err === 'object' && 'rogo' in err && 'message' in err && err.rogo) {
      alertWarn(`Found mission problem: ${err.message}`);
    } else {
      alertWarn('Unknown error hit during validation');
    }
  }

  try {
    await wait(100);
    const [mission] = await Mission.load_kml(text, jobId, { ignore_loading_errors });
    try {
      await mission.update_mission_details();
    } catch (e) {
      alertWarn('Unable to update mission details, please contact ops');
      Sentry.captureException(e);
    }

    const box = SampleBox.getCurrentBox();
    const boxAlreadyExists = !!box;
    const labNames = mission.getSamplingSpecs().map((spec) => spec.lab_name.replace(/"/g, ''));

    const boxLabName = box?.labName.replace(/"/g, '') ?? '';

    // TODO backwards compatiblility fix
    const jobLabName = labNames[0];

    this.Mission_id = mission.instance_id;

    if (sampling) {
      if (boxAlreadyExists) {
        box.getSampleBoxMissions().forEach((miss) => console.log(miss.job_id));
        const missionExists = box.getSampleBoxMissions().find((existMission) => existMission.job_id === mission.job_id);
        if (missionExists) {
          const confirm = await alertConfirm('The mission loaded is already in your box. Do you want to continue?');
          if (!confirm) {
            mission.dispose();

            return;
          }
        }
        if (!labNames.includes(boxLabName)) {
          await logger.log('LOAD_KML', `box lab does not match mission, job=${labNames.join(',')}, box=${boxLabName}`);
          // alertError('Mission does not match labs in box. Please Close Box to continue.');
          // look for open boxes that DO match the job lab
          const matchingBoxes = SampleBox.query((box) => box.labName.replace(/"/g, '') === jobLabName && !box.closed);
          const boxMatchingLabExists = matchingBoxes.length > 0;
          if (boxMatchingLabExists) {
            const matchingBox = matchingBoxes[0];
            // const confirm = await alertConfirm('Mission does not match labs in box. Do you want to switch to a box that matches the mission?');
            //EventBus.dispatch('LOADING_SCREEN:PAUSE');
            const confirm = await alertFullScreen(
              `Switching boxes`,
              "Lab doesn't match.\n" + `Do you want to switch to box <b>${matchingBox.short_uid}</b>?`,
              ['Yes', 'No'],
            );
            //EventBus.dispatch('LOADING_SCREEN:RESUME');
            if (confirm !== 'Yes') {
              alertWarn(
                `Lab for job does not match lab in box. Please Close that box OR open a new box at the prompt to continue.`,
              );
              mission.dispose();

              return;
            }
            //EventBus.dispatch('LOADING_SCREEN:PAUSE');
            const boxNextToOperator = await alertFullScreen(
              'Confirm Switch',
              `Confirm the box next to you`,
              //`MAKE SURE that box ${matchingBox.uid} (${matchingBox.friendlyId}) is next to you and ready to put samples into. Temporarily close the flaps on box ${box.uid} (${box.friendlyId}) to avoid confusion`,
              [box.short_uid, matchingBox.short_uid],
            );
            if (boxNextToOperator !== matchingBox.short_uid) {
              await alertFullScreen(
                'WRONG BOX!',
                `Please make sure to put the correct box next to you. The box you need is ${matchingBox.short_uid} for lab ${jobLabName}`,
                ['Ok'],
              );
            }
            //EventBus.dispatch('LOADING_SCREEN:RESUME');
            box.Session_id = 0;
            matchingBox.Session_id = this.instance_id!;
            dispatchActiveBoxUpdated();
            alertSuccess('Switched to new box');
          } else {
            //EventBus.dispatch('LOADING_SCREEN:PAUSE');
            const makeNewBox = await alertFullScreen("Labs don't match", `Open a new box?`, ['Yes', 'No']);
            //EventBus.dispatch('LOADING_SCREEN:RESUME');

            if (makeNewBox === 'Yes') {
              const newBox = await this.addAndSelectBox(mission);
              if (!newBox) {
                throw new Error('Could not create new box');
              }

              //EventBus.dispatch('LOADING_SCREEN:PAUSE');
              const boxNextToOperator = await alertFullScreen(
                'Label boxes with marker',
                `Box with samples: <b>${box.short_uid}</b>\n` +
                  `Empty box: <b>${newBox.short_uid}</b>\n` +
                  `Confirm the box next to you`,
                //`MAKE SURE that box ${matchingBox.uid} (${matchingBox.friendlyId}) is next to you and ready to put samples into. Temporarily close the flaps on box ${box.uid} (${box.friendlyId}) to avoid confusion`,
                [box.short_uid, newBox.short_uid],
              );
              if (boxNextToOperator !== newBox.short_uid) {
                await alertFullScreen('WRONG BOX!', `Put ${newBox.short_uid} next to you!`, ['Ok']);
              }
              // await alertFullScreen(
              //   'Switching boxes',
              //   `MAKE SURE that your new box <b>${newBox.uid}</b> (${newBox.friendlyId}) is next to you and ready to put samples into. Temporarily close the flaps on box <b>${box.uid}</b> (${box.friendlyId}) to avoid confusion`,
              //   ['Ok']
              // );

              //EventBus.dispatch('LOADING_SCREEN:RESUME');
            } else {
              mission.dispose();

              return;
            }
          }
        } else {
          // if a box already existed and matches the current mission lab, just set it as active
          box.Session_id = this.instance_id;
          dispatchActiveBoxUpdated();
        }
      } else {
        const openBoxes = SampleBox.getOpenBoxes().filter((box) => box.labName.replace(/"/g, '') === jobLabName);
        if (openBoxes.length > 0) {
          const matchingBox = openBoxes[0];
          alertInfo(
            `Switching to open box ${matchingBox.uid} (${matchingBox.friendlyId}) that matches the mission lab ${jobLabName}`,
          );
          matchingBox.Session_id = this.instance_id;
          dispatchActiveBoxUpdated();
        }
      }

      if (!this.robotConnected()) {
        alertInfo('Make sure to set depth in HMI if manually sampling!');
      }
    }

    MissionEventStore.reset();
    dispatchSessionUpdated();
    dispatchMissionCreatedDeleted();

    return mission;
  } catch (e) {
    console.error(e);
    Sentry.setContext('loadKml', { text, jobId });
    Sentry.captureException(e);
    alertError('Error while loading mission.' + e);

    return;
  }
}

/**
 * Load shape files as a mission into the data model
 * @param {string} name
 * @param {array} shpfiles
 * @param {string} jobId
 */
export async function loadShapes(
  this: Session,
  name: string,
  shpfiles: RogoShapefile[],
  jobId: string,
  setAutogenHelper?: ShowAutoGenHelper,
): Promise<Mission | undefined> {
  try {
    const confirmCallback = async (table: ConfirmColumn) => {
      const result = await new Promise<string>(async (resolve, reject) => {
        // EventBus.dispatch(SESSION_EVENTS.SHOW_SELECTION_HELPER);
        const job = await AirtableRecord.getRecord<Jobs>('Jobs', jobId);

        if (!setAutogenHelper) {
          throw new Error('Autogen helper undefined');
        }

        // @ts-ignore
        const jobType: JobType = job?.get('Deal Sites Type');

        setAutogenHelper({
          title: `Which field is the sample ID for '${table.filename}'?`,
          selection: table.selection,
          source: table.source,
          headers: table.headers,
          jobType: jobType || 'Grid',
          data: table.data,
          featureTypes: table.featureTypes,
          onSelection: (column: string) => {
            resolve(column);
          },
        });
      });

      if (setAutogenHelper) {
        setAutogenHelper(undefined);
      }

      console.log(`loadShapes - confirmCallback: return ${result}`);

      return result;
    };

    const mission = Mission.create();
    mission.job_id = jobId;
    const job = Job.create();
    job.Mission_id = mission.instance_id;

    await mission.update_mission_details({ loading_from_shapefiles: true });

    const [_, errorMessages] = await Mission.generate_from_shapes(name, shpfiles, jobId, confirmCallback, mission);

    if (mission) {
      this.Mission_id = mission.instance_id;
      dispatchSessionUpdated();
      dispatchMissionCreatedDeleted();
    }
    // report errors
    for (const errorMessage of errorMessages) {
      alertError(errorMessage);
    }

    return mission;
  } catch (e) {
    console.error(e);
    alertError('Error while loading mission.' + e);

    return undefined;
  } finally {
    console.log('Load shapefiles Complete');
  }
}

/**
 * Logs out of current session
 */
export async function logout(this: Session) {
  await SampleBox.logBoxes('BOXES_BEFORE_LOGOUT');

  const missions = Mission.query();
  for (const mission of missions) {
    mission.dispose();
  }

  // dispose the session
  this.dispose();

  // clear all Airtable searches
  const allSearches = AirtableSearch.query();
  for (const search of allSearches) {
    search.dispose();
  }

  const allBoxes = SampleBox.query();
  for (const box of allBoxes) {
    box.dispose();
  }

  // wipe redux store
  await purgeStore();

  try {
    Sentry.setUser(null);
  } catch (err) {
    console.log(err);
  }

  AccessLevelStore.reset();

  dispatchMissionCreatedDeleted();
  dispatchSessionUpdated();

  await logger.log('LOGOUT', 'logoutSuccess');
  await SampleBox.logBoxes('BOXES_AFTER_LOGOUT');

  logger.setUser();
}

export function robotConnected(this: Session) {
  console.log(`RC! session_ops.ts robotConnected`);
  const conn = getConnection(this.robot_hostname);
  return conn && conn.connected;
}

// TODO really a static Jobs utility?
export async function getDirections(id: string) {
  const job = await Airtable.getRecord<Jobs>('Jobs', id);
  if (job) {
    return formatDirectionsUrl(job.get('Lat'), job.get('Lon'));
  }
}

export async function loadMission(
  this: Session,
  jobID: string,
  sampling: boolean,
  setAutogenHelper: ShowAutoGenHelper | undefined,
): Promise<Mission | undefined> {
  const missionLoader: IMissionLoader = new OriginalMissionLoader();

  logger.log(`Using the original mission loader to load job ${jobID}`);

  return missionLoader.load(this, jobID, sampling, setAutogenHelper);
}

export async function addAndSelectBox(this: Session, mission?: Mission, spec?: SamplingSpec) {
  await logger.log('ADD_BOX', 'addBox start');

  const user = getCurrentUser();
  if (!user) {
    alertError('No User Found. Could not create new box!');

    return;
  }

  const _mission = mission || getCurrentMission();
  if (!_mission) {
    alertError('No Mission Found. Could not create new box!');

    return;
  }

  const job = _mission.getJob();
  const lab = spec?.lab_name ?? job?.lab_name ?? '';
  if (!lab) {
    // TODO: investigate
    console.warn(`AddAndSelectBox - No Destination Lab Found. Box will have no Lab, job`, job, spec);

    alertWarn('No Destination Lab Found. Box will have no Lab.');
  }
  // find an open box that is also the current box
  const existingBoxes = SampleBox.query((box) => !!box && !box.closed && !!box.Session_id);

  const box = boxCreator.create({
    labName: lab,
    labCode: spec?.lab_code ?? job?.lab_code,
    uid: null,
    closedTimestamp: '',
    username: user.name,
    userId: user.user_id,
  });

  dispatchBoxesUpdated();

  box.Session_id = this.instance_id;
  existingBoxes.forEach((existingBox) => {
    existingBox.Session_id = undefined;
  });

  dispatchActiveBoxUpdated();
  alertSuccess(`Box ${box.uid} created!`);

  return box;
}
