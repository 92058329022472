import { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Sample, SampleBox, SoilCore } from '../../../db';

import LabelCollection from './LabelCollection';

import { SetStateAsync, setStateAsync } from '../../../utils';

import { generateErrorMap, processScan } from '../../../barcodes';

import EventBus from '../../../EventBus';
import { SCAN_EVENTS, ScannedBarcodeEvent } from '../../../scanEvents';
import { setSampleBarcodes } from '../../../barcodes';
import { getCurrentMission, getCurrentSession } from '../../../dataModelHelpers';
import { BOX_EVENTS, dispatchBoxMovedSample } from '../../../boxEvents';
import BarcodeScanner from '../../utils/BarcodeScanner';
import { dispatchUpdatedCurrentSample, SESSION_EVENTS } from '../../../sessionEvents';
import { BarcodeFormat } from '@zxing/library';
import logger from '../../../logger';
import { BarcodeSelectionMode, SampleErrorMap } from '../../../types/types';
import { findSampleById } from '../../../sampleSelectionHelpers';
import CoresDialog from '../../map/dialogs/CoresDialog';
import { ManualMeasurementDialogParameters } from '../../../services/ManualMeasurementsChecker';

interface MissionLabelsProps {
  box?: SampleBox;
  toggleScanner: () => void;
  scannerActive: boolean;
  allBoxes: SampleBox[];
  barcodeAdvancementMode: BarcodeSelectionMode;
  photosBySampleId: Record<string, any[]>;
  openManualMeasurementDialog: (manualMeasurementDialogParameters: ManualMeasurementDialogParameters) => void;
}

// TODO should this be used elsewhere? Can we just use the Sample class?
interface LabelViewData {
  bagId: string;
  order: number;
  box?: SampleBox;
  instanceId: number;
}

export type LabelMap = Record<string, LabelViewData>;

interface MissionLabelsState {
  errMap: SampleErrorMap;
  labels: LabelMap;
  currentSampleId: string;
  currentSampleInstanceId: number;
  coresDialogOpen: boolean;
  coresForDialog: SoilCore[];
}

export default class MissionLabels extends PureComponent<MissionLabelsProps, MissionLabelsState> {
  setStateAsync: SetStateAsync;

  constructor(props: MissionLabelsProps) {
    super(props);
    this.state = {
      labels: {},
      errMap: {},
      currentSampleId: '',
      currentSampleInstanceId: 0,
      coresDialogOpen: false,
      coresForDialog: [],
    };

    this.setStateAsync = setStateAsync.bind(this);
    this.handleBarcodeUpdate = this.handleBarcodeUpdate.bind(this);
    this.barcodeHandler = this.barcodeHandler.bind(this);
    this.moveSample = this.moveSample.bind(this);
    this.updateCurrentSampleId = this.updateCurrentSampleId.bind(this);
    this.initLabels = this.initLabels.bind(this);
    this.loadCurrentSampleData = this.loadCurrentSampleData.bind(this);
  }

  /**
   * Register event listener for scanned barcode
   */
  async componentDidMount() {
    EventBus.on(SCAN_EVENTS.SCANNED_BARCODE, this.handleBarcodeUpdate);
    EventBus.on(BOX_EVENTS.SAMPLES_UPDATED, this.initLabels);
    EventBus.on(SESSION_EVENTS.UPDATED_CURRENT_SAMPLE, this.loadCurrentSampleData);
    await this.initLabels();
    await this.loadCurrentSampleData();
  }

  /**
   * Unregister event listener for scanned barcode
   */
  componentWillUnmount() {
    EventBus.remove(SCAN_EVENTS.SCANNED_BARCODE, this.handleBarcodeUpdate);
    EventBus.remove(BOX_EVENTS.SAMPLES_UPDATED, this.initLabels);
    EventBus.remove(SESSION_EVENTS.UPDATED_CURRENT_SAMPLE, this.loadCurrentSampleData);
  }

  /**
   * Move sample from one box to another
   * @param {number} sampleId The sample to switch
   * @param {number} toBoxInstanceId The box to switch to
   */
  async moveSample(sampleId: number, toBoxInstanceId: number) {
    const currentBox = SampleBox.getCurrentBox();
    const sample = Sample.get(sampleId);
    if (!sample) {
      await logger.log(
        'MOVE_SAMPLE',
        `moveSample(sampleId=${sampleId}, toBoxInstanceId=${toBoxInstanceId}): sampldId not found`,
      );
      return;
    }
    const fromBox = sample.getSampleBox();
    if (!fromBox) {
      await logger.log(
        'MOVE_SAMPLE',
        `moveSample(sampleId=${sampleId}, toBoxInstanceId=${toBoxInstanceId}): Can't find source box`,
      );
      return;
    }
    const toBox = SampleBox.get(toBoxInstanceId);
    if (!toBox) {
      await logger.log(
        'MOVE_SAMPLE',
        `moveSample(sampleId=${sampleId}, toBoxInstanceId=${toBoxInstanceId}): Can't find target box`,
      );
      return;
    }
    fromBox.needsUpdated = true;
    toBox.needsUpdated = true;
    const session = getCurrentSession();
    if (!session) {
      await logger.log(
        'MOVE_SAMPLE',
        `moveSample(sampleId=${sampleId}, toBoxInstanceId=${toBoxInstanceId}): No current session`,
      );
      return;
    }
    await logger.log(
      'MOVE_SAMPLE',
      `moveSample(sampleId=${sampleId}, toBoxInstanceId=${toBoxInstanceId}): Moving sample ${sampleId} from box ${fromBox?.uid} to box ${toBox?.uid}`,
    );
    if (currentBox?.instance_id === toBoxInstanceId) {
      fromBox.ReprintSession_id = session.instance_id;
      fromBox.reprint_reason = `One or more samples were moved from this box to ${toBox?.uid || 'another box'}`;
    } else {
      toBox.ReprintSession_id = session.instance_id;
      toBox.reprint_reason = `One or more samples were moved from ${
        fromBox?.uid || 'another box'
      } were moved to this box`;
    }
    sample.SampleBox_id = toBoxInstanceId;

    // TODO if we are moving the sample, the bag_id should be present
    const labels = await this.updateLabels(sampleId, sample.bag_id!, toBox);

    this.setState({ labels });

    dispatchBoxMovedSample();
  }

  async loadCurrentSampleData() {
    const session = getCurrentSession();
    if (!session) {
      await logger.log('ERROR', 'loadCurrentSampleData(): No current session');
      return;
    }
    const sample = session.getSample();
    if (sample) {
      this.setState({
        currentSampleInstanceId: sample.instance_id,
        currentSampleId: sample.sample_id,
      });
    } else {
      this.setState({ currentSampleInstanceId: 0, currentSampleId: '' });
    }
  }

  /**
   * Updates the state of the current sample id as well as the session
   * current sample id
   * @param {number} instanceId The current sample id
   */
  async updateCurrentSampleId(instanceId: number) {
    console.log(`updateCurrentSampleId(instanceId=${instanceId})`);
    const session = getCurrentSession();
    if (!session) {
      await logger.log('ERROR', `updateCurrentSampleId(instanceId=${instanceId}): No current session`);
      return;
    }
    session.Sample_id = instanceId;
    dispatchUpdatedCurrentSample();
  }

  /**
   * Handler for scanner event
   * @param {object} - contains the scan event result
   */
  async handleBarcodeUpdate(scanEvent: ScannedBarcodeEvent) {
    const { barcode, currentSampleInstanceId, errMap, nextSampleInstanceId } = scanEvent;
    // update the labels given a scan event

    // TODO this doesn't seem correct...
    let box: SampleBox | undefined = undefined;
    if (barcode) {
      box = this.props.box;
    }

    // @ts-ignore TODO see above, not sure why box is undefined
    const labels = await this.updateLabels(currentSampleInstanceId, barcode, box);
    if (nextSampleInstanceId) {
      // if the scan event contains the next sample, switch to it
      await this.updateCurrentSampleId(nextSampleInstanceId);
    }
    this.setState({ labels, errMap });
  }

  /**
   * Updates the state labels object and returns the result
   * @param {number} sampleId The sample id that is being updated
   * @param {string} barcode The new barcode
   * @param {SampleBox} box The data class instance id of the box
   * @returns {array} The result of the update
   */
  async updateLabels(instanceId: number, barcode: string, box: SampleBox) {
    console.log(`updateLabels(instanceId=${instanceId}, barcode=${barcode}, boxId=${box})`);
    const labelsCopy = { ...this.state.labels };
    const sample = Sample.get(instanceId);
    if (!sample) {
      await logger.log(
        'ERROR',
        `updateLabels(instanceId=${instanceId}, barcode=${barcode}, boxId=${box.uid}): sample not found`,
      );
      return labelsCopy;
    }
    const sample_id = sample.sample_id;
    if (!sample_id) {
      await logger.log(
        'ERROR',
        `updateLabels(instanceId=${instanceId}, barcode=${barcode}, boxId=${box.uid}): sample_id not found`,
      );
      return labelsCopy;
    }
    const order = labelsCopy[sample_id].order;
    labelsCopy[sample_id] = { bagId: barcode, order, box, instanceId };

    return labelsCopy;
  }

  /**
   * Initialize the labels object
   */
  async initLabels() {
    const labels: LabelMap = {};
    const mission = getCurrentMission();
    if (!mission) {
      await logger.log('ERROR', 'initLabels(): No current mission');
      return;
    }
    const samples = mission.getAllSamples();

    // generate the labels object for UI display
    for (let sampleIdx = 0; sampleIdx < samples.length; sampleIdx++) {
      const sample = samples[sampleIdx];
      const sampleBox = sample.getSampleBox();
      const sample_id = sample.sample_id;
      if (!sample_id) {
        console.log('ERROR', `initLabels(): sample_id not found for sample ${sample.instance_id}`);
        continue;
      }

      labels[sample_id] = {
        bagId: sample.bag_id || '',
        order: sampleIdx,
        box: sampleBox,
        instanceId: sample.instance_id,
      };
    }

    let errMap = generateErrorMap(samples); // produces a map of errors

    const currentBox = SampleBox.getCurrentBox();
    if (currentBox) {
      const boxSamples = currentBox.getSamples();
      const boxErrMap = generateErrorMap(boxSamples);
      errMap = { ...errMap, ...boxErrMap };
    }

    this.setState({ labels, errMap });
  }

  /**
   * Called when manually typing in barcode
   * @param {string} currentSampleInstanceId sample id to modify
   * @param {string} barcode new barcode text
   */
  async barcodeHandler(currentSampleInstanceId: number, barcode: string) {
    try {
      const errMap = await setSampleBarcodes(currentSampleInstanceId, barcode, false); // set samples in db
      await this.handleBarcodeUpdate({
        barcode,
        currentSampleInstanceId,
        errMap,
        nextSampleInstanceId: 0,
      }); // update labels
    } catch (err) {
      await logger.log(
        `BARCODE_HANDLER`,
        `currentSampleInstanceId=${currentSampleInstanceId}, barcode=${barcode}, err=${JSON.stringify(err)}`,
      );
    }
  }

  render() {
    const mission = getCurrentMission();
    if (!mission) {
      return null;
    }

    return (
      <>
        {this.state.coresDialogOpen && this.props.openManualMeasurementDialog && (
          <CoresDialog
            handleClose={() =>
              this.setState({
                coresDialogOpen: false,
              })
            }
            cores={this.state.coresForDialog}
            openManualMeasurementDialog={this.props.openManualMeasurementDialog}
          />
        )}

        <Paper elevation={3} style={{ width: '100%', marginTop: 20 }}>
          <Grid container spacing={1}>
            {this.props.scannerActive && (
              <Grid item xs={12} style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 20 }}>
                <BarcodeScanner
                  updateDetectedCode={processScan}
                  handleLoadFailure={this.props.toggleScanner}
                  closeScanner={this.props.toggleScanner}
                  fullScreen={false}
                  type={[BarcodeFormat.DATA_MATRIX, BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128]}
                  sampleScanner={true}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <LabelCollection
                mission={mission}
                barcodeHandler={this.barcodeHandler}
                errMap={this.state.errMap}
                sampleHandler={this.updateCurrentSampleId}
                currentSampleId={this.state.currentSampleId}
                currentSampleInstanceId={this.state.currentSampleInstanceId}
                labels={this.state.labels}
                moveSample={this.moveSample}
                activeBox={this.props.box}
                // boxUid={this.props.box?.uid}
                allBoxes={this.props.allBoxes}
                photosBySampleId={this.props.photosBySampleId}
                onCoresButtonClick={(sampleID) => {
                  const sample = findSampleById(sampleID);
                  if (!sample) {
                    return;
                  }

                  this.setState({
                    coresDialogOpen: true,
                    coresForDialog: sample.getSoilCores(),
                  });
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}
