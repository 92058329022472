import { Coordinate } from 'ol/coordinate';
import EventBus from './EventBus';
import { Extent } from 'ol/extent';

export const MAP_EVENTS = {
  SAMPLE_ARRIVED: 'sample arrived',
  SAMPLE_LEFT: 'sample left',
  CLOSE_SAMPLE_ARRIVED: 'close sample arrived',
  CLOSE_SAMPLE_LEFT: 'close sample left',
  SAMPLE_SELECTED: 'sample selected',
  SAMPLES_UPDATED: 'map: samples updated',
  CENTER_UPDATED: 'center updated',
  CORES_NEED_UPDATE: 'cores need update',
  CLOSE_SAMPLE_NEEDS_UPDATE: 'close sample needs update',
  FIT_UPDATED: 'fit updated',
  EXTENT_UPDATED: 'extent updated',
  ROTATION_UPDATED: 'rotation updated',
  RESOLUTION_UPDATED: 'resolution updated',
  POINT_RESOLUTION_UPDATED: 'point resolution updated',
  PPM_UPDATED: 'pixels per meter updated',
  ZOOM_UPDATED: 'zoom updated',
  ZOOM_TO_MISSION: 'zoom to mission',
  SET_ZOOM: 'set zoom',
  SET_CENTER: 'set center',
  SET_ROTATION: 'set rotation',
  SET_FIT: 'set fit',
};

// Change notifiers
export function dispatchZoomUpdated(zoom: number) {
  EventBus.dispatch(MAP_EVENTS.ZOOM_UPDATED, zoom);
}

export function dispatchCloseSampleNeedsUpdate() {
  EventBus.dispatch(MAP_EVENTS.CLOSE_SAMPLE_NEEDS_UPDATE);
}

export function dispatchSamplesUpdated(sampleIds: string[]) {
  EventBus.dispatch(MAP_EVENTS.SAMPLES_UPDATED, JSON.stringify({ sampleIds }));
}

export function dispatchCoresNeedUpdate() {
  EventBus.dispatch(MAP_EVENTS.CORES_NEED_UPDATE);
}

export function dispatchMapExtentUpdated(extent: Extent) {
  EventBus.dispatch(MAP_EVENTS.EXTENT_UPDATED, extent);
}

export function dispatchCenterUpdated(center: [number, number]) {
  EventBus.dispatch(MAP_EVENTS.CENTER_UPDATED, center);
}

export function dispatchRotationUpdated(rotation: number) {
  EventBus.dispatch(MAP_EVENTS.ROTATION_UPDATED, rotation);
}

export function dispatchMapResolutionUpdated(resolution: number) {
  EventBus.dispatch(MAP_EVENTS.RESOLUTION_UPDATED, resolution);
}

export function dispatchMapPointResolutionUpdated(pointResolution: number) {
  EventBus.dispatch(MAP_EVENTS.POINT_RESOLUTION_UPDATED, pointResolution);
}

export function dispatchPixelsPerMeterUpdated(pixelsPerMeter: number) {
  EventBus.dispatch(MAP_EVENTS.PPM_UPDATED, pixelsPerMeter);
}

export function dispatchFitUpdated(fit: [[number, number], [number, number]]) {
  EventBus.dispatch(MAP_EVENTS.FIT_UPDATED, fit);
}

// Setter functions
export function dispatchZoomToMission() {
  EventBus.dispatch(MAP_EVENTS.ZOOM_TO_MISSION);
}

export function dispatchSetZoom(zoom: number) {
  EventBus.dispatch(MAP_EVENTS.SET_ZOOM, zoom);
}

export function dispatchSetCenter(center: Coordinate) {
  EventBus.dispatch(MAP_EVENTS.SET_CENTER, center);
}

export function dispatchSetRotation(rotation: number) {
  EventBus.dispatch(MAP_EVENTS.SET_ROTATION, rotation);
}

export function dispatchSetExtent(extent: Extent) {
  EventBus.dispatch(MAP_EVENTS.SET_FIT, extent);
}

export function dispatchSetFit(fit: [Coordinate, Coordinate]) {
  EventBus.dispatch(MAP_EVENTS.SET_FIT, fit);
}
