import React, { PureComponent } from 'react';
import { Paper, Grid, FormControlLabel, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { LoadingButton } from '../utils';
import { CustomCheckbox } from '../utils/CustomCheckbox';
import { getCurrentSession } from '../../dataModelHelpers';
import getConnection from '../../RobotConnection';
import { RosMissionMsg, RobotControlRole } from '../../types/rosmsg';
import ShowHMIButton from '../mission/buttons/ShowHMIButton';
import { alertError } from '../../alertDispatcher';

interface MissionSyncProps {
  autoSync: boolean;
  last_sample: string;
  lastSyncTimestamp?: string;
  onSettingsChange: (label: string, newValue: any) => void;
  outOfSync: boolean;
  syncMission: () => void;
  syncInProgress: boolean;
  trackingOverride: boolean;
  updateAutoSync: (autoSync: boolean) => Promise<void>;
  robotMission?: RosMissionMsg;
  toggleTrackingOverride: () => void;
  robotControlRole: RobotControlRole;
  waitingForRoleChange: boolean;
  waitForRoleChange: () => void;
  toggleHMIVisible: () => void;
  hmiVisible: boolean;
}

export default class MissionSync extends PureComponent<MissionSyncProps> {
  render() {
    return (
      <Grid item xs={12}>
        <Paper style={{ padding: 20, margin: 10 }}>
          <Grid container item xs={12} spacing={1} alignItems="center">
            <Table size={'medium'}>
              <TableBody>
                <TableRow>
                  <TableCell>Current Robot Mission</TableCell>
                  <TableCell style={{ color: this.props.outOfSync ? 'red' : '' }}>
                    <b>
                      {this.props.robotMission ? this.props.robotMission.name : '?'}
                      {/* Prevent table shifting when length of string changes */}
                      {this.props.outOfSync
                        ? '(Out of Sync)'
                        : '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Sync Time</TableCell>
                  <TableCell>{this.props.lastSyncTimestamp || '?'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Previous Sample</TableCell>
                  <TableCell>{this.props.last_sample}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid container alignItems="center" style={{ marginTop: 20 }}>
              <Grid item>
                <LoadingButton
                  variant="outlined"
                  color="secondary"
                  //disabled={!this.props.outOfSync}
                  loading={this.props.syncInProgress}
                  onClick={this.props.syncMission}
                >
                  Sync mission
                </LoadingButton>
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ paddingLeft: 10 }}
                  label="Auto-sync mission"
                  disabled={this.props.robotControlRole !== 'control'}
                  control={
                    <CustomCheckbox
                      checked={this.props.autoSync}
                      color="primary"
                      onChange={async (e) => {
                        this.props.onSettingsChange('Auto-sync mission', e.target.checked);
                        await this.props.updateAutoSync(e.target.checked);
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  style={{ paddingLeft: 10 }}
                  label="Use tablet location"
                  control={
                    <CustomCheckbox
                      checked={this.props.trackingOverride}
                      color="primary"
                      onChange={() => {
                        this.props.onSettingsChange('Use tablet location', !this.props.trackingOverride);
                        this.props.toggleTrackingOverride();
                      }}
                    />
                  }
                />
              </Grid>
              <Grid item style={{ fontSize: 16 }}>
                <Typography>Current Robot Control State:</Typography>
              </Grid>
              <Grid
                item
                style={{
                  fontSize: 16,
                  paddingRight: 10,
                  marginRight: 6,
                  paddingLeft: 8,
                  backgroundColor: this.props.robotControlRole === 'control' ? 'green' : 'red',
                  color: 'white',
                }}
              >
                <Typography>
                  <b>{this.props.robotControlRole.toString().toUpperCase()}</b>
                </Typography>
              </Grid>
              <Grid>
                <LoadingButton
                  loading={this.props.waitingForRoleChange}
                  variant="outlined"
                  onClick={() => {
                    const session = getCurrentSession();
                    if (!session) {
                      alertError('Unable to determine current robot hostname. Please refresh!');
                      return;
                    }
                    const connection = getConnection(session.robot_hostname);
                    if (!connection) {
                      alertError('Unable to connect to robot. Please refresh!');
                      return;
                    }
                    this.props.waitForRoleChange();
                    connection.setControlRole(this.props.robotControlRole === 'control' ? 'view' : 'control');
                  }}
                >
                  {this.props.robotControlRole === 'control' ? 'View only' : 'Take Control'}
                </LoadingButton>
              </Grid>
              <ShowHMIButton
                style={{ paddingLeft: 20 }}
                toggleHMIVisible={this.props.toggleHMIVisible}
                hmiVisible={this.props.hmiVisible}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}
