import { TableCell, TableRow } from '@material-ui/core';
import { ShippingGroupBox } from './ShippingGroupBox';
import { Fragment } from 'react/jsx-runtime';
import { LoadingButton } from '../utils';

interface Props {
  labName: string;
  dropoffName: string;
  group: any;
  switchToCourier: (dropoffName: string, labName: string) => Promise<void>;
  expandBox: (boxId: string) => void;
  collapseBox: (boxId: string) => void;
  excludeBox: (boxId: string) => void;
  scannedBoxIds: string[];
  expandedBoxes: string[];
  excludedBoxes: string[];
}
export function ShippingLabGroup({
  labName,
  dropoffName,
  group,
  switchToCourier,
  expandBox,
  collapseBox,
  excludeBox,
  scannedBoxIds,
  expandedBoxes,
  excludedBoxes,
}: Props) {
  return (
    <Fragment key={labName}>
      {group.get(dropoffName).method === 'UPS' && (
        <TableRow key={labName}>
          <TableCell colSpan={2} align="left" style={{ padding: 5 }}>
            {labName}
          </TableCell>
          <TableCell colSpan={1} align="right" style={{ padding: 5 }}>
            <LoadingButton
              onClick={async () => await switchToCourier(dropoffName, labName)}
              color="secondary"
              size="small"
            >
              Switch to Courier
            </LoadingButton>
          </TableCell>
        </TableRow>
      )}
      {group
        .get(dropoffName)
        .labBoxes.get(labName)
        .map((box) => (
          <ShippingGroupBox
            box={box}
            expandBox={expandBox}
            collapseBox={collapseBox}
            excludeBox={excludeBox}
            dropoffName={dropoffName}
            group={group}
            expandedBoxes={expandedBoxes}
            excludedBoxes={excludedBoxes}
            scannedBoxIds={scannedBoxIds}
          />
        ))}
    </Fragment>
  );
}
