import { Component, PropsWithChildren } from 'react';

import LoadingButton from './LoadingButton';
import logger from '../../logger';
import { alertError } from '../../alertDispatcher';

import { DB_NAME } from '../../db';
import { saveCurrentMissionRecoveryZip } from '../../dataModelHelpers';
import { OnLoading } from '../../types/types';
import { TaskName } from '../../db/TaskClass';
import { purgeStore } from '../../redux/store';

interface ResetButtonProps {
  onLoading: OnLoading;
  currentTask: TaskName;
}

interface ResetButtonState {
  wipeConfirm: boolean;
}

export default class ResetButton extends Component<PropsWithChildren<ResetButtonProps>, ResetButtonState> {
  constructor(props: ResetButtonProps) {
    super(props);
    this.state = {
      wipeConfirm: false,
    };

    this.wipeAll = this.wipeAll.bind(this);
  }

  async wipeAll() {
    if (this.state.wipeConfirm) {
      await this.props.onLoading(async () => {
        await logger.log('WIPE_ALL', 'resetConfirmed');
        // execute save recovery zip
        if (this.props.currentTask === 'sampling') {
          try {
            await saveCurrentMissionRecoveryZip({ tag: 'reset', uploadToAWS: true });
          } catch (e) {
            console.error('Failed to execute save recovery zip', e);
          }
        }

        // wipe local storage
        localStorage.clear();

        // wipe redux store
        await purgeStore();

        // wipe DB
        try {
          await Promise.race([
            new Promise((resolve, reject) => {
              const delReq = indexedDB.deleteDatabase(DB_NAME);
              delReq.onsuccess = resolve;
              delReq.onerror = reject;
            }),
            new Promise((r) => setTimeout(r, 5000)), // unconditionally refresh page after 5s
          ]);
        } catch (e) {
          console.error('Failed to reset database', e);
          alertError('Failed to reset database');
        }
        // refresh the page
        window.location.reload();
      });
    } else {
      this.setState({ wipeConfirm: true });
      setTimeout(() => {
        this.setState({ wipeConfirm: false });
      }, 2000);
    }
  }

  render() {
    return (
      <LoadingButton
        onClick={this.wipeAll}
        style={{
          justifyContent: 'left',
          textTransform: 'capitalize',
          width: '200px',
          color: 'orange',
        }}
      >
        {this.state.wipeConfirm ? 'Tap again to confirm' : this.props.children}
      </LoadingButton>
    );
  }
}
