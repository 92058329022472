import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import SampleSite, { SampleSiteList } from './SampleSiteClass';
import { ISampleCentroid } from './types';
import { Coordinate } from 'ol/coordinate';
import Sample from './SampleClass';

export default class SampleCentroid
  extends ObjectClassGenerator<SampleCentroid>('SampleCentroid')
  implements ISampleCentroid
{
  // attributes
  #lat: number;
  #lon: number;

  // relationships
  #SampleSite_id: number | undefined;

  static tableName = 'SampleCentroid';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SampleCentroid, 'lat');
    this.publishAttribute(SampleCentroid, 'lon');
    this.publishAttribute(SampleCentroid, 'SampleSite_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SampleCentroid> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#lat = state.lat || 0.0;
    this.#lon = state.lon || 0.0;
    this.#SampleSite_id = state.SampleSite_id;
  }

  dispose() {
    const samplesite = this.getSampleSite();
    if (samplesite) {
      samplesite.dispose();
      this.SampleSite_id = undefined;
    }
    SampleCentroid.delete(this.instance_id);
  }

  set lat(value) {
    this.#lat = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lat() {
    return this.#lat;
  }

  set lon(value) {
    this.#lon = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lon() {
    return this.#lon;
  }

  get name() {
    return this.getSampleSite()?.mission_name;
  }

  get last_modified() {
    return this.getSampleSite()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getSampleSite();
    if (inst) {
      inst.last_modified = value;
    }
  }

  get sample_id() {
    // TODO this is a hack...
    return this.getSampleSite()?.getSamples()[0].sample_id;
  }

  set SampleSite_id(value) {
    if (this.#SampleSite_id) {
      const relateObj = SampleSite.get(this.#SampleSite_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SampleCentroid', this.instance_id);
      }
    }
    this.#SampleSite_id = value;
    if (value) {
      const relateObj = SampleSite.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SampleCentroid', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SampleSite_id() {
    return this.#SampleSite_id;
  }

  displace(diffLat: number, diffLon: number) {
    // return this.move(this.#lat + lat, this.#lon + lon);
    this.#lat += diffLat;
    this.#lon += diffLon;
    this.last_modified = Date.now() / 1000;

    this.getSampleSite()
      ?.getSamples()
      ?.forEach((sample: Sample) => {
        sample.clearBarcode();

        // Move cores
        sample.getSoilCores().forEach((soilcore) => soilcore.displace(diffLat, diffLon));
      });

    this.syncToDB();
  }

  move(lat: number, lon: number) {
    this.displace(lat - this.#lat, lon - this.#lon);
  }

  findSampleWithBarcode() {
    return this.getSampleSite()
      ?.getSamples()
      .find((sample: Sample) => sample.hasBarcode());
  }

  getCoordinates(): Coordinate {
    return [this.lat, this.lon];
  }

  getSampleSite() {
    return SampleSite.get(this.SampleSite_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SampleCentroid.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.name === this.name &&
        sel.last_modified === this.last_modified &&
        sel.sample_id === this.sample_id,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate sample centroid found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`sample centroid: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getSampleSite()) {
      problems.push(
        `sample centroid: Could not find sample site instance across unconditional relationship R17: ${this.instance_id}`,
      );
    }
    return problems;
  }
}

export class SampleCentroidList extends DataObjectList<SampleCentroid> {
  getSampleSites() {
    return new SampleSiteList(...this.map((samplecentroid) => samplecentroid.getSampleSite()).filter((sel) => !!sel));
  }
}
