import React, { PropsWithChildren, PureComponent } from 'react';

interface MapOverlayProps {
  text?: string;
  marginTop: number;
  blink?: boolean;
  style?: React.CSSProperties;
}

export default class MapOverlayText extends PureComponent<PropsWithChildren<MapOverlayProps>> {
  render() {
    return (
      <div
        style={{
          margin: 0,
          position: 'absolute',
          right: 10,
          zIndex: 1,
          fontSize: '24px',
          lineHeight: 0.9,
          padding: 0,
          color: '#FFFFFF',
          textShadow: '#000 2px 2px 7px',
          animation: this.props.blink ? 'redBlinkingBackground 2s infinite' : undefined,
          // put marginTop at the bottom so it overrides style
          // we should eventually refactor this to go into style
          // so what is above here is like "defaults"
          marginTop: this.props.marginTop,
          ...this.props.style,
        }}
      >
        {this.props.text}
        {this.props.children}
      </div>
    );
  }
}
