import { AirtableRecord } from '../../../db';

export function getCurrentShift(selectedShift: string, availableShifts: AirtableRecord[]) {
  const [schedDate, robot, operatorName] = selectedShift.split('/');

  const shiftObj = availableShifts.filter(
    (shift) =>
      shift.get('Robot')[0] === robot &&
      shift.get('Sched Date') === schedDate &&
      shift.get('Operator Name')[0] === operatorName,
  );

  return shiftObj && shiftObj.length ? shiftObj[0] : undefined;
}

export function getNextShift(selectedShift: string, availableShifts: AirtableRecord[]) {
  const [schedDate, robot, operatorName] = selectedShift.split('/');

  availableShifts = availableShifts.filter(
    (shift) =>
      shift.get('Robot')[0] === robot &&
      shift.get('Operator Name')[0] === operatorName &&
      new Date(shift.get('Sched Date')) > new Date(schedDate),
  );

  return availableShifts[0];
}
