import { alertSuccess, alertWarn, alertInfo } from './alertDispatcher';
import logger from './logger';
import { CoreMsg } from './types/rosmsg';

const CORE_STATES = {
  '1': 'inactive',
  '2': 'initializing',
  '3': 'checking brake',
  '4': 'checking speed',
  '5': 'lowering carriage',
  '6': 'approaching ground',
  '7': 'plunging',
  '8': 'dwelling',
  '9': 'retracting',
  '10': 'rising from ground',
  '11': 'safe position reached',
  '12': 'dump position reached',
  '13': 'core done',
};

// TODO SVH 2024-09-06 this isn't used anymore, leaving here in the near term
// const DUMP_STATES = {
//     '1': 'inactive',
//     '2': 'initializing',
//     '3': 'rising to dump position',
//     '4': 'awaiting dump',
//     '5': 'bucket removed',
//     '6': 'bucket replaced',
//     '7': 'done dumping'
// }

const HOME_STATES = {
  '1': 'inactive',
  '2': 'initializing',
  '3': 'homing',
  '4': 'waiting to reach dump position',
  '5': 'done homing',
};

const ARM_RESPONSES = {
  '11': 'estop was cut',
  '12': 'door is ajar',
  '13': 'bucket is ajar',
  '14': 'arm is not enabled',
  '16': 'arm is busy',
  '17': 'process timed out waiting',
  '19': 'timed out waiting on speed',
  '20': 'mode switched',
  '21': 'brake unrepressed while coring',
  '22': 'speed exceeded during coring',
  '23': 'trying to navigate while coring',
  '25': 'waiting on dump',
};

export function handleCoreMessage(response: number, data: CoreMsg) {
  logger.log('ROBOT_DATA_MESSAGE', data);
  const { postlaunch_depth_error: depthError, status_code: code } = data;
  switch (response) {
    case 0:
      if (code === 0) {
        //alertSuccess(`Core success!  Depth Error: ${depthError > 0 ? `${Math.abs(depthError)}mm short` : `${Math.abs(depthError)}mm too deep`}`);
        // TODO LOGGING add to mission data
      } else if (code === 15) {
        alertWarn(
          `Core finished, but exceeded max depth! Depth Error: ${depthError > 0 ? `${Math.abs(depthError)}mm short` : `${Math.abs(depthError)}mm too deep`}`,
        );
      } else {
        alertWarn('Core finished with unknown result code!');
      }
      break;
    case 1:
      const abortCode = String(code);
      const abortReason = ARM_RESPONSES[abortCode];
      if (code >= 32) {
        const stateNumber = String(code - 32);
        const state = CORE_STATES[stateNumber];
        if (state) {
          alertWarn(
            `Core timed out! State: ${state} | Depth error: ${depthError > 0 ? `${Math.abs(depthError)}mm short` : `${Math.abs(depthError)}mm too deep`}`,
          );
        } else {
          alertWarn('Core timed out at an unknown state!');
        }
      } else if (abortReason) {
        alertWarn(
          `Core failed! Reason: ${abortReason} | Depth Error: ${depthError > 0 ? `${Math.abs(depthError)}mm short` : `${Math.abs(depthError)}mm too deep`}`,
        );
      } else {
        alertWarn('Core failed with an unknown reason!');
      }
      break;
    case 2:
      const cancelCode = String(code);
      const cancelReason = ARM_RESPONSES[cancelCode];
      if (cancelReason) {
        alertWarn(`Core cancelled! Reason: ${cancelReason}`);
      } else {
        alertWarn('Core cancelled with an unknown reason!');
      }
      break;
    default:
      alertWarn('Got unknown core result code!');
  }
}

export function handleDumpMessage(response, data: { status_code: number }) {
  logger.log('ROBOT_DATA_MESSAGE', data);
}

export function handleHomeMessage(response, data) {
  logger.log('ROBOT_DATA_MESSAGE', data);
  const code = data.status_code;
  switch (response) {
    case 0:
      alertSuccess('Home success!');
      break;
    case 1:
      const abortCode = String(code);
      const abortReason = ARM_RESPONSES[abortCode];
      if (code >= 32) {
        const stateNumber = String(code - 32);
        const state = HOME_STATES[stateNumber];
        if (state) {
          alertWarn(`Home timed out! State: ${state}`);
        } else {
          alertWarn('Home timed out at an unknown state!');
        }
      } else if (abortCode) {
        alertWarn(`Home failed! Reason: ${abortReason}`);
      } else {
        alertWarn('Home failed for an unknown reason');
      }
      break;
    case 2:
      const cancelCode = String(code);
      const cancelReason = ARM_RESPONSES[cancelCode];
      if (cancelReason) {
        alertWarn(`Home cancelled! Reason: ${cancelReason}`);
      } else {
        alertWarn('Home cancelled with an unknown reason!');
      }
      break;
    default:
      alertWarn('Got unknown home result code!');
  }
}

export function handleNavMessage(data: { status?: number }) {
  logger.log('ROBOT_DATA_MESSAGE', data);
  const response = data.status;
  const successString = 'Navigation Success! ';
  const failureString = 'Navigation Failure! ';
  const stoppedString = 'Navigation Stopped! ';
  const codeString = ` Code: ${response}`;
  switch (response) {
    case 0: // right now this is only sent when we hit the first core? need to also look at "type"
      // Robot to sample, Core to Core, or Sample to sample?
      alertSuccess(successString);
      break;
    case 4:
      alertWarn(failureString + 'No location - check gps!' + codeString);
      break;
    case 5:
      alertWarn(failureString + 'Failed to initialize -> redo, call support!' + codeString);
      break;
    case 6:
      alertWarn(failureString + 'Aborted by control -> redo, call support!' + codeString);
      break;
    case 7:
      alertWarn(failureString + 'Unready -> redo, call support!' + codeString);
      break;
    case 8:
      alertInfo(stoppedString + 'Estop triggered!' + codeString);
      break;
    case 9:
      alertWarn(failureString + 'Carriage unsafe -> ensure raised to safe mark!' + codeString);
      break;
    case 10:
      alertWarn(failureString + 'Seatbelt unbuckled!' + codeString);
      break;
    case 11:
      alertWarn(failureString + 'Drive controller unsafe -> call support!' + codeString);
      break;
    case 12:
      alertWarn(failureString + 'GPS Sensor Delay -> redo, call support!' + codeString);
      break;
    case 13:
      alertWarn(failureString + 'GPS low precision -> redo, call support!' + codeString);
      break;
    case 14:
      alertWarn(failureString + 'IMU Sensor Delay -> redo, call support!' + codeString);
      break;
    case 15:
      alertWarn(failureString + 'INS unready -> Drive to initialize!' + codeString);
      break;
    case 16:
      alertInfo(stoppedString + 'Went out of bounds -> move robot!' + codeString);
      break;
    case 17:
      alertWarn(failureString + 'No path in mission -> call ops!' + codeString);
      break;
    case 18:
      alertWarn(failureString + 'Software failure -> call support!' + codeString);
      break;
    case 19:
      alertWarn(failureString + 'Unsafe slope -> drive manually till safe!' + codeString);
      break;
    case 20:
      alertInfo(stoppedString + 'Switched to manual mode!' + codeString);
      break;
    case 21:
      alertInfo(stoppedString + 'Path heading unsafe/out-of-bounds -> drive manually!' + codeString);
      break;
    case 22:
      alertSuccess(successString + 'Mission Complete!' + codeString);
      break;
    case 23:
      alertInfo(stoppedString + 'Target waypoint changed while in nav -> hit go!' + codeString);
      break;
    case 24:
      alertWarn(failureString + 'No motion timeout -> redo, call support if often!' + codeString);
      break;
    case 25:
      alertInfo(stoppedString + 'Target waypoint lost -> set waypoint and hit go!' + codeString);
      break;
    case 26:
      alertInfo(stoppedString + 'Too far from core point -> hit go to try again!' + codeString);
      break;
    case 27:
      alertInfo(stoppedString + 'Operator cancelled nav!' + codeString);
      break;
    case 28:
      alertInfo(stoppedString + 'Core Failed -> hit go to try again!' + codeString);
      break;
    default:
      alertWarn('Navigation failed with an unknown status!' + codeString);
  }
}
