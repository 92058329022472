import { DataObject } from './dataobject';
import { MissionControlDatabase } from './db';
import { IDBPDatabase, openDB } from 'idb';
import { exportDB as dexieExportDB } from 'dexie-export-import';
import BaseTable, { TableIntegrityResult } from './basetable';
import logger from '../logger';
import * as Sentry from '@sentry/react';
import { fileToBase64, wait } from '../utils';
import { DBPeriodicSync } from './local_storage';
import { LocalStorageGenerator } from '../utils';
import { alertConfirm, alertError } from '../alertDispatcher';
import { IndexableType } from 'dexie';
import Attachment from './AttachmentClass';
import { storeDispatch } from '../redux/store';
import { addMissingBoxes, deleteAllBoxes } from '../redux/features/boxes/boxesSlice';
import SampleBox from './SampleBoxClass';

const SYNC_DELAY_MS = 1000;

let syncDelayTimeout: NodeJS.Timeout | undefined = undefined;
let syncInProgress = false;

export const DataLayerStorage = LocalStorageGenerator<'idb' | 'dexie'>('dataLayerStorage', 'dexie');

export const tableList: Record<string, BaseTable<DataObject>> = {};

let totalSyncTime = 0;
let totalSyncCount = 0;

export async function clearIndexDB(dbName: string) {
  // wipe DB
  try {
    await Promise.race([
      async () => await indexedDB.deleteDatabase(dbName),
      new Promise((r) => setTimeout(r, 10000)), // unconditionally refresh page after 10s
    ]);
  } catch (e) {
    console.error('Failed to reset database', e);
    alertError('Failed to reset database');
  }
}

async function clearAllData() {
  localStorage.clear();
  await clearIndexDB(DB_NAME);
}

export async function validateAllTableDataIntegrity() {
  // const results = await Promise.all(Object.values(tableList).map(table => table.dataIntegriyCheck()));
  const results: TableIntegrityResult[] = [];
  for (const tableName of Object.keys(tableList)) {
    try {
      results.push(await tableList[tableName].dataIntegriyCheck());
    } catch (e) {
      // TODO capture sentry error
      console.error('Error in data integrity check', e);
    }
  }

  const allRecordsGood = results.every((result) => result.results.valid);

  if (!allRecordsGood) {
    return false;
  }

  return true;
}

export function getMemoryTables() {
  return Object.values(tableList);
}

// Retrieve a data table.
export function getMemoryTable(tableName: string) {
  return tableList[tableName];
}

export async function deleteAllRecords({ exceptTables }: { exceptTables: string[] }) {
  for (const tableName of Object.keys(tableList)) {
    if (exceptTables.some((_tableName) => _tableName === tableName)) {
      continue;
    }

    const table = getMemoryTable(tableName);
    table.deleteAll();

    if (tableName === 'SampleBox') {
      storeDispatch(deleteAllBoxes());
    }
  }
}

// Register a new data table.

let periodicTimer: NodeJS.Timer | undefined;
const STARTUP_DELAY = 5;

// Load all tables from IndexedDB into memory.
// If no DB backend exists, do nothing.
export async function loadAllTables() {
  const db = getDatabase();
  if (db) {
    if (DataLayerStorage.get() === 'dexie') {
      // NEW DB CODE
      await Promise.all(Object.values(tableList).map((table) => table.restoreData()));

      // Put existing sample boxes to new boxes redux store - needed
      // for the fist time the app is loaded after introducing the new store.
      storeDispatch(addMissingBoxes(SampleBox.query()));
    } else {
      // OLD DB CODE
      // const tx = db.transaction(Object.keys(tableList), 'readwrite');
      // await Promise.all(Object.values(tableList).map((table) => table.initializeData(tx)));
    }
  } else {
    // if there is no DB backend, simply reset all tables changed marker
    Object.values(tableList).forEach((table) => table.resetSync());
  }

  if (DBPeriodicSync.get()) {
    // start the sync process after a few seconds
    setTimeout(resumePeriodicSync, STARTUP_DELAY * 1000);
  }
}

const pausePeriodicSync = () => {
  if (periodicTimer) {
    // @ts-ignore
    clearInterval(periodicTimer);
    periodicTimer = undefined;
  }
};

const resumePeriodicSync = () => {
  if (!periodicTimer) {
    // TODO define as constant
    periodicTimer = setInterval(periodicDBSync, savingTimeFrameMs);
  }
};

/**
 * Takes a raw JSON string and imports it into the database
 * @param data the raw JSON string from the uploaded file
 */
export async function importDB(data: string) {
  await clearAllData();
  const jsonData = JSON.parse(data);
  await loadPEI(jsonData);
}

// export all data tables as JSON blob
export async function exportDB(fromIndexedDB = false) {
  if (fromIndexedDB) {
    try {
      pausePeriodicSync();
      // wait periodic time to ensure last write is finished
      await wait(savingTimeFrameMs);
      if (DBPeriodicSync.get()) {
        return [await dexieExportDB(getDatabase()), 'alldata_idb.json', 'application/json'] as const;
      }
    } catch (e) {
      console.error('Error exporting IndexedDB data', e);
    } finally {
      resumePeriodicSync();
    }

    return;
  }

  const db = {};
  Object.entries(tableList).forEach(
    ([tableName, table]: [string, BaseTable<any>]) => (db[tableName] = table.query(() => true)),
  );

  await Promise.all(
    db['Attachment'].map(async (attachment: Attachment) => {
      return new Promise(async (res) => {
        attachment['contentB64'] = await fileToBase64(attachment.content as Blob);

        res(attachment);
      });
    }),
  );

  const stringData = JSON.stringify(db);

  // TODO transform blob entries to base64
  return [new Blob([stringData]), 'alldata.json', 'application/json'] as const;
}

// allow the app to request a sync
export async function requestDBSync() {
  if (localStorage.getItem('liveMode') === 'true') {
    return;
  }
  // If no sync is in progress, initiate sync immediately. Otherwise delay the
  // sync. If a sync is already delayed, reset the delay.
  if (syncInProgress) {
    clearTimeout(syncDelayTimeout);
    // Attempt a random delay to avoid more collissions for syncing
    syncDelayTimeout = setTimeout(requestDBSync, SYNC_DELAY_MS); //Math.random() * SYNC_DELAY_MS + MIN_SYNC_DELAY_MS);
    // do a console.count with the time in seconds so we get a count every second
    // console.count(`Sync Delayed ${Math.round(Date.now() / 1000)}`);
  } else {
    await syncAllTables().catch((e) => {
      // alertError('Error in background DB sync');
      Sentry.captureException(e);
      const forceSyncData = Object.keys(tableList)
        .map((tableName) => `${tableName}: ${getMemoryTable(tableName).requiresForceSync}`)
        .join(', ');
      logger.log('SYNC_ALL_TABLES', `Error in background DB sync: ${e}, force sync: ${forceSyncData}`);
      // console.error('Error in background DB sync: ' + e);
    });
    syncDelayTimeout = undefined;
  }
}

// execute a sync
async function syncAllTables() {
  console.count('Sync All Tables');
  syncInProgress = true;
  try {
    const changedTables = Object.keys(tableList).filter((tableName) => getMemoryTable(tableName).needsSync());
    if (changedTables.length) {
      console.debug(`Syncing ${changedTables.length} tables (${changedTables.join(', ')})`);
      // create IndexedDB transaction
      const t0 = performance.now();

      // sync tables
      const changedTablesEntries = Object.entries(tableList).filter(([key, table]) => changedTables.includes(key));
      for (const [, table] of changedTablesEntries) {
        await table.syncData();
      }

      // commit transaction
      const t1 = performance.now();

      const duration = t1 - t0;
      totalSyncTime += duration;
      totalSyncCount += 1;
      const averageDuration = totalSyncTime / totalSyncCount;
      console.debug(`${changedTables.length} tables synced in ${duration}ms, average ${averageDuration}`, t1 - t0);
      if (duration > averageDuration * 1.1) {
        // log long sync
        //await logger.log('SYNC_ALL_TABLES', `longSync ${{ duration, averageDuration, changedTables }}`);
      }
    }
  } finally {
    syncInProgress = false;
  }
}

// Initialize the DB
export const DB_NAME = 'missioncontrol';

let dbInstOld: IDBPDatabase<any>;
export async function initOldDatabase() {
  // Initialize the DB
  const DB_VERSION = 9;
  // initialize database
  if (dbInstOld) return dbInstOld;
  dbInstOld = await openDB(DB_NAME, DB_VERSION, {
    upgrade(upgradeDB: IDBPDatabase<unknown>, oldVersion, newVersion, transaction) {
      Object.keys(tableList).forEach((tableName) => {
        if (![...upgradeDB.objectStoreNames].includes(tableName))
          upgradeDB.createObjectStore(tableName, { keyPath: '_instance_id' });
      });
    },
  });
  return dbInstOld;
}

const savingTimeFrameSeconds = localStorage.getItem('fastMode') == 'true' ? 10 : 1;
const savingTimeFrameMs = savingTimeFrameSeconds * 1000;
const itemsToKeep = 50;
const deleteTimeFrameMs = itemsToKeep * savingTimeFrameMs;

let dbInstNew: MissionControlDatabase | undefined = undefined;
export function initNewDatabase() {
  // initialize database
  if (!dbInstNew) {
    dbInstNew = new MissionControlDatabase();
  }

  return dbInstNew;
}

async function periodicDBSync() {
  try {
    // backup entire in memory data store
    const inMemoryData = await exportDB(false);
    if (inMemoryData) {
      // save to blob store
      const db = getDatabase();
      const result = await db.MemoryBackup.put({
        timestamp: Date.now(),
        data: inMemoryData[0],
        name: 'auto',
        checksum: '',
      });

      // Make sure our last memory backup is saved successfully before deleting old backups
      if (result) {
        deleteOldMemoryBackups(db);
      }
    }
  } catch (e) {
    logger.log('PERIODIC_DB_SYNC', `Error in periodic sync: ${JSON.stringify(e)}`);
  }
}

async function deleteOldMemoryBackups(db: MissionControlDatabase) {
  // delete backups older than the calculated timeframe (number of items * seconds / item save = seconds of valid data)
  //                           seconds per
  // number of items to keep * -----------  = seconds of valid backups
  //                            item save
  const oldest = Date.now() - deleteTimeFrameMs;
  await db.MemoryBackup.where('timestamp').below(oldest).delete();
}

// let dbInst:
export function getDatabase() {
  // NEW DB CODE
  return initNewDatabase();

  // OLD DB CODE
  // return initOldDatabase();
}

export async function loadPEI(pei: { [key: string]: any }) {
  await logger.log('LOAD_PEI - Start');
  const db = getDatabase();

  if (DBPeriodicSync.get()) {
    await logger.log('LOAD_PEI - DBPeriodicSync is ON');

    const lastMemoryBackup = await db.MemoryBackup.orderBy('timestamp').last();
    // if we have data already, we don't want to overwrite it
    if (lastMemoryBackup) {
      return lastMemoryBackup;
    }

    const confirm = await alertConfirm(
      'This looks like a fresh launch - we have not found any saved data! If that is correct, click "Yes" and continue. If not - click "No" and contact support!',
      'loadPEI',
    );

    if (confirm) {
      await logger.log('LOAD_PEI - "Yes" chosen');

      return await db.MemoryBackup.put({
        timestamp: Date.now(),
        data: new Blob([JSON.stringify(pei)]),
        name: 'auto',
        checksum: '',
      });
    } else {
      await logger.log('LOAD_PEI - "No" chosen');

      return;
    }
  }

  await logger.log('LOAD_PEI - Loading from JSON file');

  const promiseGenerators: (() => Promise<IndexableType>)[] = [];
  for (const tableName of Object.keys(pei)) {
    const table = db.table(tableName);

    pei[tableName].forEach(async (inst: any): Promise<any> => {
      promiseGenerators.push(async () => {
        const obj = await table.get(parseInt(inst._instance_id));
        if (!obj) {
          return await table.put(inst);
        }

        return obj;
      });
    });
  }

  return await Promise.all(promiseGenerators.map((gen) => gen()));
}

// Integrity error definition
export class IntegrityError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'IntegrityError';
  }
}
