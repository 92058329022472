import React, { PureComponent } from 'react';

import Grid from '@material-ui/core/Grid';

import AddIcon from '@material-ui/icons/Add';

import { LoadingButton } from '../../utils';
import { getCurrentSession } from '../../../dataModelHelpers';
import { alertError, alertFullScreen } from '../../../alertDispatcher';
import { SampleBox } from '../../../db';

export default class AddBoxButton extends PureComponent {
  render() {
    return (
      <Grid item>
        <LoadingButton
          variant="outlined"
          color="default"
          onClick={async () => {
            const newBox = await getCurrentSession()?.addAndSelectBox();
            if (!newBox) {
              alertError('Could not create new box');
              return;
            }
            const allOpenBoxes = SampleBox.getOpenBoxes();
            if (allOpenBoxes.length > 1) {
              await alertFullScreen('Label box', `Empty box should be marked with: ${newBox.short_uid}`, ['Ok']);
              return;
            }
          }}
        >
          <AddIcon style={{ paddingRight: 7.5 }} /> Add Box
        </LoadingButton>
      </Grid>
    );
  }
}
