import { getCurrentMission } from '../../../dataModelHelpers';
import { dispatchMissionUpdated } from '../../../missionEvents';
import { MissionInfoState } from '../common/MissionInfo';

export function updateMissionInfo(key: keyof MissionInfoState, value: string) {
  const mission = getCurrentMission();
  const job = mission?.getJob();
  if (!mission || !job) {
    return;
  }
  switch (key) {
    case 'missionName':
      mission.name = value;
      break;
    case 'client':
      job.client = value;
      break;
    case 'grower':
      job.grower = value;
      break;
    case 'farm':
      job.farm = value;
      break;
    case 'field':
      job.field = value;
      break;
    case 'labName':
      job.lab_name = value;
      break;
    case 'labCode':
      job.lab_code = value;
      break;
    case 'testPackage':
      job.test_package = value;
      break;
    case 'addOnFreq':
      job.add_on_freq = parseInt(value);
      break;
    case 'responseEmail':
      job.response_email = value;
      break;
    case 'billingAccount':
      job.billing_account = value;
      break;
    case 'samplingCompanyName':
      job.sampling_company_name = value;
      break;
    case 'samplingCompanyId':
      job.sampling_company_id = value;
      break;
    case 'labAddress':
      job.lab_address = value;
      break;
    case 'labPrimaryDelivery':
      job.lab_primary_delivery = value;
      break;
    case 'eventId':
      job.event_id = value;
      break;
    case 'fieldId':
      job.field_id = value;
      break;
    case 'attachmentId':
      job.attachment_id = value;
      break;
    default:
  }

  dispatchMissionUpdated();
}
