//db.ts
import Dexie, { type EntityTable } from 'dexie';

import AirtableRecord from './AirtableRecordClass';
import AirtableSearch from './AirtableSearchClass';
import { DataObject } from './dataobject';
import Attachment from './AttachmentClass';
import Boundary from './BoundaryClass';
import CorePoint from './CorePointClass';
import Job from './JobClass';
import Mission from './MissionClass';
import PathPoint from './PathPointClass';
import Permission from './PermissionClass';
import Robot from './RobotClass';
import Role from './RoleClass';
import Sample from './SampleClass';
import SampleBox from './SampleBoxClass';
import SampleCentroid from './SampleCentroidClass';
import SampleSite from './SampleSiteClass';
import SampleZone from './SampleZoneClass';
import SamplingSpec from './SamplingSpecClass';
import SelectedRecord from './SelectedRecordClass';
import Session from './SessionClass';
import SoilCore from './SoilCoreClass';
import Task from './TaskClass';
import User from './UserClass';
import Waypoint from './WaypointClass';
import Zone from './ZoneClass';
import ZoneRecording from './ZoneRecordingClass';
import { ScannedBox } from './types';
import SoilDumpEvent from './SoilDumpEventClass';

type RogoEntityTable<T extends DataObject> = EntityTable<T, '_instance_id'>;

export interface MemoryBackup {
  timestamp: number;
  data: Blob;
  checksum: string;
  name: string;
}

export class MissionControlDatabase extends Dexie {
  AirtableRecord!: RogoEntityTable<AirtableRecord>;
  AirtableSearch!: RogoEntityTable<AirtableSearch>;
  Attachment!: RogoEntityTable<Attachment>;
  Boundary!: RogoEntityTable<Boundary>;
  CorePoint!: RogoEntityTable<CorePoint>;
  Job!: RogoEntityTable<Job>;
  Mission!: RogoEntityTable<Mission>;
  PathPoint!: RogoEntityTable<PathPoint>;
  Permission!: RogoEntityTable<Permission>;
  Robot!: RogoEntityTable<Robot>;
  Role!: RogoEntityTable<Role>;
  Sample!: RogoEntityTable<Sample>;
  SampleBox!: RogoEntityTable<SampleBox>;
  SampleCentroid!: RogoEntityTable<SampleCentroid>;
  SampleSite!: RogoEntityTable<SampleSite>;
  SampleZone!: RogoEntityTable<SampleZone>;
  SamplingSpec!: RogoEntityTable<SamplingSpec>;
  SelectedRecord!: RogoEntityTable<SelectedRecord>;
  Session!: RogoEntityTable<Session>;
  SoilCore!: RogoEntityTable<SoilCore>;
  SoilDumpEvent!: RogoEntityTable<SoilDumpEvent>;
  Task!: RogoEntityTable<Task>;
  User!: RogoEntityTable<User>;
  Waypoint!: RogoEntityTable<Waypoint>;
  Zone!: RogoEntityTable<Zone>;
  ZoneRecording!: RogoEntityTable<ZoneRecording>;
  ScannedBoxes!: EntityTable<ScannedBox, 'box_id'>;
  MemoryBackup: EntityTable<MemoryBackup, 'timestamp' | 'name'>;

  constructor() {
    super('missioncontrol', { chromeTransactionDurability: localStorage.getItem('fastMode') == 'true' ? 'relaxed' : 'strict' });
    this.version(3).stores({
      AirtableRecord: '++_instance_id',
      AirtableSearch: '++_instance_id',
      Attachment: '++_instance_id',
      Boundary: '++_instance_id',
      CorePoint: '++_instance_id',
      SoilDupmEvent: '++_instance_id',
      Job: '++_instance_id',
      Mission: '++_instance_id',
      PathPoint: '++_instance_id',
      Permission: '++_instance_id',
      Robot: '++_instance_id',
      Role: '++_instance_id',
      Sample: '++_instance_id',
      SampleBox: '++_instance_id',
      SampleCentroid: '++_instance_id',
      SampleSite: '++_instance_id',
      SampleZone: '++_instance_id',
      SamplingSpec: '++_instance_id',
      SelectedRecord: '++_instance_id',
      Session: '++_instance_id',
      SoilCore: '++_instance_id',
      SoilDumpEvent: '++_instance_id',
      Task: '++_instance_id',
      User: '++_instance_id',
      Waypoint: '++_instance_id',
      Zone: '++_instance_id',
      ZoneRecording: '++_instance_id',
      ScannedBoxes: 'box_id,timestamp',
      MemoryBackup: 'timestamp,name',
    });
  }
}
