import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SamplingMissionView from '../mission/SamplingMissionView';
import SampleScheduleView from '../schedule/SampleScheduleView';
import SamplingMap from '../map/SamplingMap';
import Robots from '../Robots';
import RobotPanel from '../robot/RobotPanel';
import { getCurrentMission, getCurrentSession, getJobKmlFromZip, textToZip, getField } from '../../dataModelHelpers';
import Airtable, { AirtableIDLookup } from '../../airtable';
import EventBus, { onRobotMessage, removeRobotMessageCallback, RobotNumberMessage } from '../../EventBus';
import logger from '../../logger';
import {
  alertConfirm,
  alertError,
  alertSuccess,
  alertInfo,
  alertWarnConfirm,
  alertWarn,
  alertFullScreen,
} from '../../alertDispatcher';
import { CreatedOrDeletedParams, dispatchMissionUpdated, MISSION_EVENTS } from '../../missionEvents';
import {
  SetStateAsync,
  convertProjection3857,
  getUserPosition,
  roundToIncrement,
  setStateAsync,
  uuidv4,
} from '../../utils';
import { genDropoffGeoJSON, genPullinGeoJSON, updateBoundaryProperties } from '../schedule/helpers/geojson';
import { errorTone } from '../../alertTones';
import { isMobile } from 'react-device-detect';
import { BOX_EVENTS, dispatchActiveBoxUpdated } from '../../boxEvents';
import { flatten, orderBy } from 'lodash';
import SlideoutTextBox from '../mission/sampling/OperatorNotes';
import { RobotLocalNetwork } from '../../robotNetwork';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ListItemText from '@material-ui/core/ListItemText';
import AirtableRecord from '../../db/AirtableRecordClass';
import { dispatchRosMsgPeer, dispatchRosMsgPub } from '../../RobotConnection';
import { TaskName } from '../../db/TaskClass';
import {
  CoringAllowedState,
  LatLonSource,
  MissionView,
  OnLoading,
  RobotArmType,
  RobotConnectionStatus,
  RobotNavControl,
  RobotState,
  ScheduleFeature,
  ScheduleItem,
  SettingsAccessLevel,
} from '../../types/types';
import { RosMissionMsg, RobotControlRole, CoreMsg, RosPosition, DumpCompleteMsg } from '../../types/rosmsg';
import {
  RecoveryFileListResponse,
  extractRogoJobIDs,
  getRecoveryFile,
  getRecoveryList,
} from '../../recoveryServerUtils';

import S3 from '../../aws';
import { Feature, FeatureCollection, MultiPolygon, Point, Polygon } from '@turf/helpers';
import { PDFViewer } from '../utils';
import { DialogTitle, Modal } from '@material-ui/core';
import { _MS_PER_MIN, COLOR, MINIMUM_CORES, MM_PER_INCH, MS_PER_HOUR } from '../../constants';
import {
  setSampleSelected,
  DisableBarcodeEnforcement,
  MissionEventStore,
  BarcodeSelectionModeStore,
  AddMissionEvent,
} from '../../sampleSelectionHelpers';
import { BarcodeProcessorStateStore, checkGapError, setSampleBarcodes } from '../../barcodes';
import { inProcessSimulator } from '../../simulatorTools';
import { Attachment, Sample, SampleBox, SoilDumpEvent, Waypoint } from '../../db';
import BoxHistoryView from '../BoxHistoryView';
import { ChangeReasonPopupResult, ShowChangeTypeSelectorPopup } from '../../changeReasonHelpers';
import ChangeTypeReasonSelector from '../mission/sampling/SkipSampleReasonSelector';
import * as Sentry from '@sentry/react';
import { Jobs } from '@rogoag/airtable';
import { isBarcodeValidBag } from '../../barcode_rules';
import { Coordinate } from 'ol/coordinate';
import { postCustomerNotificiation as postCustomerNotification } from '../../sns_utilities';
import { PRINTER_CONFIG, PRINTER_SIZE_4_6, getPrintURLFromConfig } from '../robot/configs/PrinterConfig';
import BoxExitInterview from '../mission/sampling/BoxExitInterview';
import { generateBoxCheckin } from '../../pdfcheckin';
import SamplingHelperView from '../mission/SamplingHelperView';
import { proxyGetFiles, proxyPublishHelperData } from '../../proxyServerUtils';
import { dispatchSamplesUpdated } from '../../mapEvents';
import {
  CoreCountEnforcementEnabled,
  SimulatorEnabledStorage,
  MapNearbyAutoZoomEnabled,
  RobotArmOffsetX,
  RobotArmOffsetY,
  MobileHelperEnabled,
  BigFieldMode,
} from '../../db/local_storage';
import SamplingEntranceInterview from '../mission/sampling/SamplingEntranceInterview';
import CoreCompletion from '../../services/CoreCompletion';
import { ManualMeasurementDialogParameters } from '../../services/ManualMeasurementsChecker';
import ManualMeasurementDialog from './ManualMeasurementDialog';
import { saveRecoveryZip } from '../../services/RecoveryZipService';

import {
  calculateOrigin,
  calculateSampleUpdates,
  canHandlePositionUpdate,
} from '../../services/TargetCoordinateService';
import { closeBox } from '../../redux/features/boxes/boxesSlice';

interface SamplingTaskProps {
  tab: string;
  task: TaskName;
  missionActive: string;
  connectionStatus: RobotConnectionStatus;
  devMode: boolean;
  showRecoveryPopup: () => void;
  elevatePermissions: () => void;
  accessLevel: SettingsAccessLevel;
  onLoading: OnLoading;
  closeBox: typeof closeBox;
}

interface SamplingTaskState {
  autoSync: boolean;
  controlRole: RobotControlRole;
  currentMissionChecksum?: string;
  depthLocked: boolean;
  drillDepthDisplay?: number;
  drillDepthRaw?: number;
  drillDepthOffset?: number;
  filledSamples?: number;
  hmiVisible: boolean;
  lastSyncTimestamp?: string;
  loadingSchedule: boolean;
  missionDepth?: number;
  navWarningDirections: string;
  navigationPopupVisible: boolean;
  growerNotificationPromptVisible: boolean;
  navigationJobInstanceId: number;
  operatorNotes: string;
  operatorNotesDialogOpen: boolean;
  outOfSync: boolean;
  robotMission?: RosMissionMsg;
  scannerActive: boolean;
  scheduleFeatures: ScheduleFeature[];
  scheduleItems: ScheduleItem[];
  sampledItems: AirtableRecord<Jobs>[];
  offlineItems: AirtableRecord<Jobs>[];
  syncInProgress: boolean;
  totalSamples?: number;
  trackingOverride: boolean;

  presentSample?: Sample;
  closeSample?: Sample;
  confirmFullscreenButton: boolean;

  robotNavControlState: RobotNavControl;
  soilInBucket: boolean;
  robotArmType: RobotArmType;
  // TODO these should be a single object
  disableBarcodeTracking: boolean;
  robotState: RobotState;
  reasonSelectorOptions?: ShowChangeTypeSelectorPopup;

  manualMeasurementDialogOpen: boolean;
  manualMeasurementDialogParameters: ManualMeasurementDialogParameters | null;

  activeBox: SampleBox | undefined;
  boxesNeedReprint: {
    boxId: string;
    instanceId: number;
    boxMissions: {
      fieldName: string;
      instanceId: number;
      numSamples: number;
    }[];
  }[];
  boxToClose?: SampleBox;
  printerConfig: PRINTER_CONFIG;
  printURL: string;
  boxPdf?: {
    dataUrl: string;
    downloadName: string;
  };
  allBoxes: SampleBox[];
  boxInstanceId?: number;
  boxMissions: {
    fieldName: string;
    instanceId: number;
    numSamples: number;
  }[];
  // TODO not totally happy this this implementation... still see behavior related
  // to reprints that doesn't make sense
  boxPdfPreviewVisible: boolean;
  boxCloseTitle: string;
  currentMissionPhotos: string[];
  missionView: MissionView;
  entranceInterviewDialogOpen: boolean;
  inBounds: boolean;
  currentWaypoint: Waypoint | undefined;

  targetCoordinate: Coordinate | undefined;
  coringAllowedState: CoringAllowedState;
}

const topicsToSave = [
  'ROSMSG/config/big_motor',
  'ROSMSG/config/br_deadband',
  'ROSMSG/config/br_max_pos',
  'ROSMSG/config/br_min_pos',
  'ROSMSG/config/brake_safety_check',
  'ROSMSG/config/carriage_down_speed',
  'ROSMSG/config/carriage_up_speed',
  'ROSMSG/config/cdn_max',
  'ROSMSG/config/cdn_min',
  'ROSMSG/config/core_timeout',
  'ROSMSG/config/cup_max',
  'ROSMSG/config/cup_min',
  'ROSMSG/config/dcc_max',
  'ROSMSG/config/dcc_min',
  'ROSMSG/config/dcw_max',
  'ROSMSG/config/dcw_min',
  'ROSMSG/config/depth',
  'ROSMSG/config/depth_arrival_target',
  'ROSMSG/config/depth_offset',
  'ROSMSG/config/dwell_at_top_time',
  'ROSMSG/config/dwell_time',
  'ROSMSG/config/implement_selected',
  'ROSMSG/config/max_speed',
  'ROSMSG/config/override_depth',
  'ROSMSG/config/speed_safety_check',
  'ROSMSG/config/th_deadband',
  'ROSMSG/config/th_max_pos',
  'ROSMSG/config/th_min_pos',
  'ROSMSG/config/tip_to_ground',
];

const SAMPLE_SEND_TIMEOUT_MS = 1000;

class SamplingTask extends PureComponent<SamplingTaskProps, SamplingTaskState> {
  DROPOFF_VIEW_NAME = 'App View - Scheduled Dropoffs';
  RUN_VIEW_NAME = 'App View - Run Missions';
  COMPLETED_VIEW_NAME = 'App View - Sampled Missions';
  MAX_SAMPLES_PER_AUTOSAVE = 5;

  depthLockInitialized: boolean;
  drillDepthInitialized: boolean;
  setStateAsync: SetStateAsync;
  lastCompletedSampleSave: number;
  robotNetwork: RobotLocalNetwork;
  emptyEventBusCallback = () => {};

  sampleSelectionTimeout: NodeJS.Timeout;
  entranceInterviewDialogTimer: NodeJS.Timeout | undefined = undefined;
  closeTimeout: NodeJS.Timeout;
  position: RosPosition;

  currentOrLastSampleSender: NodeJS.Timeout;

  constructor(props: SamplingTaskProps) {
    super(props);

    this.state = {
      autoSync: JSON.parse(localStorage.getItem('autoSync') || (isMobile ? 'false' : 'true')) as boolean,
      controlRole: 'unknown',
      currentMissionChecksum: undefined,
      depthLocked: false,
      drillDepthDisplay: undefined,
      drillDepthRaw: undefined,
      drillDepthOffset: undefined,
      filledSamples: undefined,
      hmiVisible: JSON.parse(localStorage.getItem('hmiVisible') || 'false') as boolean,
      lastSyncTimestamp: undefined,
      loadingSchedule: false,
      missionDepth: undefined,
      navWarningDirections: '',
      navigationPopupVisible: false,
      growerNotificationPromptVisible: false,
      navigationJobInstanceId: 0,
      operatorNotes: '',
      operatorNotesDialogOpen: !JSON.parse(localStorage.getItem('notesClosed') || 'false'),
      outOfSync: false,
      robotMission: undefined,
      scannerActive: JSON.parse(localStorage.getItem('scannerActive') || 'false') as boolean,
      scheduleFeatures: [],
      scheduleItems: [],
      sampledItems: [],
      offlineItems: [],
      syncInProgress: false,
      totalSamples: undefined,
      trackingOverride: false,
      presentSample: undefined,
      closeSample: undefined,
      confirmFullscreenButton: false,
      robotNavControlState: 'Manual',
      soilInBucket: false,
      robotArmType: RobotArmType.NormalArm,
      disableBarcodeTracking: DisableBarcodeEnforcement.get(),
      robotState: RobotState['Unknown'],
      reasonSelectorOptions: undefined,
      manualMeasurementDialogOpen: false,
      manualMeasurementDialogParameters: null,
      boxToClose: undefined,
      boxPdf: undefined,
      activeBox: undefined, // active box unique id
      boxInstanceId: undefined, // active box instance id
      boxMissions: [], // { name: '', instanceId: ... }
      boxesNeedReprint: [], // { boxId: ..., boxInstanceId: ..., boxMissions: ... }
      printerConfig: PRINTER_SIZE_4_6,
      printURL: getPrintURLFromConfig(PRINTER_SIZE_4_6),
      allBoxes: [],
      boxPdfPreviewVisible: false,
      boxCloseTitle: '',
      currentMissionPhotos: [],
      missionView: (localStorage.getItem('missionView') as MissionView) || 'standard',
      entranceInterviewDialogOpen: false,
      inBounds: false,
      currentWaypoint: undefined,
      targetCoordinate: undefined,
      coringAllowedState: 'Good',
    };

    this.syncMission = this.syncMission.bind(this);
    this.checkOutOfSync = this.checkOutOfSync.bind(this);
    this.updateRobotMission = this.updateRobotMission.bind(this);
    this.setWaypoint = this.setWaypoint.bind(this);
    this.setZoneWaypoint = this.setZoneWaypoint.bind(this);
    this.updateAutoSync = this.updateAutoSync.bind(this);
    this.loadMissionSchedule = this.loadMissionSchedule.bind(this);
    this.setStateAsync = setStateAsync.bind(this);
    this.toggleScanner = this.toggleScanner.bind(this);
    this.showNavMessage = this.showNavMessage.bind(this);
    this.loadSchedule = this.loadSchedule.bind(this);
    this.getSampleTotal = this.getSampleTotal.bind(this);
    this.openManualMeasurementDialog = this.openManualMeasurementDialog.bind(this);
    this.loadMissionData = this.loadMissionData.bind(this);
    this.updateCompletedSync = this.updateCompletedSync.bind(this);
    this.closeNotesDialog = this.closeNotesDialog.bind(this);
    this.toggleNotesDialog = this.toggleNotesDialog.bind(this);
    this.toggleHMIVisible = this.toggleHMIVisible.bind(this);
    this.checkForOperatorNotes = this.checkForOperatorNotes.bind(this);
    this.updateDepthLock = this.updateDepthLock.bind(this);
    this.updateDepthSetting = this.updateDepthSetting.bind(this);
    this.setDepth = this.setDepth.bind(this);
    this.checkDepthSync = this.checkDepthSync.bind(this);
    this.initializeSamplingParameters = this.initializeSamplingParameters.bind(this);
    this.toggleTrackingOverride = this.toggleTrackingOverride.bind(this);
    this.resetRobotState = this.resetRobotState.bind(this);
    this.updateRobotControlRole = this.updateRobotControlRole.bind(this);
    this.waypointResetAlert = this.waypointResetAlert.bind(this);
    this.waypointPublish = this.waypointPublish.bind(this);
    this.doSampleCountLogic = this.doSampleCountLogic.bind(this);
    this.resubmitAll = this.resubmitAll.bind(this);
    this.updateAutoManualState = this.updateAutoManualState.bind(this);
    this.soilInBucketChanged = this.soilInBucketChanged.bind(this);
    this.robotArmTypeMessageReceived = this.robotArmTypeMessageReceived.bind(this);
    this.isInBulkDensityMode = this.isInBulkDensityMode.bind(this);
    this.finishBoxExitInterview = this.finishBoxExitInterview.bind(this);
    this.closeBox = this.closeBox.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
    this.handleCloseBox = this.handleCloseBox.bind(this);
    this.showBoxCheckin = this.showBoxCheckin.bind(this);
    this.handlePrinterSelect = this.handlePrinterSelect.bind(this);
    this.updateBoxData = this.updateBoxData.bind(this);
    this.updateBoxNeedsReprint = this.updateBoxNeedsReprint.bind(this);
    this.sendCurrentOrLastMissionSample = this.sendCurrentOrLastMissionSample.bind(this);
    this.showSkipReasonSelectorPopup = this.showSkipReasonSelectorPopup.bind(this);
    this.coreComplete = this.coreComplete.bind(this);
    this.dumpComplete = this.dumpComplete.bind(this);
    this.dumpCompleteMsgReceived = this.dumpCompleteMsgReceived.bind(this);
    this.handleRobotState = this.handleRobotState.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.updateDepthOffsetSetting = this.updateDepthOffsetSetting.bind(this);
    this.clearEntranceInterviewTimer = this.clearEntranceInterviewTimer.bind(this);
    this.lastCompletedSampleSave = 0;
  }

  async componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.loadMissionData);
    EventBus.on(MISSION_EVENTS.UPDATED, this.initializeSamplingParameters);
    EventBus.on(MISSION_EVENTS.UPDATED, this.checkDepthSync);
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.loadMissionData);
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.checkForOperatorNotes);
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.initializeSamplingParameters);
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.checkDepthSync);
    EventBus.on(MISSION_EVENTS.CREATED_OR_DELETED, this.clearEntranceInterviewTimer);
    EventBus.on(BOX_EVENTS.ACTIVE_UPDATED, this.getSampleTotal);
    EventBus.on('SAMPLING_TASK:SHOW_CHANGE_REASON_SELECTOR', this.showSkipReasonSelectorPopup);

    EventBus.on('ROSRESET', this.resetRobotState);
    EventBus.on('ROBOT_ROLE_UPDATED', this.updateRobotControlRole);

    onRobotMessage('arm/core_complete', this.coreComplete);
    onRobotMessage('arm/dump_complete', this.dumpCompleteMsgReceived);
    onRobotMessage('position', this.updatePosition);
    onRobotMessage('status/physical/soilinbucket', this.soilInBucketChanged, true);
    onRobotMessage('status/logical/arm_type', this.robotArmTypeMessageReceived, true);
    onRobotMessage(`hmi/butt_4/color`, this.updateAutoManualState, true);
    onRobotMessage('robot_state', this.handleRobotState, true);

    onRobotMessage('config/offset_x', this.updateOffsetX, true);
    onRobotMessage('config/offset_y', this.updateOffsetY, true);

    // TODO adding box events relatively early to try to get the box data to render the UI
    // properly
    EventBus.on(BOX_EVENTS.ACTIVE_UPDATED, this.updateBoxData);
    EventBus.on(BOX_EVENTS.SAMPLES_UPDATED, this.updateBoxData);
    EventBus.on(BOX_EVENTS.MOVED_SAMPLE, this.updateBoxNeedsReprint);
    EventBus.on(MISSION_EVENTS.UPDATED, this.updateBoxData);

    // EventBus.on('ROSMSG/status/logical/bulking', this.logicalStartBulking);
    await this.updateBoxData();
    this.updateBoxNeedsReprint();

    await this.loadSchedule();
    this.getSampleTotal();
    this.initRobotNetwork();

    this.depthLockInitialized = false;
    this.drillDepthInitialized = false;

    BarcodeProcessorStateStore.set({ barcodeScanningPaused: false, boxScanningPaused: false });

    const mission = getCurrentMission();

    if (mission) {
      this.initializeSamplingParameters();

      const currentUser = getCurrentSession()?.getUser();
      const userRequiredToDoEntranceInterviews = currentUser && !currentUser.customer_boundary_recording;
      if (!mission.getJob()?.entrance_interview_complete && userRequiredToDoEntranceInterviews) {
        this.setState({ entranceInterviewDialogOpen: true });
      }

      // TODO we are doing this for now to ensure that the data gets "cached"
      // in the event bus and we can access it. Unfortunatley, this means
      // the robot panel is also subscribing the duplicated topics, so ideally
      // we could subscribe at this level anad pass it to the robot panel
      // but this works for now
      for (const topic of topicsToSave) {
        EventBus.on(topic, this.emptyEventBusCallback);
      }
    }

    EventBus.on('ROSMSG/config/override_depth', this.updateDepthLock, true);
    EventBus.on('ROSMSG/config/depth', this.updateDepthSetting, true);
    EventBus.on('ROSMSG/config/depth_offset', this.updateDepthOffsetSetting, true);

    await this.checkForOperatorNotes();

    this.setState({
      hmiVisible: JSON.parse(localStorage.getItem('hmiVisible') || 'false'),
      missionView: (localStorage.getItem('missionView') as MissionView) || 'standard',
    });

    if (SimulatorEnabledStorage.get()) {
      inProcessSimulator(this);
    }

    if (MobileHelperEnabled.get()) {
      // not awaiting this because it will then handle its own callbacks
      this.sendCurrentOrLastMissionSample();
    }
  }

  componentWillUnmount() {
    this.deinitRobotNetwork();

    EventBus.remove(MISSION_EVENTS.UPDATED, this.loadMissionData);
    EventBus.remove(BOX_EVENTS.ACTIVE_UPDATED, this.getSampleTotal);
    EventBus.remove(MISSION_EVENTS.UPDATED, this.initializeSamplingParameters);
    EventBus.remove(MISSION_EVENTS.UPDATED, this.checkDepthSync);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.loadMissionData);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.checkForOperatorNotes);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.initializeSamplingParameters);
    EventBus.remove(MISSION_EVENTS.CREATED_OR_DELETED, this.checkDepthSync);
    EventBus.remove(`ROSMSG/mission/sync`, this.updateRobotMission);
    EventBus.remove(`ROSMSG/mission/cud_sync`, this.updateRobotMission);
    EventBus.remove('ROSMSG/mission/sync_done', this.updateCompletedSync);
    EventBus.remove('ROSMSG/config/override_depth', this.updateDepthLock);
    EventBus.remove('ROSMSG/config/depth', this.updateDepthSetting);
    EventBus.remove('ROSMSG/config/depth_offset', this.updateDepthOffsetSetting);
    EventBus.remove('ROSMSG/mission/waypoint_reset', this.waypointResetAlert);
    EventBus.remove('ROSMSG/mission/waypoint', this.waypointPublish);
    EventBus.remove('ROSRESET', this.resetRobotState);
    EventBus.remove('ROBOT_ROLE_UPDATED', this.updateRobotControlRole);
    EventBus.remove('ROSMSG/arm/core_complete', this.coreComplete);
    EventBus.remove('ROSMSG/arm/dump_complete', this.dumpCompleteMsgReceived);
    removeRobotMessageCallback('position', this.updatePosition);
    EventBus.remove('ROSMSG/hmi/butt_4/color', this.updateAutoManualState);
    EventBus.remove('ROSMSG/status/physical/soilinbucket', this.soilInBucketChanged);
    EventBus.remove('status/logical/arm_type', this.robotArmTypeMessageReceived);
    EventBus.remove('SAMPLING_TASK:SHOW_CHANGE_REASON_SELECTOR', this.showSkipReasonSelectorPopup);
    // EventBus.remove('ROSMSG/status/logical/bulking', this.logicalStartBulking);

    EventBus.remove('ROSMSG/robot_state', this.handleRobotState);

    removeRobotMessageCallback('config/offset_x', this.updateOffsetX);
    removeRobotMessageCallback('config/offset_y', this.updateOffsetY);

    for (const topic of topicsToSave) {
      EventBus.remove(topic, this.emptyEventBusCallback);
    }

    EventBus.remove(BOX_EVENTS.ACTIVE_UPDATED, this.updateBoxData);
    EventBus.remove(BOX_EVENTS.SAMPLES_UPDATED, this.updateBoxData);
    EventBus.remove(BOX_EVENTS.MOVED_SAMPLE, this.updateBoxNeedsReprint);
    EventBus.remove(MISSION_EVENTS.UPDATED, this.updateBoxData);

    clearTimeout(this.currentOrLastSampleSender);

    this.clearEntranceInterviewTimer();
  }

  clearEntranceInterviewTimer = () => {
    clearTimeout(this.entranceInterviewDialogTimer);
    this.entranceInterviewDialogTimer = undefined;
  };

  entranceInterviewStateLogic = async (prevState: SamplingTaskState) => {
    const session = getCurrentSession();
    if (session?.getUser()?.customer_boundary_recording) {
      return;
    }

    const mission = getCurrentMission();
    const entranceInterviewClosed = !this.state.entranceInterviewDialogOpen;
    const entranceInterviewJustClosed =
      entranceInterviewClosed && this.state.entranceInterviewDialogOpen !== prevState.entranceInterviewDialogOpen;

    const entranceInterviewComplete = mission?.getJob()?.entrance_interview_complete;
    let timerAlreadySet = !!this.entranceInterviewDialogTimer;
    if (entranceInterviewJustClosed && !entranceInterviewComplete && !timerAlreadySet) {
      this.entranceInterviewDialogTimer = setTimeout(() => {
        // This intentionally uses the getCurrentMission() variable inside of the callback instead
        // of `mission` above because otherwise the mission might have been closed and we wouldn't know
        // that unless we check here
        // additionally, this could also come up if the user has opened a new mission
        // either way, this logic might need some work
        if (getCurrentMission()) {
          this.setState({ entranceInterviewDialogOpen: true });
        }
        this.clearEntranceInterviewTimer();
      }, 5 * _MS_PER_MIN);
      timerAlreadySet = true;
    }

    const robotConnected = this.props.connectionStatus === 'connected';
    if (robotConnected && !timerAlreadySet) {
      if (mission) {
        const entranceInterviewComplete = mission.getJob()?.entrance_interview_complete;
        if (!entranceInterviewComplete && this.props.connectionStatus === 'connected') {
          this.setState({ entranceInterviewDialogOpen: true });
        }
      }
    }
  };

  async componentDidUpdate(prevProps: SamplingTaskProps, prevState: SamplingTaskState) {
    localStorage.setItem('missionView', this.state.missionView);
    //console.log(`SamplingTask:componentDidUpdate`, this.state.sampleToNavigateTo, this.state.robotNavControlState);
    if (this.props.connectionStatus === 'connected') {
      if (prevProps.connectionStatus !== this.props.connectionStatus) {
        EventBus.on('ROSMSG/mission/sync_done', this.updateCompletedSync, true);
        EventBus.on(`ROSMSG/mission/cud_sync`, this.updateRobotMission, true);
        EventBus.on(`ROSMSG/mission/sync`, this.updateRobotMission, true);
        EventBus.on('ROSMSG/mission/waypoint_reset', this.waypointResetAlert);
        EventBus.on('ROSMSG/mission/waypoint', this.waypointPublish);
        await this.checkOutOfSync();
      }
    }

    await this.entranceInterviewStateLogic(prevState);

    if (this.props.connectionStatus === 'disconnected' && prevProps.connectionStatus === 'connected') {
      EventBus.remove(`ROSMSG/mission/sync`, this.updateRobotMission);
      EventBus.remove(`ROSMSG/mission/cud_sync`, this.updateRobotMission);
      EventBus.remove('ROSMSG/mission/sync_done', this.updateCompletedSync);
      EventBus.remove('ROSMSG/mission/waypoint_reset', this.waypointResetAlert);
      EventBus.remove('ROSMSG/mission/waypoint', this.waypointPublish);
      this.clearMissionInfo();
    }

    if (this.state.filledSamples !== prevState.filledSamples) {
      await this.doSampleCountLogic();
    }

    // detect falling edge of soil in bucket
    // TODO disabled as of 2024-09-04 in favor of dump_complete message
    // if (!this.state.soilInBucket && prevState.soilInBucket) {
    //   await this.dumpComplete(Date.now());
    // }
  }

  updateOffsetX = (robotMessage: RobotNumberMessage) => {
    RobotArmOffsetX.set(robotMessage.msg.data / 1000);
  };

  updateOffsetY = (robotMessage: RobotNumberMessage) => {
    RobotArmOffsetY.set(robotMessage.msg.data / 1000);
  };

  async sendCurrentOrLastMissionSample() {
    try {
      const session = getCurrentSession();
      const mission = getCurrentMission();

      // don't let helper send mission ID
      // TODO not sure if this is problematic or not...
      // if the operator is sampling on their phone, this
      // wouldn't get called, but if they're sampling on
      // their phone, then this would be irrelevant
      // The better design would be
      if (isMobile) {
        return;
      }

      if (this.state.controlRole === 'control') {
        let jobId: string | undefined;
        let sampleId: string | undefined;
        if (mission) {
          jobId = mission.job_id;
          const currentSample = session?.getSample();
          if (currentSample) {
            // get sample
            sampleId = currentSample.sample_id;
          } else if (this.state.presentSample) {
            sampleId = this.state.presentSample.sample_id;
          } else {
            sampleId = mission.getLastScannedSampleId();
          }
        }

        // console.log(`${new Date()} Sending current_job_sample to ${session?.robot_hostname} with sample_id: ${sampleId} and job_id: ${jobId}`)
        const helperData = {
          data: {
            sample_id: sampleId || '',
            job_id: jobId || '',
          },
        };
        dispatchRosMsgPeer({
          hostname: session?.robot_hostname || '',
          msg: helperData,
          tag: Math.round(Math.random() * 100000).toString(),
          topic: 'current_job_sample',
          broadcast: 'send_to_others',
        });
        await proxyPublishHelperData(helperData);

        const photos = jobId ? await proxyGetFiles(jobId) : [];
        this.setState({ currentMissionPhotos: photos });
      }
    } catch (err) {
      console.error('Could not send current job sample', err);
    }

    this.currentOrLastSampleSender = setTimeout(async () => {
      await this.sendCurrentOrLastMissionSample();
    }, SAMPLE_SEND_TIMEOUT_MS);
  }

  /**
   * Generates a pdf from the box data and updates state with the data url to display to the user
   * @param {object} box Data class instance of the SampleBox that will be displayed
   */
  async showBoxCheckin(box: SampleBox) {
    try {
      const boxPdf = await generateBoxCheckin(box, this.state.printerConfig);
      this.setState({
        boxPdfPreviewVisible: true,
        boxPdf,
        boxCloseTitle: box.ReprintSession_id ? 'Reprint Box' : 'Close Box',
      });
      if (box.needsUpdated) {
        const currentMission = getCurrentMission();
        const noCurrentMission = !currentMission;
        const currentMissionInBox = currentMission && box.getSampleBoxMissions().includes(currentMission);
        const currentMissionNotInBox = !currentMissionInBox;
        if (noCurrentMission || currentMissionNotInBox) {
          // upload box data to S3
          // TODO maybe need timeout if no internet?
          const uploadSucceeded = await box.upload();
          if (!uploadSucceeded) {
            alertError('Could not upload box data to S3');
          }
        }
      }
    } catch (err) {
      console.error('Could not export PDF', err);
    }
  }

  /**
   * Asks for user confirmation to close/dispose of the box
   * @param {object} box Data class instance of a SampleBox that could be closed
   */
  async handleNoSamples(box: SampleBox) {
    const confirm = await alertConfirm('This box is empty! Do you wish to close it?', this.constructor.name);
    if (confirm) {
      await this.closeBox(box, true);
      await logger.log('HANDLE_NO_SAMPLES', 'user confirmed empty box close');
    } else {
      await logger.log('HANDLE_NO_SAMPLES', 'user denied empty box close');
    }
  }

  /**
   * Handle the close box button press.
   *
   * Case 1: If we have an open box but NO samples, then simply provide a
   *         confirmation popup to just close the empty box
   *
   * Case 2: Else if we have an open box that does have samples, show the
   *         box close dialog
   *
   * Case 3: Else if we DON'T have an open box, but we do have boxes that need
   *         reprinted, then get the the box from the top of the box reprint
   *         array and show the checkin dialog
   */
  async handleCloseBox(box?: SampleBox) {
    let boxToClose = box || SampleBox.getCurrentBox();
    const openBoxes = SampleBox.getOpenBoxes();
    if (openBoxes.length > 1 && !box) {
      const boxUid = await alertFullScreen(
        'Which box?',
        `Please select the box you are closing`,
        openBoxes.map((box) => box.short_uid),
      );
      boxToClose = openBoxes.find((box) => box.short_uid === boxUid);
    }

    // MISSIONCONTROL-MD
    // SamplingTask.handleCloseBox(components/SamplingTask)
    // TypeError: t.getSamples is not a function

    const boxToCloseSamples = boxToClose?.getSamples();
    const boxHasNoSamples = !boxToCloseSamples || !boxToCloseSamples?.length;
    if (boxToClose && boxHasNoSamples) {
      await this.handleNoSamples(boxToClose);
    } else if (boxToClose) {
      await this.showBoxCheckin(boxToClose);
      this.setState({ boxToClose });
    } else if (!boxToClose && this.state.boxesNeedReprint.length) {
      const boxReprintId = this.state.boxesNeedReprint[this.state.boxesNeedReprint.length - 1].instanceId;
      const boxReprint = SampleBox.get(boxReprintId);
      if (!boxReprint) {
        throw new Error(`Could not find box with instance id ${boxReprintId}`);
      }
      await this.showBoxCheckin(boxReprint);
      this.setState({ boxToClose: boxReprint });
    }
  }

  // logicalStartBulking = () => {
  //   logger.log('LOGICAL_START_BULKING', `Logical start bulking ${JSON.stringify(this.position)} ${this.state.presentSample?.sample_id}`);
  // }

  showSkipReasonSelectorPopup(changePopupOptions: ShowChangeTypeSelectorPopup) {
    this.setState({ reasonSelectorOptions: changePopupOptions });
  }

  handleRobotState({ hostname, msg }: { hostname: string; msg: { data: number } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      const robotState = msg.data;
      if (robotState !== this.state.robotState.valueOf()) {
        this.setState({ robotState });
      }
    }
  }

  // this should happen in auto and manual mode
  async soilInBucketChanged({ hostname, msg }: { hostname: string; msg: { data: boolean } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      const soilInBucket = msg.data === true;
      if (soilInBucket !== this.state.soilInBucket) {
        this.setState({ soilInBucket });
      }
    }
  }

  async robotArmTypeMessageReceived(robotMessage: RobotNumberMessage) {
    const session = getCurrentSession();
    if (session?.robot_hostname !== robotMessage.hostname) {
      return;
    }

    const robotArmType = robotMessage.msg.data;
    if (robotArmType !== this.state.robotArmType) {
      this.setState({ robotArmType });
    }
  }

  async updateAutoManualState({ hostname, msg }: { hostname: string; msg: { data: number } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      this.setState({ robotNavControlState: msg.data === COLOR['GREEN'].valueOf() ? 'Auto' : 'Manual' });
    }
  }

  async coreComplete(event: { hostname: string; msg: CoreMsg }) {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    const coreCompletionHandler = new CoreCompletion(
      mission,
      this.position,
      this.state.presentSample,
      this.dumpComplete,
      () => {},
      this.openManualMeasurementDialog,
    );

    await coreCompletionHandler.handle(event);
  }

  async dumpCompleteMsgReceived(event: { hostname: string; msg: DumpCompleteMsg }) {
    await this.dumpComplete();
  }

  async dumpComplete(this: SamplingTask) {
    const mission = getCurrentMission();

    if (!mission) {
      return;
    }

    const now = Date.now();

    const coresInBucket = mission.getSoilCoresInBucket();

    const recentDump = mission.getLastDump();
    const dumpEvent = SoilDumpEvent.createDump({
      Mission_id: mission.instance_id,
      guid: uuidv4(),
      timestamp: now,
      lat: this.position.x,
      lon: this.position.y,
      heading: this.position.z,
      PreviousDump_id: recentDump?.instance_id,
    });

    for (const core of coresInBucket) {
      core.SoilDumpEvent_id = dumpEvent.instance_id;
    }

    const sample = dumpEvent.getSample();

    AddMissionEvent(mission.job_id || '', {
      type: 'dump_complete',
      // TODO should we use the timestamp passed in??
      timestamp: Date.now(),
      // vvv this is a key association... if something is waiting for a dump, we will assume the dump goes to it.
      sample_id: sample?.sample_id || '',
      location: this.position,
      meta: '',
    });

    if (!sample) {
      if (!coresInBucket.length) {
        alertWarn('No cores in bucket');

        return;
      }

      if (coresInBucket[0].test_core_Mission_id) {
        alertInfo(`Dumped ${coresInBucket.length} test cores`);

        return;
      }

      alertWarn("We noticed a soil dump, but don't know what it belonged to");

      return;
    }

    EventBus.dispatch('DUMP_SUCCESS');

    const allCores = sample.getSoilCores();
    const pulledCores = allCores.filter((core) => core.pulled);

    const coresRequired = CoreCountEnforcementEnabled.get()
      ? allCores.length
      : Math.min(MINIMUM_CORES, allCores.length);

    if (pulledCores.length < coresRequired) {
      alertWarn(
        `Sample ${sample.sample_id} does not have enough cores yet, need ${coresRequired - pulledCores.length} more`,
      );

      return;
    }

    if (BarcodeSelectionModeStore.get() !== 'Automatic') {
      alertWarn('Dump recieved but barcode advancement mode is not set to automatic');

      return;
    }

    alertInfo(`Sample ${sample.sample_id} dumped and ready for scanning`);

    // TODO this is where we could command a dump
    await setSampleSelected({ timestamp: new Date(), sampleInstanceId: sample.instance_id! });
  }

  updateInBounds(coordinate: Coordinate) {
    const mission = getCurrentMission();

    // Check if in bounds
    const boundary = mission?.getBoundary();
    const inBounds = boundary?.contains(coordinate[0], coordinate[1]) || false;
    if (inBounds !== this.state.inBounds) {
      this.setState({ inBounds });
    }
  }

  isInBulkDensityMode(): boolean {
    return this.state.robotArmType === RobotArmType.BulkArm;
  }

  async updatePosition({ hostname, msg }: { hostname: string; msg: RosPosition }) {
    if (!canHandlePositionUpdate(hostname)) {
      return;
    }

    // We are explicitly NOT setting state here for this position value, otherwise we would cause too many re-renders to happen
    // in fact, the "close core" logic should likely happen in a lower level component to avoid the possibility of this logic
    // causing full re-renders when not necessary
    this.position = msg;

    const origin = calculateOrigin(msg);

    this.updateInBounds(origin);

    const sampleUpdates = calculateSampleUpdates(
      origin,
      this.state.currentWaypoint,
      this.state.robotNavControlState,
      this.state.soilInBucket,
      this.state.closeSample,
      this.state.presentSample,
      this.state.targetCoordinate,
      this.isInBulkDensityMode(),
      this.state.coringAllowedState,
      CoreCountEnforcementEnabled.get(),
    );

    if (sampleUpdates) {
      const { presentSample, closeSample, targetCoordinate, coringAllowedState } = sampleUpdates;
      this.setState({ presentSample, closeSample, targetCoordinate, coringAllowedState });
    }
  }

  clearMissionInfo() {
    this.setState({
      robotMission: undefined,
      lastSyncTimestamp: undefined,
    });
  }

  async updateRobotControlRole(role: RobotControlRole) {
    this.setState({
      controlRole: role,
    });
  }

  async waypointResetAlert({ hostname, msg }: { hostname: string; msg: any }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      const robotState = msg.data;
      if (robotState !== 0) {
        alertInfo('Target waypoint was reset! Ensure it is correct before hitting go!');
      }
    }
  }

  async waypointPublish({ hostname, msg }: { hostname: string; msg: { data: number } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname !== hostname) {
      return;
    }
    const currentWaypointIndex = msg.data;
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }
    const waypoint = mission.getWaypoints().find((waypoint) => waypoint.waypoint_number === currentWaypointIndex);
    if (!waypoint) {
      return;
    }

    if (waypoint.waypoint_number !== this.state.currentWaypoint?.waypoint_number) {
      this.setState({ currentWaypoint: waypoint });
    }

    // const sample = waypoint.getCorePoint()?.getSoilCore()?.getSample();
    // if (!sample) {
    //   return;
    // }

    // const coordsFormatted = convertProjection4329([waypoint.lon, waypoint.lat]);
    // if (JSON.stringify(coordsFormatted) !== JSON.stringify(this.state.currentWaypoint)) {
    //   this.setState({ currentWaypoint: coordsFormatted });
    // }
  }

  // TODO should we really be just resetting these values here?
  resetRobotState() {
    this.setState({ drillDepthDisplay: undefined, drillDepthRaw: undefined, depthLocked: false });
  }

  initRobotNetwork() {
    this.robotNetwork = new RobotLocalNetwork();
    this.robotNetwork.startPinging();
  }

  deinitRobotNetwork() {
    if (this.robotNetwork) {
      this.robotNetwork.stopPinging();
    }
  }

  async resubmitAll() {
    // get offline recovery files that are relevant in our list

    for (const offlineJob of this.state.offlineItems) {
      // get offline recovery file from recovery server based on job id
      const recoveryFile = await getRecoveryFile({ search: `OFFLINE_*${offlineJob.id}`, backIndex: 0 });
      if (recoveryFile) {
        // unzip the recovery file
        const { filename, content } = await textToZip(
          await getJobKmlFromZip({ file: recoveryFile, jobID: offlineJob.id }),
        );

        // upload to S3
        const checksum = await S3.upload(filename, content, content.type, { bucket: 'missionuploads', acl: '' });

        if (!checksum) {
          alertError('Error uploading recovery file to S3');
          return;
        }
      }
    }
  }

  async checkForOperatorNotes() {
    const mission = getCurrentMission();
    if (mission) {
      const job = await Airtable.getRecord('Jobs', mission.job_id || '');
      this.setState({
        operatorNotesDialogOpen: true,
        operatorNotes: (job?.get('Instructions for Field #ops') as string | undefined) || '',
      });
    } else {
      this.setState({
        operatorNotesDialogOpen: false,
        operatorNotes: '',
      });
    }
  }

  closeNotesDialog() {
    this.setState({ operatorNotesDialogOpen: false });
    localStorage.setItem('notesClosed', 'true');
  }

  toggleNotesDialog() {
    localStorage.setItem('notesClosed', this.state.operatorNotesDialogOpen.toString());
    this.setState({ operatorNotesDialogOpen: !this.state.operatorNotesDialogOpen });
  }

  toggleHMIVisible() {
    this.setState({ hmiVisible: !this.state.hmiVisible });
    localStorage.setItem('hmiVisible', (!this.state.hmiVisible).toString());
  }

  async updateCompletedSync({ hostname, msg }: { hostname: string; msg: { data: string } }) {
    const session = getCurrentSession();
    if (session?.robot_hostname === hostname) {
      this.setState({ currentMissionChecksum: msg.data });
    }
    await this.checkOutOfSync(msg.data);
  }

  async loadMissionData(missionData: CreatedOrDeletedParams) {
    // TODO moving samples causes this to reload, which we don't want to do, so we need to find
    // another way that we can "reset" upon a mission load?
    //await setSampleSelected(undefined);
    this.getSampleTotal();
    if (this.props.connectionStatus === 'connected') {
      await this.checkOutOfSync();
    }

    const mission = getCurrentMission();
    if (MissionEventStore.get().missionId !== mission?.job_id) {
      this.filterScheduleFeaturesAfterMissionUpload(missionData);

      MissionEventStore.reset();
      CoreCountEnforcementEnabled.set(mission?.getJob()?.strict_core_enforcement || false);
      MapNearbyAutoZoomEnabled.set(mission?.getJob()?.auto_zoom_near_sample || false);
    }
  }

  private filterScheduleFeaturesAfterMissionUpload(missionData: CreatedOrDeletedParams) {
    if (!missionData) {
      return;
    }

    const { deletedRogoJobId } = missionData;
    if (!deletedRogoJobId) {
      return;
    }

    this.setState((prevState: Readonly<SamplingTaskState>) => ({
      ...prevState,
      scheduleFeatures: prevState.scheduleFeatures.filter((feat) => !isFeatureRelatedToDeletedJob(feat)),
    }));

    function isFeatureRelatedToDeletedJob(feat: ScheduleFeature): unknown {
      return feat.properties && feat.properties['Rogo Job ID'] && feat.properties['Rogo Job ID'] === deletedRogoJobId;
    }
  }

  openManualMeasurementDialog(manualMeasurementDialogParameters: ManualMeasurementDialogParameters) {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    this.setState({
      manualMeasurementDialogOpen: true,
      manualMeasurementDialogParameters: manualMeasurementDialogParameters,
    });
  }

  getSampleTotal() {
    const mission = getCurrentMission();
    if (!mission) {
      this.setState({ totalSamples: undefined, filledSamples: undefined });
      return;
    }

    const samples = mission.getAllSamples(false);
    const total = samples.length;
    const filled = samples.filter(
      (sample) => Boolean(sample.bag_id) && isBarcodeValidBag(sample.bag_id || '', sample.getBarcodeRegex()),
    ).length;

    this.setState({ totalSamples: total, filledSamples: filled });
  }

  async doSampleCountLogic() {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    // Originally, this interval calculation was a bit more advanced and would scale based
    // on the size of the field. However, once the number of robot resets was improved, we decided
    // to less this to just happen every 5 samples
    //const interval = Math.min(Math.ceil(this.state.totalSamples / 40), this.MAX_SAMPLES_PER_AUTOSAVE);

    // SVH 3/8/2023: Making this 1 for now to save a recovery file every sample...
    // this has a risk of generating a LOT of recovery files, so we may need to come
    // up with a better auto-purge solution... but for now with the recovery server,
    // this is a good option
    const interval = BigFieldMode.get() ? 5 : 1;

    // if a small number, just update every sample
    // with this math (ceil(division)),
    // 1-50 samples = download every sample
    // 51-100 samples = download every 2 samples
    // 101-150   = 3
    // 151-200   = 4
    // 201-250   = 5
    // 251-300   = 6
    // 301-350   = 7
    // 351-400   = 8
    // ...

    const { filledSamples, totalSamples } = this.state;

    if (filledSamples === 1) {
      // if first filled sample...
      // TODO LOGGING store robot settings and log to job
      // console.log(`FIRST SAMPLE FILLED!`);
      const data: { [topic: string]: any } = {};
      for (const topic of topicsToSave) {
        const recent = EventBus.recent(topic);
        // console.log(`topic: ${topic}, recent.detail: ${JSON.stringify(recent?.detail)}`);
        data[topic] = recent?.detail?.msg?.data;
      }
      const mission = getCurrentMission();
      if (mission) {
        mission.settings_start = JSON.stringify(data);
      }
    } else if (filledSamples === totalSamples) {
      // TODO LOGGING store robot settings and log to job
      const data: { [topic: string]: any } = {};
      for (const topic of topicsToSave) {
        const recent = EventBus.recent(topic);
        data[topic] = recent?.detail?.msg?.data;
      }
      mission.settings_end = JSON.stringify(data);
    }

    // only auto save if we have a sample AND this is a save interval

    if (filledSamples && filledSamples > 0 && filledSamples % interval === 0) {
      setTimeout(async () => await saveRecoveryZip(mission, { tag: 'auto' }), 10000);
    }
  }

  /**
   * Toggles the sample scanner
   */
  toggleScanner() {
    localStorage.setItem('scannerActive', (!this.state.scannerActive).toString());
    this.setState({ scannerActive: !this.state.scannerActive });
  }

  async updateAutoSync(autoSync: boolean) {
    localStorage.setItem('autoSync', autoSync.toString());
    await this.setStateAsync({ autoSync });
    await this.checkOutOfSync();
  }

  checkDepthSync() {
    if (!this.drillDepthInitialized) {
      logger.logPeriodic('DEPTH_SYNC', 'Drill depth not initialized');
      return;
    }

    if (!this.depthLockInitialized) {
      logger.logPeriodic('DEPTH_SYNC', 'Depth lock not initialized');
      return;
    }

    if (this.state.controlRole !== 'control') {
      logger.logPeriodic('DEPTH_SYNC', 'Not in control');
      return;
    }

    const mission = getCurrentMission();
    if (!mission) {
      // we won't log this situation becauase it's quite common and not a concern
      logger.logPeriodic('DEPTH_SYNC', 'No mission', MS_PER_HOUR);
      return;
    }

    const missionDepth = mission.getPrimarySpec()?.end_depth || 0;
    if (!missionDepth) {
      logger.logPeriodic('DEPTH_SYNC', 'No mission depth specified or set to 0');
      alertWarn('No mission depth specified or set to 0');
      return;
    }

    if (this.state.depthLocked) {
      logger.logPeriodic('DEPTH_SYNC', 'Depth is locked');
      return;
    }

    let conversion = Math.round(Math.abs(missionDepth) * 25.4);
    if (missionDepth < 0) {
      conversion = conversion * -1;
    }

    if (conversion === this.state.drillDepthRaw) {
      logger.logPeriodic('DEPTH_SYNC', 'Drill depth from mission matches current depth');
      // alertSuccess('Drill depth from mission matches current depth');
      return;
    }

    this.setDepth(missionDepth);
  }

  resetRobotSettings() {
    const session = getCurrentSession();
    if (!session) return;

    // VISIBILIY RESET. If we are not locked, lock for this mission
    if (this.props.accessLevel !== 'Locked') {
      // this actually LOWERS permission if we're already not locked
      this.props.elevatePermissions();
    }

    // RESET AUTO SYNC

    const offset = 0;
    dispatchRosMsgPub({
      hostname: session?.robot_hostname,
      msg: { data: offset },
      topic: 'config/set_depth_offset',
      tag: this.constructor.name,
    });

    // TODO disabling for now, will revisit at a future date.
    // // these are daily?
    // const check = false;
    // dispatchRosMsgPub({
    //   topic: 'config/set_brake_safety_check',
    //   hostname: session?.robot_hostname,
    //   msg: { data: check },
    //   tag: this.constructor.name,
    // });

    // dispatchRosMsgPub({
    //   topic: 'config/set_speed_safety_check',
    //   hostname: session?.robot_hostname,
    //   msg: { data: check },
    //   tag: this.constructor.name,
    // });

    // const override = false;
    // dispatchRosMsgPub({
    //   hostname: session?.robot_hostname,
    //   msg: { data: override },
    //   topic: 'config/set_override_depth',
    //   tag: this.constructor.name
    // });
  }

  initializeSamplingParameters() {
    const mission = getCurrentMission();
    if (mission) {
      const spec = mission.getPrimarySpec();
      if (spec) {
        this.setState({
          missionDepth: spec.end_depth || 0,
        });
      }
    } else {
      this.setState({ missionDepth: undefined });
    }
  }

  updateDepthLock(event: any) {
    const session = getCurrentSession();
    const { hostname, msg } = event;
    if (hostname === session?.robot_hostname) {
      this.setState({
        depthLocked: msg.data,
      });

      this.depthLockInitialized = true;
      // logger.log('DEPTH_SYNC', 'Update Depth Lock');
      this.checkDepthSync();
    }
  }

  updateDepthSetting(event: { hostname: string; msg: { data: number } }) {
    const session = getCurrentSession();
    const { hostname, msg } = event;
    if (hostname === session?.robot_hostname) {
      const drillDepth = roundToIncrement(Math.abs(msg.data / MM_PER_INCH), 0.1);
      this.setState({
        drillDepthDisplay: drillDepth,
        drillDepthRaw: msg.data,
      });
      this.drillDepthInitialized = true;
      // logger.log('DEPTH_SYNC', 'Update Depth Setting');
      this.checkDepthSync();
    }
  }

  updateDepthOffsetSetting(event: { hostname: string; msg: { data: number } }) {
    const session = getCurrentSession();
    const { hostname, msg } = event;
    if (hostname === session?.robot_hostname) {
      const offset = msg.data;
      const drillDepthOffset = roundToIncrement(Math.abs(offset) / MM_PER_INCH, 0.125) * -Math.sign(offset);
      this.setState({
        drillDepthOffset,
      });
      // logger.log('DEPTH_SYNC', 'Update Depth Offset Setting');
      this.checkDepthSync();
    }
  }

  setDepth(depth: number) {
    const session = getCurrentSession();
    let conversion = Math.round(Math.abs(depth) * 25.4);
    if (depth < 0) {
      conversion = conversion * -1;
    }
    dispatchRosMsgPub({
      hostname: session?.robot_hostname || '',
      msg: { data: conversion },
      topic: 'config/set_depth',
      tag: this.constructor.name,
      expectationParams: {
        expectedTopic: 'config/depth',
        messages: {
          success: `Successfully set depth from ${this.state.drillDepthDisplay} to ${depth}!`,
          error: `Failed to set depth from ${this.state.drillDepthDisplay} to ${depth}!`,
        },
        doneCallback: (received: boolean, dataCorrect: boolean) => {
          if (!received || !dataCorrect) {
            errorTone();
            alertInfo('Set depth in HMI!');
          }
        },
      },
    });
  }

  syncMission() {
    const mission = getCurrentMission();
    const session = getCurrentSession();
    if (!mission) {
      this.setState({ syncInProgress: false });
      alertError('No mission loaded');

      return;
    }

    if (!this.state.outOfSync || this.state.syncInProgress) {
      return;
    }

    // package the mission
    const missionRos = mission.to_ros_msg();
    if (!missionRos) {
      this.setState({ syncInProgress: false });
      alertError('Failed to package mission');

      return;
    }

    // send mission
    const topic = 'field_param' in missionRos ? 'mission/cud_sync' : 'mission/sync';
    dispatchRosMsgPub({
      hostname: session?.robot_hostname || '',
      msg: missionRos,
      topic: topic,
      compress: true,
      tag: this.constructor.name,
      expectationParams: {
        doneCallback: async (received: boolean, dataCorrect: boolean) => {
          this.setState({ syncInProgress: false });
          if (!received || !dataCorrect) {
            await this.checkOutOfSync();
          }
        },
        messages: {
          success: 'Mission successfully synced!',
          error: 'Mission failed to sync',
        },
        expectedTopic: 'mission/sync_done',
        expectedValue: { data: mission.nav_checksum },
        expectedTimeout: 15000,
      },
    });

    // set sync in progress
    this.setState({ syncInProgress: true });
  }

  async checkOutOfSync(checksum?: string) {
    const mission = getCurrentMission();
    if (!checksum) {
      checksum = this.state.currentMissionChecksum;
    }

    if (mission && this.props.connectionStatus === 'connected') {
      const outOfSync = !this.state.robotMission || mission.nav_checksum !== checksum;

      await this.setStateAsync({ outOfSync });

      if (this.state.autoSync && outOfSync && !this.state.syncInProgress && this.state.controlRole === 'control') {
        this.syncMission();
      }
    }
  }

  updateRobotMission({ hostname, msg }: { hostname: string; msg: RosMissionMsg }) {
    const session = getCurrentSession();
    if (msg && session?.robot_hostname === hostname && msg.header.frame_id === 'map') {
      if (!this.state.robotMission || !!(this.state.robotMission.nav_checksum !== msg.nav_checksum)) {
        console.log(`!!!! setting robot mission`);
        this.setState({
          robotMission: msg,
          lastSyncTimestamp: new Date(msg.header.stamp ? msg.header.stamp.secs * 1000 : Date.now()).toLocaleString(),
        });
      }
    }
  }

  async setWaypoint(
    startAt: number,
    sampleId: string | null,
    targetWaypoint: string | null,
    tag: string,
    doneCallback: () => void,
    coordinates?: Coordinate,
  ) {
    console.log(
      `Setting Waypoint: , Start At: ${startAt}, Sample ID: ${sampleId}, Target Waypoint: ${targetWaypoint}, Tag: ${tag}, Coordinates: ${coordinates}`,
    );
    const mission = getCurrentMission();
    const isZoneMission = mission?.is_zone_mission() || false;
    await logger.log('SET_WAYPOINT', { startAt, sampleId, targetWaypoint });
    if (!this.state.robotMission) {
      alertWarn('No robot mission found');
      doneCallback();

      return;
    }
    const constrain = (val: number) => {
      return val < 1 ? 0 : val;
    };
    let waypoint_index = 0;
    if (startAt === 1) {
      // go to start
      waypoint_index = 1;
    } else if (startAt === 2 && coordinates) {
      // sample id
      /** (4) ['U.2', 'U', '.2', '2', index: 0, input: 'U.2', groups: undefined]
          0: "U.2"
          1: "U"
          2: ".2"
          3: "2"
          groups: undefined
          index: 0
          input: "U.2"
          length: 4
      */

      // let's first try a coordinate match between what is on the map and what is in our ros waypoints
      const convertedCoordinates = convertProjection3857(coordinates);
      let wp = [...this.state.robotMission.waypoints.entries()].find((wp) => {
        return (
          wp[1].position.x.toFixed(6) === convertedCoordinates[1].toFixed(6) &&
          wp[1].position.y.toFixed(6) === convertedCoordinates[0].toFixed(6)
        );
      });

      // ONLY try to do exact point matching in zone missions...
      if (wp && isZoneMission) {
        waypoint_index = constrain(wp[0] + 1);
      } else if (sampleId) {
        // sentry error
        //
        //   TypeError: Cannot read properties of null (reading '1')
        //   at wp (components/SamplingTask.tsx:1237:39)
        //   at Array.find (<anonymous>)
        //   at call (components/SamplingTask.tsx:1236:67)
        // ...
        // (3 additional frame(s) were not displayed)
        // K-6, andrewc3, 2023-10-11
        const m = sampleId.match(/^([a-zA-Z0-9]+)([,.;-]([1-9][0-9]*))?$/);
        wp = [...this.state.robotMission.waypoints.entries()].find((wp) => wp[1].sample_id === m?.[1]);
        if (m?.[3]) {
          // start at specific core within sample
          wp = [...this.state.robotMission.waypoints.entries()].find(
            (wp1) => wp && wp[0] !== undefined && wp1[0] === wp?.[0] + parseInt(m[3]) - 1,
          );
        }
        waypoint_index = wp ? constrain(wp[0] + 1) : 0; // start before a sample
      }
    } else if (startAt === 3) {
      const wp = [...this.state.robotMission.waypoints.entries()].reverse().find((wp) => wp[1].sample_id === sampleId);
      waypoint_index = wp ? constrain(wp[0] + 2) : 0; // start after a sample
    } else if (startAt === 4 && targetWaypoint) {
      waypoint_index = constrain(parseInt(targetWaypoint)); // start at a specific waypoint
    } else if (startAt === 5) {
      // go to end
      waypoint_index = this.state.robotMission.waypoints.length; // start at the end
    }

    const waypoint = mission?.getWaypoints().find((waypoint) => waypoint.waypoint_number === waypoint_index);
    const sample = waypoint?.getCorePoint()?.getSoilCore()?.getSample();
    if (sample) {
      const pulledCores = sample.getPulledSoilCores() || [];
      if (pulledCores.length > 0) {
        const response = await alertFullScreen(
          'Resample?',
          `Sample ${sample.sample_id} has ${pulledCores.length} cores pulled, what do you want to do?`,
          ['Resample', 'Sample Additional Cores', 'Cancel Setting Waypoint'],
        );
        if (response === 'Cancel Setting Waypoint') {
          doneCallback();
          return;
        } else if (response === 'Resample') {
          sample.resetPulled();

          dispatchMissionUpdated();
        } else if (response === 'Sample Additional Cores') {
          // do nothing, we can move on
        }
      }
    }

    // console.log(`Setting waypoint to ${waypoint_index}`);
    const session = getCurrentSession();
    dispatchRosMsgPub({
      hostname: session?.robot_hostname ?? '',
      msg: { data: waypoint_index },
      topic: 'mission/set_waypoint',
      tag,
      expectationParams: {
        expectedTopic: 'mission/waypoint',
        messages: {
          success:
            startAt === 1
              ? 'Successfully set waypoint to first sample!'
              : startAt === 5
                ? 'Successfully set waypoint to last sample!'
                : sampleId
                  ? `Successfully set waypoint to sample ${sampleId}!`
                  : `Successfully set target waypoint to ${targetWaypoint}!`,
          error:
            startAt === 1
              ? 'Failed to set waypoint to first sample!'
              : startAt === 5
                ? 'Failed to set waypoint to last sample!'
                : sampleId
                  ? `Failed to set waypoint to sample ${sampleId}!`
                  : `Failed to set target waypoint to ${targetWaypoint}!`,
        },
        doneCallback,
      },
    });
  }

  async setZoneWaypoint(
    zoneName: string | null,
    sampleId: string | null,
    tag: string,
    doneCallback: () => void,
    coordinates?: Coordinate,
  ) {
    const mission = getCurrentMission();
    const allZones = mission?.getZones() ?? [];
    if (sampleId) {
      await this.setWaypoint(2, sampleId, null, tag, doneCallback, coordinates);
    } else {
      const zone = allZones.find((zone) => zone.zone_name === zoneName);
      const sampleZone = zone?.getSampleZone();
      if (sampleZone) {
        const samples = orderBy(sampleZone.getSampleSite()?.getSamples(), ['order'], ['asc']);
        if (samples.length) {
          const sample = samples[0];
          await this.setWaypoint(2, sample.sample_id, null, tag, doneCallback, coordinates);
        }
      }
    }
  }

  async loadMissionSchedule(id: string) {
    const confirm = await alertConfirm('Are you sure you want to load this mission?', this.constructor.name);

    if (!confirm) return;

    const session = getCurrentSession();
    if (!session) {
      return;
    }

    await this.props.onLoading(async () => {
      this.resetRobotSettings();

      Sentry.setTag('job_info', id);

      const record = await AirtableRecord.findOne<AirtableRecord<Jobs>>(
        (record) => !!record && record.table === 'Jobs' && record.id === id,
      );

      try {
        await record?.refresh();
      } catch (err) {
        alertWarn('Unable to refresh mission data');
      }

      if (record && (record.get('Offline Synced') || record.get('Sample Date Man OVRD Date'))) {
        await errorTone();
        const warnConfirm = await alertWarnConfirm(
          'WARNING! THIS MISSION HAS BEEN COMPLETED! IF YOU LOAD IT, YOU COULD LOSE SAMPLED DATA. ARE YOU SURE?',
        );
        if (!warnConfirm) return;
      }

      const today = new Date().toISOString().substring(0, 10);
      const notificationTimeString = record?.get('Operator En Route');
      const notificationDate = notificationTimeString
        ? new Date(notificationTimeString).toISOString().substring(0, 10)
        : undefined;
      const jobTextAlreadySentToday = notificationDate && notificationDate === today;
      const customerBoundaryRecording = session.getUser()?.customer_boundary_recording;
      if (!jobTextAlreadySentToday && !customerBoundaryRecording) {
        if (await alertConfirm('This job has not been notified for sampling. Are you sampling this field now?')) {
          await postCustomerNotification(id, [this.position?.x || 0, this.position?.y || 0]);
        }
      }

      const onRobot = this.state.robotState !== RobotState['Unknown'];

      // if on robot....
      if (onRobot) {
      }

      await SampleBox.logBoxes('BOXES_BEFORE_LOADING_MISSION_IN_SAMPLING');
      await session.loadMission(id, true);
      await SampleBox.logBoxes('BOXES_AFTER_LOADING_MISSION_IN_SAMPLING');
    });
  }

  showNavMessage(directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) {
    this.setState({
      navigationPopupVisible: true,
      navWarningDirections: directionsLink,
      growerNotificationPromptVisible: true,
      navigationJobInstanceId: jobInstanceId,
    });
  }

  /**
   * Loads the jobs from the schedule to be displayed
   * @param {boolean} forceRefresh Force airtable to pull from airtable api, pulls from cache by default
   * @param {boolean} userGenerated Used for debugging -- whether an event is the function was called from a user generated event
   */
  async loadSchedule(forceRefresh?: boolean, userGenerated?: boolean) {
    await SampleBox.logBoxes('BOXES_BEFORE_LOADING_SAMPLING_SCHEDULE');
    const logTimer = logger.start('LOAD_SCHEDULE', { forceRefresh, userGenerated });
    this.setState({ loadingSchedule: true });

    try {
      const session = getCurrentSession();
      const queryParams = `{Operator Email} = '${session?.getUser()?.email}'`;

      const jobsQuery = Airtable.search<Jobs>(
        'Jobs',
        AirtableIDLookup['Jobs'].views[this.RUN_VIEW_NAME],
        queryParams,
        false,
      );

      const completedJobsQuery = Airtable.search<Jobs>(
        'Jobs',
        AirtableIDLookup['Jobs'].views[this.COMPLETED_VIEW_NAME],
        queryParams,
        false,
      );

      const dropoffsQuery = Airtable.search<Jobs>(
        'Shift Dropoffs',
        AirtableIDLookup['Shift Dropoffs'].views[this.DROPOFF_VIEW_NAME],
        queryParams,
        false,
      );

      // const labSearch = Airtable.search(
      //   'Labs',
      //   'App View - Labs',
      //   '',
      //   false
      // ); // search for all of the labs

      const [jobsResult, completedJobsResult, dropoffsResult] = await Promise.all([
        jobsQuery,
        completedJobsQuery,
        dropoffsQuery,
      ]); // wait for all promises to resolve

      const shouldRefreshJobs = forceRefresh || jobsResult.getRecords().length === 0;
      const shouldRefreshDropoffs = forceRefresh || dropoffsResult.getRecords().length === 0;
      let jobRefreshWarning = false;

      try {
        if (shouldRefreshJobs) await Promise.all([jobsResult.refresh(), completedJobsResult.refresh()]);
      } catch (error) {
        jobRefreshWarning = true;
        alertError('Unable to refresh job schedule data');
      }

      try {
        if (shouldRefreshDropoffs) await dropoffsResult.refresh();
      } catch (error) {
        alertError('Unable to refresh dropoff schedule data');
      }

      const jobs = jobsResult.getRecords();
      const dropoffs = dropoffsResult.getRecords();
      const sampledJobs = completedJobsResult.getRecords();

      const jobLabs = jobs.map((job) => getField<string, Jobs>(job, 'Lab - Final').replace(/"/g, ''));
      const uniqueLabs = [...new Set(jobLabs)];

      console.log(`Jobs: ${jobs.length}, Labs: ${JSON.stringify(uniqueLabs)}`);

      // TODO instruct the label controller to fill labels for these labs...

      let offlineJobIds: string[] = [];
      try {
        const offlineRecoveryFiles = await getRecoveryList({ count: 100, search: 'OFFLINE_' });
        if (offlineRecoveryFiles) {
          // TODO this needs to be removed/fixed, we shouldn't need this as statement
          offlineJobIds = extractRogoJobIDs(offlineRecoveryFiles as RecoveryFileListResponse);
        }
      } catch (err) {
        // unable to reach recovery server
        await logger.log('LOAD_SCHEDULE', { situation: 'Attempt to retrieve offline submitted jobs', err });
      }

      const offlineJobs = jobs.filter((record) => offlineJobIds.includes(record.id));
      sampledJobs.push(...offlineJobs);

      const sampled = jobs.filter((record) => Boolean(record.get('Sample Date Man OVRD Date')));
      sampledJobs.push(...sampled);

      // Only include jobs that are either offline OR NOT sampled already
      const items = [
        ...jobs.filter((record) => offlineJobIds.includes(record.id) || !sampled.includes(record)),
        ...dropoffs,
      ];

      await this.generateGeoJSON(items, shouldRefreshJobs || shouldRefreshDropoffs);

      // TODO filter items based on offline sync...
      // because we expect this to be rare, it would be fastest to just find all offline missions, and then compare against our job list
      //const offlineMissions = Mission.query((mission: Mission) => mission.offline_sync).map((mission) => mission.job_id);
      //this.setState({ scheduleItems: items.filter((record) =>  offlineMissions.includes(record.id))});

      // TODO filter out scheduleItems that have a sample date to deal with offline
      this.setState({ scheduleItems: items, sampledItems: sampledJobs, offlineItems: offlineJobs });
      if (forceRefresh && !jobRefreshWarning) {
        alertSuccess('Schedule successfully refreshed');
      }

      await logger.stop(logTimer, { success: true });
    } catch (err) {
      console.error('SamplingTask -> loadSchedule', err);
      alertError('Schedule (s) failed to load. Check internet.');
      await logger.stop(logTimer, { success: false });
    }

    this.setState({ loadingSchedule: false });

    await SampleBox.logBoxes('BOXES_AFTER_LOADING_SAMPLING_SCHEDULE');
  }

  async generateGeoJSON(items: AirtableRecord[], forceRefresh = false) {
    let features: Feature<Polygon | MultiPolygon | Point>[] = [];

    for (const item of items) {
      if (item.table === 'Jobs') {
        if (Boolean(item.get('Sample Date'))) {
          continue;
        }

        if (forceRefresh) {
          await item.refresh(); // to refresh item's attachments urls
        }

        features.push(genPullinGeoJSON(item));

        let result: Attachment | undefined;
        try {
          result = (await item.get_attachments('Bnd GeoJSON'))[0];
        } catch (error) {
          console.log('SamplinTask - generateGeoJSON - 2nd attempt getting attachments for item', item);
        }

        if (!result) {
          await item.refresh(); // to refresh item's attachments urls and attachments
          result = (await item.get_attachments('Bnd GeoJSON'))[0];
        }

        if (!result) {
          continue;
        }

        const jsonText = await result.text();
        try {
          const boundaryGeoJSON = JSON.parse(jsonText) as FeatureCollection<Polygon | MultiPolygon>;
          updateBoundaryProperties(item, boundaryGeoJSON);
          features.push(...boundaryGeoJSON.features);
        } catch (error) {
          console.error('SamplingTask -> generateGeoJSON - update boundary properties', error);
        }
      } else if (item.table === 'Shift Dropoffs') {
        const dropoffGeoJSON = genDropoffGeoJSON(item);
        features.push(...dropoffGeoJSON);
      } else {
        console.error(`Unknown schedule item: ${item.table}`);
      }
    }

    this.setState({ scheduleFeatures: features });
  }

  toggleTrackingOverride() {
    const trackingOverride = !this.state.trackingOverride;
    this.setState({ trackingOverride });
  }

  /**
   * Cleanup for the box exit interview
   * @param {object} switchedBox
   */
  async finishBoxExitInterview(switchedBox: boolean) {
    const box = this.state.boxToClose;
    const boxesNeedReprint = this.state.boxesNeedReprint.slice();
    if (switchedBox) {
      boxesNeedReprint.pop();
      this.setState({ boxesNeedReprint });
    }
    this.setState({ boxToClose: undefined });
    if (!box && boxesNeedReprint.length) {
      await this.handleCloseBox();
    }
  }

  downloadPdf() {
    if (this.state.boxPdf) {
      const link = document.createElement('a');
      link.href = this.state.boxPdf.dataUrl;
      link.download = this.state.boxPdf.downloadName;
      link.click();
    }
  }

  /**
   * Method that will remove box from state
   * @param {number} box The instance id of the box to close or dispose
   * @param {boolean} dispose Dispose the box instead of setting the close property
   */
  async closeBox(box: SampleBox, dispose: boolean = false) {
    await SampleBox.logBoxes('BOXES_BEFORE_CLOSING_BOX');

    if (dispose) {
      box.dispose();
    } else {
      // TODO why are we setting the session ID to undefined here?
      // what does that gain us?
      box.Session_id = undefined;
      box.ReprintSession_id = undefined;
      box.closedTimestamp = new Date().toISOString();

      this.props.closeBox(box);

      this.setState({ boxPdfPreviewVisible: false });
      const dontDispose = !dispose;

      // if no active mission or active mission is not in box, then upload the box
      const currentMission = getCurrentMission();
      const noCurrentMission = !currentMission;
      const currentMissionInBox = currentMission && box.getSampleBoxMissions().includes(currentMission);
      const currentMissionNotInBox = !currentMissionInBox;
      // if we aren't disposing the box AND
      //   we don't have a current mission OR
      //   the current mission is NOT in the box
      // then we should upload the box data. If the box gets updated before the
      // active mission is closed, then we can wait to let the mission upload
      // the box Otherwise, we should upload the box data now
      if (dontDispose && (noCurrentMission || currentMissionNotInBox)) {
        try {
          const result = await box.upload();
          if (!result) {
            await logger.log('CLOSE_BOX', `Attempted to upload dirty box data but failed: ${JSON.stringify(box)}`);
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    // If no mission is loaded, then we can select the next open box
    const openBoxes = SampleBox.getOpenBoxes();
    if (!getCurrentMission() && openBoxes.length) {
      const nextBox = openBoxes[0];
      nextBox.Session_id = getCurrentSession()?.instance_id;
      this.setState({ activeBox: nextBox, boxInstanceId: nextBox.instance_id });
    }

    dispatchActiveBoxUpdated();

    await SampleBox.logBoxes('BOXES_AFTER_CLOSING_BOX');
  }

  /**
   * Changes printer configuration to be used for PDF
   */
  handlePrinterSelect(printerConfig: PRINTER_CONFIG) {
    const printURL = getPrintURLFromConfig(printerConfig);
    console.log(printURL);
    this.setState({
      printerConfig: printerConfig,
      printURL: printURL,
    });
  }

  /**
   * Loads box data into state for display
   */
  async updateBoxData() {
    let box = SampleBox.getCurrentBox();
    const boxQuery = SampleBox.query();
    // TODO this is where we need to go away from using this temporary
    // data instance and start typing this
    const allBoxes = [...boxQuery];

    // if (!box && allBoxes.length) {
    //   const lastBox = allBoxes[allBoxes.length - 1].box;
    //   if (lastBox !== box) {
    //     box = lastBox;
    //     box.Session_id = getCurrentSession()?.instance_id;
    //   }
    // }
    if (box) {
      const boxMissionSet = Array.from(box.getSampleBoxMissions());
      const boxMissions = boxMissionSet.map((mission) => {
        const fieldName = mission.getJob()?.field || '';
        return {
          fieldName,
          instanceId: mission.instance_id,
          numSamples: flatten(
            mission.getSampleSites().map((site) => site.getSamples().filter((sample) => sample.bag_id)),
          ).length,
        };
      });
      this.setState({ activeBox: box, boxInstanceId: box.instance_id, boxMissions, allBoxes });
      return;
    } else {
      this.setState({ activeBox: undefined, boxInstanceId: undefined, boxMissions: [], allBoxes: [] });
    }
  }

  /**
   * Loads boxes that need reprinted
   */
  updateBoxNeedsReprint() {
    const session = getCurrentSession();
    if (session) {
      const boxes = session.getReprintSampleBoxes();
      const boxesNeedReprint = boxes.map((box) => {
        const boxMissionSet = box.getSampleBoxMissions();
        const boxMissions = boxMissionSet.map((mission) => {
          const fieldName = mission?.getJob()?.field || '';
          const numSamples = box.getSamples().length;
          return { fieldName, instanceId: mission.instance_id, numSamples };
        });
        return { boxId: box.uid, instanceId: box.instance_id, boxMissions };
      });

      this.setState({ boxesNeedReprint });
    }
  }

  render() {
    // TODO these shouldn't happen every render... but I don't want to put all of this in the state either
    let riskyNavigation = false;
    let jobNeedsNotified = false;

    // count photos by sample
    const photosByMetadata = this.state.currentMissionPhotos.map((photo) => {
      const [, , , filename] = photo.split('/');
      const [jobId, sampleId, , , ,] = filename.split('_');
      return { jobId, sampleId, photo };
    });

    // count photos by sampleId
    const photosBySampleId = photosByMetadata.reduce((acc, photo) => {
      if (!acc[photo.sampleId]) {
        acc[photo.sampleId] = [];
      }
      acc[photo.sampleId].push(photo);
      return acc;
    }, {});

    const navigationJob = AirtableRecord.get(this.state.navigationJobInstanceId);

    const onRobot = this.state.robotState !== RobotState['Unknown'];
    // const viewOnlyApp = this.state.controlRole === 'view';

    let currentJobName = '';
    if (this.state.growerNotificationPromptVisible) {
      const todaysDate = new Date().toISOString().substring(0, 10);
      const notificationTimeString = navigationJob?.get('Operator En Route');
      currentJobName = navigationJob?.get('Name');
      const notificationDate = notificationTimeString
        ? new Date(notificationTimeString).toISOString().substring(0, 10)
        : undefined;
      const jobTextAlreadySentToday = notificationDate && notificationDate === todaysDate;

      const operatorInGrowerTextBeta = true; // getCurrentSession()?.getUser()?.user_flags?.includes('Grower Text Beta');
      const jobInGrowerTextBeta = getCurrentMission()?.getJob()?.job_flags?.includes('Grower Text Beta');

      jobNeedsNotified = (jobInGrowerTextBeta || operatorInGrowerTextBeta) && !jobTextAlreadySentToday;

      riskyNavigation = !navigationJob || (navigationJob.get('Lat Lon Source') as LatLonSource) !== 'NearestRoad';
    }

    const mission = getCurrentMission();

    return (
      <React.Fragment>
        {this.state.boxPdf && (
          <Modal
            open={Boolean(this.state.boxPdf) && this.state.boxPdfPreviewVisible}
            style={{ zIndex: 1500, overflow: 'scroll' }}
          >
            <React.Fragment>
              <PDFViewer
                fileUrl={this.state.boxPdf.dataUrl}
                downloadName={this.state.boxPdf.downloadName}
                onClose={() => {
                  console.log(`PDFView.onClose`);
                  this.downloadPdf();
                  this.setState({ boxPdf: undefined });
                }}
                printURL={this.state.printURL}
                onSuccess={() => alertSuccess('Print Job Sent Successfully - Check Printer Power and Paper')}
                onFailure={() => alertError('Print Job Failed to Send')}
              />
            </React.Fragment>
          </Modal>
        )}

        {/* Box exit interview for active box */}
        {this.state.boxToClose && (
          <BoxExitInterview
            onClose={async () => await this.finishBoxExitInterview(false)}
            closeBox={this.closeBox}
            isReprint={false}
            box={this.state.boxToClose}
            boxUid={this.state.boxToClose.uid}
            boxInstanceId={this.state.boxToClose.instance_id}
            boxMissions={this.state.boxMissions}
            openBoxExitInterview={this.handleCloseBox}
            accessLevel={this.props.accessLevel}
            handlePrinterSelect={this.handlePrinterSelect}
            printerConfig={this.state.printerConfig}
          />
        )}

        {/* Box exit interview for boxes that need reprinted */}
        {this.state.boxToClose && !this.state.activeBox && this.state.boxesNeedReprint.length > 0 && (
          <BoxExitInterview
            onClose={async () => await this.finishBoxExitInterview(true)}
            closeBox={this.closeBox}
            isReprint={true}
            box={SampleBox.get(this.state.boxesNeedReprint[this.state.boxesNeedReprint.length - 1].instanceId)!}
            boxUid={this.state.boxesNeedReprint[this.state.boxesNeedReprint.length - 1].boxId}
            boxMissions={this.state.boxesNeedReprint[this.state.boxesNeedReprint.length - 1].boxMissions}
            boxInstanceId={this.state.boxesNeedReprint[this.state.boxesNeedReprint.length - 1].instanceId}
            openBoxExitInterview={this.handleCloseBox}
            accessLevel={this.props.accessLevel}
            printerConfig={this.state.printerConfig}
            handlePrinterSelect={this.handlePrinterSelect}
          />
        )}
        {this.state.manualMeasurementDialogOpen && this.state.manualMeasurementDialogParameters && (
          <ManualMeasurementDialog
            title={this.state.manualMeasurementDialogParameters.title}
            imageSource={this.state.manualMeasurementDialogParameters.imageSource}
            initialValue={this.state.manualMeasurementDialogParameters.measurementParameters.initialValue}
            dismissDialog={
              this.state.manualMeasurementDialogParameters.showDismissButton
                ? () => {
                    this.setState({
                      manualMeasurementDialogOpen: false,
                      manualMeasurementDialogParameters: null,
                    });
                  }
                : undefined
            }
            suggestedMin={this.state.manualMeasurementDialogParameters.measurementParameters.suggestedMin}
            suggestedMax={this.state.manualMeasurementDialogParameters.measurementParameters.suggestedMax}
            absoluteMin={this.state.manualMeasurementDialogParameters.measurementParameters.absoluteMin}
            absoluteMax={this.state.manualMeasurementDialogParameters.measurementParameters.absoluteMax}
            sliderMarks={this.state.manualMeasurementDialogParameters.measurementParameters.benchmarks}
            onSave={(measurementCm) => {
              this.state.manualMeasurementDialogParameters?.onComplete(measurementCm);

              this.setState({
                manualMeasurementDialogOpen: false,
                manualMeasurementDialogParameters: null,
              });
            }}
          />
        )}
        <SamplingEntranceInterview
          open={this.state.entranceInterviewDialogOpen}
          closeForm={() => this.setState({ entranceInterviewDialogOpen: false })}
          currentDepthOffset={this.state.drillDepthOffset}
          accessLevel={this.props.accessLevel}
          elevatePermissions={this.props.elevatePermissions}
        />
        {this.props.tab === 'mission' &&
          (this.state.missionView === 'standard' ? (
            <SamplingMissionView
              toggleScanner={this.toggleScanner}
              scannerActive={this.state.scannerActive}
              missionActive={this.props.missionActive}
              onLoading={this.props.onLoading}
              accessLevel={this.props.accessLevel}
              operatorNotesDialogOpen={this.state.operatorNotesDialogOpen}
              toggleNotesDialog={this.toggleNotesDialog}
              toggleHMIVisible={this.toggleHMIVisible}
              hmiVisible={this.state.hmiVisible}
              showNavMessage={this.showNavMessage}
              showRecoveryPopup={this.props.showRecoveryPopup}
              drillDepth={this.state.drillDepthDisplay}
              drillDepthOffset={this.state.drillDepthOffset}
              allBoxes={this.state.allBoxes}
              activeBox={this.state.activeBox}
              handleCloseBox={this.handleCloseBox}
              photosBySampleId={photosBySampleId}
              updateMissionView={(view: MissionView) => this.setState({ missionView: view })}
              robotConnected={this.props.connectionStatus === 'connected'}
              openEntranceInterview={() => this.setState({ entranceInterviewDialogOpen: true })}
              openManualMeasurementDialog={this.openManualMeasurementDialog}
            />
          ) : (
            <SamplingHelperView
              scheduleItems={this.state.scheduleItems}
              missionActive={this.props.missionActive}
              updateMissionView={(view: MissionView) => this.setState({ missionView: view })}
            />
          ))}

        {this.props.tab === 'schedule' && (
          <SampleScheduleView
            loadMissionSchedule={this.loadMissionSchedule}
            loadSchedule={this.loadSchedule}
            loadingSchedule={this.state.loadingSchedule}
            scheduleItems={this.state.scheduleItems}
            sampledItems={this.state.sampledItems}
            offlineItems={this.state.offlineItems}
            missionActive={this.props.missionActive}
            showNavMessage={this.showNavMessage}
            resubmitAll={this.resubmitAll}
          />
        )}
        {this.props.tab === 'map' && (
          <SamplingMap
            setWaypoint={this.setWaypoint}
            setZoneWaypoint={this.setZoneWaypoint}
            loadMissionSchedule={this.loadMissionSchedule}
            toggleScanner={this.toggleScanner}
            scannerActive={this.state.scannerActive}
            scheduleFeatures={this.state.scheduleFeatures}
            robotMission={this.state.robotMission}
            connectionStatus={this.props.connectionStatus}
            missionActive={this.props.missionActive}
            totalSamples={this.state.totalSamples}
            filledSamples={this.state.filledSamples}
            trackingOverride={this.state.trackingOverride}
            hmiVisible={this.state.hmiVisible}
            accessLevel={this.props.accessLevel}
            robotNavControlState={this.state.robotNavControlState}
            navigatingToSampleLocation={undefined}
            robotState={this.state.robotState}
            presentSample={this.state.presentSample}
            closeSample={this.state.closeSample}
            coresInBucket={mission?.getSoilCoresInBucket() || []}
            drillDepth={this.state.drillDepthDisplay}
            drillDepthOffset={this.state.drillDepthOffset}
            onLoading={this.props.onLoading}
            inBounds={this.state.inBounds}
            openManualMeasurementDialog={this.openManualMeasurementDialog}
            targetCoordinate={this.state.targetCoordinate}
          />
        )}
        {this.props.tab === 'boxes' && (
          <BoxHistoryView showBoxCloseScreen={this.handleCloseBox} accessLevel={this.props.accessLevel} />
        )}
        {this.props.tab === 'robot' && (
          <RobotPanel
            setDepth={this.setDepth}
            syncMission={this.syncMission}
            setWaypoint={this.setWaypoint}
            updateAutoSync={this.updateAutoSync}
            toggleTrackingOverride={this.toggleTrackingOverride}
            trackingOverride={this.state.trackingOverride}
            syncInProgress={this.state.syncInProgress}
            robotMission={this.state.robotMission}
            outOfSync={this.state.outOfSync}
            missionDepth={this.state.missionDepth}
            drillDepth={this.state.drillDepthDisplay}
            depthLocked={this.state.depthLocked}
            lastSyncTimestamp={this.state.lastSyncTimestamp}
            autoSync={this.state.autoSync}
            devMode={this.props.accessLevel === 'Technician'}
            connectionStatus={this.props.connectionStatus}
            elevatePermissions={this.props.elevatePermissions}
            accessLevel={this.props.accessLevel}
            hmiVisible={this.state.hmiVisible}
            toggleHMIVisible={this.toggleHMIVisible}
          />
        )}

        <Robots task={TaskName.SAMPLING} />

        <SlideoutTextBox
          dialogOpen={this.state.operatorNotesDialogOpen}
          message={this.state.operatorNotes}
          closeDialog={this.closeNotesDialog}
          title="MISSION NOTES:"
          expandedWidth="300px"
        />

        <Dialog
          disableEscapeKeyDown
          maxWidth={'sm'}
          open={this.state.growerNotificationPromptVisible || false}
          onClose={(_event, reason) => {
            if (reason === 'backdropClick') return;
            this.setState({
              growerNotificationPromptVisible: false,
            });
          }}
        >
          <DialogTitle>{currentJobName}</DialogTitle>
          <DialogContent>
            <Grid>
              <Grid>
                {!riskyNavigation && !jobNeedsNotified && (
                  <ListItemText>Directions are ready, please click to navigate</ListItemText>
                )}
                {riskyNavigation && (
                  <ListItemText>
                    <b>
                      Warning! This mission does not have a reliable navigation point. Please review the map near your
                      destination
                    </b>
                  </ListItemText>
                )}

                {jobNeedsNotified && (
                  <ListItemText>
                    Are you going to sample this field next? If so, we will contact the customer to let them know you're
                    on the way.
                  </ListItemText>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
              direction={isMobile ? 'column' : 'row'}
            >
              {jobNeedsNotified && (
                <Grid item xs={12} sm={5}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await this.props.onLoading(async () => {
                        this.setState({ growerNotificationPromptVisible: false });

                        // open the directions link
                        const link = document.createElement('a');
                        link.href = this.state.navWarningDirections;
                        link.target = '_blank';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        setTimeout(async () => {
                          logger.log('JOBS_NOTIFICATIONS', 'Start feedback timeout for sending notifications');
                          // TODO it would be nice if we could just use the current user position, or possibly the robot position?
                          let position: Coordinate = [0, 0];
                          try {
                            position = await getUserPosition({ enableHighAccuracy: false });
                          } catch (err) {
                            // do nothing as getUserPosition warns already
                          }

                          if (navigationJob?.id) {
                            await postCustomerNotification(navigationJob.id, position, onRobot);
                          }
                        });
                      });
                    }}
                  >
                    Notify + Navigate
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} sm={5}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    this.setState({ growerNotificationPromptVisible: false });
                    const link = document.createElement('a');
                    link.href = this.state.navWarningDirections;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  {jobNeedsNotified ? "Navigate (Don't Notify)" : riskyNavigation ? 'Navigate Anyways' : 'Navigate'}
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => {
                    this.setState({ growerNotificationPromptVisible: false });
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>

        {Boolean(this.state.reasonSelectorOptions) && (
          <ChangeTypeReasonSelector
            onClose={async (result, { enteredReason, selectedChangeType }) => {
              const resultPayload: ChangeReasonPopupResult = { result, selectedChangeType, reason: enteredReason };
              EventBus.dispatch('SAMPLING_TASK:CHANGE_TYPE_REASON_SELECTOR_CLOSED', JSON.stringify(resultPayload));
              this.setState({ reasonSelectorOptions: undefined });
            }}
            clearBarcode={async function (sampleInstanceId: number): Promise<void> {
              const errMap = await setSampleBarcodes(sampleInstanceId, '', true); // set samples in db
              await checkGapError(errMap);
              const sampleId = Sample.get(sampleInstanceId)?.sample_id;
              if (sampleId) {
                dispatchSamplesUpdated([sampleId]);
              }

              return;
            }}
            {...this.state.reasonSelectorOptions}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  closeBox,
};

export default connect(null, mapDispatchToProps)(SamplingTask);
