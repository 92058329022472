import download from 'downloadjs';

import React, { PropsWithChildren, PureComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Modal from '@material-ui/core/Modal';
import DialogTitle from '@material-ui/core/DialogTitle';
import PDFViewer from './utils/PDFViewer';

import { AiOutlineAndroid, AiOutlineApple, AiOutlineChrome } from 'react-icons/ai';

import ErrorBoundary from './utils/ErrorBoundary';
import Alerts from './alerts/Alerts';
import ResetButton from './utils/ResetButton';

import getVersion, { getVersionTimestamp } from '../version';
import logger from '../logger';
import {
  getCurrentMission,
  getCurrentSession,
  getCurrentUser,
  saveCurrentMissionRecoveryZip,
} from '../dataModelHelpers';
import { alertError, alertSuccess } from '../alertDispatcher';
import { exportDB, importDB, validateAllTableDataIntegrity } from '../db/datamanager';
import { OnLoading, SettingsAccessLevel } from '../types/types';
import { SampleBox } from '../db';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import EventBus from '../EventBus';
import { TASK_EVENTS } from '../taskEvents';
import { TaskName } from '../db/TaskClass';
import { wait } from '../utils';

import html2canvas from 'html2canvas';
import { MenuDrawerFeatureTable } from './MenuDrawerFeatureTable';

interface DrawerItemProps {
  style?: React.CSSProperties;
}

const DrawerItem = React.memo(function DrawerItem(props: PropsWithChildren<DrawerItemProps>) {
  return <ListItem style={props.style}>{props.children}</ListItem>;
});

const useStyles = makeStyles({
  paperLight: {
    backgroundColor: '#274052',
    color: 'white',
    maxWidth: '80%',
  },
  paperDark: {
    backgroundColor: '#303030',
    color: 'white',
    maxWidth: '80%',
  },
});

interface DrawerSliderProps {
  open: boolean;
  onClose: () => void;
  darkMode: boolean;
}

const DrawerSlider = React.memo(function DrawerSlider(props: PropsWithChildren<DrawerSliderProps>) {
  const styles = useStyles();
  return (
    <Drawer
      anchor="left"
      classes={{ paper: props.darkMode ? styles.paperDark : styles.paperLight }}
      open={props.open}
      onClose={props.onClose}
      keepMounted={true}
    >
      {props.children}
    </Drawer>
  );
});

interface DrawerButtonProps {
  startIcon?: React.ReactNode;
  style?: React.CSSProperties;
  onClick: (() => void) | (() => Promise<void>);
}

const DrawerButton = React.memo(function DrawerButton(props: PropsWithChildren<DrawerButtonProps>) {
  return (
    <Button
      style={{ color: 'white', textTransform: 'capitalize', width: '200px', justifyContent: 'left', ...props.style }}
      onClick={props.onClick}
      startIcon={props.startIcon || null}
    >
      {props.children}
    </Button>
  );
});

interface ItemBreakProps {
  style?: React.CSSProperties;
}

const ItemBreak = React.memo(function ItemBreak(props: ItemBreakProps) {
  return (
    <ListItem
      style={{ borderBottomStyle: 'solid', borderBottomColor: '#d3d3d3', borderBottomWidth: '1px', ...props.style }}
    />
  );
});

interface MenuDrawerProps {
  setToggleFunction: (toggleFunction: () => void) => void;
  darkMode: boolean;
  sideBarText: string;
  updateTaskRef: (taskRef: HTMLDivElement | undefined) => void;
  devMode: boolean;
  generateUserLoginQr: () => void;
  toggleTheme: () => void;
  elevatePermissions: () => void;
  checkForUpdates: () => Promise<void>;
  updateAdditionalButtonsRef: (ref: HTMLDivElement) => void;
  onLoading: OnLoading;
  accessLevel: SettingsAccessLevel;
}

interface MenuDrawerState {
  showSidebar: boolean;
  showNotifs: boolean;
  alertBadge: number;
  pdfUrl: string | null;
  eventLogDisabled: boolean;
  eventLogIsEnvironmentVariableDisabled: boolean;
  openDialog: boolean;
  showHelpLinksDialog: boolean;
  currentTask: TaskName;
  showAppDebugTable: boolean;
}

const GET_HELP_FORM_USER_ID_FIELD = 'fldWdlKLQLMEAZ02H';
const GET_HELP_FORM_JOB_ID_FIELD = 'fldKf73cwO3CkayVI';
const GET_HELP_FORM_BOX_UID_FIELD = 'fldf00cOVJDhRCwpU';
const GET_HELP_FORM_DEVICE_FIELD = 'fldzZfGUmtHZxvebV';
const GET_HELP_FORM_ROBOT_FIELD = 'fldVzGQjzUtE5gA8w';
const GET_HELP_FORM_SOURCE_FIELD = 'fldHuPy67cGYCb3FA';
const GET_HELP_FORM_BASE_URL = 'https://airtable.com/shrafuoVoImHh55IH';

export default class MenuDrawupdateAdditionalButtonsRefer extends PureComponent<MenuDrawerProps, MenuDrawerState> {
  constructor(props: MenuDrawerProps) {
    super(props);
    this.state = {
      showSidebar: false,
      showNotifs: false,
      alertBadge: 0,
      pdfUrl: null,
      eventLogDisabled: JSON.parse(localStorage.getItem('eventLogDisabled') || 'false'),
      eventLogIsEnvironmentVariableDisabled: import.meta.env.VITE_EVENT_LOGGING !== 'on',
      openDialog: false,
      showHelpLinksDialog: false,
      currentTask: TaskName.SAMPLING,
      showAppDebugTable: false,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.incrementAlertBadge = this.incrementAlertBadge.bind(this);
    this.clearAlertBadge = this.clearAlertBadge.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
  }

  toggleOpen() {
    this.setState({ showSidebar: !this.state.showSidebar });
  }

  componentDidMount() {
    this.props.setToggleFunction(this.toggleOpen);
    EventBus.on(TASK_EVENTS.UPDATED, this.taskUpdated);
  }

  componentWillUnmount() {
    EventBus.remove(TASK_EVENTS.UPDATED, this.taskUpdated);
  }

  incrementAlertBadge() {
    this.setState({ alertBadge: this.state.alertBadge + 1 });
  }

  taskUpdated = (newTaskName: string) => {
    this.setState({ currentTask: newTaskName as TaskName });
  };

  clearAlertBadge() {
    this.setState({ alertBadge: 0 });
  }

  handleOpenDialog() {
    this.setState({
      openDialog: true,
    });
  }

  handleCloseDialog() {
    this.setState({
      openDialog: false,
    });
  }

  handleDownloadCert() {
    const link = document.createElement('a');
    link.href = './static/rootCA.pem';
    link.download = 'rootCA.pem';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  toggleEventLogUploading() {
    const { eventLogDisabled } = this.state;
    this.setState({ eventLogDisabled: !eventLogDisabled });
    logger.toggleEventLogUploading();
  }

  render() {
    const versionTs = getVersionTimestamp();

    return (
      <React.Fragment>
        <IconButton
          onClick={this.toggleOpen.bind(this)}
          data-testid="logo-menu"
          color={'secondary'}
          size={'small'}
          style={{ margin: 5, marginRight: 10, marginLeft: 10 }}
        >
          <MenuIcon />
        </IconButton>
        <DrawerSlider
          open={this.state.showSidebar}
          onClose={() => {
            this.setState({ showSidebar: false, showNotifs: false });
          }}
          darkMode={this.props.darkMode}
        >
          <List>
            <DrawerItem style={{ paddingBottom: 0 }}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <IconButton
                    style={{ color: this.state.showNotifs ? '#b5734e' : 'white' }}
                    onClick={() => {
                      this.setState({ showNotifs: !this.state.showNotifs });
                    }}
                    size="small"
                  >
                    <Badge overlap="rectangular" badgeContent={this.state.alertBadge} color="primary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{this.props.sideBarText || 'Welcome to Rogo!'}</Typography>
                </Grid>
              </Grid>
            </DrawerItem>
            <ItemBreak />

            {/* notification area */}
            <List
              style={{ display: this.state.showNotifs ? 'block' : 'none', maxHeight: 600, overflow: 'auto' }}
              id="allAlerts"
            ></List>

            <Alerts
              showAll={this.state.showNotifs}
              incrementAlertBadge={this.incrementAlertBadge}
              clearAlertBadge={this.clearAlertBadge}
            />

            {/* side bar buttons */}
            <div style={{ display: this.state.showNotifs ? 'none' : 'block' }}>
              <ErrorBoundary>
                <div id="tasks-root" ref={(ref) => this.props.updateTaskRef(ref || undefined)}></div>
              </ErrorBoundary>
              <ItemBreak />
              <DrawerItem>
                <DrawerButton
                  style={{ color: 'orange', textTransform: 'capitalize' }}
                  onClick={async () => {
                    // TODO I really don't like this, it feels hacky, there must be another type of
                    // child element we can use for a link...
                    const currentMission = getCurrentMission();
                    const link = document.createElement('a');
                    const user_id = getCurrentUser()?.user_id || '';
                    const job_id = currentMission?.job_id || '';
                    const box_uid = SampleBox.getCurrentBox()?.uid || '';
                    const robot = getCurrentSession()?.robot_name || '';
                    let href = GET_HELP_FORM_BASE_URL;
                    href += `?prefill_${GET_HELP_FORM_USER_ID_FIELD}=${user_id}&hide_${GET_HELP_FORM_USER_ID_FIELD}=true`;
                    href += `&prefill_${GET_HELP_FORM_JOB_ID_FIELD}=${job_id}&hide_${GET_HELP_FORM_JOB_ID_FIELD}=true`;
                    href += `&prefill_${GET_HELP_FORM_BOX_UID_FIELD}=${box_uid}&hide_${GET_HELP_FORM_BOX_UID_FIELD}=true`;
                    href += `&prefill_${GET_HELP_FORM_DEVICE_FIELD}=DEVICE&hide_${GET_HELP_FORM_DEVICE_FIELD}=true`;
                    href += `&prefill_${GET_HELP_FORM_ROBOT_FIELD}=${robot}`;
                    href += `&prefill_fld5YxXUjWad6iYIt=`; // TOOD not sure what this field is
                    href += `&prefill_${GET_HELP_FORM_SOURCE_FIELD}=operatorhelp-app&hide_${GET_HELP_FORM_SOURCE_FIELD}=true`;
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    // we are choosing not to await this so that we can be sure to open the link
                    // and we aren't held up by the recovery file download
                    //saveRecoveryZip(currentMission, { tag: "issue", uploadToAWS: true });
                  }}
                >
                  Click To Get Help
                </DrawerButton>
              </DrawerItem>

              {/* Reload */}
              <DrawerItem>
                <DrawerButton
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload
                </DrawerButton>
              </DrawerItem>

              {/* Operator Launchpad */}
              <DrawerItem>
                <DrawerButton
                  onClick={() => {
                    // TODO I really don't like this, it feels hacky, there must be another type of
                    // child element we can use for a link...
                    const link = document.createElement('a');
                    link.href = 'https://sites.google.com/rogoag.com/operatorlaunchpad/home';
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  Operator Launchpad
                </DrawerButton>
              </DrawerItem>

              {/* Download Recovery File */}
              <DrawerItem>
                <DrawerButton
                  onClick={async () =>
                    await saveCurrentMissionRecoveryZip({ tag: 'sb', offlineSync: false, uploadToAWS: true })
                  }
                >
                  Download Recovery File
                </DrawerButton>
              </DrawerItem>

              <DrawerItem>
                <DrawerButton
                  onClick={async () => {
                    await this.props.onLoading(async () => {
                      this.toggleOpen();
                      await wait(10);
                      const screenshotTarget = document.body;

                      await html2canvas(screenshotTarget).then((canvas) => {
                        const base64image = canvas.toDataURL('image/png');

                        // download base64 image as png
                        const link = document.createElement('a');
                        link.href = base64image;
                        // use timestamp, username, and task for screenshot
                        link.download = `${getCurrentUser()?.name}_${this.state.currentTask}_${Date.now()}.png`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      });
                    });
                  }}
                >
                  Take Screeenshot
                </DrawerButton>
              </DrawerItem>

              {/* Permissions Elevation */}
              <DrawerItem>
                <DrawerButton
                  onClick={this.props.elevatePermissions}
                  style={{ color: this.props.accessLevel === 'Technician' ? '#b5734e' : 'white' }}
                >
                  {this.props.accessLevel === 'Locked'
                    ? `Click to Unlock`
                    : `Click to Lock (${this.props.accessLevel.substring(0, 1)})`}
                  {this.props.accessLevel === 'Locked' ? (
                    <FaLock style={{ marginLeft: 7.5, marginRight: 7.5 }} />
                  ) : (
                    <FaLockOpen style={{ marginLeft: 7.5, marginRight: 7.5 }} />
                  )}
                </DrawerButton>
              </DrawerItem>

              {/* Check for Updates */}
              <DrawerItem>
                <DrawerButton onClick={this.props.checkForUpdates}>Check For Updates</DrawerButton>
              </DrawerItem>

              {/* Dark Mode */}
              <DrawerItem>
                <DrawerButton
                  onClick={this.props.toggleTheme}
                  style={{ color: this.props.darkMode ? '#b5734e' : 'white' }}
                >
                  Dark Mode
                </DrawerButton>
              </DrawerItem>

              {/* Certificate Installation */}
              <DrawerItem>
                <DrawerButton onClick={this.handleOpenDialog}>Certificate Installation</DrawerButton>
                <Dialog open={this.state.openDialog} maxWidth={'sm'} fullWidth>
                  <DialogActions>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button type="button" onClick={this.handleDownloadCert}>
                          Download Certificate
                        </Button>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button type="button" onClick={this.handleCloseDialog}>
                          close
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                  <DialogTitle>
                    <Typography variant="h5" align="center">
                      Certificate Installation
                    </Typography>
                  </DialogTitle>
                  <DialogTitle>
                    <Typography variant="subtitle1" align="center">
                      Select Your System for Install Instructions
                    </Typography>
                  </DialogTitle>
                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                      onClick={() => this.setState({ pdfUrl: './static/ios.pdf' })}
                      variant={'outlined'}
                      style={{ width: 120, alignItems: 'center' }}
                    >
                      IOS
                      <AiOutlineApple size={20} style={{ marginLeft: 2.5, marginBottom: 2.5 }} />
                    </Button>
                    <Button
                      onClick={() => this.setState({ pdfUrl: './static/android.pdf' })}
                      variant={'outlined'}
                      style={{ width: 120, alignItems: 'center' }}
                    >
                      Android
                      <AiOutlineAndroid size={22} style={{ marginLeft: 2.5 }} />
                    </Button>
                    <Button
                      onClick={() => this.setState({ pdfUrl: './static/web.pdf' })}
                      variant={'outlined'}
                      style={{ width: 160, alignItems: 'center' }}
                    >
                      Web/Desktop
                      <AiOutlineChrome size={20} style={{ marginLeft: 2.5 }} />
                    </Button>
                  </DialogActions>
                </Dialog>
              </DrawerItem>

              {/* <DrawerItem>
                <DrawerButton onClick={this.props.generateUserLoginQr}>Generate Login QR</DrawerButton>
              </DrawerItem> */}

              {/* Extra item break if technician mode to separate all technician items */}
              {this.props.accessLevel === 'Technician' && <ItemBreak style={{ paddingBottom: 0 }} />}

              {/* Check Mission Integry */}
              {/* {(this.props.accessLevel === 'Technician' && Boolean(getCurrentMission())) && ( */}
              {this.props.accessLevel === 'Technician' && (
                <DrawerItem>
                  <DrawerButton
                    onClick={async () => {
                      await this.props.onLoading(async () => {
                        try {
                          //for every table in db go through every object and run the check sum function
                          const valid = await validateAllTableDataIntegrity();
                          if (valid) {
                            alertSuccess('No data problems found!');
                          } else {
                            alertError('Data problems found!');
                          }

                          console.log(`All tables valid? ${valid}`);
                        } catch (e) {
                          console.error(e);
                          alertError('Data Integrity Check Failed');
                        }
                      });
                    }}
                  >
                    Data Integrity Check
                  </DrawerButton>
                </DrawerItem>
              )}

              {this.props.accessLevel === 'Technician' && (
                <DrawerItem>
                  <DrawerButton
                    onClick={async () => {
                      await this.props.onLoading(async () => {});
                    }}
                  >
                    Force Sync Data
                  </DrawerButton>
                </DrawerItem>
              )}

              {/* Download All App Data */}
              {this.props.accessLevel === 'Technician' && (
                <DrawerItem>
                  <DrawerButton
                    onClick={async () => {
                      const exportResult = await exportDB(true);
                      if (!exportResult) {
                        alertError('DB Export Failed');
                        return;
                      }
                      const [data, filename, type] = exportResult;
                      download(data, filename, type);
                    }}
                  >
                    Download All IndexedDB App Data
                  </DrawerButton>
                </DrawerItem>
              )}

              {/* Download All App Data */}
              {this.props.accessLevel === 'Technician' && (
                <DrawerItem>
                  <DrawerButton
                    onClick={async () => {
                      const exportResult = await exportDB(false);
                      if (!exportResult) {
                        alertError('DB Export Failed');
                        return;
                      }
                      const [data, filename, type] = exportResult;
                      download(data, filename, type);
                    }}
                  >
                    Download All In Memory App Data
                  </DrawerButton>
                </DrawerItem>
              )}

              {/* Upload All App Data */}
              {this.props.accessLevel === 'Technician' && (
                <DrawerItem>
                  <DrawerButton
                    onClick={async () => {
                      // browse for file and then import it
                      const input = document.createElement('input');
                      input.type = 'file';
                      input.onchange = (e) => {
                        const target = e.target as HTMLInputElement;
                        if (!target?.files?.length) {
                          return;
                        }

                        const file = target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.readAsText(file, 'UTF-8');
                          reader.onload = async (readerEvent) => {
                            const content = readerEvent.target?.result;
                            if (!content) {
                              alertError('Error reading file');
                              return;
                            }
                            // if file is ArrayBuffer, we should fail
                            if (content instanceof ArrayBuffer) {
                              alertError('Wrong file type');
                              return;
                            }
                            await importDB(content);
                          };
                        }
                      };
                      input.click();
                    }}
                  >
                    Upload All App Data
                  </DrawerButton>
                </DrawerItem>
              )}

              {/* Download Event Log */}
              {this.props.accessLevel === 'Technician' && (
                <DrawerItem>
                  <DrawerButton onClick={async () => await logger.download()}>Download Event Log</DrawerButton>
                </DrawerItem>
              )}

              {/* Event Log Upload */}
              {this.props.accessLevel === 'Technician' && !this.state.eventLogIsEnvironmentVariableDisabled && (
                <DrawerItem>
                  <DrawerButton onClick={async () => await this.toggleEventLogUploading()}>
                    {this.state.eventLogDisabled ? 'Enable' : 'Disable'} Event Log Upload
                  </DrawerButton>
                </DrawerItem>
              )}

              <ErrorBoundary>
                <div ref={(ref) => ref && this.props.updateAdditionalButtonsRef(ref)}></div>
              </ErrorBoundary>

              <ItemBreak style={{ paddingBottom: 0 }} />

              {/* App Debug Feature Table */}
              {this.props.accessLevel === 'Technician' && (
                <DrawerItem style={{ paddingBottom: 0 }}>
                  <MenuDrawerFeatureTable
                    showFeatureTable={this.state.showAppDebugTable}
                    setShowFeatureTable={(show) => this.setState({ showAppDebugTable: show })}
                  />
                </DrawerItem>
              )}

              {this.props.accessLevel === 'Technician' && (
                <DrawerItem style={{ paddingBottom: 0 }}>
                  <Button
                    onClick={() => {
                      console.log(EventBus.report());
                    }}
                  >
                    Event Bus Report
                  </Button>
                </DrawerItem>
              )}

              {/* Reset */}
              <DrawerItem style={{ paddingBottom: 0 }}>
                <ResetButton onLoading={this.props.onLoading} currentTask={this.state.currentTask}>
                  Reset App
                </ResetButton>
              </DrawerItem>
            </div>

            <ItemBreak style={{ paddingBottom: 0 }} />
            <DrawerItem>{`version: ${versionTs}`}</DrawerItem>
            <DrawerItem>host: {window.location.hostname}</DrawerItem>
            {this.props.accessLevel === 'Technician' && (
              <DrawerItem>Base ID: {import.meta.env.VITE_AIRTABLE_BASE_ID}</DrawerItem>
            )}
          </List>
        </DrawerSlider>

        {/* Dialogs Start */}
        {this.state.pdfUrl && (
          <Modal open={Boolean(this.state.pdfUrl)} style={{ zIndex: 1500, overflow: 'scroll' }}>
            <React.Fragment>
              <PDFViewer
                fileUrl={this.state.pdfUrl}
                // TODO we need to set this for real, does this not work?
                downloadName={'instructions.pdf'}
                onClose={() => this.setState({ pdfUrl: null })}
                onSuccess={() => alertSuccess('Print Job Sent Successfully - Check Printer Power and Paper')}
                onFailure={() => alertError('Print Job Failed to Send')}
              />
            </React.Fragment>
          </Modal>
        )}
        {/* Dialogs End */}
      </React.Fragment>
    );
  }
}
