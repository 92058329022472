function getZoneStyle(style_id, zone_color) {
  return `
    <Style id="${style_id}">
       <LineStyle>
          <color>ffffffff</color>
          <width>1</width>
       </LineStyle>
       <PolyStyle>
          <fill>1</fill>
          <color>9a${zone_color}</color>
       </PolyStyle>
    </Style>
  `;
}

export function getStyleText(zones) {
  const zoneStyles = zones.map((zone) => getZoneStyle(zone.id, zone.color)).join('');
  return `
    <Style id="field_boundary">
       <LineStyle>
          <color>ffff0000</color>
          <width>2</width>
       </LineStyle>
       <PolyStyle>
          <fill>1</fill>
          <color>00ff0000</color>
       </PolyStyle>
    </Style>
    <Style id="collected_field_boundary">
       <LineStyle>
          <color>ffffac00</color>
          <width>2</width>
       </LineStyle>
       <PolyStyle>
          <fill>1</fill>
          <color>00ffac00</color>
       </PolyStyle>
    </Style>
    <Style id="unsafe_zone">
       <LineStyle>
          <color>ffffffff</color>
          <width>1</width>
       </LineStyle>
       <PolyStyle>
          <fill>1</fill>
          <color>4d0000ff</color>
       </PolyStyle>
    </Style>
    <Style id="slow_zone">
       <LineStyle>
          <color>ffffffff</color>
          <width>1</width>
       </LineStyle>
       <PolyStyle>
          <fill>1</fill>
          <color>4d00ffff</color>
       </PolyStyle>
    </Style>
    <Style id="generic_zone">
       <LineStyle>
          <color>ffffffff</color>
          <width>1</width>
       </LineStyle>
       <PolyStyle>
          <fill>1</fill>
          <color>4d00ff00</color>
       </PolyStyle>
    </Style>
    ${zoneStyles}
    <Style id="navigation_path">
       <LineStyle>
          <color>ffffffff</color>
          <width>1</width>
       </LineStyle>
    </Style>
    <Style id="core_normal">
       <IconStyle>
          <scale>1.0</scale>
          <Icon>
             <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
          </Icon>
          <hotSpot x="20" y="2" xunits="pixels" yunits="pixels" />
       </IconStyle>
       <LabelStyle>
          <color>00ffffff</color>
       </LabelStyle>
    </Style>
    <Style id="core_highlight">
       <IconStyle>
          <scale>1.1</scale>
          <Icon>
             <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
          </Icon>
          <hotSpot x="20" y="2" xunits="pixels" yunits="pixels" />
       </IconStyle>
    </Style>
        <Style id="taken_core_normal">
       <IconStyle>
          <scale>1.0</scale>
          <Icon>
             <href>http://maps.google.com/mapfiles/kml/pushpin/blue-pushpin.png</href>
          </Icon>
          <hotSpot x="20" y="2" xunits="pixels" yunits="pixels" />
       </IconStyle>
       <LabelStyle>
          <color>00ffffff</color>
       </LabelStyle>
    </Style>
    <Style id="taken_core_highlight">
       <IconStyle>
          <scale>1.1</scale>
          <Icon>
             <href>http://maps.google.com/mapfiles/kml/pushpin/blue-pushpin.png</href>
          </Icon>
          <hotSpot x="20" y="2" xunits="pixels" yunits="pixels" />
       </IconStyle>
    </Style>
    <Style id="centroid_normal">
       <IconStyle>
          <scale>1.0</scale>
          <Icon>
             <href>http://maps.google.com/mapfiles/kml/shapes/placemark_circle.png</href>
          </Icon>
       </IconStyle>
    </Style>
    <Style id="centroid_highlight">
       <IconStyle>
          <scale>1.1</scale>
          <Icon>
             <href>http://maps.google.com/mapfiles/kml/shapes/placemark_circle_highlight.png</href>
          </Icon>
       </IconStyle>
    </Style>
    <StyleMap id="core_location">
       <Pair>
          <key>normal</key>
          <styleUrl>${zones.length > 0 ? '#core_highlight' : '#core_normal'}</styleUrl>
       </Pair>
       <Pair>
          <key>highlight</key>
          <styleUrl>#core_highlight</styleUrl>
       </Pair>
    </StyleMap>
    <StyleMap id="taken_core_location">
       <Pair>
          <key>normal</key>
          <styleUrl>${zones.length > 0 ? '#taken_core_highlight' : '#taken_core_normal'}</styleUrl>
       </Pair>
       <Pair>
          <key>highlight</key>
          <styleUrl>#taken_core_highlight</styleUrl>
       </Pair>
    </StyleMap>
    <StyleMap id="centroid">
       <Pair>
          <key>normal</key>
          <styleUrl>#centroid_normal</styleUrl>
       </Pair>
       <Pair>
          <key>highlight</key>
          <styleUrl>#centroid_highlight</styleUrl>
       </Pair>
    </StyleMap>
  `;
}
