import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Waypoint, { WaypointList } from './WaypointClass';
import SoilCore, { SoilCoreList } from './SoilCoreClass';
import { ICorePoint } from './types';

export default class CorePoint extends ObjectClassGenerator<CorePoint>('CorePoint') implements ICorePoint {
  // relationships
  #Waypoint_id?: number;
  #SoilCore_id?: number;

  static tableName = 'CorePoint';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(CorePoint, 'Waypoint_id');
    this.publishAttribute(CorePoint, 'SoilCore_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<CorePoint> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#Waypoint_id = state.Waypoint_id;
    this.#SoilCore_id = state.SoilCore_id;
  }

  dispose() {
    const waypoint = this.getWaypoint();
    if (waypoint) {
      // The order of the following 2 calls is important: 
      // first "detach" a waypoint from the core point, then
      // dispose of a waypoint. Otherwise, errors occur because
      // waypoint.dispose() tries to dispose of a core point.
      this.Waypoint_id = undefined;
      waypoint.dispose();
    }

    const soilcore = this.getSoilCore();
    if (soilcore) {
      this.SoilCore_id = undefined;
    }

    CorePoint.delete(this.instance_id);
  }

  get mission_name() {
    return this.getWaypoint()?.mission_name;
  }

  get last_modified() {
    return this.getWaypoint()?.last_modified;
  }

  set last_modified(value) {
    const inst = this.getWaypoint();
    if (inst && value) {
      inst.last_modified = value;
    }
  }

  get waypoint_number() {
    return this.getWaypoint()?.waypoint_number;
  }

  get sample_id() {
    return this.getSoilCore()?.sample_id;
  }

  get sample_type() {
    return this.getSoilCore()?.sample_type;
  }

  get core_number() {
    return this.getSoilCore()?.core_number;
  }

  set Waypoint_id(value) {
    if (this.#Waypoint_id) {
      const relateObj = Waypoint.get(this.#Waypoint_id);
      if (relateObj) {
        relateObj.removeRelationshipData('CorePoint', this.instance_id);
      }
    }
    this.#Waypoint_id = value;
    if (value) {
      const relateObj = Waypoint.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('CorePoint', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Waypoint_id() {
    return this.#Waypoint_id;
  }

  getWaypoint() {
    return Waypoint.get(this.Waypoint_id);
  }

  set SoilCore_id(value) {
    if (this.#SoilCore_id) {
      const relateObj = SoilCore.get(this.#SoilCore_id);
      if (relateObj) {
        relateObj.removeRelationshipData('CorePoint', this.instance_id);
      }
    }
    this.#SoilCore_id = value;
    if (value) {
      const relateObj = SoilCore.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('CorePoint', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get SoilCore_id() {
    return this.#SoilCore_id;
  }

  getSoilCore() {
    return SoilCore.get(this.SoilCore_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = CorePoint.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.mission_name === this.mission_name &&
        sel.waypoint_number === this.waypoint_number &&
        sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate core point found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`core point: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getWaypoint()) {
      problems.push(
        `core point: Could not find waypoint instance across unconditional relationship R12: ${this.instance_id}`,
      );
    }
    if (!this.getSoilCore()) {
      problems.push(
        `core point: Could not find soil core instance across unconditional relationship R23: ${this.instance_id}`,
      );
    }
    return problems;
  }
}

export class CorePointList extends DataObjectList<CorePoint> {
  getWaypoints() {
    return new WaypointList(...this.map((corepoint) => corepoint.getWaypoint()).filter((sel) => !!sel));
  }

  getSoilCores() {
    return new SoilCoreList(...this.map((corepoint) => corepoint.getSoilCore()).filter((sel) => !!sel));
  }
}
