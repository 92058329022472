import React, { PureComponent } from 'react';

import MapButton from './MapButton';

import { AiOutlinePlayCircle } from 'react-icons/ai';

import {
  Button,
  List,
  Popper,
  Paper,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { RiArrowDropDownLine } from 'react-icons/ri';

import { ZoneRecording } from '../../../db';
import { setStateAsync, getUserPosition, updateState, SetStateAsync } from '../../../utils';
import { alertSuccess, alertError } from '../../../alertDispatcher';
import { getCurrentMission, getCurrentSession } from '../../../dataModelHelpers';
import { dispatchMissionUpdated } from '../../../missionEvents';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state/index';
import { onRobotMessage, removeRobotMessageCallback } from '../../../EventBus';
import { simplifyPolygon } from '../../../simplify';
import { RecordingCoords, RecordingDevice, RobotConnectionStatus } from '../../../types/types';
import { ZONE_RECORDING_TYPE, ZoneRecordingType, ZoneType } from '../../../db/ZoneTypesDatatype';
import { Coordinate } from 'ol/coordinate';
import { CREATE_NEW_POLYGON } from '../../../constants';
import { MdPause, MdPlayArrow, MdSave } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { KML_FEATURE_TYPE } from '../../../featureTypes';
import { RosPosition } from '../../../types/rosmsg';

interface RecordZoneButtonProps {
  updateRecordingCoords: (recordingCoords: RecordingCoords) => void;
  recordingCoords: RecordingCoords;
  connectionStatus: RobotConnectionStatus;
}

interface RecordZoneButtonState {
  anchorEl: HTMLElement | null;
  containerOpen: boolean;
  currentRecordingId: number | undefined;
  zones: {
    [key: number]: {
      type: ZoneRecordingType;
      device: RecordingDevice;
    };
  };
  zoneType: ZoneRecordingType;
}

export default class RecordZoneButton extends PureComponent<RecordZoneButtonProps, RecordZoneButtonState> {
  SERIALIZABLE_STATE_VARIABLES: (keyof RecordZoneButtonState)[] = ['containerOpen'];
  tabletRecordTimeout: ReturnType<typeof setTimeout>;
  setStateAsync: SetStateAsync;

  constructor(props: RecordZoneButtonProps) {
    super(props);

    this.state = {
      anchorEl: null,
      containerOpen: false,
      zoneType: ZONE_RECORDING_TYPE.COLLECTED_FIELD,
      zones: {},
      currentRecordingId: undefined,
    };

    this.toggleContainer = this.toggleContainer.bind(this);
    this.handleRobotPos = this.handleRobotPos.bind(this);
    this.selectZoneType = this.selectZoneType.bind(this);
    this.toggleRecord = this.toggleRecord.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.applyZone = this.applyZone.bind(this);
    this.continueZone = this.continueZone.bind(this);
    this.watchTabletLocation = this.watchTabletLocation.bind(this);

    this.setStateAsync = setStateAsync.bind(this);
  }

  async componentDidMount() {
    this.deserializeState();
    this.initZones();

    const anchorEl = document.getElementById('recordZoneToggle');
    this.setState({ anchorEl });
  }

  componentWillUnmount() {
    removeRobotMessageCallback('position', this.handleRobotPos);

    clearTimeout(this.tabletRecordTimeout);

    this.serializeState();
  }

  /**
   * serialize the state into session
   */
  serializeState() {
    const serializedState = {};
    for (const stateKey of this.SERIALIZABLE_STATE_VARIABLES) {
      serializedState[stateKey] = this.state[stateKey];
    }
    localStorage.setItem('RecordZoneButtonState', JSON.stringify(serializedState));
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const serializedState = JSON.parse(localStorage.getItem('RecordZoneButtonState') ?? '{}');
    if (serializedState) {
      for (const stateKey of Object.keys(serializedState)) {
        //this.setState({ [stateKey]: serializedState[stateKey] });
        this.setState(updateState(stateKey as keyof RecordZoneButtonState, serializedState[stateKey]));
      }
    }
  }

  initZones() {
    const mission = getCurrentMission();
    const allZones = mission?.getZoneRecordings() ?? [];
    const zones = {};

    allZones.forEach((zoneRecording) => {
      zones[zoneRecording.instance_id] = {
        type: zoneRecording.type,
        device: zoneRecording.device,
      };
    });

    this.setState({ zones });
  }

  async watchTabletLocation() {
    const currentRecording = ZoneRecording.get(this.state.currentRecordingId);
    const position = await getUserPosition({ enableHighAccuracy: true, maximumAge: Infinity });
    if (!currentRecording) {
      return;
    }
    const coordinates = currentRecording.coordinates.slice();
    coordinates.push([position[0], position[1]]);
    currentRecording.coordinates = coordinates.slice();
    this.props.updateRecordingCoords({
      id: currentRecording.instance_id,
      coords: currentRecording.coordinates.slice(),
    });

    if (this.state.currentRecordingId !== undefined) {
      this.tabletRecordTimeout = setTimeout(this.watchTabletLocation, 1000);
    }
  }

  async handleRobotPos({ hostname, msg }: { hostname: string; msg: RosPosition }) {
    const session = getCurrentSession();
    if (hostname === session?.robot_hostname) {
      const coordinates: Coordinate = [msg.x, msg.y];
      if (!this.state.currentRecordingId) {
        return;
      }
      const recording_zone = ZoneRecording.get(this.state.currentRecordingId);
      if (!recording_zone) {
        return;
      }
      const zone_coordinates = recording_zone.coordinates.slice();
      zone_coordinates.push(coordinates);
      recording_zone.coordinates = zone_coordinates;
      this.props.updateRecordingCoords({ id: recording_zone.instance_id, coords: zone_coordinates });
    }
  }

  addZone(recording: ZoneRecording) {
    const zones = { ...this.state.zones };
    zones[recording.instance_id] = {
      type: recording.type,
      device: recording.device,
    };
    return zones;
  }

  removeZone(recording: ZoneRecording) {
    const zones = { ...this.state.zones };
    delete zones[recording.instance_id];

    return zones;
  }

  async toggleRecord() {
    const mission = getCurrentMission();
    if (!mission) {
      alertError('No mission loaded');
      return;
    }
    if (this.state.currentRecordingId === undefined) {
      this.props.updateRecordingCoords({ id: undefined, coords: [] });

      const currentRecording = await ZoneRecording.createRecording(this.state.zoneType, mission.instance_id);

      currentRecording.device = this.props.connectionStatus === 'connected' ? 'robot' : 'tablet';

      const zones = this.addZone(currentRecording);

      this.setState({ currentRecordingId: currentRecording.instance_id, zones });

      if (this.props.connectionStatus === 'connected') {
        onRobotMessage('position', this.handleRobotPos);
      } else {
        await this.watchTabletLocation();
        currentRecording.device = 'tablet';
      }
    } else {
      clearTimeout(this.tabletRecordTimeout);
      removeRobotMessageCallback('position', this.handleRobotPos);
      const recordingZone = ZoneRecording.get(this.state.currentRecordingId);
      if (!recordingZone) {
        return;
      }
      recordingZone.coordinates = simplifyPolygon(recordingZone.coordinates, 0.01, false);
      this.props.updateRecordingCoords({ id: recordingZone.instance_id, coords: recordingZone.coordinates });
      this.setState({ currentRecordingId: undefined });
    }
  }

  async continueZone(zoneId: number) {
    await this.setStateAsync({ currentRecordingId: zoneId });
    if (this.props.connectionStatus === 'connected') {
      onRobotMessage('position', this.handleRobotPos);
    } else {
      this.watchTabletLocation();
    }
  }

  async applyZone(zoneId: number) {
    const mission = getCurrentMission();
    const recordingZone = ZoneRecording.get(zoneId);
    if (!recordingZone) {
      alertError('Zone not found');
      return;
    }
    let coords = recordingZone.coordinates.slice(); // zone recording arr copy
    const coordsMap: Array<string> = coords.map((coord) => JSON.stringify(coord));
    const coordsSet: Set<string> = new Set(coordsMap);
    const coordsArray = [...coordsSet].map((x) => JSON.parse(x));
    if (coordsArray.length) {
      coordsArray.push(coordsArray[0].slice()); // push first coord in array to guarentee a polygon
      if (coordsArray.length > 4) {
        if (mission?.getJob()?.plot_mission && recordingZone.type === ZONE_RECORDING_TYPE.COLLECTED_FIELD) {
          // get field
          const originalField = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);
          if (!originalField) {
            alertError('Field not found');
            return;
          }
          const poly_id = originalField.getOuterZoneBoundarys()[0].poly_id;
          await mission.update_boundary(poly_id, KML_FEATURE_TYPE.FIELD, [coordsArray]);
        } else {
          await mission?.update_boundary(CREATE_NEW_POLYGON, recordingZone.type, [coordsArray]);
        }
        if (this.props.recordingCoords.id === recordingZone.instance_id) {
          this.props.updateRecordingCoords({ id: undefined, coords: [] });
        }

        recordingZone.dispose();

        const zones = this.removeZone(recordingZone);

        this.setState({ zones });

        alertSuccess('Successfully applied zone');

        dispatchMissionUpdated();
      } else {
        alertError(`Boundary does not has enough points(minimum of 4)`);
      }
    } else {
      alertError('Boundary has no points!');
    }
  }

  async deleteZone(zoneId: number) {
    const zoneToDelete = ZoneRecording.get(zoneId);
    if (!zoneToDelete) {
      alertError('Zone not found');
      return;
    }
    if (this.props.recordingCoords.id === zoneToDelete.instance_id) {
      this.props.updateRecordingCoords({ id: undefined, coords: [] });
    }
    zoneToDelete.dispose();
    const zones = this.removeZone(zoneToDelete);
    this.setState({ zones });
  }

  toggleContainer(event) {
    this.setState({ containerOpen: !this.state.containerOpen });
  }

  selectZoneType(popupState, zoneType: ZoneRecordingType) {
    popupState.close();
    this.setState({ zoneType });
  }

  render() {
    return (
      <React.Fragment>
        <MapButton
          tooltip="Record Boundary"
          onClick={this.toggleContainer}
          toggled={this.state.containerOpen}
          id={'recordZoneToggle'}
        >
          <AiOutlinePlayCircle size={22} />
        </MapButton>
        <Popper anchorEl={this.state.anchorEl} open={this.state.containerOpen} style={{ zIndex: 2 }}>
          <Paper style={{ width: 550 }}>
            <Button
              variant="outlined"
              onClick={this.toggleRecord}
              size={'medium'}
              style={{ textTransform: 'capitalize', marginLeft: 10, marginTop: 10 }}
            >
              {this.state.currentRecordingId === undefined ? 'Start New Recording' : 'Stop Recording'}
            </Button>
            <PopupState variant="popover">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    disabled={this.state.currentRecordingId !== undefined}
                    style={{ textTransform: 'capitalize', marginTop: 10 }}
                    {...bindTrigger(popupState)}
                    size={'medium'}
                  >
                    {this.state.zoneType === ZONE_RECORDING_TYPE.COLLECTED_FIELD ? 'collected' : this.state.zoneType}
                    <RiArrowDropDownLine />
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      value={'collected field'}
                      onClick={() => this.selectZoneType(popupState, ZONE_RECORDING_TYPE.COLLECTED_FIELD)}
                    >
                      Collected
                    </MenuItem>
                    <MenuItem
                      value={'unsafe'}
                      onClick={() => this.selectZoneType(popupState, ZONE_RECORDING_TYPE.UNSAFE)}
                    >
                      Unsafe
                    </MenuItem>
                    <MenuItem value={'slow'} onClick={() => this.selectZoneType(popupState, ZONE_RECORDING_TYPE.SLOW)}>
                      Slow
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
            <IconButton
              onClick={this.toggleContainer}
              style={{ color: 'black', position: 'absolute', right: 10, top: 5 }}
              size={'small'}
            >
              <CloseIcon fontSize={'medium'} />
            </IconButton>
            <List dense>
              <Divider />
              {Object.keys(this.state.zones).length ? (
                Object.keys(this.state.zones).map((zoneId) => {
                  return (
                    <React.Fragment>
                      <ListItem>
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                              Type: {this.state.zones[zoneId].type}
                              {'\n'}
                            </Typography>
                            <Typography variant="body1" style={{ textTransform: 'capitalize' }}>
                              Source: {this.state.zones[zoneId].device}
                            </Typography>
                          </Grid>
                          <Grid item xs={8} style={{ textAlign: 'end' }}>
                            <Button
                              style={{ textTransform: 'none' }}
                              size={'large'}
                              color={'secondary'}
                              // disabled={this.state.currentRecordingId !== undefined}
                              onClick={async () => {
                                if (this.state.currentRecordingId !== undefined) {
                                  await this.toggleRecord();
                                } else {
                                  await this.continueZone(parseInt(zoneId));
                                }
                              }}
                            >
                              {this.state.currentRecordingId !== undefined ? (
                                <>
                                  <MdPause size={40} />
                                  Pause
                                </>
                              ) : (
                                <>
                                  <MdPlayArrow size={40} />
                                  Continue
                                </>
                              )}
                            </Button>
                            <Button
                              style={{
                                textTransform: 'none',
                                color: this.state.currentRecordingId === undefined ? 'rgb(29, 120, 29)' : '',
                              }}
                              size={'large'}
                              color={'secondary'}
                              disabled={this.state.currentRecordingId !== undefined}
                              onClick={() => this.applyZone(parseInt(zoneId))}
                            >
                              <MdSave size={40} />
                              Save
                            </Button>
                            <Button
                              style={{
                                textTransform: 'none',
                                color: this.state.currentRecordingId === undefined ? '#cc0000' : '',
                              }}
                              size={'large'}
                              disabled={this.state.currentRecordingId !== undefined}
                              onClick={() => this.deleteZone(parseInt(zoneId))}
                            >
                              <IoMdTrash size={40} />
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })
              ) : (
                <ListItem style={{ justifyContent: 'center', alignContent: 'center', paddingTop: 10 }}>
                  <Typography variant="caption" style={{ fontStyle: 'italic' }}>
                    No active recordings
                  </Typography>
                </ListItem>
              )}
            </List>
          </Paper>
        </Popper>
      </React.Fragment>
    );
  }
}
