import React, { PureComponent } from 'react';

import { Button } from '@material-ui/core';

import { getCurrentSession } from '../../../dataModelHelpers';
import { kmz2kml } from '../../../utils';
import { alertError } from '../../../alertDispatcher';
import { OnLoading } from '../../../types/types';

interface UploadKmlButtonProps {
  text?: string;
  onLoading: OnLoading;
  sampling: boolean;
}

export default class UploadKmlButton extends PureComponent<UploadKmlButtonProps> {
  //fileInput: React.RefObject<unknown>;
  fileInput: React.LegacyRef<HTMLInputElement>;
  reader: FileReader;

  constructor(props: UploadKmlButtonProps) {
    super(props);

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileReadKml = this.handleFileReadKml.bind(this);
    this.handleFileReadKmz = this.handleFileReadKmz.bind(this);
    this.handleLoadKml = this.handleLoadKml.bind(this);
    this.fileInput = React.createRef();
    this.reader = new FileReader();
  }

  async handleFileReadKmz(uploadFile: File) {
    this.handleLoadKml(await kmz2kml(uploadFile));
  }

  async handleFileReadKml() {
    const result = this.reader.result;
    const isString = (data: String | ArrayBuffer | null): data is string => {
      return typeof result === 'string';
    };
    console.log(result);
    console.log(typeof result);
    if (!isString(result)) {
      alertError(`Error: file is NOT the right format`);
      return;
    }
    await this.handleLoadKml(result);
  }

  async handleLoadKml(text: string) {
    const session = getCurrentSession();
    if (!session) {
      alertError('No session available');
      return;
    }
    await this.props.onLoading(() => session.loadKml(text, undefined, { sampling: this.props.sampling }));
  }

  async handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const uploadFile = event.target.files![0];
    if (uploadFile.name.endsWith('kml')) {
      this.reader.onloadend = this.handleFileReadKml;
      this.reader.readAsText(uploadFile);
    } else if (uploadFile.name.endsWith('kmz')) {
      await this.handleFileReadKmz(uploadFile);
    }
  }

  render() {
    return (
      <Button variant="outlined" component="label">
        Browse...
        <input
          type="file"
          accept=".kml,.kmz"
          style={{ display: 'none' }}
          ref={this.fileInput}
          onChange={this.handleFileUpload}
          multiple={false}
          data-testid="mission-upload-browse"
        />
      </Button>
    );
  }
}
