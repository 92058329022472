import type { Action, ThunkAction, UnknownAction } from '@reduxjs/toolkit';
import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import { boxesSlice } from './features/boxes/boxesSlice';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';
import { createMemoryStorage } from '../__test__/memory-storage';

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const combinedReducer = combineSlices(boxesSlice);

type CombinedState = Parameters<typeof combinedReducer>[0];

const rootReducer = (state: CombinedState, action: UnknownAction) => {
  if (action.type === 'RESET_APP') {
    state = undefined; // wipes the whole state, will re-initialize to each slice's initialState
  }

  return combinedReducer(state, action);
};

// Persist store to IndexedDB using localForage
localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'rogo-mc',
  version: 1.0,
  storeName: 'mc_data',
});

// Use simple memory storage in tests
const persistStorage = import.meta.env.MODE === 'test' ? createMemoryStorage() : localForage;

const persistConfig = {
  key: 'root',
  storage: persistStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof persistedReducer>;

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: RootState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false, // because localforage is async and non-serializable
      });
    },
    preloadedState,
    // It is not clear, how big the performance impact is (when devTools are on). Since we are now trying to catch the box-loss bug,
    // we keep the devTools enabled in production. At least, we know that the impact is not big if the actual extension is not working.
    // Also, we know of sites that use the devTools in production:
    // https://astexplorer.net/
    // https://github.com/Automattic/wp-calypso/blob/323ae9b74c668b505df0a3167e1ed8343cc05621/client/state/index.js#L136
    // https://www.todoist.com/
    // https://www.producthunt.com/
    // 
    // TODO: consider turning them off in the future - when the state grows
    // and when we migrate to using redux as the main source of truth - or optimize the settings.
    // Good reads: 
    // https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f
    // https://devhunt.org/blog/redux-dev-tools-best-practices
    // https://github.com/reduxjs/redux-devtools/blob/main/extension/docs/API/Arguments.md
    // 
    // To turn them off for PROD: devTools: !import.meta.env.PROD
    devTools: {
      maxAge: 25,
    },
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(Sentry.createReduxEnhancer());
    },
  });
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch);

  return store;
};

export const store = makeStore();
export const persistor = persistStore(store);

export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, Action>;

// We export this becuase our app has lots of logic outside of React components
// that needs to dispatch actions. Do not use it in React components.
// Use the `useAppDispatch` hook instead.
export const storeDispatch: AppDispatch = store.dispatch;

export const purgeStore = async () => {
  console.warn('Purging redux store!');

  try {
    store.dispatch({ type: 'RESET_APP' });
    await persistor.flush();
    await persistor.purge();
  } catch (e) {
    console.error('Failed to reset redux store', e);
  }
};
