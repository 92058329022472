import React from 'react';
import { layer, source } from 'react-openlayers';

interface Props {
  points: React.JSX.Element[];
}

export function GenericPointsLayer(props: Props) {
  return (
    // @ts-ignore
    <layer.Vector zIndex={5}>
      {/* 
            // @ts-ignore */}
      <source.VectorSourceReact>{props.points}</source.VectorSourceReact>
    </layer.Vector>
  );
}
