import { AirtableRecord, Mission, SampleBox } from '../db';
import { BOX_ID_CHARSET, EPOCH } from '../constants';
import logger from '../logger';
import { getVersionNumber } from '../version';
import { genPdfBoxManifest, genPdfCheckin3 } from '../pdfcheckin';
import { dispatchActiveBoxUpdated, dispatchUpdatedSamples } from '../boxEvents';
import { getCurrentSession } from '../dataModelHelpers';
import Airtable from '../airtable';
import hash from 'object-hash';
import { Content, ContentStack, TDocumentDefinitions } from 'pdfmake/interfaces';
import { PRINTER_CONFIG } from '../components/robot/configs/PrinterConfig';
import { BoxJobSamples } from '../types/types';
import S3 from '../aws';
import { isRecoveryServerAlive, postRecoveryFile } from '../recoveryServerUtils';
import { FieldSet } from 'airtable';
import { Box } from '@rogoag/airtable';
import boxCreator from '../services/BoxCreator';

// produces an 8 character alphanumeric string
export function encode(userId: number, timestamp: number, labCode: number) {
  // TODO add warnings for any values over the specified limits
  // combine the user and timestamp to a 50 bit string
  // using our custom base32 encoding, 50 bits = 10 characters
  //
  // userId = 11 bits = [0..2047]
  // timestamp = 30 bits =
  // labCode = 9 bits = [0..511]
  // 0        1        2        3        4        5        6
  // UUUUUUUU UUULLLLL LLLLTTTT TTTTTTTT TTTTTTTT TTTTTTTT TT??????
  // TTTTTTTT TTTTTTTT TTTTTTTT TTTTTTLL LLLLLLLU UUUUUUUU UU?????? (reversed bits)
  //
  // 0     1     2     3     4     5     6     7     8     9
  // TTTTT TTTTT TTTTT TTTTT TTTTT TTTTT LLLLL LLLLU UUUUU UUUUU (base 5)

  // we will make this 10 bits so we have headroom for user IDs
  const _userId = userId.toString(2).padStart(11, '0');
  const _timestamp = timestamp.toString(2).padStart(30, '0');
  const _labCode = labCode.toString(2).padStart(9, '0');
  const bits = _userId + _labCode + _timestamp;

  // We reverse the ID so that the front of the box ID changes regularly
  // this avoids confusion between two boxes because it helps boxes "appear"
  // more unique
  const bits_reversed = bits.split('').reverse().join('');

  // build an ID from 5 bit chunks
  let id = '';
  for (let i = 0; i < bits_reversed.length / 5; i++) {
    const char_index = parseInt(bits_reversed.slice(i * 5, (i + 1) * 5), 2);
    id += BOX_ID_CHARSET.charAt(char_index);
  }

  return id;
}

export function decodeBoxUID(uid: string) {
  let bits = '';
  for (let i = 0; i < uid.length; i++) {
    const value = BOX_ID_CHARSET.indexOf(uid[i]);
    bits += value.toString(2).padStart(5, '0');
  }

  const bits_reversed = bits.split('').reverse().join('');

  const userId = parseInt(bits_reversed.substring(0, 11), 2);
  const labCode = parseInt(bits_reversed.substring(11, 20), 2);
  const timestamp = new Date((parseInt(bits_reversed.substring(20, 50), 2) + EPOCH) * 1000);

  return { userId, labCode, timestamp };
}

/**
 * Generate a PDF for this box
 * @param {string} logo
 * @param config
 * @returns
 */
export function toPdf(this: SampleBox, logoPath: string, upsLogoPath: string, config: PRINTER_CONFIG) {
  const boxMissions = this.getSampleBoxMissions();

  if (boxMissions.length === 0) {
    throw new Error('No missions found for box');
  }

  const pdf: TDocumentDefinitions = {
    info: { title: `Box_${this.uid}.pdf` },
    content: [] as Content[],
    pageSize: config.pageSize,
    defaultStyle: config.defaultStyle,
    pageOrientation: 'landscape',
    pageMargins: 0,
    footer: undefined,
  };

  const manifest = genPdfBoxManifest(boxMissions, logoPath, upsLogoPath, this, config);
  (pdf.content as Content[]).push({
    stack: [manifest],
    fontSize: config.manifest.font_size,
    pageOrientation: 'portrait',
    margin: config.manifest.margin,
  } as ContentStack);

  const checkin = genPdfCheckin3(
    boxMissions,
    logoPath,
    boxMissions[0].getJob()?.sample_order_type || '',
    this.uid,
    this.getSamples(),
    config,
  );

  (pdf.content as Content[]).push({
    stack: checkin,
    fontSize: config.lab.font_size,
    margin: config.lab.margin,
  } as ContentStack);

  pdf.footer = function (currentPage, pageCount) {
    if (currentPage > 1) {
      return [
        {
          columns: [
            [`${currentPage - 1} / ${pageCount - 1}`],
            [
              {
                text: getVersionNumber(),
                alignment: 'right',
              },
            ],
          ],
          margin: [30, 0, 30, 0],
        },
      ];
    }
  };
  return pdf;
}

/**
 * Get all missions that are part of this box
 * @returns {Mission[]}
 */
export function getSampleBoxMissions(this: SampleBox) {
  const uniqueMissions: Mission[] = [];
  const allMissions = this.getSamples().getSampleSites().getMissions();
  for (let mission of allMissions) {
    if (!uniqueMissions.find((uniqueMission) => uniqueMission.instance_id === mission.instance_id)) {
      uniqueMissions.push(mission);
    }
  }

  return uniqueMissions;
}

/**
 * @param {SampleBox[]} boxes
 */
export async function recoverBoxes(boxes: SampleBox[]) {
  await logger.log('RECOVER_BOXES', `${boxes.length} boxes to recover: ${boxes.map((box) => box.uid).join(', ')}`);

  const session = getCurrentSession();
  //const session await Session.findOne((session) => !!session);
  for (const box of boxes) {
    const boxExisting = SampleBox.query((sampleBox) => sampleBox.uid === box.uid);
    if (!boxExisting.length) {
      const { userId, labCode } = decodeBoxUID(box.uid);
      const username = box.username || session?.username || 'unknown';

      const newBox = boxCreator.create({
        labName: box.labName,
        labCode: labCode.toString(),
        uid: box.uid,
        closedTimestamp: box.closedTimestamp,
        username: username,
        userId: userId,
        boxDailyCountNumber: box.dailyBoxCountIndex || undefined,
        jobBoxCountIndex: box.jobBoxCountIndex || undefined,
        trackingNumber: box.trackingNumber || '',
      });

      await logger.log('RECOVER_BOXES', `created box with uid ${box.uid}, instance_id ${newBox.instance_id}`);

      // if the box has a valid session ID, isn't closed, and the session is active
      // then make it the active box
      if (session && box.Session_id && !box.closed) {
        newBox.Session_id = session.instance_id;
      }
    } else {
      await logger.log('RECOVER_BOXES', `existing box with uid ${box.uid} found`);
      // TODO if the box exists, should we make sure we dont need to merge any data?
    }
  }
  dispatchActiveBoxUpdated();
}

export function calculateBoxChecksum(this: SampleBox) {
  return hash.sha1({
    labName: this.labName,
    uid: this.uid,
    // by just getting all bag IDs and consistently sorting them
    // we should be able to make a reliable/repeatable object hash
    sampleIds: this.getSamples()
      .map((sample) => sample.bag_id)
      .sort(),
    closed: this.closedTimestamp,
  });
}

/**
 * Clear samples from a box for a given mission
 * @param {number} missionId Instance id of the mission to remove
 */
export async function clearSamples(this: SampleBox, missionId) {
  const samples = this.getSamples().filter((sample) => sample.getSampleSite().getMission()?.instance_id === missionId);
  for (let sample of samples) {
    sample.bag_id = '';
    // TODO should we be resetting the Session refs here?
    sample.SampleBox_id = undefined;
  }
  dispatchUpdatedSamples();
  this.needsUpdated = true;
}

export async function clearAllSamples(this: SampleBox) {
  const samples = this.getSamples();
  for (let sample of samples) {
    sample.bag_id = '';
    // TODO should we be resetting the Session refs here?
    sample.SampleBox_id = undefined;
  }
  dispatchUpdatedSamples();
  this.needsUpdated = true;
}

export async function uploadToS3(this: SampleBox) {
  const filename = `${this.uid}_box.json`;
  const boxJSON = JSON.stringify([this]);

  let uploadResult = false;
  try {
    // upload JSON file directly to S3
    const checksum = await S3.upload(filename, new Blob([boxJSON], { type: 'plain/text' }), 'application/json', {
      bucket: 'missionuploads',
      acl: '',
    });

    // TODO validate checksum
    uploadResult = !!checksum;
  } catch (error) {
    await logger.log('UPLOAD_BOX', `Unable to upload box directly to S3: ${error}`);
  }

  if (!uploadResult) {
    await logger.log('UPLOAD_BOX', 'Attempting offline sync');
    // do offline upload?

    try {
      if (!(await isRecoveryServerAlive())) {
        await logger.log('UPLOAD_BOX', 'Error! Recovery server is not alive');
      }

      // TODO since we are doing a pre-flight check, we can probably remove
      uploadResult = await postRecoveryFile(boxJSON, filename, { uploadToAWS: true });
    } catch (error) {
      await logger.log('UPLOAD_BOX', `Unable to post to recovery server: ${error}`);
    }
  }

  if (uploadResult) {
    this.needsUpdated = false;
  }
  return uploadResult;
}

export async function upload(this: SampleBox) {
  const session = getCurrentSession();

  // Get job ids based on sample sites
  const jobs = this.getSamples()
    .getSampleSites()
    .getMissions()
    .map((mission) => mission.job_id)
    .filter((sel) => sel !== undefined);

  // Get just unique job IDs...
  const uniqueJobs = [...new Set(jobs)];

  await logger.log('UPLOAD_BOX', `box id ${this.uid}, jobs = ${jobs}, unique jobs = ${uniqueJobs}`);
  const newSamplesForBox: BoxJobSamples = {};
  for (let sample of this.getSamples()) {
    const sampleMission = sample.getSampleSite().getMission();
    const sampleJob = sampleMission?.getJob();
    if (!sampleMission?.job_id) {
      continue;
    }
    if (!newSamplesForBox[sampleMission.job_id]) {
      newSamplesForBox[sampleMission.job_id] = {
        jobInfo: {
          field: sampleJob?.field || '(No Field Name)',
        },
        samples: [],
      };
    }
    newSamplesForBox[sampleMission.job_id].samples.push({
      sample_id: sample.sample_id,
      bag_id: sample.bag_id || '',
      pulled_at: sample.pulled_at,
      order: sample.order,
    });
  }

  let localRecord = true;

  let existingRecord = AirtableRecord.findOne<AirtableRecord<Box>>(
    (sel) => sel?.table === 'Box' && sel.get('Box ID') === this.uid,
  );
  if (!existingRecord) {
    localRecord = false;
    await logger.log('UPLOAD_BOX', 'Box does not exist locally, check on AirTable');
    const recordSearch = await Airtable.search<Box>(
      'Box',
      'App View - Boxes Ready for Shipping',
      `{Box ID} = '${this.uid}'`,
    );
    if (!recordSearch) {
      throw new Error('Error searching for box in AirTable');
    }
    await recordSearch.refresh();
    const recordResult = recordSearch.getRecords();
    const recordExists = !!recordResult.length;
    if (recordExists) {
      existingRecord = recordResult[0];
    }
  }

  // There is likely some weirdness going on below, and for the time being, I will blame
  // that on the auto generated xtuml classes used above and potential synchronization issues
  // therein. As such, we will try to gingerly change boxes at this stage because by now we're
  // going to be finishing a job or shipping and
  if (existingRecord) {
    await logger.log('UPLOAD', `Box exists, update (localRecord=${localRecord})`);
    // If we don't have unique jobs, don't try to set them on the box, because
    // this is possibly causing us to have boxes be cleared from their job
    // relationship

    if (uniqueJobs) {
      await existingRecord.set('Jobs', uniqueJobs);
    }
    // Same strategy with samples, don't set the samples on the box to an empty
    // variable if we don't have any samples
    if (newSamplesForBox) {
      const existingBoxSamples = JSON.parse(existingRecord.get('Samples')!) as BoxJobSamples;

      // Merge the new samples into the existing samples
      // This logic intentionally does not overwrite existing samples
      for (const job in newSamplesForBox) {
        if (existingBoxSamples[job]) {
          for (const sample of newSamplesForBox[job].samples) {
            if (
              sample.bag_id &&
              !existingBoxSamples[job].samples.find((existingSample) => existingSample.bag_id === sample.bag_id)
            ) {
              existingBoxSamples[job].samples.push(sample);
            }
          }
          // if the job wasn't in the box before, just add it to the box
        } else {
          existingBoxSamples[job] = newSamplesForBox[job];
        }
      }

      await existingRecord.set('Samples', JSON.stringify(existingBoxSamples));
    }

    if (!existingRecord.get('Tracking Number')) {
      await existingRecord.set('Tracking Number', this.trackingNumber);
      await existingRecord.set('Tracking Number Timestamp', this.trackingNumberTime);
    }

    if (this.closedTimestamp) {
      await existingRecord.set('Closed', this.closed);
      await existingRecord.set('Closed Timestamp', this.closedTimestamp);
    }

    try {
      // this function call will clear the needsUpdated flag if it is successful
      return await this.uploadToS3();
    } catch (error) {
      await logger.log(`UPLOAD_BOX", "Error uploading to S3 (${error})`);
      // if, for some reason, the S3 call fails (which it likely never will since it also uses
      // the recovery server as a fallback) then we will try to sync directly to AirTable
      await existingRecord.sync();
      // if this succeeds, we will start clear the needsUpdated flag
      this.needsUpdated = false;
      return true; // we won't get here if we have an exception
    }
  } else {
    let updates: FieldSet = {};
    try {
      updates = {
        'Box ID': this.uid,
        'User ID': session?.username,
        Jobs: uniqueJobs,
        Samples: JSON.stringify(newSamplesForBox),
        'Lab Name': this.labName.replaceAll(/"/g, ''),
        'Tracking Number': this.trackingNumber,
        'Tracking Number Timestamp': this.trackingNumberTime,
        'App Created Time': new Date(this.createdTimestamp).toISOString(),
        'Daily Box Index': this.dailyBoxCountIndex,
        'Job Box Index': this.jobBoxCountIndex,
      };
      if (this.closedTimestamp) {
        updates['Closed'] = this.closed;
        updates['Closed Timestamp'] = this.closedTimestamp;
      }
      await logger.log('UPLOAD_BOX', `Box does not exist, push to AirTable (payload=${JSON.stringify(updates)})`);

      return await this.uploadToS3();
    } catch (error) {
      await logger.log('UPLOAD_BOX', `Error uploading box to S3 ${error}`);
      await Airtable.createRecord('Box', updates);
      // if we are pushing to AirTable directly, and we don't catch then we don't need to update
      this.needsUpdated = false;
      return true;
      // this function call will clear the needsUpdated flag if it is successful
    }
  }
}
