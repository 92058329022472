import { PureComponent } from 'react';
import MapButton from './MapButton';

import { GiCalendar } from 'react-icons/gi';
import { alertWarn } from '../../../alertDispatcher';
import { ScheduleViewMode, ScheduleViewModes } from '../../../types/types';
import { ScheduleViewState } from '../../../db/local_storage';
import { dispatchMissionUpdated } from '../../../missionEvents';
import { isLocalhost } from '../../../utils';


function modeToColor(mode: ScheduleViewMode) {
    switch (mode) {
        case 'Disabled':
            return 'gray';
        // TODO re-add when we enable this flag
        // case 'Sampled + Scheduled Jobs':
        //    return 'blue';
        case 'Scheduled Jobs Only':
            return 'green';
        default:
            alertWarn('Invalid ScheduleViewMode');
            return 'gray';
    }
}

interface ChangeScheduleViewButtonProps {
    onChange: (mode: ScheduleViewMode) => void;
}

interface ChangeScheduleViewButtonState {
    mode: ScheduleViewMode;
}

export default class ChangeScheduleViewButton extends PureComponent<
    ChangeScheduleViewButtonProps,
    ChangeScheduleViewButtonState
> {
    constructor(props: ChangeScheduleViewButtonProps) {
        super(props);

        this.state = {
            mode: ScheduleViewState.get(),
        };

        this.toggleScheduleViewState = this.toggleScheduleViewState.bind(this);
    }

    toggleScheduleViewState() {
        const currentMode = this.state.mode;
        const nextModeIndex = (ScheduleViewModes.indexOf(currentMode) + 1) % ScheduleViewModes.length;
        const newMode = ScheduleViewModes[nextModeIndex];
        ScheduleViewState.set(newMode);
        this.setState({ mode: newMode });
        this.props.onChange(newMode);

        // TODO need an event to fire to update the map, or 
        dispatchMissionUpdated();
    }


    render() {
        if (!isLocalhost) {
            return null;
        }
        return (
            <MapButton tooltip="Change the map schedule view mode" onClick={this.toggleScheduleViewState}>
                <GiCalendar size={25} color={modeToColor(this.state.mode)} />
            </MapButton>
        );
    }
}
