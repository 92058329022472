import { Fragment } from 'react/jsx-runtime';
import { AirtableRecord } from '../../db';
import { Box } from '@rogoag/airtable';
import { Grid, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { Tick } from 'react-crude-animated-tick';

interface Props {
  box: AirtableRecord<Box>;
  excludedBoxes: string[];
  expandedBoxes: string[];
  group: any;
  dropoffName: string;
  collapseBox: (boxId: string) => void;
  expandBox: (boxId: string) => void;
  excludeBox: (boxId: string) => void;
  scannedBoxIds: string[];
}
export function ShippingGroupBox({
  box,
  excludedBoxes,
  expandedBoxes,
  group,
  dropoffName,
  collapseBox,
  expandBox,
  excludeBox,
  scannedBoxIds,
}: Props) {
  return (
    <Fragment key={box.id}>
      {!excludedBoxes.includes(box.get('Box ID') ?? '') && (
        <Fragment key={box.id}>
          <TableRow key={box.id} style={!group.get(dropoffName).valid ? { opacity: '25%' } : {}}>
            <TableCell>
              <Grid item style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <IconButton
                  key={'Collapse/Expand'}
                  onClick={(event) => {
                    event.stopPropagation();
                    const boxId = box.get('Box ID');
                    if (!boxId) {
                      throw new Error('Box ID is not defined');
                    }
                    if (expandedBoxes.includes(boxId)) {
                      collapseBox(boxId);
                    } else {
                      expandBox(boxId);
                    }
                  }}
                >
                  {expandedBoxes.includes(box.get('Box ID') ?? '') ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>

                <IconButton
                  key={'Exclude'}
                  size={'small'}
                  disabled={!group.get(dropoffName).valid}
                  onClick={() => excludeBox(box.get('Box ID') ?? '')}
                  style={{ padding: 6, marginRight: 5 }}
                >
                  <AiOutlineMinusCircle />
                </IconButton>
                <Typography
                  variant="body2"
                  color={
                    scannedBoxIds.find((scannedBoxID) => scannedBoxID === box.get('Box ID')) ? 'textPrimary' : 'error'
                  }
                >
                  Box {box.get('Box ID')}
                </Typography>
              </Grid>
            </TableCell>
            {scannedBoxIds.find((scannedBoxID) => scannedBoxID === box.get('Box ID')) ? (
              <TableCell align="right" style={{ padding: 8 }} colSpan={2}>
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginRight: 7.5,
                    justifyItems: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="body2">Scanned</Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 10, marginRight: 10, marginBottom: 4 }}>
                    <Tick size={24} />
                  </Grid>
                </Grid>
              </TableCell>
            ) : (
              <TableCell style={{ padding: 0 }} colSpan={2} align="right">
                <Typography variant="body2" style={{ marginRight: 10, fontStyle: 'italic' }} color="error">
                  Needs scanning
                </Typography>
              </TableCell>
            )}
          </TableRow>
          {expandedBoxes.includes(box.get('Box ID') ?? '') &&
            Object.keys(JSON.parse(box.get('Samples') ?? '{}')).map((key) => {
              const samplesParsed = JSON.parse(box.get('Samples') ?? '{}');
              const sampleObj = samplesParsed[key];
              return (
                <TableRow key={key} style={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell key={'jobInfo'}>{sampleObj.jobInfo.field}</TableCell>
                  <TableCell key={'samples'} align="right" colSpan={2}>
                    {sampleObj.samples.length}
                  </TableCell>
                </TableRow>
              );
            })}
        </Fragment>
      )}
    </Fragment>
  );
}
