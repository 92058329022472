import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';
import Sample, { SampleList } from './SampleClass';
import Session, { SessionList } from './SessionClass';

import {
  clearAllSamples,
  clearSamples,
  toPdf,
  uploadToS3,
  upload,
  recoverBoxes,
  calculateBoxChecksum,
  getSampleBoxMissions,
  decodeBoxUID,
} from '../db_ops/sample_box_ops';
import Mission from './MissionClass';
import { PRINTER_CONFIG } from '../components/robot/configs/PrinterConfig';
import { KMLElement } from '../kml';
import logger from '../logger';
import { ISampleBox, SampleBoxDeSerialized } from './types';
import { disposeBox } from '../redux/features/boxes/boxesSlice';
import { storeDispatch } from '../redux/store';
import { getCurrentSession } from '../dataModelHelpers';
import boxCreator from '../services/BoxCreator';

export default class SampleBox extends ObjectClassGenerator<SampleBox>('SampleBox') implements ISampleBox {
  // attributes
  #uid: string;
  #closedTimestamp: string;
  #needsUpdated: boolean;
  #labName: string;
  #username: string;
  #trackingNumber: string | undefined;
  #trackingNumberTime: string | undefined;
  #jobBoxCountIndex: number;
  #dailyBoxCountIndex: number;
  #reprint_reason: string;

  // relationships
  #Session_id?: number;
  #ReprintSession_id?: number;

  static tableName = 'SampleBox';

  constructor(state: any = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SampleBox, 'uid');
    this.publishAttribute(SampleBox, 'closedTimestamp');
    this.publishAttribute(SampleBox, 'needsUpdated');
    this.publishAttribute(SampleBox, 'labName');
    this.publishAttribute(SampleBox, 'username');
    this.publishAttribute(SampleBox, 'trackingNumber');
    this.publishAttribute(SampleBox, 'trackingNumberTime');
    this.publishAttribute(SampleBox, 'jobBoxCountIndex');
    this.publishAttribute(SampleBox, 'dailyBoxCountIndex');
    this.publishAttribute(SampleBox, 'reprint_reason');
    this.publishAttribute(SampleBox, 'Session_id');
    this.publishAttribute(SampleBox, 'ReprintSession_id');
    // initialize state
    this.initializeState(state);
  }

  static async logBoxes(topic: string, uidsOnly: boolean = false) {
    await logger.log(
      topic,
      SampleBox.query().map((box) => box.toLogObject(uidsOnly)),
    );
  }

  toLogObject(uidOnly: boolean = false) {
    if (uidOnly) {
      return {
        uid: this.#uid,
      };
    }

    const samples = this.getSamples();

    return {
      uid: this.#uid,
      closed: this.closed,
      closedTimestamp: this.#closedTimestamp,
      labName: this.#labName,
      username: this.#username,
      trackingNumber: this.#trackingNumber,
      trackingNumberTime: this.#trackingNumberTime,
      jobBoxCountIndex: this.#jobBoxCountIndex,
      dailyBoxCountIndex: this.#dailyBoxCountIndex,
      reprint_reason: this.#reprint_reason,
      samplesNumber: samples.length,
    };
  }

  initializeState(state: Partial<SampleBox> = {}) {
    this._instance_id = state?._instance_id!;
    this._refs = { ...state?._refs };
    this._version = state?._version!;
    this.#uid = state?.uid || '';
    this.#closedTimestamp = state?.closedTimestamp || '';
    this.#needsUpdated = state?.needsUpdated || false;
    this.#labName = state?.labName || '';
    this.#username = state?.username || '';
    this.#trackingNumber = state?.trackingNumber || '';
    this.#trackingNumberTime = state?.trackingNumberTime || '';
    this.#jobBoxCountIndex = state?.jobBoxCountIndex || 0;
    this.#dailyBoxCountIndex = state?.dailyBoxCountIndex || 0;
    this.#reprint_reason = state?.reprint_reason || '';
    this.#Session_id = state?.Session_id;
    // TODO would be nice to fix this and make it better...
    this.#ReprintSession_id = state?.ReprintSession_id;
  }

  dispose() {
    logger.log('SAMPLE_BOX_DISPOSE', this.toLogObject());
    const boxUid = this.uid;

    for (const sample of this.getSamples()) {
      sample.SampleBox_id = undefined;
    }

    const session = this.getSession();
    if (session) {
      this.Session_id = undefined;
    }

    SampleBox.delete(this.instance_id);

    storeDispatch(disposeBox(boxUid));
  }

  set uid(value) {
    this.#uid = value;
    this.syncToDB();
  }

  get uid() {
    return this.#uid;
  }

  get short_uid() {
    return this.uid.toUpperCase().substring(0, 3);
  }

  get closed() {
    return Boolean(this.#closedTimestamp);
  }

  set closedTimestamp(value) {
    this.#closedTimestamp = value;
    this.syncToDB();
  }

  get closedTimestamp() {
    return this.#closedTimestamp;
  }

  set needsUpdated(value) {
    this.#needsUpdated = value;
    this.syncToDB();
  }

  get needsUpdated() {
    return this.#needsUpdated;
  }

  set labName(value) {
    this.#labName = value;
    this.syncToDB();
  }

  get labName() {
    return this.#labName;
  }

  set username(value) {
    this.#username = value;
    this.syncToDB();
  }

  get username() {
    return this.#username;
  }

  set trackingNumber(value) {
    this.#trackingNumber = value;
    this.syncToDB();
  }

  get trackingNumber() {
    return this.#trackingNumber;
  }

  set trackingNumberTime(value) {
    this.#trackingNumberTime = value;
    this.syncToDB();
  }

  get trackingNumberTime() {
    return this.#trackingNumberTime;
  }

  set jobBoxCountIndex(value) {
    this.#jobBoxCountIndex = value;
    this.syncToDB();
  }

  get jobBoxCountIndex() {
    return this.#jobBoxCountIndex;
  }

  set reprint_reason(value) {
    this.#reprint_reason = value;
    this.syncToDB();
  }

  get reprint_reason() {
    return this.#reprint_reason;
  }

  set dailyBoxCountIndex(value) {
    this.#dailyBoxCountIndex = value;
    this.syncToDB();
  }

  get dailyBoxCountIndex() {
    return this.#dailyBoxCountIndex;
  }

  get userUid() {
    return decodeBoxUID(this.uid).userId;
  }

  get createdTimestamp() {
    return decodeBoxUID(this.uid).timestamp;
  }

  get labCode() {
    return decodeBoxUID(this.uid).labCode;
  }

  get friendlyId() {
    const timestamp = this.createdTimestamp.getDate(); //.toString().padStart(2, '0');
    const boxIndex = this.dailyBoxCountIndex; //.toString().padStart(2, '0');
    return `${this.userUid}-${timestamp}-${boxIndex}`;
  }

  get session_token() {
    return this.getSession()?.token;
  }

  to_kml = () => {
    const box = KMLElement.element('SampleBox');
    box.putExtendedData('box_uid', this.uid);
    box.putExtendedData('closed_timestamp', this.closedTimestamp);
    box.putExtendedData('lab_name', this.labName);
    box.putExtendedData('username', this.username);
    box.putExtendedData('tracking_number', this.trackingNumber);
    box.putExtendedData('tracking_number_time', this.trackingNumberTime);
    box.putExtendedData('job_box_count_index', this.jobBoxCountIndex);
    box.putExtendedData('daily_box_count_index', this.dailyBoxCountIndex);
    box.putExtendedData('reprint_reason', this.reprint_reason);
    box.putExtendedData('session_token', this.session_token);
    return box;
  };

  /**
   *
   * @param mission_id
   * @param doc
   */
  static toKml(mission_id: number, doc: KMLElement) {
    const mission = Mission.get(mission_id);
    const boxes = mission?.getBoxes();
    // TODO it would be nice to serialize more helpful data right now we just
    // want to get the user ID and the related box ID
    const boxesString = JSON.stringify(boxes);
    doc.putExtendedData('boxes', boxesString);
  }

  getSamples() {
    if (this._refs && this._refs.Sample) {
      return new SampleList(
        ...Array.from(this._refs.Sample)
          .map((id) => Sample.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const samples = Sample.query((sel) => sel && sel.SampleBox_id === this.instance_id);
      for (const sample of samples) {
        sample.SampleBox_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SampleList(...samples);
    }
  }

  static async recreateFromKml(
    sample: Sample,
    sampleBoxUid: string,
    deSerializedBoxes: SampleBoxDeSerialized[],
  ): Promise<SampleBox | null> {
    await logger.log(
      'BOX_RECREATE_FROM_KML',
      `Box ${sampleBoxUid} not found locally, will try to load it from KML`,
    );

    const box: SampleBoxDeSerialized | undefined = deSerializedBoxes.find((box) => box.uid === sampleBoxUid);
    if (!box) {
      await logger.log('BOX_RECREATE_FROM_KML', `Box ${sampleBoxUid} not found in KML`);

      return null;
    }

    const session = getCurrentSession();
    let closedTimestamp = box.closedTimestamp;
    // If the box is not closed and the user is not the one who created the box, we will close it
    if (!closedTimestamp && session && session.username !== box.username) {
      closedTimestamp = new Date().toISOString();
    }

    const newBox = boxCreator.create({
      labName: box.labName,
      labCode: undefined,
      uid: box.uid,
      closedTimestamp,
      username: box.username,
      userId: undefined,
      boxDailyCountNumber: box.dailyBoxCountIndex,
      jobBoxCountIndex: box.jobBoxCountIndex,
      trackingNumber: box.trackingNumber || '',
    });

    sample.SampleBox_id = newBox.instance_id;

    await logger.log(
      'BOX_RECREATE_FROM_KML',
      `Box ${sampleBoxUid} found in KML, recreated box ${JSON.stringify(newBox)}, and linked to sample ${JSON.stringify(sample)}`,
    );

    return newBox;
  }

  set Session_id(value) {
    if (this.#Session_id) {
      const relateObj = Session.get(this.#Session_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SampleBox', this.instance_id);
      }
    }
    this.#Session_id = value;
    if (value) {
      const relateObj = Session.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SampleBox', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Session_id() {
    return this.#Session_id;
  }

  set ReprintSession_id(value) {
    if (this.#ReprintSession_id) {
      const relateObj = Session.get(this.#ReprintSession_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SampleBoxReprint', this.instance_id);
      }
    }
    this.#ReprintSession_id = value;
    if (value) {
      const relateObj = Session.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SampleBoxReprint', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get ReprintSession_id() {
    return this.#ReprintSession_id;
  }

  getSession() {
    return Session.get(this.Session_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SampleBox.query((sel) => sel.instance_id !== this.instance_id && sel.uid === this.uid);
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate sample box found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`sample box: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getSession()) {
      problems.push(
        `sample box: Could not find session instance across unconditional relationship R511: ${this.instance_id}`,
      );
    }
    return problems;
  }

  clearAllSamples() {
    clearAllSamples.bind(this)();
  }

  async clearSamples(missionId) {
    return await clearSamples.bind(this)(missionId);
  }

  getSampleBoxMissions(): Mission[] {
    return getSampleBoxMissions.bind(this)();
  }

  // TODO this doesn't take into account any data integrity issues with multiple
  // boxes being open...
  static getCurrentBox() {
    const boxes = SampleBox.query((box) => !box.closed && !!box.Session_id);

    // return undefined if we didn't get any results
    if (!boxes || !boxes.length) return;

    if (boxes.length > 1) {
      logger.log('GET_CURRENT_BOX', `Found ${boxes.length} open boxes.  Please close all but one box.`);
    }

    // sort for the newest box
    return boxes.sort((a, b) => {
      const aDate = new Date(a.createdTimestamp);
      const bDate = new Date(b.createdTimestamp);
      return aDate.getTime() - bDate.getTime();
    })[0];
  }

  static getOpenBoxes() {
    return SampleBox.query((box) => !box.closed);
  }

  toPdf(logo: string, upsLogo: string, config: PRINTER_CONFIG) {
    return toPdf.bind(this)(logo, upsLogo, config);
  }

  async upload() {
    return await upload.bind(this)();
  }

  async uploadToS3() {
    return await uploadToS3.bind(this)();
  }

  calculateBoxChecksum(): string {
    return calculateBoxChecksum.bind(this)();
  }

  static async recoverBoxes(boxes: SampleBox[]) {
    await recoverBoxes(boxes);
  }
}

export class SampleBoxList extends DataObjectList<SampleBox> {
  getSamples() {
    return new SampleList(
      ...this.reduce((samples: Sample[], samplebox) => samples.concat(samplebox.getSamples()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getSessions() {
    return new SessionList(...this.map((samplebox) => samplebox.getSession()).filter((sel) => !!sel));
  }
}
