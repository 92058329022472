import React, { PureComponent } from 'react';

import { Button } from '@material-ui/core';

import { TiLocationArrowOutline, TiLocationArrow } from 'react-icons/ti';

interface RobotRotateButtonProps {
  toggled: boolean;
  onClick: () => void;
  marginTop: number;
}

export default class RobotRotateButton extends PureComponent<RobotRotateButtonProps> {
  render() {
    return (
      <Button
        variant="contained"
        style={{
          backgroundColor: 'white',
          height: 40,
          width: 30,
          position: 'absolute',
          left: 10,
          marginTop: this.props.marginTop,
          zIndex: 1,
          padding: 0,
        }}
        onClick={this.props.onClick}
      >
        {this.props.toggled ? (
          <TiLocationArrow size={32} className={'tracking-lock'} />
        ) : (
          <TiLocationArrowOutline size={30} className={'tracking-default'} />
        )}
      </Button>
    );
  }
}
