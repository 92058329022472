import { ID_OPTIONS } from '@rogoag/kml-error-checker';
import shp from 'shpjs';
import { MultiPolygon, Point, Polygon, Position } from '@turf/helpers/dist/js/lib/geojson';
import {
  Boundary,
  GridPatterns,
  Job,
  Mission,
  Sample,
  SampleSite,
  SampleZone,
  SamplingTypes,
  SoilCore,
  Zone,
  ZoneType,
} from '../../../../db';
import { ConfirmColumn, RogoShapefileType } from '../../../../types/types';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { alertError, alertWarn } from '../../../../alertDispatcher';
import { MINIMUM_COORDINATES_FOR_VALID_BOUNDARY } from '../../../../constants';
import { isNumeric } from '../../../../utils';

const CENTROID_REGEX = new RegExp(/^[a-zA-Z0-9]+$/);
const CORE_REGEX = new RegExp(/^([a-zA-Z0-9]+)([,.;-]([1-9][0-9]*))+$/);

class OriginalPointsOrZonesFileProcessor {
  job: Job;
  skipFile: boolean;

  constructor(
    private shpDataArray: shp.FeatureCollectionWithFilename[],
    private confirmColumn: (item: ConfirmColumn) => Promise<string>,
    private shpfile: {
      data: shp.FeatureCollectionWithFilename | shp.FeatureCollectionWithFilename[];
      mode: RogoShapefileType;
      source: string;
      name: string;
    },
    private errorMessages: string[],
    private mission: Mission,
  ) {
    const job = this.mission.getJob();
    if (!job) {
      throw 'Mission job not found';
    }

    this.job = job;
    this.skipFile = false;
  }

  async process() {
    for (const shpdata of this.shpDataArray) {
      // TODO could we process these in parallel?
      await this.processShapeData(shpdata);

      if (this.skipFile) {
        break;
      }
    }
  }

  private getFeatureNameFromProperties(feature: Feature<Geometry, GeoJsonProperties>, sampleIDProp: string): string {
    if (!feature.properties || !feature.properties[sampleIDProp]) {
      return '';
    }

    return feature.properties[sampleIDProp].toString().trim();
  }

  private async processShapeData(shpdata: shp.FeatureCollectionWithFilename) {
    if (shpdata.type !== 'FeatureCollection') {
      console.error('Shapefile does not contain a feature collection', shpdata);
      this.errorMessages.push(`Shapefile does not contain a feature collection: ${shpdata.fileName}.shp`);

      return;
    }

    const columnHeaders = [
      ...shpdata.features.reduce<Set<string>>((acc, feature) => {
        Object.keys(feature.properties || {}).forEach((key) => acc.add(key));
        return acc;
      }, new Set([])),
    ];

    // confirm selection with user
    let sampleIDProp: string | undefined = await this.chooseSampleIDProp(columnHeaders, shpdata);
    if (!sampleIDProp) {
      console.error('No sample IDs found', shpdata);
      this.errorMessages.push(`No sample IDs found: ${shpdata.fileName}.shp`);

      return;
    }

    if (sampleIDProp === 'ignore') {
      return;
    }

    const externalCustomerID = this.mission.getJob()?.external_reference_id_column;

    if (this.mixedPointTypesPresent(shpdata.features, sampleIDProp)) {
      this.errorMessages.push('Mixed points types. Skipping file.');
      this.skipFile = true;

      return;
    }

    for (const feature of shpdata.features) {
      let featureName: string = this.getFeatureNameFromProperties(feature, sampleIDProp);

      if (!feature.geometry) {
        console.error('Feature has no geometry', feature);
        this.errorMessages.push('Feature has no geometry');

        return;
      }

      // TODO this is specifically used for Brookside for now for BCS jobs. Should make this more adapatable
      if (feature.properties && 'GrowerID' in feature.properties) {
        this.job.grower_id = feature.properties['GrowerID'];
      }

      if (this.isPoint(feature.geometry)) {
        this.processPoint(feature, featureName, externalCustomerID);
      } else if (this.isPolygonOrMultiPolygon(feature.geometry)) {
        await this.processPolygonOrMultipolygon(feature, featureName, externalCustomerID);
      } else {
        console.error('Feature has invalid geometry type for samples', feature);
        this.errorMessages.push(`Feature has invalid geometry type for boundaries: ${feature.geometry.type}`);
      }
    }
  }

  private async processPolygonOrMultipolygon(
    feature: Feature<Geometry, GeoJsonProperties>,
    featureName: string,
    externalCustomerIDColumn?: string,
  ) {
    let geomCoordinates: Position[][][];
    if (this.isPolygon(feature.geometry)) {
      geomCoordinates = [feature.geometry.coordinates];
    } else {
      geomCoordinates = (feature.geometry as MultiPolygon).coordinates;
    }

    if (featureName.endsWith('.0')) {
      featureName = featureName.slice(0, -2);
    }

    if (featureName.match(CENTROID_REGEX) === null) {
      if (featureName) {
        // ignore empty names
        console.error(`Cannot load non-aphanumeric sample ID: ${featureName}`);
        this.errorMessages.push(`Cannot load non-aphanumeric sample ID: ${featureName}`);
      }

      return;
    }

    let zone = this.mission.getZones().find((sel) => sel.zone_name === featureName);
    if (!zone) {
      zone = this.createZone(zone, featureName, feature, externalCustomerIDColumn);
    }

    await this.createBoundaries(geomCoordinates, zone);
  }

  private async createBoundaries(geomCoordinates: Position[][][], zone: Zone) {
    let polyID: number | undefined = undefined;
    for (const polyCoordinates of geomCoordinates) {
      polyID = undefined;
      for (const [i, coordinates] of Object.entries(polyCoordinates)) {
        if (coordinates.length < MINIMUM_COORDINATES_FOR_VALID_BOUNDARY) {
          continue;
        }

        const newBoundary = Boundary.create();
        newBoundary.boundary_id = this.mission.assign_id();
        newBoundary.coordinates = coordinates.map((coord) => [coord[1], coord[0]]);

        if (parseInt(i) === 0) {
          polyID = this.mission.assign_id();
          newBoundary.poly_id = polyID;
          newBoundary.OuterZone_id = zone.instance_id;
        } else {
          newBoundary.poly_id = polyID!;
          newBoundary.InnerZone_id = zone.instance_id;
        }

        newBoundary.simplify(0.5);
      }
    }
  }

  private createZone(
    zone: Zone | undefined,
    featureName: string,
    feature: Feature<Geometry, GeoJsonProperties>,
    externalCustomerIDColumn?: string,
  ) {
    // TODO why do we pass in a zone but then also create it? Should we only create it if it doesn't exist?
    zone = Zone.create();
    zone.Mission_id = this.mission.instance_id;
    zone.zone_name = featureName;
    zone.zone_type = ZoneType.SAMPLE_ZONE;

    let sampleSite = this.mission.getSampleSites().find((sel) => sel.original_sample_id === featureName);
    if (!sampleSite) {
      sampleSite = SampleSite.create();
      sampleSite.original_sample_id = featureName;
      sampleSite.external_reference_id = this.validateExternalReferenceID(
        externalCustomerIDColumn,
        feature,
        featureName,
      );
      sampleSite.sample_site_source = 'Customer';
      // if the geojson properties has a ZoneID property, save that as the original sample id
      if (feature.properties && feature.properties['ZoneID']) {
        sampleSite.external_reference_id = feature.properties['ZoneID'].toString();
      }
      sampleSite.Mission_id = this.mission.instance_id;
    }

    let sampleZone = sampleSite.getSampleZone();
    if (!sampleZone) {
      sampleZone = SampleZone.create();
      sampleZone.SampleSite_id = sampleSite.instance_id;
    }
    sampleZone.Zone_id = zone.instance_id;

    return zone;
  }

  private processPoint(
    feature: Feature<Geometry, GeoJsonProperties>,
    featureName: string,
    externalCustomerID?: string,
  ) {
    const geomCoordinates = (feature.geometry as Point).coordinates;

    if (this.featureNameMatchesCore(featureName)) {
      // zone sampling
      this.processCore(featureName, geomCoordinates);
    } else if (!this.mission.is_zone_mission()) {
      // grid sampling
      this.processCentroid(feature, featureName, geomCoordinates, externalCustomerID);
    } else {
      if (featureName) {
        // ignore empty names
        console.error(`Cannot load non-aphanumeric sample ID: ${featureName}`);
        this.errorMessages.push(`Cannot load non-aphanumeric sample ID: ${featureName}`);
      }
    }
  }

  private processCore(featureName: string, geomCoordinates: Position) {
    const primarySpec = this.mission.getPrimarySpec();
    if (!primarySpec) {
      throw new Error(`Processing core: no primary spec found`);
    }

    const match = featureName.match(CORE_REGEX);
    if (!match) {
      throw new Error(`Core feature does not match the regex: ${featureName}`);
    }

    const sampleId = match[1];

    if (!sampleId) {
      throw new Error(`Core feature does not have a sample ID: ${featureName}`);
    }

    let sampleSite = this.mission.getSampleSites().find((sel) => !!match && sel.original_sample_id === sampleId);
    let sample = sampleSite ? sampleSite.getSamples()[0] : undefined;
    let samplingSpec = sample ? sample.getSamplingSpec() : undefined;

    if (!sampleSite) {
      sampleSite = SampleSite.create();
      sampleSite.original_sample_id = sampleId;
      sampleSite.sample_site_source = 'Customer';
      sampleSite.Mission_id = this.mission.instance_id;
      const sampleZone = SampleZone.create();
      sampleZone.SampleSite_id = sampleSite.instance_id;
    }

    if (!sample) {
      sample = Sample.createSample(
        this.mission,
        sampleSite,
        primarySpec,
        sampleId,
        SamplingTypes.REGULAR,
      );
    }

    if (samplingSpec) {
      samplingSpec.cores += 1;
    }

    const soilCore = SoilCore.create();
    soilCore.Sample_id = sample.instance_id;
    soilCore.core_number = match ? parseInt(match[3]) : sample.getSoilCores().length;
    soilCore.lat = geomCoordinates[1];
    soilCore.lon = geomCoordinates[0];
    soilCore.source = 'Customer';
  }

  private validateExternalReferenceID(
    externalCustomerIDColumn: string | undefined,
    feature: Feature<Geometry, GeoJsonProperties>,
    featureName: string,
  ) {
    if (externalCustomerIDColumn) {
      const transformedFeatureProperties = Object.keys(feature.properties || {}).reduce((acc, key) => {
        acc[key.toUpperCase()] = feature.properties ? feature.properties[key] : '';
        return acc;
      }, {});
      const externalReferenceId = transformedFeatureProperties
        ? transformedFeatureProperties[externalCustomerIDColumn.toUpperCase()]
        : '';
      // if we have an externalCustomerID and it's not in the properties, we need to throw an error
      if (!externalReferenceId) {
        throw new Error(`External reference ID not found in properties for sample ${featureName}`);
      }
      return externalReferenceId;
    }
  }

  private processCentroid(
    feature: Feature<Geometry, GeoJsonProperties>,
    featureName: string,
    geomCoordinates: Position,
    externalCustomerIDColumn: string | undefined,
  ) {
    const useOriginalSampleId = this.job.use_original_sample_id;
    if (!this.featureNameMatchesCentroid(featureName)) {
      const centroidMismatchMessage = `Centroid feature does not match the regex: ${featureName}`;
      if (!useOriginalSampleId) {
        const abortMessage = `${centroidMismatchMessage}. No "Use Original Sample ID" flag is found on a deal - skipping centroid.`;
        this.errorMessages.push(abortMessage);
        alertError(abortMessage);

        return;
      }

      alertWarn(`${centroidMismatchMessage}`);
    }

    const sampleSiteExists = SampleSite.findByOriginalSampleID(this.mission, featureName);
    if (sampleSiteExists) {
      alertWarn(`Ignoring duplicate sample. Sample ID: ${featureName}`);

      return;
    }

    const samples = this.mission.getAllSamples();
    const samplingSpecs = this.mission.getSamplingSpecs();
    const samplesToCreate = samplingSpecs.length;

    let i = 0;
    while (i < samplesToCreate) {
      const sample = Sample.create();
      const sampleId = useOriginalSampleId ? (samples.length + 1 + i).toString() : featureName;
      sample.sample_id = sampleId;

      let sampleSite = this.mission.getSampleSites().find((sel) => sel.original_sample_id === featureName);
      if (!sampleSite) {
        sampleSite = SampleSite.createWithCentroid(
          this.mission,
          featureName,
          geomCoordinates[1],
          geomCoordinates[0],

          this.validateExternalReferenceID(externalCustomerIDColumn, feature, featureName),
        );
        sampleSite.sample_site_source = 'Customer';
      }
      sample.SampleSite_id = sampleSite.instance_id;
      sample.sample_type = SamplingTypes.REGULAR;
      sample.SamplingSpec_id = samplingSpecs[i].instance_id;

      i++;
    }
  }

  private featureNameMatchesCentroid(featureName: string): boolean {
    return featureName.match(CENTROID_REGEX) !== null;
  }

  private featureNameMatchesCore(featureName: string): boolean {
    const match = featureName.match(CORE_REGEX);

    return match !== null;
  }

  private mixedPointTypesPresent(features: Feature<Geometry, GeoJsonProperties>[], sampleIDProp: string) {
    const pointTypes = new Set<'centroid' | 'core'>();
    for (const feature of features) {
      let featureName: string = this.getFeatureNameFromProperties(feature, sampleIDProp);

      if (!feature.geometry) {
        continue;
      }

      if (!this.isPoint(feature.geometry)) {
        continue;
      }

      if (this.featureNameMatchesCore(featureName)) {
        pointTypes.add('core');
      } else {
        pointTypes.add('centroid');
      }

      if (pointTypes.size > 1) {
        return true;
      }
    }

    return false;
  }

  private async chooseSampleIDProp(columnHeaders: string[], shpdata: shp.FeatureCollectionWithFilename) {
    if (columnHeaders.length <= 1) {
      // TODO technically this doesn't guarantee that there's only one column, just selects the first column...
      // if theres only one column, that must be the sample IDs
      return columnHeaders[0];
    }

    let sampleIDProp: string | undefined = undefined;
    // If wanting to test a mission with points that need selected, comment out this for loop
    for (const opt of ID_OPTIONS) {
      const prop = columnHeaders.find((header) => {
        return header.toLowerCase().trim() === opt.toLowerCase().trim();
      });

      if (prop) {
        sampleIDProp = prop;
        break;
      }
    }

    sampleIDProp = await this.confirmColumn({
      filename: `${shpdata.fileName}.shp`,
      source: this.shpfile.source,
      selection: sampleIDProp || '',
      headers: columnHeaders,
      // would prefer for this to be inferred better
      data: shpdata.features.map((feature) =>
        columnHeaders.map<string>((header) => (feature.properties ? feature.properties[header].toString() : '')),
      ),
      featureTypes: shpdata.features.reduce((acc, feature) => {
        acc[feature.geometry.type] = (acc[feature.geometry.type] || 0) + 1;
        return acc;
      }, {}),
    });

    return sampleIDProp;
  }

  private isPoint(geometry: Geometry): geometry is Point {
    return geometry.type === 'Point';
  }

  private isPolygonOrMultiPolygon(geometry: Geometry): geometry is Polygon | MultiPolygon {
    return geometry.type === 'Polygon' || geometry.type === 'MultiPolygon';
  }

  private isPolygon(geometry: Geometry): geometry is Polygon {
    return geometry.type === 'Polygon';
  }
}

export default OriginalPointsOrZonesFileProcessor;
