import React, { PureComponent } from 'react';

import { Drawer, Divider, Typography, IconButton, TextField } from '@material-ui/core';
import LoadingButton from '../utils/LoadingButton';

import CloseIcon from '@material-ui/icons/Close';

interface SideBarInfoProps {
  totalFields: number;
  totalAcres: number;
  totalSamples: number;
  averageAcres: number;
  averageSamples: number;
  jobLinks: {
    name: string;
    link: string;
  }[];
  onClose: () => void;
  setWeatherDays: (days: number) => void;
}

interface SideBarInfoState {
  weatherDates: string;
}

export default class SideBarInfo extends PureComponent<SideBarInfoProps, SideBarInfoState> {
  constructor(props: SideBarInfoProps) {
    super(props);

    this.state = {
      weatherDates: '',
    };

    this.setWeatherDays = this.setWeatherDays.bind(this);
  }

  async setWeatherDays() {
    const currentDate = new Date();
    const weatherDate = new Date(this.state.weatherDates);
    const diffTime = Math.abs(weatherDate.getTime() - currentDate.getTime());
    const weatherDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    await this.props.setWeatherDays(weatherDays);
  }

  render() {
    return (
      <Drawer
        anchor={'right'}
        open={true}
        variant={'persistent'}
        PaperProps={{
          style: {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <IconButton style={{ alignSelf: 'flex-end' }} onClick={this.props.onClose}>
          {/* 
                    // @ts-ignore */}
          <CloseIcon size={20} />
        </IconButton>
        <div style={{ width: 250, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant={'body1'}>
            <b>Num Fields Selected:</b> {this.props.totalFields}
          </Typography>
          <br />
          <Typography variant={'body1'}>
            <b>Total Acres:</b> {this.props.totalAcres}
          </Typography>
          <br />
          <Typography variant={'body1'}>
            <b>Total Samples:</b> {this.props.totalSamples}
          </Typography>
          <br />
          <Divider style={{ width: '100%' }} />
          <br />
          <Typography variant={'body1'}>
            <b>Avg Acres Per Field:</b> {this.props.averageAcres}
          </Typography>
          <br />
          <Typography variant={'body1'}>
            <b>Avg Samples Per Field:</b> {this.props.averageSamples}
          </Typography>
          <br />
          <Divider style={{ width: '100%' }} />
          <br />

          <Typography variant={'body1'}>Set Weather Date</Typography>

          <br />

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              variant={'standard'}
              type={'date'}
              value={this.state.weatherDates}
              onChange={(e) => this.setState({ weatherDates: e.target.value })}
              color={'secondary'}
            />
            <LoadingButton
              style={{ textTransform: 'capitalize' }}
              onClick={this.setWeatherDays}
              disabled={!this.state.weatherDates}
            >
              Set
            </LoadingButton>
          </div>
          <br />
          <Divider style={{ width: '100%' }} />
          <br />
          <Typography variant={'body1'}>Job Links</Typography>
          <br />
        </div>

        <div style={{ marginLeft: 20, flexDirection: 'column', display: 'flex', paddingBottom: 10 }}>
          {this.props.jobLinks.map((jobLink) => (
            <Typography key={jobLink.name} variant={'caption'}>
              <a href={jobLink.link}>{jobLink.name}</a>
            </Typography>
          ))}
        </div>
      </Drawer>
    );
  }
}
