import { getLength } from 'ol/sphere';
import LineString from 'ol/geom/LineString';
import { Geometry, Point, Polygon } from 'ol/geom';

// This is a hacky function that translates a geometry by
// guess/check/revise to approximate accurate spherical translation
export const translate = (point: Point, deltaX: number, deltaY: number) => {
  // translate X
  let origPoint = point.clone();
  let error = Math.abs(deltaX) - getLength(new LineString([point.getCoordinates(), origPoint.getCoordinates()]));
  while (error > 0.01) {
    // require accuracy within 1 cm
    let inc = (deltaX / Math.abs(deltaX)) * error * 0.75; // move half the error each time
    point.translate(inc, 0);
    error = Math.abs(deltaX) - getLength(new LineString([point.getCoordinates(), origPoint.getCoordinates()]));
  }
  origPoint = point.clone();
  error = Math.abs(deltaY) - getLength(new LineString([point.getCoordinates(), origPoint.getCoordinates()]));
  while (error > 0.01) {
    // require accuracy within 1 cm
    let inc = (deltaY / Math.abs(deltaY)) * error * 0.75; // move half the error each time
    point.translate(0, inc);
    error = Math.abs(deltaY) - getLength(new LineString([point.getCoordinates(), origPoint.getCoordinates()]));
  }
};

export const translatePolygonPoint = (polygon: Polygon, index: number, deltaX: number, deltaY: number) => {
  // translate X
  let origPolygon = polygon.clone();
  let error = Math.abs(deltaX) - getLength(new LineString([polygon[index], origPolygon[index]]));
  while (error > 0.01) {
    // require accuracy within 1 cm
    let inc = (deltaX / Math.abs(deltaX)) * error * 0.75; // move half the error each time
    polygon[index][0] += inc;
    error = Math.abs(deltaX) - getLength(new LineString([polygon[index], origPolygon[index]]));
  }
  origPolygon = polygon.clone();
  error = Math.abs(deltaY) - getLength(new LineString([polygon[index], origPolygon[index]]));
  while (error > 0.01) {
    // require accuracy within 1 cm
    let inc = (deltaY / Math.abs(deltaY)) * error * 0.75; // move half the error each time
    polygon[index][1] += inc;
    error = Math.abs(deltaY) - getLength(new LineString([polygon[index], origPolygon[index]]));
  }
};

// export const translatePolygon = (polygon: Polygon, deltaX: number, deltaY: number) => {
//   polygon.getCoordinates().forEach((ring) => {
//     ring.forEach((point, index) => {
//       translate(polygon, index, deltaX, deltaY);
//     });
//   });
// }

// flip transform function
export const flip = (
  coords: number[],
  output: number[] | undefined,
  dim: number | undefined,
  center: number[],
  vert: boolean,
  horiz: boolean,
) => {
  if (!output || !dim) {
    throw new Error('Output array must be defined');
  }
  for (let i = 0; i < coords.length; i++) {
    if (horiz && i % dim === 0) {
      output[i] = (coords[i] - center[0]) * -1 + center[0];
    } else if (vert && i % dim === 1) {
      output[i] = (coords[i] - center[1]) * -1 + center[1];
    } else {
      output[i] = coords[i];
    }
  }
  return output;
};

// detects whether point is in bounds
export const inBounds = (point: Point, field: Geometry, boundaryDistance: number = 0): boolean => {
  // check each vertex of a hexagon to approximate a circle with radius of
  // 'boundaryDistance'
  for (let t = 0; t < 2 * Math.PI; t += Math.PI / 3) {
    let p = point.clone();
    translate(p, boundaryDistance * Math.cos(t), boundaryDistance * Math.sin(t));
    if (!field.intersectsCoordinate(p.getCoordinates())) {
      return false;
    }
  }
  return true;
};
