import React, { PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyAccordion from '../../utils/MyAccordion';
import logger from '../../../logger';
import { alertSuccess, alertError } from '../../../alertDispatcher';
import { getCurrentMission } from '../../../dataModelHelpers';
import { dispatchMissionUpdated } from '../../../missionEvents';
import {
  validateAngle,
  validateCores,
  validateEndDepth,
  validateLength,
  validateRadius,
  validateStartDepth,
} from '../helpers/samplingParams';
import { OnLoading } from '../../../types/types';
import { MAP_TYPES, MapType } from '../../map/helpers/mapTypes';
import { FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB } from '../../../constants';

interface SamplingParametersProps {
  expanded: boolean;
  onLoading: OnLoading;
  mapType: MapType;
}

type ValidationErrorMap = Record<string, string | undefined>;
interface SamplingParametersState {
  cores: number;
  corePattern: number;
  radius: number;
  length: number;
  angle: number;
  startDepth: number;
  endDepth: number;
  errorMap: ValidationErrorMap;
  distanceBetweenCoreLines: number;
}

export default class SamplingParameters extends PureComponent<SamplingParametersProps, SamplingParametersState> {
  constructor(props: SamplingParametersProps) {
    super(props);

    this.state = {
      cores: 0,
      corePattern: 0,
      radius: 0,
      length: 0,
      angle: 0,
      startDepth: 0,
      endDepth: 0,
      errorMap: {},
      distanceBetweenCoreLines: FEET_BETWEEN_CORE_LINES_FOR_MULTI_LAB,
    };
  }

  componentDidMount() {
    this.initializeSamplingParameters();
  }

  updateParameter(key: string, value: number) {
    const errorMap = this.validate(key, value);
    // @ts-ignore - this is a dynamic key
    this.setState({ errorMap, [key]: value });
  }

  initializeSamplingParameters() {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }
    const spec = mission.getPrimarySpec();
    if (spec) {
      this.setState({
        cores: spec.cores,
        radius: spec.radius,
        length: spec.length,
        angle: spec.angle,
        corePattern: spec.pattern_type.valueOf(),
        startDepth: spec.start_depth || 0,
        endDepth: spec.end_depth || 0,
      });
    }
  }

  validate(key: string, value: number) {
    const errorMap = { ...this.state.errorMap };
    switch (key) {
      case 'cores':
        errorMap['cores'] = validateCores(value);
        break;
      case 'radius':
        errorMap['radius'] = validateRadius(value);
        break;
      case 'length':
        errorMap['length'] = validateLength(value);
        break;
      case 'angle':
        errorMap['angle'] = validateAngle(value);
        break;
      case 'startDepth':
        errorMap['startDepth'] = validateStartDepth(value, this.state.endDepth);
        break;
      case 'endDepth':
        errorMap['endDepth'] = validateEndDepth(value, this.state.startDepth);
        break;
      default:
    }

    return errorMap;
  }

  async handleUpdateParameters() {
    try {
      await this.props.onLoading(async () => {
        const mission = getCurrentMission();
        if (!mission) {
          return;
        }
        const spec = mission.getPrimarySpec();
        await spec?.update_parameters({
          cores: this.state.cores,
          pattern_type: this.state.corePattern,
          radius: this.state.radius,
          length: this.state.length,
          angle: this.state.angle,
          start_depth: this.state.startDepth,
          end_depth: this.state.endDepth,
        });

        // just get core waypoints
        const cores = mission
          .getWaypoints()
          .map((waypoint) => waypoint.getCorePoint()?.getSoilCore())
          .filter((core) => !!core);

        const sampling = this.props.mapType === MAP_TYPES.SAMPLING;
        if (sampling) {
          await mission.calculate_path_restore_cores(cores);
        } else {
          await mission.calculate_path(cores);
        }
      });
      alertSuccess('Updated sampling parameters');
      await logger.log('HANDLE_UPDATE_PARAMETERS');
    } catch (err) {
      console.error('Could not update parameters', err);
      await logger.log('HANDLE_UPDATE_PARAMETERS', `${JSON.stringify(err)}`);
    }
    dispatchMissionUpdated();
  }

  async handleRenumberSamples() {
    try {
      await this.props.onLoading(async () => {
        const mission = getCurrentMission();
        await mission?.renumber_samples(this.props.mapType === MAP_TYPES.SAMPLING, true);
        dispatchMissionUpdated();
      });
      alertSuccess('Renumbered Samples');
      logger.log('HANDLE_RENUMBER_SAMPLES', 'Success');
    } catch (err) {
      console.error('Could not renumber samples', err);
      alertError('Could not renumber samples');
      logger.log('HANDLE_RENUMBER_SAMPLES', 'Failure');
    }

    dispatchMissionUpdated();
  }

  render() {
    return (
      <MyAccordion defaultExpanded={this.props.expanded} elevation={3}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Sampling parameters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  label={this.state.corePattern === 3 ? 'min cores:' : '# cores:'}
                  value={this.state.cores}
                  fullWidth
                  error={Boolean(this.state.errorMap['cores'])}
                  helperText={this.state.errorMap['cores']}
                  inputProps={{ enterKeyHint: 'done' }}
                  // onKeyDown={this.removeFocus}
                  onChange={(e) => this.updateParameter('cores', parseFloat(e.target.value))}
                />
              </Grid>
              <Grid item xs={6} sm={12} md={4}>
                <TextField
                  label="start depth:"
                  value={this.state.startDepth}
                  fullWidth
                  error={Boolean(this.state.errorMap['startDepth'])}
                  helperText={this.state.errorMap['startDepth']}
                  inputProps={{ enterKeyHint: 'done' }}
                  // onKeyDown={this.removeFocus}
                  onChange={(e) => this.updateParameter('startDepth', parseFloat(e.target.value || '0'))}
                  InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={6} sm={12} md={4}>
                <TextField
                  label="end depth:"
                  value={this.state.endDepth}
                  fullWidth
                  error={Boolean(this.state.errorMap['endDepth'])}
                  helperText={this.state.errorMap['endDepth']}
                  inputProps={{ enterKeyHint: 'done' }}
                  // onKeyDown={this.removeFocus}
                  onChange={(e) => this.updateParameter('endDepth', parseFloat(e.target.value || '0'))}
                  InputProps={{ endAdornment: <InputAdornment position="end">in</InputAdornment> }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={4}>
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      color="primary"
                      checked={this.state.corePattern === 1}
                      disabled={this.state.corePattern === 3}
                      onChange={() => this.updateParameter('corePattern', 1)}
                    />
                  }
                  label="circle"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value={2}
                  control={
                    <Radio
                      color="primary"
                      checked={this.state.corePattern === 2}
                      disabled={this.state.corePattern === 3}
                      onChange={() => this.updateParameter('corePattern', 2)}
                    />
                  }
                  label="line"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  value={3}
                  control={
                    <Radio
                      color="primary"
                      checked={this.state.corePattern === 3}
                      disabled={this.state.corePattern !== 3}
                      onChange={() => this.updateParameter('corePattern', 3)}
                    />
                  }
                  label="zone"
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={1}
              alignItems="center"
              className={this.state.corePattern !== 1 ? 'hide' : undefined}
            >
              <Grid item xs={6} sm={6}>
                <TextField
                  label="radius:"
                  value={this.state.radius}
                  fullWidth
                  error={Boolean(this.state.errorMap['radius'])}
                  helperText={this.state.errorMap['radius']}
                  // @ts-ignore
                  inputProps={{ enterKeyHint: 'done' }}
                  // onKeyDown={this.removeFocus}
                  onChange={(e) => this.updateParameter('radius', parseFloat(e.target.value))}
                  InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              spacing={1}
              alignItems="center"
              className={this.state.corePattern !== 2 ? 'hide' : undefined}
            >
              <Grid item xs={6} sm={6}>
                <TextField
                  label="length:"
                  value={this.state.length}
                  fullWidth
                  error={Boolean(this.state.errorMap['length'])}
                  helperText={this.state.errorMap['length']}
                  inputProps={{ enterKeyHint: 'done' }}
                  // onKeyDown={this.removeFocus}
                  onChange={(e) => this.updateParameter('length', parseFloat(e.target.value))}
                  InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="angle:"
                  value={this.state.angle}
                  fullWidth
                  error={Boolean(this.state.errorMap['angle'])}
                  helperText={this.state.errorMap['angle']}
                  // onKeyDown={this.removeFocus}
                  onChange={(e) => this.updateParameter('angle', parseFloat(e.target.value))}
                  inputProps={{ enterKeyHint: 'done' }}
                  InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                />
              </Grid>
            </Grid>
            {this.state.corePattern !== 3 && (
              <Grid item xs={12}>
                <Button variant="outlined" onClick={this.handleRenumberSamples.bind(this)}>
                  Renumber Samples
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={this.handleUpdateParameters.bind(this)}>
                Update Path
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </MyAccordion>
    );
  }
}
