import { Coordinate } from 'ol/coordinate';
import React, { PureComponent } from 'react';
import { getCurrentMission } from '../../dataModelHelpers';
import { calculateDistance4329, calculateNavigationAngle, convertProjection4329, m_to_ft } from '../../utils';
import { calculateArmPosition } from '../../services/RobotArmService';
import { Sample, Waypoint } from '../../db';

import { DEFAULT_CORE_PRESENT_DISTANCE_FEET, SAMPLE_WARNING_DISTANCE_FT } from '../../constants';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { MapCalculationPositionStorage } from '../../db/local_storage';
import EventBus from '../../EventBus';
import { RosPosition } from '../../types/rosmsg';

interface RobotNavigationIndicatorProps {
  range: [number, number];
  height: number;
  style?: React.CSSProperties;
  markerValue: number;
  presentSample: Sample | undefined;
  currentWaypoint: Waypoint | undefined;
  targetCoordinate?: Coordinate | undefined;
}

interface RobotNavigationIndicatorState {
  barSize: number;
  robotPosition?: Coordinate;
  robotHeading: number;
}

export default class RobotNavigationIndicator extends PureComponent<
  RobotNavigationIndicatorProps,
  RobotNavigationIndicatorState
> {
  DEFAULT_BAR_SIZE = 200;

  constructor(props: RobotNavigationIndicatorProps) {
    super(props);

    this.state = {
      barSize: this.DEFAULT_BAR_SIZE,
      robotPosition: undefined,
      robotHeading: 0,
    };

    this.updatePosition = this.updatePosition.bind(this);
  }

  componentDidMount() {
    EventBus.on('ROSMSG/position', this.updatePosition);
  }

  componentWillUnmount() {
    EventBus.remove('ROSMSG/position', this.updatePosition);
  }

  async updatePosition({ hostname, msg }: { hostname: string; msg: RosPosition }) {
    this.setState({
      robotPosition: convertProjection4329([msg.y, msg.x]),
      robotHeading: msg.z,
    });
  }

  render() {
    const mission = getCurrentMission();

    if (!mission) {
      return null;
    }

    const presentToleranceFeet = mission.get_present_tolerance('ft');
    // let arrowY = 0;
    // let arrowX = 0;
    // switch (this.props.mode) {
    //   case 'bottom-center':
    //   case 'bottom-left':
    //   case 'bottom-right':
    //   case 'bottom-sliding':
    //     arrowY = extent[1] + navIconOffset;
    //     break;
    //   case 'center-center':
    //   case 'center-left':
    //   case 'center-right':
    //   case 'center-sliding':
    //     arrowY = extent[1] + (extent[3] - extent[1]) / 2;
    //     break;
    //   case 'top-center':
    //   case 'top-left':
    //   case 'top-right':
    //   case 'top-sliding':
    //     arrowY = extent[3] - navIconOffset;
    //     break;
    // }

    // switch (this.props.mode) {
    //   case 'top-sliding':
    //   case 'center-sliding':
    //   case 'bottom-sliding':
    //     clamp(extent[0] + xBounds, extent[0] + (xCoordinateRange / 2 + xDiff), extent[2] - xBounds);
    //     break;
    //   case 'bottom-left':
    //   case 'center-left':
    //   case 'top-left':
    //     arrowX = extent[0] + navIconOffset;
    //     break;
    // }

    const robotPosition = this.state.robotPosition;
    const robotHeading = this.state.robotHeading;
    let targetCoordinate = this.props.targetCoordinate;

    if (!robotPosition || !targetCoordinate) {
      return null;
    }

    targetCoordinate = convertProjection4329([targetCoordinate[1], targetCoordinate[0]]);

    const armPosition = calculateArmPosition(robotPosition, -robotHeading);

    const originPosition = MapCalculationPositionStorage.get() === 'Arm' ? armPosition : robotPosition;
    const robotNavigationAngle = calculateNavigationAngle(robotHeading, originPosition, targetCoordinate);

    const robotToCoreDistance = calculateDistance4329(targetCoordinate, originPosition);
    const robotToCoreDistanceFt = m_to_ft(robotToCoreDistance);

    return (
      <div
        style={{
          position: 'absolute',
          // left: 10,
          top: 100,
          width: 50,
          height: 50,
          zIndex: 1,
        }}
      >
        <ArrowUpward
          style={{
            transform: `rotate(${robotNavigationAngle}rad)`,
            color:
              robotToCoreDistanceFt >= SAMPLE_WARNING_DISTANCE_FT
                ? 'white'
                : robotToCoreDistanceFt >= presentToleranceFeet
                  ? 'orange'
                  : 'green',
            fontSize: 200,
          }}
        />
      </div>
    );
  }
}
