import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DirectionsIcon from '@material-ui/icons/Directions';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Airtable from '../../../airtable';
import { distanceLatLon, round } from '../../../utils';
import { Tooltip } from '@material-ui/core';
import { alertFullScreen } from '../../../alertDispatcher';

import { FaReceipt } from 'react-icons/fa';
import { ShiftDropoffs } from '@rogoag/airtable';

const doneStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
}));

interface ShippingDropoffProps {
  key: string;
  id: string;
  currentLat: number;
  currentLon: number;
  location: string;
}

export default function ShippingDropoff(props: ShippingDropoffProps) {
  const [dropLat, setDropLat] = React.useState<number>();
  const [dropLon, setDropLon] = React.useState<number>();
  const [hours, setHours] = React.useState<string>();
  const [done, setDone] = React.useState<boolean>();
  const [notes, setNotes] = React.useState<string>();
  (async () => {
    const dropoff = await Airtable.getRecord<ShiftDropoffs>('Shift Dropoffs', props.id);
    if (dropoff) {
      setDropLat(dropoff.get('Dropoff Lat')[0]);
      setDropLon(dropoff.get('Dropoff Lon')[0]);
      setHours(dropoff.get('Dropoff Hours')[0]);
      setDone(dropoff.get('Dropoff Complete') ? true : false);
      const operatorNotes = dropoff.get('Dropoff Notes')?.length > 0 ? dropoff.get('Dropoff Notes')[0] : '';
      setNotes(operatorNotes);
    }
  })();
  const distanceMiles =
    !!dropLat && !!dropLon
      ? round(distanceLatLon(dropLat, dropLon, props.currentLat, props.currentLon, 'Miles'), 1)
      : undefined;
  const doneClasses = doneStyles();
  const classes = done ? doneClasses : undefined;
  console.log(notes);
  return (
    <ListItem key={props.id} classes={classes} divider data-testid={`shipping-dropoff-${props.id}`}>
      <ListItemIcon>
        <Link
          href={`https://www.google.com/maps/dir/?api=1&destination="${props.location}"`}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton color="secondary">
            <DirectionsIcon fontSize="large" />
          </IconButton>
        </Link>
        <Tooltip title={!notes ? 'No Dropoff Notes Available' : 'Dropoff Notes'} placement="top">
          <span>
            <IconButton
              disabled={!notes}
              color="secondary"
              onClick={async (e) => {
                // the notes boolean property disables this component if it's not valid
                await alertFullScreen('Dropoff Notes', notes!, ['Close']);
              }}
            >
              <FaReceipt fontSize={32} />
            </IconButton>
          </span>
        </Tooltip>
      </ListItemIcon>

      <ListItemIcon></ListItemIcon>
      <ListItemText primary={`Dropoff: ${props.location}`} style={{ overflow: 'hidden' }} />
      <Box flexDirection={'column'} style={{ paddingLeft: 20 }}>
        <ListItemText
          primary={
            hours ? (
              <Typography>
                <b>{hours}</b>
              </Typography>
            ) : undefined
          }
          secondary={
            <Typography>
              <b>{distanceMiles || 'N/A'}</b> mi. to drop location
            </Typography>
          }
        />
      </Box>
    </ListItem>
  );
}
