import React, { PureComponent } from 'react';

import { Button } from '@material-ui/core';

import { alertError } from '../../../alertDispatcher';
import { getCurrentSession } from '../../../dataModelHelpers';
import { kmz2kml } from '../../../utils';
import { OnLoading } from '../../../types/types';
import * as Sentry from '@sentry/react';

interface StandardMissionButtonProps {
  text: string;
  filePath: string;
  onLoading: OnLoading;
  sampling?: boolean;
}

export default class StandardMissionButton extends PureComponent<StandardMissionButtonProps> {
  fileInput: React.RefObject<unknown>;
  constructor(props: StandardMissionButtonProps) {
    super(props);

    this.loadStandardMission = this.loadStandardMission.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.fileInput = React.createRef();
  }

  async handleFileUpload(uploadFile: { name: string; content: Blob }) {
    await this.props.onLoading(async () => {
      let text: string = '';
      if (uploadFile.name.endsWith('.kml')) {
        text = await uploadFile.content.text();
      } else if (uploadFile.name.endsWith('.kmz')) {
        text = await kmz2kml(uploadFile.content);
      }

      if (text) {
        const session = getCurrentSession();
        if (!session) {
          alertError('No session found.');
          return;
        }
        await session.loadKml(text, undefined, { sampling: this.props.sampling });
        Sentry.setTag('job_info', uploadFile.name);
      } else {
        console.error('No mission file selected.');
        alertError('No mission file selected.');
      }
    });
  }

  async loadStandardMission() {
    const content = await this.props.onLoading(() => fetch(this.props.filePath).then((r) => r.blob()));
    const tokens = this.props.filePath.split('/');
    const filename = tokens[tokens.length - 1];
    
    console.log(`loadStandardMission - file: ${filename}`);

    await this.handleFileUpload({ name: filename, content });
  }

  render() {
    return (
      <Button variant="outlined" component="label" onClick={this.loadStandardMission}>
        {this.props.text}
      </Button>
    );
  }
}
