import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import AirtableRecord, { AirtableRecordList } from './AirtableRecordClass';
import AirtableSearch, { AirtableSearchList } from './AirtableSearchClass';
import { AirtableRecordFields, ISelectedRecord } from './types';

export default class SelectedRecord<T extends AirtableRecordFields = any>
  extends ObjectClassGenerator<SelectedRecord>('SelectedRecord')
  implements ISelectedRecord
{
  // attributes

  // relationships
  #AirtableRecord_id?: number;
  #AirtableSearch_id?: number;

  static tableName = 'SelectedRecord';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SelectedRecord, 'AirtableRecord_id');
    this.publishAttribute(SelectedRecord, 'AirtableSearch_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SelectedRecord<T>> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#AirtableRecord_id = state.AirtableRecord_id;
    this.#AirtableSearch_id = state.AirtableSearch_id;
  }

  dispose() {
    const airtablerecord = this.get_AirtableRecord();
    if (airtablerecord) {
      if (airtablerecord.getSelectedRecords().length <= 1) {
        airtablerecord.dispose();
      }
      this.AirtableRecord_id = undefined;
    }
    const airtablesearch = this.getAirtableSearch();
    if (airtablesearch) {
      this.AirtableSearch_id = undefined;
    }

    SelectedRecord.delete(this.instance_id);
  }

  get table() {
    return this.getAirtableSearch()?.table;
  }

  get view() {
    return this.getAirtableSearch()?.view;
  }

  get query() {
    return this.getAirtableSearch()?.query;
  }

  get record_id() {
    return this.get_AirtableRecord()?.id;
  }

  set AirtableRecord_id(value) {
    if (this.#AirtableRecord_id) {
      const relateObj = AirtableRecord.get(this.#AirtableRecord_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SelectedRecord', this.instance_id);
      }
    }
    this.#AirtableRecord_id = value;
    if (value) {
      const relateObj = AirtableRecord.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SelectedRecord', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get AirtableRecord_id() {
    return this.#AirtableRecord_id;
  }

  get_AirtableRecord() {
    return AirtableRecord.get(this.AirtableRecord_id) as AirtableRecord<T> | undefined;
  }

  set AirtableSearch_id(value) {
    if (this.#AirtableSearch_id) {
      const relateObj = AirtableSearch.get(this.#AirtableSearch_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SelectedRecord', this.instance_id);
      }
    }
    this.#AirtableSearch_id = value;
    if (value) {
      const relateObj = AirtableSearch.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SelectedRecord', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get AirtableSearch_id() {
    return this.#AirtableSearch_id;
  }

  getAirtableSearch() {
    return AirtableSearch.get(this.AirtableSearch_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = SelectedRecord.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.view === this.view &&
        sel.query === this.query &&
        sel.table === this.table &&
        sel.record_id === this.record_id,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate selected record found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`selected record: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getAirtableSearch()) {
      problems.push(
        `selected record: Could not find airtable search instance across unconditional relationship R1000: ${this.instance_id}`,
      );
    }
    return problems;
  }

  static async recover(allSelectedRecords: any[]) {
    for (const record of allSelectedRecords) {
      const newRecord = await SelectedRecord.create(record['_instance_id']);
      for (const key of Object.keys(record)) {
        newRecord[key] = record[key];
      }
    }
  }
}

export class SelectedRecordList<U extends AirtableRecordFields = AirtableRecordFields> extends DataObjectList<
  SelectedRecord<U>
> {
  getAirtableRecords() {
    return new AirtableRecordList(
      ...this.map((selectedrecord) => selectedrecord.get_AirtableRecord()).filter((sel) => !!sel),
    );
  }

  getAirtableSearchs() {
    return new AirtableSearchList(
      ...this.map((selectedrecord) => selectedrecord.getAirtableSearch()).filter((sel) => !!sel),
    );
  }
}
