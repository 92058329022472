import React, { PureComponent } from 'react';
import { Grid, Paper } from '@material-ui/core';

import SamplingParameters from '../common/SamplingParameters';
import MissionInfo from '../common/MissionInfo';
import MissionHeader from '../common/MissionHeader';
import { OnLoading, SettingsAccessLevel } from '../../../types/types';

import { CloseMissionButton, DownloadCSVButton, DownloadKmlButton, DownloadPDFButton, DownloadShapeButton } from '../buttons';
import ShowNotesButton from '../buttons/ShowOperatorNotesButton';

interface MapsMissionLoadedProps {
  onLoading: OnLoading;
  accessLevel: SettingsAccessLevel;
  mapmakingNotesVisible: boolean;
  toggleMapmakingNotes: () => void;
  openExitInterview: () => void;
}

interface MapsMissionLoadedState {
  dialogOpen: boolean;
}

export default class MapsMissionLoaded extends PureComponent<MapsMissionLoadedProps, MapsMissionLoadedState> {
  constructor(props: MapsMissionLoadedProps) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  render() {
    return (
      <Paper style={{ marginTop: 20, maxWidth: '100%', marginBottom: 20 }}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} style={{ marginTop: 10, textAlign: 'center' }}>
            <MissionHeader />
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ paddingTop: 20, paddingBottom: 20, marginLeft: 12.5 }}
          >
            <CloseMissionButton openDialog={this.props.openExitInterview} />

            <DownloadKmlButton />

            <ShowNotesButton isShown={this.props.mapmakingNotesVisible} handleClick={this.props.toggleMapmakingNotes} />

            {/* Temporarily disabling due to inconsistency with cloud CSVs */}
            {this.props.accessLevel === 'Technician' && <DownloadCSVButton />}

            {this.props.accessLevel === 'Technician' && <DownloadShapeButton />}

            {this.props.accessLevel === 'Technician' && <DownloadPDFButton accessLevel={this.props.accessLevel} />}
          </Grid>

          <Grid item xs={12} style={{ marginLeft: 18 }}>
            <SamplingParameters expanded={true} onLoading={this.props.onLoading} mapType="maps" />
          </Grid>

          <Grid item xs={12} style={{ marginLeft: 18, marginBottom: 20 }}>
            <MissionInfo expanded={true} />
          </Grid>

          {/* <MapsExitInterview
            open={this.state.dialogOpen}
            closeForm={() => this.setState({ dialogOpen: false })}
          /> */}
        </Grid>
      </Paper>
    );
  }
}
