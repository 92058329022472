import EventBus from './EventBus';
import { deflate, inflate } from 'pako';

import { alertError, alertSuccess, alertWarn } from './alertDispatcher';
import { getCurrentSession } from './dataModelHelpers';
import { handleCoreMessage, handleDumpMessage, handleHomeMessage, handleNavMessage } from './messageHandler';

import logger from './logger';
import { LocalStorageGenerator, isLocalhost } from './utils';
import { RobotControlRole } from './types/rosmsg';
import { getVersionNumber } from './version';
import { isDesktop, isMobile } from 'react-device-detect';
import {
  BasePeerMessage,
  ClientAddedOrRemoved,
  PublishClientListMessage,
  RolePeerMessage,
  RosMsgConnectionData,
  RosMsgPubData,
  WebsocketPacket,
  WebsocketPeerList,
} from './types/types';
import { MobileHelperEnabled, SimulatorEnabledStorage } from './db/local_storage';
import { FrequencyCounter } from './frequencyCounter';

const WebsocketDebugStore = LocalStorageGenerator('websocketDebug', false);

export const MSG_HEADER = 'ROSMSG';
export const PUB_HEADER = 'ROSMSGPUB';
export const PEER_HEADER = 'ROSMSGPUBPEER';
export const CONNECTION_STATUS_HEADER = 'ROSSTATUS/connection';

class RobotConnection {
  DEFAULT_PORT = isMobile && MobileHelperEnabled.get() ? 9091 : 9090;
  DEFAULT_ENDPOINT = isMobile && MobileHelperEnabled.get() ? '/ws' : '';

  RETRY_PERIOD = 2000;

  HEARTBEAT_TIMEOUT = 10000;

  CONNECTION_ATTEMPT_TIMEOUT = 2000;

  MESSAGE_TIMEOUT = 2000;

  hostname: string;
  robotControlRole: RobotControlRole;
  options: {
    endpoint: string;
    insecure: boolean;
    port: number;
  };
  closeConnectionTimeoutId?: NodeJS.Timeout;
  tryConnectionTimeout?: NodeJS.Timeout;
  pendingMessages: Map<string, any>;
  socket?: WebSocket;
  active: boolean;
  needsToHandleError: boolean;
  connected: boolean;
  retryTimeout: NodeJS.Timeout | undefined;

  clientList: WebsocketPeerList;
  clientIp: string;
  rawMessageCount: number;
  counter: FrequencyCounter<string> = new FrequencyCounter<string>(10);
  documentDispatchedMessages: number;
  ignoredCount: number;
  alerted: boolean;
  debug: boolean;

  constructor(hostname: string) {
    this.hostname = hostname;

    const savedRole = localStorage.getItem('robotControlRole');
    const DEFAULT_ROLE = isDesktop ? 'control' : 'view';
    this.robotControlRole = savedRole ? (savedRole as RobotControlRole) : DEFAULT_ROLE;

    this.closeConnectionTimeoutId = undefined;
    this.tryConnectionTimeout = undefined;

    this.pendingMessages = new Map();

    this.clientList = {};

    this.debug = WebsocketDebugStore.get();

    this._handleInboundMessageDebug = this._handleInboundMessageDebug.bind(this);
    this._handleInboundMessage = this._handleInboundMessage.bind(this);
    this._handleInboundMessageLocalDebug = this._handleInboundMessageLocalDebug.bind(this);
    this.openConnection = this.openConnection.bind(this);
    this.deactivateConnection = this.deactivateConnection.bind(this);
    this._handleCloseConnection = this._handleCloseConnection.bind(this);
    this._handleHeartbeatRecieved = this._handleHeartbeatRecieved.bind(this);
    this._publish = this._publish.bind(this);
    this._publishPeerMessage = this._publishPeerMessage.bind(this);
    this._initConnection = this._initConnection.bind(this);
    this._retryConnection = this._retryConnection.bind(this);
    this._handleConnectionError = this._handleConnectionError.bind(this);
    this._saveControlRole = this._saveControlRole.bind(this);
    this._handleDataMessage = this._handleDataMessage.bind(this);
  }

  mockListener = async (event: CustomEvent<WebsocketPacket>) => {
    await this._handleInboundMessage({ data: JSON.stringify(event.detail) }, true);
  };

  /**
   * Open socket connection with robot
   */
  openConnection() {
    clearTimeout(this.retryTimeout);
    clearTimeout(this.tryConnectionTimeout);
    if (SimulatorEnabledStorage.get()) {
      dispatchRosMsgConnection({ hostname: this.hostname, status: 'connecting' });
      setTimeout(() => {
        dispatchRosMsgConnection({ hostname: this.hostname, status: 'connected' });

        // @ts-ignore
        document.addEventListener('ROBOT_MOCK', this.mockListener);

        // setTimeout(() => {
        document.dispatchEvent(
          new CustomEvent<WebsocketPacket>('ROBOT_MOCK', {
            detail: {
              type: 'data',
              topic: 'robot_name',
              compressed: false,
              data: {
                data: 'K-SIM',
              },
            },
          }),
        );
        // }, 1000);
      }, 1000);
      return;
    }
    this.retryTimeout = undefined;
    try {
      this.socket = new WebSocket(
        `${this.options?.insecure ? 'ws' : 'wss'}://${this.hostname}:${this.options?.port || this.DEFAULT_PORT}${this.options?.endpoint || this.DEFAULT_ENDPOINT}`,
      );
    } catch (error) {
      if (!isLocalhost) {
        console.log('Unable to connect to robot');
      }
    }
    if (!this.socket) {
      return;
    }
    this.socket.onopen = this._initConnection;
    this.socket.onclose = this._handleCloseConnection;
    this.socket.onerror = this._handleConnectionError;
    // if our debug flag is set,
    this.socket.onmessage = SimulatorEnabledStorage.get()
      ? this._handleInboundMessageLocalDebug
      : this.debug
        ? this._handleInboundMessageDebug
        : this._handleInboundMessage;
    this.active = true;
    this.tryConnectionTimeout = setTimeout(() => {
      this.socket?.close();
    }, this.CONNECTION_ATTEMPT_TIMEOUT);
    dispatchRosMsgConnection({ hostname: this.hostname, status: 'connecting' });
  }

  /**
   * Deactivates connection completely
   */
  deactivateConnection() {
    clearTimeout(this.retryTimeout);
    clearTimeout(this.tryConnectionTimeout);
    this.retryTimeout = undefined;
    this.active = false;
    this._handleCloseConnection();

    // @ts-ignore
    document.removeEventListener('ROBOT_MOCK', this.mockListener);
  }

  _handleConnectionError(error) {
    if (this.needsToHandleError) {
      alertWarn(`Robot connection closed!`);
      console.error('Error in websocket: ', JSON.stringify(error));
      this.needsToHandleError = false;
    }

    // @ts-ignore
    document.removeEventListener('ROBOT_MOCK', this.mockListener);
  }

  _clearRobotData() {
    const recentEvents = EventBus.getRecentEvents();
    for (const recentEvent of Object.keys(recentEvents)) {
      if (recentEvent.includes('ROSMSG')) {
        EventBus.clearEvent(recentEvent);
      }
    }
    this.clientIp = '';
    this.clientList = {};
    EventBus.dispatch('ROSRESET');
  }

  /**
   * Close socket connection
   */
  async _handleCloseConnection(event?) {
    if (this.connected) {
      this.needsToHandleError = true;
      this._clearRobotData();
    }
    this.connected = false;
    EventBus.remove(PUB_HEADER, this._publish);
    EventBus.remove(PEER_HEADER, this._publishPeerMessage);
    clearTimeout(this.closeConnectionTimeoutId);
    clearTimeout(this.tryConnectionTimeout);
    this.closeConnectionTimeoutId = undefined;
    if (this.socket && ([this.socket.OPEN, this.socket.CONNECTING] as number[]).includes(this.socket.readyState)) {
      this.socket.close();
    }
    this.socket = undefined;
    dispatchRosMsgConnection({ hostname: this.hostname, status: 'disconnected' });
    if (this.active && !this.retryTimeout) {
      this._retryConnection();
    }
    const session = getCurrentSession();
    logger.setRobot();
    if (session) {
      session.robot_name = '';
    }

    // @ts-ignore
    document.removeEventListener('ROBOT_MOCK', this.mockListener);
  }

  /**
   * Retry connection
   */
  _retryConnection() {
    if (this.active) {
      this.retryTimeout = setTimeout(() => {
        this.openConnection();
        this.retryTimeout = undefined;
      }, this.RETRY_PERIOD);
    }
  }

  /**
   * Callback for when connection gets initialized
   */
  _initConnection() {
    this.connected = true;
    this.rawMessageCount = 0;
    this.ignoredCount = 0;
    this.documentDispatchedMessages = 0;
    this.alerted = true;
    clearTimeout(this.tryConnectionTimeout);
    EventBus.on(PUB_HEADER, this._publish);
    EventBus.on(PEER_HEADER, this._publishPeerMessage);
    const appVersion = getVersionNumber();
    dispatchRosMsgConnection({ hostname: this.hostname, status: 'connected' });
    dispatchRosMsgPeer({
      hostname: this.hostname,
      msg: { data: appVersion },
      tag: Math.round(Math.random() * 100000).toString(),
      topic: 'app_version',
      broadcast: 'send_to_self',
    });
    this.setControlRole(this.robotControlRole);
    this._handleHeartbeatRecieved();
    alertSuccess('Robot successfully connected!');
  }

  /**
   * Publish a message over the socket given a topic and message
   */
  async _publish({ topic, msg, compress, expectationParams }: RosMsgPubData) {
    if (this.robotControlRole === 'view') {
      alertWarn('Your app does not have robot control. Plesase Take Control before changing any other settings');
      // TODO we should maybe warn the sender that we aren't sending robot publish/control messages...
      return;
    }
    // console.log(`${topic},${typeof msg === 'string' ? msg : JSON.stringify(msg)},${compress}`);
    if (compress) {
      msg = Buffer.from(deflate(JSON.stringify(msg))).toString('base64');
    }
    // { type: 'data', topic, data: msg, compressed: boolean}
    this._handleMessageVerification(topic, msg, expectationParams);
    this.socket?.send(JSON.stringify({ type: 'data', topic, data: msg, compressed: compress }));
  }

  /**
   * Publish a message over the socket given a topic and message
   */
  async _publishPeerMessage({
    topic,
    msg,
    broadcast,
    compress,
    expectationParams,
  }: {
    topic: string;
    msg: any;
    broadcast: 'send_to_others' | 'send_to_self' | 'send_to_all';
    compress: boolean;
    expectationParams: any;
  }) {
    // console.log(`>>> ROSMSGPEER ${topic},${JSON.stringify(msg)},${broadcast}`);
    if (compress) {
      msg = Buffer.from(deflate(JSON.stringify(msg))).toString('base64');
    }
    this._handleMessageVerification(topic, msg, expectationParams);
    this.socket?.send(JSON.stringify({ type: 'peer_msg', topic, broadcast, data: msg, compressed: compress }));
  }

  _handleMessageVerification(topic: string, msg, expectationParams) {
    if (expectationParams) {
      const { expectedTopic, expectedValue } = expectationParams;
      let expectsValue = msg;
      let expectsTopic = topic;
      if (expectedValue !== undefined) {
        expectsValue = expectedValue;
      }
      if (expectedTopic !== undefined) {
        expectsTopic = expectedTopic;
      }

      this._startMessageTimer(expectsTopic, expectsValue, expectationParams);
    }
  }

  _startMessageTimer(topic: string, data, expectationParams, tag?) {
    const existingMessage = this.pendingMessages.get(topic);
    if (existingMessage && tag === existingMessage.tag) {
      console.log(`${Date.now()} Clear existing timer for ${topic}`);
      clearTimeout(existingMessage.timeoutId);
      this.pendingMessages.delete(topic);
    }
    let errMessage: string = '';
    let successMessage = null;
    let doneCallback = null;
    let expectedTimeout = this.MESSAGE_TIMEOUT;
    if (expectationParams) {
      if (expectationParams.messages) {
        errMessage = expectationParams.messages.error;
        successMessage = expectationParams.messages.success;
      }
      if (expectationParams.doneCallback) {
        doneCallback = expectationParams.doneCallback;
      }
      if (expectationParams.expectedTimeout) {
        expectedTimeout = expectationParams.expectedTimeout;
      }
    }
    console.log(`${Date.now()} Set pending for ${topic} (${expectedTimeout})`);
    this.pendingMessages.set(topic, {
      timeoutId: setTimeout(
        async () => await this._messageFailure(topic, errMessage, doneCallback, false, false),
        expectedTimeout,
      ),
      data,
      successMessage,
      doneCallback,
      tag,
    });
  }

  async _messageFailure(topic: string, errorMessage: string, doneCallback, received: boolean, dataCorrect: boolean) {
    if (errorMessage) {
      alertError(errorMessage);
    }

    console.log(`${Date.now()} Failure, delete pending for ${topic}`);
    this.pendingMessages.delete(topic);

    if (doneCallback) {
      await doneCallback(received, dataCorrect);
    }
  }

  /**
   *
   * @param {string} topic
   * @param {string} successMessage
   * @param doneCallback
   */
  async _messageSuccess(topic: string, successMessage: string, doneCallback) {
    if (successMessage) {
      alertSuccess(successMessage);
    }

    console.log(`${Date.now()} Success, delete pending for ${topic}`);
    this.pendingMessages.delete(topic);

    if (doneCallback) {
      await doneCallback(true, true);
    }
  }

  /**
   * Resets the heartbeat timeout
   */
  _handleHeartbeatRecieved() {
    clearTimeout(this.closeConnectionTimeoutId);
    this.closeConnectionTimeoutId = undefined;
    this.closeConnectionTimeoutId = setTimeout(() => {
      if (this.closeConnectionTimeoutId) {
        this._handleCloseConnection();
        alertWarn('Lost heartbeat with robot. Disconnecting...');
        console.warn('did not recieve heartbeat for two seconds, closing');
      }
    }, this.HEARTBEAT_TIMEOUT);
  }

  /**
   *
   * @param {string} topic
   * @param {any}    data
   */
  async _handleDataMessage(
    topic: string,
    // data: { status?: number, result: number, status_code: number, postlaunch_depth_error: number }
    // data: RosCoreComplete | { status?: number, result: number, status_code: number, postlaunch_depth_error: number }
    data: any, // TODO typing this as any for now, will add better ROS types later
  ) {
    //console.log(`<<< ROSMSG ${topic},${JSON.stringify(data)},${data.result}`);
    if (topic === 'arm/core_complete') {
      console.log('arm/core_complete', data);
      handleCoreMessage(data.result, data);
    } else if (topic === 'arm/dump_complete') {
      console.log('arm/dump_complete', data);
      handleDumpMessage(data.result, data);
    } else if (topic === 'arm/home_complete') {
      console.log('arm/home_complete', data);
      handleHomeMessage(data.result, data);
    } else if (topic === 'navigation/nav_leg_complete') {
      console.log('navigation/nav_leg_complete', JSON.stringify(data));
      handleNavMessage(data);
    }
  }

  /**
   *
   * @param {string} topic
   * @param {any}    data
   */
  _handlePeerMessage = async (topic: string, data: BasePeerMessage) => {
    // console.log(`<<< ROSMSGPEER: ${topic}, ${JSON.stringify(data)}`);
    const session = getCurrentSession();
    this.clientIp = data.dest_ip;
    if (topic === 'send_role') {
      dispatchRosMsgPeer({
        hostname: session?.robot_hostname || '',
        msg: { data: { role: this.robotControlRole } },
        tag: Math.round(Math.random() * 100000).toString(),
        topic: 'client_role',
        broadcast: 'send_to_others',
      });
    } else if (topic === 'client_role') {
      const _data = data as RolePeerMessage;
      // console.log(`<<< ROSMSGPEER, data=${JSON.stringify(data)} ${Object.keys(this.clientList).includes(_data.sender_ip)}`);
      if (Object.keys(this.clientList).includes(_data.sender_ip)) {
        this.clientList[_data.sender_ip].role = _data.data.role;
      }
      // if someone broadcasts as a control, become a view
      if (_data.data.role === 'control' && _data.sender_ip !== this.clientIp) {
        logger.log(
          'ROBOTCONNECTION',
          `Received control message from ${_data.sender_ip}, setting role to view (my ip = ${this.clientIp}))`,
        );
        this.setControlRole('view');
      }
    } else if (topic === 'client_list') {
      const _data = data as PublishClientListMessage;
      for (const client of _data.clients) {
        if (!Object.keys(this.clientList).includes(client.ip)) {
          this.clientList[client.ip] = { ip: client.ip, role: 'unknown' };
        }
      }
    } else if (topic === 'client_added') {
      const _data = data as ClientAddedOrRemoved;
      if (!Object.keys(this.clientList).includes(_data.ip)) {
        // if we already have a client, then we will warn the user
        // about a new client being added
        if (Object.keys(this.clientList).length >= 1) {
          alertWarn(`Warning: New client (${_data.ip}), unknown role`);
        }
        this.clientList[_data.ip] = { ip: _data.ip, role: 'unknown' };
        // if a client was just added, and we are a control, then we will force everyone else to be a view
        if (this.robotControlRole === 'control') {
          this.setControlRole('control');
        }
      }
    } else if (topic === 'client_removed') {
      const _data = data as ClientAddedOrRemoved;
      if (Object.keys(this.clientList).includes(_data.ip)) {
        alertWarn(`Warning: Client (${_data.ip}) removed, role ${this.clientList[_data.ip].role}`);
        delete this.clientList[_data.ip];
      }
    }
  };

  async _handleInboundMessageDebug(event: { data: string }) {
    console.log(`${Date.now()} _handleInboundMessage ${event.data}`);
    logger.log('ROBOTCONNECTION', `${event.data}`);
    await this._handleInboundMessage(event);
  }

  // This method is used for when we are running local dev and we want to ignore robot position
  // messages so we can use our own debugger tools
  async _handleInboundMessageLocalDebug(event: { data: string }) {
    const { topic }: WebsocketPacket = JSON.parse(event.data);

    if (SimulatorEnabledStorage.get()) {
      if (topic?.includes('position')) {
        // ignore message in local debug for position so we can use our debugger tools
        return;
      }
    }

    await this._handleInboundMessage(event);
  }

  /**
   * Handle for inbound message event
   * @param {object} event Inbound message event
   */
  async _handleInboundMessage(event: { data: string }, mock_message = false) {
    this.rawMessageCount++;
    this.counter.push(event.data);
    try {
      const { type, topic, data: _data, compressed }: WebsocketPacket = JSON.parse(event.data);
      const session = getCurrentSession();

      if (!session) {
        return;
      }

      // assign this separately so the rest can stay const
      let data = _data;
      if (compressed) {
        data = JSON.parse(Buffer.from(inflate(Buffer.from(data, 'base64'))).toString()); // decompress the data
      }

      this._handleHeartbeatRecieved();

      // this feels scary, like it has performance concerns...
      if (this._ignoreMessage(topic, data)) {
        // TODO warn user? probably not...
        this.ignoredCount++;
        return;
      }

      if (type === 'data') {
        // TODO consider moving this to a different file -LPS
        // TODO in order to accomplish moving this code out of robot connection, we should make
        // some kind of special message handlers that can globally register themselves for
        // specific topics and then just register them when we create the socket.
        await this._handleDataMessage(topic, data);
      } else if (type === 'error') {
        alertError(`Robot connection error: ${data}`);
      } else if (type === 'warning') {
        alertWarn(`Robot connection warning: ${data}`);
      } else if (type === 'peer_msg') {
        await this._handlePeerMessage(topic, data);
      } else if (type === 'heartbeat') {
        return;
      }

      const pendingMessage = this.pendingMessages.get(topic);
      if (pendingMessage) {
        console.log(`${Date.now()} Have pending messages for ${topic}`);
        console.log(`pendingMessage.data: ${JSON.stringify(pendingMessage.data)}, data: ${JSON.stringify(data)}`);
        if (JSON.stringify(pendingMessage.data) === JSON.stringify(data)) {
          clearTimeout(pendingMessage.timeoutId);
          await this._messageSuccess(topic, pendingMessage.successMessage, pendingMessage.doneCallback);
        } else {
          console.log('Data mismatch??');
          await this._messageFailure(topic, pendingMessage.failureMessage, pendingMessage.doneCallback, true, false);
        }
      }
      EventBus.dispatch(`${MSG_HEADER}/${topic}`, { hostname: session.robot_hostname, msg: data });
      this.documentDispatchedMessages++;
      if (!this.alerted && this.rawMessageCount !== this.documentDispatchedMessages + this.ignoredCount) {
        this.alerted = true;
        console.warn(
          `rawMessageCount: ${this.rawMessageCount}, documentDispatchedMessages: ${this.documentDispatchedMessages}, ignoredCount: ${this.ignoredCount}`,
        );
        await logger.log(
          'ROBOTCONNECTION',
          `raw: ${this.rawMessageCount}, ignore: ${this.ignoredCount}, dispatched: ${this.documentDispatchedMessages}`,
        );
        this.rawMessageCount = 0;
        this.ignoredCount = 0;
        this.documentDispatchedMessages = 0;
      }
    } catch (err) {
      console.error('Could not parse and send message recieved over websocket. ', err);
    }
  }

  private _ignoreMessage(topic: string, data: any) {
    if (this.robotControlRole !== 'view') {
      return false;
    }

    if (!topic) {
      return true;
    }

    if (topic.includes('navigation/waypoints')) {
      return true;
    }

    if (topic.includes('app/barcode')) {
      alertWarn(
        'We noticed a barcode scan, but you do not have control of the robot. Please take control before scanning any barcodes.',
      );
      return true;
    }

    if (topic === 'arm/core_complete') {
      return true;
    }

    return false;
  }

  _saveControlRole(role: RobotControlRole) {
    this.robotControlRole = role;
    localStorage.setItem('robotControlRole', role);
    EventBus.dispatch('ROBOT_ROLE_UPDATED', role);
  }

  async setControlRole(role: RobotControlRole) {
    // console.log(`ROSMSGPEER setControlRole(${role})`)
    const session = getCurrentSession();
    dispatchRosMsgPeer({
      hostname: session?.robot_hostname || '',
      msg: { data: { role: role } },
      tag: Math.round(Math.random() * 100000).toString(),
      topic: 'client_role',
      broadcast: 'send_to_others',
    });

    this._saveControlRole(role);
  }
}

export function dispatchRosMsgPub(data: RosMsgPubData) {
  // TODO settings interception
  EventBus.dispatch(PUB_HEADER, data);
  // EventBus.dispatch('ROSMSGPEER', data);
}

export function dispatchRosMsgPeer(
  data: RosMsgPubData & { broadcast: 'send_to_all' | 'send_to_others' | 'send_to_self' },
) {
  // TODO settings interception
  EventBus.dispatch(PEER_HEADER, data);
}

export function dispatchRosMsgConnection(data: RosMsgConnectionData) {
  EventBus.dispatch('CONNECTION_STATUS_HEADER', data);
}

type RobotConnectionLookup = Record<string, RobotConnection>;
const connections: RobotConnectionLookup = {};

export default function getConnection(
  hostname: string,
  options?: { insecure: boolean; port: number; endpoint: string },
  activate = false,
) {
  if (hostname) {
    let conn: RobotConnection = connections[hostname];
    if (!conn) {
      conn = new RobotConnection(hostname);
      if (options) {
        conn.options = options;
      }
      connections[hostname] = conn;
    }
    if (activate) {
      conn.openConnection();
    }
    return conn;
  }
}
