import React, { PureComponent } from 'react';

import { Switch, withStyles, FormControlLabel } from '@material-ui/core';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    marginRight: '10px',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

interface SwitchButtonProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  disabled?: boolean;
}

export default class SwitchButton extends PureComponent<SwitchButtonProps> {
  render() {
    return (
      <FormControlLabel
        control={
          <AntSwitch disabled={this.props.disabled} checked={this.props.checked} onChange={this.props.onChange} />
        }
        label={this.props.label}
      />
    );
  }
}
