import React, { PureComponent } from 'react';

import LoadingButton from '../../utils/LoadingButton';
import { Grid } from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { saveCurrentMissionRecoveryZip } from '../../../dataModelHelpers';
import { alertInfo } from '../../../alertDispatcher';

interface DownloadRecoveryButtonProps {}

export default class DownloadRecoveryButton extends PureComponent<DownloadRecoveryButtonProps> {
  render() {
    return (
      <Grid item>
        <LoadingButton
          variant="outlined"
          onClick={async () => {
            alertInfo('Starting manual download process');
            await saveCurrentMissionRecoveryZip({ tag: 'dl' });
            alertInfo('End manual download process');
          }}
          data-testid="mission-download-recovery"
        >
          <CloudDownloadIcon style={{ paddingRight: 7.5 }} />
          Recovery
        </LoadingButton>
      </Grid>
    );
  }
}
