import { processScan } from './barcodes';
import EventBus from './EventBus';

const PREFIX = '!@#'; //make sure prefix characters all all different
const SUFFIX = '$%^'; //make sure suffix characters all all different

// !@#6856786453$%^
const scannerState = {
  barcode: '',
  pastPrefix: false,
};

/**
 * This is the entry point and will be called any time scanning is available.
 * It should request access (if necessary), set up the scanner, and register any listeners.
 */
function initScanner() {
  localStorage.setItem('init scanner', new Date().toUTCString());
  document.addEventListener('keypress', handleKeyPress);
  EventBus.on('ROSMSG/app/barcode', handleROSBarcode);
}

/**
 * This another entry point and will be called any time scanning becomes unavailable.
 * It clean up and gracefully release all resources and listeners.
 * It should also be able to be called _before_ initScanner is called and not throw an error.
 */
function deinitScanner() {
  localStorage.setItem('deinint scanner', new Date().toUTCString());
  document.removeEventListener('keypress', handleKeyPress);
  EventBus.remove('ROSMSG/app/barcode', handleROSBarcode);
}

async function handleKeyPress({ key }: KeyboardEvent) {
  const { barcode, pastPrefix } = scannerState;
  const newBarcode = barcode + key;
  if (pastPrefix) {
    await handlePastPrefix(newBarcode);
  } else {
    handlePrefix(newBarcode);
  }
}

/**
 * This processes barcode messages from ROS.
 * Example msg: {"hostname":"local.rogoag.com","msg":{"data":"RG621390598042"}}
 * @param msg The ROS message containing the barcode data
 */
async function handleROSBarcode(payload: { hostname: string; msg: { data: string } }) {
  await processScan(payload.msg.data);
}

const handlePastPrefix = async (newBarcode: string) => {
  const lastThreeChars = newBarcode.slice(-3);
  const hasSuffix = lastThreeChars === SUFFIX;
  const hasDupPrefix = lastThreeChars === PREFIX;

  if (hasSuffix) {
    const cleaned = newBarcode.substring(PREFIX.length, newBarcode.length - SUFFIX.length);
    await processScan(cleaned);
    scannerState.barcode = '';
    scannerState.pastPrefix = false;
  } else if (hasDupPrefix) {
    scannerState.barcode = PREFIX;
  } else {
    scannerState.barcode = newBarcode;
  }
};

const handlePrefix = (newBarcode: string) => {
  const validCharacter = PREFIX[newBarcode.length - 1] === newBarcode[newBarcode.length - 1];
  const pastPrefix = PREFIX === newBarcode;

  scannerState.barcode = validCharacter ? newBarcode : '';
  scannerState.pastPrefix = pastPrefix;
};

export { initScanner, deinitScanner };
