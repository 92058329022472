import { FrequencyCounter } from './frequencyCounter';

// TODO this could be narrowed down to more specific types where used
type EventBusCallback = (payload: object | string | boolean | any, event: string) => void | Promise<void>;

type LocalCallback = (e: any) => Promise<void>;
type CallbackRegistration = { callback: EventBusCallback; cb: LocalCallback };

class EventBus {
  recentEvents: Record<string, CustomEvent> = {};
  registeredCallbacks = new Map<string, CallbackRegistration[]>();
  rateCounter = new FrequencyCounter<string>(10);

  /**
   * Register a callback on a specific event.
   * @param {string} event - the event to listen for
   * @param {function} callback - a callback that takes a single argument for the resulting event data
   * @param {boolean} receiveMostRecent - if true, the dispatcher will invoke the callback with the most recent event
   */
  on(event: string, callback: EventBusCallback, receiveMostRecent: boolean = false) {
    // create the callback and store in the map
    if (callback === undefined) {
      throw new Error('callback is undefined');
    }
    // TODO technically the event listener is for Event, so ideally we'd do a TypeScript
    // type guard call to check for 'detail' before proceeding here...
    const cb = async (e: Event) => {
      //console.log(`${e.type}:${JSON.stringify(e.detail)}`);
      try {
        if (!('detail' in e)) return;
        await callback(e.detail, e.type);
      } catch (err) {
        console.log(e);
        console.log(err);
      }
    };

    let callbacks = [
      {
        callback,
        cb,
      },
    ];

    let callbackExists = false;

    const registeredCallbacksForEvent = this.registeredCallbacks.get(event);
    if (registeredCallbacksForEvent) {
      if (registeredCallbacksForEvent.some((callbackRegistration) => callbackRegistration.callback === callback)) {
        callbackExists = true;
      } else {
        callbacks = [...registeredCallbacksForEvent, ...callbacks];
      }
    }

    // invoke immediately with the most recent event
    if (receiveMostRecent && this.recentEvents[event]) {
      setTimeout(async () => await cb(this.recentEvents[event]), 0);
    }

    if (!callbackExists) {
      this.registeredCallbacks.set(event, callbacks);
      // register the listener
      document.addEventListener(event, cb);
    }
  }

  /**
   *
   * @param {string} topic - the topic to receive the event data for.
   * @returns
   */
  recent(topic: string) {
    //console.log(JSON.stringify(this.recentEvents));
    if (this.recentEvents.hasOwnProperty(topic)) {
      return this.recentEvents[topic];
    }
    return undefined;
  }

  /**
   * Unregister a callback on a specific event.
   * @param {string} event - the event to unregister for
   * @param {function} callback - the original registerd callback
   */
  remove(event: string, callback: EventBusCallback) {
    const registeredCallbacks = this.registeredCallbacks.get(event);
    if (registeredCallbacks) {
      const registeredCallback = registeredCallbacks.find((rcb) => rcb.callback === callback);
      if (registeredCallback) {
        document.removeEventListener(event, registeredCallback.cb);
        const callbacks = registeredCallbacks.filter((cb) => cb.callback !== callback);
        this.registeredCallbacks.set(event, callbacks);

        if (callbacks.length === 0) {
          this.registeredCallbacks.delete(event);
        }
      }
    }
  }

  report() {
    const report_result: Record<string, string[]> = {};
    for (let [key, value] of this.registeredCallbacks) {
      report_result[key] = value.map((cb) => cb.callback.name || cb.callback.toString());
    }
    return report_result;
  }

  stats() {
    // log unique registered events
    let listenerCount = 0;
    Array.from(this.registeredCallbacks.keys()).forEach(
      (key) => (listenerCount += this.registeredCallbacks?.get(key)?.length || 0),
    );
    // console.log(`EventBus: ${this.registeredCallbacks.size} registered events, ${listenerCount} listeners`);
    return {
      uniqueMessageCount: this.registeredCallbacks.size,
      listenerCount,
      messageFrequency: this.rateCounter.getAverageHz(),
      totalMessages: this.rateCounter.length,
      allTimeMessageFrequency: this.rateCounter.getAllTimeHz(),
    };
  }

  getRecentEvents() {
    return this.recentEvents;
  }

  clearEvent(event: string) {
    delete this.recentEvents[event];
  }

  /**
   * Register a callback on a specific event. It will only be handled once and then automatically unregistered.
   * @param {string} event - the event to listen for
   * @param {function} callback - a callback that takes a single argument for the resulting event data
   */
  once(event: string, callback: EventBusCallback) {
    this.rateCounter.push(event);
    const cb = async (e: Event) => {
      // TODO technically the event listener is for Event, so ideally we'd do a TypeScript
      // type guard call to check for 'detail' before proceeding here...
      if (!('detail' in e)) return;
      await callback(e.detail, event);
      document.removeEventListener(event, cb);
    };
    document.addEventListener(event, cb);
  }

  /**
   * Dispatch an event with some payload data
   * @param {string} event - the event to dispatch
   * @param {object} data - the data payload to deliver to listeners
   */
  dispatch(event: string, data?: any) {
    this.rateCounter.push(event);
    const e = new CustomEvent(event, { detail: data }); // create the event
    // if (event.startsWith('ROSMSG')) console.log(`${event}:${JSON.stringify(data)}`);
    // if (event === 'ROSMSG/position') {
    //   console.log(`${event}: ${JSON.stringify(data)}`);
    // }
    this.recentEvents[event] = e; // save the most recent of each event
    document.dispatchEvent(e); // dispatch the event
  }
}

const eb = new EventBus();
Object.freeze(eb);

export interface RobotMessage<T> {
  hostname: string;
  msg: T;
}

export type RobotNumberMessage = RobotMessage<{ data: number }>;

export type RobotEventCallback<T> = (robotMessage: RobotMessage<T>) => void | Promise<void>;

export const onRobotMessage = <T>(topic: string, callback: RobotEventCallback<T>, receiveMostRecent?: boolean) => {
  eb.on(`ROSMSG/${topic}`, callback, receiveMostRecent);
};

export const removeRobotMessageCallback = <T>(topic: string, callback: RobotEventCallback<T>) => {
  eb.remove(`ROSMSG/${topic}`, callback);
};

export default eb;
