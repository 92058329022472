import { PureComponent } from 'react';

import { LoadingButton } from '../../utils';
import { Grid } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

interface CloseMissionButtonProps {
  openDialog: () => void;
}

export default class CloseMissionButton extends PureComponent<CloseMissionButtonProps> {
  render() {
    return (
      <Grid item>
        <LoadingButton
          variant="outlined"
          style={{
            color: '#cc0000',
            borderColor: '#cc0000',
          }}
          onClick={this.props.openDialog}
        >
          <CloseIcon style={{ marginRight: 7.5 }} />
          Close Mission
        </LoadingButton>
      </Grid>
    );
  }
}
