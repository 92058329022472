import React, { PureComponent } from 'react';

import { Checkbox, Grid } from '@material-ui/core';

import { LoadingButton } from '../../utils';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { allBarcodesValid } from '../../../barcodes';
import { alertError, cancelConfirmTag } from '../../../alertDispatcher';
import { getCurrentMission } from '../../../dataModelHelpers';
import logger from '../../../logger';
import download from 'downloadjs';
import { alertConfirmDownload } from '../helpers/downloads';

interface Props {}

interface State {
  usePulledLocations: boolean;
  makeKMZ: boolean;
}

export default class DownloadKmlButton extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      usePulledLocations: false,
      makeKMZ: false,
    };

    this.downloadKML = this.downloadKML.bind(this);
  }

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
  }

  async downloadKML() {
    try {
      if (allBarcodesValid() || (await alertConfirmDownload(this.constructor.name))) {
        const mission = getCurrentMission();
        if (!mission) {
          alertError('No mission available');
          return;
        }
        const makeKMZ = this.state.makeKMZ;
        const kml = await mission?.to_kml({
          insert_boxes: true,
          use_pulled_locations: this.state.usePulledLocations,
        });
        if (!kml) {
          alertError('Could not export KML');
          return;
        }

        const filename = `${mission.name}.${makeKMZ ? 'kmz' : 'kml'}`;

        await logger.log('DOWNLOAD_KML', { filename });

        const mimeType = makeKMZ ? 'application/vnd.google-earth.kmz' : 'application/vnd.google-earth.kml+xml';
        const data = makeKMZ ? await kml.toKMZ(filename) : kml.toString();
        // const data = exampleRogoKML();
        if (data) {
          download(data, filename, mimeType);
        } else {
          console.error('Could not export KML', kml);
        }
      }
    } catch (err) {
      console.error('Could not export KML', err);
    }
  }

  render() {
    return (
      <Grid item>
        <LoadingButton variant="outlined" onClick={this.downloadKML} data-testid="mission-download-kml">
          <CloudDownloadIcon style={{ paddingRight: 7.5 }} />
          Mission File
        </LoadingButton>
        <Checkbox
          color="primary"
          checked={this.state.usePulledLocations}
          onChange={(e) => {
            this.setState({ usePulledLocations: e.target.checked });
          }}
        />
        Use Pulled Location
        <Checkbox
          color="primary"
          checked={this.state.makeKMZ}
          onChange={(e) => {
            this.setState({ makeKMZ: e.target.checked });
          }}
        />
        KMZ?
      </Grid>
    );
  }
}
