import { KML_FEATURE_TYPE } from '../featureTypes';
import { ObjectValues } from '../types/types';

export const enum ZoneType {
  FIELD = 0,
  UNSAFE = 1,
  SLOW = 2,
  PULLIN = 3,
  COLLECTED_FIELD = 4,
  SAMPLE_ZONE = 5,
}

export const ZONE_RECORDING_TYPE = {
  UNSAFE: KML_FEATURE_TYPE.UNSAFE,
  SLOW: KML_FEATURE_TYPE.SLOW,
  COLLECTED_FIELD: KML_FEATURE_TYPE.COLLECTED_FIELD,
  GENERIC_ZONE: KML_FEATURE_TYPE.GENERIC_ZONE,
  SAMPLE_ZONE: KML_FEATURE_TYPE.SAMPLE_ZONE,
} as const;

export type ZoneRecordingType = ObjectValues<typeof ZONE_RECORDING_TYPE>;
