import { Coordinate } from 'ol/coordinate';
import { orderBy, flatten, isEmpty } from 'lodash';
import { Mission, SamplingSpec, GridPatterns, Sample, SoilCore, Waypoint, CorePoint, SampleCentroid, Job } from '../db';
import { cleanSet, CircleShape, LineShape, BaseShape, rotatePoint } from '../utils';
import { ft_to_m, METERS_BETWEEN_CORE_LINES_FOR_MULTI_LAB } from '../constants';
import { fromLatLon, toLatLon } from 'utm';

class PathGridGenerator {
  mission: Mission;
  spec: SamplingSpec;
  initialPrevPoint: Coordinate | null;

  constructor(mission: Mission, spec: SamplingSpec, initialPrevPoint: Coordinate | null = null) {
    this.mission = mission;
    this.spec = spec;
    this.initialPrevPoint = initialPrevPoint;
  }

  generate() {
    // Calculate the shape of each cluster of samples.
    let subsamplesShape: BaseShape | null = null;
    if (this.spec.pattern_type === GridPatterns.CIRCLE) {
      subsamplesShape = new CircleShape(this.spec.cores, ft_to_m(this.spec.radius));
    } else if (this.spec.pattern_type === GridPatterns.LINE) {
      subsamplesShape = new LineShape(this.spec.cores, ft_to_m(this.spec.length), this.spec.angle);
    }

    if (this.spec.cores === 0) {
      throw new Error('No cores specified');
    }

    if (!subsamplesShape) {
      return;
    }

    const sampleGroups = this.groupSamplesWithCommonSampleSite();
    if (isEmpty(sampleGroups)) {
      return;
    }

    const orderedSamples = orderBy(sampleGroups, (samples) => samples[0].order, ['asc']);

    orderedSamples.forEach((samples) => {
      const groupSamplesCount = samples.length;

      let prevSampleID: string = '';
      let sampleNumberInGroup = 0;
      for (const sample of samples) {
        const sampleSite = sample.getSampleSite();
        const sampleCentroid = sampleSite.getSampleCentroid();
        if (sampleCentroid) {
          sampleNumberInGroup += 1;

          this.generateCores(
            sample,
            this.mission,
            this.initialPrevPoint,
            this.chooseSampleLocation(sampleCentroid, groupSamplesCount, sampleNumberInGroup, this.spec.angle),
            subsamplesShape,
            prevSampleID,
          );

          prevSampleID = sample.sample_id!;
        }
      }
    });

    // Renumber waypoints
    for (const [i, waypoint] of orderBy(this.mission.getWaypoints(), ['waypoint_number'], ['asc']).entries()) {
      waypoint.waypoint_number = i + 1;
    }
  }

  private chooseSampleLocation(
    sampleCentroid: SampleCentroid,
    groupSamplesCount: number,
    sampleNumberInGroup: number,
    angle: number = 0,
  ) {
    const metersBetweenCoreLines =
      this.spec.parallel_line_distance_ft !== undefined
        ? ft_to_m(this.spec.parallel_line_distance_ft)
        : METERS_BETWEEN_CORE_LINES_FOR_MULTI_LAB;

    // If a group consists of a single sample - no movement is required.
    const sampleUtmLoc = fromLatLon(sampleCentroid.lat, sampleCentroid.lon);
    if (groupSamplesCount === 1) {
      return sampleUtmLoc;
    }

    // Save the original centroid position
    const centroidGeometryPoint = {
      x: sampleUtmLoc.easting,
      y: sampleUtmLoc.northing,
    };

    const evenNumberOfSamples = groupSamplesCount % 2 === 0;

    // If the number of samples in group is even, we want to move the sampleCentroid,
    // so that cores are generated evenly on each side of a centroid.
    // If the number of samples is odd, then we need to move the centroid,
    // so that cores in the middle lie on the middle sample line.
    const moveLineLengths =
      Math.floor(groupSamplesCount / 2) - (evenNumberOfSamples ? 0.5 : 0) - sampleNumberInGroup + 1;

    const moveMeters = moveLineLengths * metersBetweenCoreLines;

    // First move the sample the required distance.
    sampleUtmLoc.northing -= moveMeters;

    // Then rotate around the original centroid.
    const rotatedCenter = rotatePoint(
      {
        x: sampleUtmLoc.easting,
        y: sampleUtmLoc.northing,
      },
      centroidGeometryPoint,
      angle,
    );

    sampleUtmLoc.easting = rotatedCenter.x;
    sampleUtmLoc.northing = rotatedCenter.y;

    return sampleUtmLoc;
  }

  private groupSamplesWithCommonSampleSite(): { [sampleID: string]: Array<Sample> } {
    const specCount = this.mission.getSamplingSpecs().length;
    const groups = {};

    cleanSet(orderBy(flatten(this.mission.getSampleSites().map((site) => site.getSamples())), ['order'], ['asc']))
      .filter((sample) => !sample.skipped_or_deleted)
      .forEach((sample) => {
        if (!sample.sample_id) {
          return;
        }

        const groupID = Math.floor((parseInt(sample.sample_id) - 1) / specCount);

        if (!groups[groupID]) {
          groups[groupID] = [];
        }

        groups[groupID].push(sample);
      });

    return groups;
  }

  private generateCores(
    sample: Sample,
    mission: Mission,
    initialPrevPoint: Coordinate | null,
    sampleUtmLocation: {
      easting: number;
      northing: number;
      zoneNum: number;
      zoneLetter: string;
    },
    subsamplesShape: BaseShape,
    prevSampleID: string,
  ) {
    // Get existing cores and their place in the path; dispose existing cores
    const cores = cleanSet(sample.getSoilCores());
    let waypointNumber = cleanSet(mission.getWaypoints()).length + 1;
    for (const core of cores) {
      const corePoint = core.getCorePoint();
      if (corePoint && corePoint.waypoint_number !== undefined && corePoint.waypoint_number < waypointNumber) {
        waypointNumber = corePoint.waypoint_number;
      }
      core.dispose();
    }

    // Determine how to enter the cluster.
    // Call with the points in the cluster, the previous sample point,
    // and the center point of the next cluster.
    const waypoints = cleanSet(mission.getWaypoints());
    const prevWaypoint = waypoints.find((sel) => sel.waypoint_number === waypointNumber - 1);
    const prevPoint = prevWaypoint
      ? fromLatLon(prevWaypoint.lat, prevWaypoint.lon)
      : initialPrevPoint
        ? fromLatLon(initialPrevPoint[0], initialPrevPoint[1])
        : null;

    const missionJob = mission.getJob();
    const optimalCluster = subsamplesShape.optimal_cores(
      [sampleUtmLocation.easting, sampleUtmLocation.northing],
      prevPoint ? [prevPoint.easting, prevPoint.northing] : null,
      this.shouldAlternateDirection(missionJob!, prevSampleID, sample.sample_id),
    );

    // Generate the core pattern
    for (const [i, samplePoint] of optimalCluster.entries()) {
      // create core
      const [e, n] = samplePoint;
      const latLon = toLatLon(e, n, sampleUtmLocation.zoneNum, sampleUtmLocation.zoneLetter);
      const core = SoilCore.create();
      core.Sample_id = sample.instance_id;
      core.core_number = i + 1;
      core.lat = latLon.latitude;
      core.lon = latLon.longitude;

      // create new core waypoint
      const waypoint = Waypoint.create();
      waypoint.Mission_id = mission.instance_id!;
      waypoint.waypoint_number = waypointNumber + i; // Removing strage 0.001 * i logic because otherwise prevPoint is always undefined
      waypoint.lat = latLon.latitude;
      waypoint.lon = latLon.longitude;
      const corePoint = CorePoint.create();
      corePoint.Waypoint_id = waypoint.instance_id!;
      corePoint.SoilCore_id = core.instance_id!;
    }
  }

  private shouldAlternateDirection(
    missionJob: Job,
    prevSampleID: string,
    currentSampleID: string | undefined,
  ): boolean {
    if (prevSampleID === currentSampleID) {
      return false;
    }

    if (missionJob.plot_mission) {
      return false;
    }

    if (missionJob.disable_core_line_alteration) {
      return false;
    }

    return true;
  }
}

export default PathGridGenerator;
