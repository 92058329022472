import { EPOCH } from '../constants';
import { getCurrentMission } from '../dataModelHelpers';
import { SampleBox } from '../db';
import { BoxCreateParams } from '../db/types';
import { decodeBoxUID, encode } from '../db_ops/sample_box_ops';
import { addBox } from '../redux/features/boxes/boxesSlice';
import { storeDispatch } from '../redux/store';

class BoxCreator {
  public create(params: BoxCreateParams): SampleBox {
    const box = SampleBox.create();
    box.uid = params.uid || BoxCreator.genUid(params.userId, params.labCode, new Date());

    box.closedTimestamp = params.closedTimestamp;
    box.labName = params.labName.replace(/"/g, '');
    box.needsUpdated = true;
    box.username = params.username;
    box.trackingNumber = params.trackingNumber;
    box.trackingNumberTime = params.trackingNumberTime;

    const { timestamp } = decodeBoxUID(box.uid);
    box.dailyBoxCountIndex = params.boxDailyCountNumber || BoxCreator.getDayBoxCountIndex(timestamp);
    box.jobBoxCountIndex = params.jobBoxCountIndex || BoxCreator.getJobBoxCountIndex();

    storeDispatch(addBox(box));

    return box;
  }

  private static genUid(user_id: number | undefined, labCode: string | undefined, date: Date) {
    const nowTime = date.getTime();

    //  new Date().getTime() / 1000;
    if (user_id !== null && user_id !== undefined) {
      const now = Math.round(nowTime / 1000) - EPOCH;
      return encode(user_id, now, parseInt(labCode || '0'));
    } else {
      console.error('Invalid user for box');
      return '';
    }
  }

  private static getDayBoxCountIndex(date: Date) {
    const currentDay = date.getDay();
    // get all boxes
    const boxesToday = SampleBox.query(
      (sampleBox: SampleBox) => currentDay === new Date(sampleBox.createdTimestamp).getDay(),
    );
    // get the highest box number
    const highestBoxNumber = boxesToday.sort((a, b) => b.dailyBoxCountIndex - a.dailyBoxCountIndex)[0];
    return highestBoxNumber ? highestBoxNumber.dailyBoxCountIndex + 1 : 1;
  }

  private static getJobBoxCountIndex() {
    // We should ONLY call this when we are creating a box for the currently open job
    // as it relies on getting the active mission
    const currentMission = getCurrentMission();
    if (!currentMission) {
      return 1;
    }

    return currentMission.getAllSampleBoxes().length + 1;
  }
}

const boxCreator = new BoxCreator();

export default boxCreator;
