import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import AirtableRecord, { AirtableRecordList } from './AirtableRecordClass';

import { write, text } from '../db_ops/attachment_ops';
import { IAttachment } from './types';

export type SupportedFileExtension = 'csv' | 'kml' | 'kmz' | 'png' | 'zip';
export default class Attachment extends ObjectClassGenerator<Attachment>('Attachment') implements IAttachment {
  // attributes
  #field_name: string;
  #attachment_id: string;
  #filename: string;
  #url: string;
  #content: Blob | undefined;
  #downloadUrl: string;
  #dirty: boolean;

  // relationships
  #AirtableRecord_id: number | undefined;

  static tableName = 'Attachment';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Attachment, 'field_name');
    this.publishAttribute(Attachment, 'attachment_id');
    this.publishAttribute(Attachment, 'filename');
    this.publishAttribute(Attachment, 'url');
    this.publishAttribute(Attachment, 'content');
    this.publishAttribute(Attachment, 'downloadUrl');
    this.publishAttribute(Attachment, 'dirty');
    this.publishAttribute(Attachment, 'AirtableRecord_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: any = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#field_name = state.field_name || '';
    this.#attachment_id = state.attachment_id || '';
    this.#filename = state.filename || '';
    this.#url = state.url || '';
    this.#content = state.content || undefined;
    this.#downloadUrl = state.downloadUrl || undefined;
    this.#dirty = state.dirty || false;
    this.#AirtableRecord_id = state.AirtableRecord_id;
  }

  dispose() {
    const airtablerecord = this.getAirtableRecord();
    if (airtablerecord) {
      this.AirtableRecord_id = undefined;
    }

    Attachment.delete(this.instance_id);
  }

  set field_name(value: string) {
    this.#field_name = value;
    this.syncToDB();
  }

  get field_name() {
    return this.#field_name;
  }

  set attachment_id(value: string) {
    this.#attachment_id = value;
    this.syncToDB();
  }

  get attachment_id() {
    return this.#attachment_id;
  }

  set filename(value: string) {
    this.#filename = value;
    this.syncToDB();
  }

  get filename() {
    return this.#filename;
  }

  set url(value: string) {
    this.#url = value;
    this.syncToDB();
  }

  get url() {
    return this.#url;
  }

  set content(value) {
    this.#content = value;
    this.syncToDB();
  }

  get content() {
    return this.#content;
  }

  set downloadUrl(value: string) {
    this.#downloadUrl = value;
    this.syncToDB();
  }

  get downloadUrl() {
    return this.#downloadUrl;
  }

  set dirty(value: boolean) {
    this.#dirty = value;
    this.syncToDB();
  }

  get dirty() {
    return this.#dirty;
  }

  get table() {
    return this.getAirtableRecord()?.table;
  }

  get record_id() {
    return this.getAirtableRecord()?.id;
  }

  set AirtableRecord_id(value) {
    if (this.#AirtableRecord_id) {
      const relateObj = AirtableRecord.get(this.#AirtableRecord_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Attachment', this.instance_id);
      }
    }
    this.#AirtableRecord_id = value;
    if (value) {
      const relateObj = AirtableRecord.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Attachment', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get AirtableRecord_id() {
    return this.#AirtableRecord_id;
  }

  getAirtableRecord() {
    return AirtableRecord.get(this.AirtableRecord_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Attachment.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.field_name === this.field_name &&
        sel.record_id === this.record_id &&
        sel.table === this.table &&
        sel.attachment_id === this.attachment_id,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate attachment found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`attachment: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getAirtableRecord()) {
      problems.push(
        `attachment: Could not find airtable record instance across unconditional relationship R1001: ${this.instance_id}`,
      );
    }
    return problems;
  }

  static async recover(allAttachments: any[]) {
    for (const record of allAttachments) {
      const exitingAttachments = Attachment.query((attachment) => attachment.attachment_id === record.attachment_id);
      if (exitingAttachments.length) continue;

      const newRecord = Attachment.create();

      for (const key of Object.keys(record)) {
        newRecord[key] = record[key];
      }
    }
  }

  async write(
    friendlyName: string,
    extension: SupportedFileExtension,
    content: Blob,
  ): Promise<{ filename: string; content: Blob }> {
    return await write.bind(this)(friendlyName, extension, content);
  }

  async text() {
    return await text.bind(this)();
  }
}

export class AttachmentList extends DataObjectList<Attachment> {
  getAirtableRecords() {
    return new AirtableRecordList(...this.map((attachment) => attachment.getAirtableRecord()).filter((sel) => !!sel));
  }
}
