import React, { PureComponent } from 'react';

import MapButton from './MapButton';

import { getArea } from 'ol/sphere';
import { toLonLat } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { calculateAcres, round, updateState } from '../../../utils';
import Airtable from '../../../airtable';

import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import ContrastPaper from '../../utils/ContrastPaper';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { flip, translate, inBounds } from '../helpers/gridHelper';

import { ZoneType } from '../../../db';
import Feature from 'ol/Feature';
import { LineString, Polygon } from 'ol/geom';

import { HiOutlineViewGridAdd } from 'react-icons/hi';

import { MISSION_EVENTS, dispatchMissionUpdated } from '../../../missionEvents';
import EventBus from '../../../EventBus';

import './styles/gridbutton.css';
import { getCurrentMission } from '../../../dataModelHelpers';
import { alertWarnConfirm } from '../../../alertDispatcher';
import { GridLayerFeature } from '../layers/GridLayer';
import { Jobs } from '@rogoag/airtable';
import { SQ_M_PER_ACRE } from '../../../constants';

interface GridGenerationButtonProps {
  updateGridLayerFeatures: (features: GridLayerFeature[]) => void;
  gridLayerFeatures: GridLayerFeature[];
}

interface GridGenerationButtonState {
  gridContainerOpen: boolean;
  acres: number;
  actual: number;
  density: number; // acres/sample
  angle: number; // degrees
  boundaryDist: number; // feet
  startCorner: string; // NW, NE, SE, SW could be enum or new type
  pathDirection: string; // N/S, E/W could be enum or new type
  shiftNorth: number; // feet
  shiftEast: number; // feet
  densityIncrement: number; // acres/sample
  angleIncrement: number; // degrees
  boundaryIncrement: number; // feet
  shiftIncrement: number; // feet
  upToDate: boolean;
  fields: Feature<Polygon>[];
}

export default class GridGenerationButton extends PureComponent<GridGenerationButtonProps, GridGenerationButtonState> {
  SERIALIZABLE_STATE_VARIABLES = ['gridContainerOpen'];

  constructor(props: GridGenerationButtonProps) {
    super(props);

    this.state = {
      gridContainerOpen: false,
      acres: 0,
      actual: 0,
      density: 0, // acres/sample
      angle: 0, // degrees
      boundaryDist: 0, // feet
      startCorner: 'NW',
      pathDirection: 'N/S',
      shiftNorth: 0, // feet
      shiftEast: 0, // feet
      densityIncrement: 0.1, // acres/sample
      angleIncrement: 5, // degrees
      boundaryIncrement: 5, // feet
      shiftIncrement: 5, // feet
      upToDate: false,
      fields: [],
    };

    this.toggleGridContainer = this.toggleGridContainer.bind(this);
    this.initFields = this.initFields.bind(this);
  }

  async componentDidMount() {
    EventBus.on(MISSION_EVENTS.UPDATED, this.initFields);
    await this.initFields();

    this.deserializeState();
  }

  async componentDidUpdate(prevProps: GridGenerationButtonProps) {
    let acres = calculateAcres(this.state.fields);
    if (acres !== this.state.acres) {
      this.setState({ acres });
    }
    const mission = getCurrentMission();
    if (this.state.gridContainerOpen && !this.state.density && mission && mission.job_id) {
      await this.getDensity();
    }
    if (!this.state.upToDate && this.state.gridContainerOpen && this.state.fields.length > 0) {
      this.generateGrid();
    }
  }

  componentWillUnmount() {
    EventBus.remove(MISSION_EVENTS.UPDATED, this.initFields);

    this.serializeState();
  }

  /**
   * serialize the state into session
   */
  serializeState() {
    const serializedState: { [key: string]: any } = {};
    for (const stateKey of this.SERIALIZABLE_STATE_VARIABLES) {
      // @ts-ignore
      serializedState[stateKey] = this.state[stateKey];
    }
    localStorage.setItem('GridGenerationButtonState', JSON.stringify(serializedState));
  }

  /**
   * deserialize the state
   */
  deserializeState() {
    const serializedState = JSON.parse(localStorage.getItem('GridGenerationButtonState') || '{}');
    if (serializedState) {
      for (const stateKey of Object.keys(serializedState)) {
        this.setState(updateState(stateKey as keyof GridGenerationButtonState, serializedState[stateKey]));
      }
    }
  }

  async initFields() {
    const mission = getCurrentMission();
    if (!mission) {
      this.close();
      return;
    }
    const fieldZone = mission.getZones().find((sel) => sel.zone_type === ZoneType.FIELD);
    if (!fieldZone) {
      this.close();
      return;
    }
    const features = await fieldZone.to_feature();
    const fields = features.map((feature) => {
      const coords = feature.geometry.coordinates.map((coords) =>
        coords.map((ring) => {
          return ring;
        }),
      );
      const geom = new Polygon(coords);
      return new Feature(geom);
    });
    this.setState({ fields });
  }

  async toggleGridContainer() {
    if (!this.state.gridContainerOpen) {
      const mission = getCurrentMission();
      const job = mission?.getJob();
      if (job && (job.points_submitted_from_customer || !job.allowed_to_create)) {
        const definitelyOpen = await alertWarnConfirm(
          'This job should include points already. Are you sure you want to generate points?',
        );
        if (!definitelyOpen) {
          return;
        }
      }
      this.setState({ gridContainerOpen: true });
    } else {
      this.close();
    }
  }

  validate() {
    const validationMap = {
      density: { error: false, text: '' },
      angle: { error: false, text: '' },
      boundaryDist: { error: false, text: '' },
    };
    if (undefined === this.state.density || isNaN(this.state.density) || this.state.density <= 0) {
      validationMap.density = { error: true, text: 'Must be a positive number' };
    }
    if (undefined === this.state.angle || isNaN(this.state.angle) || this.state.angle < 0 || this.state.angle >= 90) {
      validationMap.angle = { error: true, text: 'Must be between 0 and 90' };
    }
    if (undefined === this.state.boundaryDist || isNaN(this.state.boundaryDist) || this.state.boundaryDist < 0) {
      validationMap.boundaryDist = { error: true, text: 'Must be a positive number' };
    }
    return validationMap;
  }

  reset() {
    this.setState({
      density: 0,
      angle: 0,
      boundaryDist: 0,
      startCorner: 'NW',
      pathDirection: 'N/S',
      shiftNorth: 0,
      shiftEast: 0,
      upToDate: false,
    });
  }

  async getDensity() {
    const currentMission = getCurrentMission();
    if (!currentMission?.job_id) {
      return;
    }
    try {
      const record = await Airtable.getRecord<Jobs>('Jobs', currentMission.job_id);
      if (record && record.get('Deal Density')[0]) {
        this.setState({ density: record.get('Deal Density')[0] });
      } else {
        console.warn('No Deal Density set for job');
      }
    } catch (err) {
      console.error('Could not pull airtable record for deal density', err);
    }
  }

  generateGrid() {
    //Parse inputs
    let density = this.state.density;
    let angle = this.state.angle;
    let boundaryDistance = this.state.boundaryDist;
    let startCorner = this.state.startCorner; // 'NW', 'NE', 'SW', 'SE'
    let pathDirection = this.state.pathDirection; // 'N/S', 'E/W'
    let shiftNorth = this.state.shiftNorth;
    let shiftEast = this.state.shiftEast;
    let field = new MultiPolygon(this.state.fields.map((f) => f.getGeometry()!.clone()));

    // Input Checking
    if (
      density === undefined ||
      isNaN(density) ||
      density <= 0 ||
      isNaN(angle) ||
      angle < 0 ||
      isNaN(boundaryDistance) ||
      boundaryDistance < 0
    ) {
      this.props.updateGridLayerFeatures([]);
      console.log('Invalid inputs to generation');
      console.log(density, angle, boundaryDistance);
      this.setState({ upToDate: true });
      return;
    }

    // ensure angle is in the range [0,90)
    if (angle >= 90 || angle < 0) {
      angle = (angle + 90) % 90;
    }

    // Convert to proper units
    density = density * SQ_M_PER_ACRE; // acres to meters^2
    boundaryDistance = boundaryDistance / 3.2808; // feet to meters
    angle = (angle * Math.PI) / 180 + ((Math.PI / 2) % (Math.PI / 2)); // degrees to radians in range [0, PI/2)

    // Distance between grid points
    let pointDistance = Math.sqrt(density); // meters^2 to meters
    shiftNorth = ((shiftNorth % pointDistance) + pointDistance) % pointDistance;
    shiftEast = ((shiftEast % pointDistance) + pointDistance) % pointDistance;

    // create initial transform point
    let shiftVector = new Point([shiftEast, shiftNorth]);

    // rotate whole polygon opposite of the desired rotation
    field.rotate(-1 * angle, getCenter(field.getExtent()));
    shiftVector.rotate(-1 * angle, [0, 0]);

    // flip to change the start corner
    if (startCorner === 'NW') {
      field.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), true, false));
      shiftVector.applyTransform((c, o, d) => flip(c, o, d, [0, 0], true, false));
    } else if (startCorner === 'NE') {
      field.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), true, true));
      shiftVector.applyTransform((c, o, d) => flip(c, o, d, [0, 0], true, true));
    } else if (startCorner === 'SW') {
      // no transform necessary
    } else if (startCorner === 'SE') {
      field.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), false, true));
      shiftVector.applyTransform((c, o, d) => flip(c, o, d, [0, 0], false, true));
    }

    // rotate and flip to change sample direction
    if ('N/S' === pathDirection) {
      field.rotate(Math.PI / 2, getCenter(field.getExtent()));
      field.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), false, true));
      shiftVector.rotate(Math.PI / 2, [0, 0]);
      shiftVector.applyTransform((c, o, d) => flip(c, o, d, [0, 0], false, true));
    }

    // Find boundary coordinates
    let extent = field.getExtent();
    let minx = extent[0];
    let miny = extent[1];
    let maxx = extent[2];
    let maxy = extent[3];

    // Create grid of points
    let points: Point[] = [];
    let plots: Polygon[] = [];
    let incX = pointDistance;
    let incY = 0;

    let currentPoint = new Point([minx, miny]);
    let shiftX =
      (((pointDistance / 2 + shiftVector.getCoordinates()[0]) % pointDistance) + pointDistance) % pointDistance;
    let shiftY =
      (((pointDistance / 2 + shiftVector.getCoordinates()[1]) % pointDistance) + pointDistance) % pointDistance;
    translate(currentPoint, shiftX, shiftY);

    let check = 0; // to prevent an infinite loop
    while (currentPoint.getCoordinates()[0] < maxx || currentPoint.getCoordinates()[1] < maxy) {
      // add point to list if in bounds
      if (inBounds(currentPoint, field, boundaryDistance)) {
        points.push(currentPoint);
      }

      // check if we need to change directions
      if (incY === 0 && (currentPoint.getCoordinates()[0] > maxx || currentPoint.getCoordinates()[0] < minx)) {
        incY = pointDistance;
        incX = -incX;
      } else if (Math.abs(incY) > 0) {
        incY = 0;
      }

      // update current point
      currentPoint = currentPoint.clone();
      translate(currentPoint, incX, incY);
      if (check > 10000) {
        break;
      }
      check += 1;
    }

    // apply transforms in reverse order to all points
    for (let p of points) {
      // rotate and flip to change sample direction
      if ('N/S' === pathDirection) {
        p.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), false, true));
        p.rotate((-1 * Math.PI) / 2, getCenter(field.getExtent()));
      }

      // flip to change the start corner
      if (startCorner === 'NW') {
        p.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), true, false));
      } else if (startCorner === 'NE') {
        p.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), true, true));
      } else if (startCorner === 'SW') {
        // no transform necessary
      } else if (startCorner === 'SE') {
        p.applyTransform((c, o, d) => flip(c, o, d, getCenter(field.getExtent()), false, true));
      }

      // rotate for angle
      p.rotate(angle, getCenter(field.getExtent()));
    }
    // Display results
    this.props.updateGridLayerFeatures([...plots, ...points]);
    this.setState({ actual: points.length, upToDate: true });
  }

  async applyGrid() {
    // send coordinates to server
    const points = this.props.gridLayerFeatures
      .map((f, idx) => {
        if (f instanceof Polygon || f instanceof LineString) {
          return null;
        }
        const lonlat = toLonLat(f.getCoordinates());
        return { id: (idx + 1).toString(), coords: { lat: lonlat[1], lon: lonlat[0] } };
      })
      .filter((point) => !!point);
    const mission = getCurrentMission();
    if (mission) {
      console.log(points);
      mission.delete_all_samples_and_cores();
      await mission.update_points(points, false);
      await mission.calculate_path();
      dispatchMissionUpdated();
    }
    this.close();
  }

  close() {
    this.reset();
    this.setState({ gridContainerOpen: false });
    this.props.updateGridLayerFeatures([]);
  }

  render() {
    return (
      <React.Fragment>
        <MapButton
          tooltip="Generate Sample Grid"
          onClick={this.toggleGridContainer}
          toggled={this.state.gridContainerOpen}
          id={'toggleGridContainer'}
        >
          <HiOutlineViewGridAdd size={20} />
        </MapButton>
        {this.state.gridContainerOpen && (
          <ContrastPaper style={{ position: 'absolute', zIndex: 150 }}>
            <FormGroup row>
              <Box pt={1} pl={1}>
                <Typography variant="h5">Generate grid</Typography>
              </Box>
              <Box pt={1} pl={1} pb={1} className={'root'}>
                <Fab size="small" onClick={this.reset.bind(this)} title="Reset" className={'fabRoot'}>
                  <RotateLeftIcon />
                </Fab>
              </Box>
              <Box pt={1} pl={1} pb={1} pr={1}>
                <Fab size="small" title="Close" onClick={this.close.bind(this)} className={'fabRoot'}>
                  <CloseIcon />
                </Fab>
              </Box>
            </FormGroup>
            <FormGroup row>
              <Box pt={1} pl={1}>
                <TextField
                  label="acres:"
                  value={this.state.acres}
                  disabled
                  variant="outlined"
                  size="small"
                  inputProps={{ size: '6' }}
                />
              </Box>
              <Box pt={1} pl={1}>
                <TextField
                  label="target:"
                  value={Math.ceil(this.state.acres / this.state.density)}
                  disabled
                  variant="outlined"
                  size="small"
                  inputProps={{ size: '6' }}
                />
              </Box>
              <Box pt={1} pl={1} pr={1}>
                <TextField
                  label="actual:"
                  value={this.state.actual}
                  disabled
                  variant="outlined"
                  size="small"
                  inputProps={{ size: '6' }}
                />
              </Box>
            </FormGroup>
            <FormGroup row>
              <Box pt={1} pl={1}>
                <TextField
                  label="density:"
                  value={this.state.density}
                  size="small"
                  fullWidth
                  inputProps={{ size: '14' }}
                  InputProps={{ endAdornment: <InputAdornment position="end">ac/s</InputAdornment> }}
                  error={this.validate().density.error}
                  helperText={this.validate().density.text}
                  onChange={(e) => {
                    this.setState({ density: parseFloat(e.target.value || '0'), upToDate: false });
                  }}
                />
              </Box>
              <Box pt={1} pl={1} className={'root'}>
                <Fab
                  size="small"
                  className={'fabRoot'}
                  onClick={(e) => {
                    if (!isNaN(this.state.density)) {
                      this.setState({
                        density: round(this.state.density + this.state.densityIncrement, 1),
                        upToDate: false,
                      });
                    }
                  }}
                >
                  +
                </Fab>
              </Box>
              <Box pt={1} pl={1} pr={1}>
                <Fab
                  size="small"
                  className={'fabRoot'}
                  onClick={(e) => {
                    if (!isNaN(this.state.density) && round(this.state.density - this.state.densityIncrement, 1) > 0) {
                      this.setState({
                        density: round(this.state.density - this.state.densityIncrement, 1),
                        upToDate: false,
                      });
                    }
                  }}
                >
                  -
                </Fab>
              </Box>
            </FormGroup>
            <FormGroup row>
              <Box pt={1} pl={1}>
                <TextField
                  label="angle:"
                  value={this.state.angle}
                  size="small"
                  fullWidth
                  inputProps={{ size: '15' }}
                  InputProps={{ endAdornment: <InputAdornment position="end">deg</InputAdornment> }}
                  error={this.validate().angle.error}
                  helperText={this.validate().angle.text}
                  onChange={(e) => {
                    this.setState({ angle: parseFloat(e.target.value || '0'), upToDate: false });
                  }}
                />
              </Box>
              <Box pt={1} pl={1} className={'root'}>
                <Fab
                  size="small"
                  className={'fabRoot'}
                  onClick={(e) => {
                    if (!isNaN(this.state.angle)) {
                      this.setState({
                        angle: round((this.state.angle + this.state.angleIncrement + 90) % 90, 1),
                        upToDate: false,
                      });
                    }
                  }}
                >
                  +
                </Fab>
              </Box>
              <Box pt={1} pl={1} pr={1}>
                <Fab
                  size="small"
                  className={'fabRoot'}
                  onClick={(e) => {
                    if (!isNaN(this.state.angle)) {
                      this.setState({
                        angle: round((this.state.angle - this.state.angleIncrement + 90) % 90, 1),
                        upToDate: false,
                      });
                    }
                  }}
                >
                  -
                </Fab>
              </Box>
            </FormGroup>
            <FormGroup row>
              <Box pt={1} pl={1}>
                <TextField
                  label="min dist from bnd:"
                  value={this.state.boundaryDist}
                  size="small"
                  fullWidth
                  inputProps={{ size: '17' }}
                  InputProps={{ endAdornment: <InputAdornment position="end">ft</InputAdornment> }}
                  error={this.validate().boundaryDist.error}
                  helperText={this.validate().boundaryDist.text}
                  onChange={(e) => {
                    this.setState({ boundaryDist: parseFloat(e.target.value || '0'), upToDate: false });
                  }}
                />
              </Box>
              <Box pt={1} pl={1} className={'root'}>
                <Fab
                  size="small"
                  className={'fabRoot'}
                  onClick={(e) => {
                    if (!isNaN(this.state.boundaryDist)) {
                      this.setState({
                        boundaryDist: round(this.state.boundaryDist + this.state.boundaryIncrement, 1),
                        upToDate: false,
                      });
                    }
                  }}
                >
                  +
                </Fab>
              </Box>
              <Box pt={1} pl={1} pr={1}>
                <Fab
                  size="small"
                  className={'fabRoot'}
                  onClick={(e) => {
                    if (
                      !isNaN(this.state.boundaryDist) &&
                      round(this.state.boundaryDist - this.state.boundaryIncrement, 1) >= 0
                    ) {
                      this.setState({
                        boundaryDist: round(this.state.boundaryDist - this.state.boundaryIncrement, 1),
                        upToDate: false,
                      });
                    }
                  }}
                >
                  -
                </Fab>
              </Box>
            </FormGroup>
            <FormGroup row>
              <Box pt={1} pl={1} width="50%">
                <FormControl fullWidth>
                  <InputLabel>start corner</InputLabel>
                  <Select
                    value={this.state.startCorner}
                    onChange={(e) => {
                      const newStartCorner = e.target.value;
                      if (typeof newStartCorner === 'string') {
                        this.setState({ startCorner: newStartCorner.toString(), upToDate: false });
                      }
                    }}
                  >
                    <MenuItem value="NW">NW</MenuItem>
                    <MenuItem value="NE">NE</MenuItem>
                    <MenuItem value="SW">SW</MenuItem>
                    <MenuItem value="SE">SE</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box pt={1} pl={1} pr={1} width="50%">
                <FormControl fullWidth>
                  <InputLabel>path direction</InputLabel>
                  <Select
                    value={this.state.pathDirection}
                    onChange={(e) => {
                      const pathDirection = e.target.value;
                      if (typeof pathDirection === 'string') {
                        this.setState({ pathDirection: pathDirection.toString(), upToDate: false });
                      }
                    }}
                  >
                    <MenuItem value="N/S">N/S</MenuItem>
                    <MenuItem value="E/W">E/W</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </FormGroup>
            <FormGroup row>
              <Box pt={1} pl={1} pb={1}>
                <Fab
                  size="small"
                  title="Shift west"
                  className={'fabRoot'}
                  onClick={(e) => {
                    this.setState({ shiftEast: this.state.shiftEast - this.state.shiftIncrement, upToDate: false });
                  }}
                >
                  <ArrowLeftIcon />
                </Fab>
              </Box>
              <Box pt={1} pl={1} pb={1}>
                <Fab
                  size="small"
                  title="Shift east"
                  className={'fabRoot'}
                  onClick={(e) => {
                    this.setState({ shiftEast: this.state.shiftEast + this.state.shiftIncrement, upToDate: false });
                  }}
                >
                  <ArrowRightIcon />
                </Fab>
              </Box>
              <Box pt={1} pl={1} pb={1}>
                <Fab
                  size="small"
                  title="Shift north"
                  className={'fabRoot'}
                  onClick={(e) => {
                    this.setState({ shiftNorth: this.state.shiftNorth + this.state.shiftIncrement, upToDate: false });
                  }}
                >
                  <ArrowDropUpIcon />
                </Fab>
              </Box>
              <Box pt={1} pl={1} pb={1}>
                <Fab
                  size="small"
                  title="Shift south"
                  className={'fabRoot'}
                  onClick={(e) => {
                    this.setState({ shiftNorth: this.state.shiftNorth - this.state.shiftIncrement, upToDate: false });
                  }}
                >
                  <ArrowDropDownIcon />
                </Fab>
              </Box>
              <Box pt={1} pl={1} pb={1} pr={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.applyGrid.bind(this)}
                  disabled={!this.state.density}
                >
                  Apply
                </Button>
              </Box>
            </FormGroup>
          </ContrastPaper>
        )}
      </React.Fragment>
    );
  }
}
