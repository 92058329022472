import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Session, { SessionList } from './SessionClass';
import { IRobotConnection } from '../types/types';

export default class Robot extends ObjectClassGenerator<Robot>('Robot') implements IRobotConnection {
  // attributes
  #hostname: string;
  #endpoint: string;
  #port: number;
  #insecure: boolean;

  static tableName = 'Robot';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Robot, 'hostname');
    this.publishAttribute(Robot, 'endpoint');
    this.publishAttribute(Robot, 'port');
    this.publishAttribute(Robot, 'insecure');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Robot> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#hostname = state.hostname || '';
    this.#endpoint = state.endpoint || '';
    this.#port = state.port || 0;
    this.#insecure = state.insecure || false;
  }

  dispose() {
    for (const session of this.getSessions()) {
      session.Robot_id = undefined;
    }

    Robot.delete(this.instance_id);
  }

  set hostname(value) {
    this.#hostname = value;
    this.syncToDB();
  }

  get hostname() {
    return this.#hostname;
  }

  set endpoint(value) {
    this.#endpoint = value;
    this.syncToDB();
  }

  get endpoint() {
    return this.#endpoint;
  }

  set port(value) {
    this.#port = value;
    this.syncToDB();
  }

  get port() {
    return this.#port;
  }

  set insecure(value) {
    this.#insecure = value;
    this.syncToDB();
  }

  get insecure() {
    return this.#insecure;
  }

  getSessions() {
    if (this._refs && this._refs.Session) {
      return new SessionList(
        ...Array.from(this._refs.Session)
          .map((id) => Session.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const sessions = Session.query((sel) => sel && sel.Robot_id === this.instance_id);
      for (const session of sessions) {
        session.Robot_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SessionList(...sessions);
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Robot.query((sel) => sel.instance_id !== this.instance_id && sel.hostname === this.hostname);
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate robot found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`robot: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    return problems;
  }
}

export class RobotList extends DataObjectList<Robot> {
  getSessions() {
    return new SessionList(
      ...this.reduce((sessions: Session[], robot) => sessions.concat(robot.getSessions()), []).filter((sel) => !!sel),
    );
  }
}
