import { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import LoadMapsMission from './maps/LoadMapsMission';
import MapsMissionLoaded from './maps/MapsMissionLoaded';
import { OnLoading, SettingsAccessLevel } from '../../types/types';
import { AirtableRecord } from '../../db';

interface MapsMissionViewProps {
  missionActive: string;
  onLoading: OnLoading;
  loadMissionMaps: (id: string) => Promise<void>;
  loadSchedule: (forceRefresh: boolean) => Promise<void>;
  loadingSchedule: boolean;
  scheduleItems: AirtableRecord[];
  accessLevel: SettingsAccessLevel;
  mapmakingNotesVisible: boolean;
  toggleMapmakingNotes: () => void;
  lastMissionId?: string;
  openExitInterview: () => void;
}

export default class MapsMissionView extends PureComponent<MapsMissionViewProps> {
  render() {
    return (
      <Grid container item xs={12}>
        {Boolean(this.props.missionActive) ? (
          <MapsMissionLoaded
            onLoading={this.props.onLoading}
            accessLevel={this.props.accessLevel}
            mapmakingNotesVisible={this.props.mapmakingNotesVisible}
            toggleMapmakingNotes={this.props.toggleMapmakingNotes}
            openExitInterview={this.props.openExitInterview}
          />
        ) : (
          <LoadMapsMission
            onLoading={this.props.onLoading}
            loadSchedule={this.props.loadSchedule}
            loadingSchedule={this.props.loadingSchedule}
            loadMissionMaps={this.props.loadMissionMaps}
            scheduleItems={this.props.scheduleItems}
            accessLevel={this.props.accessLevel}
            lastMissionId={this.props.lastMissionId}
          />
        )}
      </Grid>
    );
  }
}
