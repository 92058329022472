import { Button, Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';

interface MapOverlayButtonProps {
  text: string;
  icon: JSX.Element;
  marginLeft: number | string;
  marginTop: number | string;
  onClick: () => void | Promise<void>;
}

export default class MapOverlayButton extends PureComponent<MapOverlayButtonProps> {
  DEFAULT_BAR_SIZE = 200;

  render() {
    return (
      <div
        style={{
          margin: 0,
          position: 'absolute',
          right: 10,
          marginTop: this.props.marginTop,
          marginLeft: this.props.marginLeft,
          zIndex: 10,
          borderColor: '#EEEEEE',
          borderWidth: 0,
          borderStyle: 'solid',
          // height: 20,
          padding: 0,
          display: 'flex',
        }}
      >
        <Button variant="contained" size={'large'} onClick={this.props.onClick}>
          <Typography style={{ color: 'black' }}>{this.props.text}</Typography>
          {this.props.icon}
        </Button>
      </div>
    );
  }
}
