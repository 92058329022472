import React, { Component } from 'react';
import { flatten } from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import LoadingButton from '../../utils/LoadingButton';

import { AirtableRecord, SampleBox, ZoneType } from '../../../db';

import Airtable from '../../../airtable';
import { cleanSet, isBulkDensityCore } from '../../../utils';
import logger from '../../../logger';
import { alertError, alertSuccess } from '../../../alertDispatcher';

import { getErrorsFromMap, generateErrorMap } from '../../../barcodes';
import {
  getCurrentMission,
  getCurrentSession,
  saveCurrentMissionRecoveryZip,
  getPulledSamples,
} from '../../../dataModelHelpers';
import { dispatchSessionUpdated, dispatchUpdatedCurrentSample } from '../../../sessionEvents';
import { CreatedOrDeletedParams, dispatchMissionCreatedDeleted } from '../../../missionEvents';
import { CustomCheckbox } from '../../utils/CustomCheckbox';
import { BarcodeIssue, BoundaryChangeType, MissionStatus, SettingsAccessLevel, TakenCore } from '../../../types/types';

import { isMobile } from 'react-device-detect';

import S3 from '../../../aws';
import { RogoError } from '../../../RogoAlertError';
import { ALERT_DISPATCH_EVENTS } from '../../../alertEvents';

import { Box, DialogTitle, Typography } from '@material-ui/core';
import { MissionEventStore } from '../../../sampleSelectionHelpers';

interface SamplingExitInterviewProps {
  open: boolean;
  closeForm: () => void;
  accessLevel: SettingsAccessLevel;
}

interface SamplingExitInterviewState {
  confirmNoButton: boolean;
  missionStatus: MissionStatus;
  samplesPulled: string;
  fieldNotReady: boolean;
  tilledField: boolean;
  fieldNotes: string;
  customerBoundaryChange: BoundaryChangeType;
  boundaryNotes: string;
  barcodeIssue: BarcodeIssue[];
  submitFailed: boolean;
  missionHasCollectedBoundary: boolean;
  overrideNoBoundary: boolean;
}

export default class SamplingExitInterview extends Component<SamplingExitInterviewProps, SamplingExitInterviewState> {
  closeTimeout: NodeJS.Timeout | undefined = undefined;

  constructor(props: SamplingExitInterviewProps) {
    super(props);
    this.state = {
      confirmNoButton: false,
      missionStatus: 'completed',
      samplesPulled: '',
      fieldNotReady: false,
      tilledField: false,
      fieldNotes: '',
      customerBoundaryChange: BoundaryChangeType.None,
      boundaryNotes: '',
      barcodeIssue: [],
      submitFailed: false,
      missionHasCollectedBoundary: false,
      overrideNoBoundary: false,
    };
  }

  async componentDidMount() {
    await this.initializeFromAirtable();
  }

  async componentDidUpdate(prevProps: SamplingExitInterviewProps, prevState: SamplingExitInterviewState) {
    // Only update when opening the ExitInterview
    if (!prevProps.open && this.props.open === true) {
      await this.updateSamplesPulled();
      this.validateBarcodes();
      this.checkForCollectedBoundary();
    }
    if (
      prevState.customerBoundaryChange === BoundaryChangeType.None &&
      prevState.customerBoundaryChange !== this.state.customerBoundaryChange
    ) {
      this.checkForCollectedBoundary();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.closeTimeout);
  }

  async updateSamplesPulled() {
    const mission = getCurrentMission();
    if (!mission) {
      return;
    }
    this.setState({ samplesPulled: await getPulledSamples(mission) });
  }

  updateMissionStatus = (missionStatus: MissionStatus) => {
    this.setState({ missionStatus });
  };

  async submitDirect(record: AirtableRecord) {
    const { filename, content } = await this.updateCompletedRecord(record);

    // upload to S3
    const checksum = await S3.upload(filename, content, content.type, { bucket: 'missionuploads', acl: '' });

    await saveCurrentMissionRecoveryZip({ tag: 'submit', offlineSync: false });

    // TODO validate checksum
    return !!checksum;
  }

  async submitOffline(record: AirtableRecord) {
    await this.updateCompletedRecord(record);
    record.set('Offline Synced', true);

    // if this is an offline sync, we just need to send to the robot process
    await saveCurrentMissionRecoveryZip({ tag: 'offline', offlineSync: true });
    return true;
  }

  handleSubmission = async ({ offlineSync = false } = {}) => {
    await SampleBox.logBoxes('BOXES_BEFORE_MISSION_SUBMISSION');

    const fieldNotReady = this.state.fieldNotReady;
    let submitSuccess = false;
    try {
      const mission = getCurrentMission();
      const record = await mission?.get_airtable_job_record();
      if (!record) {
        throw new Error('Could not find record for mission');
      }
      if (fieldNotReady) {
        submitSuccess = await this.submitNotReady(record);
      } else if (offlineSync) {
        submitSuccess = await this.submitOffline(record);
      } else {
        submitSuccess = await this.submitDirect(record);
      }
      // const validity = await validateAllTableDataIntegrity();
      // if (!validity) {
      //   alertWarn('Data integrity check failed, contact support!');
      //   Sentry.captureMessage(`Data integrity check failed when uploading ${mission.job_id} ${mission.name}`);
      // }
    } catch (err) {
      if (err instanceof RogoError) {
        err.showError();
      } else if (err && typeof err === 'string') {
        console.error('Failed to submit on mission, message: ', err);
        alertError(`Could not submit for unknown reason! Contact support! ${err}`);
      } else if (err && typeof err === 'object' && 'message' in err && typeof err.message === 'string') {
        console.error('Failed to submit on mission, message: ', err.message);
        if (err.message.includes('Failed to fetch')) {
          console.error('Failed submit on mission due to internet');
          alertError('Could not submit due to internet!');
        } else {
          alertError(`Experienced airtable error, contact ops! ${err.message}`);
        }
      } else {
        console.error('Failed to submit on mission, unkown reason: ', err);
        alertError('Could not submit for unknown reason! Contact support!');
      }
      submitSuccess = false;
    }

    if (submitSuccess) {
      alertSuccess(`Mission successfully ${fieldNotReady ? 'skipped' : 'submitted'}!`);
      this.handleClear(false);
    }

    await SampleBox.logBoxes('BOXES_AFTER_MISSION_SUBMISSION');

    this.setState({ submitFailed: !submitSuccess });
  };

  async updateCompletedRecord(record: AirtableRecord) {
    // update username before tranformating to KML
    const mission = getCurrentMission();

    // we must update these properties on the job so that they end up in the serialized kml
    const job = mission?.getJob();
    if (job) {
      if (this.state.customerBoundaryChange !== BoundaryChangeType.None) {
        job.boundary_change_notes = this.state.boundaryNotes;
        job.boundary_change_type = this.state.customerBoundaryChange;
      }
      if (this.state.overrideNoBoundary) {
        const job_flags = job.job_flags.filter((flag) => flag !== 'Boundary Collect Bypass');
        job_flags.push('Boundary Collect Bypass');
        await record.set('Job Flags', job_flags);
        job.job_flags = job_flags;
      }

      job.in_field_notes_ops = this.state.fieldNotes;
      job.tilled_field_at_sampling = this.state.tilledField;
    }

    // Generate mission name and transform to KMZ
    if (!mission) {
      throw new Error('Mission not found');
    }
    const missionName = mission.getMissionName(this.state.missionStatus.toString());
    const kml_doc = await mission.to_kml({ insert_boxes: true });
    const kmz = await kml_doc?.toKMZ(`${missionName}.kml`);

    if (!kmz) {
      throw new Error('Could not create KMZ');
    }

    // first clear the field that we are going to set
    const kml = await record.new_attachment('Exe Mission');
    const { filename, content } = await kml.write(
      `${missionName}`,
      'kmz',
      new Blob([kmz], { type: 'application/vnd.google-earth.kmz' }),
    );

    const sampleDate =
      mission.sample_date > 0.0 ? new Date(mission.sample_date * 1000).toISOString() : new Date().toISOString();
    await record.set('Sample Date Man OVRD Date', sampleDate);

    await record.set('Tilled Field at Sampling?', this.state.tilledField);
    await record.set('In-Field Notes #ops', this.state.fieldNotes);
    const barcodesPulled = mission.getBarcodes();
    await record.set('Barcodes Pulled', barcodesPulled.join(', '));
    await record.set('Samples Omitted? #code', this.getSamplesOmitted());
    await record.set('Exe # Samples', barcodesPulled.length);
    if (this.state.customerBoundaryChange !== BoundaryChangeType.None) {
      await record.set('Boundary Change Type', this.state.customerBoundaryChange);
      await record.set('Boundary Change Notes', this.state.boundaryNotes);
    }

    return { filename, content };
  }

  async submitNotReady(record: AirtableRecord) {
    await saveCurrentMissionRecoveryZip({ tag: 'notready' });
    try {
      await record.set('Unready field upon arrival?', this.state.fieldNotReady);
      await record.set('Tilled Field at Sampling?', this.state.tilledField);
      await record.set('In-Field Notes #ops', this.state.fieldNotes);
      await record.set('Skipped by Operator Time', new Date().toISOString());

      // not ready submission is still done via AirTable sync
      // this should be updated eventually to submit via S3
      await record.sync();

      return true;
    } catch (e) {
      throw new RogoError({
        message: 'Mission upload failed on sync, check internet!',
        alertType: ALERT_DISPATCH_EVENTS.ERROR,
      });
    }
  }

  handleSkipUpload = async () => {
    await SampleBox.logBoxes('BOXES_BEFORE_SKIP_UPLOAD');

    if (!this.state.confirmNoButton) {
      this.setState({ confirmNoButton: true });
      this.closeTimeout = setTimeout(() => {
        this.setState({ confirmNoButton: false });
      }, 2000);
    } else {
      // because operators load the missions on their phone and then have to "skip upload" they were getting files downloaded
      // all the time on their phones. This will prevent that. Seeing the skip recoverys is helpful for the robots
      // but on phones we don't need it. Technically the last saved recovery should have the same data anyways, it just creates
      // a helpful marker for me in the recovery files.
      if (!isMobile) {
        await saveCurrentMissionRecoveryZip({ tag: 'skip' });
      }

      await logger.log('SKIP_UPLOAD', 'Mission upload was skipped');
      await SampleBox.logBoxes('BOXES_AFTER_SKIP_UPLOAD');

      await this.handleClear(true);

      // we will do this AFTER we clear the mission to avoid current session / current mission issues
      // with samples changing and auto-recoverys
      // TODO we will not NOT do this as it causes problems when reloading a recovery file to get a box
      // and then skipping upload on that mission
      //mission.clearSampleBarcodes();
    }

  };

  handleClear = async (skipped: boolean) => {
    // TODO this does not clean up the mission correctly, especially
    // if we had closed boxes that were around for a closed mission
    await logger.log('HANDLE_CLEAR', `skipped=${skipped}`);
    const session = getCurrentSession();

    // Call this before clearing the session's mission
    const currentMission = getCurrentMission();

    if (session) {
      session.Mission_id = undefined;
      session.Sample_id = undefined;
      dispatchUpdatedCurrentSample();
      dispatchSessionUpdated();
    }

    let missionData: CreatedOrDeletedParams;
    if (currentMission) {
      const currentMissionJobId = currentMission.job_id!;
      const currentMissionInstanceId = currentMission.instance_id;
      missionData = {
        deletedRogoJobId: currentMissionJobId,
        deletedInstanceId: currentMissionInstanceId,
      };
    }
    
    localStorage.setItem('notesClosed', 'false');
    MissionEventStore.reset();
    this.props.closeForm();

    dispatchMissionCreatedDeleted(missionData);
  };

  validateBarcodes = () => {
    const mission = getCurrentMission();
    const samples = cleanSet(flatten(mission?.getSampleSites().map((site) => site.getSamples())));
    //const skippedSamples = samples.filter((s) => s.skipped_or_deleted);
    const barcodesNotComplete = samples.some((s) => !s.skipped_or_deleted && !s.bag_id);
    const errMap = generateErrorMap(samples);
    const { dupError, gapError, invalidError } = getErrorsFromMap(errMap);
    const barcodeIssue: { partialIgnore: boolean; message: string }[] = [];
    if (barcodesNotComplete) {
      barcodeIssue.push({
        partialIgnore: true,
        message: "Every sample must contain a barcode or be marked as 'Not sampled' (contact ops manager)",
      });
    }
    if (dupError) {
      barcodeIssue.push({ partialIgnore: false, message: 'Duplicate barcodes have been found' });
    }
    if (invalidError) {
      barcodeIssue.push({ partialIgnore: false, message: 'Invalid barcodes have been found' });
    }
    if (gapError) {
      barcodeIssue.push({ partialIgnore: false, message: 'Gap in barcodes found' });
    }
    this.setState({ barcodeIssue });
  };

  async initializeFromAirtable() {
    const mission = getCurrentMission();
    if (!mission?.job_id) {
      return;
    }

    const job = await Airtable.getRecord('Jobs', mission.job_id);
    if (job) {
      this.setState({
        fieldNotReady: job.get('Unready field upon arrival?') as boolean,
        tilledField: job.get('Tilled Field at Sampling?') as boolean,
        customerBoundaryChange: (job.get('Boundary Change Type') || '') as BoundaryChangeType,
        boundaryNotes: (job.get('Boundary Change Notes') as string | undefined) || '',
      });
    }
  }

  getSamplesOmitted() {
    // return true if some samples have been omitted
    const mission = getCurrentMission();
    const samples = mission?.getAllSamples() ?? [];
    return Boolean(samples.find((sel) => sel.skipped_or_deleted));
  }

  checkForCollectedBoundary() {
    const mission = getCurrentMission();
    const boundary = mission?.getZones().find((sel) => sel.zone_type === ZoneType.COLLECTED_FIELD);
    this.setState({
      missionHasCollectedBoundary: Boolean(boundary),
    });
  }

  render() {
    // Show an error depending on completed or partial mission
    const completedBarcodeIssue = this.state.barcodeIssue.length > 0;
    const partialBarcodeIssue = this.state.barcodeIssue.some((k) => !k.partialIgnore);
    const barcodeIssues =
      (this.state.missionStatus === 'completed' && completedBarcodeIssue) ||
      (this.state.missionStatus === 'partial' && partialBarcodeIssue);
    // TODO remove duplicate boundary check values
    const boundaryIssues =
      this.state.customerBoundaryChange !== BoundaryChangeType.None &&
      (this.state.boundaryNotes === '' || (!this.state.missionHasCollectedBoundary && !this.state.overrideNoBoundary));

    const disableSubmit = barcodeIssues || boundaryIssues;
    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth={'lg'}
        open={this.props.open || false}
        fullWidth
        onClose={(_event, reason) => {
          if (reason === 'backdropClick') return;
          // TODO This property isn't used anywhere else, this might have just worked because it forced a re-render even though
          // it didn't set a valid state property, so for now it has been replaced by calling the closeForm
          // method from the props (see below the commented setState call)

          // this.setState({
          //   open: false
          // });

          this.props.closeForm();
        }}
      >
        <DialogTitle>Upload Mission</DialogTitle>
        <DialogContent>
          <Grid>
            {this.props.accessLevel === 'Technician' ? (
              <Grid item xs={12} style={{ marginBottom: '12px', marginRight: '128px' }}>
                <FormGroup row>
                  <RadioGroup
                    row
                    value={this.state.missionStatus}
                    onChange={(e) => {
                      this.updateMissionStatus(e.target.value as MissionStatus);
                    }}
                  >
                    <FormControlLabel value="completed" control={<Radio />} label="Completed" />
                    <FormControlLabel
                      value="partial"
                      control={<Radio data-testid="exit-interview-partial" />}
                      label="Partial"
                    />
                  </RadioGroup>
                  {this.state.missionStatus === 'partial' && (
                    <TextField
                      required={this.state.missionStatus === 'partial'}
                      label="Samples pulled"
                      disabled={this.state.missionStatus !== 'partial'}
                      value={this.state.samplesPulled}
                      onChange={(e) => {
                        this.setState({ samplesPulled: e.target.value });
                      }}
                      error={this.state.samplesPulled === ''}
                      helperText={this.state.samplesPulled === '' ? 'Text field must not be blank.' : ''}
                      data-testid="exit-interview-samples-pulled"
                    />
                  )}
                </FormGroup>
              </Grid>
            ) : null}
            <Grid item xs={12} style={{ marginBottom: '12px' }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.fieldNotReady}
                      color="primary"
                      onChange={(e) => {
                        this.setState({ fieldNotReady: e.target.checked });
                      }}
                    />
                  }
                  label="Field not ready?"
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={this.state.tilledField}
                      color="primary"
                      onChange={(e) => {
                        this.setState({ tilledField: e.target.checked });
                      }}
                      data-testid="exit-interview-tilled"
                    />
                  }
                  label="Tilled Field?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '12px' }}>
              <FormGroup row>
                <TextField
                  label="Field notes"
                  multiline
                  minRows={2}
                  style={{
                    width: '100%',
                  }}
                  variant="outlined"
                  value={this.state.fieldNotes}
                  onChange={(e) => {
                    this.setState({ fieldNotes: e.target.value });
                  }}
                  data-testid="exit-interview-notes"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              {/* Ask the operator what type of carbon probe they used */}
              {/* It will either be a hand probe or the heavy duty probe mounted on the Kubota */}
              {/* This will be used to determine the type of carbon probe used */}
              <FormGroup row></FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <Grid style={{ marginTop: '1em', marginRight: '1em' }}>
                  <InputLabel>Customer boundary change?</InputLabel>
                  <Select
                    value={this.state.customerBoundaryChange || 'None'}
                    onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                      this.setState({
                        customerBoundaryChange: (e.target.value === 'None' ? '' : e.target.value) as BoundaryChangeType,
                      });
                    }}
                  >
                    {Object.keys(BoundaryChangeType).map((item, idx) => (
                      <MenuItem key={idx.toString()} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* // TODO remove duplicate boundary check values */}
                {this.state.customerBoundaryChange !== BoundaryChangeType.None &&
                !this.state.missionHasCollectedBoundary ? (
                  <Grid style={{ marginTop: '1em', marginRight: '1em' }}>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          checked={this.state.overrideNoBoundary}
                          color="primary"
                          onChange={(e) => {
                            this.setState({ overrideNoBoundary: e.target.checked });
                          }}
                          data-testid="exit-interview-bnd-ovrd"
                        />
                      }
                      label="Submit with no collected boundary?"
                    />
                    {!this.state.overrideNoBoundary ? (
                      <FormHelperText error={true}>No boundary has been collected for this mission</FormHelperText>
                    ) : null}
                  </Grid>
                ) : null}
              </FormGroup>
              {/* // TODO remove duplicate boundary check values */}
              {this.state.customerBoundaryChange !== BoundaryChangeType.None ? (
                <FormGroup row>
                  <Grid item xs={12} style={{ marginTop: '1em', marginRight: '1em' }}>
                    <TextField
                      label="Boundary notes"
                      multiline
                      required
                      minRows={2}
                      fullWidth
                      variant="outlined"
                      value={this.state.boundaryNotes}
                      onChange={(e) => {
                        this.setState({ boundaryNotes: e.target.value });
                      }}
                      error={this.state.boundaryNotes === ''}
                      helperText={this.state.boundaryNotes === '' ? 'Text field must not be blank.' : ''}
                    />
                  </Grid>
                </FormGroup>
              ) : null}
            </Grid>
          </Grid>

          {this.state.submitFailed ? (
            <div key="submitFailed" style={{ color: 'red' }}>
              <b>Something went wrong submitting to Airtable. Try again or email mission file to ops support.</b>
            </div>
          ) : null}
          <React.Fragment>
            {this.state.fieldNotReady ? (
              <div key="fieldNotReady">
                <b>If the field is not ready to be sampled, contact ops manager and then skip</b>
              </div>
            ) : (
              [
                barcodeIssues && (
                  <div key="barcodeissues" style={{ color: 'red' }}>
                    <b>
                      {this.state.missionStatus === 'partial'
                        ? this.state.barcodeIssue
                            .filter((k) => !k.partialIgnore)
                            .map((k) => <div key={k.message}>{k.message}</div>)
                        : this.state.barcodeIssue.map((k) => <div key={k.message}>{k.message}</div>)}
                    </b>
                  </div>
                ),
                boundaryIssues && (
                  <div key="boundaryIssues" style={{ color: 'red' }}>
                    <b>A problem with collected boundaries is preventing you from submitting the exit interview.</b>
                  </div>
                ),
              ]
            )}
          </React.Fragment>
        </DialogContent>
        <DialogActions>
          {this.state.fieldNotReady ? (
            <LoadingButton
              variant="outlined"
              color="secondary"
              onClick={this.handleSubmission}
              data-testid="exit-interview-skip"
            >
              Skip field
            </LoadingButton>
          ) : (
            <React.Fragment>
              {/* // Temporarily enabling this in a way that keeps the existing code if we want to "reenable" it */}
              {(true || this.state.submitFailed) && (
                <LoadingButton
                  disabled={disableSubmit}
                  variant="outlined"
                  onClick={async () => {
                    await this.handleSubmission({ offlineSync: true });
                  }}
                  data-testid="exit-interview-yes"
                >
                  Offline Upload
                </LoadingButton>
              )}

              <LoadingButton
                disabled={disableSubmit}
                variant="contained"
                onClick={this.handleSubmission}
                data-testid="exit-interview-yes"
                // @ts-ignore
                color="primary"
              >
                Upload
              </LoadingButton>
              <LoadingButton variant="outlined" onClick={this.handleSkipUpload} data-testid="exit-interview-no">
                {this.state.confirmNoButton ? 'Are you sure?' : 'Skip Upload'}
              </LoadingButton>
            </React.Fragment>
          )}
          <Button variant="outlined" color="default" onClick={this.props.closeForm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
