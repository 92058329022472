import { Grid, IconButton, Paper, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { IoAddCircleOutline } from 'react-icons/io5';

interface ExcludedBoxesListProps {
  excludedBoxes: string[];
  includeBox: (boxId: string) => void;
}

export function ExcludedBoxesList(props: ExcludedBoxesListProps) {
  return (
    <Grid container>
      <Paper style={{ marginTop: 10, marginBottom: 10, width: '100%' }}>
        <Table>
          <TableHead style={{ textAlign: 'center' }}>
            <TableRow>
              <TableCell>
                <Typography style={{ paddingTop: 5, paddingBottom: 5 }}>Excluded Boxes</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {props.excludedBoxes.map((boxId) => (
            <TableRow key={boxId}>
              <TableCell>
                <Grid container direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    size={'small'}
                    onClick={() => props.includeBox(boxId)}
                    style={{ padding: 6, marginRight: 5 }}
                  >
                    <IoAddCircleOutline />
                  </IconButton>
                  <Typography variant={'body2'}>Box {boxId}</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Paper>
    </Grid>
  );
}
