import { RobotConnectionStore } from './components/RobotConnectionStore';

export class RobotLocalNetwork {
  MAX_TIMEOUT = 1000;

  PING_INTERVAL = 5000;

  HTTPS_PORT = 8080;
  HTTPS_URL: string;
  isAlive: boolean;
  pingTimeout: NodeJS.Timeout | null;
  errorThrown: boolean;

  constructor() {
    this.HTTPS_URL = `https://${RobotConnectionStore.get().hostname}:${this.HTTPS_PORT}`;
    this.isAlive = false;
    this.pingTimeout = null;
    this.errorThrown = false;

    this.networkDied = this.networkDied.bind(this);
    this.networkOnline = this.networkOnline.bind(this);
    this.startPinging = this.startPinging.bind(this);
  }

  httpsRequest = () => {
    return new Promise<void>((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', this.HTTPS_URL);

      xhr.timeout = this.MAX_TIMEOUT;

      xhr.onerror = () => {
        this.networkDied();

        resolve();
      };

      xhr.ontimeout = () => {
        this.networkDied();

        resolve();
      };

      xhr.onloadend = () => {
        //console.log('RESPONSE', xhr.status);
        if (xhr.readyState === 4 && xhr.status === 200) {
          this.networkOnline();
        }

        resolve();
      };

      xhr.send();
    });
  };

  async startPinging() {
    if (import.meta.env.DEV) {
      return;
    }

    await this.httpsRequest();
    this.pingTimeout = setTimeout(this.startPinging, this.PING_INTERVAL);
  }

  stopPinging() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
  }

  networkOnline() {
    if (!this.isAlive) {
      this.errorThrown = false;
      // alertSuccess('Local Network Online');
      this.isAlive = true;
    }
  }

  networkDied() {
    if (!this.errorThrown) {
      this.errorThrown = true;
      // alertError('Local Network Offline');
      this.isAlive = false;
    }
  }
}
