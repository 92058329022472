import _ from 'lodash';
import { processChunks } from '@rogoag/utilities';
import ddb from '@rogoag/dynamodb';
import { IDBPDatabase, IDBPObjectStore, openDB } from 'idb';
import getVersion from './version';
import download from 'downloadjs';
import * as Sentry from '@sentry/react';
import { MS_PER_MIN } from './constants';

const MISSION_CONTROL_EVENT_LOG_DB_NAME = 'missioncontrol-event-log';
const MISSION_CONTROL_EVENT_LOG_STORE_NAME = 'EventLog';
const MISSION_CONTROL_EVENT_LOG_UPLOAD_INTERVAL = 60 * 1000;
const MISSION_CONTROL_EVENT_LOG_UPLOAD_MAX_LOGS = 1000;
const MISSION_CONTROL_EVENT_LOG_EXPIRATION_PERIOD_DAYS = 2;
const MISSION_CONTROL_EVENT_LOG_INDIVIDUAL_UPLOAD_TIMEOUT = 3 * 1000;
const MISSION_CONTROL_EVENT_LOG_OVERALL_UPLOAD_TIMEOUT = 10 * 1000;
const MISSION_CONTROL_EVENT_LOG_UPLOAD_MAX_CONCURRENT = 6;

interface LogEntry {
  timestamp: string;
  userID: string;
  robot: string;
  lastConnectedRobot: string;
  logSessionID: string | null;
  eventType: string;
  appVersion: string;
  status: string;
  data: string | null;
  durationMS: number;
}

export class MissionControlEventLogger {
  dynamodb: ddb;
  db: IDBPDatabase<unknown> | null;
  uploadInterval: NodeJS.Timeout;
  uploadIntervalStarted: boolean;
  userID: string;
  robot: string;
  lastConnectedRobot: string;
  logSessionID: string | null;
  initialized: boolean;
  disabled: boolean;
  recentLogs: Record<string, [LogEntry, number]> = {};

  constructor() {
    this.dynamodb = new ddb({
      accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
      secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
      region: 'us-east-2',
    });

    this.db = null;
    this.uploadIntervalStarted = false;
    this.userID = localStorage.getItem('username') || 'no-user';
    this.robot = localStorage.getItem('robot') || 'no-robot';
    this.lastConnectedRobot = localStorage.getItem('lastConnectedRobot') || 'no-robot';
    this.logSessionID = localStorage.getItem('logSessionID');
    if (!this.logSessionID) {
      this.logSessionID = Math.floor(Math.random() * 100000).toString();
      localStorage.setItem('logSessionID', this.logSessionID);
    }
    this.initialized = false;
    const isAdvancedModeDisabled: boolean = JSON.parse(localStorage.getItem('eventLogDisabled') ?? 'false');
    const isEnvironmentVariableDisabled = import.meta.env.VITE_EVENT_LOGGING !== 'on';
    this.disabled = isAdvancedModeDisabled || isEnvironmentVariableDisabled;
    console.debug('Logger', { isAdvancedModeDisabled, isEnvironmentVariableDisabled });
  }

  // TODO this seems to possibly not be set on recovery loads
  setUser(userID: string = '') {
    if (userID) {
      localStorage.setItem('username', userID);
      this.userID = userID;
    } else {
      localStorage.removeItem('username');
      this.userID = 'no-user';
    }
  }

  setRobot(robot: string = '') {
    if (robot) {
      localStorage.setItem('robot', robot);
      localStorage.setItem('lastConnectedRobot', robot);
      Sentry.setTag('robot', robot);
      this.robot = robot;
      this.lastConnectedRobot = robot;
    } else {
      localStorage.removeItem('robot');
      this.robot = 'no-robot';
    }
  }

  async toggleEventLogUploading() {
    console.debug('Logger toggleEventLogUploading');
    this.disabled = !this.disabled;

    if (this.disabled) {
      this._cancelUploadCycle();
    } else {
      await this._startUploadCycle(true);
    }

    localStorage.setItem('eventLogDisabled', JSON.stringify(this.disabled));
  }

  async logPeriodic(eventType: string, data?, periodMS = MS_PER_MIN, timestampOVRD?: any) {
    if (!this.initialized) {
      await this._initialize();
    }

    if (!this.initialized) {
      return;
    }

    let logKey = `${eventType}-${periodMS}`;

    const appVersion = getVersion();

    const timestamp = timestampOVRD ? new Date(timestampOVRD).toISOString() : new Date().toISOString();

    const logEntry: LogEntry = {
      timestamp,
      userID: this.userID,
      robot: this.robot,
      lastConnectedRobot: this.lastConnectedRobot,
      logSessionID: this.logSessionID,
      eventType: `${eventType} (periodic on ${periodMS}ms)`,
      appVersion,
      status: 'local',
      data: null,
      durationMS: 0,
    };

    if (data) {
      logKey += `-${JSON.stringify(data)}`;
      logEntry.data = JSON.stringify(data);
    }

    if (this.recentLogs[logKey]) {
      const [recentLog, count] = this.recentLogs[logKey];
      const now = new Date().getTime();
      const recentLogTime = new Date(recentLog.timestamp).getTime();
      const timeSinceLastLog = now - recentLogTime;

      // if logged in last periodMS, do not log
      if (timeSinceLastLog < periodMS) {
        this.recentLogs[logKey] = [recentLog, count + 1];
        return;
      }

      recentLog.eventType = `${eventType} (periodic on ${periodMS}ms)` + (count > 0 ? ` (${count} times)` : '');
    }

    // remove old log
    this.recentLogs[logKey] = [logEntry, 0];

    if (this.db) await this.db.add(MISSION_CONTROL_EVENT_LOG_STORE_NAME, logEntry);
  }

  // TODO better understand timestampOVRD, is it boolean or object?
  async log(eventType: string, data?, durationMS = 0, timestampOVRD?: any) {
    if (!this.initialized) {
      await this._initialize();
    }

    if (!this.initialized) {
      return;
    }

    const appVersion = getVersion();

    const timestamp = timestampOVRD ? new Date(timestampOVRD).toISOString() : new Date().toISOString();

    const logEntry: LogEntry = {
      timestamp,
      userID: this.userID,
      robot: this.robot,
      lastConnectedRobot: this.lastConnectedRobot,
      logSessionID: this.logSessionID,
      eventType,
      appVersion,
      status: 'local',
      data: null,
      durationMS: 0,
    };

    if (data) {
      logEntry.data = JSON.stringify(data);
    }

    if (durationMS > 0) {
      logEntry.durationMS = durationMS;
    }

    console.debug('Logger log', logEntry);

    if (this.db) await this.db.add(MISSION_CONTROL_EVENT_LOG_STORE_NAME, logEntry);
  }

  start(eventType: string, startingData) {
    return new LogTimer(eventType, startingData);
  }

  async stop(logTimer: LogTimer, endingData?) {
    if (!this.initialized) {
      await this._initialize();
    }

    if (!this.initialized) {
      return;
    }

    if (!logTimer) {
      throw new Error('No Log Timer to stop');
    }

    const durationMS = logTimer.stop();
    const eventType = logTimer.eventType;
    const data = {
      startingData: logTimer.startingData,
      endingData,
    };

    await this.log(eventType, data, durationMS, logTimer.started);
  }

  async download() {
    const ROWDELIM = '\n';
    const COLDELIM = '\t';
    const KEYS = [
      'timestamp',
      'userID',
      'robot',
      'lastConnectedRobot',
      'logSessionID',
      'eventType',
      'appVersion',
      'durationMS',
      'data',
    ];
    const csvrows: string[] = [];
    csvrows.push(KEYS.join(COLDELIM));
    const logs = await this.db?.getAll(MISSION_CONTROL_EVENT_LOG_STORE_NAME);
    logs?.forEach((log) => {
      // TODO we should better define this return type
      const csvcol: any[] = [];
      KEYS.forEach((key) => {
        csvcol.push(log[key]);
      });
      csvrows.push(csvcol.join(COLDELIM));
    });
    download(csvrows.join(ROWDELIM), 'event-log.tsv', 'text/tab-separated-values');
  }

  /* DO NOT CALL THE FOLLOWING METHODS EXTERNALLY */
  async _startUploadCycle(startNow?: boolean) {
    // do not start already started or in worker
    if (this.uploadIntervalStarted || this.disabled) {
      return;
    }

    this.uploadIntervalStarted = true;
    console.debug('Logger _startUploadCycle');

    if (startNow) {
      await this._upload();
    }

    // upload loop
    this.uploadInterval = setInterval(() => {
      this._upload();
    }, MISSION_CONTROL_EVENT_LOG_UPLOAD_INTERVAL);
  }

  async _setAsUploaded(log) {
    if (this.disabled) return;
    delete log.status;
    await this.db?.put(MISSION_CONTROL_EVENT_LOG_STORE_NAME, log);
  }

  // upload small chunks of logs to dynamodb
  async _upload() {
    if (this.disabled) return;

    console.debug('Logger _upload');

    const local: LogEntry[] | undefined = await this.db?.getAllFromIndex(
      MISSION_CONTROL_EVENT_LOG_STORE_NAME,
      'status',
      'local',
    );

    // look for log entries with duplicate timestamps
    const duplicateTimestamps = _(local)
      .groupBy('timestamp')
      .pickBy((logs) => logs.length > 1)
      .keys()
      .value();

    // dedupe duplicate timestamps
    duplicateTimestamps.forEach((timestamp) => {
      const logs = _.filter(local, { timestamp });
      // add 1 ms to each duplicate timestamp
      logs.forEach((log, i) => {
        const newTimestamp = new Date(new Date(timestamp).getTime() + i + 1).toISOString();
        log.timestamp = newTimestamp;
      });
    });

    const logsToUpload = _.slice(local, 0, MISSION_CONTROL_EVENT_LOG_UPLOAD_MAX_LOGS);

    if (logsToUpload.length === 0) {
      console.debug('Logger there are no logs to upload.');
      return;
    }

    const timerLabel = `Logger _upload ${logsToUpload.length} log(s) attempted`;
    let countSuccess = 0;
    let overallTimeoutTriggered = false;
    console.time(timerLabel);

    const logChunks = _(logsToUpload).chunk(MISSION_CONTROL_EVENT_LOG_UPLOAD_MAX_CONCURRENT).value().entries();

    const uploadStart = Date.now();

    // @ts-ignore
    await processChunks(logChunks, (logs) => {
      const promises: Promise<void>[] = [];
      const now = Date.now();

      if (now - uploadStart >= MISSION_CONTROL_EVENT_LOG_OVERALL_UPLOAD_TIMEOUT) {
        overallTimeoutTriggered = true;
        return Promise.reject('Logger max overall timeout');
      }

      logs.forEach((log) => {
        const params = {
          TableName: 'EVENT_LOG',
          Item: log,
          ReturnValues: 'NONE',
        };

        const promise = this.dynamodb
          .create(params, MISSION_CONTROL_EVENT_LOG_INDIVIDUAL_UPLOAD_TIMEOUT)
          .then(() => {
            countSuccess++;
            this._setAsUploaded(log);
          })
          .catch((e) => console.warn('Logger', e));

        promises.push(promise);
      });

      return Promise.all(promises).catch((e) => console.warn(e));
    });

    console.timeEnd(timerLabel);
    console.debug(
      `Logger ${countSuccess} log(s) successfully uploaded${overallTimeoutTriggered ? ` before ${MISSION_CONTROL_EVENT_LOG_OVERALL_UPLOAD_TIMEOUT / 1000} second timeout triggered` : ''}.`,
    );
  }

  _cancelUploadCycle() {
    console.debug('Logger _cancelUploadCycle');
    this.uploadIntervalStarted = false;
    clearInterval(this.uploadInterval);
  }

  async _expireRecords() {
    // expire records more than 2 days old
    const expiration = new Date(
      Date.now() - MISSION_CONTROL_EVENT_LOG_EXPIRATION_PERIOD_DAYS * 24 * 60 * 60 * 1000,
    ).toISOString();
    const range = IDBKeyRange.upperBound(expiration);
    let cursor = await this.db
      ?.transaction(MISSION_CONTROL_EVENT_LOG_STORE_NAME, 'readwrite')
      .store.index('timestamp')
      .openCursor(range);
    while (cursor) {
      cursor.delete();
      cursor = await cursor.continue();
    }
  }

  async _initialize() {
    if (this.initialized) {
      return;
    }

    //check for support
    if (typeof window === 'undefined' || !('indexedDB' in window)) {
      return;
    }

    console.debug('Logger _initialize');

    // initialize database
    this.db = await openDB(MISSION_CONTROL_EVENT_LOG_DB_NAME, 3, {
      upgrade(upgradeDB, oldVersion, newVersion, transaction) {
        // initialize log item object store
        let store: IDBPObjectStore<unknown, ArrayLike<string>, 'EventLog', 'versionchange'> | undefined = undefined;
        if (!upgradeDB.objectStoreNames.contains(MISSION_CONTROL_EVENT_LOG_STORE_NAME)) {
          store = upgradeDB.createObjectStore(MISSION_CONTROL_EVENT_LOG_STORE_NAME, {
            keyPath: 'id',
            autoIncrement: true,
          });
        } else {
          store = transaction.objectStore(MISSION_CONTROL_EVENT_LOG_STORE_NAME);
        }
        if (!store.indexNames.contains('status')) {
          store.createIndex('status', 'status', { unique: false });
        }
        if (!store.indexNames.contains('timestamp')) {
          store.createIndex('timestamp', 'timestamp');
        }
      },
    });

    // check for records that need to be expired
    await this._expireRecords();

    // start upload loop
    this._startUploadCycle();

    // mark initialized
    this.initialized = true;

    await this.log('LOGGER_INTIALIZED');
  }
}

class LogTimer {
  eventType: string;
  startingData: string;
  started: number;
  ended: number;
  constructor(eventType: string, startingData: string) {
    this.eventType = eventType;
    this.startingData = startingData;
    this.started = Date.now();
  }

  stop() {
    this.ended = Date.now();
    return this.ended - this.started;
  }
}
