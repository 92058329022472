import EventBus from './EventBus';
import { ALERT_DISPATCH_EVENTS, ALERT_RETURN_EVENTS, ALERT_EVENT_NAME, FULL_SCREEN_ALERT } from './alertEvents';

export type AlertActions = { [key: string]: () => Promise<void> };
export interface AlertEvent {
  type: string;
  message: string;
  actions?: AlertActions;
  active?: boolean;
  timeoutId?: NodeJS.Timeout;
  needsConfirm?: boolean;
  confirmNum?: number;
  responses: (string | [string, string])[];
  tag?: string;
  backgroundColor: string;
  lowerScreenText?: string;
  timeout?: number;
}

export interface ConfirmReturn {
  message: string;
  confirm: boolean;
  tag: string;
  result: string | [string, string];
  confirmNum: number;
}

export interface CancelConfirmEvent {
  message?: string;
  tag?: string;
  confirm: boolean;
}

let confirmNum = 0;

type AlertOptions = Partial<Omit<AlertEvent, 'timeoutId' | 'message' | 'type'>>;

export function alertSuccess(message: string, options: AlertOptions = {}) {
  EventBus.dispatch(ALERT_EVENT_NAME, { ...options, type: ALERT_DISPATCH_EVENTS.SUCCESS, message } as AlertEvent);
}

export function alertError(message: string, options: AlertOptions = {}) {
  EventBus.dispatch(ALERT_EVENT_NAME, { ...options, type: ALERT_DISPATCH_EVENTS.ERROR, message } as AlertEvent);
}

export function alertWarn(message: string, options: AlertOptions = {}) {
  EventBus.dispatch(ALERT_EVENT_NAME, { ...options, type: ALERT_DISPATCH_EVENTS.WARNING, message } as AlertEvent);
}

export function alertInfo(message: string, options: AlertOptions = {}) {
  EventBus.dispatch(ALERT_EVENT_NAME, { ...options, type: ALERT_DISPATCH_EVENTS.INFO, message } as AlertEvent);
}

export const alertErrorConfirm = (message: string, tag?: string) =>
  _alertConfirm(ALERT_DISPATCH_EVENTS.CONFIRM_ERROR, message, tag) as Promise<boolean>;

export const alertWarnConfirm = (message: string, tag?: string) =>
  _alertConfirm(ALERT_DISPATCH_EVENTS.CONFIRM_WARN, message, tag) as Promise<boolean>;

export const alertConfirm = (message: string, tag?: string) =>
  _alertConfirm(ALERT_DISPATCH_EVENTS.CONFIRM, message, tag) as Promise<boolean>;

// Forcing this to be Promise<string> is a hack. Now I'm going to need you to get ALL THE WAY off my back about it
export const alertFullScreen = <T extends string>(
  title: string,
  message: string,
  responses: (T | [T, string])[],
  backgroundColor?: string,
  lowerScreenText?: string,
) =>
  _alertConfirm(
    ALERT_DISPATCH_EVENTS.CONFIRM,
    message,
    title,
    responses,
    true,
    backgroundColor,
    lowerScreenText,
  ) as Promise<T>;

export function _alertConfirm(
  alertDispatchType: string,
  message: string,
  tag?: string,
  responses: (string | [string, string])[] = ['Yes', 'No'],
  fullscreen: boolean = false,
  backgroundColor?: string,
  lowerScreenText?: string,
): Promise<boolean> | Promise<string | [string, string]> {
  confirmNum = confirmNum + 1;
  if (fullscreen) {
    return new Promise<string | [string, string]>((res, _rej) => {
      const confirmCopy = confirmNum;

      let confirmReturnHandler = async (event: ConfirmReturn) => {
        if (!event || ((event.message === message || event.tag === tag) && confirmCopy === event.confirmNum)) {
          EventBus.remove(ALERT_RETURN_EVENTS.UNMOUNT, confirmReturnHandler);
          EventBus.remove(ALERT_RETURN_EVENTS.CONFIRM_RETURN, confirmReturnHandler);
          res(event.result);
        }
      };

      EventBus.on(ALERT_RETURN_EVENTS.CONFIRM_RETURN, confirmReturnHandler);
      EventBus.on(ALERT_RETURN_EVENTS.UNMOUNT, confirmReturnHandler);

      EventBus.dispatch(fullscreen ? FULL_SCREEN_ALERT : ALERT_EVENT_NAME, {
        type: alertDispatchType,
        message,
        tag,
        confirmNum,
        needsConfirm: true,
        responses,
        backgroundColor,
        lowerScreenText,
      } as AlertEvent);
    });
  }
  return new Promise<boolean>((res, _rej) => {
    const confirmCopy = confirmNum;

    let confirmReturnHandler = async (event: any) => {
      if (!event || ((event.message === message || event.tag === tag) && confirmCopy === event.confirmNum)) {
        EventBus.remove(ALERT_RETURN_EVENTS.UNMOUNT, confirmReturnHandler);
        EventBus.remove(ALERT_RETURN_EVENTS.CONFIRM_RETURN, confirmReturnHandler);
        res(!!event && !!event.confirm);
      }
    };

    EventBus.on(ALERT_RETURN_EVENTS.CONFIRM_RETURN, confirmReturnHandler);
    EventBus.on(ALERT_RETURN_EVENTS.UNMOUNT, confirmReturnHandler);

    EventBus.dispatch(fullscreen ? FULL_SCREEN_ALERT : ALERT_EVENT_NAME, {
      type: alertDispatchType,
      message,
      tag,
      confirmNum,
      needsConfirm: true,
      responses,
    } as AlertEvent);
  });
}

export function cancelConfirm(message: string) {
  EventBus.dispatch(ALERT_RETURN_EVENTS.CONFIRM_RETURN, { message, confirm: false } as CancelConfirmEvent);
}

export function cancelConfirmTag(tag: string) {
  EventBus.dispatch(ALERT_RETURN_EVENTS.CONFIRM_RETURN, { tag, confirm: false } as CancelConfirmEvent);
}
