import React, { PureComponent } from 'react';

import MapButton from './MapButton';

import { AiOutlineQrcode } from 'react-icons/ai';

import './styles/scannerbutton.css';
import BarcodeScanner from '../../utils/BarcodeScanner';
import { processScan } from '../../../barcodes';
import { BarcodeFormat } from '@zxing/library';

interface ScannerButtonProps {
  toggleScanner: () => void;
  scannerActive: boolean;
}

export default class ScannerButton extends PureComponent<ScannerButtonProps> {
  render() {
    return (
      <React.Fragment>
        <MapButton
          tooltip={this.props.scannerActive ? 'Disable Camera Scanner' : 'Enable Camera Scanner'}
          onClick={this.props.toggleScanner}
          toggled={this.props.scannerActive}
        >
          <AiOutlineQrcode size={22} />
        </MapButton>
        {this.props.scannerActive && (
          <div style={{ position: 'absolute' }}>
            <BarcodeScanner
              updateDetectedCode={processScan}
              handleLoadFailure={this.props.toggleScanner}
              closeScanner={this.props.toggleScanner}
              fullScreen={false}
              type={[BarcodeFormat.DATA_MATRIX, BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128]}
              sampleScanner={true}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
