import EventBus from './EventBus';

export const BOX_EVENTS = {
  ACTIVE_UPDATED: 'updated box',
  SAMPLES_UPDATED: 'samples updated',
  MOVED_SAMPLE: 'moved sample',
  CREATED_OR_DELETED: 'updated box',
  BOXES_UPDATED: 'boxes updated',
} as const;

export function dispatchBoxesUpdated() {
  EventBus.dispatch(BOX_EVENTS.BOXES_UPDATED);
}

export function dispatchActiveBoxUpdated() {
  EventBus.dispatch(BOX_EVENTS.ACTIVE_UPDATED);
}

export function dispatchUpdatedSamples() {
  EventBus.dispatch(BOX_EVENTS.SAMPLES_UPDATED);
}

export function dispatchBoxCreatedDeleted() {
  EventBus.dispatch(BOX_EVENTS.CREATED_OR_DELETED);
}

export function dispatchBoxMovedSample() {
  EventBus.dispatch(BOX_EVENTS.MOVED_SAMPLE);
}

export function dispatchBoxesNeedReprint() {
  EventBus.dispatch(BOX_EVENTS.MOVED_SAMPLE);
}
