import { PureComponent } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import {
  DownloadKmlButton,
  DownloadRecoveryButton,
  DownloadPDFButton,
  CloseBoxButton,
  CloseMissionButton,
  AddBoxButton,
} from '../buttons';

import ShowNotesButton from '../buttons/ShowOperatorNotesButton';

import SamplingExitInterview from './SamplingExitInterview';
import MissionLabels from './MissionLabels';
import SamplingParameters from '../common/SamplingParameters';
import MissionInfo from '../common/MissionInfo';
import MissionDirections from '../common/MissionDirections';

import Airtable from '../../../airtable';

import { getCurrentMission } from '../../../dataModelHelpers';
import MissionHeader from '../common/MissionHeader';

import { BarcodeSelectionMode, LatLonSource, MissionView, OnLoading, SettingsAccessLevel } from '../../../types/types';
import HMIButton from '../buttons/HMIButton';
import ShowHMIButton from '../buttons/ShowHMIButton';
import { SampleBox } from '../../../db';
import BarcodeAdvancementSelection from '../buttons/BarcodeAdvancementSelection';
import { BarcodeSelectionModeStore } from '../../../sampleSelectionHelpers';
import CoreEnforcementCheckbox from '../buttons/CoreEnforcementCheckbox';
import { ManualMeasurementDialogParameters } from '../../../services/ManualMeasurementsChecker';
import { Jobs } from '@rogoag/airtable';

interface SamplingMissionLoadedProps {
  showNavMessage: (directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) => void;
  onLoading: OnLoading;
  activeBox?: SampleBox;
  openBoxExitInterview: (box?: SampleBox) => Promise<void>;
  addNewBox: () => Promise<void>;
  toggleScanner: () => void;
  scannerActive: boolean;
  allBoxes: SampleBox[];
  operatorNotesDialogOpen: boolean;
  toggleNotesDialog: () => void;
  toggleHMIVisible: () => void;
  hmiVisible: boolean;
  accessLevel: SettingsAccessLevel;
  samplingDepth?: number;
  samplingDepthOffset?: number;
  photosBySampleId: Record<string, any[]>;
  updateMissionView: (view: MissionView) => void;
  robotConnected: boolean;
  openEntranceInterview: () => void;
  openManualMeasurementDialog: (manualMeasurementDialogParameters: ManualMeasurementDialogParameters) => void;
}

interface SamplingMissionLoadedState {
  exitInterviewDialogOpen: boolean;
  pdfSampleOrder: string;
  jobId: string;
  currentBarcodeAdvanceSelection: BarcodeSelectionMode;
}

export default class SamplingMissionLoaded extends PureComponent<
  SamplingMissionLoadedProps,
  SamplingMissionLoadedState
> {
  constructor(props: SamplingMissionLoadedProps) {
    super(props);

    this.state = {
      exitInterviewDialogOpen: false,
      pdfSampleOrder: 'drive',
      jobId: '',
      currentBarcodeAdvanceSelection: BarcodeSelectionModeStore.get(),
    };
  }

  async componentDidMount() {
    await this.getSampleOrder();
  }

  componentDidUpdate(
    prevProps: Readonly<SamplingMissionLoadedProps>,
    prevState: Readonly<SamplingMissionLoadedState>,
    snapshot?: any,
  ): void {
    // console.log(`SamplingMissionLoaded componentDidUpdate`);
  }

  async getSampleOrder() {
    try {
      const mission = getCurrentMission();
      if (!mission?.job_id) {
        return;
      }

      const record = await Airtable.getRecord<Jobs>('Jobs', mission.job_id);
      if (record && record.get('PDF order type')) {
        this.setState({ pdfSampleOrder: record.get('PDF order type'), jobId: mission.job_id });
      } else {
        console.warn('Could not get PDF order type, defaulting to drive');
      }
    } catch (err) {
      console.error('Could not pull airtable record, default PDF order type to drive', err);
    }
  }

  render() {
    let drillDepthLabel = '';
    if (this.props.samplingDepth) {
      drillDepthLabel = `(${this.props.samplingDepth}"`;
      if (this.props.samplingDepthOffset) {
        drillDepthLabel += `${Math.sign(this.props.samplingDepthOffset) === 1 ? '+' : ''}${this.props.samplingDepthOffset}")`;
      } else {
        drillDepthLabel += ')';
      }
    }

    const mission = getCurrentMission();
    const entranceInterviewComplete = mission?.getJob()?.entrance_interview_complete;

    return (
      <Grid container spacing={1} style={{ paddingLeft: 8, marginBottom: 20 }}>
        <Paper style={{ width: '100%', marginTop: 20 }} elevation={1}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20, marginLeft: 30, marginRight: 30 }}>
              <MissionHeader />
              <Typography variant="h6" style={{ display: 'inline', marginLeft: 10 }}>
                {drillDepthLabel}
              </Typography>
              <MissionDirections
                jobId={this.state.jobId}
                thisMissionIsLoaded={true}
                showNavMessage={this.props.showNavMessage}
              />
            </Grid>

            {this.props.accessLevel === 'Technician' && (
              <Grid item xs={12} style={{ marginLeft: 18, marginRight: 18 }}>
                <MissionInfo expanded={false} />
              </Grid>
            )}

            {this.props.accessLevel === 'Technician' && (
              <Grid item xs={12} style={{ marginLeft: 18, marginRight: 18 }}>
                <SamplingParameters expanded={false} onLoading={this.props.onLoading} mapType="sampling" />
              </Grid>
            )}

            <Grid
              container
              item
              xs={12}
              spacing={2}
              alignItems="center"
              justifyContent="center"
              style={{ paddingTop: 20, paddingBottom: 20, marginLeft: 12.5 }}
            >
              <CloseMissionButton openDialog={() => this.setState({ exitInterviewDialogOpen: true })} />

              {!!mission && (
                <Button
                  onClick={this.props.openEntranceInterview}
                  variant="outlined"
                  style={{
                    marginLeft: 10,
                    borderWidth: entranceInterviewComplete ? 1 : 5,
                    borderColor: entranceInterviewComplete ? 'green' : 'red',
                  }}
                >
                  {entranceInterviewComplete ? 'Update Field Conditions' : 'Log Field Conditions'}
                </Button>
              )}

              {this.props.activeBox ? (
                <CloseBoxButton
                  disabled={!this.props.activeBox}
                  openBoxExitInterview={this.props.openBoxExitInterview}
                />
              ) : (
                <AddBoxButton />
              )}

              <DownloadRecoveryButton />

              <ShowNotesButton
                isShown={this.props.operatorNotesDialogOpen}
                handleClick={this.props.toggleNotesDialog}
              />

              <ShowHMIButton toggleHMIVisible={this.props.toggleHMIVisible} hmiVisible={this.props.hmiVisible} />

              <BarcodeAdvancementSelection
                accessLevel={this.props.accessLevel}
                updateBarcodeAdvancement={(currentBarcodeAdvanceSelection) =>
                  this.setState({ currentBarcodeAdvanceSelection })
                }
              />

              {this.props.accessLevel === 'Technician' && <CoreEnforcementCheckbox />}

              {this.props.accessLevel === 'Technician' && <DownloadKmlButton />}

              {this.props.accessLevel === 'Technician' && <DownloadPDFButton accessLevel={this.props.accessLevel} />}

              <Grid item>
                <Button onClick={this.props.toggleScanner} variant="outlined">
                  {this.props.scannerActive ? 'Stop Scan' : 'Camera Scan'}
                </Button>
              </Grid>

              <Grid item>
                <Button onClick={() => this.props.updateMissionView('helper')} variant="outlined">
                  Helper View
                </Button>
              </Grid>
            </Grid>

            {this.props.hmiVisible && (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ paddingBottom: 20, marginLeft: 12.5 }}
              >
                <HMIButton height={70} buttonID="butt_1" defaultLabel="Go/Core" />

                {this.props.accessLevel === 'Technician' && (
                  <HMIButton height={70} buttonID="butt_2" defaultLabel="Soil in Bucket" />
                )}
                {this.props.accessLevel === 'Technician' && (
                  <HMIButton height={70} buttonID="butt_3" defaultLabel="Dump Required" />
                )}

                <HMIButton height={70} buttonID="butt_4" defaultLabel="Auto/Man" />

                {this.props.accessLevel === 'Technician' && (
                  <HMIButton height={70} buttonID="front" defaultLabel="Front Lights" />
                )}
                {this.props.accessLevel === 'Technician' && (
                  <HMIButton height={70} buttonID="rear" defaultLabel="Rear Lights" />
                )}
                {this.props.accessLevel === 'Technician' && (
                  <HMIButton height={70} buttonID="strobe" defaultLabel="Strobe Lights" />
                )}
                {this.props.accessLevel === 'Technician' && (
                  <HMIButton height={70} buttonID="reset" defaultLabel="Reset (Tap 2x)" />
                )}
              </Grid>
            )}
          </Grid>
        </Paper>

        <MissionLabels
          toggleScanner={this.props.toggleScanner}
          scannerActive={this.props.scannerActive}
          barcodeAdvancementMode={this.state.currentBarcodeAdvanceSelection}
          box={this.props.activeBox}
          allBoxes={this.props.allBoxes}
          photosBySampleId={this.props.photosBySampleId}
          openManualMeasurementDialog={this.props.openManualMeasurementDialog}
        />

        {/* Dialogs Start */}
        <SamplingExitInterview
          open={this.state.exitInterviewDialogOpen}
          closeForm={() => this.setState({ exitInterviewDialogOpen: false })}
          accessLevel={this.props.accessLevel}
        />
        {/* Dialogs End */}
      </Grid>
    );
  }
}
