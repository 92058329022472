import React, { useRef } from 'react';

import { ListItem, TextField, Button, Typography, Menu, MenuItem } from '@material-ui/core';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state/index';

import logger from '../../../logger';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Sample, SampleBox } from '../../../db';
import { FaBarcode } from 'react-icons/fa';
import { getCurrentSession } from '../../../dataModelHelpers';

interface SampleLabelProps {
  allBoxes: SampleBox[];
  backgroundColor: any;
  barcodeGapError: boolean;
  barcodeHandler: (sampleInstanceId: number, barcode: string) => Promise<void>;
  barcodeId: string;
  currentBoxUid?: string;
  currentSampleId: string;
  disabled: boolean;
  duplicateBarcodeError: boolean;
  invalidBarcodeError: boolean;
  moveSample: (sampleInstanceId: number, sampleId: number) => Promise<void>;
  onCoresButtonClick?: (sampleID: string) => void;
  photos: any[];
  sampleBox?: SampleBox;
  sampleHandler: (sampleInstaceId: number) => Promise<void>;
  sampleId: string;
  sampleInstanceId: number;
}

const sampleButtonStyle = {
  minHeight: 56,
  marginBottom: 22,
  marginLeft: 5,
};

export default React.memo(
  React.forwardRef(function SampleLabel(props: SampleLabelProps, ref: any) {
    const sample = Sample.get(props.sampleInstanceId);
    const content =
      props.barcodeId || (sample?.skipped_or_deleted ? `${sample.change_type} (${sample.change_reason})` : '');
    const inputRef = useRef();
    const currentUserName = getCurrentSession()?.username;

    const liStyle: React.CSSProperties = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: 0,
      height: '100%',
    };

    if (props.backgroundColor) {
      liStyle.backgroundColor = props.backgroundColor;
    }

    return (
      <ListItem key={`${props.sampleInstanceId}ListItem`} divider style={liStyle}>
        {props.sampleBox ? (
          <PopupState variant="popover">
            {(popupState) => (
              <React.Fragment>
                {/* 
              // @ts-ignore */}
                <Typography
                  component={Button}
                  variant="caption"
                  style={{
                    fontStyle: 'bold',
                    width: 120,
                    height: 78,
                    textTransform: 'none',
                    fontSize: 16,
                    // marginBottom: 8,
                    padding: 0,
                  }}
                  {...bindTrigger(popupState)}
                  endIcon={<ArrowDropDownIcon />}
                  disabled={props.sampleBox?.username !== currentUserName} // do not allow to change box if not the owner
                >
                  {props.sampleBox?.short_uid}
                  {props.sampleBox?.uid === props.currentBoxUid && '\n(current)'}
                </Typography>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                    }}
                  >
                    Cancel
                  </MenuItem>
                  {props.allBoxes
                    .filter((boxEntry) => props.sampleBox?.uid !== boxEntry.uid)
                    .filter((boxEntry) => boxEntry.labName === props.sampleBox?.labName)
                    .filter((boxEntry) => boxEntry.username === currentUserName) // do not allow to move to a box that is not owned
                    .map((boxEntry) => (
                      <MenuItem
                        onClick={async () => {
                          popupState.close();
                          await props.moveSample(props.sampleInstanceId, boxEntry.instance_id);
                        }}
                        key={boxEntry.uid}
                      >
                        {props.currentBoxUid === boxEntry.uid ? `${boxEntry.short_uid}(current)` : boxEntry.short_uid}
                      </MenuItem>
                    ))}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        ) : (
          <div style={{ width: 120, padding: 0 }} />
        )}

        <TextField
          label={props.sampleId}
          value={content}
          inputRef={inputRef}
          inputProps={{
            readOnly: props.currentSampleId !== props.sampleId, // || textfieldClicks < 2
          }}
          onChange={
            props.currentSampleId !== props.sampleId
              ? undefined
              : async (e) => {
                  await props.barcodeHandler(props.sampleInstanceId, e.target.value);
                }
          }
          error={props.barcodeGapError || props.duplicateBarcodeError || props.invalidBarcodeError}
          helperText={
            props.barcodeGapError
              ? 'Barcode gap. Something is probably wrong!'
              : props.invalidBarcodeError
                ? 'Invalid barcode format'
                : props.duplicateBarcodeError
                  ? 'Duplicate barcode'
                  : ' ' // https://mui.com/material-ui/react-text-field/#helper-text
          }
          disabled={props.disabled}
          fullWidth
          variant="outlined"
          data-testid={`mission-label-${props.currentSampleId}`}
          ref={ref}
        />

        {!props.disabled ? (
          <React.Fragment>
            {props.photos.length > 0 && (
              <Typography style={{ marginLeft: 5, minWidth: 80 }}>{`Pictures: ${props.photos.length}`}</Typography>
            )}
            {!!props.onCoresButtonClick && (
              <Button
              style={sampleButtonStyle}
                onClick={() => props.onCoresButtonClick!(props.sampleId)}
                variant="outlined"
                size="large"
                color="secondary"
              >
                Cores
              </Button>
            )}

            <Button
              style={sampleButtonStyle}
              onClick={async () => {
                await logger.log('BARCODE_CLEAR', { content, sampleId: props.sampleId });
                await props.barcodeHandler(props.sampleInstanceId, '');
              }}
              variant="outlined"
              size="large"
              disabled={sample?.skipped_or_deleted}
              color="secondary"
            >
              Clear
            </Button>
            {/* <SkipSampleSiteButton
              sampleId={props.sampleId}
              barcodeHandler={props.barcodeHandler}
              updateSample={undefined}
              // TODO always enable skip button so operators see the notification
              // TODO remove after fall 2023 season
              disabled={false}
              //disabled={Boolean(props.barcodeId)}
              sample={sample} /> */}

            <Button
              style={sampleButtonStyle}
              // style={{ marginBottom: 10 }}
              onClick={async () => {
                await props.sampleHandler(props.sampleInstanceId);
                //await logger.log('BARCODE_SCAN', { content, sampleId: props.sampleId });
                //await props.barcodeHandler(props.sampleInstanceId, props.barcodeId);
              }}
              color="secondary"
            >
              <FaBarcode size={32} />
            </Button>
          </React.Fragment>
        ) : (
          <div style={{ marginRight: 128 }} />
        )}
      </ListItem>
    );
  }),
);
