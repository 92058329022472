import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Typography } from '@material-ui/core';
import { AutoGenHelperParams, JobType } from '../../types/types';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
  body: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
  },
}))(TableCell);

interface AutoGenHelperProps {
  autogenHelper: AutoGenHelperParams;
}

const FileSourceMapping: Record<string, Record<JobType, string>> = {
  'Pts Shp': {
    Zone: 'core identifiers (1.1, 1-1, etc)',
    Grid: 'sample identifiers (1, 2, 3, etc)',
    Modgrid: 'sample identifiers (1, 2, 3, etc)',
  },
  'Sample Zones Shp': {
    Zone: 'zone numbers (1, 2, 3, etc or A, B, C, etc)',
    Grid: '',
    Modgrid: '',
  },
};

export default function AutoGenHelper(props: AutoGenHelperProps) {
  const { title, selection, headers, data, featureTypes, onSelection } = props.autogenHelper;
  let displayData: string[][] = [];
  let displayHeaders: string[] = [];
  if (props.autogenHelper.autoFilter || true) {
    for (const _ of data) {
      displayData.push([]);
    }
    for (const [columnIndex, columnHeader] of headers.entries()) {
      const rowHasValues = data.some((row) => !!row[columnIndex]);
      if (rowHasValues) {
        displayData.forEach((_, rowIndex) => displayData[rowIndex].push(data[rowIndex][columnIndex]));
        displayHeaders.push(columnHeader);
      }
    }
  } else {
    displayData = data;
    displayHeaders = headers;
  }
  const [value, setValue] = useState<string>();
  const selectedCol = value || selection;
  const helperText = FileSourceMapping[props.autogenHelper.source][props.autogenHelper.jobType];
  const sanitizedSource = props.autogenHelper.source
    .replace('Pts', 'Points')
    .replace('Shp', 'Shapefile')
    .replace('Bnd', 'Boundary');

  return (
    <Dialog disableEscapeKeyDown maxWidth={false} open={true} style={{ zIndex: 1600 }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ minHeight: 70 }}>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Typography variant="body1">
              {Object.entries(featureTypes)
                .map((entry) => `${entry[0]}: ${entry[1]}`)
                .join(', ')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">{`File Source: ${sanitizedSource}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1">{helperText ? `Look for ${helperText}` : ``}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {displayHeaders.map((header) =>
                  header === selectedCol ? (
                    <StyledTableCell
                      key={header}
                      data-testid={`auto-gen-${header}`}
                      onClick={() => {
                        setValue(header);
                      }}
                    >
                      {header}
                    </StyledTableCell>
                  ) : (
                    <TableCell
                      key={header}
                      data-testid={`auto-gen-${header}`}
                      onClick={() => {
                        setValue(header);
                      }}
                    >
                      {header}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.map((row) => (
                <TableRow key={JSON.stringify(row)}>
                  {[...row.entries()].map((entry) =>
                    displayHeaders[entry[0]] === selectedCol ? (
                      <StyledTableCell
                        key={`${entry[0]}_${entry[1]}`} 
                        onClick={() => {
                          setValue(displayHeaders[entry[0]]);
                        }}
                      >
                        {entry[1]}
                      </StyledTableCell>
                    ) : (
                      <TableCell
                        key={`${entry[0]}_${entry[1]}`} 
                        onClick={() => {
                          setValue(displayHeaders[entry[0]]);
                        }}
                      >
                        {entry[1]}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="auto-gen-select"
          variant="contained"
          color="secondary"
          onClick={() => {
            onSelection(selectedCol);
          }}
        >
          Select
        </Button>
        <Button
          data-testid="auto-gen-ignore"
          variant="contained"
          color="default"
          onClick={() => {
            onSelection('ignore');
          }}
        >
          Ignore file
        </Button>
      </DialogActions>
    </Dialog>
  );
}
