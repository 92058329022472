import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import SelectedRecord, { SelectedRecordList } from './SelectedRecordClass';

import { refresh, getRecords, search } from '../db_ops/airtable_search_ops';
import AirtableRecord from './AirtableRecordClass';
import { AirtableRecordFields, IAirtableSearch } from './types';

export default class AirtableSearch<T extends AirtableRecordFields = any>
  extends ObjectClassGenerator<AirtableSearch>('AirtableSearch')
  implements IAirtableSearch
{
  // attributes
  #table: string;
  #view: string;
  #query: string;
  #currentRefresh: Promise<void>;

  // relationships

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(AirtableSearch, 'table');
    this.publishAttribute(AirtableSearch, 'view');
    this.publishAttribute(AirtableSearch, 'query');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: any = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#table = state.table || '';
    this.#view = state.view || '';
    this.#query = state.query || '';
    this.#currentRefresh = state.currentRefresh || undefined;
  }

  dispose() {
    for (const selectedrecord of this.getSelectedRecords()) {
      selectedrecord.AirtableSearch_id = 0;
      selectedrecord.dispose();
    }

    AirtableSearch.delete(this.instance_id);
  }

  set table(value) {
    this.#table = value;
    this.syncToDB();
  }

  get table() {
    return this.#table;
  }

  set view(value) {
    this.#view = value;
    this.syncToDB();
  }

  get view() {
    return this.#view;
  }

  set query(value) {
    this.#query = value;
    this.syncToDB();
  }

  get query() {
    return this.#query;
  }

  set currentRefresh(value) {
    this.#currentRefresh = value;
  }

  get currentRefresh() {
    return this.#currentRefresh;
  }

  getSelectedRecords() {
    if (this._refs && this._refs.SelectedRecord) {
      return new SelectedRecordList<T>(
        ...Array.from(this._refs.SelectedRecord)
          .map((id) => SelectedRecord.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const selectedrecords = SelectedRecord.query<SelectedRecord<T>>(
        (sel) => sel && sel.AirtableSearch_id === this.instance_id,
      );
      for (const selectedrecord of selectedrecords) {
        selectedrecord.AirtableSearch_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SelectedRecordList<T>(...selectedrecords);
    }
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = AirtableSearch.query(
      (sel) =>
        sel.instance_id !== this.instance_id &&
        sel.view === this.view &&
        sel.query === this.query &&
        sel.table === this.table,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate airtable search found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`airtable search: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    return problems;
  }

  async refresh({ waitForAttachments = true } = {}) {
    // @ts-ignore
    return await refresh.bind(this)({ waitForAttachments });
  }

  getRecords() {
    // @ts-ignore
    return getRecords.bind(this)() as AirtableRecord<T>[];
  }

  static async search(tableName: string, viewName: string, query: string, refreshOnCreate: boolean) {
    await search(tableName, viewName, query, refreshOnCreate);
  }

  static async recover(allSelectedRecords: any[]) {
    const existingSearch = AirtableSearch.query();
    if (existingSearch.length) return;
    for (const record of allSelectedRecords) {
      const newRecord = await AirtableSearch.create(record['_instance_id']);
      for (const key of Object.keys(record).filter((key) => key === '_instance_id')) {
        newRecord[key] = record[key];
      }
    }
  }
}

export class AirtableSearchList extends DataObjectList<AirtableSearch> {
  getSelectedRecords() {
    return new SelectedRecordList(
      ...this.reduce(
        (airtablesearchs: SelectedRecord[], airtablesearch) =>
          airtablesearchs.concat(airtablesearch.getSelectedRecords()),
        [],
      ).filter((sel) => !!sel),
    );
  }
}
