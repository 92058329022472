import axios from 'axios';
import md5 from 'md5';
import { BasicJsonRecord } from './types/types';
import { LocalStorageGenerator } from './utils';
// import WebSocket from 'ws';

// TODO make environment variable?
const ProxyServerStore = LocalStorageGenerator('file_server_server_credentials', {
  hostname: 'year.rogoag.com',
  port: 9091,
});

const FILE_HTTPS_URL = () => `https://${ProxyServerStore.get().hostname}:${ProxyServerStore.get().port}`;

export interface HelperData {
  data: {
    sample_id: string;
    job_id: string;
  };
}

export const proxyGetFiles = async (job_id: string) => {
  const response = await axios.get<string[]>(`${FILE_HTTPS_URL()}/files?search=${job_id}&extension=.png`);
  console.log(`Get files from local server result: ${response.data}`);
  console.log(response.data);
  return response.data;
};

export const proxyGetFile = async (path: string) => {
  const response = await axios.get<Blob>(`${FILE_HTTPS_URL()}/file/${path}`, { responseType: 'blob' });
  console.log(`Get file from local server result: ${response.data}`);
  return response.data;
};

export const getWebsocketConnection = () => {
  return new WebSocket(`wss://${ProxyServerStore.get().hostname}:${ProxyServerStore.get().port}/ws`);
};

export const proxyPublishHelperData = async (data: HelperData) => {
  const response = await axios.post<string>(`${FILE_HTTPS_URL()}/websocket-relay/helper-data`, data);
  console.log(`Publish helper data to local server result: ${response.status}`);
  return response.data;
};

const POST_TIMEOUT = 10000;
export const proxyPostFile = async (
  content: Blob | string,
  job_id: string,
  filename: string,
  { uploadToAWS = true, additionalParams = {} as BasicJsonRecord } = {},
) => {
  const form = new FormData();
  const contentIsString = (content: Blob | string): content is string => {
    return typeof content === 'string';
  };
  const payload = contentIsString(content) ? new Blob([content], { type: 'plain/text' }) : content;
  form.append('file', payload, filename);
  let url = `${FILE_HTTPS_URL()}/file?job_id=${job_id}&upload_to_aws=${uploadToAWS}&hostname=${window.location.host}`;
  for (const [key, value] of Object.entries(additionalParams)) {
    url += `&${key}=${value}`;
  }
  const response = await axios.post<string>(url, form, { timeout: POST_TIMEOUT });
  console.log(`Send file to local server result: ${response.data}`);

  const view = new Uint8Array(await payload.arrayBuffer());
  const hash = md5(view);

  console.log(`${hash}, ${response.data}, ${response.data === hash}`);
  if (response.data !== hash) {
    throw Error('Received hash did not match file!');
  }
  return true;
};
