import React, { PureComponent } from 'react';

import { Grid } from '@material-ui/core';

import { LoadingButton } from '../../utils';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getCurrentMission } from '../../../dataModelHelpers';
import { allBarcodesValid } from '../../../barcodes';
import download from 'downloadjs';
import { alertConfirmDownload } from '../helpers/downloads';
import { alertError, cancelConfirmTag } from '../../../alertDispatcher';
export default class DownloadShapeButton extends PureComponent<{}> {
  constructor(props) {
    super(props);

    this.downloadShapefile = this.downloadShapefile.bind(this);
  }

  componentWillUnmount() {
    cancelConfirmTag(this.constructor.name);
  }

  async downloadShapefile() {
    try {
      const mission = getCurrentMission();
      if (!mission) {
        alertError('No mission available');
        return;
      }
      if (allBarcodesValid() || (await alertConfirmDownload(this.constructor.name))) {
        const [shp1, shp2, shp3] = await Promise.all([
          mission.to_shapefile(true, true),
          mission.to_shapefile(true, false),
          mission.to_shapefile(false, true),
        ]);
        console.log(typeof shp1);
        if (shp1 && shp2 && shp3) {
          download(shp1, `${mission.name}_pts+bnds.zip`);
          download(shp2, `${mission.name}_pts.zip`);
          download(shp3, `${mission.name}_bnds.zip`);
        } else {
          alertError(`Could not export Shapefile ${!!shp1} ${!!shp2} ${!!shp3}`);
          //console.error('Could not export Shapefile', shp1, shp2, shp3);
        }
      }
    } catch (err) {
      alertError(`Could not export Shapefile ${JSON.stringify(err)}`);
      //console.error('Could not export Shapefile', err);
    }
  }

  render() {
    return (
      <Grid item>
        <LoadingButton variant="outlined" onClick={this.downloadShapefile}>
          <CloudDownloadIcon style={{ paddingRight: 7.5 }} />
          Shapefile
        </LoadingButton>
      </Grid>
    );
  }
}
