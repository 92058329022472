import React, { Fragment } from 'react';

import List from '@material-ui/core/List';
import Pagination from '@material-ui/lab/Pagination';

import MapsJob from './MapsJob';
import { AirtableRecord } from '../../../db';
import { Button, ListSubheader } from '@material-ui/core';
import _ from 'lodash';

const PAGE_SIZE = 50;

interface MapsScheduleCollectionProps {
  filterText: string;
  items: AirtableRecord[];
  loadMissionMaps: (id: string) => Promise<void>;
}

function getJobKey(job: AirtableRecord) {
  // console.log(`getJobKey: ${job.id}`);
  // console.log(`getJobKey: ${job.get('Robot')} - ${job.get('Operator Name')}`);
  const hasOperatorName = job.has('Operator Name') && !!job.get('Operator Name')?.[0];
  const operatorName = hasOperatorName ? job.get('Operator Name')[0] : 'Unassigned';
  const robot = job.has('Robot') && !!job.get('Robot') ? job.get('Robot') : '~No Robot';

  return `${robot} - ${operatorName}`;
}

export default function MapsScheduleCollection(props: MapsScheduleCollectionProps) {
  const [page, setPage] = React.useState(1);
  const [scheduledJobsOnly] = React.useState(false);
  // TODO leaving here for future filtering applications
  // const tokenArrayMatch = (filterText: string, value?: any[]) => {
  //   if (!value) return false;
  //   const searchName = value.map(v => v.toLowerCase()).join(' ');
  //   const tokens = filterText.split(' ');
  //   return tokens.every(token => searchName.includes(token));
  // }
  const tokenMatch = (filterText: string, name: string) => {
    if (!name) return false;
    const searchName = name.toLowerCase();
    const tokens = filterText.split(' ');
    return tokens.every((token) => searchName.includes(token));
  };
  const filterText = props.filterText?.trim();
  const isRogoJobId = filterText.startsWith('rec');
  const jobIdMatch = (filterText: string, job_id: string) => {
    return job_id.toLowerCase().includes(filterText);
  };

  const normalizedFilterText = filterText.toLowerCase();
  // TODO this is getting a bit inefficient, we need to come up with
  // a better way to filter items. For efficiency, we could probably
  // remove the point filtering options, those are likely not used
  // by anyone except me looking for specific types of jobs
  const allItems = props.items.filter(
    (sel) =>
      !filterText ||
      tokenMatch(normalizedFilterText, sel.get('Name')) ||
      tokenMatch(normalizedFilterText, sel.get('Robot')) ||
      // tokenArrayMatch(normalizedFilterText, sel.get('Operator Name')) ||
      (isRogoJobId && jobIdMatch(normalizedFilterText, sel.get('Rogo Job ID'))) ||
      (normalizedFilterText.includes('has:points') && sel.hasAttachments('Pts Shp')) ||
      (normalizedFilterText.includes('has:zones') && sel.hasAttachments('Sample Zones Shp')) ||
      (normalizedFilterText.includes('has:cores+zones') &&
        sel.hasAttachments('Sample Zones Shp') &&
        sel.hasAttachments('Pts Shp')),
  );

  const count = Math.ceil(allItems.length / PAGE_SIZE);
  const p = Math.min(page, count);
  const sortedJobs = _.sortBy(allItems, getJobKey);

  const items = sortedJobs.slice((p - 1) * PAGE_SIZE, p * PAGE_SIZE);

  let lastJobKey = '';
  return (
    <React.Fragment>
      {isRogoJobId && items.length === 0 && (
        <Button variant={'outlined'} onClick={async () => await props.loadMissionMaps(props.filterText)}>
          Load From Airtable By ID
        </Button>
      )}
      {/* <Box display={'flex'}>
        <Checkbox checked={scheduledJobsOnly} onChange={(event) => {
          setScheduledJobsOnly(event.target.checked);
        }} />
        <Typography>Scheduled Jobs Only?</Typography>
      </Box> */}
      {allItems.length > PAGE_SIZE ? (
        <Pagination
          count={count}
          page={p}
          variant="outlined"
          shape="rounded"
          onChange={(_e, p) => {
            setPage(p);
          }}
        />
      ) : null}
      <List dense>
        {/* {groupedJobs && Object.keys(groupedJobs).map((key, _idx) => {
          console.count(`key=${key}`);
          return (
            <>
              <ListSubheader key={key}>{key || "Unassigned"}</ListSubheader>
              {groupedJobs[key].map((item, _idx) => {
                return (<MapsJob key={item.id} job={item} id={item.id} name={item.get('Name')} order={item.get('Order')} {...props} />);
              })}
            </>
          )
        })} */}
        {items
          .filter((job) => !scheduledJobsOnly || job.get('Scheduled?'))
          .map((item, _idx) => {
            const jobKey = getJobKey(item);
            if (jobKey !== lastJobKey) {
              lastJobKey = jobKey;
              return (
                <Fragment key={item.id}>
                  <ListSubheader key={jobKey}>{jobKey}</ListSubheader>
                  <MapsJob
                    key={item.id}
                    job={item}
                    id={item.id}
                    name={item.get('Name')}
                    order={item.get('Order')}
                    {...props}
                  />
                </Fragment>
              );
            }
            return (
              <MapsJob
                key={item.id}
                job={item}
                id={item.id}
                name={item.get('Name')}
                order={item.get('Order')}
                {...props}
              />
            );
          })}
      </List>
    </React.Fragment>
  );
}
