import { PureComponent } from 'react';

import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Portal from '@material-ui/core/Portal';

import { FaRobot, FaMap, FaShippingFast } from 'react-icons/fa';
import { AiFillSchedule } from 'react-icons/ai';

import { getCurrentSession } from '../dataModelHelpers';
import EventBus from '../EventBus';
import { SESSION_EVENTS } from '../sessionEvents';
import { dispatchTaskUpdated } from '../taskEvents';
import { Task } from '../db';
import { TaskName } from '../db/TaskClass';

interface TasksProps {
  taskRef?: HTMLDivElement;
  task?: TaskName;
  sidebarToggleFunction: () => void;
}

interface TasksState {
  tasks: Task[];
}

export default class Tasks extends PureComponent<TasksProps, TasksState> {
  ICON_MAP = {
    maps: <FaMap />,
    sampling: <FaRobot />,
    scheduling: <AiFillSchedule />,
    shipping: <FaShippingFast />,
  };

  constructor(props: TasksProps) {
    super(props);
    this.state = {
      tasks: [],
    };

    this.getTasks = this.getTasks.bind(this);
  }

  async componentDidMount() {
    EventBus.on(SESSION_EVENTS.UPDATED, this.getTasks);
    const session = getCurrentSession();
    if (session) {
      this.getTasks();
    }
  }

  componentWillUnmount() {
    // TODO TS should we really not have a second argument?
    EventBus.remove(SESSION_EVENTS.UPDATED, this.getTasks);
  }

  updateTask(newTaskName: TaskName) {
    const session = getCurrentSession();
    if (session) {
      const taskList = session?.getUser()?.getTasks();
      if (!taskList) return;
      const currentTask = taskList.find((task) => newTaskName === task?.name);
      if (!currentTask) return;
      session.Task_id = currentTask.instance_id;
      dispatchTaskUpdated(newTaskName);
    }
  }

  getTasks() {
    const session = getCurrentSession();
    // get all tasks for this user
    const tasks = session?.getUser()?.getTasks() || [];
    this.setState({ tasks: tasks.filter((task) => !!task) });
    if (session) {
      // get the current task session
      const currentTask = session.getTask();
      if (!currentTask) {
        this.setDefaultTask();
      }
    }
  }

  setDefaultTask() {
    const session = getCurrentSession();
    const role = session?.getUser()?.getRole();
    this.updateTask(role?.default_task as TaskName);
  }

  render() {
    return (
      <Portal container={this.props.taskRef}>
        {this.state.tasks.map((task) => (
          <ListItem key={`task-${task.name}`}>
            <Button
              style={{
                color: task.name === this.props.task ? '#b5734e' : 'white',
                width: '200px',
                justifyContent: 'left',
                textTransform: 'capitalize',
              }}
              onClick={async () => {
                await this.updateTask(task.name);
                this.props.sidebarToggleFunction();
              }}
              startIcon={this.ICON_MAP[task.name]}
            >
              {task.name}
            </Button>
          </ListItem>
        ))}
      </Portal>
    );
  }
}
