import { getCurrentMission } from '../dataModelHelpers';
import { Sample, SoilCore } from '../db';
import SoilWithCore from '../assets/img/SoilExpandedWithCore.png';
import SoilLossMeasurement from '../assets/img/SoilCoreLossMeasurement.png';
import SoilWithHole from '../assets/img/SoilExpandedWithCoreHole.png';
import { alertInfo } from '../alertDispatcher';
import { ManualMeasurementParameters } from './CoreCompletion';

const TARGET_DEPTH_SUGGESTED_MIN_PERCENT_DECREASE = 15;
const TARGET_DEPTH_SUGGESTED_MAX_PERCENT_INCREASE = 5;
const TARGET_DEPTH_ABSOLUTE_MAX_MULTIPLICATOR = 2;

const DEPTH_OR_LENGTH_MEASUREMENT_BENCHMARK_STEP = 5;

const CORE_LOSS_SUGGESTED_MIN = -6;
const CORE_LOSS_SUGGESTED_MAX = 2;
const CORE_LOSS_ABSOLUTE_MIN = -20;
const CORE_LOSS_ABSOLUTE_MAX = 20;

class ManualMeasurementsChecker {
  async check(
    openDialog: (params: ManualMeasurementDialogParameters) => void,
    soilCore?: SoilCore,
    showDismissButton = false,
  ) {
    if (!soilCore) {
      return;
    }

    const mission = getCurrentMission();
    if (!mission) {
      return;
    }

    const job = mission.getJob();
    if (!job) {
      return;
    }

    if (!job.manual_measurement_required) {
      return;
    }

    const targetDepthCm = soilCore.getTargetDepth();

    const generateBenchmarks = (min: number, max: number, step: number) => {
      const out = [min];

      let i = min + step;
      while (i <= max) {
        out.push(i);

        i += step;
      }

      return out;
    };

    let paramsForModalDialog: ManualMeasurementDialogParameters;
    const suggestedMin = generateSuggestedMin(targetDepthCm);
    const suggestedMax = generateSuggestedMax(targetDepthCm);
    const measurementParametersForDepthOrLengthOptions = {
      initialValue: soilCore.core_length_cm ? soilCore.core_length_cm : targetDepthCm,
      suggestedMin,
      suggestedMax,
      absoluteMin: 0,
      absoluteMax: targetDepthCm * TARGET_DEPTH_ABSOLUTE_MAX_MULTIPLICATOR,
      benchmarks: generateBenchmarks(suggestedMin, suggestedMax, DEPTH_OR_LENGTH_MEASUREMENT_BENCHMARK_STEP).map(
        (value) => ({ value, label: value.toString() }),
      ),
    };
    const sample = soilCore.getSample();
    if (!sample) {
      throw new Error('Sample is not defined');
    }
    let dialogTitle: string;

    const alertOnComplete = (title: string, measurement: number, sample: Sample | undefined) => {
      alertInfo(`${title} recorded as ${measurement} (Sample ${sample?.sample_id || 'None'})`);
    };

    const manualMeasurementPromise = new Promise<void>((resolve) => {
      if (job.manual_hole_depth_measurement) {
        dialogTitle = 'Hole Depth (cm)';

        paramsForModalDialog = {
          title: dialogTitle,
          imageSource: SoilWithHole,
          sample,
          showDismissButton,
          onComplete: (measurement: number) => {
            soilCore.core_length_cm = measurement;
            alertOnComplete(dialogTitle, measurement, sample);
            resolve();
          },
          measurementParameters: measurementParametersForDepthOrLengthOptions,
        };
      } else if (job.manual_core_length_measurement) {
        dialogTitle = 'Core Length (cm)';

        paramsForModalDialog = {
          title: dialogTitle,
          imageSource: SoilWithCore,
          sample,
          showDismissButton,
          onComplete: (measurement: number) => {
            soilCore.core_length_cm = measurement;
            alertOnComplete(dialogTitle, measurement, sample);
            resolve();
          },
          measurementParameters: measurementParametersForDepthOrLengthOptions,
        };
      } else if (job.manual_core_loss_measurement) {
        dialogTitle = 'Core Loss (cm)';

        paramsForModalDialog = {
          title: dialogTitle,
          imageSource: SoilLossMeasurement,
          sample,
          showDismissButton,
          onComplete: (measurement: number) => {
            soilCore.core_length_cm = targetDepthCm + measurement;
            alertOnComplete(dialogTitle, measurement, sample);
            resolve();
          },
          measurementParameters: {
            initialValue: soilCore.core_length_cm ? soilCore.core_length_cm - targetDepthCm : 0,
            suggestedMin: CORE_LOSS_SUGGESTED_MIN,
            suggestedMax: CORE_LOSS_SUGGESTED_MAX,
            absoluteMin: CORE_LOSS_ABSOLUTE_MIN,
            absoluteMax: CORE_LOSS_ABSOLUTE_MAX,
            benchmarks: true,
          },
        };
      }

      openDialog(paramsForModalDialog);
    });

    await manualMeasurementPromise;

    function generateSuggestedMax(targetDepthCm: number): number {
      let raw = Math.round((targetDepthCm * (100 + TARGET_DEPTH_SUGGESTED_MAX_PERCENT_INCREASE)) / 100);

      return raw + DEPTH_OR_LENGTH_MEASUREMENT_BENCHMARK_STEP - (raw % DEPTH_OR_LENGTH_MEASUREMENT_BENCHMARK_STEP);
    }

    function generateSuggestedMin(targetDepthCm: number): number {
      let raw = Math.round((targetDepthCm * (100 - TARGET_DEPTH_SUGGESTED_MIN_PERCENT_DECREASE)) / 100);

      return raw - (raw % DEPTH_OR_LENGTH_MEASUREMENT_BENCHMARK_STEP);
    }
  }
}

export type ManualMeasurementDialogParameters = {
  title: string;
  imageSource: string;
  sample: Sample;
  showDismissButton?: boolean;
  onComplete: (measurementCm: number) => void;
  measurementParameters: ManualMeasurementParameters;
};

export default ManualMeasurementsChecker;
