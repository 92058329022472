import { Extent } from 'ol/extent';
import { isLocalhost, LocalStorageGenerator } from '../utils';
import { Size } from 'ol/size';
import { _MS_PER_DAY } from '../constants';
import { Coordinate } from 'ol/coordinate';
import { ScheduleViewMode } from '../types/types';

/**
 * Check if a item in local storage has expeired (timestamp < now)
 * @param   {string} key The local storage key to look up
 * @returns {boolean} True if the date is expired, false if it is not
 */
export function getLocalStorageDateExpired(key: string) {
  return getLocalStorageDateExpiredTime(key, 0);
}

/**
 * Check if a Date time in local storage is greater than the specified expire time
 * @param {string} key The local storage key to look up
 * @param {number} differentInMsFromNow The expired time window to check. 0 would be if the timestamp is > current time (for future stored timestamps)
 * @returns
 */
export function getLocalStorageDateExpiredTime(key: string, differentInMsFromNow: number) {
  const date = getLocalStorageDate(key);
  if (date) {
    const now = new Date();
    const savedDate = new Date(date);
    if (now.getTime() - savedDate.getTime() > differentInMsFromNow) {
      return true;
    }
    return false;
  }
  return true;
}

/**
 * A time reference to use for our time set function
 */
export type TimeReference = 'now' | 'start_of_day' | 'end_of_day';

/**
 *
 * @param key
 * @param expireTime
 * @param timeReference
 */
export function setLocalStorageExpiring(key: string, expireTime: number, timeReference: TimeReference) {
  let referenceTime = new Date();
  if (timeReference === 'end_of_day') {
    referenceTime = new Date(Math.ceil(referenceTime.getTime() / _MS_PER_DAY) * _MS_PER_DAY);
  } else if (timeReference === 'start_of_day') {
    referenceTime = new Date(Math.floor(referenceTime.getTime() / _MS_PER_DAY) * _MS_PER_DAY);
  }

  referenceTime = new Date(referenceTime.getTime() + expireTime);

  localStorage.setItem(key, referenceTime.getTime().toString());
}

export function getLocalStorageDate(key: string) {
  try {
    const dateValue = localStorage.getItem(key);
    if (dateValue) {
      return new Date(parseInt(dateValue));
    }
    return null;
  } catch {
    console.log('Error parsing date from local storage');
    return null;
  }
}

export const OriginPositionOptions = ['Robot Center', 'Arm'] as const;
export type OriginPosition = (typeof OriginPositionOptions)[number];

export const DEFAULT_MAX_ZOOM = 22.4;
export const DEFAULT_NEAR_SAMPLE_ZOOM = DEFAULT_MAX_ZOOM;

export const MaxZoom = LocalStorageGenerator('maxZoom', DEFAULT_MAX_ZOOM);

const DEFAULT_ZOOM = 10;
const DEFAULT_EXTENT: Extent = [-9691553.78, 4974426.29, -9691553.78, 4974426.29];
const DEFAULT_CENTER = [-9691553.78, 4974426.29];

export const ScannerPauseDelayMs = LocalStorageGenerator('scannerDelay', 2000);

export const MobileHelperEnabled = LocalStorageGenerator('mobileHelperEnabled', false);

export const DBPeriodicSync = LocalStorageGenerator('dbPeriodicSync', true);
export const ForceSyncEnabled = LocalStorageGenerator('forceSync', true);

export const EnableExperimentalUPSManifestLogo = LocalStorageGenerator('enableExperimentalUPSManifestLogo', true);
export const BigFieldMode = LocalStorageGenerator('bigFieldMode', false);

export const MapZoomStorage = LocalStorageGenerator<{ value: number }>('zoom', { value: DEFAULT_ZOOM });
export const CoreCountEnforcementEnabled = LocalStorageGenerator('coreCheckEnabled', false);
export const CoreMatchingUsingRobotInAutoMode = LocalStorageGenerator('coreMatchingUsingRobotInAutoMode', true);
export const BlockCoringLogicEnabled = LocalStorageGenerator('blockCoringEnforcement', true);
export const BlockCoringMissingEntranceInterview = LocalStorageGenerator('blockCoringMissingEntranceInterview', true);
export const BlockCoringSoilMixRisk = LocalStorageGenerator('blockCoringSoilMixRisk', true);
export const BlockCoringMissingBarcode = LocalStorageGenerator('blockCoringMissingBarcode', true);
export const BlockCoringInSampleDeadband = LocalStorageGenerator('blockCoringInSampleDeadband', true);
export const ScheduleViewState = LocalStorageGenerator<ScheduleViewMode>('ScheduleViewMode', "Scheduled Jobs Only");
export const ScheduleViewDisabled = () => ScheduleViewState.get() === 'Disabled';

export const AlertsDisposeTimeout = LocalStorageGenerator('alertsDisposeTimeout', 5000);

export const DexieInterfaceV2 = LocalStorageGenerator('dexieV2', false);

export const MapExtentStorage = LocalStorageGenerator<Extent>('extent', DEFAULT_EXTENT);
export const MapSizeStorage = LocalStorageGenerator<Size | undefined>('mapSize', undefined);
export const MapResolutionStorage = LocalStorageGenerator<number>('mapResolution', 0);
export const MapSmoothZoomEnabled = LocalStorageGenerator('smoothZoom', false);

export const MapDebugStorage = LocalStorageGenerator('mapDebug', false);
export const MapDebugEventBus = LocalStorageGenerator('mapDebugEventBus', false);
export const MapDebugArmPosition = LocalStorageGenerator('mapDebugArmPosition', false);
export const MapDebugCloseTolerance = LocalStorageGenerator('mapDebugCloseTolerance', false);
export const MapDebugPresentTolerance = LocalStorageGenerator('mapDebugPresentTolerance', false);
export const MapDebugNoSampleTolerance = LocalStorageGenerator('mapDebugNoSampleTolerance', false);
export const MapDebugExtent = LocalStorageGenerator('mapDebugExtent', false);

// Core debug switches
// Core tolerance debug switches
export const MapDebugCloseCoreTolerance = LocalStorageGenerator('mapDebugCloseCoreTolerance', false);
export const MapDebugPresentCoreTolerance = LocalStorageGenerator('mapDebugPresentCoreTolerance', true);
export const MapDebugNoSampleCoreTolerance = LocalStorageGenerator('mapDebugNoSampleCoreTolerance', false);

// Core distance debug switch
export const MapDebugEdgeUnpulledCores = LocalStorageGenerator('mapDebugEdgeUnpulledCores', true);
export const MapDebugCoreLabels = LocalStorageGenerator('mapDebugCoreLabels', false);
export const MapDebugCoreWaypointNumbers = LocalStorageGenerator('mapDebugCoreWaypointNumbers', false);

export const MapDebugZoomLevel = LocalStorageGenerator('mapDebugZoomLevel', false);

export const MapCenterStorage = LocalStorageGenerator<Coordinate>('center', DEFAULT_CENTER);
export const MapRotationStorage = LocalStorageGenerator<{ value: number } | undefined>('rotation', undefined);
export const MapZoomLevelNearSampleStorage = LocalStorageGenerator('zoomNearSample', DEFAULT_NEAR_SAMPLE_ZOOM);
export const MapZoomLevelNotNearSampleStorage = LocalStorageGenerator('zoomNotNearSample', 16);
export const DistanceAheadMetersStorage = LocalStorageGenerator('distanceAheadMeters', 30);
export const UseClosestCoreStrategyStorage = LocalStorageGenerator('useClosestCoreStrategy', false);
export const UseClosestEdgeCoreStrategyStorage = LocalStorageGenerator('useClosestEdgeCoreStrategy', true);
export const DEFAULT_ORIGIN: OriginPosition = 'Arm';
export const MapCalculationPositionStorage = LocalStorageGenerator<OriginPosition>('navigationOrigin', DEFAULT_ORIGIN);

export const MapNearbyAutoZoomEnabled = LocalStorageGenerator('autoZoomNearby', false);
export const MapPointResolution = LocalStorageGenerator('pointResolution', 0.1);
export const MapPixelsPerMeterStorage = LocalStorageGenerator('pixelsPerMeter', 0);
export const MapShowPulledCoreLocation = LocalStorageGenerator('showPulledCoreLocation', false);
export const MapManualDriveAidStorage = LocalStorageGenerator('manualDriveAid', false);

// default the simulator to 'true' if we are on localhost
export const SimulatorEnabledStorage = LocalStorageGenerator('simulator', isLocalhost);
export const SimulatorAddJitter = LocalStorageGenerator('simulatorAddJitter', 0.00000001);
export const SimulatorAccelerationScaler = LocalStorageGenerator('simulatorAccelerationScaler', 5);
export const SimulatorFPS = LocalStorageGenerator('simulatorFPS', 30);
export const SimulatorMaxMilesPerHour = LocalStorageGenerator('simulatorMaxMilesPerHour', 15);
export const SimulatorAccelerationFactor = LocalStorageGenerator('simulatorAccelerationFactor', 3);
export const SimulatorDecelerationFactor = LocalStorageGenerator('simulatorDecelerationFactor', 6);
export const SimulatorKeyRepeatMode = LocalStorageGenerator<boolean>('keyRepeatMode', true);

export const FertilityArmOffset = { dx: 0.2302, dy: 0.9652 };
export const HDArmOffset = { dx: 1.1176, dy: 0.0 };

// TODO need values
export const MiniArmOffset = { dx: 0.0, dy: 0.0 };

export const RobotArmOffsetX = LocalStorageGenerator('robotArmOffsetX', 0);
export const RobotArmOffsetY = LocalStorageGenerator('robotArmOffsetY', 0);

// DO NOT UPDATE KEY, this is used when starting the app but is referenced only
// by its key in db.ts for simplifying imports
export const FastMode = LocalStorageGenerator('fastMode', false);

export interface MapState {
  center: Coordinate;
  zoom: number;
  extent: Extent;
  size: Size | undefined;
  rotation: number | undefined;
}

// const DEFAULT_MAP_STATE: MapState = {
//   center: DEFAULT_CENTER,
//   zoom: DEFAULT_ZOOM,
//   extent: DEFAULT_EXTENT,
//   size: undefined,
//   rotation: undefined,
// };

// export const MapStateStorage = LocalStorageGenerator<MapState>('mapState', DEFAULT_MAP_STATE);
