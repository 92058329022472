export default function getVersion() {
  return `${__APP_VERSION__} (${__BUILD_ID__})`;
}

export function getVersionTimestamp() {
  return __BUILD_ID__;
}

export function getVersionNumber() {
  return __APP_VERSION__;
}
