import React, { PureComponent } from 'react';

import { Grid, AccordionDetails, AccordionSummary, Typography, GridSize } from '@material-ui/core';

import { MyAccordion } from '../../utils';

import MissionInfoItem from './MissionInfoItem';
import { getCurrentMission } from '../../../dataModelHelpers';
import { updateMissionInfo } from '../helpers/missionInfo';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { updateState } from '../../../utils';

interface MissionInfoProps {
  expanded: boolean;
}

export interface MissionInfoState {
  missionName: string;
  dealSitesType: string;
  jobId: string;
  sampleDate: string;
  client: string;
  grower: string;
  farm: string;
  field: string;
  labName: string;
  labCode: string;
  testPackage: string;
  addOnFreq?: number;
  responseEmail: string;
  billingAccount: string;
  samplingCompanyName: string;
  samplingCompanyId: string;
  labAddress: string;
  labPrimaryDelivery: string;
  eventId?: string;
  fieldId?: string;
  attachmentId?: string;
}

type MissionInfoItem = {
  label: string;
  type: string;
  value?: string | number;
  width: { xs: GridSize; sm: GridSize };
  disabled?: boolean;
};

export default class MissionInfo extends PureComponent<MissionInfoProps, MissionInfoState> {
  constructor(props: MissionInfoProps) {
    super(props);

    this.state = {
      missionName: '',
      dealSitesType: '',
      jobId: '',
      sampleDate: '',
      client: '',
      grower: '',
      farm: '',
      field: '',
      labName: '',
      labCode: '',
      testPackage: '',
      addOnFreq: undefined,
      responseEmail: '',
      billingAccount: '',
      samplingCompanyName: '',
      samplingCompanyId: '',
      labAddress: '',
      labPrimaryDelivery: '',
      eventId: undefined,
      fieldId: undefined,
      attachmentId: undefined,
    };

    this.updateItem = this.updateItem.bind(this);
    this.initMissionInfo = this.initMissionInfo.bind(this);
  }

  componentDidMount() {
    this.initMissionInfo();
  }

  /**
   * Init mission info in state
   */
  initMissionInfo() {
    const mission = getCurrentMission();
    const job = mission?.getJob();
    if (!mission || !job) {
      return;
    }

    const specs = mission.getSamplingSpecs();
    const labNames = new Set(specs.map((spec) => spec.lab_name.replace(/"/g, '')));
    const labCodes = new Set(specs.map((spec) => spec.lab_code));
    const labAddresses = new Set(specs.map((spec) => spec.lab_address));
    const testPackages = new Set(specs.map((spec) => spec.test_package));
    this.setState({
      missionName: mission.name,
      dealSitesType: job.sites_type,
      jobId: job.job_id!,
      client: job.client,
      grower: job.grower,
      farm: job.farm,
      field: job.field,
      labName: [...labNames].join(', '),
      labCode: [...labCodes].join(', '),
      testPackage: [...testPackages].join(', '),
      addOnFreq: job.add_on_freq,
      responseEmail: job.response_email,
      billingAccount: job.billing_account,
      samplingCompanyName: job.sampling_company_name,
      samplingCompanyId: job.sampling_company_id,
      labAddress: [...labAddresses].join(', '),
      labPrimaryDelivery: job.lab_primary_delivery,
      eventId: job.event_id,
      fieldId: job.field_id,
      attachmentId: job.attachment_id,
    });
  }

  /**
   * Updates the a mission info item
   * @param {string} key The state key
   * @param {string} value The new value
   */
  updateItem(key: keyof MissionInfoState, value: string) {
    updateMissionInfo(key, value); // updates the data model

    this.setState(updateState(key as keyof MissionInfoState, value));
  }

  render() {
    const missionInfoConfigurations: MissionInfoItem[] = [
      { label: 'Mission Name', type: 'text', value: this.state.missionName, width: { xs: 7, sm: 7 }, disabled: true },
      { label: 'Type', type: 'text', value: this.state.dealSitesType, width: { xs: 2, sm: 2 }, disabled: true },
      { label: 'Sample Date', type: 'date', value: this.state.sampleDate, width: { xs: 3, sm: 3 } },
      { label: 'Client', type: 'text', value: this.state.client, width: { xs: 6, sm: 6 }, disabled: true },
      { label: 'Grower', type: 'text', value: this.state.grower, width: { xs: 6, sm: 6 }, disabled: true },
      { label: 'Farm', type: 'text', value: this.state.farm, width: { xs: 6, sm: 6 }, disabled: true },
      { label: 'Field', type: 'text', value: this.state.field, width: { xs: 6, sm: 6 }, disabled: true },
      { label: 'Lab Name', type: 'text', value: this.state.labName, width: { xs: 12, sm: 6 }, disabled: true },
      { label: 'Test Package', type: 'text', value: this.state.testPackage, width: { xs: 6, sm: 3 }, disabled: true },
      { label: 'Add-On Freq.', type: 'text', value: this.state.addOnFreq, width: { xs: 6, sm: 3 } },
      { label: 'Response Email', type: 'text', value: this.state.responseEmail, width: { xs: 8, sm: 4 } },
      {
        label: 'Billing Account',
        type: 'text',
        value: this.state.billingAccount,
        width: { xs: 4, sm: 2 },
        disabled: true,
      },
      {
        label: 'Sampling Company Name',
        type: 'text',
        value: this.state.samplingCompanyName,
        width: { xs: 6, sm: 3 },
        disabled: true,
      },
      {
        label: 'Sampling Company ID',
        type: 'text',
        value: this.state.samplingCompanyId,
        width: { xs: 6, sm: 3 },
        disabled: true,
      },
      { label: 'Lab Address', type: 'textarea', value: this.state.labAddress, width: { xs: 9, sm: 9 }, disabled: true },
      {
        label: 'Lab Primary Delivery',
        type: 'text',
        value: this.state.labPrimaryDelivery,
        width: { xs: 3, sm: 3 },
        disabled: true,
      },
      {
        label: 'Event ID',
        type: 'text',
        value: this.state.eventId?.toString(),
        width: { xs: 6, sm: 3 },
        disabled: true,
      },
      {
        label: 'Field ID',
        type: 'text',
        value: this.state.fieldId?.toString(),
        width: { xs: 6, sm: 3 },
        disabled: true,
      },
      { label: 'Job ID', type: 'text', value: this.state.jobId, width: { xs: 6, sm: 3 }, disabled: true },
      {
        label: 'Attachment ID',
        type: 'text',
        value: this.state.attachmentId?.toString(),
        width: { xs: 6, sm: 3 },
        disabled: true,
      },
    ];
    return (
      <MyAccordion defaultExpanded={this.props.expanded} elevation={3}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Mission Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12} spacing={1} alignItems="center">
            {missionInfoConfigurations.map((item, index) => (
              <MissionInfoItem
                key={index}
                label={item.label}
                type={item.type}
                value={item.value}
                multiline={false}
                disabled={item.disabled}
                width={item.width}
                onChange={(e) =>
                  this.updateItem(
                    item.label.replace(/\s+/g, '').toLowerCase() as keyof MissionInfoState,
                    e.target.value,
                  )
                }
              />
            ))}
          </Grid>
        </AccordionDetails>
      </MyAccordion>
    );
  }
}
