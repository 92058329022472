import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Print from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton';

import { SampleBox } from '../db';
import { alertFullScreen } from '../alertDispatcher';
import { isMobile } from 'react-device-detect';
import { FaCheck, FaQuestion, FaUps } from 'react-icons/fa';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingButton } from './utils';
import { SettingsAccessLevel } from '../types/types';
import { MdDelete } from 'react-icons/md';
import { useAppDispatch } from '../redux/hooks';
import { updateBoxTrackingNumber } from '../redux/features/boxes/boxesSlice';

interface BoxHistoryItemProps {
  box: SampleBox;
  showBoxCheckin: (box: SampleBox) => Promise<void>;
  showShippingLabelPDf(box: SampleBox): Promise<void>;
  showBoxCloseScreen: (box: SampleBox) => Promise<void>;
  accessLevel: SettingsAccessLevel;
}

const BoxHistoryItem: React.FC<BoxHistoryItemProps> = ({
  box,
  showBoxCheckin,
  showShippingLabelPDf,
  showBoxCloseScreen,
  accessLevel,
}) => {
  const appDispatch = useAppDispatch();
  const sortedMissions = box.getSampleBoxMissions().sort((a, b) => (a.last_modified > b.last_modified ? 1 : -1));

  let firstMissionName = 'No Missions';
  if (sortedMissions.length > 0 && sortedMissions[0]) {
    firstMissionName = sortedMissions[0].name || 'No Missions';
  }

  const boxMissions = box.getSampleBoxMissions().sort((a, b) => (a.last_modified > b.last_modified ? 1 : -1));

  const handleListItemClick = async () => {
    await alertFullScreen(
      `Details for ${box.uid.toUpperCase()}`,
      [
        `Created Time: <b>${box.createdTimestamp.toLocaleString()}</b>`,
        `Closed Time: <b>${box.closedTimestamp ? new Date(box.closedTimestamp).toLocaleString() : 'Not closed'}</b>`,
        `Sample Count: <b>${box.getSamples().length}</b>`,
        `Friendly ID: <b>${box.friendlyId}</b>`,
        `Lab: <b>${box.labName.replace(/"/g, '')}</b>`,
        'Missions:',
        ...box.getSampleBoxMissions().map((m) => `- ${m.name}`),
      ].join('\n'),
      ['Ok'],
    );
  };

  return (
    <ListItem onClick={handleListItemClick} key={box.uid} divider button>
      <ListItemText
        primary={
          <Typography variant="h6">
            <b>{box.short_uid}</b>
          </Typography>
        }
        secondary={<Typography>{box.friendlyId}</Typography>}
        style={{ width: 0, margin: 0 }}
      />

      {!isMobile && (
        <>
          <ListItemText
            primary={<Typography variant="body1">{box.labName}</Typography>}
            style={{ width: 0, margin: 0 }}
          />

          <ListItemText
            primary={
              <Typography>
                <b>{firstMissionName}</b>
              </Typography>
            }
            primaryTypographyProps={{ style: { textAlign: 'left' } }}
            secondary={
              <Typography>
                {boxMissions.length > 1
                  ? `${boxMissions.length - 1} more missions, click to see all (${box.getSamples().length} Samples)`
                  : `(${box.getSamples().length} Samples total)`}
              </Typography>
            }
            secondaryTypographyProps={{ style: { textAlign: 'left' } }}
            style={{
              marginLeft: 0,
              overflow: 'hidden',
              alignContent: 'start',
              width: 400,
            }}
          />
        </>
      )}

      <ListItemText style={{ width: 20 }}>
        {!box.closed && (
          <LoadingButton
            variant="outlined"
            onClick={async () => {
              await showBoxCloseScreen(box);
            }}
          >
            <CloseIcon style={{ paddingRight: 7.5 }} /> Close
          </LoadingButton>
        )}
      </ListItemText>

      {accessLevel === 'Technician' && !isMobile && (
        <ListItemIcon
          onClick={async (e) => {
            e.stopPropagation();
            box.trackingNumber = '';
            box.trackingNumberTime = undefined;
            appDispatch(updateBoxTrackingNumber(box));
          }}
        >
          <IconButton disabled={!box.trackingNumber} color="secondary">
            <MdDelete />
            <FaUps fontSize="32" />
          </IconButton>
        </ListItemIcon>
      )}

      <ListItemIcon
        onClick={async (e) => {
          e.stopPropagation();
          await showShippingLabelPDf(box);
        }}
      >
        <IconButton color="secondary">
          <FaUps fontSize="32" />
          {box.trackingNumber ? <FaCheck fontSize="32" /> : <FaQuestion fontSize="32" />}
        </IconButton>
      </ListItemIcon>

      <ListItemIcon
        onClick={async (e) => {
          e.stopPropagation();
          await showBoxCheckin(box);
        }}
      >
        <IconButton color="secondary">
          <Print fontSize="large" />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
};

export default BoxHistoryItem;
