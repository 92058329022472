import { Coordinate } from 'ol/coordinate';
import React, { PureComponent } from 'react';
import { getCurrentMission } from '../../dataModelHelpers';
import {
  calculateDistance4329,
  calculateNavigationAngle,
  clamp,
  convertProjection4329,
  m_to_ft,
  roundToIncrement,
} from '../../utils';
import { calculateArmPosition } from '../../services/RobotArmService';
import { Sample, Waypoint } from '../../db';
import {
  DEFAULT_CORE_PRESENT_DISTANCE_FEET,
  SAMPLE_WARNING_DISTANCE_FT,
  SAMPLE_WARNING_DISTANCE_M,
} from '../../constants';
import { MapCalculationPositionStorage } from '../../db/local_storage';
import EventBus from '../../EventBus';
import { RosPosition } from '../../types/rosmsg';

interface SampleDistanceIndicatorProps {
  range: [number, number];
  height: number;
  style?: React.CSSProperties;
  markerValue: number;
  presentSample: Sample | undefined;
  currentWaypoint: Waypoint | undefined;
  targetCoordinate?: Coordinate | undefined;
}

interface SampleDistanceIndicatorState {
  barSize: number;
  robotPosition?: Coordinate;
  robotHeading: number;
}

export default class SampleDistanceIndicator extends PureComponent<
  SampleDistanceIndicatorProps,
  SampleDistanceIndicatorState
> {
  DEFAULT_BAR_SIZE = 200;

  constructor(props: SampleDistanceIndicatorProps) {
    super(props);

    this.state = {
      barSize: this.DEFAULT_BAR_SIZE,
      robotPosition: undefined,
      robotHeading: 0,
    };

    this.updatePosition = this.updatePosition.bind(this);
  }

  componentDidMount() {
    EventBus.on('ROSMSG/position', this.updatePosition);
  }

  componentWillUnmount() {
    EventBus.remove('ROSMSG/position', this.updatePosition);
  }

  async updatePosition({ hostname, msg }: { hostname: string; msg: RosPosition }) {
    this.setState({
      robotPosition: convertProjection4329([msg.y, msg.x]),
      robotHeading: msg.z,
    });
  }

  render() {
    const mission = getCurrentMission();

    if (!mission) {
      return null;
    }

    const robotPosition = this.state.robotPosition;
    const robotHeading = this.state.robotHeading;

    if (!robotPosition) {
      return null;
    }

    const missionTolerance = mission.getJob()?.sampling_tolerance_ft;
    const presentToleranceFeet = missionTolerance || DEFAULT_CORE_PRESENT_DISTANCE_FEET;

    const armPosition = calculateArmPosition(robotPosition, -robotHeading);

    let targetCoordinate = this.props.targetCoordinate;
    if (!targetCoordinate) {
      return null;
    }

    targetCoordinate = convertProjection4329([targetCoordinate[1], targetCoordinate[0]]);

    const originPosition = MapCalculationPositionStorage.get() === 'Arm' ? armPosition : robotPosition;
    const robotNavigationAngle = calculateNavigationAngle(robotHeading, originPosition, targetCoordinate);
    const robotToCoreDistance = calculateDistance4329(targetCoordinate, originPosition);

    const robotToCoreDistanceFt = m_to_ft(robotToCoreDistance);

    const behind = Math.abs(robotNavigationAngle) > Math.PI / 2;

    // TODO define constant for max bar distance
    const maxPixelHeight = this.props.height - 500;
    const pixelsPerFeet = maxPixelHeight / 50;
    const sampleBarHeight = clamp(0, Math.abs(robotToCoreDistanceFt) * pixelsPerFeet, maxPixelHeight);

    return (
      <div
        style={{
          marginTop: 290,
          position: 'absolute',
          left: 30,
          maxWidth: this.state.barSize,
          zIndex: 1,
          borderColor: '#EEEEEE',
          borderWidth: 0,
          borderStyle: 'solid',
          // fill the parent height?
          height: sampleBarHeight,
          width: 140,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          // Color this from red..yellow..green..yellow..red
          backgroundColor:
            robotToCoreDistanceFt >= SAMPLE_WARNING_DISTANCE_FT
              ? 'white'
              : robotToCoreDistanceFt >= presentToleranceFeet
                ? 'orange'
                : 'green',
          borderRadius: 10,
          ...this.props.style,
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            fontSize: '32px',
            lineHeight: 0.9,
            color: robotToCoreDistance <= SAMPLE_WARNING_DISTANCE_M ? 'white' : 'black',
            textShadow: '#000 2px 2px 7px',
          }}
        >
          {`${behind ? '' : 'Forward\n'}${roundToIncrement(robotToCoreDistanceFt, 0.1)} ft${behind ? '\nBackward' : ''}`}
        </div>
      </div>
    );
  }
}
