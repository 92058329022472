import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Role, { RoleList } from './RoleClass';
import Task, { TaskList } from './TaskClass';
import { IPermission } from './types';

export default class Permission extends ObjectClassGenerator<Permission>('Permission') implements IPermission {
  // attributes

  // relationships
  #Role_id?: number;
  #Task_id?: number;

  static tableName = 'Permission';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Permission, 'Role_id');
    this.publishAttribute(Permission, 'Task_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Permission> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#Role_id = state.Role_id;
    this.#Task_id = state.Task_id;
  }

  dispose() {
    const role = this.getRole();
    if (role) {
      this.Role_id = undefined;
    }
    const task = this.getTask();
    if (task) {
      this.Task_id = undefined;
    }

    Permission.delete(this.instance_id);
  }

  get role_name() {
    return this.getRole()?.name;
  }

  get name() {
    return this.getTask()?.name;
  }

  set Role_id(value) {
    if (this.#Role_id) {
      const relateObj = Role.get(this.#Role_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Permission', this.instance_id);
      }
    }
    this.#Role_id = value;
    if (value) {
      const relateObj = Role.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Permission', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Role_id() {
    return this.#Role_id;
  }

  getRole() {
    return Role.get(this.Role_id);
  }

  set Task_id(value) {
    if (this.#Task_id) {
      const relateObj = Task.get(this.#Task_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Permission', this.instance_id);
      }
    }
    this.#Task_id = value;
    if (value) {
      const relateObj = Task.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Permission', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Task_id() {
    return this.#Task_id;
  }

  getTask() {
    return Task.get(this.Task_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Permission.query(
      (sel) => sel.instance_id !== this.instance_id && sel.role_name === this.role_name && sel.name === this.name,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate permission found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`permission: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    return problems;
  }
}

export class PermissionList extends DataObjectList<Permission> {
  getRoles() {
    return new RoleList(...this.map((permission) => permission.getRole()).filter((sel) => !!sel));
  }

  getTasks() {
    return new TaskList(...this.map((permission) => permission.getTask()).filter((sel) => !!sel));
  }
}
