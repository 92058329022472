import React, { PureComponent } from 'react';

import { Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { UploadKmlButton, StandardMissionButton } from '../buttons';
import { PaperArea } from '../../utils';
import MapsScheduleView from '../../schedule/MapsScheduleView';
import { OnLoading, SettingsAccessLevel } from '../../../types/types';
import { AirtableRecord } from '../../../db';
import { UseTestLoaderStore } from '../../../utils';
import Airtable from '../../../airtable';
import { Jobs } from '@rogoag/airtable';

interface LoadMapsMissionProps {
  onLoading: OnLoading;
  loadSchedule: (forceRefresh: boolean) => Promise<void>;
  loadingSchedule: boolean;
  loadMissionMaps: (id: string) => Promise<void>;
  scheduleItems: AirtableRecord[];
  accessLevel: SettingsAccessLevel;
  lastMissionId?: string;
}

interface LoadMapsMissionState {
  useTestLoader: boolean;
  lastMissionName: string;
}

export default class LoadMapsMission extends PureComponent<LoadMapsMissionProps, LoadMapsMissionState> {
  constructor(props: LoadMapsMissionProps) {
    super(props);

    this.state = {
      useTestLoader: UseTestLoaderStore.get(),
      lastMissionName: '',
    };
  }

  async componentDidMount() {
    if (this.props.lastMissionId) {
      const record = await AirtableRecord.getRecord<Jobs>('Jobs', this.props.lastMissionId);
      if (record) {
        this.setState({ lastMissionName: record.get('Name') });
      }
    }
  }

  async componentDidUpdate(prevProps: LoadMapsMissionProps) {
    if (this.props.lastMissionId !== prevProps.lastMissionId) {
      if (this.props.lastMissionId) {
        const record = await AirtableRecord.getRecord<Jobs>('Jobs', this.props.lastMissionId);
        if (record) {
          this.setState({ lastMissionName: record.get('Name') });
        }
      } else {
        this.setState({ lastMissionName: '' });
      }
    }
  }

  render() {
    const lastMissionId = this.props.lastMissionId;
    return (
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <PaperArea fullwidth={!(this.props.accessLevel === 'Technician') && !this.props.lastMissionId}>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Upload Mission
          </Typography>
          <UploadKmlButton sampling={false} onLoading={this.props.onLoading} />
        </PaperArea>

        {!!lastMissionId && (
          <PaperArea>
            <Typography variant="h6" style={{ marginBottom: 10, textOverflow: 'ellipsis' }}>
              {`Last Mission: ${this.state.lastMissionName}`}
            </Typography>
            <Button variant="outlined" onClick={() => this.props.loadMissionMaps(lastMissionId)}>
              Load
            </Button>
          </PaperArea>
        )}

        {this.props.accessLevel === 'Technician' && (
          <PaperArea>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Test Mission
            </Typography>
            <StandardMissionButton
              text="Load"
              filePath="./static/standard_mission.kmz"
              onLoading={this.props.onLoading}
            />
          </PaperArea>
        )}

        <MapsScheduleView
          loadSchedule={this.props.loadSchedule}
          loadingSchedule={this.props.loadingSchedule}
          loadMissionMaps={this.props.loadMissionMaps}
          scheduleItems={this.props.scheduleItems}
        />
      </Grid>
    );
  }
}
