import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

export default withStyles((theme) => {
  const backgroundColorDefault = theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900];
  return {
    root: {
      backgroundColor: backgroundColorDefault,
    },
  };
})(Paper);
