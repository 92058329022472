import { PredefinedPageSize } from 'pdfmake/interfaces';
import { RobotConnectionStore } from '../../RobotConnectionStore';
import { LABEL_SERVER_HOSTNAME, LABEL_SERVER_HTTPS_URL } from '../../../labelServerUtils';
import { LocalStorageGenerator } from '../../../utils';

export declare type PRINTER_CONFIG = {
  label: string;
  pageSize: PredefinedPageSize;
  defaultStyle: {
    fontSize: number;
  };
  manifest: {
    horizontal_rule: number;
    vertical_rule: number;
    font_size: number;
    qr_width: number;
    col_gap: number;
    margin: number;
  };
  lab: {
    font_size: number;
    qr_margin: number[];
    qr_width: number;
    margin: number[] | number;
    logo_size: number;
  };
};
// printer configs for different sized printer
export const PRINTER_SIZE_4_6: PRINTER_CONFIG = {
  label: '4 X 6 INCHES',
  pageSize: 'A6',
  defaultStyle: { fontSize: 8 },
  manifest: {
    horizontal_rule: 240,
    vertical_rule: 275,
    font_size: 10,
    qr_width: 140,
    col_gap: 10,
    margin: 15,
  },
  lab: {
    font_size: 8,
    qr_margin: [-22, 0, 32, 0],
    qr_width: 80,
    margin: [5, 7, 16, 5],
    logo_size: 100,
  },
};

export const PRINTER_SIZE_8_5_11: PRINTER_CONFIG = {
  label: '8.5 X 11 INCHES',
  pageSize: 'A4',
  defaultStyle: { fontSize: 10 },
  manifest: {
    horizontal_rule: 375,
    vertical_rule: 400,
    font_size: 14,
    qr_width: 180,
    col_gap: 15,
    margin: 90,
  },
  lab: {
    font_size: 10,
    qr_margin: [-72, 0, 72, 0],
    qr_width: 100,
    margin: 30,
    logo_size: 200,
  },
};

export const PrinterProtocols = ['http', 'https', 'ipp', 'ipps'] as const;
export type PrinterProtcol = (typeof PrinterProtocols)[number];

export const PrintServerProtocol = LocalStorageGenerator<PrinterProtcol>('printer_mode', 'https');
export const RJ4230BName = LocalStorageGenerator('rj4230b_name', 'RJ4230B');
export const PJ722Name = LocalStorageGenerator('pj722_name', 'pj722');

const printServerHostname = PrintServerProtocol.get().startsWith('http')
  ? LABEL_SERVER_HOSTNAME()
  : RobotConnectionStore.get().hostname;
export function getPrintURLFromConfig(printerConfig: PRINTER_CONFIG) {
  switch (printerConfig) {
    case PRINTER_SIZE_4_6:
      // return `ipps://${RobotConnectionStore.get().hostname}/printers/${RJ4230BName.get()}`;
      return `${PrintServerProtocol.get()}://${printServerHostname}/printers/${RJ4230BName.get()}`;
    case PRINTER_SIZE_8_5_11:
      return `${PrintServerProtocol.get()}://${printServerHostname}/printers/${PJ722Name.get()}`;
    default:
      return '';
  }
}

export const PRINTER_OPTIONS = [PRINTER_SIZE_8_5_11, PRINTER_SIZE_4_6];
