import axios from 'axios';
import md5 from 'md5';
import { z } from 'zod';
import { Fetcher } from 'openapi-typescript-fetch';
import { paths } from './recovery_server';
import { BasicJsonRecord } from './types/types';
import logger from './logger';
import { LocalStorageGenerator } from './utils';

// declare fetcher for paths
const fetcher = Fetcher.for<paths>();

// TODO make environment variable?
const FileRecoveryStore = LocalStorageGenerator('file_recovery_server_credentials', {
  hostname: 'local.rogoag.com',
  port: 8081,
});
const FILE_RECOVERY_HTTPS_PORT = 8081;
// TODO I should override this to use a local server for development
const FILE_RECOVERY_HTTPS_URL = () => `https://${FileRecoveryStore.get().hostname}:${FILE_RECOVERY_HTTPS_PORT}`;

// global configuration
fetcher.configure({
  baseUrl: FILE_RECOVERY_HTTPS_URL(),
  init: {
    headers: {
      'Cache-Control': 'no-cache',
      Expires: '0',
    },
  },
});

const RecoveryFileMetadata = z.object({
  filename: z.string(),
  // TODO once this version of the app is published for some time, these can be made mandatory...
  md5: z.string().optional(),
  ip: z.string().optional(),
  upload_to_aws: z.string().optional(),
  hostname: z.string().optional(),
  time: z.string().optional(),
  filled_samples: z.string().optional(),
  total_samples: z.string().optional(),
  job_name: z.string().optional(),
  job_id: z.string().optional(),
  box_count: z.string().optional(),
  open_box: z.string().optional(),
});

export type RecoveryMetadataParams = {
  [key: string]: string | number | boolean;
};

// extract the inferred type
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RecoveryFileMetadata = z.infer<typeof RecoveryFileMetadata>;

const OldRecoveryFileList = z.object({
  recovery_list: z.array(z.string()),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OldRecoveryFileList = z.infer<typeof OldRecoveryFileList>;

const RecoveryFileListResponse = z.object({
  recovery_list: z.array(RecoveryFileMetadata),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RecoveryFileListResponse = z.infer<typeof RecoveryFileListResponse>;

const AIRTABLE_OFFLINE_ID_REGEX = /_(rec[A-Za-z0-9]{14})_/;

export const extractRogoJobIDs = (recoveryList: RecoveryFileListResponse) => {
  const jobIDs: Array<string> = [];
  for (const recoveryFile of recoveryList.recovery_list) {
    if (recoveryFile.job_id) {
      jobIDs.push(recoveryFile.job_id);
    } else if (recoveryFile.filename.match(AIRTABLE_OFFLINE_ID_REGEX)?.[1]) {
      const jobId = recoveryFile.filename.match(AIRTABLE_OFFLINE_ID_REGEX)?.[1];
      if (jobId) {
        jobIDs.push(jobId);
      }
    }
  }
  return [...new Set(jobIDs)];
};

const useRecoveryServer = (): boolean => {
  if (import.meta.env.PROD) {
    return true;
  }

  return import.meta.env.VITE_USE_RECOVERY_SERVER.toLowerCase() === 'true';
};

const GET_TIMEOUT = 2000;
export const isRecoveryServerAlive = async (timeout = GET_TIMEOUT) => {
  if (!useRecoveryServer()) {
    return false;
  }

  try {
    const response = await axios.get(`${FILE_RECOVERY_HTTPS_URL()}/ping`, {
      headers: {
        'Cache-Control': 'no-cache',
        Expires: '0',
      },
      timeout,
    });

    return response.status >= 200 && response.status < 300;
  } catch (err) {
    await logger.log('IS_RECOVERY_SERVER_ALIVE', { timeout, err });

    return false;
  }
};

const GET_LIST_TIMEOUT = 5000;
//const _getRecoveryList = fetcher.path('/recovery_list').method('get').create();
export const getRecoveryList = async ({ count = 4, timeout = GET_LIST_TIMEOUT, search = '' } = {}) => {
  if (!useRecoveryServer()) {
    return undefined;
  }

  try {
    const response = await axios.get<RecoveryFileListResponse | OldRecoveryFileList>(
      `${FILE_RECOVERY_HTTPS_URL()}/recovery/list?metadata=true&count=${count}&search=${search}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          Expires: '0',
        },
        timeout,
      },
    );

    // TODO removing this as of 8/6/2024 as no recovery servers
    // should send this old format by default so we can get better typing
    // out of this function by forcing it to the new type
    // At some point in the future we can remove this check

    // if (typeof response.data === 'object' &&
    //     "recovery_list" in response.data &&
    //     typeof response.data.recovery_list[0] === 'string') {
    //     return OldRecoveryFileList.parse(response.data);
    // }
    return RecoveryFileListResponse.parse(response.data);
  } catch (err) {
    await logger.log('GET_RECOVERY_LIST', { count, timeout, search });
    return undefined;
  }
  // const response = await _getRecoveryList({ count, metadata: true, search });
  // console.log(response);
  // if (typeof response.data === 'object' &&
  //     "recovery_list" in response.data &&
  //     typeof response.data.recovery_list[0] === 'string') {
  //     return OldRecoveryFileList.parse(response.data);
  // }
  // return RecoveryFileListResponse.parse(response.data);
};

//const _getRecoveryFile = fetcher.path('/recovery').method('get').create();
export const getRecoveryFile = async ({ backIndex = 0, timeout = GET_TIMEOUT, search = '' }) => {
  if (!useRecoveryServer()) {
    return undefined;
  }

  const response = await axios.get<ArrayBuffer>(`${FILE_RECOVERY_HTTPS_URL()}/recovery`, {
    headers: {
      'Cache-Control': 'no-cache',
      Expires: '0',
    },
    params: {
      back_index: backIndex.toString(),
      search: search,
    },
    responseType: 'arraybuffer',
    timeout,
  });

  return new File([response.data], 'recovery.zip');
};

const POST_TIMEOUT = 10000;
export const postRecoveryFile = async (
  content: Blob | string,
  filename: string,
  { uploadToAWS = false, additionalParams = {} as BasicJsonRecord },
) => {
  const form = new FormData();
  const contentIsString = (content: Blob | string): content is string => {
    return typeof content === 'string';
  };
  const payload = contentIsString(content) ? new Blob([content], { type: 'plain/text' }) : content;
  form.append('file', payload, filename);
  let url = `${FILE_RECOVERY_HTTPS_URL()}/recovery?upload_to_aws=${uploadToAWS}&hostname=${window.location.host}`;
  for (const [key, value] of Object.entries(additionalParams)) {
    url += `&${key}=${value}`;
  }
  const response = await axios.post<string>(url, form, { timeout: POST_TIMEOUT });
  console.log(`Send recovery to local server result: ${response.data}`);

  const view = new Uint8Array(await payload.arrayBuffer());
  const hash = md5(view);

  console.log(`${hash}, ${response.data}, ${response.data === hash}`);
  if (response.data !== hash) {
    throw Error('Received hash did not match file!');
  }
  return true;
};
