import React, { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';
import { convertProjection4329 } from '../../../utils';
import { Coordinate } from 'ol/coordinate';

interface RecordZoneLayerProps {
  recordingCoords: Coordinate[];
}

export default class RecordZoneLayer extends PureComponent<RecordZoneLayerProps> {
  render() {
    const coordinates = this.props.recordingCoords.map((coord) => convertProjection4329([coord[1], coord[0]]));
    return (
      // @ts-ignore
      <layer.Vector zIndex={1}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          <feature.LineStringReact
            coordinates={coordinates}
            strokeOptions={{ color: '#33274b', width: 2 }}
            zIndex={8}
          />
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
