import GeometryType from 'ol/geom/GeometryType';
import { convertProjection4329 } from '../../../utils';
import { Feature, FeatureCollection, Geometry } from '@turf/helpers';
import { AirtableRecord } from '../../../db';
import * as turf from '@turf/turf';

/**
 * Generates a dropoff geojson object from a dropoff airtable record
 * @param {object} dropoff Airtable record instance of a dropoff
 */
export function genDropoffGeoJSON(dropoff: AirtableRecord) {
  const features: turf.Feature<turf.Point>[] = [];
  if (dropoff.get('Dropoff Lon') && dropoff.get('Dropoff Lat') && dropoff.get('Dropoff Location')) {
    const dropoffFeature: turf.Feature<turf.Point> = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: convertProjection4329([dropoff.get('Dropoff Lon')[0], dropoff.get('Dropoff Lat')[0]]),
      },
      properties: {
        name: 'dropoff',
        'Dropoff Location': dropoff.get('Dropoff Location')[0],
        'Dropoff Hours': dropoff.get('Dropoff Hours') ? dropoff.get('Dropoff Hours')[0] : '',
      },
    };
    features.push(dropoffFeature);
  }
  return features;
}

/**
 * Generates a pullin geojson object from a job airtable record
 * @param {object} job Airtable record instance of a job
 */
export function genPullinGeoJSON(job: AirtableRecord) {
  const pullinFeature: turf.Feature<turf.Point> = {
    type: 'Feature',
    geometry: {
      type: GeometryType.POINT,
      coordinates: convertProjection4329([job.get('Lon'), job.get('Lat')]),
    },
    properties: {
      name: 'pullin',
      'Field from Form': job.get('Field from Form'),
      'Rogo Job ID': job.get('Rogo Job ID'),
      Name: (job.get('Name') || '').toLowerCase(),
      MapMade: Boolean(job.fields['Int Mission']),
    },
  };

  return pullinFeature;
}

/**
 * Updates the properties of boundary geojson object with job information
 * @param {object} job Airtable record instance of a job
 * @param {*} boundaryGeoJSON GeoJSON for a boundary
 */
export function updateBoundaryProperties(job: AirtableRecord, boundaryGeoJSON: FeatureCollection<Geometry>) {
  for (let feature of boundaryGeoJSON.features) {
    if (!feature.properties) {
      continue;
    }
    feature.properties.name = 'field';
    feature.properties['Field from Form'] = job.get('Field from Form');
    feature.properties['Rogo Job ID'] = job.get('Rogo Job ID');
    feature.properties['Name'] = (job.get('Name') || '').toLowerCase();

    //feature.geometry.coordinates = feature.geometry.coordinates.map((coords) => coords.map((ring) => convertProjection4329(ring)))
    feature.properties['mapMade'] = Boolean(job.fields['Int Mission']);
    if (feature.geometry.type === 'Polygon') {
      feature.geometry.coordinates = feature.geometry.coordinates.map((coords) =>
        coords.map((ring) => convertProjection4329(ring)),
      );
    } else if (feature.geometry.type === 'MultiPolygon') {
      feature.geometry.coordinates = feature.geometry.coordinates.map((coords) =>
        coords.map((ring) => ring.map((subRing) => convertProjection4329(subRing))),
      );
    }
  }
}
