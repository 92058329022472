import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, TextField } from '@material-ui/core';
import { useState } from 'react';

interface BypassDialogProps {
  visible: boolean;
  title: string;
  dialogTitle: string;
  subTitle: string;
  bypassKeyword: string;
  onClose: (bypassed: boolean) => void;
  acceptButtonText: string;
}

export const BypassDialog = (props: BypassDialogProps) => {
  const [bypassText, setBypassText] = useState('');
  return (
    <Dialog open={props.visible} maxWidth={'md'} title={props.title}>
      <DialogTitle>
        <Typography variant="h4" align="center">
          {props.dialogTitle}
        </Typography>
        <Typography variant="h6" align="center">
          {props.subTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {/* // Text input box */}
        <TextField
          label={`Type "${props.bypassKeyword}" to bypass`}
          multiline
          minRows={3}
          fullWidth
          variant="outlined"
          value={bypassText}
          onChange={(e) => setBypassText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            setBypassText('');
            props.onClose(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="default"
          disabled={bypassText.toLowerCase() !== props.bypassKeyword.toLowerCase()}
          onClick={() => {
            setBypassText('');
            props.onClose(true);
          }}
        >
          {props.acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
