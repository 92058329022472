import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import User, { UserList } from './UserClass';
import Robot, { RobotList } from './RobotClass';
import Mission, { MissionList } from './MissionClass';
import SampleBox, { SampleBoxList } from './SampleBoxClass';
import Job, { JobList } from './JobClass';
import Sample from './SampleClass';
import Task, { TaskList } from './TaskClass';

import {
  newSession,
  loadKml,
  loadShapes,
  logout,
  robotConnected,
  addAndSelectBox,
  getDirections,
  loadMission,
} from '../db_ops/session_ops';
import { ShowAutoGenHelper, JobType, RogoShapefile } from '../types/types';
import { RobotConnectionStore } from '../components/RobotConnectionStore';
import { ISession } from './types';
import SamplingSpec from './SamplingSpecClass';
import { dispatchActiveBoxUpdated } from '../boxEvents';

export default class Session extends ObjectClassGenerator<Session>('Session') implements ISession {
  // attributes
  #token: string;
  #robot_name: string;

  // relationships
  #User_id?: number;
  #Robot_id?: number;
  #LastMission_id?: number;
  #Mission_id?: number;
  #Sample_id?: number;
  #Task_id?: number;

  static tableName = 'Session';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Session, 'token');
    // this.publishAttribute(Session, 'currentSampleId');
    this.publishAttribute(Session, 'robot_name');
    this.publishAttribute(Session, 'User_id');
    this.publishAttribute(Session, 'Robot_id');
    this.publishAttribute(Session, 'LastMission_id');
    this.publishAttribute(Session, 'Mission_id');
    this.publishAttribute(Session, 'Sample_id');
    this.publishAttribute(Session, 'Task_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Session> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#token = state.token || '';
    // this.#currentSampleId = state.currentSampleId || '';
    this.#robot_name = state.robot_name || '';
    this.#User_id = state.User_id;
    this.#Robot_id = state.Robot_id;
    this.#LastMission_id = state.LastMission_id;
    this.#Mission_id = state.Mission_id;
    this.#Sample_id = state.Sample_id;
    this.#Task_id = state.Task_id;
  }

  dispose() {
    const user = this.getUser();
    if (user) {
      this.User_id = undefined;
    }

    const robot = this.getRobot();
    if (robot) {
      this.Robot_id = undefined;
    }

    const mission = this.getMission();
    if (mission) {
      this.Mission_id = undefined;
    }

    const lastMission = this.getLastMission();
    if (lastMission) {
      this.LastMission_id = undefined;
    }

    for (const job of this.getJobs()) {
      job.Session_id = undefined;
      job.dispose();
    }

    for (const mission of this.getMissions()) {
      mission.Session_id = undefined;
      mission.dispose();
    }

    for (const samplebox of this.getSampleBoxes()) {
      samplebox.Session_id = undefined;
      samplebox.dispose();
    }

    const sample = this.getSample();
    if (sample) {
      this.Sample_id = undefined;
    }

    const task = this.getTask();
    if (task) {
      this.Task_id = undefined;
    }

    Session.delete(this.instance_id);
  }

  set token(value) {
    this.#token = value;
    this.syncToDB();
  }

  get token() {
    return this.#token;
  }

  // set currentSampleId(value) {
  //   this.#currentSampleId = value;
  //   this.update();
  // }

  // get currentSampleId() {
  //   return this.#currentSampleId;
  // }

  set robot_name(value) {
    this.#robot_name = value;
    this.syncToDB();
  }

  get robot_name() {
    return this.#robot_name;
  }

  get username() {
    return this.getUser()?.name;
  }

  // for backwards compatibility
  get robot_hostname() {
    return RobotConnectionStore.get().hostname;
  }

  get current_mission_name() {
    return this.getMission()?.name;
  }

  get current_mission_last_modified() {
    return this.getMission()?.last_modified;
  }

  get current_task_name() {
    return this.getTask()?.name;
  }

  set User_id(value) {
    if (this.#User_id) {
      const relateObj = User.get(this.#User_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Session', this.instance_id);
      }
    }
    this.#User_id = value;
    if (value) {
      const relateObj = User.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Session', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get User_id() {
    return this.#User_id;
  }

  getUser() {
    return User.get(this.User_id);
  }

  set Robot_id(value) {
    if (this.#Robot_id) {
      const relateObj = Robot.get(this.#Robot_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Session', this.instance_id);
      }
    }
    this.#Robot_id = value;
    if (value) {
      const relateObj = Robot.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Session', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Robot_id() {
    return this.#Robot_id;
  }

  getRobot() {
    return Robot.get(this.Robot_id);
  }

  set LastMission_id(value) {
    if (this.#LastMission_id) {
      const relateObj = Mission.get(this.#LastMission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Session', this.instance_id);
      }
    }
    this.#LastMission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Session', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get LastMission_id() {
    return this.#LastMission_id;
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Session', this.instance_id);
      }
      this.#LastMission_id = this.#Mission_id;
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Session', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  getMission() {
    return Mission.get(this.Mission_id);
  }

  getLastMission() {
    return Mission.get(this.LastMission_id);
  }

  // getForeignObject<T extends DataObject>(refId: string) {
  //   if (this._refs && this._refs[refId]) {
  //     return [...Array.from(this._refs.SampleBox).map((id) => DataObject.get(id)).filter(sel => !!sel))];
  //   }
  // }

  getSampleBoxes() {
    if (this._refs && this._refs.SampleBox) {
      return new SampleBoxList(
        ...Array.from(this._refs.SampleBox)
          .map((id) => SampleBox.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const sampleboxs = SampleBox.query((sel) => sel && sel.Session_id === this.instance_id);
      for (const samplebox of sampleboxs) {
        samplebox.Session_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SampleBoxList(...sampleboxs);
    }
  }

  getReprintSampleBoxes() {
    //return this.getForeignObject<SampleBox>('SampleBox', 'SampleBoxReprint', 'ReprintSession_id');
    if (this._refs && this._refs.SampleBoxReprint) {
      return new SampleBoxList(
        ...Array.from(this._refs.SampleBoxReprint)
          .map((id) => SampleBox.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const sampleboxs = SampleBox.query((sel) => sel && sel.ReprintSession_id === this.instance_id);
      for (const samplebox of sampleboxs) {
        samplebox.ReprintSession_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SampleBoxList(...sampleboxs);
    }
  }

  getJobs() {
    if (this._refs && this._refs.Job) {
      return new JobList(
        ...Array.from(this._refs.Job)
          .map((id) => Job.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const jobs = Job.query((sel) => sel && sel.Session_id === this.instance_id);
      for (const job of jobs) {
        job.Session_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new JobList(...jobs);
    }
  }

  getMissions() {
    if (this._refs && this._refs.Mission) {
      return new MissionList(
        ...Array.from(this._refs.Mission)
          .map((id) => Mission.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const missions = Mission.query((sel) => sel && sel.Session_id === this.instance_id);
      for (const mission of missions) {
        mission.Session_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new MissionList(...missions);
    }
  }

  set Sample_id(value: number | undefined) {
    if (this.#Sample_id) {
      const relateObj = Sample.get(this.#Sample_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Session', this.instance_id);
      }
    }

    this.#Sample_id = value;

    if (value) {
      const relateObj = Sample.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Session', this.instance_id);
      }
    }

    this.syncToDB();
  }

  get Sample_id(): number | undefined {
    return this.#Sample_id;
  }

  getSample() {
    const sample = Sample.get(this.Sample_id);
    // console.log(`SessionClass: getSample: ${sample?.sample_id}`);
    return sample;
  }

  set Task_id(value) {
    if (this.#Task_id) {
      const relateObj = Task.get(this.#Task_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Session', this.instance_id);
      }
    }
    this.#Task_id = value;
    if (value) {
      const relateObj = Task.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Session', this.instance_id);
      }
    }
    this.syncToDB();
  }

  get Task_id() {
    return this.#Task_id;
  }

  getTask() {
    return Task.get(this.Task_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Session.query((sel) => sel.instance_id !== this.instance_id && sel.token === this.token);
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate session found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`session: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getUser()) {
      problems.push(
        `session: Could not find user instance across unconditional relationship R502: ${this.instance_id}`,
      );
    }
    if (!this.getTask()) {
      problems.push(
        `session: Could not find task instance across unconditional relationship R513: ${this.instance_id}`,
      );
    }
    return problems;
  }

  static async newSession(username: string, password: string, alreadyHashed: boolean) {
    return await newSession(username, password, alreadyHashed);
  }

  // switch_robot(robot_name: string) {
  //   return switch_robot.bind(this)(robot_name);
  // }

  async loadKml(
    text: string,
    jobId?: string,
    {
      sampling = false,
      dealSitesType = undefined as JobType | undefined,
      fieldName = undefined as string | undefined,
      expectedDensity = undefined as number | undefined,
    } = {},
  ): Promise<Mission | undefined> {
    return await loadKml.bind(this)(text, jobId, { sampling, dealSitesType, fieldName, expectedDensity });
  }

  async loadShapes(
    name: string,
    shpfiles: RogoShapefile[],
    jobId: string,
    setAutogenHelper?: ShowAutoGenHelper,
  ): Promise<Mission | undefined> {
    return await loadShapes.bind(this)(name, shpfiles, jobId, setAutogenHelper);
  }

  async logout() {
    await logout.bind(this)();
  }

  robotConnected() {
    return robotConnected.bind(this)();
  }

  async loadMission(id: string, sampling: boolean, setAutogenHelper?: ShowAutoGenHelper): Promise<Mission | undefined> {
    return await loadMission.bind(this)(id, sampling, setAutogenHelper);
  }

  async addAndSelectBox(mission?: Mission, spec?: SamplingSpec) {
    return await addAndSelectBox.bind(this)(mission, spec);
  }

  makeBoxActive = (box: SampleBox) => {
    const currentBox = SampleBox.getCurrentBox();
    if (currentBox) {
      currentBox.Session_id = undefined;
    }

    box.Session_id = this.instance_id;
    dispatchActiveBoxUpdated();
    return box;
  };

  async getDirections(id: string) {
    return await getDirections.bind(this)(id);
  }
}

export class SessionList extends DataObjectList<Session> {
  getUsers() {
    return new UserList(...this.map((session) => session.getUser()).filter((sel) => !!sel));
  }

  getRobots() {
    return new RobotList(...this.map((session) => session.getRobot()).filter((sel) => !!sel));
  }

  getMissions() {
    return new MissionList(...this.map((session) => session.getMission()).filter((sel) => !!sel));
  }

  getSampleBoxes() {
    return new SampleBoxList(
      ...this.reduce((boxes: SampleBox[], session) => boxes.concat(session.getSampleBoxes()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getJobs() {
    return new JobList(
      ...this.reduce((jobs: Job[], session) => jobs.concat(session.getJobs()), []).filter((sel) => !!sel),
    );
  }

  getTasks() {
    return new TaskList(...this.map((session) => session.getTask()).filter((sel) => !!sel));
  }
}
