import EventBus from './EventBus';

export const SESSION_EVENTS = {
  UPDATED: 'updated session',
  UPDATED_CURRENT_SAMPLE: 'updated current sample',
  NAVIGATING_TO_SAMPLE: 'navigating to sample',
  SHOW_SELECTION_HELPER: 'show selection helper',
};

export function dispatchSessionUpdated() {
  console.log(`SessionEvents:dispatchSessionUpdated`);
  EventBus.dispatch(SESSION_EVENTS.UPDATED);
}

export function dispatchUpdatedCurrentSample(instanceIds: (number | undefined)[] = []) {
  console.log(`SessionEvents:dispatchUpdatedCurrentSample ${JSON.stringify(instanceIds)}`);
  EventBus.dispatch(SESSION_EVENTS.UPDATED_CURRENT_SAMPLE, JSON.stringify(instanceIds));
}
