import _ from 'lodash';
import { round } from './utils';
import { PRINTER_CONFIG } from './components/robot/configs/PrinterConfig';
import {
  Content,
  ContentBase,
  ContentColumns,
  ContentImage,
  ContentStack,
  ContentTable,
  ContentText,
} from 'pdfmake/interfaces';
import { IJob, IMission, ISample, ISamplingSpec } from './db/types';

const _sort_samples = (sel: ISample) => {
  try {
    if (isNaN(parseInt(sel.sample_id))) {
      throw new Error('cannot parse int');
    }
    return parseInt(sel.sample_id);
  } catch (err) {
    return sel.sample_id;
  }
};

/* Generate a pdfmake document definition for a checkin for the given
 * missions. `missions` is an array of objects each with a 'mission'
 * and 'samples' field. This allows checkins for partial fields to be
 * generated.
 *
 * Structure of missions:
 *
 * {
 *   name: string,
 *   job_id: string,
 *   partial: boolean,
 *   job: object,
 *   start_depth: number,
 *   end_depth: number,
 *   sample_date: number,
 *   samples: [
 *     {
 *       sample_id: string,
 *       bag_id: string,
 *       box_id: string
 *     }
 *   ]
 * }
 */

export function genPdfCheckinOld(
  missions: IMission[],
  logo: string | ArrayBuffer,
  pdfSampleOrder: string,
  boxId: string | undefined,
  sampleBoxSamples: ISample[],
  config: PRINTER_CONFIG,
) {
  const smallSize = config.pageSize === 'A6';

  const stack: Content[] = [];

  for (let i = 0; i < missions.length; i++) {
    let sampleBoxSamplesCopy = sampleBoxSamples.slice();
    const mission = missions[i];
    const job = mission?.getJob();
    if (!job) {
      throw new Error('Job not found for mission');
    }

    // NEW APP: minor data extraction difference
    // let partial = false;
    // if (currentMission) {
    //   const currentMissionSamples = currentMission.getSampleSites().getSamples();
    //   const blankLabel = currentMissionSamples.find((sample) => !sample.bag_id);
    //   partial = currentMission.job_id === mission.job_id && !!blankLabel;
    // }

    const partial = mission.getSamples().some((sample) => !sample.bag_id);

    if (!partial) {
      sampleBoxSamplesCopy = mission.getSamples();
    }

    console.log(sampleBoxSamplesCopy.map((sample) => `${sample.sample_id}:${sample.change_type}`).join(','));

    const unskippedSamples = sampleBoxSamplesCopy.filter((s) => (!partial || !s.skipped_or_deleted) && s.bag_id);

    console.log(unskippedSamples.map((sample) => `${sample.sample_id}:${sample.change_type}`).join(','));

    // NEW APP: bag_id -> barcode
    const samples =
      pdfSampleOrder === 'sample_id'
        ? _.orderBy(unskippedSamples, [_sort_samples])
        : _.orderBy(unskippedSamples, ['order', _sort_samples], ['asc']);

    // NEW APP: (sample) => sample.jobID === mission.id
    let missionSamples = samples.filter(
      (sample) => sample.getSampleSite()?.getMission()?.instance_id === mission.instance_id,
    );

    // add header
    const header = genHeader({
      logo,
      smallSize,
      job,
      boxId,
      partial,
      mission,
      config,
    });
    stack.push(header);

    // add info table
    const info_table = genInfoTable(smallSize, partial, missionSamples, mission, job);
    stack.push(info_table);

    // instructions to refer to master checkin sheet
    if (partial && pdfSampleOrder === 'sample_id') {
      stack.push({
        text: 'Refer to the master check-in sheet for full table of samples',
        italics: true,
        fontSize: config.lab.font_size,
        margin: [0, 0, 0, 40],
      });
    }

    // table of samples
    // NEW APP: mission.getSamplingSpecs()[0] -> mission.customerDepthSpecs[0]
    const samples_table = genSamplesTableTemplate(mission.getSamplingSpecs()[0]);
    if (smallSize) {
      samples_table.columns = [samples_table.columns[0]];
    }
    for (const [i, sample] of missionSamples.entries()) {
      genSampleRow(smallSize, samples, boxId, info_table, job, i, sample, samples_table);
    }
    stack.push(samples_table);

    // add lab instructions
    // if (mission.job_id) {
    //   const lab_instructions = genLabInstructions(mission.job_id, true, config); // _.get(mission, 'lab.idCode'),
    //   if (i < missions.length - 1) {
    //     lab_instructions["pageBreak"] = 'after'; // add a page break except on the final checkin
    //   }
    //   stack.push(lab_instructions);
    // } else if (i < missions.length - 1) {
    //   // TODO we are casting here to assume we have a "pageBreak" property
    //   // this is true for most content elements EXCEPT for a raw string
    //   // this should
    //   (stack[stack.length - 1] as ContentBase).pageBreak = 'after';
    // }

    // (stack[stack.length - 1] as ContentBase).pageBreak = 'after';
    // we are going to manually edit this
    if (i < missions.length - 1) {
      // TODO we are casting here to assume we have a "pageBreak" property
      // this is true for most content elements EXCEPT for a raw string
      // this should
      (stack[stack.length - 1] as ContentBase).pageBreak = 'after';
    }
  }

  return stack;
}

interface GenHeaderArgs {
  logo: string | ArrayBuffer;
  smallSize: boolean;
  job: IJob;
  boxId?: string;
  partial: boolean;
  mission: IMission;
  config: PRINTER_CONFIG;
}

function genHeader({ logo, smallSize, job, boxId, partial, mission, config }: GenHeaderArgs) {
  // NEW APP: very different
  const logo_section = [
    {
      image: logo,
      width: config.lab.logo_size,
      margin: [0, 0, smallSize ? 0 : 5, 10],
    } as ContentImage,
    {
      stack: [
        { text: 'Deliver to:', fontSize: config.lab.font_size + 2 },
        (job?.lab_name || 'No Lab Name Provided').replace(/"/g, ''),
        { text: job?.lab_address, fontSize: config.lab.font_size + 2 },
      ],
      bold: true,
      fontSize: config.lab.font_size + 4,
      margin: [0, 0, 0, 20],
    } as ContentStack,
  ] as Content[];

  let checkInInfo: ContentStack | undefined = undefined;
  if (boxId) {
    // only generate this part when in the context of a box
    checkInInfo = {
      stack: [
        {
          text: partial ? 'Partial check-in sheet' : 'Master check-in sheet',
          fontSize: config.lab.font_size + 1,
          bold: true,
        },
        {
          text: `Box ID: ${boxId}`,
          fontSize: config.lab.font_size + 1,
        },
      ],
    };
  }

  // NEW APP: minor data extraction difference
  let qrlabel = job.lab_submittal_id?.length > 0 ? 'Lab Job ID:' : 'Rogo Job ID:';
  let qrvalue = job.lab_submittal_id?.length > 0 ? job.lab_submittal_id : mission.job_id;
  const qr_code = {
    alignment: 'right',
    margin: config.lab.qr_margin,
    stack: [
      {
        qr: !qrvalue || qrvalue.length === 0 ? 'null' : qrvalue,
        fit: config.lab.qr_width,
      },
      {
        text: qrlabel,
        fontSize: 10,
      },
      {
        text: !qrvalue || qrvalue.length === 0 ? 'null' : qrvalue,
        bold: true,
      },
    ],
  };

  return {
    columns: [logo_section, boxId ? checkInInfo : {}, qr_code],
    margin: [0, 10, 0, 10],
    columnGap: 5,
  } as ContentColumns;
}

function genInfoTable(smallSize: boolean, partial: boolean, missionSamples: ISample[], mission: IMission, job: IJob) {
  const info_table = {
    columns: [
      {
        table: {
          widths: ['auto', '*'],
          body: [],
        },
        width: '*',
      } as ContentTable,
      {
        table: {
          widths: ['auto', '*'],
          body: [],
        },
        width: '*',
      } as ContentTable,
    ],
    columnGap: 10,
    margin: [0, 0, 0, 10],
  } as ContentColumns;

  if (smallSize) {
    info_table.columns = [info_table.columns[0]];
  }

  const _mission_info_item = (name: string, value: number | string, col?: number) => {
    let colnum = smallSize
      ? 0
      : col !== undefined
        ? col
        : (info_table.columns[0] as ContentTable).table.body.length >
            (info_table.columns[1] as ContentTable).table.body.length
          ? 1
          : 0;
    if (value && value.toString() !== '') {
      (info_table.columns[colnum] as ContentTable).table.body.push([{ text: name, bold: true }, value]);
    }
  };

  // NEW APP: minor name and date changes
  _mission_info_item(partial ? '# samples (this box)' : 'Total samples', missionSamples.length, 0);
  _mission_info_item('Field', job.field, 0);
  _mission_info_item('Farm', job.farm, 0);
  _mission_info_item('Grower', job.grower, 0);
  _mission_info_item('Client', job.client, 0);
  _mission_info_item(
    'Date sampled',
    mission.sample_date > 0.0 ? new Date(mission.sample_date * 1000).toLocaleDateString() : '',
    1,
  );
  _mission_info_item('Billing acct #', job.billing_account);
  _mission_info_item('Results dest.', job.response_email);
  _mission_info_item('Submitter notif. ID', job.submitter_notified_id);
  _mission_info_item('Rogo Job ID', mission.job_id ?? '');
  _mission_info_item('Field ID', job.field_id);
  _mission_info_item('Event ID', job.event_id);
  _mission_info_item('Lab Job ID', job.lab_submittal_id);

  const columnNums = smallSize ? [0] : [0, 1];
  for (const i of columnNums) {
    // TODO This is assuming ContentTable type, probably not a good idea
    if ((info_table.columns[i] as ContentTable).table.body.length < 1) {
      delete info_table.columns[i]; // prevent empty columns
    }
  }

  return info_table;
}

function genSamplesTableTemplate(depthSpecs: ISamplingSpec) {
  const { start_depth, end_depth } = depthSpecs || { start_depth: null, end_depth: null };
  return {
    columns: [
      {
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Depth range (in)',
                    bold: true,
                  } as ContentText,
                  start_depth != null && end_depth != null
                    ? ({
                        text: `${round(start_depth, 2)}" - ${round(end_depth, 2)}"`,
                        bold: true,
                      } as ContentText)
                    : {},
                ],
              ],
            },
            width: '*',
          } as ContentTable,
          {
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto'],
              body: [
                [
                  { text: 'Barcode #', bold: true },
                  { text: 'Sample ID', bold: true },
                  { text: 'Test package', bold: true },
                ],
              ],
            },
            width: '*',
          } as ContentTable,
        ],
      } as ContentStack,
      {
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ['*', '*'],
              body: [
                [
                  {
                    text: 'Depth range (in)',
                    bold: true,
                  },
                  start_depth != null && end_depth != null
                    ? {
                        text: `${round(start_depth, 2)}" - ${round(end_depth, 2)}"`,
                        bold: true,
                      }
                    : {},
                ],
              ],
            },
            width: '*',
          } as ContentTable,
          {
            table: {
              headerRows: 1,
              widths: ['*', 'auto', 'auto'],
              body: [
                [
                  { text: 'Barcode #', bold: true },
                  { text: 'Sample ID', bold: true },
                  { text: 'Test package', bold: true },
                ],
              ],
            },
            width: '*',
          } as ContentTable,
        ],
      } as ContentStack,
    ],
    columnGap: 10,
    margin: [0, 0, 0, 10],
  } as ContentColumns;
}

function genSampleRow(
  smallSize: boolean,
  samples: ISample[],
  boxId: string | undefined,
  info_table: ContentColumns,
  job: IJob | undefined,
  i: number,
  sample: ISample,
  samples_table: ContentColumns,
) {
  const FIRST_PAGE_MAX = 35 - (info_table.columns[0] as ContentTable).table.body.length;
  const LAST_PAGE_MAX = 32;
  const FULL_PAGE_MAX = 41;
  const SINGLE_PAGE_MAX = FIRST_PAGE_MAX + LAST_PAGE_MAX - FULL_PAGE_MAX;

  const singlePage = samples.length <= SINGLE_PAGE_MAX * 2;
  const firstPage = i < FIRST_PAGE_MAX * 2;
  const lastPage = samples.length - i <= (samples.length - FIRST_PAGE_MAX * 2) % (FULL_PAGE_MAX * 2);

  let colnum = 0;

  if (!smallSize) {
    if (singlePage) {
      colnum = Math.floor(i / Math.ceil(samples.length / 2)) % 2;
    } else if (lastPage) {
      const samples_left = (samples.length - FIRST_PAGE_MAX * 2) % (FULL_PAGE_MAX * 2);
      colnum = Math.floor((i - (samples.length - samples_left)) / Math.ceil(samples_left / 2)) % 2;
    } else if (firstPage) {
      colnum = Math.floor(i / FIRST_PAGE_MAX) % 2;
    } else {
      colnum = Math.floor((i - FIRST_PAGE_MAX * 2) / FULL_PAGE_MAX) % 2;
    }
  }

  if (!sample.bag_id) {
    throw new Error('Sample missing bag_id');
  }
  // NEW APP: Changes quite a bit
  const len = sample.bag_id.length;
  const barcode = sample.skipped_or_deleted
    ? sample.change_type
    : `${sample.bag_id.slice(0, 4)}-${sample.bag_id.slice(4, len - 3)}-${sample.bag_id.slice(len - 3, len)}`;
  const test_package = job?.test_package || '';
  const sample_tests: string[] = [];
  const add_on_freq = job?.add_on_freq || 1;
  for (const t of test_package.split('+')) {
    if (!t.endsWith('*')) {
      sample_tests.push(t);
    } else if (i % add_on_freq === 0) {
      sample_tests.push(t.slice(0, -1));
    }
  }
  const testPackageStr = sample_tests.join('+');

  const sampleRow = [
    {
      columns: [
        {
          text: barcode,
          width: 90,
        },
        {
          text: sample.box_uid && sample.box_uid === boxId ? '(this box)' : '',
          width: '*',
          alignment: 'right',
          fontSize: 8,
        },
      ],
      columnGap: 0,
    },
    sample.sample_id,
    testPackageStr,
  ];
  // TODO more iffy casting...
  ((samples_table.columns[colnum] as ContentStack).stack[1] as ContentTable).table.body.push(sampleRow);
}
