import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import Mission, { MissionList } from './MissionClass';
import Session, { SessionList } from './SessionClass';
import { BoundaryChangeType, CoreDiameter } from '../types/types';
import { IJob, Residue } from './types';

export default class Job extends ObjectClassGenerator<Job>('Job') implements IJob {
  // attributes

  #add_on_freq: number;
  #allowed_to_create: boolean;
  #allowed_to_move: boolean;
  #allowed_to_renumber: boolean;
  #attachment_id: string;
  #auto_renumber: boolean;
  #auto_zoom_near_sample: boolean;
  #billing_account: string;
  #boundary_change_notes: string;
  #boundary_change_type: BoundaryChangeType;
  #client: string;
  #company_sample_validator: string;
  #core_diameter: CoreDiameter;
  #enable_manual_drive_aid: boolean;
  #entrance_interview_crop: string;
  #entrance_interview_residue: Residue;
  #event_id: string;
  #farm: string;
  #field_aligned_sample_path: number;
  #field_id: string;
  #field: string;
  #grower_id: string;
  #grower: string;
  #implement_centerted_navigation: boolean;
  #in_field_notes_ops: string;
  #job_flags: string[];
  #lab_address: string;
  #lab_code: string;
  #lab_instructions: string;
  #lab_name: string;
  #lab_primary_delivery: string;
  #lab_qrs_required: boolean;
  #lab_short_name: string;
  #lab_submittal_id: string;
  #manual_core_length_measurement: boolean;
  #manual_core_loss_measurement: boolean;
  #use_original_sample_id: boolean;
  #manual_hole_depth_measurement: boolean;
  #map_making_rules: string;
  #open_time: number;
  #plot_mission: boolean;
  #disable_core_line_alteration: boolean;
  #plot_settings: string;
  #required_fields: string;
  #response_email: string;
  #sample_order_type: string;
  #sampling_company_id: string;
  #sampling_company_name: string;
  #sampling_tolerance_ft: number;
  #sampling_type_special: string;
  #selected_sample_column: string;
  #sites_type: string;
  #submitter_notified_id: string;
  #test_package: string;
  #tilled_field_at_sampling: boolean;
  #strict_core_enforcement: boolean;
  #external_reference_id_column: string;

  // relationships
  #Mission_id: number | undefined;
  #Session_id: number | undefined;

  static tableName = 'Job';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(Job, 'attachment_id');
    this.publishAttribute(Job, 'test_package');
    this.publishAttribute(Job, 'event_id');
    this.publishAttribute(Job, 'client');
    this.publishAttribute(Job, 'grower');
    this.publishAttribute(Job, 'grower_id');
    this.publishAttribute(Job, 'farm');
    this.publishAttribute(Job, 'field');
    this.publishAttribute(Job, 'billing_account');
    this.publishAttribute(Job, 'sampling_company_name');
    this.publishAttribute(Job, 'sampling_company_id');
    this.publishAttribute(Job, 'response_email');
    this.publishAttribute(Job, 'lab_name');
    this.publishAttribute(Job, 'lab_short_name');
    this.publishAttribute(Job, 'lab_code');
    this.publishAttribute(Job, 'lab_address');
    this.publishAttribute(Job, 'lab_primary_delivery');
    this.publishAttribute(Job, 'lab_submittal_id');
    this.publishAttribute(Job, 'lab_qrs_required');
    this.publishAttribute(Job, 'auto_renumber');
    this.publishAttribute(Job, 'lab_instructions');
    this.publishAttribute(Job, 'field_id');
    this.publishAttribute(Job, 'add_on_freq');
    this.publishAttribute(Job, 'sample_order_type');
    this.publishAttribute(Job, 'submitter_notified_id');
    this.publishAttribute(Job, 'tilled_field_at_sampling');
    this.publishAttribute(Job, 'in_field_notes_ops');
    this.publishAttribute(Job, 'boundary_change_type');
    this.publishAttribute(Job, 'boundary_change_notes');
    this.publishAttribute(Job, 'sites_type');
    this.publishAttribute(Job, 'job_flags');
    this.publishAttribute(Job, 'selected_sample_column');
    this.publishAttribute(Job, 'company_sample_validator');
    this.publishAttribute(Job, 'map_making_rules');
    this.publishAttribute(Job, 'required_fields');
    this.publishAttribute(Job, 'open_time');
    this.publishAttribute(Job, 'allowed_to_renumber');
    this.publishAttribute(Job, 'allowed_to_create');
    this.publishAttribute(Job, 'allowed_to_move');
    this.publishAttribute(Job, 'core_diameter');
    this.publishAttribute(Job, 'field_aligned_sample_path');
    this.publishAttribute(Job, 'manual_hole_depth_measurement');
    this.publishAttribute(Job, 'manual_core_length_measurement');
    this.publishAttribute(Job, 'manual_core_loss_measurement');
    this.publishAttribute(Job, 'use_original_sample_id');
    this.publishAttribute(Job, 'plot_settings');
    this.publishAttribute(Job, 'plot_mission');
    this.publishAttribute(Job, 'disable_core_line_alteration');
    this.publishAttribute(Job, 'entrance_interview_residue');
    this.publishAttribute(Job, 'entrance_interview_crop');
    this.publishAttribute(Job, 'sampling_tolerance_ft');
    this.publishAttribute(Job, 'sampling_type_special');
    this.publishAttribute(Job, 'implement_centerted_navigation');
    this.publishAttribute(Job, 'enable_manual_drive_aid');
    this.publishAttribute(Job, 'auto_zoom_near_sample');
    this.publishAttribute(Job, 'strict_core_enforcement');
    this.publishAttribute(Job, 'external_reference_id_column');
    this.publishAttribute(Job, 'Mission_id');
    this.publishAttribute(Job, 'Session_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Job> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#attachment_id = state.attachment_id || '';
    this.#test_package = state.test_package || '';
    this.#event_id = state.event_id || '';
    this.#client = state.client || '';
    this.#grower = state.grower || '';
    this.#grower_id = state.grower_id || '';
    this.#farm = state.farm || '';
    this.#field = state.field || '';
    this.#billing_account = state.billing_account || '';
    this.#sampling_company_name = state.sampling_company_name || '';
    this.#sampling_company_id = state.sampling_company_id || '';
    this.#response_email = state.response_email || '';
    this.#lab_name = state.lab_name || '';
    this.#lab_code = state.lab_code || '';
    this.#lab_address = state.lab_address || '';
    this.#lab_primary_delivery = state.lab_primary_delivery || '';
    this.#lab_submittal_id = state.lab_submittal_id || '';
    this.#lab_qrs_required = state.lab_qrs_required || false;
    this.#auto_renumber = state.auto_renumber || false;
    this.#field_id = state.field_id || '';
    this.#add_on_freq = state.add_on_freq || 0;
    this.#sample_order_type = state.sample_order_type || '';
    this.#submitter_notified_id = state.submitter_notified_id || '';
    this.#tilled_field_at_sampling = state.tilled_field_at_sampling || false;
    this.#in_field_notes_ops = state.in_field_notes_ops || '';
    this.#boundary_change_type = state.boundary_change_type || BoundaryChangeType.None;
    this.#boundary_change_notes = state.boundary_change_notes || '';
    this.#sites_type = state.sites_type || '';
    this.#job_flags = state.job_flags || [];
    this.#selected_sample_column = state.selected_sample_column || '';
    this.#company_sample_validator = state.company_sample_validator || '';
    this.#map_making_rules = state.map_making_rules || '';
    this.#required_fields = state.required_fields || '';
    this.#open_time = state.open_time || 0;
    this.#allowed_to_renumber = state.allowed_to_renumber || false;
    this.#allowed_to_create = state.allowed_to_create || false;
    this.#allowed_to_move = state.allowed_to_move || false;
    this.#core_diameter = state.core_diameter || '0.75 in';
    this.#field_aligned_sample_path = state.field_aligned_sample_path || 0;
    this.#manual_hole_depth_measurement = state.manual_hole_depth_measurement || false;
    this.#manual_core_length_measurement = state.manual_core_length_measurement || false;
    this.#manual_core_loss_measurement = state.manual_core_loss_measurement || false;
    this.#use_original_sample_id = state.use_original_sample_id || false;
    this.#plot_settings = state.plot_settings || '';
    this.#plot_mission = state.plot_mission || false;
    this.#disable_core_line_alteration = state.disable_core_line_alteration || false;
    this.#entrance_interview_residue = state.entrance_interview_residue || '';
    this.#entrance_interview_crop = state.entrance_interview_crop || '';
    this.#sampling_tolerance_ft = state.sampling_tolerance_ft || 0;
    this.#sampling_type_special = state.sampling_type_special || '';
    this.#implement_centerted_navigation = state.implement_centerted_navigation || true;
    this.#enable_manual_drive_aid = state.enable_manual_drive_aid || false;
    this.#auto_zoom_near_sample = state.auto_zoom_near_sample || false;
    this.#strict_core_enforcement = state.strict_core_enforcement || false;
    this.#external_reference_id_column = state.external_reference_id_column || '';
    this.#Mission_id = state.Mission_id;
    this.#Session_id = state.Session_id;
  }

  dispose() {
    const mission = this.getMission();
    if (mission) {
      mission.job_id = undefined;
      mission.dispose();
      this.Mission_id = undefined;
    }
    const session = this.getSession();
    if (session) {
      this.Session_id = undefined;
    }

    Job.delete(this.instance_id);
  }

  set attachment_id(value) {
    this.#attachment_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get attachment_id() {
    return this.#attachment_id;
  }

  set test_package(value) {
    this.#test_package = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get test_package() {
    return this.#test_package;
  }

  set event_id(value) {
    this.#event_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get event_id() {
    return this.#event_id;
  }

  set client(value) {
    this.#client = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get client() {
    return this.#client;
  }

  set grower(value) {
    this.#grower = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get grower() {
    return this.#grower;
  }

  set grower_id(value) {
    this.#grower_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get grower_id() {
    return this.#grower_id;
  }

  set farm(value) {
    this.#farm = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get farm() {
    return this.#farm;
  }

  set field(value) {
    this.#field = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get field() {
    return this.#field;
  }

  set billing_account(value) {
    this.#billing_account = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get billing_account() {
    return this.#billing_account;
  }

  set sampling_company_name(value) {
    this.#sampling_company_name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sampling_company_name() {
    return this.#sampling_company_name;
  }

  set sampling_company_id(value) {
    this.#sampling_company_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sampling_company_id() {
    return this.#sampling_company_id;
  }

  set response_email(value) {
    this.#response_email = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get response_email() {
    return this.#response_email;
  }

  set lab_name(value) {
    this.#lab_name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_name() {
    return this.#lab_name;
  }

  set lab_short_name(value) {
    this.#lab_short_name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_short_name() {
    return this.#lab_short_name;
  }

  set lab_code(value) {
    this.#lab_code = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_code() {
    return this.#lab_code;
  }

  set lab_address(value) {
    this.#lab_address = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_address() {
    return this.#lab_address;
  }

  set lab_primary_delivery(value) {
    this.#lab_primary_delivery = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_primary_delivery() {
    return this.#lab_primary_delivery;
  }

  set lab_submittal_id(value) {
    this.#lab_submittal_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_submittal_id() {
    return this.#lab_submittal_id;
  }

  set lab_qrs_required(value) {
    this.#lab_qrs_required = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_qrs_required() {
    return this.#lab_qrs_required;
  }

  set auto_renumber(value) {
    this.#auto_renumber = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get auto_renumber() {
    return this.#auto_renumber;
  }

  set lab_instructions(value) {
    this.#lab_instructions = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get lab_instructions() {
    return this.#lab_instructions;
  }

  set field_id(value) {
    this.#field_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get field_id() {
    return this.#field_id;
  }

  set add_on_freq(value) {
    this.#add_on_freq = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get add_on_freq() {
    return this.#add_on_freq;
  }

  set sample_order_type(value) {
    this.#sample_order_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sample_order_type() {
    return this.#sample_order_type;
  }

  set submitter_notified_id(value) {
    this.#submitter_notified_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get submitter_notified_id() {
    return this.#submitter_notified_id;
  }

  set company_sample_validator(value) {
    this.#company_sample_validator = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get company_sample_validator() {
    return this.#company_sample_validator;
  }

  get use_original_sample_id() {
    return this.#use_original_sample_id;
  }

  set use_original_sample_id(value) {
    this.#use_original_sample_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  set map_making_rules(value) {
    this.#map_making_rules = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get map_making_rules() {
    return this.#map_making_rules;
  }

  set required_fields(value) {
    this.#required_fields = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get required_fields() {
    return this.#required_fields;
  }

  set open_time(value) {
    this.#open_time = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get open_time() {
    return this.#open_time;
  }

  get zone_interleave() {
    return this.#required_fields.toLowerCase().includes('zns-itl');
  }

  get points_submitted_from_customer() {
    return this.#required_fields.toLowerCase().includes('pts');
  }

  set tilled_field_at_sampling(value) {
    this.#tilled_field_at_sampling = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get tilled_field_at_sampling() {
    return this.#tilled_field_at_sampling;
  }

  set in_field_notes_ops(value) {
    this.#in_field_notes_ops = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get in_field_notes_ops() {
    return this.#in_field_notes_ops;
  }

  set boundary_change_type(value) {
    this.#boundary_change_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get boundary_change_type() {
    return this.#boundary_change_type;
  }

  set boundary_change_notes(value) {
    this.#boundary_change_notes = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get boundary_change_notes() {
    return this.#boundary_change_notes;
  }

  get job_id() {
    return this.getMission()?.job_id;
  }

  get sites_type() {
    return this.#sites_type;
  }

  set sites_type(value) {
    this.#sites_type = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get job_flags() {
    return this.#job_flags;
  }

  set job_flags(value) {
    this.#job_flags = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get selected_sample_column() {
    return this.#selected_sample_column;
  }

  set selected_sample_column(value) {
    this.#selected_sample_column = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get allowed_to_renumber() {
    return this.#allowed_to_renumber;
  }

  set allowed_to_renumber(value) {
    this.#allowed_to_renumber = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get allowed_to_create() {
    return this.#allowed_to_create;
  }

  set allowed_to_create(value) {
    this.#allowed_to_create = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get allowed_to_move() {
    return this.#allowed_to_move;
  }

  set allowed_to_move(value) {
    this.#allowed_to_move = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get core_diameter() {
    return this.#core_diameter;
  }

  set core_diameter(value) {
    this.#core_diameter = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get field_aligned_sample_path() {
    return this.#field_aligned_sample_path;
  }

  set field_aligned_sample_path(value) {
    this.#field_aligned_sample_path = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get manual_hole_depth_measurement() {
    return this.#manual_hole_depth_measurement;
  }

  set manual_hole_depth_measurement(value) {
    this.#manual_hole_depth_measurement = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get manual_measurement_required() {
    return (
      this.manual_hole_depth_measurement || this.manual_core_length_measurement || this.manual_core_loss_measurement
    );
  }

  get manual_core_length_measurement() {
    return this.#manual_core_length_measurement;
  }

  set manual_core_length_measurement(value) {
    this.#manual_core_length_measurement = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get manual_core_loss_measurement() {
    return this.#manual_core_loss_measurement;
  }

  set manual_core_loss_measurement(value) {
    this.#manual_core_loss_measurement = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get plot_settings() {
    return this.#plot_settings;
  }

  set plot_settings(value) {
    this.#plot_settings = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get plot_mission() {
    return this.#plot_mission;
  }

  set plot_mission(value) {
    this.#plot_mission = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get disable_core_line_alteration() {
    return this.#disable_core_line_alteration;
  }

  set disable_core_line_alteration(value) {
    this.#disable_core_line_alteration = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get entrance_interview_residue() {
    return this.#entrance_interview_residue;
  }

  set entrance_interview_residue(value) {
    this.#entrance_interview_residue = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get entrance_interview_crop() {
    return this.#entrance_interview_crop;
  }

  set entrance_interview_crop(value) {
    this.#entrance_interview_crop = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  // this is a proxy property in case we add more entrance interview questions
  get entrance_interview_complete() {
    return this.#entrance_interview_residue !== '' && this.#entrance_interview_crop !== '';
  }

  get sampling_tolerance_ft() {
    return this.#sampling_tolerance_ft;
  }

  set sampling_tolerance_ft(value) {
    this.#sampling_tolerance_ft = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sampling_type_special() {
    return this.#sampling_type_special;
  }

  set sampling_type_special(value) {
    this.#sampling_type_special = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get implement_centerted_navigation() {
    return this.#implement_centerted_navigation;
  }

  set implement_centerted_navigation(value) {
    this.#implement_centerted_navigation = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get enable_manual_drive_aid() {
    return this.#enable_manual_drive_aid;
  }

  set enable_manual_drive_aid(value) {
    this.#enable_manual_drive_aid = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get auto_zoom_near_sample() {
    return this.#auto_zoom_near_sample;
  }

  set auto_zoom_near_sample(value) {
    this.#auto_zoom_near_sample = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get strict_core_enforcement() {
    return this.#strict_core_enforcement;
  }

  set strict_core_enforcement(value) {
    this.#strict_core_enforcement = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get external_reference_id_column() {
    return this.#external_reference_id_column;
  }

  set external_reference_id_column(value) {
    this.#external_reference_id_column = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get last_modified() {
    return this.getMission()?.last_modified;
  }

  set last_modified(value) {
    let inst: Mission;
    if (Boolean((inst = this.getMission()))) {
      inst.last_modified = value;
    }
  }

  get session_token() {
    return this.getSession()?.token;
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Job', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Job', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  getMission() {
    // TODO is this always true?
    return Mission.get(this.Mission_id)!;
  }

  set Session_id(value) {
    if (this.#Session_id) {
      const relateObj = Session.get(this.#Session_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Job', this.instance_id);
      }
    }
    this.#Session_id = value;
    if (value) {
      const relateObj = Session.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Job', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Session_id() {
    return this.#Session_id;
  }

  getSession() {
    return Session.get(this.Session_id);
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Job.query(
      (sel) =>
        sel.instance_id !== this.instance_id && sel.job_id === this.job_id && sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(`Duplicate job found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`);
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`job: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getMission()) {
      problems.push(`job: Could not find mission instance across unconditional relationship R24: ${this.instance_id}`);
    }
    if (!this.getSession()) {
      problems.push(`job: Could not find session instance across unconditional relationship R509: ${this.instance_id}`);
    }
    return problems;
  }

  // async update_info(missionInfo) {
  //   await update_info.bind(this)(missionInfo);
  // }
}

export class JobList extends DataObjectList<Job> {
  constructor(...items) {
    const m = new Map();
    items.forEach((i) => m.set(i.instance_id, i));
    super(...m.values());
  }

  getMissions() {
    return new MissionList(...this.map((job) => job.getMission()).filter((sel) => !!sel));
  }

  getSessions() {
    return new SessionList(...this.map((job) => job.getSession()).filter((sel) => !!sel));
  }
}
