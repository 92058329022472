import { Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { CustomCheckbox } from '../../utils/CustomCheckbox';

interface LayerCustomCheckBoxProps {
  checked: boolean;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}
export default class LayerCustomCheckBox extends PureComponent<LayerCustomCheckBoxProps> {
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <CustomCheckbox checked={this.props.checked} color="primary" onChange={this.props.onChange} size={'small'} />

        <Typography variant={'caption'}>{this.props.label}</Typography>
      </div>
    );
  }
}
