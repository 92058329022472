import React, { PureComponent } from 'react';

import MapButton from './MapButton';
import ContrastPaper from '../../utils/ContrastPaper';

import CloseIcon from '@material-ui/icons/Close';

import {
  Popover,
  Grid,
  Typography,
  IconButton,
  Box,
  Fab,
  FormGroup,
  InputAdornment,
  TextField,
  InputLabel,
} from '@material-ui/core';

import { FaRegCompass } from 'react-icons/fa';

import { generateColor, roundToIncrement } from '../../../utils';

// import './styles/legendbutton.css';
import { DROPOFF_COLORS } from '../helpers/dropoffColors';
import { MapType } from '../helpers/mapTypes';
import Label from '../../mission/sampling/Label';

const FAB_SIZE_MAPS = 'small';
const FAB_SIZE_SAMPLING = 'medium';

type ValidationMapResult = {
  density: {
    error: boolean;
    text: string;
  };
  angle: {
    error: boolean;
    text: string;
  };
  xCount: {
    error: boolean;
    text: string;
  };
  yCount: {
    error: boolean;
    text: string;
  };
};

interface ValueChangerProps {
  value: number;
  increment: number;
  precision?: number;
  label: string;
  smallScreenLabelAddition?: string;
  validate: () => ValidationMapResult;
  update(newValue: number): void;
  decrementFragment?: React.ReactNode;
  incrementFragment?: React.ReactNode;
  mapType: MapType;
  units?: string;
  icon?: React.ReactNode;
  smallScreenLabelClass?: string;
}

interface ValueChangerState {
  anchorEl: null | Element | ((element: Element) => Element);
}

export default class ValueChanger extends PureComponent<ValueChangerProps, ValueChangerState> {
  constructor(props: ValueChangerProps) {
    super(props);

    this.state = {
      anchorEl: null,
    };

    this.toggleContainer = this.toggleContainer.bind(this);
  }

  toggleContainer(event) {
    if (!this.state.anchorEl) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ anchorEl: null });
    }
  }

  render() {
    return (
      <React.Fragment>
        <FormGroup row className="value-changer">
          <div className={'label ' + (this.props.smallScreenLabelClass || '')}>
            <Typography className="text">{this.getLabel()}</Typography>
          </div>
          <div className="values">
            <div className="button">
              <Fab
                size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                onClick={(e) => {
                  if (!isNaN(this.props.value)) {
                    this.props.update(
                      roundToIncrement(this.props.value - this.props.increment, this.props.precision || 0.1),
                    );
                  }
                }}
              >
                {this.props.decrementFragment || '-'}
              </Fab>
            </div>
            <div className="value">
              <TextField
                type="number"
                value={this.props.value}
                size="medium"
                inputProps={{ size: '6' }}
                InputProps={{ endAdornment: this.getUnits() }}
                onChange={(e) => {
                  console.log(e.target.value);
                  this.props.update(parseFloat(e.target.value));
                }}
              />
            </div>
            <div className="button">
              <Fab
                size={this.props.mapType === 'maps' ? FAB_SIZE_MAPS : FAB_SIZE_SAMPLING}
                onClick={(e) => {
                  if (!isNaN(this.props.value)) {
                    this.props.update(
                      roundToIncrement(this.props.value + this.props.increment, this.props.precision || 0.1),
                    );
                  }
                }}
              >
                {this.props.incrementFragment || '+'}
              </Fab>
            </div>
          </div>
        </FormGroup>
      </React.Fragment>
    );
  }

  private getUnits(): React.ReactNode {
    return <InputAdornment position="end">{this.props.icon ? this.props.icon : this.props.units}</InputAdornment>;
  }

  private getLabel(): React.ReactNode {
    if (this.props.smallScreenLabelAddition) {
      return (
        <>
          <div className="big-screen-label">{`${this.props.label}`}</div>
          <div className="small-screen-label">{`${this.props.label}`}</div>
          <div className="small-screen-label">{`${this.props.smallScreenLabelAddition}`}</div>
        </>
      );
    } else {
      return (
        <>
          <div className="big-screen-label">{`${this.props.label}`}</div>
        </>
      );
    }
  }
}
