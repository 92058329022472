import React, { PureComponent, KeyboardEvent } from 'react';

import { TextField, InputAdornment, IconButton, Grid, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

interface ScheduleFilterProps {
  filterText: string;
  updateFilter: (filter: string) => void;
  loadSchedule: (forceRefresh: boolean) => Promise<void>;
}

export default class ScheduleFilter extends PureComponent<ScheduleFilterProps> {
  handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') (event.target as HTMLDivElement).blur();
  };

  render() {
    return (
      <Tooltip
        style={{ fontSize: 30 }}
        title={
          <h3>
            Filter by job name, Rogo Job ID, or use has:points, has:zones, or has:cores+zones for special filtering.
          </h3>
        }
      >
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <TextField
            type="text"
            label="filter..."
            value={this.props.filterText}
            onChange={(e) => {
              this.props.updateFilter(e.target.value);
            }}
            inputProps={{ enterKeyHint: 'search' }}
            style={{ width: '95%' }}
            onKeyDown={this.handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      this.props.loadSchedule(true);
                    }}
                    data-testid="job-browser-refresh"
                  >
                    <RefreshIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Tooltip>
    );
  }
}
