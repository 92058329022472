import type { PayloadAction } from '@reduxjs/toolkit';
import { createEntityAdapter, EntityState, createSelector } from '@reduxjs/toolkit';
import { createAppSlice } from '../../createAppSlice';
import { SampleBox } from '../../../db';
import { RootState } from '../../store';
import { Box } from '../../types';

interface BoxesState extends EntityState<Box, string> {}

const boxesAdapter = createEntityAdapter<Box>({
  // Sort in descending date order
  sortComparer: (a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
});

const initialState: BoxesState = boxesAdapter.getInitialState({});

function sampleBoxToBox(sampleBox: SampleBox): Box {
  return {
    id: sampleBox.uid,
    closedTimestamp: sampleBox.closedTimestamp,
    needsUpdated: sampleBox.needsUpdated,
    labName: sampleBox.labName,
    userName: sampleBox.username,
    jobBoxCountIndex: sampleBox.jobBoxCountIndex,
    dailyBoxCountIndex: sampleBox.dailyBoxCountIndex,
    reprintReason: sampleBox.reprint_reason,
    trackingNumber: sampleBox.trackingNumber,
    trackingNumberTime: sampleBox.trackingNumberTime,
    createdAt: new Date().toISOString(),
  };
}

// If you are not using async thunks you can use the standalone `createSlice`.
export const boxesSlice = createAppSlice({
  name: 'boxes',
  initialState,
  reducers: (create) => ({
    addBox: create.reducer((state, action: PayloadAction<SampleBox>) => {
      boxesAdapter.addOne(state, sampleBoxToBox(action.payload));
    }),
    addMissingBoxes: create.reducer((state, action: PayloadAction<SampleBox[]>) => {
      const existingBoxIds = state.ids;

      action.payload.forEach((sampleBox) => {
        if (existingBoxIds.includes(sampleBox.uid)) {
          return;
        }

        boxesAdapter.addOne(state, sampleBoxToBox(sampleBox));
      });
    }),
    updateBoxTrackingNumber: create.reducer((state, action: PayloadAction<SampleBox>) => {
      const sampleBox = action.payload;

      boxesAdapter.updateOne(state, {
        id: sampleBox.uid,
        changes: {
          trackingNumber: sampleBox.trackingNumber,
          trackingNumberTime: sampleBox.trackingNumberTime,
          needsUpdated: sampleBox.needsUpdated,
        },
      });
    }),
    closeBox: create.reducer((state, action: PayloadAction<SampleBox>) => {
      const sampleBox = action.payload;

      boxesAdapter.updateOne(state, {
        id: sampleBox.uid,
        changes: {
          closedTimestamp: sampleBox.closedTimestamp,
        },
      });
    }),
    disposeBox: create.reducer((state, action: PayloadAction<string>) => {
      boxesAdapter.updateOne(state, {
        id: action.payload,
        changes: {
          disposedAt: new Date().toISOString(),
        },
      });
    }),
    deleteAllBoxes: create.reducer((state) => {
      boxesAdapter.removeAll(state);
    }),
  }),
});

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllBoxes,
  selectById: selectBoxById,
  selectIds: selectBoxIds,
  // Pass in a selector that returns the posts slice of state
} = boxesAdapter.getSelectors((state: RootState) => state.boxes);

// Memoized selector to get the IDs of all live boxes
export const selectLiveBoxIds = createSelector([selectAllBoxes], (boxes) =>
  boxes.filter((box) => !box.disposedAt).map((box) => box.id),
);

export const selectUserLiveBoxIds = createSelector(
  [selectAllBoxes, (state: RootState, userName: string) => userName],
  (boxes, userName) => boxes.filter((box) => !box.disposedAt && box.userName === userName).map((box) => box.id),
);

// Action creators are generated for each case reducer function.
export const { addBox, addMissingBoxes, disposeBox, closeBox, updateBoxTrackingNumber, deleteAllBoxes } = boxesSlice.actions;
