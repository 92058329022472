import React, { PureComponent } from 'react';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';

import { SettingsAccessLevel, BarcodeSelectionMode, BarcodeSelectionModes } from '../../../types/types';
import { BarcodeSelectionModeStore } from '../../../sampleSelectionHelpers';
import logger from '../../../logger';

interface BarcodeAdvancementSelectionProps {
  accessLevel: SettingsAccessLevel;
  updateBarcodeAdvancement: (barcodeAdvancement: BarcodeSelectionMode) => void;
}

interface BarcodeAdvancementSelectionState {
  barcodeSelectionMode: BarcodeSelectionMode;
}

export default class BarcodeAdvancementSelection extends PureComponent<
  BarcodeAdvancementSelectionProps,
  BarcodeAdvancementSelectionState
> {
  constructor(props: BarcodeAdvancementSelectionProps) {
    super(props);

    this.state = {
      barcodeSelectionMode: BarcodeSelectionModeStore.get(),
    };
  }

  handleModeSelect = async (barcodeSelectionMode: BarcodeSelectionMode) => {
    // set the data store directly
    BarcodeSelectionModeStore.set(barcodeSelectionMode);

    await logger.log('BARCODE_ADVANCE', `Set mode to ${barcodeSelectionMode}`);

    // use this to prompt the parent component to re-render
    this.props.updateBarcodeAdvancement(barcodeSelectionMode);

    // set the local saved state
    this.setState({ barcodeSelectionMode });
  };

  render() {
    return (
      <Grid item>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Barcode Advance Mode</InputLabel>
          <Select
            label="Barcode Advance"
            value={this.state.barcodeSelectionMode}
            onChange={async (e) => {
              await this.handleModeSelect(e.target.value as BarcodeSelectionMode);
            }}
            style={{
              marginRight: 15,
              height: 36,
              fontSize: 14,
              overflow: 'hidden',
              width: 180,
            }}
          >
            {BarcodeSelectionModes.map((mode: BarcodeSelectionMode) => (
              <MenuItem key={mode} value={mode} style={{ fontSize: 14 }}>
                {mode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }
}
