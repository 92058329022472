import React from 'react';

import Label from './Label';
import { List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Mission, SampleBox } from '../../../db';
import { SampleErrorMap } from '../../../types/types';
import { LabelMap } from './MissionLabels';

interface LabelCollectionProps {
  mission: Mission;
  barcodeHandler: (sampleInstanceId: number, sampleId: string) => Promise<void>;
  errMap: SampleErrorMap;
  sampleHandler: (sampleInstanceId: number) => Promise<void>;
  currentSampleId: string;
  currentSampleInstanceId: number;
  labels: LabelMap;
  moveSample: (sampleId: number, toBoxInstanceId: number) => Promise<void>;
  activeBox: SampleBox | undefined;
  allBoxes: SampleBox[];
  photosBySampleId: Record<string, any[]>;
  onCoresButtonClick: (sampleID: string) => void;
}

export default React.memo(
  React.forwardRef(function LabelCollection(props: LabelCollectionProps, _ref: any) {
    const labels = Object.keys(props.labels).sort((a, b) => {
      return props.labels[a].order - props.labels[b].order;
    });

    const rowRender = ({ index, key, style }) => {
      const itemKey = labels[index];
      const label = props.labels[itemKey];
      const noActiveBox = !props.activeBox;
      const boxClosed = Boolean(label.box && label.box.closed);
      const photos = props.photosBySampleId[itemKey] || [];

      return (
        <div style={style} key={key}>
          <Label
            key={itemKey}
            sampleId={itemKey}
            barcodeId={label.bagId}
            sampleBox={label.box}
            sampleInstanceId={label.instanceId}
            currentBoxUid={props.activeBox?.uid}
            disabled={noActiveBox || boxClosed}
            duplicateBarcodeError={props.errMap[itemKey].dupError}
            invalidBarcodeError={props.errMap[itemKey].invalidError}
            barcodeGapError={props.errMap[itemKey].gapError}
            backgroundColor={props.currentSampleId === itemKey ? '#829AB1' : null}
            barcodeHandler={props.barcodeHandler}
            sampleHandler={props.sampleHandler}
            moveSample={props.moveSample}
            allBoxes={props.allBoxes}
            currentSampleId={props.currentSampleId}
            photos={photos}
            onCoresButtonClick={props.mission.isManualMeasurementRequired() ? props.onCoresButtonClick : undefined}
          />
        </div>
      );
    };

    return (
      <div style={{ display: 'block', height: '100vh' }}>
        <AutoSizer>
          {({ height, width }) => (
            <List height={height} width={width} rowRenderer={rowRender} rowHeight={95} rowCount={labels.length} />
          )}
        </AutoSizer>
      </div>
    );
  }),
);
