import React, { useState } from 'react';
import {
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
  Button,
  Grid,
  Typography,
  Box,
  Slider,
  Input,
  InputAdornment,
  Mark,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';

export interface ManualMeasurementDialogProps {
  title: string;
  imageSource: string;
  onSave: (value: number) => void;
  initialValue?: number;
  suggestedMin?: number;
  suggestedMax?: number;
  absoluteMin?: number;
  absoluteMax?: number;
  sliderMarks?: boolean | Mark[];
  dismissDialog?: () => void;
}

export default function ManualMeasurementDialog({
  title,
  imageSource,
  onSave,
  initialValue = 50,
  suggestedMin = 0,
  suggestedMax = 100,
  sliderMarks = false,
  absoluteMin = 0,
  absoluteMax = 0,
  dismissDialog = undefined,
}: ManualMeasurementDialogProps) {
  const classes = useStyles();
  const [value, setValue] = useState<number>(initialValue);
  // We disable the Save button until an operator intereacts with controls.
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
    setSaveButtonDisabled(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === '' ? absoluteMin : Number(event.target.value));
    setSaveButtonDisabled(false);
  };

  const handleInputBlur = () => {
    if (value < absoluteMin) {
      setValue(absoluteMin);
    } else if (value > absoluteMax) {
      setValue(absoluteMax);
    }
  };

  const onDecreaseButtonClick = (decreaseBy: number) => {
    setValue((prevValue) => Math.max(prevValue - decreaseBy, absoluteMin));
    setSaveButtonDisabled(false);
  };

  const onIncreaseButtonClick = (increaseBy: number) => {
    setValue((prevValue) => Math.min(prevValue + increaseBy, absoluteMax));
    setSaveButtonDisabled(false);
  };

  return (
    <StyledDialog open={true} maxWidth="lg">
      <DialogTitle disableTypography>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" className={classes.dialogHeader}>
              <b>Enter {title}</b>
            </Typography>
          </Grid>

          {dismissDialog && (
            <Grid item>
              <IconButton aria-label="close modal">
                <CloseIcon onClick={dismissDialog} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Box m={0} display={'flex'}>
          <img
            style={{
              marginRight: 10,
              pointerEvents: 'none',
              width: '35%',
            }}
            src={imageSource}
            alt="source"
          />

          <Grid
            xs={12}
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            className={classes.rightSide}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.controls}
            >
              <Grid item>
                <StyledInput
                  disableUnderline
                  value={value}
                  margin="dense"
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  startAdornment={
                    <StyledInputAdornment position="start">
                      <ButtonGroup orientation="vertical" size="large" color="primary" variant="contained">
                        <StyledControlButton startIcon={<ArrowUpward />} onClick={() => onIncreaseButtonClick(10)}>
                          10
                        </StyledControlButton>
                        <StyledControlButton startIcon={<ArrowUpward />} onClick={() => onIncreaseButtonClick(1)}>
                          1
                        </StyledControlButton>
                      </ButtonGroup>
                    </StyledInputAdornment>
                  }
                  endAdornment={
                    <StyledInputAdornment position="end">
                      <ButtonGroup orientation="vertical" size="large" color="primary" variant="contained">
                        <StyledControlButton startIcon={<ArrowDownward />} onClick={() => onDecreaseButtonClick(1)}>
                          1
                        </StyledControlButton>
                        <StyledControlButton startIcon={<ArrowDownward />} onClick={() => onDecreaseButtonClick(10)}>
                          10
                        </StyledControlButton>
                      </ButtonGroup>
                    </StyledInputAdornment>
                  }
                  inputProps={{
                    min: absoluteMin,
                    max: absoluteMax,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                    style: { fontSize: 32, textAlign: 'center', color: 'black' },
                  }}
                />
              </Grid>

              <Grid item className={classes.sliderRoot}>
                <StyledSlider
                  value={typeof value === 'number' ? value : suggestedMin}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  marks={sliderMarks}
                  min={suggestedMin}
                  max={suggestedMax}
                  orientation="vertical"
                />
              </Grid>
            </Grid>

            <Grid item>
              <StyledSaveButton
                size="large"
                variant="contained"
                color="primary"
                onClick={() => onSave(value)}
                disabled={saveButtonDisabled}
              >
                Save
              </StyledSaveButton>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
}

const StyledDialog = withStyles((theme) => ({
  [theme.breakpoints.only('xs')]: {
    paper: {
      minHeight: '75vh',
    },
  },
  [theme.breakpoints.up('lg')]: {
    paper: {
      minHeight: '70vh',
      minWidth: '60vw',
    },
  },
}))(Dialog);

const StyledSlider = withStyles({
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginBottom: '-15px !important',
    marginLeft: '-15px! important',
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &:active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  markLabel: {
    left: '35px !important',
    fontSize: '1rem',
  },
})(Slider);

const StyledControlButton = withStyles((theme) => ({
  root: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    root: {
      fontSize: '35px',
    },
  },
}))(Button);

const StyledSaveButton = withStyles((theme) => ({
  [theme.breakpoints.up('lg')]: {
    root: {
      padding: '15px 50px 15px 50px',
      fontSize: '15px',
      fontWeight: 700,
    },
  },
}))(Button);

const StyledInput = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '200px',
    marginLeft: '15px',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  input: {
    fontSize: '40px !important',
  },
  [theme.breakpoints.up('lg')]: {
    root: {
      minHeight: '350px',
    },
    input: {
      fontSize: '55px !important',
    },
  },
}))(Input);

const StyledInputAdornment = withStyles({
  positionStart: {
    marginRight: 0,
  },
  positionEnd: {
    marginLeft: 0,
  },
})(InputAdornment);

const useStyles = makeStyles((theme) => ({
  controls: {
    height: '87%',
  },
  sliderRoot: {
    height: '87%',
  },
  dialogHeader: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '40px',
    },
  },
  rightSide: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '650px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '585px',
    },
  },
}));
