import React, { PureComponent } from 'react';

import { RiFileSearchLine } from 'react-icons/ri';

import MapButton from './MapButton';

interface CenterMissionButtonProps {
  onClick: () => Promise<void>;
}

export default class CenterMissionButton extends PureComponent<CenterMissionButtonProps> {
  render() {
    return (
      <MapButton tooltip="Zoom to field" onClick={this.props.onClick}>
        <RiFileSearchLine size={22} />
      </MapButton>
    );
  }
}
