import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { FixedSizeList } from 'react-window';
import { DropoffLocationDistance } from '../../types/types';

interface DropoffListProps {
  list: DropoffLocationDistance[];
  dropoffName: string;
  onClick: (dropoff: DropoffLocationDistance, dropoffName: string) => void;
  disabled: boolean;
}

export default function DropoffList(props: DropoffListProps) {
  const rowRender = ({ index, style }) => {
    const name = props.list[index].record.get('Name');
    return (
      <MenuItem
        disabled={props.disabled}
        onClick={() => props.onClick(props.list[index], props.dropoffName)}
        style={style}
        key={name}
      >
        <Typography variant="caption" key={name}>
          {name}{' '}
          {props.list[index].distance !== undefined ? ` - ${Math.round(props.list[index].distance)} mi` : ''}{' '}
        </Typography>
      </MenuItem>
    );
  };
  return (
    <div>
      {/* 
      @ts-ignore it is not clear what exactly is wrong with these types. Could be the children */}
      <FixedSizeList height={400} width={600} itemSize={52} itemCount={props.list.length}>
        {rowRender}
      </FixedSizeList>
    </div>
  );
}
