import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { FaRuler } from 'react-icons/fa';
import { SoilCore } from '../../db';
import ManualMeasurementsChecker, { ManualMeasurementDialogParameters } from '../../services/ManualMeasurementsChecker';
import { dispatchMissionUpdated } from '../../missionEvents';

type Props = {
  cores: SoilCore[];
  openManualMeasurementDialog: (params: ManualMeasurementDialogParameters) => void;
};

const CoresList = ({ cores, openManualMeasurementDialog }: Props) => {
  const classes = useStyles();

  if (cores.length === 0) {
    return null;
  }

  const onCoreClick = async (core: SoilCore) => {
    await new ManualMeasurementsChecker().check(openManualMeasurementDialog, core, true);

    dispatchMissionUpdated();
  };

  const sortedCores = cores.slice().sort((c1, c2) => c1.core_number - c2.core_number);

  return (
    <List className={classes.root}>
      {sortedCores.map((core) => {
        const labelId = `checkbox-list-label-${core._instance_id}`;

        return (
          <ListItem key={core._instance_id} role={undefined} dense button onClick={() => onCoreClick(core)}>
            <ListItemText
              id={labelId}
              primary={`Core ${core.core_number}, ${core.pulled ? 'PULLED' : 'NOT PULLED'}, length ${
                core.core_length_cm
              } (cm)`}
            />

            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="core-length-measurements" onClick={() => onCoreClick(core)}>
                <FaRuler />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export default CoresList;
