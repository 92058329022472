import { PureComponent } from 'react';
import { layer, source, feature } from 'react-openlayers';
import { Coordinate } from 'ol/coordinate';
import EventBus from '../../../EventBus';
import { DRAW_TYPES, DefaultKMLFeatureProperties, DrawConfig, KMLFeature } from '../../../types/types';
import { getZoneKey } from '../helpers/features';
import { BLACK, GREEN, RED, YELLOW } from '../../../rgbColors';
import { getCurrentMission } from '../../../dataModelHelpers';
import { KML_FEATURE_TYPE, KmlFeatureType } from '../../../featureTypes';
import { convertProjection3857 } from '../../../utils';

interface DrawingMarkersLayerProps {
  currentDrawType?: KmlFeatureType;
  drawConfig: DrawConfig;
  showZones: boolean;
}

interface DrawingMarkersLayerState {
  drawingMarkers: { coordinates: Coordinate; sampleId: string }[];
  renderedMarkers: JSX.Element[];
  renderedZones: React.ReactNode[];
  zoneCoreMap: Record<string | number, number>;
}

export default class DrawingMarkersLayer extends PureComponent<DrawingMarkersLayerProps, DrawingMarkersLayerState> {
  constructor(props: DrawingMarkersLayerProps) {
    super(props);
    this.state = {
      drawingMarkers: [],
      renderedMarkers: [],
      renderedZones: [],
      zoneCoreMap: {},
    };
  }

  componentDidMount() {
    EventBus.on('DRAW_LAYER:UPDATE_DRAWING_MARKERS', this.updateDrawingMarkers);
    EventBus.on('DRAW_LAYER:CLEAR_DRAWING_MARKERS', this.clearDrawingMarkers);
    EventBus.on('DRAW_LAYER:REMOVE_DRAWING_MARKER', this.removeDrawingMarker);
    EventBus.on('DRAW_LAYER:REMOVE_LAST_MARKER', this.removeLastMarker);
  }

  componentWillUnmount() {
    EventBus.remove('DRAW_LAYER:UPDATE_DRAWING_MARKERS', this.updateDrawingMarkers);
    EventBus.remove('DRAW_LAYER:CLEAR_DRAWING_MARKERS', this.clearDrawingMarkers);
    EventBus.remove('DRAW_LAYER:REMOVE_DRAWING_MARKER', this.removeDrawingMarker);
    EventBus.remove('DRAW_LAYER:REMOVE_LAST_MARKER', this.removeLastMarker);
  }

  updateDrawingMarkers = (data: string) => {
    console.log(`DrawingMarkersLayer updateDrawingMarkers`, this.state.drawingMarkers.length, data);
    const newMarker = JSON.parse(data) as { coordinates: Coordinate; sampleId: string };
    const renderedMarkers = [...this.state.renderedMarkers];
    renderedMarkers.push(this.genMarker(newMarker.coordinates, this.state.drawingMarkers.length, false));

    const mission = getCurrentMission();
    const isZoneMission = mission?.is_zone_mission();
    const sampleZones = mission?.getZones()?.getSampleZones();
    let zones = [...this.state.renderedZones];
    const zoneCoreMap = { ...this.state.zoneCoreMap };

    if (isZoneMission && this.props.currentDrawType === DRAW_TYPES.CORE_LINE) {
      // get the zone that this marker is in
      if (!zones.length) {
        zones =
          sampleZones
            ?.map((zone) => zone?.getZone()?.to_feature())
            .flat()
            .map((kmlItem) => {
              if (kmlItem) {
                return this.genSampleZone(kmlItem, 0, 0, false);
              }
            })
            .filter((zone) => !!zone) ?? [];
        sampleZones?.forEach((sampleZone) => {
          zoneCoreMap[sampleZone.getSampleSite()?.original_sample_id ?? ''] = 0;
        });
      }

      const convertedCoordinate = convertProjection3857(newMarker.coordinates);

      const sampleZone = mission?.getEnclosedSampleZone(convertedCoordinate);
      const sampleZoneName = sampleZone?.getZone()?.zone_name ?? '';
      zoneCoreMap[sampleZoneName] += 1;

      console.log(JSON.stringify(zoneCoreMap));

      const sampleZoneFeatures =
        sampleZone
          ?.getZone()
          ?.to_feature()
          .map((kmlItem) => {
            return this.genSampleZone(kmlItem, 0, 0, false);
          }) ?? [];

      zones.push(...sampleZoneFeatures);

      // sampleZoneFeatures.forEach(feature => {
      //     const zoneKey = getZoneKey(feature.properties);
      //     const zoneCoreMap = this.state.zoneCoreMap;
      //     const coreCount = zoneCoreMap.get(zoneKey) || 0;
      //     zoneCoreMap.set(zoneKey, coreCount + 1);
      // })

      // remove all items from the array that match this zone ID
      //zones = zones.filter((zone) => zone.key !== `${KML_FEATURE_TYPE.SAMPLE_ZONE}-${key}`);
    }

    this.setState({
      drawingMarkers: [...this.state.drawingMarkers, newMarker],
      renderedMarkers,
      renderedZones: zones,
      zoneCoreMap,
    });
    // const mission = getCurrentMission();
    // console.log(mission.getZoneCoreMap(this.state.drawingMarkers.map(marker => marker.coordinates)));
  };

  clearDrawingMarkers = () => {
    this.setState({ drawingMarkers: [], renderedMarkers: [] });
  };

  removeDrawingMarker = (removeCoordinates: string) => {
    const coordinates = JSON.parse(removeCoordinates) as Coordinate;
    const newDrawingMarkers = this.state.drawingMarkers.filter((marker) => {
      return marker.coordinates[0] !== coordinates[0] && marker.coordinates[1] !== coordinates[1];
    });
    const newRenderedMarkers = this.state.renderedMarkers.filter((marker) => {
      return marker.key !== `drawing-marker-${coordinates[0]}-${coordinates[1]}`;
    });
    this.setState({ drawingMarkers: newDrawingMarkers, renderedMarkers: newRenderedMarkers });
  };

  removeLastMarker = () => {
    const newDrawingMarkers = this.state.drawingMarkers.slice(0, -1);
    const newRenderedMarkers = this.state.renderedMarkers.slice(0, -1);
    this.setState({ drawingMarkers: newDrawingMarkers, renderedMarkers: newRenderedMarkers });
  };

  genSampleZone = (
    kmlFeature: KMLFeature<DefaultKMLFeatureProperties>,
    sampleMarkerCount = 0,
    zoneMarkerCount = 0,
    hasSplitZones = false,
  ) => {
    const key = getZoneKey(kmlFeature.properties);

    if (!kmlFeature.geometry.coordinates) {
      return null;
    }
    return (
      <feature.PolygonReact
        coordinates={kmlFeature.geometry.coordinates}
        // color each zone green/yellow/red as cores are added
        // fillOptions={}
        fillOptions={
          this.props.showZones
            ? { color: kmlFeature.properties.zone_color.concat([0.3]) }
            : {
                color:
                  zoneMarkerCount === 0
                    ? RED.concat([0.3])
                    : zoneMarkerCount === 1
                      ? YELLOW.concat([0.3])
                      : GREEN.concat([0.3]),
              }
        }
        strokeOptions={{ color: BLACK, width: 1 }}
        key={`${KML_FEATURE_TYPE.SAMPLE_ZONE}-${kmlFeature.properties.name}-${key}`}
        properties={kmlFeature.properties}
        zIndex={30}
        hideAtZoom={10}
        textOptions={{
          text: `${kmlFeature.properties.name}\nCores${hasSplitZones ? '*' : ''}: ${sampleMarkerCount}`,
          font: '18px Calibri,sans-serif',
          fillOptions: { color: BLACK },
          overflow: true,
        }}
      />
    );
  };

  genMarker = (coordinates: Coordinate, index: number, isZoneMission: boolean) => {
    // console.log(`${index} ${JSON.stringify(coordinates)}`);
    return (
      <feature.PointReact
        key={`drawing-marker-${index}`}
        coordinate={coordinates}
        zIndex={0}
        // circleOptions={{
        //     radius: selected ? 12 : 8,
        //     strokeOptions: { color: 'black', width: 2 },
        //     fillOptions: { color: skipped ? 'red' : activeSample ? 'blue' : scanned ? '#43a047' : 'white' },
        //     blinking: presentSample
        // }}
        circleOptions={{
          radius: this.props.currentDrawType === DRAW_TYPES.CORE_LINE ? 8 : isZoneMission ? 12 : 18,
          strokeOptions: { color: 'black', width: 2 },
          fillOptions: this.props.currentDrawType === DRAW_TYPES.CORE_LINE ? { color: 'white' } : undefined,
        }}
      />
    );
  };

  render() {
    // const mission = getCurrentMission();
    // const utm_loc = fromLatLon(lat, lon);
    // const p = point([utm_loc.easting, utm_loc.northing]);
    // const poly = polygon([this.coordinates.map(coord => [fromLatLon(coord[0], coord[1]).easting, fromLatLon(coord[0], coord[1]).northing])]);
    // return booleanWithin(p, poly);
    // const elements: JSX.Element[] = [];
    // if (this.state.drawingMarkers.length) {
    //     elements.push(...this.state.drawingMarkers.map((marker, index) => {
    //         return this.genMarker(marker.coordinates, index, isZoneMission);
    //     }));
    // }

    // if (isZoneMission && !!sampleZones && sampleZones.length && this.props.currentDrawType === DRAW_TYPES.CORE_LINE) {
    //     elements.push(...sampleZones.map((zone) => {
    //         const sampleCores = markersToPoints.filter(marker => zone.sample_id === marker.sampleId);
    //         return zone.getZone().to_features().map((boundary, index, features) => {
    //             // check if any of the points in drawingMarkers are contained in boundary.geometry.coordinates
    //             const zoneCores = markersToPoints.filter(marker => booleanWithin(marker.point, boundary as Feature<Polygon>));
    //             return this.genSampleZone(boundary, sampleCores.length, zoneCores.length, features.length > 1);
    //         });
    //     }).flat());

    // }

    return (
      // @ts-ignore
      <layer.VectorImage zIndex={1}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>{this.state.renderedMarkers}</source.VectorSourceReact>
      </layer.VectorImage>
    );
  }
}
