import { Coordinate } from 'ol/coordinate';
import { feature } from 'react-openlayers';
import { MapPointResolution, MapRotationStorage } from '../../../db/local_storage';
import { FEET_PER_METER } from '../../../constants';
import { ColorLike } from 'ol/colorlike';

interface LineWithDistanceProps {
  coordinates: Coordinate[];
  color?: ColorLike;
  unit?: 'm' | 'ft';
}

export function LineWithDistance(props: LineWithDistanceProps) {
  let { coordinates, color } = props;

  const distanceInMeters =
    Math.sqrt(Math.pow(coordinates[1][0] - coordinates[0][0], 2) + Math.pow(coordinates[1][1] - coordinates[0][1], 2)) *
    MapPointResolution.get();

  const distanceInFeet = distanceInMeters * FEET_PER_METER;

  return (
    <feature.LineStringReact
      coordinates={coordinates}
      zIndex={2}
      hideAtZoom={14}
      strokeOptions={{
        color: color || 'black',
        width: 2,
      }}
      textOptions={{
        text: `${props.unit === 'm' ? distanceInMeters.toFixed(2) : distanceInFeet.toFixed(1)}${props.unit === 'm' ? 'm' : 'ft'}`,
        offsetY: 5,
        font: '20px sans-serif',
        fillOptions: {
          color: 'white',
        },
        rotation: -(MapRotationStorage.get()?.value ?? 0),
      }}
    />
  );
}
