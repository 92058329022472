import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Modal, Paper } from '@material-ui/core';
import _ from 'lodash';
import BoxHistoryCollection from './BoxHistoryCollection';
import { SampleBox } from '../db';
import { PRINTER_CONFIG, PRINTER_SIZE_4_6, getPrintURLFromConfig } from './robot/configs/PrinterConfig';
import { alertError, alertSuccess } from '../alertDispatcher';
import { PDFViewer } from './utils';
import { generateBoxCheckin } from '../pdfcheckin';
import { getCurrentMission, getCurrentSession } from '../dataModelHelpers';
import { SettingsAccessLevel } from '../types/types';
import { selectUserLiveBoxIds } from '../redux/features/boxes/boxesSlice';
import { useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';
import logger from '../logger';

interface BoxHistoryViewProps {
  showBoxCloseScreen: (box: SampleBox) => Promise<void>;
  accessLevel: SettingsAccessLevel;
}

const BoxHistoryView: React.FC<BoxHistoryViewProps> = ({ showBoxCloseScreen, accessLevel }) => {
  const currentUsername = getCurrentSession()?.username || '';
  const userLiveBoxIdsFromNewStore = useAppSelector((state: RootState) => selectUserLiveBoxIds(state, currentUsername));
  const userBoxesFromOriginalStore = SampleBox.query().filter((box) => box.username === currentUsername);

  useEffect(() => {
    const compareBoxesInStores = async () => {
      const userLiveBoxUidsFromOriginalStore = userBoxesFromOriginalStore.map((box) => box.uid);
      const boxUidsDifference = _.xor(userLiveBoxUidsFromOriginalStore, userLiveBoxIdsFromNewStore);

      if (boxUidsDifference.length > 0) {
        alertError(`Box data mismatch detected. Please contact support! Diff: ${boxUidsDifference.join(', ')}`);

        await logger.log('BOX_DATA_MISMATCH', {
          boxesFromOriginalStore: userLiveBoxUidsFromOriginalStore,
          boxesFromNewStore: userLiveBoxIdsFromNewStore,
          diff: boxUidsDifference,
        });
      }
    };

    compareBoxesInStores();
  }, [userBoxesFromOriginalStore, userLiveBoxIdsFromNewStore]);

  const [boxPdfPreviewVisible, setBoxPdfPreviewVisible] = useState(false);
  const [boxPdf, setBoxPdf] = useState<
    | {
        dataUrl: string;
        downloadName: string;
      }
    | undefined
  >({
    dataUrl: '',
    downloadName: '',
  });

  const [printerConfig] = useState<PRINTER_CONFIG>(PRINTER_SIZE_4_6);
  const [printURL] = useState<string>(getPrintURLFromConfig(PRINTER_SIZE_4_6));

  const downloadPdf = useCallback(() => {
    if (boxPdf) {
      const link = document.createElement('a');
      link.href = boxPdf.dataUrl;
      link.download = boxPdf.downloadName;
      link.click();
    }
  }, [boxPdf]);

  const showBoxCheckin = useCallback(
    async (box: SampleBox, labelOnly: boolean = false) => {
      try {
        const boxPdfResult = await generateBoxCheckin(box, printerConfig, labelOnly);
        setBoxPdfPreviewVisible(true);
        setBoxPdf(boxPdfResult);

        if (box.needsUpdated) {
          const currentMission = getCurrentMission();
          const noCurrentMission = !currentMission;
          const currentMissionInBox = !!currentMission && box.getSampleBoxMissions().includes(currentMission);
          const currentMissionNotInBox = !currentMissionInBox;

          if (noCurrentMission || currentMissionNotInBox) {
            const uploadSucceeded = await box.upload();

            if (!uploadSucceeded) {
              alertError('Could not upload box data to S3');
            }
          }
        }
      } catch (err) {
        console.error('Could not export PDF', err);
      }
    },
    [printerConfig],
  );

  return (
    <Paper style={{ width: '100%', marginTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <BoxHistoryCollection
            title="Box History"
            boxes={userBoxesFromOriginalStore}
            defaultExpanded={true}
            showBoxCheckin={showBoxCheckin}
            showShipppingLabelPDF={async (box: SampleBox) => {
              await showBoxCheckin(box, true);
            }}
            showBoxCloseScreen={showBoxCloseScreen}
            accessLevel={accessLevel}
          />
        </Grid>

        {boxPdf && (
          <Modal open={Boolean(boxPdf) && boxPdfPreviewVisible} style={{ zIndex: 1500, overflow: 'scroll' }}>
            <>
              <PDFViewer
                fileUrl={boxPdf.dataUrl}
                downloadName={boxPdf.downloadName}
                onClose={() => {
                  downloadPdf();
                  setBoxPdf(undefined);
                  setBoxPdfPreviewVisible(false);
                }}
                printURL={printURL}
                onSuccess={() => alertSuccess('Print Job Sent Successfully - Check Printer Power and Paper')}
                onFailure={() => alertError('Print Job Failed to Send')}
              />
            </>
          </Modal>
        )}
      </Grid>
    </Paper>
  );
};

export default BoxHistoryView;
