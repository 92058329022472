import { IKMLElement } from '../../../../kml';
import { Mission, Sample, SampleBox, SampleSite } from '../../../../db';
import { cleanSet } from '../../../../utils';
import logger from '../../../../logger';
import { checkPointCoordinates } from './checks';
import { SampleBoxDeSerialized } from '../../../../db/types';
import { getCurrentSession } from '../../../../dataModelHelpers';
import boxCreator from '../../../BoxCreator';

class SampleKmlLoader {
  constructor(
    private mission: Mission,
    private deSerializedBoxes: SampleBoxDeSerialized[],
  ) {}

  async load(point: IKMLElement): Promise<[Sample, string[]]> {
    const errorMessages: string[] = [];
    const sample = Sample.create();
    const ed = point.getExtendedData();
    const pointName = point.find('name');
    const name = pointName ? pointName.getText() : '';
    const originalSampleId = ed.original_sample_id ? ed.original_sample_id.toString().trim() : '';
    const externalReferenceId = ed.external_reference_id ? ed.external_reference_id.toString().trim() : '';

    sample.sample_id = name;
    sample.sample_type = Sample.getSampleTypeByPoint(point);
    this.setSampleSiteAndCentroid(originalSampleId, sample, point, externalReferenceId, errorMessages);

    await this.setBox(ed, sample);

    this.setSpec(ed, sample);

    // load extra info if it exists
    sample.order = ed.order ? parseInt(ed.order) : 0;
    sample.previous_order = ed.previous_order ? parseInt(ed.previous_order) : 0;
    sample.bag_id = ed.bag_id ? ed.bag_id.toString().trim() : '';
    sample.sampled_at = ed.sampled_at ? new Date(ed.sampled_at).valueOf() / 1000.0 : 0.0;
    sample.scanned_at = ed.scanned_at ? new Date(ed.scanned_at).valueOf() / 1000.0 : 0.0;
    sample.pulled_at = ed.pulled_at && ed.pulled_at !== '' ? new Date(ed.pulled_at).valueOf() / 1000.0 : 0.0;
    sample.change_reason = ed.change_reason ? ed.change_reason.toString().trim() : '';
    sample.change_type = ed.change_type ? ed.change_type.toString().trim() : '';
    sample.sample_site_source = ed.sample_site_source ? ed.sample_site_source.toString().trim() : '';

    return [sample, errorMessages];
  }

  private async setBox(ed: Record<string, any>, sample: Sample) {
    let sampleBoxUid = ed.box_uid;
    if (!sampleBoxUid) {
      return;
    }

    sampleBoxUid = sampleBoxUid.toString().trim();
    const box = SampleBox.query((sampleBox) => sampleBox.uid === sampleBoxUid);
    if (box.length) {
      sample.SampleBox_id = box[0].instance_id;

      await logger.log(
        'LOAD_KML - SampleKmlLoader',
        `Linked box with uid ${sampleBoxUid} to sample: ${JSON.stringify(sample)}`,
      );
    } else {
      await SampleBox.recreateFromKml(sample, sampleBoxUid, this.deSerializedBoxes);
    }
  }

  private setSampleSiteAndCentroid(
    originalSampleId: string,
    sample: Sample,
    point: IKMLElement,
    externalReferenceId: string,
    errorMessages: string[],
  ): SampleSite {
    let sampleSite = cleanSet(this.mission.getSampleSites()).find(
      (sel) => sel.original_sample_id && sel.original_sample_id === originalSampleId,
    );
    if (!sampleSite) {
      const [lat, lon] = checkPointCoordinates(point, errorMessages);
      sampleSite = SampleSite.createWithCentroid(this.mission, originalSampleId, lat, lon, externalReferenceId);
    }
    sample.SampleSite_id = sampleSite.instance_id;

    return sampleSite;
  }

  private setSpec(ed: Record<string, any>, sample: Sample) {
    if (!ed.spec_id) {
      return;
    }

    // For all missions, new or old ones, we should have specs
    // already created (prior to loading samples) - see SpecKmlLoader.
    const existingSpec = this.mission.getSamplingSpecBySpecId(ed.spec_id);
    if (!existingSpec) {
      throw new Error(`SampleKmlLoader - spec with spec_id ${ed.spec_id} not found`);
    }

    sample.SamplingSpec_id = existingSpec.instance_id;
  }
}

export default SampleKmlLoader;
