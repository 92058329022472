import EventBus from './EventBus';

export const MISSION_EVENTS = {
  UPDATED: 'updated mission',
  CREATED_OR_DELETED: 'created or deleted mission',
};

export function dispatchMissionUpdated() {
  EventBus.dispatch(MISSION_EVENTS.UPDATED);
}

export type CreatedOrDeletedParams =
  | {
      deletedInstanceId: number | undefined;
      deletedRogoJobId: string | undefined;
    }
  | undefined;

export function dispatchMissionCreatedDeleted(missionData: CreatedOrDeletedParams = undefined) {
  EventBus.dispatch(MISSION_EVENTS.CREATED_OR_DELETED, missionData);
}
