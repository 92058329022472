import { PureComponent } from 'react';
import MapButton from './MapButton';
import { FaRegTrashAlt } from 'react-icons/fa';
import { alertConfirm } from '../../../alertDispatcher';

interface DeleteButtonProps {
  textColor?: string;
  confirm: boolean;
  onClick: () => Promise<void>;
}

export default class DeleteButton extends PureComponent<DeleteButtonProps> {
  constructor(props: DeleteButtonProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  //show a confirmation prompt when deleting if not in map maker view
  async handleClick() {
    let confirmed = !this.props.confirm;

    if (!confirmed) {
      confirmed = await alertConfirm('Are you sure you want to delete this?');
    }

    if (confirmed) {
      await this.props.onClick();
    }
  }

  render() {
    return (
      <MapButton onClick={this.handleClick} tooltip="Delete / Undelete">
        <FaRegTrashAlt size={18} color={this.props.textColor ? this.props.textColor : ''} />
      </MapButton>
    );
  }
}
