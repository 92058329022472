import { DialogTitle, Typography, DialogContent, DialogActions, Box, Button } from '@material-ui/core';
import { AlertEvent } from '../../alertDispatcher';
import { BlurDialog } from '../utils';
import { useState } from 'react';

interface FullScreenAlertProps {
  alertEvent?: AlertEvent;
  fullScreenAlertConfirm?: (response: string | [string, string]) => void;
}

export function FullScreenAlert(props: FullScreenAlertProps) {
  const [confirmFullscreenButton, setConfirmFullscreenButton] = useState(false);
  return (
    <BlurDialog open={Boolean(props.alertEvent)} backgroundColor={props.alertEvent?.backgroundColor} maxWidth="md">
      <DialogTitle disableTypography>
        <Typography variant="h4" align="center">
          {props.alertEvent?.tag}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {props.alertEvent?.message.split('\n').map((text, key) => {
          return (
            <Typography variant="h6" align="center" key={key}>
              <div key={key} dangerouslySetInnerHTML={{ __html: text }}></div>
            </Typography>
          );
        })}
      </DialogContent>
      <DialogActions style={{ padding: 20, justifyContent: 'center' }}>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={{ gap: 10 }}>
          {props.alertEvent?.responses.map((response) => (
            <Button
              key={Array.isArray(response) ? response[0] : response}
              size="large"
              variant="outlined"
              color="default"
              style={{
                fontSize: 20,
                paddingLeft: 30,
                paddingRight: 30,
              }}
              onClick={() => {
                if (Array.isArray(response)) {
                  if (!confirmFullscreenButton) {
                    setConfirmFullscreenButton(true);
                    // this.closeTimeout = setTimeout(() => {
                    //     this.setState({ confirmFullscreenButton: false });
                    // }, 2000);
                  } else {
                    props.fullScreenAlertConfirm && props.fullScreenAlertConfirm(response);
                  }
                } else {
                  props.fullScreenAlertConfirm && props.fullScreenAlertConfirm(response);
                }
              }}
            >
              {Array.isArray(response) ? (confirmFullscreenButton ? response[1] : response[0]) : response}
            </Button>
          ))}
        </Box>
      </DialogActions>
      <DialogContent>
        {props.alertEvent?.lowerScreenText?.split('\n').map((i, key) => {
          return (
            <Typography variant="h6" align="center" key={key}>
              <div style={{ fontSize: 14 }} key={key} dangerouslySetInnerHTML={{ __html: i }}></div>
            </Typography>
          );
        })}
      </DialogContent>
    </BlurDialog>
  );
}
