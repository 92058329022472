import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { SoilCore } from '../../../db';
import CoresList from '../../cores/CoresList';
import { ManualMeasurementDialogParameters } from '../../../services/ManualMeasurementsChecker';
import { Divider, Grid } from '@material-ui/core';

type Props = {
  cores: SoilCore[];
  openManualMeasurementDialog: (params: ManualMeasurementDialogParameters) => void;
  handleClose: () => void;
};

export default function CoresDialog({ cores, handleClose, openManualMeasurementDialog }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [pulledOnly, setPulledOnly] = useState(false);

  const filteredCores = cores.filter((core) => {
    if (!pulledOnly) {
      return true;
    }

    return core.pulled;
  });

  return (
    <Dialog fullScreen={fullScreen} fullWidth open onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Cores List</DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row" alignItems="center" spacing={2}>
            <Grid item>Filters: </Grid>

            <Grid item>
              <ToggleButton
                size="small"
                value="check"
                selected={pulledOnly}
                onChange={() => {
                  setPulledOnly(!pulledOnly);
                }}
              >
                Pulled Only
              </ToggleButton>
            </Grid>
          </Grid>

          <Divider />

          <Grid item>
            <CoresList cores={filteredCores} openManualMeasurementDialog={openManualMeasurementDialog} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}
