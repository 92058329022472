import { PureComponent } from 'react';
import { layer, interaction, source, feature } from 'react-openlayers';
import Collection from 'ol/Collection';
import { BROWN, BURLY_WOOD } from '../../../rgbColors';

import LineString from 'ol/geom/LineString';
import GeometryType from 'ol/geom/GeometryType';
import { Point, Polygon, SimpleGeometry } from 'ol/geom';
import { CoordinatePoint, DRAW_TYPES } from '../../../types/types';
import { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import { DrawConfig } from '../../../types/types';
import BaseEvent from 'ol/events/Event';
import { convertProjection3857, convertProjection4329, isLineCoord, isPointCoord, isPolyCoord } from '../../../utils';
import Draw, { SketchCoordType } from 'ol/interaction/Draw';
import { alertError } from '../../../alertDispatcher';
import { getCurrentMission } from '../../../dataModelHelpers';
import Projection from 'ol/proj/Projection';
import EventBus from '../../../EventBus';

interface DrawLayerProps {
  drawConfig: DrawConfig;
  selectedReorder: Coordinate[];
  selectedCoreLine: Collection<FeatureLike>;
  reorderLine: Coordinate[];
  placedSample: Coordinate | null;
  coreLine: Coordinate[];
  updateSelectedReorder: (selected: Coordinate[]) => void;
  updateSelectedCoreLine: (selected: Collection<FeatureLike>) => void;
  handleModify: (event: BaseEvent) => void;
  handleDraw: (event: BaseEvent, sampleCentroids: Set<CoordinatePoint>) => Promise<void>;
  handleCoreLineModify: (event: BaseEvent) => void;
}

interface DrawLayerState {
  reorderLine: Coordinate[];
}

export default class DrawLayer extends PureComponent<DrawLayerProps, DrawLayerState> {
  LINE_STRING_PROPERTIES = { linestring: true }; // used to identify order line
  reorderLinePoints: Set<CoordinatePoint>;
  lastPublishedCoordinateCount: number;
  altDepressed: boolean;
  deleteCondition: boolean;
  removeLast: boolean;
  drawingRef: Draw;
  //continueDrawing: boolean;

  constructor(props: DrawLayerProps) {
    super(props);

    this.state = {
      reorderLine: [],
    };

    this.reorderLinePoints = new Set();
    this.lastPublishedCoordinateCount = 0;

    this.handleFilter = this.handleFilter.bind(this);
    this.handleContinueDrawing = this.handleContinueDrawing.bind(this);
    this.altDepressed = false;
    this.deleteCondition = false;
    this.removeLast = false;
  }

  componentDidUpdate(prevProps: DrawLayerProps) {}

  componentDidMount() {
    EventBus.on(`DRAW_LAYER:CLEAR_DRAWING_MARKERS`, this.clearReorderLine);
    // listen to keyboard events
    document.addEventListener('keydown', this.handleKeyDown);
    // listen to right click mouse events to delete points

    document.addEventListener('contextmenu', this.handleRightClick);

    // listen for mouse double click
    document.addEventListener('dblclick', this.handleDoubleClick);
  }
  // handleKeydownCheckpoints(event: KeyboardEvent) {
  //     if (event.ctrlKey && event.key === 'z') {
  //         this.handleUndo();
  //     }
  // }
  handleKeyDown = (event: KeyboardEvent) => {
    // if shift is depressed, save this on the class instance and then we will use this in the handleContinueDrawing function
    // to enable/disable snapping
    this.altDepressed = event.altKey;

    this.deleteCondition = event.altKey; // && event.key.toLowerCase() === 'z';
    // console.log(`alt, delete`, this.altDepressed, this.deleteCondition);
  };

  handleDoubleClick = (event: MouseEvent) => {
    console.log(`DrawLayer:handleDoubleClick`);
  };

  handleRightClick = (event: MouseEvent) => {
    console.log(`DrawLayer:handleRightClick`);
    this.removeLast = true;
    // if (this.deleteCondition) {
    //     console.log(`DrawLayer:handleRightClick:deleteCondition`);
    //     // remove last item added in set
    //     const lastItem = this.reorderLinePoints.values().next().value;
    //     this.reorderLinePoints.delete(lastItem);
    //     this.removeLast = true;

    //     EventBus.dispatch('DRAW_LAYER:REMOVE_DRAWING_MARKER');
    // }
  };

  componentWillUnmount() {
    EventBus.remove(`DRAW_LAYER:CLEAR_DRAWING_MARKERS`, this.clearReorderLine);
  }

  clearReorderLine = () => {
    console.log(`DrawLayer:clearReorderLine`);
    this.reorderLinePoints.clear();
    this.lastPublishedCoordinateCount = 0;
  };

  /**
   * Handles order line feature selection
   * @param {*} feature
   */
  handleFilter(feature: FeatureLike) {
    console.log(`DrawLayer:handleFilter`);
    if (feature.getProperties().linestring && !this.props.selectedReorder.length) {
      const selected = new Collection([feature]);
      if (this.props.drawConfig.featureType === DRAW_TYPES.REORDER_LINE) {
        this.props.updateSelectedReorder((feature.getGeometry() as SimpleGeometry).getCoordinates());
      } else {
        this.props.updateSelectedCoreLine(selected);
      }
    } else if (
      feature.getProperties().linestring &&
      (this.props.selectedReorder.length || this.props.selectedCoreLine.getArray().length)
    ) {
      this.props.updateSelectedReorder([]);
      this.props.updateSelectedCoreLine(new Collection([]));
    }

    return false;
  }

  handleContinueDrawing(
    coordinates: SketchCoordType,
    geometry?: SimpleGeometry,
    projection?: Projection,
  ): SimpleGeometry | undefined {
    // Case 1: We are doing a drive reorder and the draw config is a line string
    const mission = getCurrentMission();
    // if this is a zone mission, we will not do any of this because it is currently not applicable
    if (
      this.props.drawConfig.geometryType === GeometryType.LINE_STRING &&
      coordinates.length &&
      isLineCoord(coordinates)
    ) {
      // in this condition, we will kick off a calculation to try to determine the nearest core to the last point, and once this function
      // fires again, it will check for a replacement line string and replace it if it exists
      // this signifies that a point has been clicked in the line
      let coordinateAdded = false;
      if (this.lastPublishedCoordinateCount !== coordinates.length) {
        this.lastPublishedCoordinateCount = coordinates.length;
        coordinateAdded = true;
      }

      const latestCoordinate = coordinates[coordinates.length - 1];
      const convertedCoordinate = convertProjection3857(latestCoordinate);

      let closePoints: (CoordinatePoint & { sampleId?: string })[] = [];
      if (mission?.is_zone_mission()) {
        // if drawing core locations...
        if (this.props.drawConfig.featureType === DRAW_TYPES.CORE_LINE) {
          if (coordinateAdded) {
            const zone = mission.getEnclosedSampleZone(convertedCoordinate);
            if (zone) {
              console.log(`core + zone`);
              closePoints = [
                {
                  sampleId: zone.sample_id,
                  lat: convertedCoordinate[0],
                  lon: convertedCoordinate[1],
                  getCoordinates: () => [convertedCoordinate[0], convertedCoordinate[1]],
                },
              ];
              console.log(`DrawLayer:handleContinueDrawing:zone`, zone.sample_id);
            } else {
              console.log(`core + no zone`);
            }
          }
        } else {
          const closeCorePointResults = mission.getCloseCores2(convertedCoordinate);
          closePoints = closeCorePointResults.map((coreResult) => coreResult[0]);
        }
      } else {
        closePoints = mission?.getCloseSampleCentroids(convertedCoordinate) || [];
      }

      if (closePoints.length) {
        // choose the closest centroid, assuming we told it to sort...
        const closestCentroid = closePoints[0];
        const centroidProjection = convertProjection4329([closestCentroid.lon, closestCentroid.lat]);
        coordinates[coordinates.length - 1] = centroidProjection;

        if (coordinateAdded) {
          if (!this.reorderLinePoints.has(closestCentroid)) {
            this.reorderLinePoints.add(closestCentroid);
            EventBus.dispatch(
              'DRAW_LAYER:UPDATE_DRAWING_MARKERS',
              JSON.stringify({
                coordinates: centroidProjection,
                sampleId: closestCentroid.sampleId,
              }),
            );
          }
          // else {
          //     // if the point already exists, we will remove it
          //     this.reorderLinePoints.delete(closestCentroid);
          //     EventBus.dispatch('DRAW_LAYER:REMOVE_DRAWING_MARKER', JSON.stringify(centroidProjection));
          //     console.log(`DrawLayer:Try to remove coordinates...`)
          //     for (const coordinate of coordinates) {
          //         if (coordinate[0] === centroidProjection[0] && coordinate[1] === centroidProjection[1]) {
          //             console.log(`DrawLayer:remove ${centroidProjection}`)
          //             coordinates.splice(coordinates.indexOf(coordinate), 1);
          //             break;
          //         }
          //     }
          //     coordinates.pop(); // also remove the point we just added since we're removing
          //     this.lastPublishedCoordinateCount = coordinates.length;
          // }
        }
      }

      // this.setState({
      //     reorderLine: coordinates,
      //     reorderLineLength: coordinates.length,
      // });
      //console.log(`DrawLayer:handleContinueDrawing:closeCentroids`, closeCentroids);
      // Case 2: We are drawing a polygon (boundary, unsafe, or slow)
    } else if (this.props.drawConfig.geometryType === GeometryType.POLYGON) {
      // if array of arrays...
      if (isPolyCoord(coordinates)) {
        coordinates = [coordinates[0].concat([coordinates[0][0]])];
      } else {
        alertError(
          "Uh-oh, we shouldn't be here. We claimed we were drawing a polygon, instead we drew something else.",
        );
      }
    }

    // if the geometry object exists, set the coordinates and return it
    if (geometry) {
      geometry.setCoordinates(coordinates);
      return geometry;
    }

    if (this.props.drawConfig.geometryType === GeometryType.LINE_STRING) {
      if (isLineCoord(coordinates)) {
        return new LineString(coordinates);
      } else {
        alertError(
          "Uh-oh, we shouldn't be here. We claimed we were drawing a line string, instead we drew something else.",
        );
      }
    } else if (this.props.drawConfig.geometryType === GeometryType.POINT) {
      if (isPointCoord(coordinates)) {
        return new Point(coordinates);
      } else {
        alertError(`Uh-oh, we shouldn't be here. We claimed we were drawing a point, instead we drew something else.`);
      }
    } else {
      if (isPolyCoord(coordinates)) {
        return new Polygon(coordinates);
      } else {
        alertError(
          `Uh-oh, we shouldn't be here. We claimed we were drawing a polygon, instead we drew something else.`,
        );
      }
    }
  }

  handleDraw = async (event: BaseEvent) => {
    await this.props.handleDraw(event, this.reorderLinePoints);
  };

  handleOnPropertyChange = (event: BaseEvent) => {};

  render() {
    return (
      // @ts-ignore
      <layer.Vector zIndex={1}>
        {/* 
                // @ts-ignore */}
        <source.VectorSourceReact>
          {this.props.reorderLine.length > 0 && (
            <feature.LineStringReact
              key={'reorderLine'}
              coordinates={this.props.reorderLine}
              strokeOptions={{
                color: BURLY_WOOD,
                width: this.props.selectedReorder && this.props.selectedReorder.length > 0 ? 4 : 2,
              }}
              properties={this.LINE_STRING_PROPERTIES}
            />
          )}
          {this.props.coreLine.length > 0 && (
            <feature.LineStringReact
              key={'coreLine'}
              coordinates={this.props.coreLine}
              strokeOptions={{
                color: BROWN,
                width: this.props.selectedCoreLine && this.props.selectedCoreLine.getArray().length > 0 ? 4 : 2,
              }}
              properties={this.LINE_STRING_PROPERTIES}
            />
          )}
          {this.props.selectedReorder.length > 0 && (
            <interaction.ModifyReact key={'selectedReorder'} onModifyend={this.props.handleModify} />
          )}
          {this.props.selectedCoreLine.getArray().length > 0 && (
            <interaction.ModifyReact key={'selectedCoreLine'} onModifyend={this.props.handleCoreLineModify} />
          )}
          {(this.props.reorderLine.length > 0 || this.props.coreLine.length > 0) &&
            !this.props.drawConfig.geometryType && (
              <interaction.SelectReact
                // @ts-ignore
                //filter={this.handleFilter}
                style={null}
                multi={false}
              />
            )}
          {this.props.placedSample && (
            <feature.PointReact
              key={'drawSamplePoint'}
              coordinate={this.props.placedSample}
              circleOptions={{
                radius: 10,
                fillOptions: { color: 'white' },
                strokeOptions: { color: 'blake', width: 2 },
              }}
            />
          )}

          {this.props.drawConfig.geometryType && (
            <interaction.DrawReact
              onDrawend={this.handleDraw}
              // @ts-ignore
              geometryFunction={this.handleContinueDrawing}
              type={this.props.drawConfig.geometryType}
              styleOptions={{ ...this.props.drawConfig.styleParams }}
              interactionRef={(interaction) => {
                // @ts-ignore
                this.drawingRef = interaction;
              }}
            />
          )}
        </source.VectorSourceReact>
      </layer.Vector>
    );
  }
}
