import { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import SampleScheduleCollection from './sampling/SampleScheduleCollection';

import ScheduleFilter from './common/ScheduleFilter';
import AirtableRecord from '../../db/AirtableRecordClass';
import { getUserPosition } from '../../utils';
import { LoadingButton } from '../utils';
import { Coordinate } from 'ol/coordinate';
import { Jobs, ShiftDropoffs } from '@rogoag/airtable';
import { LatLonSource, ScheduleItem } from '../../types/types';

interface SampleScheduleViewProps {
  loadingSchedule: boolean;
  loadMissionSchedule: (id: string) => Promise<void>;
  loadSchedule: (forceRefresh: boolean) => Promise<void>;
  missionActive: string;
  scheduleItems: ScheduleItem[];
  sampledItems: AirtableRecord<Jobs>[];
  offlineItems: AirtableRecord<Jobs>[];
  showNavMessage: (directionsLink: string, directionsSource: LatLonSource, jobInstanceId: number) => void;
  resubmitAll: () => Promise<void>;
}

interface SampleScheduleViewState {
  currentPosition: Coordinate | undefined;
  filterText: string;
  totalSampledAcres: number;
  totalScheduledAcres: number;
  equivalentScheduledAcres: number;
  equivalentSampledAcres: number;
  loading: boolean;
  sampledSampleCount: number;
  scheduledSampleCount: number;
  totalScheduledInvalid: boolean;
  totalSampledInvalid: boolean;
  equivalentScheduledInvalid: boolean;
  equivalentSampledInvalid: boolean;
  scheduledSampleCountInvalid: boolean;
  sampledSampleCountInvalid: boolean;
}

export default class SampleScheduleView extends Component<SampleScheduleViewProps, SampleScheduleViewState> {
  constructor(props: SampleScheduleViewProps) {
    super(props);
    this.state = {
      filterText: '',
      currentPosition: undefined,
      totalSampledAcres: 0,
      totalScheduledAcres: 0,
      equivalentScheduledAcres: 0,
      equivalentSampledAcres: 0,
      sampledSampleCount: 0,
      scheduledSampleCount: 0,
      loading: false,
      totalScheduledInvalid: false,
      totalSampledInvalid: false,
      equivalentScheduledInvalid: false,
      equivalentSampledInvalid: false,
      scheduledSampleCountInvalid: false,
      sampledSampleCountInvalid: false,
    };

    this.updateFilter = this.updateFilter.bind(this);
  }

  async updateScheduleStats() {
    const getTotal = (recordList: AirtableRecord<Jobs>[], field: keyof Jobs) =>
      recordList.reduce((total, item) => {
        if (!field) {
          return total;
        }
        const value = item.get(field)?.toString() || '0';
        const add = parseFloat(value);
        if (!add || Number.isNaN(add)) {
          return total;
        }
        return total + add;
      }, 0);

    const getInvalid = (recordList: AirtableRecord<Jobs>[], field: string) =>
      recordList.some((item) => {
        const fieldValue = item.get(field);
        return fieldValue === undefined || Number.isNaN(fieldValue.toString());
      });

    const totalAcresField = 'Boundary Acres';
    const equivalentAcresField = 'Ops Acre Eq.';

    const jobsOnly = this.props.scheduleItems.filter(
      (item) => item.table !== 'Shift Dropoffs',
    ) as AirtableRecord<Jobs>[];
    const totalScheduledAcres = getTotal(jobsOnly, totalAcresField);
    const totalSampledAcres = getTotal(this.props.sampledItems, totalAcresField);
    const equivalentScheduledAcres = getTotal(jobsOnly, equivalentAcresField);
    const equivalentSampledAcres = getTotal(this.props.sampledItems, equivalentAcresField);
    const scheduledSampleCount = getTotal(jobsOnly, '# Samples');
    const sampledSampleCount = getTotal(this.props.sampledItems, '# Samples');

    const totalScheduledInvalid = getInvalid(jobsOnly, totalAcresField);
    const totalSampledInvalid = getInvalid(this.props.sampledItems, totalAcresField);
    const equivalentScheduledInvalid = getInvalid(jobsOnly, equivalentAcresField);
    const equivalentSampledInvalid = getInvalid(this.props.sampledItems, equivalentAcresField);
    const scheduledSampleCountInvalid = getInvalid(jobsOnly, '# Samples');
    const sampledSampleCountInvalid = getInvalid(this.props.sampledItems, '# Samples');

    let currentPosition: Coordinate | undefined;
    try {
      currentPosition = await getUserPosition();
    } catch (_) {
      // for now, we won't error anything...
    }

    this.setState({
      currentPosition,
      totalScheduledAcres,
      totalSampledAcres,
      equivalentScheduledAcres,
      equivalentSampledAcres,
      sampledSampleCount,
      scheduledSampleCount,
      totalScheduledInvalid,
      totalSampledInvalid,
      equivalentScheduledInvalid,
      equivalentSampledInvalid,
      scheduledSampleCountInvalid,
      sampledSampleCountInvalid,
    });
  }

  async componentDidUpdate(oldProps: SampleScheduleViewProps) {
    if (this.props.loadingSchedule || !oldProps.loadingSchedule) {
      return;
    }

    await this.updateScheduleStats();
  }

  async componentDidMount() {
    console.log(`SampleScheduleView:componentDidMount ${this.props.sampledItems.length}`);

    await this.updateScheduleStats();
  }

  updateFilter(filterText: string) {
    this.setState({ filterText });
  }

  resubmitAllButton() {
    if (!this.props.offlineItems.length) return undefined;
    return (
      <LoadingButton
        loading={this.state.loading}
        onClick={async () => {
          this.setState({ loading: true });
          await this.props.resubmitAll();
          this.setState({ loading: false });
        }}
      >
        Resubmit All
      </LoadingButton>
    );
  }

  render() {
    return (
      <Paper style={{ width: '100%', marginTop: 10 }}>
        <Grid container spacing={1}>
          <ScheduleFilter
            updateFilter={this.updateFilter}
            loadSchedule={this.props.loadSchedule}
            filterText={this.state.filterText}
          />

          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: 5, margin: 10 }}>
              {/* missions loading. only display one linear progress element for the whole page */}
              {this.props.loadingSchedule ? (
                <LinearProgress color="secondary" style={{ margin: 20 }} />
              ) : (
                <SampleScheduleCollection
                  loadMissionSchedule={this.props.loadMissionSchedule}
                  items={this.props.sampledItems}
                  filterText={this.state.filterText}
                  missionActive={this.props.missionActive}
                  showNavMessage={this.props.showNavMessage}
                  currentLat={this.state.currentPosition ? this.state.currentPosition[0] : 0}
                  currentLon={this.state.currentPosition ? this.state.currentPosition[1] : 0}
                  showSampled={true}
                  title={'Sampled (Yesterday + Today)'}
                  acres={`${Math.round(this.state.totalSampledAcres)}${this.state.totalSampledInvalid ? '*' : ''}`}
                  equivalentAcres={`${Math.round(this.state.equivalentSampledAcres)}${this.state.equivalentSampledInvalid ? '*' : ''}`}
                  sampleCount={`${Math.round(this.state.sampledSampleCount)}${this.state.sampledSampleCountInvalid ? '*' : ''}`}
                  resubmitButton={this.resubmitAllButton()}
                  defaultExpanded={false}
                  sortFunc={(a, b) => {
                    const aDate = a.get('Sample Date Man OVRD Date')?.toString();
                    const bDate = b.get('Sample Date Man OVRD Date')?.toString();
                    return bDate?.localeCompare(aDate) || 0;
                  }}
                  reverse={true}
                />
              )}
            </Paper>

            {this.props.loadingSchedule ? (
              <></>
            ) : (
              <Paper elevation={3} style={{ padding: 5, margin: 10 }}>
                <SampleScheduleCollection
                  loadMissionSchedule={this.props.loadMissionSchedule}
                  items={this.props.scheduleItems}
                  filterText={this.state.filterText}
                  missionActive={this.props.missionActive}
                  showNavMessage={this.props.showNavMessage}
                  currentLat={this.state.currentPosition ? this.state.currentPosition[0] : 0}
                  currentLon={this.state.currentPosition ? this.state.currentPosition[1] : 0}
                  showSampled={true}
                  title={'Scheduled'}
                  acres={`${Math.round(this.state.totalScheduledAcres)}${this.state.totalScheduledInvalid ? '*' : ''}`}
                  equivalentAcres={`${Math.round(this.state.equivalentScheduledAcres)}${this.state.equivalentScheduledInvalid ? '*' : ''}`}
                  sampleCount={`${Math.round(this.state.scheduledSampleCount)}${this.state.scheduledSampleCountInvalid ? '*' : ''}`}
                  defaultExpanded={true}
                />
                {/* <Accordion defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<FaAngleRight />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container direction='row'>
                        <Typography>
                          Scheduled Jobs
                        </Typography>
                        <Typography style={{ marginLeft: 40 }}>
                          {`${Math.round(this.state.totalScheduledAcres)} acres`}
                        </Typography>
                      </Grid>
                    </AccordionSummary>
                    <SampleScheduleCollection
                      loadMissionSchedule={this.props.loadMissionSchedule}
                      items={this.props.scheduleItems}
                      filterText={this.state.filterText}
                      missionActive={this.props.missionActive}
                      showNavMessage={this.props.showNavMessage}
                      currentLat={this.state.currentPosition ? this.state.currentPosition[0] : 0}
                      currentLon={this.state.currentPosition ? this.state.currentPosition[1] : 0}
                      showSampled={false}
                    />
                  </Accordion> */}
              </Paper>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
