import GeometryType from 'ol/geom/GeometryType';
import { GridPatterns } from '../db';
import SoilCoreClass from '../db/SoilCoreClass';
import { KMLElement } from '../kml';
import { KMLFeature, KMLSoilCoreFeatureProperties } from '../types/types';

import { convertProjection4329 } from '../utils';

export function to_kml(this: SoilCoreClass, use_pulled_core_locations: boolean = false) {
  const placemark = KMLElement.element('Placemark');
  const placemark_vis = KMLElement.subelement(placemark, 'visibility');
  placemark_vis.setText('1');
  const nm = KMLElement.subelement(placemark, 'name');
  nm.setText(this.name);
  const point = KMLElement.subelement(placemark, 'Point');
  const coords = KMLElement.subelement(point, 'coordinates');
  const sample = this.getSample();
  if (use_pulled_core_locations && this.pulled) {
    // TODO THESE ARE BACKWARDS.... SOMEWHERE LAT == LON????
    coords.setText(`${this.pulled_lon},${this.pulled_lat},0`);
  } else {
    coords.setText(`${this.lon},${this.lat},0`);
  }

  placemark.putExtendedData('core_location', true);
  placemark.putExtendedData('pulled_lat', this.pulled_lat);
  placemark.putExtendedData('pulled_lon', this.pulled_lon);
  placemark.putExtendedData('planned_lat', this.lat);
  placemark.putExtendedData('planned_lon', this.lon);
  placemark.putExtendedData('pulled_heading', this.pulled_heading);
  placemark.putExtendedData('source', this.source);
  placemark.putExtendedData('dump_guid', this.getSoilDumpEvent()?.guid ?? '');
  placemark.putExtendedData('core_length_cm', this.core_length_cm);
  placemark.putExtendedData('initiator', this.initiator ?? '');
  placemark.putExtendedData('depth_error', this.depth_error);
  placemark.putExtendedData('core_diameter_inches', this.core_diameter_inches);

  // TODO this shouldn't be necessary anymore since we serialize a different message for the robot
  // placemark.putExtendedData('command', this.core_number === Math.max(...cleanSet(sample.getSoilCores()).map(sel => sel.core_number)) ? 2 : 1);
  placemark.putExtendedData('pulled_at_timestamp', this.pulled_at);
  placemark.putExtendedData('pulled_at', new Date(this.pulled_at * 1000).toISOString()); // for backwards compatibility TODO

  // Could not download KML. Trying in safe mode. TypeError: Could not download KML. Trying in safe mode. :: this.get_CorePoint(...).getWaypoint is not a function

  const waypoint = this.getCorePoint()?.getWaypoint();
  placemark.putExtendedData('waypoint_index', waypoint ? waypoint.waypoint_number - 1 : -1); // for backwards compatibility TODO
  placemark.putExtendedData('sample_id', this.sample_id ?? ''); // for backwards compatibility TODO
  placemark.putExtendedData('change_type', sample?.change_type ?? '');
  placemark.putExtendedData('change_reason', sample?.change_reason ?? '');
  const spec = sample?.getSamplingSpec();
  // for zone missions, sample info is stored in each core
  if (spec?.pattern_type === GridPatterns.ZONE) {
    placemark.putExtendedData('zone_core', true);
    placemark.putExtendedData('order', sample?.order ?? '');
    placemark.putExtendedData('bag_id', sample?.bag_id ?? '');
    placemark.putExtendedData('start_depth', spec?.start_depth);
    placemark.putExtendedData('end_depth', spec?.end_depth);
    const sampleBox = sample?.getSampleBox();
    if (sampleBox) {
      placemark.putExtendedData('box_uid', sampleBox.uid);
    }
  }

  const style = KMLElement.subelement(placemark, 'styleUrl');
  style.setText('#core_location');
  return placemark;
}

export function to_feature(this: SoilCoreClass, use_pulled_location: boolean = false) {
  const allSoilCores = this.getSample()?.getSoilCores() || [];
  const spec = this.getSample()?.getSamplingSpec();

  const corePoint = this.getCorePoint();

  const feature: KMLFeature<KMLSoilCoreFeatureProperties> = {
    type: 'Feature',
    geometry: {},
    properties: {
      name: `${this.sample_id}.${this.core_number}`,
      visibility: true,
      core_location: true,
      command: this.core_number === Math.max(...allSoilCores.map((sel) => sel.core_number)) ? 2 : 1,
      waypoint_index: corePoint?.waypoint_number ? corePoint.waypoint_number - 1 : -1, // for backwards compatibility TODO
      waypoint_number: corePoint?.waypoint_number || -1, // for backwards compatibility TODO
      sample_id: this.sample_id || '', // for backwards compatibility TODO
      skipped: this.skipped_or_deleted,
      source: this.source,
      pulled: !!this.pulled_at,

      // zone features, we'll null them out if we don't need them
      zone_core: spec?.pattern_type === GridPatterns.ZONE ? true : undefined,
      order: spec?.pattern_type === GridPatterns.ZONE ? this.getSample()?.order : undefined,
      bag_id: spec?.pattern_type === GridPatterns.ZONE ? this.getSample()?.bag_id : undefined,
      start_depth: spec?.pattern_type === GridPatterns.ZONE ? spec?.start_depth : undefined,
      end_depth: spec?.pattern_type === GridPatterns.ZONE ? spec?.end_depth : undefined,
    },
  };
  feature.geometry = {
    type: GeometryType.POINT,
    coordinates:
      use_pulled_location && this.pulled_lat && this.pulled_lon
        ? convertProjection4329([this.pulled_lon, this.pulled_lat])
        : convertProjection4329([this.lon, this.lat]),
  };

  // for zone missions, sample info is stored in each core
  // if we don't have a zone, we will delete these properties
  if (spec?.pattern_type !== GridPatterns.ZONE) {
    delete feature.properties.zone_core;
    delete feature.properties.order;
    delete feature.properties.bag_id;
    delete feature.properties.start_depth;
    delete feature.properties.end_depth;
  }
  return feature;
}
