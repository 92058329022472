export function validateCores(cores: number) {
  if (cores < 0 || isNaN(cores) || !Number.isInteger(cores)) {
    return 'Number of cores must be a positive integer';
  }
}

export function validateStartDepth(startDepth: number, endDepth: number) {
  if (startDepth < 0 || isNaN(startDepth)) {
    return 'Start depth must be a positive number';
  } else if (startDepth >= endDepth) {
    return 'Start depth must less than end depth';
  }
}

export function validateEndDepth(endDepth: number, startDepth: number) {
  if (endDepth < 0 || isNaN(endDepth)) {
    return 'End depth must be a positive number';
  } else if (endDepth <= startDepth) {
    return 'End depth must greater than start depth';
  }
}

export function validateRadius(radius: number) {
  if (radius < 0 || isNaN(radius)) {
    return 'Sample radius must be a positive number';
  }
}

export function validateAngle(angle: number) {
  if (angle < 0 || angle >= 360 || isNaN(angle)) {
    return 'Sample angle must be a number between 0 and 360';
  }
}

export function validateLength(length: number) {
  if (length < 0 || isNaN(length)) {
    return 'Sample length must be a positive number';
  }
}
