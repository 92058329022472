import GeometryType from 'ol/geom/GeometryType';
import { convertProjection4329 } from '../utils';
import { DataObjectList, ObjectClassGenerator } from './dataobject';
import Mission from './MissionClass';
import SoilCore, { SoilCoreList } from './SoilCoreClass';
import { ISoilDumpEvent, SoilDumpEventCreateParams } from './types';
import { KMLFeature } from '../types/types';
import { KMLElement } from '../kml';
import Sample from './SampleClass';

export default class SoilDumpEvent
  extends ObjectClassGenerator<SoilDumpEvent>('SoilDumpEvent')
  implements ISoilDumpEvent
{
  // attributes
  #timestamp: number;
  #heading: number;
  #lat: number;
  #lon: number;
  #guid: string;
  #Mission_id?: number;
  #PreviousDump_id?: number;

  static tableName = 'SoilDumpEvent';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    this.publishAttribute(SoilDumpEvent, 'Mission_id');
    this.publishAttribute(SoilDumpEvent, 'timestamp');
    this.publishAttribute(SoilDumpEvent, 'heading');
    this.publishAttribute(SoilDumpEvent, 'lat');
    this.publishAttribute(SoilDumpEvent, 'lon');
    this.publishAttribute(SoilDumpEvent, 'guid');
    this.publishAttribute(SoilDumpEvent, 'PreviousDump_id');

    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<SoilDumpEvent> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#Mission_id = state.Mission_id;
    this.#timestamp = state.timestamp!;
    this.#heading = state.heading!;
    this.#lat = state.lat!;
    this.#lon = state.lon!;
    this.#guid = state.guid!;
    this.#PreviousDump_id = state.PreviousDump_id;
  }

  dispose() {
    SoilDumpEvent.delete(this.instance_id);
  }

  get Mission_id() {
    return this.#Mission_id;
  }

  // get NextDump_id() {
  //     return this.#NextDump_id;
  // }

  get PreviousDump_id() {
    return this.#PreviousDump_id;
  }

  get timestamp() {
    return this.#timestamp;
  }

  get heading() {
    return this.#heading;
  }

  get lat() {
    return this.#lat;
  }

  get lon() {
    return this.#lon;
  }

  get guid() {
    return this.#guid;
  }

  set Mission_id(value) {
    if (this.#Mission_id) {
      const relateObj = Mission.get(this.#Mission_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SoilDumpEvent', this.instance_id);
      }
    }
    this.#Mission_id = value;
    if (value) {
      const relateObj = Mission.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SoilDumpEvent', this.instance_id);
      }
    }
    this.syncToDB();
  }

  // set NextDump_id(value) {
  //     if (this.#NextDump_id) {
  //         const relateObj = SoilDumpEvent.get(this.#NextDump_id);
  //         if (relateObj) {
  //             relateObj.removeRelationshipData('SoilDumpEvent', this.instance_id);
  //         }
  //     }
  //     this.#NextDump_id = value;
  //     if (value) {
  //         const relateObj = SoilDumpEvent.get(value);
  //         if (relateObj) {
  //             relateObj.addRelationshipData('SoilDumpEvent', this.instance_id);
  //         }
  //     }
  //     this.update();
  // }

  set PreviousDump_id(value) {
    if (this.#PreviousDump_id) {
      const relateObj = SoilDumpEvent.get(this.#PreviousDump_id);
      if (relateObj) {
        relateObj.removeRelationshipData('SoilDumpEvent', this.instance_id);
      }
    }
    this.#PreviousDump_id = value;
    if (value) {
      const relateObj = SoilDumpEvent.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('SoilDumpEvent', this.instance_id);
      }
    }
    this.syncToDB();
  }

  set timestamp(value) {
    this.#timestamp = value;
    this.syncToDB();
  }

  set heading(value) {
    this.#heading = value;
    this.syncToDB();
  }

  set lat(value) {
    this.#lat = value;
    this.syncToDB();
  }

  set lon(value) {
    this.#lon = value;
    this.syncToDB();
  }

  set guid(value) {
    this.#guid = value;
    this.syncToDB();
  }

  getMission() {
    return Mission.get(this.Mission_id);
  }

  getCoordinates() {
    return [this.lat, this.lon];
  }

  getPreviousDump() {
    if (!this.PreviousDump_id) {
      return;
    }
    return SoilDumpEvent.get(this.PreviousDump_id);
  }

  getSample(): Sample | undefined {
    const cores = this.getSoilCores();
    if (cores.length === 0) {
      return this.getPreviousDump()?.getSample();
    }

    const associatedCores = cores.filter((core) => !!core.Sample_id);
    if (associatedCores.length === 0) {
      return this.getPreviousDump()?.getSample();
    }

    return associatedCores[associatedCores.length - 1].getSample();
  }

  getSoilCores() {
    if (this._refs && this._refs.SoilCore) {
      return new SoilCoreList(
        ...Array.from(this._refs.SoilCore)
          .map((id) => SoilCore.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const soilcores = SoilCore.query((sel) => sel && sel.SoilDumpEvent_id === this.instance_id);
      for (const soilcore of soilcores) {
        soilcore.SoilDumpEvent_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SoilCoreList(...soilcores);
    }
  }

  to_kml = () => {
    return [];
  };

  static createDump(params: SoilDumpEventCreateParams) {
    const dumpEvent = SoilDumpEvent.create();

    dumpEvent.Mission_id = params.Mission_id;
    dumpEvent.guid = params.guid;
    dumpEvent.timestamp = params.timestamp;
    dumpEvent.lat = params.lat;
    dumpEvent.lon = params.lon;
    dumpEvent.heading = params.heading;
    dumpEvent.PreviousDump_id = params.PreviousDump_id;

    return dumpEvent;
  }

  /**
   *
   * @param mission_id
   * @param doc
   */
  static toKml(mission_id: number, doc: KMLElement) {
    const mission = Mission.get(mission_id);
    const dumps = mission?.getSoilDumpEvents();
    // TODO it would be nice to serialize more helpful data right now we just
    const dumpsString = JSON.stringify(dumps);
    doc.putExtendedData('dumps', dumpsString);
  }

  to_feature = () => {
    const sampleID = this.getSample()?.sample_id || 'Test';
    const feature: KMLFeature<any> = {
      type: 'Feature',
      geometry: {},
      properties: {
        name: `Dump Sample ${sampleID}`,
        dump_event: true,
        sample_id: sampleID,
        visibility: true,
        core_ids: this.getSoilCores().map((core) => core.name),
      },
    };
    feature.geometry = {
      type: GeometryType.POINT,
      coordinates: convertProjection4329([this.lon, this.lat]),
    };

    return feature;
  };
}

export class SoilDumpEventList extends DataObjectList<SoilDumpEvent> {}
