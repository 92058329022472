import { DataObjectList, ObjectClassGenerator } from '../db/dataobject';
import { getMemoryTable } from '../db/datamanager';

import SampleSite, { SampleSiteList } from './SampleSiteClass';
import Waypoint, { WaypointList } from './WaypointClass';
import Zone, { ZoneList } from './ZoneClass';
import SamplingSpec, { SamplingSpecList } from './SamplingSpecClass';
import Job, { JobList } from './JobClass';
import ZoneRecording, { ZoneRecordingList } from './ZoneRecordingClass';
import Session, { SessionList } from './SessionClass';

import {
  assign_id,
  to_kml,
  reorder,
  calculate_path,
  to_csv,
  create_zone_cores,
  getCentroidPath,
  to_feature,
  update_boundary,
  update_path,
  to_shapefile,
  to_pdf,
  _to_shapefile,
  _to_kml,
  to_ros_msg,
  update_pullin,
  delete_mission,
  update_mission_details,
  is_zone_mission,
  generate_from_shapes,
  renumber_samples,
  add_sample,
  add_core,
  getBarcodes,
  getMissionName,
  get_airtable_job_record,
  getCoresTaken,
  reverse_path,
  skipUnsampled,
  unskipAllSkipped,
  calculate_path_restore_cores,
} from '../db_ops/mission_ops';
import { cleanSet, convertProjection3857, distanceLatLon, getCloseCores } from '../utils';
import { getAllSampleBoxes, getAllSamples, getField } from '../dataModelHelpers';
import Sample from './SampleClass';
import {
  SampleChangeType,
  ConfirmColumn,
  CoordinatePoint,
  KMLFeature,
  RogoShapefile,
  SampleSource,
} from '../types/types';
import { FrameID } from '../types/rosmsg';
import { PRINTER_CONFIG } from '../components/robot/configs/PrinterConfig';
import SampleBox from './SampleBoxClass';
import { Coordinate } from 'ol/coordinate';
import { KmlFeatureType } from '../featureTypes';
import {
  DEFAULT_CORE_CLOSE_DISTANCE_METERS,
  DEFAULT_CORE_PRESENT_DISTANCE_FEET,
  M_PER_FT,
  REORDER_TOLERANCE_METERS,
} from '../constants';
import { alertError } from '../alertDispatcher';
import SoilCore from './SoilCoreClass';
import { dispatchMissionUpdated } from '../missionEvents';
import { ClosestCore as CloseCore, IMission } from './types';
import { ZoneType } from './ZoneTypesDatatype';
import SoilDumpEvent, { SoilDumpEventList } from './SoilDumpEventClass';
import SampleZone from './SampleZoneClass';
import MissionKmlLoader from '../services/mission-loading/original/from-kml/MissionKmlLoader';
import PointsUpdater from '../services/PointsUpdater';

export default class Mission extends ObjectClassGenerator<Mission>('Mission') implements IMission {
  // attributes
  #name: string;
  #last_modified: number;
  #job_id: string | undefined;
  #description: string;
  #pullin_lat: number;
  #pullin_lon: number;
  #sample_date: number;
  #nav_checksum: string;
  #settings_changes: string;
  #settings_start: string;
  #settings_end: string;
  #cores: string;
  #path_distance: number;

  // relationships
  #Session_id?: number;

  static tableName = 'Mission';

  constructor(state = {}) {
    super(state);
    // publish persistent attributes
    // iterate through all of the keys of the IMission interface
    // and publish them as attributes
    // this is a shorthand way to avoid manually writing out each attribute
    // for example, this.publishAttribute(Mission, 'name');

    // type MissionKeys = keyof Omit<IMission, 'instance_id' | '_instance_id'>;
    // const keysToSkip = [
    //   '_instance_id',
    //   '_version',
    //   '_refs',
    // ];
    // for (const key of Object.keys(this) as Array<MissionKeys>) {
    //   if (keysToSkip.includes(key)) continue;
    //   if (typeof this[key] === 'function') continue;
    //   this.publishAttribute(Mission, key);
    // }
    this.publishAttribute(Mission, 'name');
    this.publishAttribute(Mission, 'last_modified');
    this.publishAttribute(Mission, 'job_id');
    this.publishAttribute(Mission, 'description');
    this.publishAttribute(Mission, 'pullin_lat');
    this.publishAttribute(Mission, 'pullin_lon');
    this.publishAttribute(Mission, 'sample_date');
    this.publishAttribute(Mission, 'nav_checksum');
    this.publishAttribute(Mission, 'settings_changes');
    this.publishAttribute(Mission, 'settings_start');
    this.publishAttribute(Mission, 'settings_end');
    this.publishAttribute(Mission, 'cores');
    this.publishAttribute(Mission, 'path_distance');
    this.publishAttribute(Mission, 'Session_id');
    // initialize state
    this.initializeState(state);
  }

  initializeState(state: Partial<Mission> = {}) {
    this._instance_id = state._instance_id!;
    this._refs = { ...state._refs };
    this._version = state._version!;
    this.#name = state.name || '';
    this.#last_modified = state.last_modified || 0.0;
    this.#job_id = state.job_id || '';
    this.#description = state.description || '';
    this.#pullin_lat = state.pullin_lat || 0.0;
    this.#pullin_lon = state.pullin_lon || 0.0;
    this.#sample_date = state.sample_date || 0.0;
    this.#nav_checksum = state.nav_checksum || '';
    this.#settings_changes = state.settings_changes || '';
    this.#settings_start = state.settings_start || '';
    this.#settings_end = state.settings_end || '';
    this.#path_distance = state.path_distance || 0.0;
    this.#cores = state.cores || '';
    this.#Session_id = state.Session_id;
  }

  dispose() {
    for (const samplesite of this.getSampleSites()) {
      samplesite.Mission_id = undefined;
      samplesite.dispose();
    }

    for (const waypoint of this.getWaypoints()) {
      waypoint.Mission_id = undefined;
      waypoint.dispose();
    }

    for (const zone of this.getZones()) {
      zone.Mission_id = undefined;
      zone.dispose();
    }

    for (const samplingspec of this.getSamplingSpecs()) {
      samplingspec.Mission_id = undefined;
      samplingspec.dispose();
    }

    const job = this.getJob();
    if (job) {
      job.Mission_id = undefined;
      job.dispose();
    }

    for (const zonerecording of this.getZoneRecordings()) {
      zonerecording.Mission_id = undefined;
      zonerecording.dispose();
    }

    const session = this.getSession();
    if (session) {
      this.Session_id = undefined;
    }

    Mission.delete(this.instance_id);
  }

  set name(value) {
    this.#name = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get name() {
    return this.#name;
  }

  set last_modified(value) {
    this.#last_modified = value;
    this.syncToDB();
  }

  get last_modified() {
    return this.#last_modified;
  }

  set job_id(value) {
    this.#job_id = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get job_id() {
    return this.#job_id;
  }

  set description(value) {
    this.#description = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get description() {
    return this.#description;
  }

  set pullin_lat(value) {
    this.#pullin_lat = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pullin_lat() {
    return this.#pullin_lat;
  }

  set pullin_lon(value) {
    this.#pullin_lon = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get pullin_lon() {
    return this.#pullin_lon;
  }

  set sample_date(value) {
    this.#sample_date = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get sample_date() {
    return this.#sample_date;
  }

  set nav_checksum(value) {
    this.#nav_checksum = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get nav_checksum() {
    return this.#nav_checksum;
  }

  get settings_start() {
    return this.#settings_start;
  }

  set settings_start(value: string) {
    this.#settings_start = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get settings_end() {
    return this.#settings_end;
  }

  set settings_end(value: string) {
    this.#settings_end = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  set settings_changes(value) {
    this.#settings_changes = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get settings_changes() {
    return this.#settings_changes;
  }

  get cores() {
    return this.#cores;
  }

  set cores(value: string) {
    this.#cores = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get path_distance() {
    return this.#path_distance;
  }

  set path_distance(value: number) {
    this.#path_distance = value;
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get session_token() {
    return this.getSession()?.token;
  }

  get spec_count() {
    return this.getSamplingSpecs().length;
  }

  clearSampleBarcodes() {
    this.getAllSamples().forEach((sample: Sample) => sample.clearBarcode());
  }

  resetPulled() {
    this.getSamples().forEach((sample) => sample.resetPulled());
  }

  insert_waypoint = (waypointIndex: number, newWaypoint: Waypoint, lastWaypoint: Waypoint) => {
    const waypoints = this.getWaypoints();
    console.log(
      waypointIndex,
      waypoints.map((wp) => wp.waypoint_number),
    );
    for (const wp of waypoints) {
      if (wp.waypoint_number >= waypointIndex && wp._instance_id !== newWaypoint._instance_id) {
        wp.waypoint_number++;
      }
    }

    console.log(
      waypointIndex,
      waypoints.map((wp) => wp.waypoint_number),
    );

    this.to_ros_msg();
  };

  getNextSampleNumber() {
    const sample_ids = this.getAllSamples().map((sample) => sample.sample_id);
    if (sample_ids.length === 0) {
      return 1;
    }

    if (sample_ids.some((sample_id) => Number.isNaN(parseInt(sample_id)))) {
      alertError(`Not all sample IDs are numbers, trying to find max real number`);
      // parseInt and filter out NaNs
      const max_sample_id = Math.max(
        ...sample_ids.map((sample_id) => parseInt(sample_id)).filter((sample_id) => !Number.isNaN(sample_id)),
      );
      return max_sample_id + 1;
    }

    const max_sample_id = Math.max(...sample_ids.map((sample_id) => parseInt(sample_id)));
    return max_sample_id + 1;
  }

  // @ts-ignore
  getSampleSites() {
    if (this._refs && this._refs.SampleSite) {
      return new SampleSiteList(
        ...Array.from(this._refs.SampleSite)
          .map((id) => SampleSite.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const sampleSites = SampleSite.query((sel) => sel && sel.Mission_id === this.instance_id);
      for (const sampleSite of sampleSites) {
        sampleSite.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SampleSiteList(...sampleSites);
    }
  }

  getSampleZoneWithSampleID(sampleID: string): SampleZone | undefined {
    const sampleZones = cleanSet(this.getSampleSites().map((sel) => sel.getSampleZone()));

    return sampleZones.find((sel) => {
      if (!sel) {
        return false;
      }

      const sampleSite = sel.getSampleSite();
      if (!sampleSite) {
        return false;
      }

      return sampleSite.original_sample_id === sampleID;
    });
  }

  getSoilDumpEvents() {
    if (this._refs && this._refs.SoilDumpEvent) {
      return new SoilDumpEventList(
        ...Array.from(this._refs.SoilDumpEvent)
          .map((id) => SoilDumpEvent.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const soilDumpEvents = SoilDumpEvent.query((sel) => sel && sel.Mission_id === this.instance_id);
      for (const soildumpevent of soilDumpEvents) {
        soildumpevent.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new SoilDumpEventList(...soilDumpEvents);
    }
  }

  // @ts-ignore
  getSamples() {
    return this.getSampleSites().getSamples();
  }

  getAllCores(): SoilCore[] {
    return this.getSamples().reduce(
      (accumulator: SoilCore[], currentValue: Sample) => accumulator.concat(currentValue.getSoilCores()),
      [],
    );
  }

  getNextSampleInDriveOrder() {
    const samples = this.getSamples();
    const unsampledSamples = samples.filter((sample) => !sample.skipped_or_deleted && !sample.bag_id);
    if (unsampledSamples.length === 0) {
      return undefined;
    }

    const nextSample = unsampledSamples.sort((a, b) => a.order - b.order)[0];
    return nextSample;
  }

  getSoilCoresInBucket() {
    // TODO this might be ineffcient if there are a lot of cores
    const coreUndumped = (core: SoilCore) => core && !core.isPulledBDCore() && core.isInBucket();

    return [...this.getSamples().getSoilCores().filter(coreUndumped), ...this.getTestCores().filter(coreUndumped)];
  }

  getLastDump() {
    return this.getSoilDumpEvents().sort((a, b) => b.timestamp - a.timestamp)[0];
  }

  getTestCores() {
    if (this._refs && this._refs.SoilCore) {
      return Array.from(this._refs.SoilCore)
        .map((id) => SoilCore.get(id))
        .filter((sel) => !!sel);
    } else {
      const soilCores = SoilCore.query((sel) => sel && sel.test_core_Mission_id === this.instance_id);
      for (const soilCore of soilCores) {
        soilCore.test_core_Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return [...soilCores];
    }
  }

  getBoxes() {
    // Get all samples
    const samples = this.getAllSamples();

    // Get all box_uids from all samples with duplicates removed
    const boxUids = [...new Set(samples.map((sample) => sample.box_uid))];

    // Get all boxes with box_uids from boxIds
    const boxes = SampleBox.query((sel) => sel && boxUids.includes(sel.uid));

    return boxes;
  }

  getWaypoints() {
    if (this._refs && this._refs.Waypoint) {
      return new WaypointList(
        ...Array.from(this._refs.Waypoint)
          .map((id) => Waypoint.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const waypoints = Waypoint.query((sel) => sel && sel.Mission_id === this.instance_id);
      for (const waypoint of waypoints) {
        waypoint.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }

      return new WaypointList(...waypoints);
    }
  }

  getUnskippedWaypoints() {
    const waypoints = this.getWaypoints();
    return waypoints.filter((wp) => {
      const sample = wp.getRelatedSample();
      if (!sample) return false;
      return !sample.skipped_or_deleted;
    });
  }

  getZones() {
    if (this._refs && this._refs.Zone) {
      return new ZoneList(
        ...Array.from(this._refs.Zone)
          .map((id) => Zone.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const zones = Zone.query((sel) => sel && sel.Mission_id === this.instance_id);
      for (const zone of zones) {
        zone.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new ZoneList(...zones);
    }
  }

  getBoundary() {
    const zones = this.getZones();
    if (zones.length === 0) {
      return undefined;
    }

    const collectedBoundary = zones.find((zone) => zone.zone_type === ZoneType.COLLECTED_FIELD);
    if (collectedBoundary) {
      return collectedBoundary;
    }

    const originalBoundary = zones.find((zone) => zone.zone_type === ZoneType.FIELD);
    if (originalBoundary) {
      return originalBoundary;
    }

    throw new Error(`Mission ${this.instance_id} does not have a boundary`);
  }

  getPrimarySpec() {
    const specs = this.getSamplingSpecs();
    if (specs.length === 0) {
      throw new Error(`Mission does not have any sampling specs`);
    }

    // if there's only one spec, it will be primary
    if (specs.length === 1) {
      return specs[0];
    }

    // if there are multiple specs, return the first one that is marked as primary
    const primarySpecs = specs.filter((spec) => spec.primary_spec);
    if (primarySpecs.length > 1) {
      throw new Error(`Mission ${this.instance_id} has multiple primary specs`);
    }

    if (primarySpecs.length === 1) {
      return primarySpecs[0];
    }

    return specs[0];
  }

  getSamplingSpecs() {
    if (this._refs && this._refs.SamplingSpec) {
      return new SamplingSpecList(
        ...Array.from(this._refs.SamplingSpec)
          .map((id) => SamplingSpec.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const samplingspecs = SamplingSpec.query((sel) => sel && sel.Mission_id === this.instance_id);
      for (const samplingspec of samplingspecs) {
        samplingspec.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }

      return new SamplingSpecList(...samplingspecs);
    }
  }

  getSamplingSpecBySpecId(specId: string): SamplingSpec | undefined {
    return this.getSamplingSpecs().find((spec) => spec.spec_id === specId);
  }

  getJob() {
    if (this._refs && this._refs.Job) {
      const result = Array.from(this._refs.Job).map((id) => Job.get(id));
      return result[0];
    } else {
      const job = Job.findOne((sel) => !!sel && sel.Mission_id === this.instance_id);
      if (job) {
        job.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return job;
    }
  }

  getZoneRecordings() {
    if (this._refs && this._refs.ZoneRecording) {
      return new ZoneRecordingList(
        ...Array.from(this._refs.ZoneRecording)
          .map((id) => ZoneRecording.get(id))
          .filter((sel) => !!sel),
      );
    } else {
      const zonerecordings = ZoneRecording.query((sel) => sel && sel.Mission_id === this.instance_id);
      for (const zonerecording of zonerecordings) {
        zonerecording.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return new ZoneRecordingList(...zonerecordings);
    }
  }

  getSession() {
    if (this.#Session_id) {
      return Session.get(this.Session_id);
    }
    if (this._refs && this._refs.Session) {
      const result = Array.from(this._refs.Session).map((id) => Session.get(id));
      return result[0];
    } else {
      const session = Session.findOne((sel) => !!sel && sel.Mission_id === this.instance_id);
      if (session) {
        session.Mission_id = this.instance_id; // re-set foreign key to force update of _refs
      }
      return session;
    }
  }

  set Session_id(value) {
    if (this.#Session_id) {
      const relateObj = Session.get(this.#Session_id);
      if (relateObj) {
        relateObj.removeRelationshipData('Mission', this.instance_id);
      }
    }
    this.#Session_id = value;
    if (value) {
      const relateObj = Session.get(value);
      if (relateObj) {
        relateObj.addRelationshipData('Mission', this.instance_id);
      }
    }
    this.last_modified = Date.now() / 1000;
    this.syncToDB();
  }

  get Session_id() {
    return this.#Session_id;
  }

  async checkIntegrity() {
    const problems: string[] = [];
    // check uniqueness
    // check ID1
    const id1_duplicates = Mission.query(
      (sel) =>
        sel.instance_id !== this.instance_id && sel.name === this.name && sel.last_modified === this.last_modified,
    );
    for (const dup of id1_duplicates) {
      problems.push(
        `Duplicate mission found with ID1 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check ID2
    const id2_duplicates = Mission.query(
      (sel) =>
        sel.instance_id !== this.instance_id && sel.job_id === this.job_id && sel.last_modified === this.last_modified,
    );
    for (const dup of id2_duplicates) {
      problems.push(
        `Duplicate mission found with ID2 for instance id ${this.instance_id}: ${dup.instance_id} (${dup})`,
      );
    }
    // check relationships
    for (const tableName in this._refs) {
      Array.from(this._refs[tableName]).forEach((key) => {
        if (!getMemoryTable(tableName)?.getOne(key)) {
          problems.push(`mission: Could not find ${tableName} instance for ID: ${key}`);
        }
      });
    }
    if (!this.getJob()) {
      problems.push(`mission: Could not find job instance across unconditional relationship R24: ${this.instance_id}`);
    }
    if (!this.getSession()) {
      problems.push(
        `mission: Could not find session instance across unconditional relationship R510: ${this.instance_id}`,
      );
    }
    return problems;
  }

  assign_id() {
    return assign_id.bind(this)();
  }

  to_kml({ insert_boxes = true, use_pulled_locations = false } = {}) {
    return to_kml.bind(this)({
      insert_boxes,
      use_pulled_locations,
    });
  }

  static async load_kml(text: string, jobId?: string, { sampling = false, ignore_loading_errors = false } = {}) {
    const missionKmlLoader = new MissionKmlLoader();

    return await missionKmlLoader.load(text, jobId, { sampling, ignore_loading_errors });
  }

  async reorder(coords: Coordinate[], renumber: boolean, sampling: boolean, reorderCentroids?: Set<CoordinatePoint>) {
    return await reorder.bind(this)(coords, renumber, sampling, reorderCentroids);
  }

  // reorder2 = async (sampleCentroids: Set<SampleCentroid>, partialReorder = false) => {
  //   const reorderedSamples = [...sampleCentroids]
  //     .map(sampleCentroid => sampleCentroid.getSampleSite().getSamples()[0]);
  //   const allMissionSamples = cleanSet(flatten(this.getSampleSites().map(site => site.getSamples())));
  //   const allOtherMissionSamples = allMissionSamples.filter(sample => !reorderedSamples.includes(sample));
  //   const error_messages: string[] = [];
  //   if (partialReorder) {
  //     // get the sample with the lowest drive order
  //     const sortedSamples = reorderedSamples.sort((sampleA, sampleB) => sampleA.order - sampleB.order);
  //     // get the original "lowest" drive order
  //     const lowestDriveOrder = sortedSamples[0].order;
  //     for (let order = lowestDriveOrder; order < reorderedSamples.length + lowestDriveOrder; order++) {
  //       reorderedSamples[order - lowestDriveOrder].order = order;
  //     }
  //   } else {
  //     console.log(JSON.stringify(reorderedSamples.map((sample) => sample.order)));
  //     console.log(allOtherMissionSamples);
  //     // just naively reorder all samples as before
  //     for (let order = 0; order < reorderedSamples.length; order++) {
  //       reorderedSamples[order].order = order + 1;
  //       //allMissionSamples.find((sample) => sample.sample_id === reorderedSamples[order].sample_id).order = order + 1;
  //     }

  //     console.log(JSON.stringify(reorderedSamples.map((sample) => sample.order)));

  //     // add any leftover samples
  //     for (const sample of allOtherMissionSamples) {
  //       error_messages.push(`WARNING: missed sample in order: ${sample.sample_id}`);
  //     }

  //     // set the sample order
  //     for (const [i, sample_] of allOtherMissionSamples.entries()) {
  //       console.log(i);
  //       //allMissionSamples.find((sample) => sample_.sample_id === sample.sample_id).order = reorderedSamples.length + i + 1;
  //       sample_.order = reorderedSamples.length + i + 1;
  //     }

  //     this.renumber_samples();
  //   }

  //   // if there is a path, re-calculate
  //   const waypoints = cleanSet(this.getWaypoints());
  //   const disposes = [];
  //   if (waypoints.length > 0) {
  //     for (const wp of waypoints) {
  //       disposes.push(wp.dispose());
  //     }
  //     await Promise.all(disposes);
  //     await this.calculate_path();
  //   }

  //   return error_messages;
  // }

  getEnclosedSampleZone(coordinate: Coordinate) {
    // console.log(`Sample Zones`, this.getZones().getSampleZones().length);
    const zones = this.getZones()
      .getSampleZones()
      .filter((zone) => zone.getZone()?.contains(coordinate[0], coordinate[1]));

    if (zones.length === 0) {
      return undefined;
    }

    if (zones.length > 1) {
      console.warn(`Found ${zones.length} zones containing coordinate ${coordinate}`);
    }

    return zones[0];
  }

  getZoneCoreMap(points: Coordinate[]) {
    console.log(points);
    const result: Record<string, Record<string, Coordinate[]>> = {};
    for (let point of points.map((point) => convertProjection3857(point))) {
      for (let zone of this.getZones().getSampleZones().getZones()) {
        if (!(zone.zone_name in result)) {
          result[zone.zone_name] = {};
        }
        zone.getContainingBoundaries(point[0], point[1]).forEach((boundary) => {
          const poly_id = boundary.poly_id.toString();
          if (!(poly_id in result[zone.zone_name])) {
            result[zone.zone_name][poly_id] = [];
          }
          result[zone.zone_name][poly_id].push(point);
        });
      }
    }

    return result;

    // const sampleZonePoints: Record<string, { sampleZone: SampleZone, points: Coordinate[] }> = {};
    // const subZonePoints: Record<string, { points: Coordinate[] }> = {};
    // for (const sampleZone of this.getZones().getSampleZones()) {
    //   sampleZonePoints[sampleZone.sample_id] = { sampleZone, points: points.filter(point => sampleZone.getZone().contains(point[0], point[1])) };
    // }
    // for (const [zone, { sampleZone, points }] of Object.entries(sampleZonePoints)) {
    //     sampleZone.getZone().to_features().map(feature => {
    //       const subZonePoints_ = points.filter(_point => booleanWithin(point(_point), feature as Feature<Polygon>));
    //       subZonePoints[`${zone}_${feature.properties?.name}`] = { points: subZonePoints_ };
    //     })
    // }

    // for (const sampleZone of this.getZones().getSampleZones()) {
    //   if (sampleZone.getZone().contains(point[0], point[1])) {
    //     const zone = sampleZone.getZone();
    //     const zonePoints = points.filter(point => zone.contains(point[0], point[1]));
    //     const zoneCoreCounts = zone.to_features().map(feature => {
    //       return zonePoints.filter(zonePoint => booleanWithin(point(zonePoint), feature as Feature<Polygon>)).length
    //     })
    //     result[zone.zone_name] = { sampleCoreCount: zonePoints.length, zoneCoreCount: zoneCoreCounts };
    //   }
    // }

    // return { sampleZonePoints, subZonePoints };
  }

  getCloseCores2(coordinate: Coordinate, toleranceInMeters = REORDER_TOLERANCE_METERS): CloseCore[] {
    const cores = this.getSamples().getSoilCores();

    const result = getCloseCores(cores, coordinate, toleranceInMeters);

    //console.log(`${closeCores.length} close cores found, took ${performance.now() - start}ms`);

    return result;
  }

  // getCloseCoresZones = (coordinate: Coordinate, toleranceInMeters = REORDER_TOLERANCE_METERS) => {
  //   const cores = this.getZones().getSampleZones().getSampleSites().
  //   const closeCores = cores.map(core => {
  //       const distanceToCore = distanceLatLon(coordinate[0], coordinate[1], core.lat, core.lon, 'K') * 1000;

  //       return [core, distanceToCore, distanceToCore < toleranceInMeters] as const;
  //     }).filter(coreTuple => coreTuple[2]); // filter out cores that are too far away

  //   // calculate this outside of the return for benchmarking reasons
  //   const result = closeCores
  //     .sort((coreStructA, coreStructB) => coreStructA[1] - coreStructB[1])
  //     .map(core => core[0]);

  //   //console.log(`${closeCores.length} close cores found, took ${performance.now() - start}ms`);

  //   return result;
  // }

  getCloseSampleCentroids = (
    coordinate: Coordinate,
    { toleranceInMeters = REORDER_TOLERANCE_METERS, sort = true } = {},
  ) => {
    // get sample centroids
    const sampleCentroids = this.getSampleSites().getSampleCentroids();
    const closeSampleCentroids = sampleCentroids
      .map((sampleCentroid) => {
        const distanceInMeters =
          distanceLatLon(coordinate[0], coordinate[1], sampleCentroid.lat, sampleCentroid.lon, 'Kilometers') * 1000;

        return [sampleCentroid, distanceInMeters, distanceInMeters < toleranceInMeters] as const;
      })
      .filter((sampleCentroidTuple) => sampleCentroidTuple[2]); // filter out centroids that are too far away

    if (!sort) {
      const result = closeSampleCentroids.map((sampleCentroid) => sampleCentroid[0]);

      return result;
    }

    // calculate this outside of the return for benchmarking reasons
    const result = closeSampleCentroids
      .sort((sampleCentroidStructA, sampleCentroidStructB) => sampleCentroidStructA[1] - sampleCentroidStructB[1])
      .map((sampleCentroid) => sampleCentroid[0]);

    return result;
  };

  unskipAllSkipped(this: Mission) {
    return unskipAllSkipped.bind(this)();
  }

  skipUnsampled(this: Mission, change_type: SampleChangeType, change_reason: string) {
    return skipUnsampled.bind(this)(change_type, change_reason);
  }

  getCoresTaken(this: Mission) {
    return getCoresTaken.bind(this)();
  }

  async get_airtable_job_record() {
    return await get_airtable_job_record.bind(this)();
  }

  async calculate_path_restore_cores(
    reordered_points_of_interest?: Sample[] | SoilCore[],
    initial_prev_point: Coordinate | null = null,
    matchCoresByPosition: boolean = false,
  ) {
    return await calculate_path_restore_cores.bind(this)(reordered_points_of_interest, initial_prev_point, matchCoresByPosition);
  }

  async calculate_path(
    reordered_points_of_interest?: Sample[] | SoilCore[],
    initial_prev_point: Coordinate | null = null,
  ) {
    return await calculate_path.bind(this)(reordered_points_of_interest, initial_prev_point);
  }

  async reverse_path() {
    return await reverse_path.bind(this)();
  }

  async to_csv() {
    return to_csv.bind(this)();
  }

  async create_zone_cores(coords: Coordinate[]) {
    return await create_zone_cores.bind(this)(coords);
  }

  delete_all_samples_and_cores() {
    const samples = this.getAllSamples(true);
    for (const sample of samples) {
      for (const core of sample.getSoilCores()) {
        core.dispose();
      }
      const sampleSite = sample.getSampleSite();
      if (sampleSite) {
        sampleSite.dispose();
      }
      sample.dispose();
    }
    dispatchMissionUpdated();
  }

  delete_zone_cores() {
    const cores = this.getZones().getSampleZones().getSampleSites().getSamples().getSoilCores();
    for (const core of cores) {
      core.dispose();
    }
    dispatchMissionUpdated();
  }

  getCentroidPath(): { coord: Coordinate; sample_id: string }[] {
    return getCentroidPath.bind(this)();
  }

  // @ts-ignore
  to_feature(prevFeatures: {
    waypoints?: {
      waypointData: string;
      waypointFeatures: KMLFeature;
    };
    settings?: {
      showPulledCoreLocation?: boolean;
    };
  }) {
    return to_feature.bind(this)(prevFeatures);
  }

  async update_boundary(poly_id: number, zone_type: KmlFeatureType, points: Coordinate[][]) {
    await update_boundary.bind(this)(poly_id, zone_type, points);
  }

  // TODO this is NOT  coordinate array, it is some kind of different object with numbers and IDs..
  async update_points(
    points: {
      id: string;
      coords: { lat: number; lon: number };
    }[],
    sampling: boolean,
  ) {
    const pointsUpdater = new PointsUpdater(this, sampling);

    return await pointsUpdater.update(points);
  }

  async update_path(points: number[][]) {
    await update_path.bind(this)(points);
  }

  async to_shapefile(pts: boolean, bnd: boolean) {
    return await to_shapefile.bind(this)(pts, bnd);
  }

  to_pdf(logo: string | ArrayBuffer, pdfSampleOrder: string, config: PRINTER_CONFIG) {
    return to_pdf.bind(this)(logo, pdfSampleOrder, config);
  }

  _to_shapefile(pts: boolean, bnd: boolean) {
    return _to_shapefile.bind(this)(pts, bnd);
  }

  _to_kml({ use_pulled_locations = false, insert_boxes = true, safe_mode = false }) {
    return _to_kml.bind(this)({
      use_pulled_locations,
      insert_boxes,
      safe_mode,
    });
  }

  get_present_tolerance(unit: 'm' | 'ft' = 'm') {
    const missionTolerance = this.getJob()?.sampling_tolerance_ft;
    const presentToleranceFeet = missionTolerance || DEFAULT_CORE_PRESENT_DISTANCE_FEET;
    const presentToleranceMeters = presentToleranceFeet * M_PER_FT;

    return unit === 'm' ? presentToleranceMeters : presentToleranceFeet;
  }

  get_close_tolerance() {
    const presentToleranceMeters = this.get_present_tolerance();
    const closeSampleToleranceMeters = Math.max(presentToleranceMeters, DEFAULT_CORE_CLOSE_DISTANCE_METERS);
    // console.log(`Close Sample Tolerance: ${closeSampleToleranceMeters},${presentToleranceMeters},${DEFAULT_CORE_CLOSE_DISTANCE_METERS}`);

    return closeSampleToleranceMeters;
  }

  get_close_present_tolerance() {
    const presentToleranceMeters = this.get_present_tolerance();
    const closeSampleToleranceMeters = Math.max(presentToleranceMeters, DEFAULT_CORE_CLOSE_DISTANCE_METERS);

    return [closeSampleToleranceMeters, presentToleranceMeters] as const;
  }

  to_ros_msg(frameID: FrameID = 'latlon') {
    return to_ros_msg.bind(this)(frameID);
  }

  async update_pullin(point: Coordinate) {
    await update_pullin.bind(this)(point);
  }

  async delete_mission() {
    await delete_mission.bind(this)();
  }

  is_zone_mission(): boolean {
    return is_zone_mission.bind(this)();
  }

  async update_mission_details({ sampling = false, loading_from_shapefiles = false } = {}) {
    return await update_mission_details.bind(this)({ sampling, loading_from_shapefiles });
  }

  // async update_info(missionInfo) {
  //   await update_info.bind(this)(missionInfo);
  // }

  static async generate_from_shapes(
    name: string,
    shpfiles: RogoShapefile[],
    jobId: string,
    selectColumnFunction: (data: ConfirmColumn) => Promise<string>,
    mission?: Mission,
  ) {
    return await generate_from_shapes(name, shpfiles, jobId, selectColumnFunction, mission);
  }

  renumber_samples(sampling: boolean = true, recalculate_checksum = false) {
    return renumber_samples.bind(this)(sampling, recalculate_checksum);
  }

  async add_sample(point: { coords: { lat: number; lon: number } }, sample_id: string, sample_source: SampleSource) {
    return await add_sample.bind(this)(point, sample_id, sample_source);
  }

  async add_core(point: { coords: { lat: number; lon: number } }, sample_id: string, sample_source: SampleSource) {
    return await add_core.bind(this)(point, sample_id, sample_source);
  }

  rename_sample = (point: Coordinate, sampleId: string, newSampleId: string) => {
    const matchingSamples = this.getAllSamples().filter((sample) => sample.sample_id === sampleId);
    if (!matchingSamples.length) {
      throw new Error(`Could not find sample ${sampleId}`);
    }

    if (matchingSamples.length > 1) {
      throw new Error(`Found multiple samples with ID ${sampleId}`);
    }

    const sample = matchingSamples[0];
    const sampleSite = sample.getSampleSite();
    sample.sample_id = newSampleId;
    const sampleZone = sampleSite?.getSampleZone()?.getZone();
    if (sampleZone) {
      sampleZone.zone_name = newSampleId;
    }
  };

  getBarcodes() {
    return getBarcodes.bind(this)();
  }

  getMissionName(type: string): string {
    return getMissionName.bind(this)(type);
  }

  getLastScannedSampleId() {
    const samples = this.getAllSamples();
    if (samples.length === 0) {
      return '';
    }
    const lastScannedSample = samples.sort((sampleA, sampleB) => sampleB.scanned_at - sampleA.scanned_at);
    return lastScannedSample[0].sample_id;
  }

  getAllSamples(include_skips = true): Sample[] {
    return getAllSamples.bind(this)(include_skips);
  }

  getAllSampleBoxes(): SampleBox[] {
    return getAllSampleBoxes.bind(this)();
  }

  getNextUnpulledSample() {
    const samples = this.getAllSamples(false);
    const unpulledSamples = samples.filter((sample) => !sample.allCoresPulled());
    if (unpulledSamples.length === 0) {
      return undefined;
    }

    const nextUnpulledSample = unpulledSamples.sort((sampleA, sampleB) => sampleA.order - sampleB.order)[0];
    return nextUnpulledSample;
  }

  isManualMeasurementRequired(): boolean {
    const job = this.getJob();
    if (!job) {
      return false;
    }

    return job.manual_measurement_required;
  }

  isBDMission(): boolean {
    const job = this.getJob();
    if (!job) {
      return false;
    }

    return job.sampling_type_special.includes('BD');
  }
}

export class MissionList extends DataObjectList<Mission> {
  getSampleSites() {
    return new SampleSiteList(
      ...this.reduce((sampleSites: SampleSite[], mission) => sampleSites.concat(mission.getSampleSites()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getWaypoints() {
    return new WaypointList(
      ...this.reduce((waypoints: Waypoint[], mission) => waypoints.concat(mission.getWaypoints()), []).filter(
        (sel) => !!sel,
      ),
    );
  }

  getZones() {
    return new ZoneList(
      ...this.reduce((zones: Zone[], mission) => zones.concat(mission.getZones()), []).filter((sel) => !!sel),
    );
  }

  getSamplingSpecs() {
    return new SamplingSpecList(
      ...this.reduce(
        (samplingSpecs: SamplingSpec[], mission) => samplingSpecs.concat(mission.getSamplingSpecs()),
        [],
      ).filter((sel) => !!sel),
    );
  }

  getJobs() {
    return new JobList(...this.map((mission) => mission.getJob()).filter((sel) => !!sel));
  }

  getZoneRecordings() {
    return new ZoneRecordingList(
      ...this.reduce(
        (zoneRecordings: ZoneRecording[], mission) => zoneRecordings.concat(mission.getZoneRecordings()),
        [],
      ).filter((sel) => !!sel),
    );
  }

  getSessions() {
    return new SessionList(...this.map((mission) => mission.getSession()).filter((sel) => !!sel));
  }
}
