import { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, InputLabel, Radio, FormControlLabel } from '@material-ui/core';
import { ConfirmDialog } from '../../utils';
import { ChangeReason, ChangeReasons, SampleChangeType } from '../../../types/types';
import { Sample } from '../../../db';
import { ConfirmDialogResult } from '../../utils/ConfirmDialog';
import { alertError } from '../../../alertDispatcher';
import { findSampleById } from '../../../sampleSelectionHelpers';

interface ChangeTypeReasonSelectorProps {
  clearBarcode: (sampleInstanceId: number) => Promise<void>;
  sampleCoreOrZoneId?: string;
  onClose: (
    result: ConfirmDialogResult,
    data: { enteredReason?: string; selectedChangeType?: SampleChangeType; error?: string },
  ) => Promise<void> | void;
  changeTypeOptions?: SampleChangeType[];
  defaultChangeType?: SampleChangeType;
  defaultChangeReason?: ChangeReason | string;
}

interface ChangeTypeReasonSelectorState {
  reasonSelector: boolean;
  selectedReason?: ChangeReason;
  enteredReason?: string;
  error?: string;
  selectedChangeType?: SampleChangeType;
  sample?: Sample;
}

const isPredefinedSkipReason = (reason: ChangeReason | string | undefined) =>
  !reason ? false : ChangeReasons.indexOf(reason) > -1;

//button on the sampling mission page to allow operators to skip a sample
export default class ChangeTypeReasonSelector extends PureComponent<
  ChangeTypeReasonSelectorProps,
  ChangeTypeReasonSelectorState
> {
  OTHER_REASON_NEEDED = 'Please type in your change reason. Change Reason cannot be blank.';

  constructor(props: ChangeTypeReasonSelectorProps) {
    super(props);
    const sample = findSampleById(props.sampleCoreOrZoneId);
    // const changeType = props.changeTypeOptions.length === 1 ? props.changeTypeOptions[0] : sample?.change_type ?? props.defaultChangeType;
    let changeType: SampleChangeType = 'None';
    if (props.changeTypeOptions?.length === 1) {
      changeType = props.changeTypeOptions[0];
    } else if (sample?.change_type && sample.change_type !== 'None') {
      changeType = sample.change_type;
    } else if (props.defaultChangeType) {
      changeType = props.defaultChangeType;
    }
    const changeReason = sample?.change_reason ?? props.defaultChangeReason;
    this.state = {
      reasonSelector: false,
      selectedReason: isPredefinedSkipReason(changeReason) ? changeReason : Boolean(changeReason) ? 'Other' : '',
      error: undefined,
      selectedChangeType: changeType,
      enteredReason: isPredefinedSkipReason(changeReason) ? '' : changeReason,
      sample: sample,
    };
  }

  selectReason = async (type: SampleChangeType | null, reason: string | null) => {
    // if we are prompting for an add, then the sample likely doesn't exist so we will
    // simply move on
    if (type === 'Add') {
      return;
    }
    if (!this.state.sample) {
      alertError(`Sample not found (sampleId=${this.props.sampleCoreOrZoneId})`);
      return;
    }
  };

  render() {
    let noneText = '';
    switch (this.state.sample?.change_type) {
      case 'Skip':
        noneText = 'Unskip';
        break;
      case 'Delete':
        noneText = 'Undelete';
        break;
    }

    const changeTypeSelected = this.state.selectedChangeType !== 'None';
    const changeReasonSelected = Boolean(this.state.selectedReason);
    const otherChangeReasonWithoutText = this.state.selectedReason !== 'Other' || Boolean(this.state.enteredReason);
    const changeReasonOkay = changeReasonSelected && otherChangeReasonWithoutText;

    const confirmDisabled = changeTypeSelected && !changeReasonOkay;
    return (
      <ConfirmDialog
        open={true}
        title={`Select Change Type And Reason for ${this.props.sampleCoreOrZoneId}`}
        message={''} // {'Skip = ... <br> Delete = ....'}
        confirmAction={`Confirm ${this.state.selectedChangeType === 'None' ? noneText : this.state.selectedChangeType}`}
        denyAction={'Cancel'}
        confirmDisabled={confirmDisabled}
        onConfirm={async (result) => {
          if (result === 'confirm') {
            if (this.state.selectedChangeType === 'None') {
              //await this.selectReason(this.state.selectedChangeType, '')
              await this.props.onClose(result, {
                selectedChangeType: this.state.selectedChangeType,
                enteredReason: '',
              });
            } else if (this.state.selectedReason === 'Other') {
              //await this.selectReason(this.state.selectedChangeType, this.state.enteredReason)
              await this.props.onClose(result, {
                selectedChangeType: this.state.selectedChangeType,
                enteredReason: this.state.enteredReason,
              });
            } else {
              //await this.selectReason(this.state.selectedChangeType, this.state.selectedReason)
              await this.props.onClose(result, {
                selectedChangeType: this.state.selectedChangeType,
                enteredReason: this.state.selectedReason,
              });
            }
          } else {
            this.props.onClose(result, { enteredReason: '', selectedChangeType: undefined });
          }
        }}
      >
        <InputLabel error={this.state.error !== undefined} style={{ marginBottom: 10, fontSize: 18 }}>
          {/* {`Change Type: ${this.props.changeTypeOptions.length === 1 ? this.props.changeTypeOptions[0] : ''}`} */}
          <div
            dangerouslySetInnerHTML={{
              __html: `Change Type: <b>${this.props.changeTypeOptions?.length === 1 ? this.props.changeTypeOptions[0] : ''}</b>`,
            }}
          />
        </InputLabel>

        {this.props.changeTypeOptions && this.props.changeTypeOptions.length > 1 && (
          <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
            {/* // special case where we filter out "None" if that is the current sample state as it is unclear
                        // what that means. If the state is already "Skip" or "Delete", then we will include "None" */}
            {this.props.changeTypeOptions
              .filter((change_type) => this.state.sample?.skipped_or_deleted || change_type !== 'None')
              .map((changeType) => (
                <FormControlLabel
                  key={changeType}
                  control={
                    <Radio
                      color="primary"
                      checked={this.state.selectedChangeType === changeType}
                      onChange={() => this.setState({ selectedChangeType: changeType })}
                    />
                  }
                  // if selecting the "None" option, display it as "Unskip" or "Undelete"
                  label={changeType === 'None' ? noneText : changeType}
                />
              ))}
          </Grid>
        )}

        <InputLabel error={this.state.error !== undefined} style={{ fontSize: 18 }}>
          Change Reason:
        </InputLabel>
        <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
          {ChangeReasons.map((skipReason) => (
            <FormControlLabel
              key={skipReason}
              control={
                <Radio
                  color="primary"
                  checked={this.state.selectedReason === skipReason}
                  onChange={() => {
                    this.setState({
                      selectedReason: skipReason,
                    });
                    // if we selected 'Other' and we don't have a reason, show an error
                    if (skipReason === 'Other') {
                      // && !Boolean(this.state.enteredReason)
                      this.setState({
                        error: !Boolean(this.state.enteredReason) ? this.OTHER_REASON_NEEDED : undefined,
                      });
                    } else {
                      this.setState({
                        error: undefined,
                        enteredReason: undefined,
                      });
                    }
                  }}
                  disabled={this.state.selectedChangeType === 'None'}
                />
              }
              label={skipReason}
            />
          ))}
        </Grid>

        <TextField
          label="Other Reason"
          multiline
          minRows={2}
          fullWidth
          variant="outlined"
          value={this.state.selectedReason === 'Other' ? this.state.enteredReason : ''}
          error={Boolean(this.state.error)}
          helperText={this.state.error}
          disabled={this.state.selectedChangeType === 'None' || this.state.selectedReason !== 'Other'}
          onChange={(e) => {
            const otherReason = e.target.value.toString();
            this.setState({
              enteredReason: otherReason,
              error: Boolean(otherReason) ? undefined : this.OTHER_REASON_NEEDED,
            });
          }}
        />
      </ConfirmDialog>
    );
  }
}
